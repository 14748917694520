import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  TextField,
  Typography,
  createFilterOptions,
  FormHelperText
} from '@mui/material';

import { useEffect, useState, useRef } from 'react';
import { addDays, format } from 'date-fns';
import { clone, debounce } from 'lodash';

import { useForm } from '../../../hooks/useForm';

import InvoiceItemSumatory from '../../invoiceItem/InvoiceItemSumatory';
import InvoiceItemTable from '../../invoiceItem/InvoiceItemTable';
import InvoiceItemForm from '../../invoiceItem/InvoiceItemForm';
import DeletePopUp from '../../DeletePopUp';

import { COMMON_MESSAGE } from '../../../util/yupValidationMessages';
import CustomDatePicker from '../../CustomDatePicker';
import dayjs from 'dayjs';

const filter = createFilterOptions();

const initialValues = {
  serie: undefined,
  numberOfDocument: 1,
  date: new Date(),
  paymentPeriod: undefined,
  client: undefined,
  paymentMode: undefined,
  commentary: undefined,
  salesReceiptItems: []
};

const Form = (props) => {
  const {
    getNextDocumentNumber,
    setDocumentStorage,
    isInSerieDateRange,
    handleDeleteDraft,
    documentStorage,
    currentCompany,
    paymentPeriods,
    paymentModes,
    documentDraft,
    onSubmitForm,
    document,
    onCancel,
    clients,
    draftId,
    series,
    units,
    taxes,
    items,
    isOnRequest
  } = props;

  const [isSalesReceiptItemPopUpOpen, setIsSalesReceiptItemPopUpOpen] = useState(false);
  const [addAnotherSalesReceiptItem, setAddAnotherSalesReceiptItem] = useState(false);
  const [paymentPeriodInitialLength, setPaymentPeriodInitialLength] = useState(null);
  const [paymentModeInitialLength, setPaymentModeInitialLength] = useState(null);
  const [paymentPeriodInputValue, setPaymentPeriodInputValue] = useState(null);
  const [selectedSalesReceiptItem, setSelectedSalesReceiptItem] = useState();
  const [salesReceiptItemError, setSalesReceiptItemError] = useState(false);
  const [paymentModeInputValue, setPaymentModeInputValue] = useState(null);
  const [clientInitialLength, setClientInitialLength] = useState(null);
  const [serieInitialLength, setSerieInitialLength] = useState(null);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [clientInputValue, setClientInputValue] = useState(null);
  const [salesReceiptItems, setSalesReceiptItems] = useState([]);
  const [serieInputValue, setSerieInputValue] = useState(null);
  const [currentSerie, setCurrentSerie] = useState(null);
  const [taxesMerged, setTaxesMerged] = useState(null);

  const { openForm } = useForm();

  const formikRef = useRef();

  const currentDocument = document ?? documentDraft ?? null;

  const salesReceiptValues = currentDocument ? ({
    serie: currentDocument.serie,
    numberOfDocument: 1,
    date: new Date(),
    paymentPeriod: currentDocument.paymentPeriod?.id || '',
    client: currentDocument.clientId || '',
    paymentMode: currentDocument.paymentMode?.id || '',
    commentary: currentDocument.commentary ?? '',
    referenceCode: currentDocument.referenceCode ?? ''
  }) : null;

  const validationSchema = Yup
    .object()
    .shape({
      serie: Yup
        .string()
        .trim()
        .required(COMMON_MESSAGE.REQUIRED),
      date: Yup
        .date()
        .min(new Date(currentSerie?.startDateRaw || new Date()),
          COMMON_MESSAGE.INVALID_DATE)
        .max(new Date(currentSerie?.endDateRaw || new Date()),
          COMMON_MESSAGE.INVALID_DATE)
        .required(COMMON_MESSAGE.REQUIRED),
      paymentPeriod: Yup
        .string()
        .required(COMMON_MESSAGE.REQUIRED),
      paymentMode: Yup
        .string()
        .required(COMMON_MESSAGE.REQUIRED),
      client: Yup
        .string()
        .trim(),
      referenceCode: Yup
        .string()
        .trim(),
      commentary: Yup
        .string()
        .max(200, COMMON_MESSAGE.MAX_CHARACTERS)
        .trim(),
      numberOfDocument: Yup
        .number()
    });

  useEffect(() => {
    if (!serieInitialLength && series) {
      setSerieInitialLength(series.length);
    } else if (series?.length > serieInitialLength) {
      setSerieInputValue(series[(series.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.serie = series[(series.length) - 1].id;
      }
    }
  }, [series]);

  useEffect(() => {
    if (!paymentPeriodInitialLength && paymentPeriods) {
      setPaymentPeriodInitialLength(paymentPeriods.length);
    } else if (paymentPeriods?.length > paymentPeriodInitialLength) {
      setPaymentPeriodInputValue(paymentPeriods[(paymentPeriods.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentPeriod = paymentPeriods[(paymentPeriods.length) - 1].id;
      }
    }
  }, [paymentPeriods]);

  useEffect(() => {
    if (!paymentModeInitialLength && paymentModes) {
      setPaymentModeInitialLength(paymentModes.length);
    } else if (paymentModes?.length > paymentModeInitialLength) {
      setPaymentModeInputValue(paymentModes[(paymentModes.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentMode = paymentModes[(paymentModes.length) - 1].id;
      }
    }
  }, [paymentModes]);

  useEffect(() => {
    if (!clientInitialLength && clients) {
      setClientInitialLength(clients.length);
    } else if (clients?.length > clientInitialLength) {
      setClientInputValue(clients[(clients.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.client = clients[(clients.length) - 1].id;
      }
    }
  }, [clients]);

  const handleOpenSalesReceiptItemForm = () => {
    setIsSalesReceiptItemPopUpOpen(true);
  };

  const handleCloseSalesReceiptItemForm = () => {
    setIsSalesReceiptItemPopUpOpen(false);
    setSelectedSalesReceiptItem();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const updateSalesReceiptStorage = (data) => {
    const idCompany = currentCompany.id;
    const initialDraftsStorage = {
      salesReceipts: []
    };

    const draftsStorage = clone(documentStorage) || clone(initialDraftsStorage);
    let hasCompanyStorage;
    const companyIndex = draftsStorage.salesReceipts.findIndex((company) => hasCompanyStorage = idCompany in company);
    let updatedSalesReceiptStorage = clone(initialValues);
    const drafts = [];

    if (!hasCompanyStorage) {
      data.keys.forEach((key, index) => updatedSalesReceiptStorage[key] = data.values[index]);
      drafts.push({ [draftId]: updatedSalesReceiptStorage });
      draftsStorage.salesReceipts.push(
        {
          [idCompany]: drafts
        }
      );
    } else {
      const invalidIndex = -1;
      const draftIndex = draftsStorage.salesReceipts[companyIndex][idCompany].findIndex((draft) => draftId in draft);
      if (draftIndex > invalidIndex) {
        updatedSalesReceiptStorage = draftsStorage.salesReceipts[companyIndex][idCompany][draftIndex][draftId];
        data.keys.forEach((key, index) => updatedSalesReceiptStorage[key] = data.values[index]);
        draftsStorage.salesReceipts[companyIndex][idCompany][draftIndex][draftId] = updatedSalesReceiptStorage;
      } else {
        data.keys.forEach((key, index) => updatedSalesReceiptStorage[key] = data.values[index]);
        draftsStorage.salesReceipts[companyIndex][idCompany].push({
          [draftId]: updatedSalesReceiptStorage
        });
      }
    }
    setDocumentStorage(draftsStorage);
  };

  const debouncedUpdateSalesReceiptStorage = debounce(updateSalesReceiptStorage, 1000);

  const handleAddSalesReceiptItem = (salesReceipt) => {
    const newSalesReceipt = {
      // this is a temporary id, since we won't send this id it to api
      id: Math.random(),
      discount: Number(salesReceipt.discount),
      item: salesReceipt.item,
      itemCode: salesReceipt.itemCode,
      tax: salesReceipt.tax,
      taxValue: Number(salesReceipt.taxValue),
      taxName: salesReceipt.taxName,
      price: Number(salesReceipt.price),
      quantity: Number(salesReceipt.quantity),
      unit: salesReceipt.unit,
      total: Number(salesReceipt.total),
      description: salesReceipt.description
    };

    const newSalesReceipts = [...salesReceiptItems, newSalesReceipt];

    setSalesReceiptItems(newSalesReceipts);
    debouncedUpdateSalesReceiptStorage({ keys: ['salesReceiptItems'], values: [newSalesReceipts] });
    setSalesReceiptItemError(false);
  };

  const handleUpdateSalesReceiptItem = (updatedSalesReceiptItem) => {
    const editedSalesReceiptItemList = salesReceiptItems.map(((salesReceiptItem) => {
      if (updatedSalesReceiptItem.id === salesReceiptItem.id) {
        return updatedSalesReceiptItem;
      }
      return salesReceiptItem;
    }));

    setSalesReceiptItems(editedSalesReceiptItemList);
    debouncedUpdateSalesReceiptStorage({ keys: ['salesReceiptItems'], values: [editedSalesReceiptItemList] });
  };

  const handleDeleteSalesReceiptItem = () => {
    const filteredSalesReceiptItems = salesReceiptItems.filter((salesReceipt) => salesReceipt.id !== selectedSalesReceiptItem.id);
    setSalesReceiptItems(filteredSalesReceiptItems);
    setSelectedSalesReceiptItem();
    handleDeletePopUpClose();

    if (filteredSalesReceiptItems.length === 0) {
      setSalesReceiptItemError(true);
    }
    debouncedUpdateSalesReceiptStorage({ keys: ['salesReceiptItems'], values: [filteredSalesReceiptItems] });
  };

  const handleSelectSalesReceiptItem = (salesReceipt) => {
    setAddAnotherSalesReceiptItem(!addAnotherSalesReceiptItem);
    setSelectedSalesReceiptItem(salesReceipt);
  };

  const handleToggleAddAnotherSalesReceiptItem = () => {
  };

  const handleFormatTaxes = () => {
    const reducer = (acc, currentValue) => {
      acc[currentValue.id] = currentValue;
      return acc;
    };

    const hashItens = items ? items
      .map((item) => item.tax)
      .reduce(reducer, {}) : {};

    const hashTaxes = taxes ? taxes.reduce(reducer, hashItens) : {};

    setTaxesMerged(Object.values(hashTaxes));
  };

  useEffect(() => {
    handleFormatTaxes();
  }, [taxes, items]);

  const getPaymentPeriodRange = (values) => {
    if (paymentPeriods) {
      return format(addDays(values?.date, paymentPeriods.find((paymentPeriod) => paymentPeriod?.id === values?.paymentPeriod)?.numberOfDay || 0), 'dd/MM/yyyy');
    }
    return format(values?.date, 'dd/MM/yyyy');
  };

  const handleSetCurrentSerie = (value) => {
    setCurrentSerie(value);
  };

  const isSerieDraftExist = () => series?.find((serie) => currentDocument?.serie === serie.id);
  const isPaymentPeriodExist = () => paymentPeriods?.find((paymentPeriod) => currentDocument?.paymentPeriod?.id === paymentPeriod.id);
  const isPaymentModeExist = () => paymentModes?.find((paymentMode) => currentDocument?.paymentMode?.id === paymentMode.id);
  const isClientExist = () => clients?.find((client) => currentDocument?.clientId === client.id);

  useEffect(() => {
    if ((series && document) || (series && currentDocument)) {
      handleSetCurrentSerie(series.find((serie) => serie.id === currentDocument?.serie));
    }
  }, [series]);

  useEffect(() => {
    if (currentDocument) {
      setSalesReceiptItems(currentDocument.salesReceiptItems?.map((item) => ({
        id: item.id,
        discount: Number(item.discount),
        item: item.item.id || item.item,
        itemCode: item.item.code || item.itemCode,
        tax: item.tax.id || item.tax,
        taxValue: Number(item.tax.value) || Number(item.taxValue),
        taxName: item.tax.name || item.taxName,
        price: Number(item.price),
        quantity: Number(item.quantity),
        unit: item.unit,
        total: Number(item.itemTotal) || Number(item.total)
      })));
    }
  }, []);

  const handleDateChange = (newValue, setFieldValue) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue('date', newValue.toDate());
    }
  };

  const [date, setDate] = useState();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={(document || currentDocument) ? salesReceiptValues : initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.salesReceiptItems = salesReceiptItems;

          if (!values.salesReceiptItems.length) {
            setSalesReceiptItemError(true);
            return;
          }

          onSubmitForm(values);
          handleDeleteDraft(draftId);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Box>
            <Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                mt: 2
              }}
              >
                <Autocomplete
                  id="sales-receipt-form-serie-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={series || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });
                    return filtered;
                  }}
                  disableClearable
                  inputValue={serieInputValue || ''}
                  defaultValue={currentDocument && isSerieDraftExist() ? ({
                    id: isSerieDraftExist()?.id,
                    name: isSerieDraftExist()?.name
                  }) : null}
                  onChange={(event, value) => {
                    handleSetCurrentSerie(value);
                    setSerieInputValue(value.name);
                    setFieldValue('serie', value.id || '');
                    debouncedUpdateSalesReceiptStorage({ keys: ['serie', 'serieName', 'serieCode'], values: [value.id || '', value.name || '', value.code || ''] });
                    if (value.name === 'Criar Novo') {
                      openForm('SERIE');
                      value.name = serieInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && serieInputValue !== '') || serieInputValue.length === 1) {
                      setSerieInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.serie && errors.serie)}
                      helperText={touched.serie && errors.serie}
                      label="Série*"
                      name="serie"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
                <TextField
                  id="sales-receipt-form-number-of-document"
                  error={Boolean(touched.numberOfDocument && errors.numberOfDocument)}
                  fullWidth
                  helperText={touched.numberOfDocument && errors.numberOfDocument}
                  disabled
                  label="Número*"
                  name="numberOfDocument"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={getNextDocumentNumber(values.serie) || 1}
                  variant="outlined"
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <Box>
                  <CustomDatePicker
                    id="sales-receipt-form-date-picker"
                    label="Data"
                    onChange={(newValue) => {
                      setDate(newValue);
                      handleDateChange(newValue, setFieldValue)}
                  }
                    values={date}
                  />
                  {(currentSerie && !isInSerieDateRange(values.date, currentSerie)) && (
                    <Box>
                      <FormHelperText
                        id="sales-receipt-form-date-picker-helper-text"
                        error
                      >
                        {COMMON_MESSAGE.INVALID_DATE}
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
                <Autocomplete
                  id="sales-receipt-form-payment-period-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={paymentPeriods || []}
                  defaultValue={currentDocument && isPaymentPeriodExist() ? ({
                    id: isPaymentPeriodExist().id,
                    name: isPaymentPeriodExist().name
                  }) : null}
                  disableClearable
                  inputValue={paymentPeriodInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentPeriodInputValue(value.name);
                    setFieldValue('paymentPeriod', value.id || '');
                    debouncedUpdateSalesReceiptStorage({ keys: ['paymentPeriod'], values: [value || ''] });

                    if (value.name === 'Criar Novo') {
                      openForm('PAYMENT_PERIOD');
                      value.name = paymentPeriodInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentPeriodInputValue !== '') || paymentPeriodInputValue.length === 1) {
                      setPaymentPeriodInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentPeriod && errors.paymentPeriod)}
                      helperText={touched.paymentPeriod && errors.paymentPeriod}
                      label="Período de Pagamento*"
                      name="paymentPeriod"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Limite de Pagamento"
                  name="range"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    debouncedUpdateSalesReceiptStorage({ keys: ['paymentPeriod'], values: [event.target.value || ''] });
                  }}
                  value={getPaymentPeriodRange(values)}
                  variant="outlined"
                />
                <Autocomplete
                  id="sales-receipt-form-payment-mode-autocomplete"
                  getOptionLabel={(option) => option.code}
                  options={paymentModes || []}
                  disableClearable
                  defaultValue={currentDocument && isPaymentModeExist() ? ({
                    id: isPaymentModeExist()?.id,
                    code: isPaymentModeExist()?.code
                  }) : null}
                  inputValue={paymentModeInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentModeInputValue(value.code);
                    setFieldValue('paymentMode', value.id || '');
                    debouncedUpdateSalesReceiptStorage({ keys: ['paymentMode'], values: [value || ''] });
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentModeInputValue !== '') || paymentModeInputValue.length === 1) {
                      setPaymentModeInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentMode && errors.paymentMode)}
                      helperText={touched.paymentMode && errors.paymentMode}
                      label="Modo de Pagamento*"
                      name="paymentMode"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <Autocomplete
                  id="sales-receipt-form-client-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={clients || []}
                  disableClearable
                  inputValue={clientInputValue || ''}
                  defaultValue={currentDocument && isClientExist() ? ({
                    id: isClientExist().id,
                    name: isClientExist().name
                  }) : null}
                  onChange={(event, value) => {
                    setClientInputValue(value.name);
                    setFieldValue('client', value.id || '');
                    debouncedUpdateSalesReceiptStorage({ keys: ['clientId', 'clientName'], values: [value.id || '', value.name || ''] });

                    if (value.name === 'Criar Novo') {
                      openForm('CLIENT');
                      value.name = clientInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && clientInputValue !== '') || clientInputValue.length === 1) {
                      setClientInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.client && errors.client)}
                      helperText={touched.client && errors.client}
                      label="Cliente"
                      name="client"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <TextField
                  id="sales-receipt-form-reference-code-input"
                  fullWidth
                  error={Boolean(touched.referenceCode && errors.referenceCode)}
                  helperText={touched.referenceCode && errors.referenceCode}
                  label="Código de Referência"
                  name="referenceCode"
                  onChange={(event) => {
                    handleChange(event);
                    debouncedUpdateSalesReceiptStorage({ keys: ['referenceCode'], values: [event.target.value || ''] });
                  }}
                  value={values.referenceCode}
                  variant="outlined"
                />
              </Box>
            </Box>

            <Box sx={{ py: 4 }}>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista de itens no Talão de Venda
              </Typography>
              <InvoiceItemTable
                invoiceItems={salesReceiptItems}
                onOpenForm={handleOpenSalesReceiptItemForm}
                selectInvoiceItem={handleSelectSalesReceiptItem}
                onDelete={handleDeletePopUpOpen}
                currentCompany={currentCompany}
              />
              <Typography
                color="error"
                variant="overline"
                hidden={!salesReceiptItemError}
              >
                Obrigatório
              </Typography>
              <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleCloseSalesReceiptItemForm}
                open={isSalesReceiptItemPopUpOpen}
              >
                {isSalesReceiptItemPopUpOpen && (
                  <InvoiceItemForm
                    onComplete={handleCloseSalesReceiptItemForm}
                    onCancel={handleCloseSalesReceiptItemForm}
                    onAdd={handleAddSalesReceiptItem}
                    onEdit={handleUpdateSalesReceiptItem}
                    onAddAnother={handleOpenSalesReceiptItemForm}
                    toggleAddAnother={handleToggleAddAnotherSalesReceiptItem}
                    isAddAnother={addAnotherSalesReceiptItem}
                    isAdd={!selectedSalesReceiptItem}
                    selectedInvoiceItem={selectedSalesReceiptItem}
                    openForm={openForm}
                    units={units}
                    taxes={taxesMerged}
                    items={items}
                    currentCompany={currentCompany}
                  />
                )}
              </Dialog>
            </Box>
            <Box sx={{
              py: 4,
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: 4
            }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="overline"
                >
                  Comentário(s)
                </Typography>
                <TextField
                  id="sales-receipt-form-commentary-input"
                  error={Boolean(touched.commentary && errors.commentary)}
                  fullWidth
                  helperText={touched.commentary && errors.commentary}
                  name="commentary"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    debouncedUpdateSalesReceiptStorage({ keys: ['commentary'], values: [event.target.value] });
                  }}
                  value={values.commentary}
                  variant="standard"
                  multiline
                />
              </Box>
              <InvoiceItemSumatory
                invoiceItems={salesReceiptItems}
                currentCompany={currentCompany}
              />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button
                id="sales-receipt-form-cancel-button"
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Voltar
              </Button>
              <Button
                id="sales-receipt-form-add-button"
                color="primary"
                disabled={isOnRequest}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                Criar
              </Button>
            </Box>
            <Dialog
              maxWidth="xs"
              onClose={handleDeletePopUpClose}
              open={isDeletePopUpOpen}
            >
              {isDeletePopUpOpen && (
                <DeletePopUp
                  onCancel={handleDeletePopUpClose}
                  onDelete={handleDeleteSalesReceiptItem}
                />
              )}
            </Dialog>
          </Box>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  isInSerieDateRange: PropTypes.func.isRequired,
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lastDocumentNumber: PropTypes.string,
    range: PropTypes.string,
    fiscalYearName: PropTypes.string,
    fiscalYearDate: PropTypes.string
  }).isRequired,
  reinitializeForm: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  paymentPeriods: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.string
  }).isRequired,
  paymentModes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  units: PropTypes.arrayOf({
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    tax: PropTypes.number
  }).isRequired,
  items: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number
  }).isRequired,
  document: PropTypes.shape({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    serieCode: PropTypes.string,
    paymentPeriod: PropTypes.string,
    isVoided: PropTypes.bool,
    paymentMode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    referenceCode: PropTypes.string,
    serieName: PropTypes.string,
    valueWithoutTax: PropTypes.number,
    totalTax: PropTypes.number,
    valueWithTax: PropTypes.number,
    discount: PropTypes.number,
    totalGeneral: PropTypes.number,
    number: PropTypes.number,
    salesReceiptItems: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    })
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  draftId: PropTypes.number.isRequired,
  documentDraft: PropTypes.object.isRequired,
  documentStorage: PropTypes.object.isRequired,
  setDocumentStorage: PropTypes.func.isRequired,
  handleDeleteDraft: PropTypes.func.isRequired,
  getNextDocumentNumber: PropTypes.func.isRequired,
  isOnRequest: PropTypes.bool.isRequired,
};

export default Form;
