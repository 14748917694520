import { EFATURA_DOCUMENT_STATUS } from '../constants';

export const eFaturasStatus = (status) => {
  switch (status) {
    case 'completed':
      return { label: 'Completo', color: '#21C8C3', backgroundColor: 'rgba(33, 200, 195, 0.1)' };
    case 'failed':
      return { label: 'Falhou', color: '#FF5E52', backgroundColor: 'rgba(255, 94, 82, 0.1)' };
    default:
      return { label: 'Pendente', color: '#F1B37A', backgroundColor: 'rgba(241, 179, 122, 0.1)' };
  }
};

export const isDocumentEfaturaStatusFailed = (status) => status === EFATURA_DOCUMENT_STATUS.failed;

/**
 * @param {boolean} isDocumentRetried - a boolean value to check if the document is retried, if so this function will return false
 * @param {object} efaturaData - the object with important data to make the validation
 * @param {string} efaturaData.status - the status of the document at e-Fatura, if the status is different than "failed" this function will return false
 * @param {string | undefined} efaturaData.errorCode - the error code related to the reason why the document failed,
 * if this attribute is undefined this function will return false
 * @returns {boolean} - return true if the document is not retried, the status of the document at e-Fatura is failed and it has a error code
 */
export const isDocumentEfaturaStatusFailedWithError = (isDocumentRetried, efaturaData) => !isDocumentRetried
  && isDocumentEfaturaStatusFailed(efaturaData?.status)
  && efaturaData?.errorCode;

/**
 * Checks if a document that its eFaturas status failed is been retried
 * @param {array} documentsIds - list of documents ids that are been retried
 * @param {string} documentId - the document id that is been retried
 * @return {string} - Return pending or other status of eFatura status
*/
export const getDocumentEfaturaStatusButton = (documentsIds, documentId, documentEfaturaStatus) => (documentsIds.includes(documentId) ? EFATURA_DOCUMENT_STATUS.pending : documentEfaturaStatus);

export const isCompanyNotIntegratedWithEFatura = (status) => status !== true;

export const isCompanyIntegratedWithEFatura = (status) => status === true;
