import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/pt';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box } from '@mui/material';
import dayjs from 'dayjs';

const CustomDatePicker = ({ label, onChange, id, values }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt">
      <Box id={id}>
        <DatePicker
          id={id}
          label={label}
          onChange={onChange}
          sx={{ width: '100%' }}
          value={values ? values : dayjs()}
        />
      </Box>
    </LocalizationProvider>
  );
};

CustomDatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default CustomDatePicker;
