import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

let companyId;

const initialState = {
  companySettings: null,
  isLoading: false,
  error: false
};

const slice = createSlice({
  name: 'companySettings',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.companySettings = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.companySettings = null;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'companySettings/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/company_settings`);
      const companySettings = {
        isPaymentEnabled: data.is_payment_enabled
      };

      dispatch(_getSuccess(companySettings));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
