import { useCallback, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { ResetPasswordForm } from '../../components/authentication/resetPassword';
import { useSelector } from '../../store';
import { TOAST_TYPE } from '../../constants';
import Toast from '../../components/Toast';
import useAuth from '../../hooks/useAuth';

const ResetPassword = () => {
  const { platform } = useAuth();
  const navigate = useNavigate();
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const { onRequestFailure, errorMessage } = useSelector((state) => state.users);

  const { token } = useParams();

  const navigateToLoginPage = useCallback(() => {
    navigate('/authentication/login');
  }, []);

  const handleRegisterButtonClick = useCallback(() => {
    navigate('/authentication/register');
  }, []);

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    }
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequestFailure, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Resetar Senha | Samba</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Nova Senha
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Introduza a sua nova senha.
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT'
                && (
                  <>
                    <ResetPasswordForm
                      token={token}
                      handlePopUpOpen={handlePopUpOpen}
                      navigateToLoginPage={navigateToLoginPage}
                      handleRegisterButtonClick={handleRegisterButtonClick}
                    />
                    <Toast
                      isOpen={isPopUpOpen}
                      toastType={toastType}
                      message={message}
                      onClose={handlePopUpClose}
                    />

                  </>
                )}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ResetPassword;
