import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import {
  Box,
  Typography
} from '@mui/material';

import animationData from '../../lotties/loading-fail-state.json';

export default function UnsuccessPopUp(props) {
  const { message } = props;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData
  };
  return (
    <Box>
      <Lottie
        options={defaultOptions}
        height={350}
        width={350}
      />
      <Typography
        color="textSecondary"
        variant="h5"
        align="center"
        sx={{
          mb: 4
        }}
      >
        {message}
      </Typography>

    </Box>
  );
}

UnsuccessPopUp.propTypes = {
  message: PropTypes.string.isRequired
};
