import { Box, Table, TableBody, TableCell, TableRow, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

function EditableTable({
  values,
  touched,
  handleBlur,
  handleChange,
  errors,
  serialNumberConfig
}) {
  return (
    <Box sx={{ p: 3 }}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell />
            <TableCell sx={{ fontWeight: 700 }}>
              Número do Último Documento*
            </TableCell>
            <TableCell sx={{ fontWeight: 700 }}>
              Limite de Número de Série*
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Fatura
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.invoiceLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-invoice-last-document-number-input"
                    touched={touched.invoiceLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.invoiceLastDocumentNumber && errors.invoiceLastDocumentNumber)}
                    helperText={touched.invoiceLastDocumentNumber && errors.invoiceLastDocumentNumber}
                    value={values.invoiceLastDocumentNumber}
                    name="invoiceLastDocumentNumber"
                    label="Número"
                    variant="outlined"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.invoiceRange
                : (
                  <TextField
                    id="serie-form-invoice-range-input"
                    touched={touched.invoiceRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.invoiceRange && errors.invoiceRange)}
                    helperText={touched.invoiceRange && errors.invoiceRange}
                    value={values.invoiceRange}
                    name="invoiceRange"
                    label="Limite"
                    variant="outlined"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Recibo
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.receiptLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-receipt-last-document-number-input"
                    touched={touched.receiptLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.receiptLastDocumentNumber && errors.receiptLastDocumentNumber)}
                    helperText={touched.receiptLastDocumentNumber && errors.receiptLastDocumentNumber}
                    value={values.receiptLastDocumentNumber}
                    name="receiptLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.receiptRange
                : (
                  <TextField
                    id="serie-form-receipt-range-input"
                    touched={touched.receiptRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.receiptRange && errors.receiptRange)}
                    helperText={touched.receiptRange && errors.receiptRange}
                    value={values.receiptRange}
                    name="receiptRange"
                    label="Número"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Fatura/Recibo
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.invoiceReceiptLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-invoice-receipt-last-document-number-input"
                    touched={touched.invoiceReceiptLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.invoiceReceiptLastDocumentNumber && errors.invoiceReceiptLastDocumentNumber)}
                    helperText={touched.invoiceReceiptLastDocumentNumber && errors.invoiceReceiptLastDocumentNumber}
                    value={values.invoiceReceiptLastDocumentNumber}
                    name="invoiceReceiptLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.invoiceReceiptRange
                : (
                  <TextField
                    id="serie-form-invoice-receipt-range-input"
                    touched={touched.invoiceReceiptRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.invoiceReceiptRange && errors.invoiceReceiptRange)}
                    helperText={touched.invoiceReceiptRange && errors.invoiceReceiptRange}
                    value={values.invoiceReceiptRange}
                    name="invoiceReceiptRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Nota de Débito
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.debitNoteLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-debit-note-last-document-number-input"
                    touched={touched.debitNoteLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.debitNoteLastDocumentNumber && errors.debitNoteLastDocumentNumber)}
                    helperText={touched.debitNoteLastDocumentNumber && errors.debitNoteLastDocumentNumber}
                    value={values.debitNoteLastDocumentNumber}
                    name="debitNoteLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.debitNoteRange
                : (
                  <TextField
                    id="serie-form-debit-note-range-input"
                    touched={touched.debitNoteRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.debitNoteRange && errors.debitNoteRange)}
                    helperText={touched.debitNoteRange && errors.debitNoteRange}
                    value={values.debitNoteRange}
                    name="debitNoteRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Nota de Crédito
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.creditNoteLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-credit-note-last-document-number-input"
                    touched={touched.creditNoteLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.creditNoteLastDocumentNumber && errors.creditNoteLastDocumentNumber)}
                    helperText={touched.creditNoteLastDocumentNumber && errors.creditNoteLastDocumentNumber}
                    value={values.creditNoteLastDocumentNumber}
                    name="creditNoteLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.creditNoteRange
                : (
                  <TextField
                    id="serie-form-credit-note-range-input"
                    touched={touched.creditNoteRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.creditNoteRange && errors.creditNoteRange)}
                    helperText={touched.creditNoteRange && errors.creditNoteRange}
                    value={values.creditNoteRange}
                    name="creditNoteRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Pró-Forma
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.proFormaLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-pro-forma-last-document-number-input"
                    touched={touched.proFormaLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.proFormaLastDocumentNumber && errors.proFormaLastDocumentNumber)}
                    helperText={touched.proFormaLastDocumentNumber && errors.proFormaLastDocumentNumber}
                    value={values.proFormaLastDocumentNumber}
                    name="proFormaLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.proFormaRange
                : (
                  <TextField
                    id="serie-form-pro-forma-range-input"
                    touched={touched.proFormaRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.proFormaRange && errors.proFormaRange)}
                    helperText={touched.proFormaRange && errors.proFormaRange}
                    value={values.proFormaRange}
                    name="proFormaRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Nota de Devolução
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.returnNoteLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-return-note-last-document-number-input"
                    touched={touched.returnNoteLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.returnNoteLastDocumentNumber && errors.returnNoteLastDocumentNumber)}
                    helperText={touched.returnNoteLastDocumentNumber && errors.returnNoteLastDocumentNumber}
                    value={values.returnNoteLastDocumentNumber}
                    name="returnNoteLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.returnNoteRange
                : (
                  <TextField
                    id="serie-form-return-note-range-input"
                    touched={touched.returnNoteRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.returnNoteRange && errors.returnNoteRange)}
                    helperText={touched.returnNoteRange && errors.returnNoteRange}
                    value={values.returnNoteRange}
                    name="returnNoteRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Talão de Venda
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.salesReceiptLastDocumentNumber
                : (
                  <TextField
                    id="serie-form-sales-receipt-last-document-number-input"
                    touched={touched.salesReceiptLastDocumentNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.salesReceiptLastDocumentNumber && errors.salesReceiptLastDocumentNumber)}
                    helperText={touched.salesReceiptLastDocumentNumber && errors.salesReceiptLastDocumentNumber}
                    value={values.salesReceiptLastDocumentNumber}
                    name="salesReceiptLastDocumentNumber"
                    label="Número"
                  />
                )}
            </TableCell>
            <TableCell>
              {serialNumberConfig
                ? serialNumberConfig.salesReceiptRange
                : (
                  <TextField
                    id="serie-form-sales-receipt-range-input"
                    touched={touched.salesReceiptRange}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.salesReceiptRange && errors.salesReceiptRange)}
                    helperText={touched.salesReceiptRange && errors.salesReceiptRange}
                    value={values.salesReceiptRange}
                    name="salesReceiptRange"
                    label="Limite"
                  />
                )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Box>
  );
}

EditableTable.propTypes = {
  values: PropTypes.shape({
    invoiceLastDocumentNumber: PropTypes.string,
    invoiceRange: PropTypes.string,
    receiptLastDocumentNumber: PropTypes.string,
    receiptRange: PropTypes.string,
    invoiceReceiptLastDocumentNumber: PropTypes.string,
    invoiceReceiptRange: PropTypes.string,
    debitNoteLastDocumentNumber: PropTypes.string,
    debitNoteRange: PropTypes.string,
    creditNoteLastDocumentNumber: PropTypes.string,
    creditNoteRange: PropTypes.string,
    proFormaLastDocumentNumber: PropTypes.string,
    proFormaRange: PropTypes.string,
    returnNoteLastDocumentNumber: PropTypes.string,
    returnNoteRange: PropTypes.string,
    salesReceiptLastDocumentNumber: PropTypes.string,
    salesReceiptRange: PropTypes.string
  }).isRequired,
  touched: PropTypes.PropTypes.shape({
    invoiceLastDocumentNumber: PropTypes.string,
    invoiceRange: PropTypes.string,
    receiptLastDocumentNumber: PropTypes.string,
    receiptRange: PropTypes.string,
    invoiceReceiptLastDocumentNumber: PropTypes.string,
    invoiceReceiptRange: PropTypes.string,
    debitNoteLastDocumentNumber: PropTypes.string,
    debitNoteRange: PropTypes.string,
    creditNoteLastDocumentNumber: PropTypes.string,
    creditNoteRange: PropTypes.string,
    proFormaLastDocumentNumber: PropTypes.string,
    proFormaRange: PropTypes.string,
    returnNoteLastDocumentNumber: PropTypes.string,
    returnNoteRange: PropTypes.string,
    salesReceiptLastDocumentNumber: PropTypes.string,
    salesReceiptRange: PropTypes.string
  }).isRequired,
  serialNumberConfig: PropTypes.PropTypes.shape({
    invoiceLastDocumentNumber: PropTypes.string,
    invoiceRange: PropTypes.string,
    receiptLastDocumentNumber: PropTypes.string,
    receiptRange: PropTypes.string,
    invoiceReceiptLastDocumentNumber: PropTypes.string,
    invoiceReceiptRange: PropTypes.string,
    debitNoteLastDocumentNumber: PropTypes.string,
    debitNoteRange: PropTypes.string,
    creditNoteLastDocumentNumber: PropTypes.string,
    creditNoteRange: PropTypes.string,
    proFormaLastDocumentNumber: PropTypes.string,
    proFormaRange: PropTypes.string,
    returnNoteLastDocumentNumber: PropTypes.string,
    returnNoteRange: PropTypes.string,
    salesReceiptLastDocumentNumber: PropTypes.string,
    salesReceiptRange: PropTypes.string
  }).isRequired,
  errors: PropTypes.shape({
    invoiceLastDocumentNumber: PropTypes.string,
    invoiceRange: PropTypes.string,
    receiptLastDocumentNumber: PropTypes.string,
    receiptRange: PropTypes.string,
    invoiceReceiptLastDocumentNumber: PropTypes.string,
    invoiceReceiptRange: PropTypes.string,
    debitNoteLastDocumentNumber: PropTypes.string,
    debitNoteRange: PropTypes.string,
    creditNoteLastDocumentNumber: PropTypes.string,
    creditNoteRange: PropTypes.string,
    proFormaLastDocumentNumber: PropTypes.string,
    proFormaRange: PropTypes.string,
    returnNoteLastDocumentNumber: PropTypes.string,
    returnNoteRange: PropTypes.string,
    salesReceiptLastDocumentNumber: PropTypes.string,
    salesReceiptRange: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default EditableTable;
