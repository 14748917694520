import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Box,
  Typography,
  TextField,
  Autocomplete
} from '@mui/material';
import BorderButton from '../BorderButton';
import {
  emailValidationSchema,
  getCombinedValidationSchema,
  typesOfUsersValidationSchema
} from '../../yupValidation';

const UserManagementForm = (props) => {
  let formikRef;
  const { roles, onCancel, onSubmit } = props;

  const initialValues = {
    email: null,
    typeOfUser: null
  };

  const combinedValidationSchema = getCombinedValidationSchema([
    emailValidationSchema(),
    typesOfUsersValidationSchema()
  ]);

  const handleSubmit = (values) => {
    onSubmit(values);
  };

  const handleExternalSubmit = async () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  return (
    <Formik
      innerRef={(ref) => (formikRef = ref)}
      initialValues={initialValues}
      validationSchema={combinedValidationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleChange, values, errors, touched, handleBlur, setFieldValue }) => (
        <Box sx={{ p: 3 }}>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            Adicionar novo utilizador
          </Typography>

          <Box sx={{ mt: 3 }}>
            <Box sx={{ mb: 2 }}>
              <Autocomplete
                id="user-management-form-user-type-autocomplete"
                getOptionLabel={(option) => option.pt}
                options={roles}
                disableClearable
                noOptionsText="Nenhum tipo de usuário disponível no momento"
                onChange={(e, value) => setFieldValue('typeOfUser', value?.id || '')}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.typeOfUser && errors.typeOfUser)}
                    helperText={touched.typeOfUser && errors.typeOfUser}
                    label="Tipo de Utilizador*"
                    name="typeOfUser"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>

            <TextField
              id="user-management-form-email-text-field"
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={touched.email && errors.email}
              label="Email*"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.email}
              variant="outlined"
            />

            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
              <Box sx={{ mr: 2 }}>
                <BorderButton
                  id="user-management-form-cancel-button"
                  title="Cancelar"
                  variant="outlined"
                  type="button"
                  onClick={onCancel}
                />
              </Box>

              <BorderButton
                id="user-management-form-add-button"
                title="Criar"
                variant="contained"
                type="submit"
                onClick={handleExternalSubmit}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

UserManagementForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  roles: PropTypes.shape({
    id: PropTypes.string.isRequired,
    pt: PropTypes.string.isRequired
  })
};
export default UserManagementForm;
