import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  issueReasons: undefined,
  issueReasonsFormatted: undefined,
  isLoading: false,
  loadingError: false
};

const slice = createSlice({
  name: 'issueReasons',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.issueReasons = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFormattedSuccess: (state, { payload }) => {
      state.issueReasonsFormatted = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.issueReasons = undefined;
      state.issueReasonsFormatted = undefined;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFormattedSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'issueReasons/fetch',
  async (_, { dispatch, getState }) => {
    dispatch(_get());
    try {
      const { countryId } = getState().companies.currentCompany;
      const response = await axios.get(`/countries/${countryId}/issue_reasons`);
      const issueReasonsFormatted = {};
      response.data.map((issueReason) => issueReasonsFormatted[issueReason.code] = issueReason);

      dispatch(_getSuccess(response.data));
      dispatch(_getFormattedSuccess(issueReasonsFormatted));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
