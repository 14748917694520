import { TextField } from '@mui/material';
import PropTypes from 'prop-types';

const TextInput = ({
  id,
  label,
  error,
  helperText,
  onChange,
  handleBlur,
  value,
  name,
  multiline,
  minRows,
  maxRows
}) => (
  <TextField
    id={id}
    fullWidth
    error={error}
    label={label}
    helperText={helperText}
    onChange={onChange}
    onBlur={handleBlur}
    value={value}
    name={name}
    multiline={multiline}
    minRows={minRows}
    maxRows={maxRows}
  />
);

TextInput.defaultProps = {
  id: null,
  error: null,
  helperText: null,
  multiline: false,
  minRows: 1,
  maxRows: 3
};

TextInput.propTypes = {
  id: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  multiline: PropTypes.bool,
  minRows: PropTypes.number,
  maxRows: PropTypes.number
};

export default TextInput;
