import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  creditNotes: null,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  creditNotePdf: null
};

const slice = createSlice({
  name: 'creditNotes',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.creditNotes = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.creditNotes = null;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.creditNotes === null) {
        state.creditNotes = [];
      }
      state.creditNotes.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendCreditNotesEmail: (state) => {
      state.onRequest = true;
    },
    _sendCreditNotesEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendCreditNotesEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setCreditNotesPdf: (state, { payload }) => {
      state.creditNotePdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage,
  _sendCreditNotesEmail,
  _sendCreditNotesEmailFailure,
  _sendCreditNotesEmailSuccess,
  _setCreditNotesPdf
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'creditNote/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/credit_notes`);

      const creditNotes = data.map((creditNote) => ({
        id: creditNote.id,
        documentType: creditNote.document_type ? {
          id: creditNote.document_type.id,
          code: creditNote.document_type.code,
          name: creditNote.document_type.name
        } : {},
        clientName: creditNote.customer.name,
        clientId: creditNote.customer.id,
        issueReason: creditNote.issue_reason ? {
          id: creditNote.issue_reason.id,
          code: creditNote.issue_reason.code,
          description: creditNote.issue_reason.description
        } : {},
        serieCode: creditNote.serial_number.code,
        paymentPeriod: {
          id: creditNote.payment_period.id,
          code: creditNote.payment_period.code,
          name: creditNote.payment_period.name,
          numberOfDay: creditNote.payment_period.number_of_day
        },
        paymentMode: {
          id: creditNote.payment_mode.id,
          code: creditNote.payment_mode.code,
          name: creditNote.payment_mode.name
        },
        efaturaData: {
          id: creditNote.efatura_data.id,
          status: creditNote.efatura_data.status,
          errorCode: creditNote.efatura_data.error_code,
          errorMessage: creditNote.efatura_data.error_message
        },
        date: creditNote.date,
        serie: creditNote.serial_number.id,
        serieName: creditNote.serial_number.name,
        commentary: creditNote.commentary,
        valueWithoutTax: creditNote.value_without_tax,
        totalTax: creditNote.value_of_tax,
        valueWithTax: creditNote.value_with_tax,
        discount: creditNote.discount,
        totalGeneral: creditNote.total_general,
        number: creditNote.number || creditNote.invoice_number,
        isVoided: creditNote.is_voided,
        receiptsValue: creditNote.receipts_values,
        paymentStatus: creditNote.payment_status,
        items: creditNote.items.map((creditNoteItem) => ({
          id: creditNoteItem.id,
          item: creditNoteItem.item,
          quantity: creditNoteItem.quantity,
          unit: creditNoteItem.unit_code,
          price: creditNoteItem.price,
          tax: creditNoteItem.tax,
          discount: creditNoteItem.discount,
          itemTotal: creditNoteItem.item_total
        })),
        documentsReferenced: creditNote.credit_note_references?.length ? creditNote.credit_note_references?.map((creditNoteReference) => ({
          id: creditNoteReference.id,
          creditNoteId: creditNoteReference.credit_note_id,
          document: {
            id: creditNoteReference.document_reference_id,
            code: creditNoteReference.document_reference_type_code
          },
          paymentAmount: creditNoteReference.payment_amount
        })) : []
      }));
      dispatch(_getSuccess(creditNotes));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'creditNote/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const creditNoteItems = data.items.map((creditNoteItem) => ({
        item_id: creditNoteItem.item,
        quantity: Number(creditNoteItem.quantity),
        unit_code: creditNoteItem.unit,
        price: Number(creditNoteItem.price),
        tax_id: creditNoteItem.tax,
        discount: Number(creditNoteItem.discount)
      }));

      const creditNoteReferences = data.documentReference?.map((documentReference) => ({
        document_reference_id: documentReference.document.id,
        document_reference_type_code: documentReference.document.code,
        payment_amount: documentReference.paymentAmount
      }));

      const creditNote = {
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client,
        issue_reason_id: data.issueReason,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        items: creditNoteItems,
        references: creditNoteReferences
      };

      const response = await axios.post(`/companies/${companyId}/credit_notes`, creditNote);

      const newCreditNote = {
        id: response.data.id,
        clientName: response.data.customer.name,
        clientId: response.data.customer.id,
        issueReason: {
          id: response.data.issue_reason.id,
          code: response.data.issue_reason.code,
          description: response.data.issue_reason.description
        },
        serieCode: response.data.serial_number.code,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalTax: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        number: response.data.number,
        isVoided: response.data.is_voided,
        receiptsValue: response.data.receipts_values,
        paymentStatus: response.data.payment_status,
        items: response.data.items.map((responseItem) => ({
          id: responseItem.id,
          item: responseItem.item,
          quantity: responseItem.quantity,
          unit: responseItem.unit_code,
          price: responseItem.price,
          tax: responseItem.tax,
          discount: responseItem.discount,
          itemTotal: responseItem.item_total
        })),
        documentsReferenced: response.data.credit_note_references?.map((documentReference) => ({
          id: documentReference.id,
          creditNoteId: documentReference.credit_note_id,
          document: {
            id: documentReference.document_reference_id,
            code: documentReference.document_reference_type_code
          },
          paymentAmount: documentReference.payment_amount
        }))
      };

      analytics.dispatchEvent(events.creditNoteCreated());

      dispatch(_createOrUpdatingSuccess(newCreditNote));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const sendCreditNotesEmail = createAsyncThunk(
  'creditNote/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendCreditNotesEmail());

    try {
      await axios.post(`/companies/${companyId}/credit_notes/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.creditNoteEmailed());

      dispatch(_sendCreditNotesEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendCreditNotesEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'creditNote/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/credit_notes/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.creditNoteDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'creditNote/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/credit_notes/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setCreditNotesPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'creditNote/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { creditNotes } = getState().creditNotes;

      const updatedCreditNotes = creditNotes.map((creditNote) => {
        const creditNoteUpdated = data.find((i) => i.id === creditNote.id);

        if (creditNoteUpdated) {
          return { ...creditNote, receiptsValue: creditNote.receiptsValue + creditNoteUpdated.value };
        }

        return creditNote;
      });

      dispatch(_getSuccess(updatedCreditNotes));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
