import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Scrollbar from '../Scrollbar';

import { formatCurrency } from '../../util/formatCurrency';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const InvoiceItemListTable = (props) => {
  const { invoiceItems, onOpenForm, selectInvoiceItem, onDelete, currentCompany, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const handleClick = (event, invoiceItem) => {
    setAnchorEl(event.currentTarget);
    selectInvoiceItem(invoiceItem);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleEdit = () => {
    handleClose();
    onOpenForm();
  };
  const handleDelete = () => {
    handleClose();
    onDelete();
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Item
                </TableCell>
                <TableCell>
                  Unidade
                </TableCell>
                <TableCell>
                  Quantidade
                </TableCell>
                <TableCell>
                  Preço
                </TableCell>
                <TableCell>
                  {taxOrRetentionLabels.taxTypeLabel}
                </TableCell>
                <TableCell>
                  Desconto
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceItems && invoiceItems.map((invoiceItem) => (
                <TableRow
                  hover
                  key={invoiceItem.id}
                >
                  <TableCell onClick={(e) => handleClick(e, invoiceItem)}>
                    <ArrowDropDownIcon />
                  </TableCell>
                  <TableCell>
                    {invoiceItem.itemCode}
                  </TableCell>
                  <TableCell>
                    {invoiceItem.unit}
                  </TableCell>
                  <TableCell>
                    {invoiceItem.quantity}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoiceItem.price)}
                    $
                  </TableCell>
                  <TableCell>
                    {invoiceItem.taxName}
                    {' - '}
                    {invoiceItem.taxValue}
                    %
                  </TableCell>
                  <TableCell>
                    {invoiceItem.discount || 0}
                    %
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoiceItem.total)}
                    $
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                id="document-form-add-item"
                hover
                onClick={onOpenForm}
              >
                <TableCell
                  colSpan={8}
                  align="center"
                >
                  <Typography
                    color="textSecondary"
                    variant="overline"
                    sx={{
                      color: 'rgba(0, 0, 0, 0.38)'
                    }}
                  >
                    Novo Item
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          Editar
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          Apagar
        </MenuItem>
      </Menu>
    </Card>
  );
};

InvoiceItemListTable.propTypes = {
  onOpenForm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  selectInvoiceItem: PropTypes.func.isRequired,
  invoiceItems: PropTypes.arrayOf({
    discount: PropTypes.number,
    item: PropTypes.string,
    tax: PropTypes.string,
    taxValue: PropTypes.number,
    taxName: PropTypes.number,
    price: PropTypes.string,
    quantity: PropTypes.number,
    unit: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

export default InvoiceItemListTable;
