import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import rawAxios from 'axios';
import axiosLess from '../lib/axiosLess';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import { SLICE_TEXTS } from '../constants';

const initialState = {
  isLoading: false,
  onlineStore: null,
  errorMessage: null,
  successMessage: null,
  isLoadingCreate: false
};

const slice = createSlice({
  name: 'onlineStores',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getStoreSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.onlineStore = payload;
    },
    _getStoreFailure: (state, { payload }) => {
      state.isLoading = false;
      state.onlineStore = null;
      state.errorMessage = payload;
    },
    _create: (state) => {
      state.isLoadingCreate = true;
    },
    _createStoreSuccess: (state) => {
      state.isLoadingCreate = false;
    },
    _createStoreFailure: (state, { payload }) => {
      state.errorMessage = payload;
      state.successMessage = null;
      state.isLoadingCreate = false;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
      state.errorMessage = null;
    },
    _clearMessages: (state) => {
      state.successMessage = null;
      state.errorMessage = null;
    }
  }
});

const {
  _get,
  _getStoreSuccess,
  _getStoreFailure,
  _create,
  _createStoreSuccess,
  _createStoreFailure,
  _clearMessages,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'onlineStores/fetch',
  async (_, { getState, dispatch }) => {
    dispatch(_get());
    try {
      const { id } = getState().companies.currentCompany;

      const { data } = await axios.get(`/online_stores/${id}`);

      const onlineStore = {
        eCommerceName: data.ecommerce_name,
        facebookURL: data.facebook_url,
        instagramURL: data.instagram_url,
        subdomain: data.subdomain,
        subscriptionStatus: data.subscription_status,
        coverURL: data.cover_url,
        logoURL: data.logo_url,
        email: data.email,
        phoneNumber: data.phone_number,
        status: data.status
      };

      dispatch(_getStoreSuccess(onlineStore));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_getStoreFailure(errorMessage));
    }
  }
);

export const create = createAsyncThunk(
  'onlineStores/create',
  async (data, { dispatch, getState }) => {
    const { id } = getState().companies.currentCompany;

    const body = {
      subdomain: data.subdomain,
      ecommerce_name: data.eCommerceName,
      facebook_url: data.facebookURL ? data.facebookURL : undefined,
      instagram_url: data.instagramURL ? data.instagramURL : undefined
    };

    dispatch(_create());

    try {
      await axios.patch(`/companies/${id}`, { online_store: body });

      await Promise.all(data.images?.map(async (image) => {
        const { data: presignedData } = await axiosLess.get(`/online_stores/${id}/images/${encodeURIComponent(image.file.name)}/presigned_url`);

        await rawAxios.put(presignedData.presigned_url, image.file);

        const imageInfo = {
          type: image.type,
          file_name: image.file.name
        };

        await axiosLess.post(`/online_stores/${id}/images`, imageInfo);
      }));

      dispatch(_createStoreSuccess());
      dispatch(_setSuccessMessage(SLICE_TEXTS.E_COMMERCE.SUCCESS_MESSAGE));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createStoreFailure(errorMessage));
    }
  }
);

export const clearMessages = createAsyncThunk(
  'onlineStores/clear_messages',
  async (_, { dispatch }) => {
    dispatch(_clearMessages());
  }
);
