import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import Scrollbar from '../Scrollbar';

const ClientListTable = (props) => {
  const { clients, onOpenClientDetail, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Nome Fiscal
                </TableCell>
                <TableCell>
                  NIF do Cliente
                </TableCell>
                <TableCell>
                  País
                </TableCell>
                <TableCell>
                  Endereço
                </TableCell>
                <TableCell>
                  Telefone
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {clients?.map((client) => (
                <TableRow
                  hover
                  key={client.id}
                  onClick={() => onOpenClientDetail(client)}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {client.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {client.fiscalName}
                  </TableCell>
                  <TableCell>
                    {client.nif}
                  </TableCell>
                  <TableCell>
                    {client.countryName}
                  </TableCell>
                  <TableCell>
                    {client.address}
                  </TableCell>
                  <TableCell>
                    {`${client.phoneNumber?.country_code || ''} ${client.phoneNumber?.phone_number || ''}`}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

ClientListTable.propTypes = {
  clients: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    nif: PropTypes.number,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    internal: PropTypes.bool,
    isIndividual: PropTypes.bool,
    phoneNumber: PropTypes.shape({
      phone_number: PropTypes.string,
      country_code: PropTypes.string
    })
  }).isRequired,
  onOpenClientDetail: PropTypes.func.isRequired
};

export default ClientListTable;
