import React from 'react';
import PropsTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { formatCurrency } from '../../util/formatCurrency';

const DisplayBalance = (props) => {
  const { label, balance } = props;

  return (
    <Box>
      <Typography
        id="current-bank-withdrawal-balance"
        color="textSecondary"
        variant="subtitle2"
        sx={{ display: 'flex', justifyContent: 'right' }}
      >
        {label}
        {' '}
        {formatCurrency(balance)}
      </Typography>
    </Box>
  );
};

DisplayBalance.propTypes = {
  label: PropsTypes.string.isRequired,
  balance: PropsTypes.number.isRequired
};
export default DisplayBalance;
