import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';

import ProviderListTable from '../../components/provider/ProviderListTable';
import ProviderForm from '../../components/provider/ProviderForm';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchProviders } from '../../slices/providers';
import Toast from '../../components/Toast';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const Provider = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);

  const { settings } = useSettings();
  const dispatch = useDispatch();

  const { providers, isLoading, creating, creatingError, creatingSuccessMessage, creatingErrorMessage } = useSelector((state) => state).providers;
  const { currentCompany } = useSelector((state) => state).companies;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchProviders());
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  function returnResult() {
    if (providers && providers.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <ProviderListTable
            providers={providers}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Fornecedor"
        buttonText="Criar Fornecedor"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!creating && creating !== undefined) && (!creatingError && creatingError !== undefined)) {
      handleSetMessage(creatingSuccessMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (creatingError) {
      handleSetMessage(creatingErrorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [creating, creatingError, creatingSuccessMessage, creatingErrorMessage]);

  return (
    <>
      <Helmet>
        <title>Fornecedores | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Fornecedores
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Meus Fornecedores
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
              <ProviderForm
                onCancel={handleModalClose}
                onSubmitForm={handlePopUpOpen}
              />
            ) }
          </Dialog>
        </Container>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default Provider;
