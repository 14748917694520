import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Dialog, DialogContent, Button, Box, TextField, FormLabel, useMediaQuery } from '@mui/material';
import { PAYMENT_SERVICES } from '../../constants';
import NumericTextField from '../NumericTextField';

const PaymentMethodForm = (props) => {
  const {
    handleDispatchPayDocument,
    isPaymentFormOpen,
    setIsPaymentFormOpen,
    debtAmount,
    setAmountPaid
  } = props;

  const initialValues = {
    email: '',
    amount: ''
  };

  const calculateAmount = (percentageAmount) => Math.round(debtAmount * (percentageAmount / 100));

  const handlePaymentMethodFormClose = () => {
    setIsPaymentFormOpen(false);
  };

  const handleSubmitToPay = (emailValue, amount) => {
    setAmountPaid(calculateAmount(amount));
    handleDispatchPayDocument({ paymentService: PAYMENT_SERVICES.stripe, email: emailValue, amount: calculateAmount(amount) });
    handlePaymentMethodFormClose();
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog
      onClose={handlePaymentMethodFormClose}
      open={isPaymentFormOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: '33rem'
        }
      }}
    >
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup
            .object()
            .shape(
              {
                email: Yup
                  .string()
                  .email('Digite um endereço de email válido')
                  .max(255)
                  .required('Obrigatório'),
                amount: Yup
                  .number()
                  .max(100, 'Valor máximo 100%')
                  .min(1, 'Valor mínimo 1%')
                  .required('Obrigatório')
              }
            )}
          onSubmit={async (values) => {
            handleSubmitToPay(values.email, values.amount);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              id="send-form-to-pay-document"
              onSubmit={handleSubmit}
            >
              <Box sx={{ pb: 2 }}>
                <FormLabel>Insira as informacões abaixo antes de prosseguir para o pagamento:</FormLabel>
                <TextField
                  id="send-form-to-pay-document-email-field"
                  error={Boolean(touched.email && errors.email)}
                  sx={{ mt: 2 }}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email*"
                  name="email"
                  placeholder="Introduzir Email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values?.email}
                  variant="outlined"
                />
                <TextField
                  id="send-form-to-pay-document-debt-amount-field"
                  fullWidth
                  sx={{ mt: 2 }}
                  label="Valor em divida"
                  name="debtAmount"
                  disabled
                  value={debtAmount}
                  variant="outlined"
                />
                <NumericTextField
                  id="send-form-to-pay-document-amount-field"
                  error={Boolean(touched.amount && errors.amount)}
                  helperText={touched.amount && errors.amount}
                  fullWidth
                  sx={{ mt: 2 }}
                  label="Valor a pagar (%)*"
                  name="amount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.amount}
                  variant="outlined"
                />
                <TextField
                  id="send-form-to-pay-document-debt-amount-field"
                  fullWidth
                  sx={{ mt: 2 }}
                  label="Pendente"
                  disabled
                  value={debtAmount - calculateAmount(values?.amount)}
                  variant="outlined"
                />
                <Box sx={{ mt: 3, display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between' }}>
                  <Button
                    id="send-form-to-pay-document-cancel-button"
                    size="large"
                    variant="outlined"
                    onClick={handlePaymentMethodFormClose}
                    sx={{ width: isMobile ? '100%' : '13rem', mt: isMobile ? 1 : 0 }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    id="send-form-to-pay-document-submit-button"
                    size="large"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                    sx={{ width: isMobile ? '100%' : '13rem' }}
                  >
                    Continuar
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

PaymentMethodForm.propTypes = {
  handleDispatchPayDocument: PropTypes.func.isRequired,
  isPaymentFormOpen: PropTypes.bool.isRequired,
  setIsPaymentFormOpen: PropTypes.func.isRequired,
  debtAmount: PropTypes.number.isRequired,
  setAmountPaid: PropTypes.func.isRequired
};

export default PaymentMethodForm;
