import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { create as createUnit, update as updateUnit } from '../../slices/units';
import { useDispatch } from '../../store';

const initialValues = {
  code: undefined,
  description: undefined
};

const UnitForm = (props) => {
  const { onCancel, isAdd, selectedUnit, onSubmitForm } = props;
  const dispatch = useDispatch();

  const selectedUnitValues = {
    code: selectedUnit?.code,
    description: selectedUnit?.description
  };
  return (
    <Formik
      initialValues={isAdd ? initialValues : selectedUnitValues}
      validationSchema={Yup
        .object()
        .shape({
          code: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          description: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitForm();
          if (!isAdd) {
            values.unitId = selectedUnit.id;
            dispatch(updateUnit(values));
          } else {
            dispatch(createUnit(values));
          }

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {isAdd ? 'Nova Unidade' : 'Editar Unidade'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              autoFocus
              error={Boolean(touched.code && errors.code)}
              fullWidth
              helperText={touched.code && errors.code}
              label="Código*"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.description && errors.description)}
                fullWidth
                helperText={touched.description && errors.description}
                label="Descrição*"
                name="description"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
                variant="outlined"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {isAdd ? 'Criar' : 'Guardar'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

UnitForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  // selectedUnit can be null if the isAdd is true
  selectedUnit: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string
  })
};
UnitForm.defaultProps = {
  selectedUnit: {}
};
export default UnitForm;
