export default function errorMessageHandler(error) {
  let errorMessage = '';

  if (error.response && error.response.data.error) {
    errorMessage = error.response.data.error;
  } else if (error.request) {
    errorMessage = 'Error na conexão ao servidor, por favor tente novamente';
  } else {
    errorMessage = 'Algo deu errado :(';
  }
  return errorMessage;
}
