import calculateWithdrawalFee from './calculateWithdrawalFee';

/**
 * Calculates the amount after subtracting withdrawal fee from the value.
 * @param {number} value - The initial value before the withdrawal fee has been applied.
 * @param fee value - The fee to be applied
 * @returns {number} - The amount after subtracting the calculated withdrawal fee from the initial value.
 */
export default function calculateAmountAfterFee(value, fee) {
  return value - calculateWithdrawalFee(value, fee);
}
