import { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { ForgotPasswordForm } from '../../components/authentication/forgotPassword';
import { useDispatch, useSelector } from '../../store';
import { forgotPassword } from '../../slices/users';
import { TOAST_TYPE } from '../../constants';
import Toast from '../../components/Toast';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const { onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.users);

  const navigateToLoginPage = useCallback(() => {
    navigate('/authentication/login');
  }, []);

  const handleRegisterButtonClick = useCallback(() => {
    navigate('/authentication/register');
  }, []);

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== null) && (!onRequestFailure && onRequestFailure !== null)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      setIsDisabled(true);
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    }
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  const handleSubmit = (email) => {
    dispatch(forgotPassword(email));
    handlePopUpOpen();
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Restaurar Senha | Samba</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Restaurar Senha
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Esqueceu a sua senha? Introduza o seu email para inicar o processo de restaurar senha.
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                <ForgotPasswordForm
                  onSubmitForm={handleSubmit}
                  isDisabled={isDisabled}
                  navigateToLoginPage={navigateToLoginPage}
                  handleRegisterButtonClick={handleRegisterButtonClick}
                />
                <Toast
                  isOpen={isPopUpOpen}
                  toastType={toastType}
                  message={message}
                  onClose={handlePopUpClose}
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default ForgotPassword;
