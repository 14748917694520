import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  transactions: [],
  onRequest: null,
  onRequestSuccess: null,
  onRequestFailure: null
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    _get: (state) => {
      state.onRequest = true;
      state.onRequestSuccess = null;
      state.onRequestFailure = null;
    },
    _getTransactionSuccess: (state, { payload }) => {
      state.transactions = payload;
      state.onRequest = false;
      state.onRequestSuccess = true;
      state.onRequestFailure = false;
    },
    _getTransactionFailure: (state) => {
      state.transactions = [];
      state.onRequest = false;
      state.onRequestSuccess = false;
      state.onRequestFailure = true;
    }
  }
});

const {
  _get,
  _getTransactionSuccess,
  _getTransactionFailure
} = slice.actions;

export default slice.reducer;

export const fetchAllTransactions = createAsyncThunk(
  'transaction/fetch_all_transactions',
  async (_, { dispatch, getState }) => {
    dispatch(_get());
    try {
      const companyId = getState().companies.currentCompany.id;

      const { data } = await axios.get(`/companies/${companyId}/payments?item_type=POS_TRANSACTION`);

      dispatch(_getTransactionSuccess(data));
    } catch (error) {
      dispatch(_getTransactionFailure());
    }
  }
);
