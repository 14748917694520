import { COMPANY_TYPES } from '../constants';

function calculateDiscount(invoiceItems = [], companyType = COMPANY_TYPES[1].id) {
  let total = 0;

  invoiceItems.map((invoiceItem) => {
    let sum = 0;
    if (companyType === COMPANY_TYPES[0].name) {
      sum = (invoiceItem.price * invoiceItem.quantity) * (1 - (invoiceItem.taxValue / 100)) * (invoiceItem.discount / 100);
    } else {
      sum = (invoiceItem.price * invoiceItem.quantity) * (1 + (invoiceItem.taxValue / 100)) * (invoiceItem.discount / 100);
    }
    total += sum;
    return total;
  });

  return total;
}

export default calculateDiscount;
