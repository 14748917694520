import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Container,
  Dialog,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography
} from '@mui/material';
import { includes } from 'lodash';
import { isAfter, isBefore, format, addDays, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import useSettings from '../../hooks/useSettings';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, POPUP_CONTENT_MESSAGE, EFATURA_BANNER_MESSAGE } from '../../constants';

import ClientListTable from '../../components/clients/ClientListTable';
import ClientForm from '../../components/clients/ClientForm';
import ClientViewDetails from '../../components/clients/ClientViewDetails';
import { useDispatch, useSelector } from '../../store';
import { create as createClient, update as updateClient, fetch as fetchClients, remove as deleteClient } from '../../slices/clients';
import { fetch as fetchCountries } from '../../slices/countries';
import { voidInvoice, sendInvoiceEmail, print as printInvoice } from '../../slices/invoices';
import DeletePopUp from '../../components/DeletePopUp';
import Scrollbar from '../../components/Scrollbar';
import TabPanel from '../../components/TabPanel';
import ExtractTable from '../../components/clients/ExtractTable';
import InvoiceViewDetails from '../../components/documents/ViewDetails';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';
import CustomDatePicker from '../../components/CustomDatePicker';

const FiscalYear = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isViewClientModalOpen, setIsViewClientModalOpen] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [filterClients, setFilterClients] = useState(null);
  const [selectedClients, setSelectedClients] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const { settings } = useSettings();
  const dispatch = useDispatch();

  const { clients, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).clients;
  const { currentCompany } = useSelector((state) => state).companies;
  const { countries, loading } = useSelector((state) => state).countries;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isViewInvoiceModalOpen, setIsViewInvoiceModalOpen] = useState(false);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchClients());
      dispatch(fetchCountries());
    }
  }, [dispatch, currentCompany]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUpdateSelectedClient = () => {
    if (selectedClient) {
      const updatedClient = clients.find((client) => client.id === selectedClient.id);
      setSelectedClient(updatedClient);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handleCreateOrUpdateClient = (client) => {
    handlePopUpOpen();
    if (isViewClientModalOpen) {
      dispatch(updateClient(client));
    } else {
      dispatch(createClient(client));
    }
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
    handleUpdateSelectedClient();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleClientDetailOpen = (client) => {
    setSelectedClient(client);
    setIsViewClientModalOpen(true);
  };

  const handleClientDetailClose = () => {
    setSelectedClient([]);
    setIsViewClientModalOpen(false);
  };

  const handleClientDelete = () => {
    handleClientDetailClose();
    handleDeletePopUpClose();
    dispatch(deleteClient(selectedClient));
    handlePopUpOpen();
    setFilterClients();
  };

  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');

  const handleInvoiceDetailOpen = (invoice) => {
    setSelectedInvoice(invoice);
    setIsViewInvoiceModalOpen(true);
  };

  const handleInvoiceDetailClose = () => {
    setSelectedInvoice([]);
    setIsViewInvoiceModalOpen(false);
  };

  useEffect(() => {
    if (selectedClients) {
      const newClientFilterList = selectedClients.map((filterClient) => {
        const clientSelected = clients?.find(
          (client) => includes(client.name.toLowerCase(), filterClient.name?.toLowerCase())
        );

        if (startDate && endDate) {
          const invoices = clientSelected?.invoices?.filter(
            (invoice) => (isBefore(new Date(invoice.date), endDate) && isAfter(new Date(invoice.date), addDays(startDate, -1)))
          );

          return { ...clientSelected, invoices };
        }

        return clientSelected;
      });

      setFilterClients(
        newClientFilterList
      );
    } else {
      setFilterClients(clients);
    }
  }, [selectedClients, startDate, endDate]);

  function returnResult() {
    if (clients && clients.length > 0) {
      return (
        <Box>
          <ClientListTable
            clients={selectedClients?.length > 0 ? filterClients : clients}
            onOpenClientDetail={handleClientDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        primaryButtonId="create-new-client-button"
        title="Não tens nenhum Cliente"
        buttonText="Criar Cliente"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  /**
   * Send Email
   * @param {string} email - Email to send the Invoice email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendInvoiceEmail({ id: selectedInvoice.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const handlePrintInvoice = () => {
    dispatch(printInvoice(selectedInvoice));
  };

  const handleInvoiceVoid = () => {
    handleInvoiceDetailClose();
    handleDeletePopUpClose();
    handlePopUpOpen();
    dispatch(voidInvoice(selectedInvoice));
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  const handleDateChangeStartDate = (newValue) => {
    setStartDate(newValue.toDate());
  };

  const handleDateChangeEndDate = (newValue) => {
    setEndDate(newValue.toDate());
  };

  const [initialPeriod, setInitialPeriod] = useState();
  const [finalPeriod, setFinalPeriod] = useState();

  return (
    <>
      <Helmet>
        <title>Clientes | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Clientes
              </Typography>
            </Grid>
            <Grid item>
              <Button
                id="create-new-client"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ mt: 3 }}
          >
            <Tab
              label="MEUS CLIENTES"
            />
            <Tab
              label="EXTRATO"
            />
          </Tabs>
          {(clients && clients.length > 0) && (
          <Autocomplete
            fullWidth
            multiple
            options={clients}
            sx={{
              mt: 3
            }}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            onChange={(e, value) => setSelectedClients(value)}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={(
                    <CheckBoxIcon
                      fontSize="small"
                      color="primary"
                    />
                  )}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            value={selectedClients || []}
            renderTags={(values) => values.map(((value) => (
              <Chip
                size="medium"
                variant="outlined"
                label={value.name}
                sx={{
                  backgroundColor: 'rgba(52, 183, 206, 0.1)',
                  border: '1px solid rgba(52, 183, 206, 0.2)',
                  color: 'primary.main'
                }}
                style={{ marginRight: 8 }}
              />
            )))}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                variant="outlined"
                placeholder="Cliente"
              />
            )}
          />
          )}
          <TabPanel
            value={tabValue}
            index={0}
          >
            <Box>
              {isLoading
                ? (
                  <LoadingPopUp />
                ) : returnResult()}
            </Box>
          </TabPanel>
          <TabPanel
            value={tabValue}
            index={1}
          >
            <Box>
              {(selectedClients && selectedClients.length > 0) && (
                <Box sx={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  gap: 1
                }}
                >
                  <Box>
                    <CustomDatePicker
                      label="Período Inicial*"
                      onChange={(newValue) => {
                        setInitialPeriod(newValue)
                        handleDateChangeStartDate(newValue)}
                      }
                      values={initialPeriod}
                    />
                  </Box>
                  <Box>
                    <CustomDatePicker
                      label="Período Final*"
                      onChange={(newValue) =>{
                        setFinalPeriod(newValue)
                        handleDateChangeEndDate(newValue)}
                      }
                      values={finalPeriod}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            {(filterClients && filterClients?.length > 0)
              ? filterClients?.map((client) => (
                <Box py={1} key={client.id}>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    {client.name}
                    {(startDate && endDate) && (
                      `- ${format(startDate, 'LLLL', { locale: ptBR })} a ${format(endDate, 'LLLL', { locale: ptBR })}`
                    )}
                  </Typography>
                  <ExtractTable
                    invoices={client.invoices}
                    onOpenInvoiceDetails={handleInvoiceDetailOpen}
                    receipts={client.receipts}
                    formatDate={formatDate}
                  />
                </Box>
              ))
              : clients?.map((client) => (
                <Box py={1} key={client.id}>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    {client.name}
                    {(startDate && endDate) && (
                      `- ${format(startDate, 'LLLL', { locale: ptBR })} a ${format(endDate, 'LLLL', { locale: ptBR })}`
                    )}
                  </Typography>
                  <ExtractTable
                    invoices={client.invoices}
                    formatDate={formatDate}
                    onOpenInvoiceDetails={handleInvoiceDetailOpen}
                    receipts={client.receipts}
                  />
                </Box>
              ))}

          </TabPanel>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && !loading && (
              <ClientForm
                countries={countries}
                onCancel={handleModalClose}
                onSubmitForm={handleCreateOrUpdateClient}
                selectedClient={selectedClient}
                isAdd={!isViewClientModalOpen}
              />
            )}
          </Dialog>
        </Container>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleClientDetailClose}
          open={isViewClientModalOpen}
        >
          <Scrollbar>
            {isViewClientModalOpen && (
            <ClientViewDetails
              client={selectedClient}
              onEdit={handleNewClick}
              onDelete={handleDeletePopUpOpen}
              onCancel={handleClientDetailClose}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            onCancel={handleDeletePopUpClose}
            onDelete={handleClientDelete}
          />
          )}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleInvoiceDetailClose}
          open={isViewInvoiceModalOpen}
        >
          <Scrollbar>
            {isViewInvoiceModalOpen && (
              <InvoiceViewDetails
                title={selectedInvoice.documentType.name}
                document={{ ...selectedInvoice, dateFormatted: format(parseISO(selectedInvoice.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedInvoice.date), selectedInvoice.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
                onCancel={handleInvoiceDetailClose}
                onVoid={handleDeletePopUpOpen}
                handleSendEmail={handleSendEmail}
                handlePrintButtonClick={handlePrintInvoice}
                currentCompany={currentCompany}
              />
            )}
          </Scrollbar>
        </Dialog>
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            message={POPUP_CONTENT_MESSAGE.VOID.MESSAGE}
            action={POPUP_CONTENT_MESSAGE.VOID.ACTION}
            onCancel={handleDeletePopUpClose}
            onDelete={handleInvoiceVoid}
          />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default FiscalYear;
