import React from 'react';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

const InfoPair = ({ label, value, id }) => (
  <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
    <Typography color="textSecondary">
      {label}
    </Typography>
    <Typography
      color="textSecondary"
      fontWeight="medium"
      id={id}
    >
      {value}
    </Typography>
  </Box>
);

InfoPair.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  id: PropTypes.string.isRequired
};

export default InfoPair;
