import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CustomCard from '../CustomCard';
import Container from '../Container';

export const WhySamba = (props) => {
  const { handleScheduleDemo } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'relative'
      }}
      component="section"
    >
      <Container>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          height: 'auto',
          mt: 6
        }}
        >
          <Typography
            color={theme.typography.homePageTextSecondary}
            sx={{
              textAlign: 'center'
            }}
          >
            Consegue imaginar um sistema tão difícil que teria de ser treinado
            para saber utilizá-lo? Sejamos realistas! Causamos uma boa primeira
            e duradoura impressão com o nosso sistema simples e bonito.
          </Typography>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mt: 6,
            justifyContent: 'space-between',
            flexWrap: mobileDevice ? 'wrap-reverse' : 'unset',
            gap: '2rem'
          }}
          >
            <Box sx={{
              display: 'flex',
              flex: mobileDevice ? 'unset' : 1
            }}
            >
              <CustomCard
                title={'Sem necessidade de \n treinamento!'}
                message="O nosso sistema é extremamente simples fazendo-o
                 economizar tempo e dinheiro!"
                color={theme.palette.purple.light}
              />
            </Box>
            <Box sx={{
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              flex: mobileDevice ? 'unset' : 1.4
            }}
            >
              <Box
                alt="Receipt Image"
                component="img"
                src="/static/homepage/receipt.svg"
                sx={{
                  maxWidth: '100%',
                  height: 'auto',
                  background: '#fff',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                }}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mt: 6,
            justifyContent: 'space-between',
            flexWrap: mobileDevice ? 'wrap-reverse' : 'unset',
            gap: '2rem'
          }}
          >
            <Box sx={{
              display: 'flex',
              flex: mobileDevice ? 'unset' : 1.4
            }}
            >
              <Box
                alt="Signup"
                component="img"
                src="/static/homepage/signup-form.svg"
                sx={{
                  height: 'auto',
                  maxWidth: '100%'
                }}
              />
            </Box>
            <Box sx={{
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              flex: mobileDevice ? 'unset' : 1
            }}
            >
              <CustomCard
                title={'Não vamos gastar o seu \n tempo com representantes de \n '
                  + 'venda.'}
                message="Crie a sua conta gratuitamente e comece a utilizar o
                   Samba em segundos. Já poupa na comissão que pagaria. 😉"
                color={theme.palette.purple.light}
              />
            </Box>
          </Box>

          <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            mt: 6,
            justifyContent: 'space-between',
            flexWrap: mobileDevice ? 'wrap-reverse' : 'unset',
            gap: '2rem'
          }}
          >
            <Box sx={{
              display: 'flex',
              flex: mobileDevice ? 'unset' : 1
            }}
            >
              <CustomCard
                title="100% na Cloud"
                message="Dirija o seu negócio apenas navegando na internet!
                  Dirija o seu negócio apenas navegando na internet!"
                color={theme.palette.purple.light}
              />
            </Box>
            <Box sx={{
              maxWidth: '100%',
              display: 'flex',
              justifyContent: 'center',
              flex: mobileDevice ? 'unset' : 1.4
            }}
            >
              <Box
                alt="Cloud"
                component="img"
                src="/static/homepage/cloud.svg"
                sx={{
                  height: 'auto',
                  maxWidth: '100%'
                }}
              />
            </Box>
          </Box>
        </Box>

        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          mt: 6,
          mb: 6
        }}
        >
          <Typography
            color={theme.typography.homePageTextSecondary}
            sx={{
              textAlign: 'center'
            }}
          >
            Um software de gestão empresarial não deve exigir muito dos gestores.
            O nosso sistema intuitivo permite que o foco seja no que realmente
            importa - a sua empresa.
          </Typography>
          <a
            href="https://calendly.com/chuva-nilson/samba-demo"
            target="_blank"
            rel="noreferrer"
            onClick={handleScheduleDemo}
          >
            <Button
              alt="Marcar Demo Grátis"
              color="primary"
              type="button"
              variant="contained"
              size="small"
              sx={{
                mt: 6,
                borderRadius: 4
              }}
            >
              <Typography
                variant="textSecondary"
                color="#FAFCFF"
                sx={{
                  fontFamily: 'Gill Sans',
                  fontWeight: 600,
                  fontSize: 16,
                  pt: 0.5
                }}
              >
                Marcar Demo Grátis
              </Typography>
            </Button>
          </a>
        </Box>
      </Container>
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-12.svg"
        sx={{
          position: 'absolute',
          left: -160,
          top: -150,
          opacity: 1
        }}
      />
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-13.svg"
        sx={{
          position: 'absolute',
          top: mobileDevice ? 100 : 0,
          right: 0,
          bottom: 100,
          opacity: 1
        }}
      />
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-13.svg"
        sx={{
          position: 'absolute',
          right: 0,
          top: 950,
          opacity: 1
        }}
      />
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-12.svg"
        sx={{
          position: 'absolute',
          left: -160,
          top: 650,
          opacity: 1
        }}
      />
    </Box>
  );
};
WhySamba.propTypes = {
  handleScheduleDemo: PropTypes.func
};
