import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import { useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';

const ReceiptItemList = (props) => {
  const { onOpenForm, invoiceList, onSelectInvoice, onDelete, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const handleClick = (event, invoice) => {
    setAnchorEl(event.currentTarget);
    setSelectedInvoice(invoice);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    onSelectInvoice(selectedInvoice);
    onOpenForm();
    handleClose();
  };

  const handleDelete = () => {
    onSelectInvoice(selectedInvoice);
    onDelete();
    handleClose();
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>
                  Fatura
                </TableCell>
                <TableCell>
                  Valor em Dívida
                </TableCell>
                <TableCell>
                  Valor a ser Pago
                </TableCell>
                <TableCell>
                  Pendente
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceList?.map((invoice) => (
                <TableRow
                  hover
                  key={invoice.info.id}
                >
                  <TableCell onClick={(e) => handleClick(e, invoice)}>
                    <ArrowDropDownIcon />
                  </TableCell>
                  <TableCell>
                    {invoice.info.clientName}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoice.valueInDebt)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoice.paymentValue)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoice.pendent)}
                  </TableCell>
                </TableRow>
              ))}
              <TableRow
                id="receipt-item-list-add-button"
                hover
                onClick={onOpenForm}
              >
                <TableCell />
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    Nova Fatura
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    {formatCurrency(0.00)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    {formatCurrency(0.00)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    color="textSecondary"
                    variant="overline"
                  >
                    {formatCurrency(0.00)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleEdit}>
          Editar
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          Apagar
        </MenuItem>
      </Menu>
    </Card>
  );
};

ReceiptItemList.propTypes = {
  onOpenForm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onSelectInvoice: PropTypes.func.isRequired,
  invoiceList: PropTypes.arrayOf({
    info: PropTypes.shape({
      id: PropTypes.string,
      clientName: PropTypes.string
    }),
    valueInDebt: PropTypes.number,
    paymentValue: PropTypes.number,
    pendent: PropTypes.number
  }).isRequired
};

export default ReceiptItemList;
