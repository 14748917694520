import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

const MessageBox = (props) => {
  const { message, color } = props;

  return (
    <Chip
      id="message-box"
      sx={{
        py: 1.8,
        width: '100%',
        color,
        borderColor: color,
        height: 'auto',
        '& .MuiChip-label': {
          display: 'block',
          whiteSpace: 'normal'
        },
        justifyContent: 'start'
      }}
      label={message}
      variant="outlined"
    />
  );
};

MessageBox.propTypes = {
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default MessageBox;
