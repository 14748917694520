import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

let companyId;

const initialState = {
  products: null,
  isLoading: false
};

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.products = payload;
      state.isLoading = false;
    },
    _getFailure: (state) => {
      state.products = null;
      state.isLoading = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'products/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/products`);

      const products = data.map((product) => ({
        id: product.id,
        name: product.name,
        sellingPrice: product.selling_price,
        description: product.description,
        images: product.images,
        tax: {
          value: product.tax.value
        }
      }));

      dispatch(_getSuccess(products));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const fetchCompanyProducts = createAsyncThunk(
  'products/fetch_company_products',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const { data } = await axios.get('/companies/me/products');

      const products = data.map((product) => ({
        id: product.id,
        name: product.name,
        sellingPrice: product.selling_price,
        description: product.description,
        images: product.images,
        tax: {
          value: product.tax.value
        }
      }));

      dispatch(_getSuccess(products));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
