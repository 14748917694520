import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Autocomplete,
  InputAdornment
} from '@mui/material';

import { useEffect } from 'react';
import { create as createProvider } from '../../slices/providers';
import { fetch as fetchCountries } from '../../slices/countries';

import { useDispatch, useSelector } from '../../store';

import NumericTextField from '../NumericTextField';
import { CAPE_VERDE_COUNTRY_CODE } from '../../constants';

const initialValues = {
  name: undefined,
  type: 'internal',
  fiscalName: undefined,
  nif: undefined,
  country: undefined,
  isIndividual: 'singular',
  address: null,
  phoneNumber: null,
  country_code: CAPE_VERDE_COUNTRY_CODE
};

const ProviderForm = (props) => {
  const { onCancel, onSubmitForm } = props;
  const dispatch = useDispatch();

  const { countries } = useSelector((state) => state.countries);

  useEffect(() => {
    dispatch(fetchCountries());
  }, [dispatch]);

  const getCountryCode = (countryId) => {
    if (!countryId) {
      return initialValues.country_code;
    }

    const getCountry = countries.filter((country) => countryId === country.id);

    return getCountry[0]?.country_code;
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={Yup
        .object()
        .shape({
          name: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          fiscalName: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          nif: Yup
            .string()
            .matches(/^[0-9]+$/, 'Deve conter exatamente 9 dígitos')
            .length(9, 'Deve conter exatamente 9 dígitos')
            .trim()
            .required('Obrigatório'),
          country: Yup
            .string()
            .trim()
            .required('Obrigatório'),
          isIndividual: Yup
            .string()
            .nullable()
            .required('Obrigatório'),
          type: Yup
            .string()
            .nullable()
            .required('Obrigatório'),
          address: Yup
            .string()
            .nullable()
            .required('Obrigatório'),
          phoneNumber: Yup
            .string()
            .nullable()
            .matches(/^[0-9]+$/, 'Pode conter apenas dígitos')
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            name: values.name.trim(),
            internal: values.type === 'internal',
            fiscal_name: values.fiscalName,
            tax_id: String(values.nif),
            country_id: values.country,
            is_individual: values.isIndividual === 'singular',
            address: values.address,
            phone_numbers: [
              {
                phone_number: values.phoneNumber,
                country_code: values.country_code
              }
            ]
          };

          onSubmitForm();
          await dispatch(createProvider(data));

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              Novo Fornecedor
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              autoFocus
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Nome*"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.fiscalName && errors.fiscalName)}
                fullWidth
                helperText={touched.fiscalName && errors.fiscalName}
                label="Nome Fiscal*"
                name="fiscalName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.fiscalName}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <NumericTextField
                error={Boolean(touched.nif && errors.nif)}
                fullWidth
                helperText={touched.nif && errors.nif}
                label="NIF do Fornecedor*"
                name="nif"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.nif}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Autocomplete
                getOptionLabel={(option) => option.pt}
                options={countries}
                disableClearable
                onChange={(e, value) => {
                  setFieldValue('country', value?.id || '');
                  setFieldValue('country_code', getCountryCode(value?.id));
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    label="País"
                    name="country"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.address && errors.address)}
                fullWidth
                helperText={touched.address && errors.address}
                label="Endereço*"
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.address}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                fullWidth
                helperText={touched.phoneNumber && errors.phoneNumber}
                label="Telefone*"
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.phoneNumber}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{values?.country_code}</InputAdornment>
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormLabel>Tipo</FormLabel>
              <RadioGroup
                row
                name="type"
                value={values.type}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="internal"
                  control={<Radio />}
                  label="Interno"
                />
                <FormControlLabel
                  value="external"
                  control={<Radio />}
                  label="Externo"
                />
              </RadioGroup>
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormLabel>Entidade</FormLabel>
              <RadioGroup
                row
                name="isIndividual"
                value={values.isIndividual}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="singular"
                  control={<Radio />}
                  label="Singular"
                />
                <FormControlLabel
                  value="colective"
                  control={<Radio />}
                  label="Coletivo"
                />
              </RadioGroup>
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Guardar
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

ProviderForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ProviderForm;
