import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(3, 2),
    margin: theme.spacing(0, 2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  },
  icons: {
    width: '32px',
    [theme.breakpoints.down('sm')]: {
      width: '48px'
    }
  }
}));

export function Footer(props) {
  const { handleFacebookClick, handleInstagramClick, ...rest } = props;
  const classes = useStyles();

  return (
    <Box
      {...rest}
      component="footer"
    >
      <Box
        className={classes.footer}
      >
        <Box>
          <Typography
            variant="h6"
            color="#FFFFFF"
            sx={{
              fontWeight: 600,
              fontSize: 46
            }}
          >
            SAMBA
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
        >
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              my: 1,
              cursor: 'pointer'
            }}
            component="a"
            href="https://chuva-io.notion.site/Termos-de-servi-o-Samba-ff0c334a680a4bdaa9f994c6f1f5462e?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Termos e Condiçōes
          </Typography>
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              my: 1
            }}
          >
            samba@chuva.io
          </Typography>
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              my: 1
            }}
          >
            +238 353 35 17
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <a
              href="https://www.facebook.com/SAMBA-Cloud-108642664819245"
              target="_blank"
              rel="noreferrer"
              onClick={handleFacebookClick}
            >
              <Box
                alt="Facebook"
                component="img"
                src="/static/homepage/facebook-icon.svg"
                className={classes.icons}
              />
            </a>
            <a
              href="https://www.instagram.com/samba.cloud/"
              target="_blank"
              rel="noreferrer"
              onClick={handleInstagramClick}
            >
              <Box
                alt="Instagram"
                component="img"
                src="/static/homepage/instagram-icon.svg"
                className={classes.icons}
              />
            </a>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1
        }}
        >
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            sx={{
              fontSize: 16,
              fontWeight: 600,
              my: 1
            }}
          >
            Formas de Pagamento
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box
              alt="Vinti4 logo"
              component="img"
              src="/static/SISP/vinti4.png"
              sx={{
                width: 45
              }}
            />
            <Box
              alt="Visa Secure logo"
              component="img"
              src="/static/SISP/visaSecure.svg"
              sx={{
                width: 45
              }}
            />
            <Box
              alt="American Express logo"
              component="img"
              src="/static/SISP/americanExpress.jpg"
              sx={{
                width: 45
              }}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box
              alt="Mastercard logo"
              component="img"
              src="/static/SISP/mc.png"
              sx={{
                width: 150
              }}
            />
          </Box>
        </Box>
        <Box>
          <a
            href="https://chuva.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              alt="Powered by Chuva"
              component="img"
              src="/static/homepage/powered-by-chuva.png"
              sx={{
                width: 160
              }}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
}

Footer.propTypes = {
  handleFacebookClick: PropTypes.func.isRequired,
  handleInstagramClick: PropTypes.func.isRequired
};
