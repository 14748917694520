import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  Button,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Typography,
  Autocomplete,
  TextField
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import DetailsSection from '../../DetailsSection';

import { renderTaxOrRetentionLabels } from '../../../util/renderTaxOrRetentionLabels';
import { formatCurrency } from '../../../util/formatCurrency';
import { formattedDate } from '../../../util/formattedDate';

const ViewDetails = (props) => {
  const { orderStatusList, updateOrderStatus, title, order, onClose, currentCompany, ...other } = props;
  const [orderStatus, setOrderStatus] = useState({ id: order.orderStatus });

  const taxOrRetentionLabel = renderTaxOrRetentionLabels(currentCompany.companyType);

  const documentTableHeader = [
    'Artigo',
    'Nome',
    'Unidade',
    'Quantidade',
    'Preço',
    taxOrRetentionLabel.taxTypeLabel,
    'Desconto',
    'Total'
  ];

  const rowItems = (orderItem) => (
    <>
      <TableRow>
        <TableCell>
          {orderItem.id}
        </TableCell>
        <TableCell>
          {orderItem.item.name}
        </TableCell>
        <TableCell>
          {orderItem.unit_code}
        </TableCell>
        <TableCell>
          {orderItem.quantity}
        </TableCell>
        <TableCell>
          {formatCurrency(orderItem.price)}
        </TableCell>
        <TableCell>
          {orderItem.tax.value}
          %
        </TableCell>
        <TableCell>
          {orderItem.discount}
          %
        </TableCell>
        <TableCell>
          {formatCurrency(orderItem.item_total)}
          $
        </TableCell>
      </TableRow>
    </>
  );

  const renderOrderStatus = () => (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Estado do Pedido
        </Typography>
      </Box>
      <Box>
        <Autocomplete
          id="order-status-autocomplete"
          getOptionLabel={(option) => option.name}
          options={orderStatusList || []}
          disableClearable
          defaultValue={{
            name: orderStatusList.find((value) => value.id === order.orderStatus)?.name || ''
          }}
          onChange={(event, value) => {
            setOrderStatus(value);
          }}
          renderInput={(params) => (
            <TextField
              fullWidth
              label="Estado do Pedido"
              name="paymentMode"
              variant="outlined"
              {...params}
            />
          )}
        />
      </Box>
    </Box>
  );

  const renderOrderDetails = () => (
    <DetailsSection
      title="Detalhes do Pedido"
      rows={[
        { label: 'Código', value: order.id },
        { label: 'Data', value: formattedDate(order.date) },
        { label: 'Modo de Pagamento', value: order.paymentMode },
        { label: 'Endereço de Entrega', value: order.client.deliveryAddress }
      ]}
    />
  );

  const renderClientDetails = () => (
    <DetailsSection
      title="Detalhes do Cliente"
      rows={[
        { label: 'Nome', value: `${order.client.firstName} ${order.client.lastName}` },
        { label: 'Telefone', value: order.client.countryCode + order.client.phoneNumber },
        { label: 'Email', value: order.client.email }
      ]}
    />
  );

  const renderSummary = () => (
    <DetailsSection
      title="Sumário"
      rows={[
        { label: taxOrRetentionLabel.valueWithoutTaxLabel, value: formatCurrency(order.valueWithoutTax) },
        { label: taxOrRetentionLabel.valueOfTaxLabel, value: formatCurrency(order.valueOfTax) },
        { label: 'Valor Geral', value: formatCurrency(order.total) }
      ]}
    />
  );

  const dataToSent = {
    id: order.id,
    status: orderStatus.id
  };

  return (
    <Card {...other}>
      <Box
        id="order-view-details"
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', p: 3, flexDirection: 'column', gap: 3 }}>
        {renderOrderStatus()}
        {renderOrderDetails()}
        {renderClientDetails()}
        <Box>
          <Typography
            color="textSecondary"
            variant="overline"
          >
            Produtos
          </Typography>
          <Box sx={{ pt: 1 }}>
            <Card>
              <Scrollbar>
                <Table>
                  <TableHead>
                    <TableRow>
                      {documentTableHeader.map((cellContent) => (
                        <TableCell>
                          {cellContent}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {order.orderItems.map((orderItem) => rowItems(orderItem))}
                  </TableBody>
                </Table>
              </Scrollbar>
            </Card>
          </Box>
        </Box>
        {renderSummary()}
      </Box>
      <Box sx={{ gap: 1, px: 3, py: 2, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          style={{ borderRadius: '1.75rem' }}
        >
          Voltar
        </Button>
        <Button
          onClick={() => updateOrderStatus(dataToSent)}
          color="primary"
          id="order-view-details-save"
          variant="contained"
          style={{ borderRadius: '1.75rem' }}
        >
          Guardar
        </Button>
      </Box>
    </Card>
  );
};

ViewDetails.propTypes = {
  order: PropTypes.shape({
    id: PropTypes.string,
    client: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      email: PropTypes.string,
      phoneNumber: PropTypes.string,
      countryCode: PropTypes.string,
      deliveryAddress: PropTypes.string
    }),
    paymentMode: PropTypes.string,
    date: PropTypes.string,
    number: PropTypes.number,
    orderItems: {},
    valueWithoutTax: PropTypes.number,
    valueOfTax: PropTypes.number,
    total: PropTypes.number,
    orderStatus: PropTypes.string
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  updateOrderStatus: PropTypes.func.isRequired,
  orderStatusList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired
};

export default ViewDetails;
