import React, { useEffect, useState } from 'react';
import PropsTypes from 'prop-types';

import { Formik } from 'formik';
import { Box, Typography } from '@mui/material';

import NumericTextField from '../NumericTextField';

import Card from '../Card';
import BorderButton from '../BorderButton';
import BankAccountViewSummaryDetails from '../bank_account/BankAccountViewSummaryDetails';

import { formatCurrency } from '../../util/formatCurrency';
import calculateWithdrawalFee from '../../util/calculateWithdrawalFee';
import calculateAmountAfterFee from '../../util/calculateAmountAfterFee';
import { amountValidationSchema, getCombinedValidationSchema } from '../../yupValidation';
import { BALANCE_TYPES, BANK_WITHDRAWAL_TYPE } from '../../constants';
import InfoPair from '../InfoPair';

const BankWithdrawalForm = (props) => {
  let formikRef;

  const ZERO_AMOUNT = 0;
  const CONVERT_TO_DECIMAL = 10;
  const {
    bankAccounts,
    balances,
    sambaConstants,
    companyConstants,
    onCancel,
    onSubmit,
    withdrawalType
  } = props;

  const [amountValue, setAmountValue] = useState('');
  const [finalAmount, setFinalAmount] = useState(0);
  const [withdrawalFee, setWithdrawalFee] = useState(0);
  const [amountAfterFee, setAmountAfterFee] = useState(0);

  const withdrawalFeePingPOS = sambaConstants.pingPos.WITHDRAWAL_FEE;
  const withdrawalFeeCard = sambaConstants.card.WITHDRAWAL_FEE;

  const withdrawalFeeSamba = companyConstants.SAMBA_WITHDRAWAL_FEE;

  const totalFees = () => {
    if (withdrawalType === BANK_WITHDRAWAL_TYPE.PING) {
      return withdrawalFeePingPOS + withdrawalFeeSamba;
    }

    return withdrawalFeeCard + withdrawalFeeSamba;
  };

  const findBalanceById = (incomingBalance, balanceId) => incomingBalance?.find((balance) => balance.balanceId === balanceId);

  const getBalanceByType = () => {
    if (withdrawalType === BANK_WITHDRAWAL_TYPE.PING) {
      return findBalanceById(balances, BALANCE_TYPES.pingPOS);
    }
    return findBalanceById(balances, BALANCE_TYPES.CARD);
  };

  const balance = parseInt(getBalanceByType().value, CONVERT_TO_DECIMAL);

  const initialValues = {
    amount: null
  };

  const combinedValidationSchema = getCombinedValidationSchema([
    amountValidationSchema()
  ]);

  const handleSubmit = (values) => {
    const data = {
      amount: values.amount,
      bankAccountId: bankAccounts[0].id
    };
    onSubmit(data);
  };

  const handleExternalSubmit = async () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  const getFinalAmount = () => balance - amountValue;

  useEffect(() => {
    setWithdrawalFee(calculateWithdrawalFee(amountValue, totalFees()));
    if (getFinalAmount() > ZERO_AMOUNT) {
      setFinalAmount(getFinalAmount());
    } else {
      setFinalAmount(ZERO_AMOUNT);
    }
  }, [amountValue]);

  useEffect(() => {
    setAmountAfterFee(calculateAmountAfterFee(amountValue, totalFees()));
  }, [withdrawalFee, amountValue]);

  return (
    <Formik
      innerRef={(ref) => (formikRef = ref)}
      initialValues={initialValues}
      validationSchema={combinedValidationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleChange, values, errors, touched, handleBlur }) => (
        <Box sx={{ p: 3 }}>
          <Typography
            align="center"
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            Levantamento Bancário
          </Typography>

          <Box sx={{ mt: 3 }}>
            <NumericTextField
              id="bank-withdrawal-form-amount-text-field"
              error={Boolean(touched.amount && errors.amount)}
              fullWidth
              helperText={touched.amount && errors.amount}
              label="Valor a ser levantado*"
              name="amount"
              onBlur={handleBlur}
              onChange={(e) => {
                handleChange(e);
                setAmountValue(e.target.value);
              }}
              value={values.amount}
              variant="outlined"
            />
            <Card>
              <InfoPair
                id="bank-withdrawal-fee-pop-up"
                label={`Taxa de levantamento (${(totalFees() * 100)}%)`}
                value={formatCurrency(withdrawalFee)}
              />
              <InfoPair
                id="amount-to-receive-bank-withdrawal-pop-up"
                label="Valor a receber"
                value={formatCurrency(amountAfterFee)}
              />
              <InfoPair
                id="current-ping-balance-bank-withdrawal-pop-up"
                label={withdrawalType === BANK_WITHDRAWAL_TYPE.PING ? 'Saldo Ping atual' : 'Saldo Cartōes Online atual'}
                value={formatCurrency(balance)}
              />
              <InfoPair
                id="final-ping-balance-bank-withdrawal-pop-up"
                label={withdrawalType === BANK_WITHDRAWAL_TYPE.PING ? 'Saldo Ping final' : 'Saldo Cartōes Online final'}
                value={formatCurrency(finalAmount)}
              />
            </Card>
            <BankAccountViewSummaryDetails
              bankAccount={bankAccounts}
            />
            <Box sx={{ mt: 3 }}>
              <Typography
                color="textSecondary"
              >
                Apenas contas bancárias de Cabo Verde sāo permitidas para
                levantamentos. Por favor, verifique antes de prosseguir.
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2 }}>
              <Box sx={{ mr: 2 }}>
                <BorderButton
                  id="request-bank-withdrawal-pop-up-cancel-button"
                  title="Cancelar"
                  variant="outlined"
                  type="button"
                  onClick={onCancel}
                />
              </Box>
              <BorderButton
                id="request-bank-withdrawal-pop-up-request-button"
                title="Solicitar"
                variant="contained"
                type="submit"
                onClick={handleExternalSubmit}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );
};

BankWithdrawalForm.propTypes = {
  bankAccounts: PropsTypes.shape({
    bankName: PropsTypes.string,
    abbreviation: PropsTypes.string,
    accountNumber: PropsTypes.string,
    nib: PropsTypes.string,
    swift: PropsTypes.string,
    iban: PropsTypes.string
  }).isRequired,
  balances: PropsTypes.arrayOf(
    PropsTypes.shape({
      balanceId: PropsTypes.string.isRequired,
      value: PropsTypes.string.isRequired
    }).isRequired
  ).isRequired,
  sambaConstants: PropsTypes.shape({
    pingPos: PropsTypes.shape({
      WITHDRAWAL_FEE: PropsTypes.number.isRequired,
      MAX_TRANSACTION_AMOUNT: PropsTypes.number.isRequired,
      MIN_TRANSACTION_AMOUNT: PropsTypes.number.isRequired
    }),
    card: PropsTypes.shape({
      WITHDRAWAL_FEE: PropsTypes.number.isRequired,
      MAX_TRANSACTION_AMOUNT: PropsTypes.number.isRequired
    })
  }).isRequired,
  companyConstants: PropsTypes.shape({
    SAMBA_WITHDRAWAL_FEE: PropsTypes.number.isRequired
  }).isRequired,
  onSubmit: PropsTypes.func.isRequired,
  onCancel: PropsTypes.func.isRequired,
  withdrawalType: PropsTypes.oneOf([BANK_WITHDRAWAL_TYPE.PING, BANK_WITHDRAWAL_TYPE.CARD]).isRequired
};
export default BankWithdrawalForm;
