import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import useSettings from '../hooks/useSettings';
import CompanyTable from '../components/company/Table';
import CompanyForm from '../components/company/Form';
import CompanyViewDetails from '../components/company/ViewDetails';

import EmptyList from '../components/empty_list';
import LoadingPopUp from '../components/LoadingPopUp';
import Toast from '../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../constants';
import { useDispatch, useSelector } from '../store';
import { fetch as fetchCompanies, create as createCompany, set as setCurrentCompany } from '../slices/companies';

import { fetch as fetchCountries } from '../slices/countries';
import { fetch as fetchCurrencies } from '../slices/currencies';
import { fetch as fetchCounties } from '../slices/counties';

import WarningPopup from '../components/WarningPopup';
import WarningAlertOnPage from '../components/AlertOnPage';
import AlertIcon from '../icons/Alert';

import { isCompanyNotIntegratedWithEFatura } from '../util/eFaturasStatus';

const Company = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isWarningPopUpOpen, setIsWarningPopUpOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const { settings } = useSettings();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const location = useLocation();
  const {
    currentCompany,
    companies, isLoading,
    onRequest,
    onRequestFailure,
    successMessage,
    errorMessage
  } = useSelector((state) => state.companies);

  const { countries } = useSelector((state) => state.countries);
  const { currencies } = useSelector((state) => state.currencies);
  const { counties } = useSelector((state) => state.counties);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const handleNewClick = () => {
    setIsModalOpen(true);
    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchCounties());
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  const handleWarningPopUpOpen = () => {
    setIsWarningPopUpOpen(true);
  };

  const handleWarningPopUpClose = () => {
    setIsWarningPopUpOpen(false);
    handlePopUpOpen();
  };

  const handleViewCompany = (company) => {
    setSelectedCompany(company);
  };

  const handleSelectCompany = (company) => {
    dispatch(setCurrentCompany(company));
    navigate('/faturacao/faturas');
  };

  const handleCloseViewCompany = () => {
    setSelectedCompany(null);
  };

  const handleCreateCompany = (company) => {
    if (
      !company.county
      || !company.accountantTaxId
      || !company.countyTaxId
      || !company.fiscalName
    ) {
      handleWarningPopUpOpen();
    } else {
      handlePopUpOpen();
    }
    dispatch(createCompany(company));
    navigate('/faturacao/faturas');
  };

  useLayoutEffect(() => {
    if (location?.state?.openPopup) {
      handleNewClick();
    }

    dispatch(fetchCompanies());
  }, [dispatch]);

  function returnResult() {
    if (companies && companies.length > 0) {
      return (
        <>
          <Box sx={{ mt: 3 }}>
            <CompanyTable
              companies={companies}
              onSelectCompany={handleSelectCompany}
              onViewCompany={handleViewCompany}
            />
          </Box>
          {companies?.find((company) => (
            !company.accountantTaxId
            || !company.financeDepartmentTaxId
            || !company.fiscalName
          )) ? (
            <Box sx={{
              display: 'flex',
              alignItems: 'center'
            }}
            >
              <AlertIcon sx={{
                fontSize: 17,
                ml: 1
              }}
              />
              <Typography
                color="error"
                variant="subtitle2"
                sx={{
                  m: 2
                }}
              >
                É necessário preencher os campos da informação fiscal para o modelo 106
              </Typography>
            </Box>
            ) : null}
        </>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Empresa"
        buttonText="Criar Empresa"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Minhas Empresas | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: isModalOpen ? 1 : 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                id="company-page-title"
                color="textPrimary"
                variant="h5"
              >
                Minhas Empresas
              </Typography>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista Empresas
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen ? (
              <CompanyForm
                onCancel={handleModalClose}
                onSubmitForm={handleCreateCompany}
                countries={countries}
                currencies={currencies}
                counties={counties}
              />
            ) : (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  spacing={3}
                >
                  <Grid item>
                    <Typography
                      color="textPrimary"
                      variant="h5"
                    >
                      Minhas Empresas
                    </Typography>
                    <Typography
                      color="textSecondary"
                      variant="overline"
                    >
                      Lista Empresas
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleNewClick}
                    >
                      Novo
                    </Button>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 3 }}>
                  {isLoading
                    ? (
                      <LoadingPopUp />
                    ) : returnResult()}
                </Box>
              </>
            )}
          </Dialog>
        </Container>
        <Dialog
          maxWidth="xs"
          onClose={handleWarningPopUpClose}
          open={isWarningPopUpOpen}
        >
          {isWarningPopUpOpen && (
          <WarningPopup
            message="É necessário preencher os campos da informação fiscal para o modelo 106"
            buttonText="OK"
            onButtonPress={handleWarningPopUpClose}
          />
          )}
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleCloseViewCompany}
          open={selectedCompany}
        >
          {selectedCompany && (
          <CompanyViewDetails
            company={selectedCompany}
            onCancel={handleCloseViewCompany}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default Company;
