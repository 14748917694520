import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import { camelCaseToCamelCaseWithLowerCaseFirstLetter } from '../util/camelCaseToCamelCaseWithLowerCaseFirstLetter';

const initialState = {
  sambaConstants: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'sambaConstants',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    _getSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.sambaConstants = payload;
      state.error = null;
    },
    _getFailure: (state) => {
      state.isLoading = false;
      state.sambaConstants = null;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetchSambaConstants = createAsyncThunk(
  'sambaConstants/fetch_samba_constants',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get('/constants');

      const transformedObject = camelCaseToCamelCaseWithLowerCaseFirstLetter(response.data);

      dispatch(_getSuccess(transformedObject));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
