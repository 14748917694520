import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import analytics, { events } from '../services/analytics';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';

let companyId;

const initialState = {
  salesReceipts: null,
  isLoading: false,
  loadingError: null,
  salesReceiptPdf: null,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: null,
  errorMessage: null
};

const slice = createSlice({
  name: 'salesReceipts',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
      state.loadingError = false;
    },
    _getSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.loadingError = false;
      state.salesReceipts = payload;
    },
    _getFailure: (state) => {
      state.isLoading = false;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.salesReceipts === null) {
        state.salesReceipts = [];
      }
      state.salesReceipts.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
    },
    _getPdfFailure: (state, { payload }) => {
      state.isLoading = false;
      state.loadingError = true;
      state.errorMessage = payload;
    },
    _void: (state) => {
      state.onRequest = true;
    },
    _voidingSuccess: (state, { payload }) => {
      state.salesReceipts = state.salesReceipts.map((saleReceipt) => {
        if (saleReceipt.id === payload.id) {
          saleReceipt.isVoided = true;
        }

        return saleReceipt;
      });
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _voidFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendSalesReceiptEmail: (state) => {
      state.onRequest = true;
    },
    _sendSalesReceiptEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendSalesReceiptEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSalesReceiptPdf: (state, { payload }) => {
      state.salesReceiptPdf = payload;
      state.isLoading = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _void,
  _voidFailure,
  _voidingSuccess,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _sendSalesReceiptEmail,
  _sendSalesReceiptEmailSuccess,
  _sendSalesReceiptEmailFailure,
  _setSuccessMessage,
  _setSalesReceiptPdf
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'salesReceipt/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`companies/${companyId}/sales_receipts`);

      const salesReceipts = data.map((salesReceipt) => ({
        id: salesReceipt.id,
        clientName: salesReceipt?.customer?.name,
        clientId: salesReceipt?.customer?.id,
        serieCode: salesReceipt.serial_number.code,
        paymentPeriod: {
          id: salesReceipt.payment_period.id,
          code: salesReceipt.payment_period.code,
          name: salesReceipt.payment_period.name,
          numberOfDay: salesReceipt.payment_period.number_of_day
        },
        paymentMode: {
          id: salesReceipt.payment_mode.id,
          code: salesReceipt.payment_mode.code,
          name: salesReceipt.payment_mode.name
        },
        efaturaData: {
          id: salesReceipt.efatura_data.id,
          status: salesReceipt.efatura_data.status,
          errorCode: salesReceipt.efatura_data.error_code,
          errorMessage: salesReceipt.efatura_data.error_message
        },
        date: salesReceipt.date,
        serie: salesReceipt.serial_number.id,
        serieName: salesReceipt.serial_number.name,
        commentary: salesReceipt.commentary,
        valueWithoutTax: salesReceipt.value_without_tax,
        totalTax: salesReceipt.value_of_tax,
        valueWithTax: salesReceipt.value_with_tax,
        discount: salesReceipt.discount,
        totalGeneral: salesReceipt.total_general,
        number: salesReceipt.number || salesReceipt.sales_receipt_number,
        isVoided: salesReceipt.is_voided,
        receiptsValue: salesReceipt.receipts_values,
        paymentStatus: salesReceipt.payment_status,
        salesReceiptItems: salesReceipt.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      }));

      dispatch(_getSuccess(salesReceipts));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'salesReceipt/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/sales_receipts/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setSalesReceiptPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const create = createAsyncThunk(
  'salesReceipt/create',
  async (data, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;

    dispatch(_createOrUpdate());
    try {
      const salesReceiptItems = data.salesReceiptItems.map((item) => ({
        item_id: item.item,
        quantity: Number(item.quantity),
        unit_code: item.unit,
        price: Number(item.price),
        tax_id: item.tax,
        discount: Number(item.discount),
        item_description: item.description
      }));

      const salesReceipt = {
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client ? data.client : undefined,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        items: salesReceiptItems
      };

      const response = await axios.post(`/companies/${companyId}/sales_receipts`, salesReceipt);

      const newSalesReceipt = {
        id: response.data.id,
        clientName: response.data.customer.name,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieCode: response.data.serial_number.code,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalIva: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        number: response.data.number || response.data.invoice_number,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        isVoided: response.data.is_voided,
        paymentStatus: response.data.payment_status,
        salesReceiptItems: response.data.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      };

      analytics.dispatchEvent(events.salesReceiptCreated());

      dispatch(_createOrUpdatingSuccess(newSalesReceipt));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'salesReceipt/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/sales_receipts/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = data.clientName ? removeChar(data.clientName, '.') : 'Consumidor final';

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.salesReceiptDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const voidSalesReceipt = createAsyncThunk(
  'salesReceipt/void',
  async (data, { dispatch }) => {
    dispatch(_void());
    try {
      await axios.post(`/companies/${companyId}/sales_receipts/${data.id}/void`);

      dispatch(_voidingSuccess(data));
      dispatch(_setSuccessMessage('Anulado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_voidFailure(errorMessage));
    }
  }
);

export const sendSalesReceiptEmail = createAsyncThunk(
  'salesReceipt/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendSalesReceiptEmail());
    try {
      await axios.post(`/companies/${companyId}/sales_receipts/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.salesReceiptEmailed());

      dispatch(_sendSalesReceiptEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendSalesReceiptEmailFailure(errorMessage));
    }
  }
);

export const updateState = createAsyncThunk(
  'salesReceipt/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { salesReceipts } = getState().salesReceipts;

      const updatedSalesReceipt = salesReceipts.map((salesReceipt) => {
        const salesReceiptUpdated = data.find((i) => i.id === salesReceipt.id);

        if (salesReceiptUpdated) {
          return { ...salesReceipt, receiptsValue: salesReceipt.receiptsValue + salesReceiptUpdated.value };
        }

        return salesReceipt;
      });

      dispatch(_getSuccess(updatedSalesReceipt));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
