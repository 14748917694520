import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  TextField,
  Typography,
  createFilterOptions,
  InputAdornment
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { formatCurrency } from '../../util/formatCurrency';
import Toast from '../Toast';
import { TOAST_TYPE } from '../../constants';
import NumericTextField from '../NumericTextField';
import TextInput from '../Inputs/TextInput';
import { COMMON_MESSAGE } from '../../util/yupValidationMessages';

import calculateTotalInvoiceItem from '../../util/calculateTotalInvoiceItem';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const filter = createFilterOptions();

const initialValues = {
  item: null,
  price: null,
  unit: null,
  tax: null,
  quantity: null,
  discount: 0,
  description: undefined
};

const validationSchema = Yup
  .object()
  .shape({
    item: Yup
      .string()
      .typeError(COMMON_MESSAGE.REQUIRED)
      .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
      .trim()
      .required(COMMON_MESSAGE.REQUIRED),
    price: Yup
      .number()
      .typeError(COMMON_MESSAGE.NUMBER)
      .required(COMMON_MESSAGE.REQUIRED),
    unit: Yup
      .string()
      .typeError(COMMON_MESSAGE.REQUIRED)
      .required(COMMON_MESSAGE.REQUIRED),
    tax: Yup
      .string()
      .required(COMMON_MESSAGE.REQUIRED)
      .nullable(),
    quantity: Yup
      .number()
      .typeError(COMMON_MESSAGE.NUMBER)
      .required(COMMON_MESSAGE.REQUIRED),
    discount: Yup
      .number()
      .typeError(COMMON_MESSAGE.NUMBER),
    description: Yup.string().max(255, COMMON_MESSAGE.MAX_CHARACTERS)
  });

const InvoiceItemForm = (props) => {
  const {
    onComplete,
    onCancel,
    onAdd,
    onEdit,
    onAddAnother,
    isAdd,
    shouldUseCost,
    selectedInvoiceItem,
    openForm,
    taxes,
    units,
    items,
    toggleAddAnother,
    isAddAnother,
    currentCompany
  } = props;

  const [itemInitialLength, setItemInitialLength] = useState(null);
  const [itemInputValue, setItemInputValue] = useState(null);
  const [unitInitialLength, setUnitInitialLength] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [unitInputValue, setUnitInputValue] = useState(null);
  const [taxInputValue, setTaxInputValue] = useState(null);
  const [priceInputValue, setPriceInputValue] = useState(null);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const formikRef = useRef();

  useEffect(() => {
    if (selectedInvoiceItem) {
      setTaxInputValue(`${selectedInvoiceItem.taxName} - ${selectedInvoiceItem.taxValue}`);
      setUnitInputValue(selectedInvoiceItem.unit);
    }
  }, []);

  useEffect(() => {
    if (!itemInitialLength && items) {
      setItemInitialLength(items.length);
    } else if (items?.length > itemInitialLength) {
      const newItem = items[(items.length) - 1];

      if (formikRef.current) {
        formikRef.current.values.item = newItem.id;
        formikRef.current.values.price = shouldUseCost ? newItem.cost : newItem.sellingPrice;
        formikRef.current.values.unit = newItem.unit.code;
        formikRef.current.values.tax = newItem.tax.id;
      }

      setItemInputValue(`${newItem.code} - ${newItem.name}`);
      setUnitInputValue(newItem.unit?.code);
      setPriceInputValue(shouldUseCost ? newItem.cost : newItem.sellingPrice);
      setTaxInputValue(`${newItem.tax?.name} - ${newItem.tax?.value}`);
    }
  }, [items]);

  useEffect(() => {
    if (!unitInitialLength && units) {
      setUnitInitialLength(units.length);
    } else if (units?.length > unitInitialLength) {
      setUnitInputValue(units[(units.length) - 1].code);

      if (formikRef.current) {
        formikRef.current.values.unit = units[(units.length) - 1].code;
      }
    }
  }, [units]);

  const invoiceItemValues = {
    id: selectedInvoiceItem?.id,
    item: selectedInvoiceItem?.item,
    price: selectedInvoiceItem?.price,
    unit: selectedInvoiceItem?.unit,
    tax: selectedInvoiceItem?.tax,
    quantity: selectedInvoiceItem?.quantity,
    discount: selectedInvoiceItem?.discount,
    description: selectedInvoiceItem?.description
  };
  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
    setTimeout(() => {
      setIsPopUpOpen(false);
    }, 3000);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={isAdd ? initialValues : invoiceItemValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const getTax = taxes.find((tax) => tax.id === values.tax);

          values.total = calculateTotalInvoiceItem(
            values.price,
            values.quantity,
            taxes.find((tax) => tax.id === values.tax)?.value,
            values.discount,
            currentCompany.companyType
          );
          values.itemCode = items.find((item) => item.id === values.item)?.code;
          values.taxValue = getTax.value;
          values.taxName = getTax.name;

          handlePopUpOpen();
          await new Promise((res) => setTimeout(res, 2000));

          if (isAdd) {
            onAdd(values);
          } else {
            onEdit(values);
          }

          setStatus({ success: true });
          setSubmitting(false);
          if (isAddAnother) {
            onComplete();
            onAddAnother();
          } else {
            onComplete();
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          id="document-items-form"
          onSubmit={handleSubmit}
        >
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {isAdd
                ? 'Item na Fatura' : 'Editar Item na Fatura'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box sx={{
              mt: 2
            }}
            >
              <Autocomplete
                id="document-items-form-add-item"
                getOptionLabel={(option) => {
                  const optionValue = option.code !== 'Criar Novo' && option.code !== '' ? `${option.code} - ${option.name}` : option.code;
                  return optionValue;
                }}
                options={items || []}
                disableClearable
                defaultValue={isAdd ? null : ({
                  id: invoiceItemValues.item,
                  code: items.find((item) => item.id === invoiceItemValues.item)?.code,
                  name: items.find((item) => item.id === invoiceItemValues.item)?.name
                })}
                inputValue={itemInputValue || ''}
                onChange={(event, value) => {
                  setItemInputValue(`${value.code} - ${value.name}`);
                  setFieldValue('item', value?.id || '');

                  setFieldValue('unit', value?.unit?.code || '');
                  setFieldValue('price', (shouldUseCost ? value?.cost : value?.sellingPrice) || '');
                  setFieldValue('tax', value?.tax?.id || '');

                  setUnitInputValue(value?.unit?.code);
                  setPriceInputValue(shouldUseCost ? value?.cost : value?.sellingPrice);
                  setTaxInputValue(value?.tax?.name);

                  if (value.code === 'Criar Novo') {
                    openForm('ITEM');
                    value.code = itemInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && itemInputValue !== '') || itemInputValue.length === 1) {
                    setItemInputValue(newInputValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    code: 'Criar Novo',
                    name: ''
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    error={Boolean(touched.item && errors.item)}
                    fullWidth
                    helperText={touched.item && errors.item}
                    label="Item*"
                    name="item"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>

            <Box sx={{
              mt: 2
            }}
            >
              <NumericTextField
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="Preço Unitário"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                onInputChange={(event, value) => {
                  setPriceInputValue(value);
                }}
                value={values?.price || ''}
                inputValue={priceInputValue || ''}
                variant="outlined"
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 1
            }}
            >
              <Autocomplete
                getOptionLabel={(option) => option.code}
                options={units || []}
                disableClearable
                defaultValue={isAdd ? null : ({
                  code: units.find((unit) => unit.code === invoiceItemValues.unit)?.code
                })}
                inputValue={unitInputValue || ''}
                onChange={(event, value) => {
                  setUnitInputValue(value.code);
                  setFieldValue('unit', value.code || '');

                  if (value.code === 'Criar Novo') {
                    openForm('UNIT');
                    value.code = unitInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && unitInputValue !== '') || unitInputValue.length === 1) {
                    setUnitInputValue(newInputValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    code: 'Criar Novo'
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                    label="Unidade (UND)*"
                    name="unit"
                    variant="outlined"
                    {...params}
                  />
                )}
              />

              <NumericTextField
                id="document-items-form-quantity-input"
                error={Boolean(touched.quantity && errors.quantity)}
                fullWidth
                helperText={touched.quantity && errors.quantity}
                label="Quantidade (QTD)*"
                name="quantity"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.quantity}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 1
            }}
            >
              <Autocomplete
                getOptionLabel={(option) => `${option.name} - ${option.value}%`}
                options={taxes || []}
                disableClearable
                defaultValue={isAdd ? null : ({
                  id: invoiceItemValues.tax,
                  name: taxes.find((tax) => tax.id === invoiceItemValues.tax)?.name,
                  value: taxes.find((tax) => tax.id === invoiceItemValues.tax)?.value
                })}
                onInputChange={(event, value) => {
                  setTaxInputValue(value);
                }}
                onChange={(e, value) => {
                  setFieldValue('tax', value?.id || '');
                  setTaxInputValue(value);
                }}
                inputValue={taxInputValue || ''}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.tax && errors.tax)}
                    helperText={touched.tax && errors.tax}
                    label={taxOrRetentionLabels.typeRequiredLabel}
                    name="tax"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              <NumericTextField
                id="document-items-form-discount-input"
                error={Boolean(touched.discount && errors.discount)}
                fullWidth
                helperText={touched.discount && errors.discount}
                label="Desconto*"
                name="discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.discount}
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextInput
                id="document-items-form-description-input"
                name="description"
                error={Boolean(
                  touched.description
                  && errors.description
                )}
                label="Descriçāo"
                helperText={
                  touched.description
                  && errors.description
                }
                handleBlur={handleBlur}
                onChange={handleChange}
                value={values.description}
              />
            </Box>
            <Box sx={{ mt: 2, p: 1 }}>
              <Typography
                align="center"
                color="textPrimary"
                variant="h5"
              >
                Total:
                {' '}
                {formatCurrency(calculateTotalInvoiceItem(
                  values.price,
                  values.quantity,
                  taxes?.find((tax) => tax.id === values.tax)?.value || 0,
                  values.discount,
                  currentCompany.companyType
                ))}
                $
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Checkbox
                checked={isAddAnother}
                onClick={toggleAddAnother}
                color="primary"
              />
              <Typography
                color="textSecondary"
                variant="body2"
              >
                criar outro
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="document-items-form-add-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Criar
            </Button>
          </Box>

          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handlePopUpClose}
            open={isPopUpOpen}
          >
            {isPopUpOpen && (
            <Toast
              isOpen={isPopUpOpen}
              toastType={TOAST_TYPE.SUCCESS}
              message="Guardado com sucesso!"
              onClose={handlePopUpClose}
            />
            )}
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

InvoiceItemForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  shouldUseCost: PropTypes.bool,
  openForm: PropTypes.func.isRequired,
  onAddAnother: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  toggleAddAnother: PropTypes.func.isRequired,
  isAddAnother: PropTypes.bool.isRequired,
  selectedInvoiceItem: PropTypes.object,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  units: PropTypes.arrayOf({
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  items: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

InvoiceItemForm.defaultValue = {
  shouldUseCost: false
};
export default InvoiceItemForm;
