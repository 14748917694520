import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

import { useEffect } from 'react';

import calculateDiscount from '../../util/calculateDiscount';
import calculateTotalGeneral from '../../util/calculateTotalGeneral';
import calculateValueOfTax from '../../util/calculateValueOfTax';
import calculateValueWithTax from '../../util/calculateValueWithTax';
import calculateValueWithoutTax from '../../util/calculateValueWithoutTax';
import { formatCurrency } from '../../util/formatCurrency';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const CreditNoteItemSummation = (props) => {
  const { creditNoteItems, setIsTotalChanged, setCreditNoteTotal, currentCompany, ...other } = props;
  const filterCreditNoteItems = creditNoteItems?.filter((creditNoteItem) => !creditNoteItem.deleted);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const discount = calculateDiscount(filterCreditNoteItems, currentCompany.companyType);
  const valueWithoutTax = calculateValueWithoutTax(filterCreditNoteItems);
  const totalTax = calculateValueOfTax(filterCreditNoteItems);
  const valueWithTax = calculateValueWithTax(filterCreditNoteItems, currentCompany.companyType);
  const totalGeneralTax = calculateTotalGeneral(valueWithoutTax, totalTax, discount, currentCompany.companyType);

  useEffect(() => {
    setIsTotalChanged(totalGeneralTax !== 0);
    setCreditNoteTotal(totalGeneralTax);
  }, [totalGeneralTax]);

  return (
    <Card {...other}>
      <Box sx={{ p: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueWithoutTaxLabel}
              </TableCell>
              <TableCell id="document-summation-value-without-tax">
                {formatCurrency(valueWithoutTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueOfTaxLabel}
              </TableCell>
              <TableCell id="document-summation-total-tax">
                {formatCurrency(totalTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueWithTaxLabel}
              </TableCell>
              <TableCell id="document-summation-value-with-tax">
                {formatCurrency(valueWithTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                Desconto
              </TableCell>
              <TableCell id="document-summation-discount">
                {formatCurrency(discount || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>
                Total Geral
              </TableCell>
              <TableCell id="document-summation-total-general">
                {formatCurrency(totalGeneralTax || 0)}
                $
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};
CreditNoteItemSummation.propTypes = {
  creditNoteItems: PropTypes.arrayOf({
    discount: PropTypes.number,
    item: PropTypes.string,
    tax: PropTypes.string,
    taxValue: PropTypes.number,
    price: PropTypes.number,
    quantity: PropTypes.number,
    unit: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  setCreditNoteTotal: PropTypes.func.isRequired,
  setIsTotalChanged: PropTypes.bool.isRequired
};
export default CreditNoteItemSummation;
