import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  series: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'series',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.series = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.series = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.series === undefined) {
        state.series = [];
      }
      state.series.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.series = state.series.filter((serie) => serie.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'series/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/serial_numbers`);

      const series = data.map((serie) => ({
        id: serie.id,
        code: serie.code,
        name: serie.name,
        startDate: format(parseISO(serie.date_range[0].value), 'dd/MM/yyyy'),
        endDate: format(parseISO(serie.date_range[1].value), 'dd/MM/yyyy'),
        startDateRaw: serie.date_range[0].value,
        endDateRaw: serie.date_range[1].value,
        lastDocumentNumber: serie.last_document_number,
        range: serie.range,
        fiscalYear: serie.fiscalYear,
        fiscalYearName: serie.fiscalYear.name,
        fiscalYearDate: serie.fiscalYear.date_range[0].value.split('-')[0],
        invoices: serie.invoices,
        serialNumberConfig: serie.serial_number_config && {
          invoiceLastDocumentNumber: serie.serial_number_config.invoice_last_document_number,
          invoiceRange: serie.serial_number_config.invoice_range,
          receiptLastDocumentNumber: serie.serial_number_config.receipt_last_document_number,
          receiptRange: serie.serial_number_config.receipt_range,
          invoiceReceiptLastDocumentNumber: serie.serial_number_config.invoice_receipt_last_document_number,
          invoiceReceiptRange: serie.serial_number_config.invoice_receipt_range,
          debitNoteLastDocumentNumber: serie.serial_number_config.debit_note_last_document_number,
          debitNoteRange: serie.serial_number_config.debit_note_range,
          creditNoteLastDocumentNumber: serie.serial_number_config.credit_note_last_document_number,
          creditNoteRange: serie.serial_number_config.credit_note_range,
          proFormaLastDocumentNumber: serie.serial_number_config.pro_forma_last_document_number,
          proFormaRange: serie.serial_number_config.pro_forma_range,
          returnNoteLastDocumentNumber: serie.serial_number_config.return_note_last_document_number,
          returnNoteRange: serie.serial_number_config.return_note_range,
          salesReceiptLastDocumentNumber: serie.serial_number_config.sales_receipt_last_document_number,
          salesReceiptRange: serie.serial_number_config.sales_receipt_range
        }
      }));

      dispatch(_getSuccess(series));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'series/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const startDateFormatted = format(data.startDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);
      const endDateFormatted = format(data.endDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);

      const serie = {
        code: data.code,
        name: data.name,
        date_range: `${startDateFormatted},${endDateFormatted}`,
        serial_number_config: {
          invoice_last_document_number: data.invoiceLastDocumentNumber ? Number(data.invoiceLastDocumentNumber) : 0,
          invoice_range: data.invoiceRange ? Number(data.invoiceRange) : 0,
          receipt_last_document_number: data.receiptLastDocumentNumber ? Number(data.receiptLastDocumentNumber) : 0,
          receipt_range: data.receiptRange ? Number(data.receiptRange) : 0,
          invoice_receipt_last_document_number: data.invoiceReceiptLastDocumentNumber ? Number(data.invoiceReceiptLastDocumentNumber) : 0,
          invoice_receipt_range: data.invoiceReceiptRange ? Number(data.invoiceReceiptRange) : 0,
          debit_note_last_document_number: data.debitNoteLastDocumentNumber ? Number(data.debitNoteLastDocumentNumber) : 0,
          debit_note_range: data.debitNoteRange ? Number(data.debitNoteRange) : 0,
          credit_note_last_document_number: data.creditNoteLastDocumentNumber ? Number(data.creditNoteLastDocumentNumber) : 0,
          credit_note_range: data.creditNoteRange ? Number(data.creditNoteRange) : 0,
          pro_forma_last_document_number: data.proFormaLastDocumentNumber ? Number(data.proFormaLastDocumentNumber) : 0,
          pro_forma_range: data.proFormaRange ? Number(data.proFormaRange) : 0,
          return_note_last_document_number: data.returnNoteLastDocumentNumber ? Number(data.returnNoteLastDocumentNumber) : 0,
          return_note_range: data.returnNoteRange ? Number(data.returnNoteRange) : 0,
          sales_receipt_last_document_number: data.salesReceiptLastDocumentNumber ? Number(data.salesReceiptLastDocumentNumber) : 0,
          sales_receipt_range: data.salesReceiptRange ? Number(data.salesReceiptRange) : 0
        }
      };

      const response = await axios.post(`/companies/${companyId}/fiscal_years/${data.fiscalYear}/serial_numbers`, serie);

      const newSerie = {
        id: response.data.id,
        code: response.data.code,
        name: response.data.name,
        startDate: format(parseISO(response.data.date_range[0].value), 'dd/MM/yyyy'),
        startDateRaw: response.data.date_range[0].value,
        endDate: format(parseISO(response.data.date_range[1].value), 'dd/MM/yyyy'),
        endDateRaw: response.data.date_range[1].value,
        lastDocumentNumber: response.data.last_document_number,
        range: response.data.range,
        fiscalYearName: response.data.fiscalYear.name,
        fiscalYear: response.data.fiscalYear,
        fiscalYearDate: response.data.fiscalYear.date_range[0].value.split('-')[0],
        serialNumberConfig: {
          invoiceLastDocumentNumber: data.invoiceLastDocumentNumber,
          invoiceRange: data.invoiceRange,
          receiptLastDocumentNumber: data.receiptLastDocumentNumber,
          receiptRange: data.receiptRange,
          invoiceReceiptLastDocumentNumber: data.invoiceReceiptLastDocumentNumber,
          invoiceReceiptRange: data.invoiceReceiptRange,
          debitNoteLastDocumentNumber: data.debitNoteLastDocumentNumber,
          debitNoteRange: data.debitNoteRange,
          creditNoteLastDocumentNumber: data.creditNoteLastDocumentNumber,
          creditNoteRange: data.creditNoteRange,
          proFormaLastDocumentNumber: data.proFormaLastDocumentNumber,
          proFormaRange: data.proFormaRange,
          returnNoteLastDocumentNumber: data.returnNoteLastDocumentNumber,
          returnNoteRange: data.returnNoteRange,
          salesReceiptLastDocumentNumber: data.salesReceiptLastDocumentNumber,
          salesReceiptRange: data.salesReceiptRange
        }
      };

      analytics.dispatchEvent(events.serialNumberCreated());

      dispatch(_createOrUpdatingSuccess(newSerie));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const update = createAsyncThunk(
  'series/update',
  async (data, { dispatch, getState }) => {
    dispatch(_createOrUpdate());
    try {
      const startDateFormatted = format(data.startDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);
      const endDateFormatted = format(data.endDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);

      const editedSerie = {
        code: data.code,
        name: data.name,
        date_range: `${startDateFormatted},${endDateFormatted}`,
        serial_number_config: {
          invoice_last_document_number: data.invoiceLastDocumentNumber ? Number(data.invoiceLastDocumentNumber) : 0,
          invoice_range: data.invoiceRange ? Number(data.invoiceRange) : 0,
          receipt_last_document_number: data.receiptLastDocumentNumber ? Number(data.receiptLastDocumentNumber) : 0,
          receipt_range: data.receiptRange ? Number(data.receiptRange) : 0,
          invoice_receipt_last_document_number: data.invoiceReceiptLastDocumentNumber ? Number(data.invoiceReceiptLastDocumentNumber) : 0,
          invoice_receipt_range: data.invoiceReceiptRange ? Number(data.invoiceReceiptRange) : 0,
          debit_note_last_document_number: data.debitNoteLastDocumentNumber ? Number(data.debitNoteLastDocumentNumber) : 0,
          debit_note_range: data.debitNoteRange ? Number(data.debitNoteRange) : 0,
          credit_note_last_document_number: data.creditNoteLastDocumentNumber ? Number(data.creditNoteLastDocumentNumber) : 0,
          credit_note_range: data.creditNoteRange ? Number(data.creditNoteRange) : 0,
          pro_forma_last_document_number: data.proFormaLastDocumentNumber ? Number(data.proFormaLastDocumentNumber) : 0,
          pro_forma_range: data.proFormaRange ? Number(data.proFormaRange) : 0,
          return_note_last_document_number: data.returnNoteLastDocumentNumber ? Number(data.returnNoteLastDocumentNumber) : 0,
          return_note_range: data.returnNoteRange ? Number(data.returnNoteRange) : 0,
          salesReceiptLastDocumentNumber: data.salesReceiptLastDocumentNumber ? Number(data.salesReceiptLastDocumentNumber) : 0,
          sales_receipt_range: data.salesReceiptRange ? Number(data.salesReceiptRange) : 0
        }
      };

      await axios.put(`/companies/${companyId}/fiscal_years/${data.fiscalYear}/serial_numbers/${data.id}`, editedSerie);
      dispatch(_createOrUpdatingSuccess());

      const { series } = getState().series;
      const { fiscalYears } = getState().fiscalYear;

      const getFiscalYear = fiscalYears.find((fiscalYear) => fiscalYear.id === data.fiscalYear);

      const updatedSeries = series.map((serie) => {
        if (serie.id === data.id) {
          return {
            id: data.id,
            code: data.code,
            name: data.name,
            startDate: format(parseISO(startDateFormatted), 'dd/MM/yyyy'),
            endDate: format(parseISO(endDateFormatted), 'dd/MM/yyyy'),
            endDateRaw: data.endDate,
            startDateRaw: data.startDate,
            fiscalYearName: getFiscalYear.name,
            fiscalYear: getFiscalYear,
            serialNumberConfig: {
              invoiceLastDocumentNumber: data.invoiceLastDocumentNumber,
              invoiceRange: data.invoiceRange,
              receiptLastDocumentNumber: data.receiptLastDocumentNumber,
              receiptRange: data.receiptRange,
              invoiceReceiptLastDocumentNumber: data.invoiceReceiptLastDocumentNumber,
              invoiceReceiptRange: data.invoiceReceiptRange,
              debitNoteLastDocumentNumber: data.debitNoteLastDocumentNumber,
              debitNoteRange: data.debitNoteRange,
              creditNoteLastDocumentNumber: data.creditNoteLastDocumentNumber,
              creditNoteRange: data.creditNoteRange,
              proFormaLastDocumentNumber: data.proFormaLastDocumentNumber,
              proFormaRange: data.proFormaRange,
              returnNoteLastDocumentNumber: data.returnNoteLastDocumentNumber,
              returnNoteRange: data.returnNoteRange,
              salesReceiptLastDocumentNumber: data.salesReceiptLastDocumentNumber,
              salesReceiptRange: data.salesReceiptRange
            }
          };
        }

        return serie;
      });

      dispatch(_getSuccess(updatedSeries));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const remove = createAsyncThunk(
  'series/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/fiscal_years/${data.fiscalYear.id}/serial_numbers/${data.id}`);
      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
