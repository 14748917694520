/**
 * Returns the colors associated with the given eFatura status.
 * @param {string} status - The status of the eFatura.
 * @returns {Object} An object containing label, color, and backgroundColor properties.
 */
export const getEfaturaStatusColors = (status) => {
  switch (status) {
    case 'liquidated':
      return {
        label: 'Liquidado',
        color: '#21C8C3',
        backgroundColor: 'rgba(33, 200, 195, 0.1)'
      };
    case 'overdue':
      return {
        label: 'Em Atraso',
        color: '#FF5E52',
        backgroundColor: 'rgba(255, 94, 82, 0.1)'
      };
    case 'none':
      return {
        label: 'Sem Estado',
        color: '#535353',
        backgroundColor: 'rgba(145, 158, 171, 0.1)'
      };
    default:
      return {
        label: 'Pendente',
        color: '#F1B37A',
        backgroundColor: 'rgba(241, 179, 122, 0.1)'
      };
  }
};
