import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  Divider,
  TextField,
  Typography,
  createFilterOptions,
  InputAdornment
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { formatCurrency } from '../../util/formatCurrency';
import Toast from '../Toast';
import { TOAST_TYPE, COMPANY_TYPES } from '../../constants';
import NumericTextField from '../NumericTextField';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const filter = createFilterOptions();

const CreditNoteItemForm = (props) => {
  const {
    onComplete,
    onCancel,
    onEdit,
    shouldUseCost,
    selectedCreditNoteItem,
    openForm,
    taxes,
    units,
    items,
    documentName,
    selectedDocumentReferenceItems,
    setMessage,
    handleInvalidTotalPopUpOpen,
    setIsItemEdited,
    currentCompany
  } = props;
  const [itemInitialLength, setItemInitialLength] = useState(null);
  const [itemInputValue, setItemInputValue] = useState(null);
  const [unitInitialLength, setUnitInitialLength] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [unitInputValue, setUnitInputValue] = useState(null);
  const [taxInputValue, setTaxInputValue] = useState(null);
  const [priceInputValue, setPriceInputValue] = useState(null);
  const [itemTotal, setItemTotal] = useState(null);

  const formikRef = useRef();
  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const {
    price: priceOfDocumentItem,
    quantity: quantityOfDocumentItem,
    total: totalOfDocumentItem
  } = selectedDocumentReferenceItems?.find((item) => item.id === selectedCreditNoteItem.id);

  const handleEdit = (event) => {
    if (formatCurrency(totalOfDocumentItem) === itemTotal) {
      event.preventDefault();
      setMessage(`O total permaneceu em ${itemTotal}$`);
      handleInvalidTotalPopUpOpen();
    }
  };

  useEffect(() => {
    if (selectedCreditNoteItem) {
      setTaxInputValue(`${selectedCreditNoteItem.taxName} - ${selectedCreditNoteItem.taxValue}`);
      setUnitInputValue(selectedCreditNoteItem.unit);
    }
  }, []);

  useEffect(() => {
    if (!itemInitialLength && items) {
      setItemInitialLength(items.length);
    } else if (items?.length > itemInitialLength) {
      const newItem = items[(items.length) - 1];

      if (formikRef.current) {
        formikRef.current.values.item = newItem.id;
        formikRef.current.values.price = shouldUseCost ? newItem.cost : newItem.sellingPrice;
        formikRef.current.values.unit = newItem.unit.code;
        formikRef.current.values.tax = newItem.tax.id;
      }

      setItemInputValue(`${newItem.code} - ${newItem.name}`);
      setUnitInputValue(newItem.unit?.code);
      setPriceInputValue(shouldUseCost ? newItem.cost : newItem.sellingPrice);
      setTaxInputValue(`${newItem.tax?.name} - ${newItem.tax?.value}`);
    }
  }, [items]);

  useEffect(() => {
    if (!unitInitialLength && units) {
      setUnitInitialLength(units.length);
    } else if (units?.length > unitInitialLength) {
      setUnitInputValue(units[(units.length) - 1].code);

      if (formikRef.current) {
        formikRef.current.values.unit = units[(units.length) - 1].code;
      }
    }
  }, [units]);

  function calculateTotalInvoiceItem(companyType, unitPrice = 0, quantity = 0, taxValue = 0, discount = 0) {
    let totalGeneral = 0;

    if (companyType === COMPANY_TYPES[0].name) {
      totalGeneral = (unitPrice * quantity * (1 - (taxValue / 100)) * (1 - (discount / 100)));
      setItemTotal(formatCurrency(totalGeneral));
    } else {
      totalGeneral = (unitPrice * quantity * (1 + (taxValue / 100)) * (1 - (discount / 100)));
      setItemTotal(formatCurrency(totalGeneral));
    }

    if (totalGeneral) {
      return totalGeneral;
    }

    return 0;
  }

  const creditNoteItemValues = {
    id: selectedCreditNoteItem?.id,
    item: selectedCreditNoteItem?.item,
    price: selectedCreditNoteItem?.price,
    unit: selectedCreditNoteItem?.unit,
    tax: selectedCreditNoteItem?.tax,
    quantity: selectedCreditNoteItem?.quantity,
    discount: selectedCreditNoteItem?.discount
  };
  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
    setTimeout(() => {
      setIsPopUpOpen(false);
    }, 3000);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  return (
    <Formik
      innerRef={formikRef}
      enableReinitialize
      initialValues={creditNoteItemValues}
      validationSchema={Yup
        .object()
        .shape({
          item: Yup
            .string()
            .typeError('Obrigatório')
            .max(255)
            .trim()
            .required('Obrigatório'),
          price: Yup
            .number()
            .max(priceOfDocumentItem, `Preço Unitário não pode ser maior que ${formatCurrency(priceOfDocumentItem)}$`)
            .typeError('Deve conter apenas números')
            .required('Obrigatório'),
          unit: Yup
            .string()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          tax: Yup
            .string()
            .required('Obrigatório'),
          quantity: Yup
            .number()
            .max(quantityOfDocumentItem, `Quantidade não pode ser maior que ${quantityOfDocumentItem}`)
            .typeError('Deve conter apenas números')
            .required('Obrigatório'),
          discount: Yup
            .number()
            .typeError('Pode conter apenas números')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const getTax = taxes.find((tax) => tax.id === values.tax);

          values.total = calculateTotalInvoiceItem(
            currentCompany.companyType,
            values.price,
            values.quantity,
            taxes.find((tax) => tax.id === values.tax)?.value,
            values.discount
          );
          values.itemCode = items.find((item) => item.id === values.item)?.code;
          values.taxValue = getTax.value;
          values.taxName = getTax.name;

          handlePopUpOpen();
          onEdit(values);
          setIsItemEdited(true);

          setStatus({ success: true });
          setSubmitting(false);
          onComplete();
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          id="document-items-form"
          onSubmit={handleSubmit}
        >
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {`Editar Item na ${documentName}`}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box sx={{
              mt: 2
            }}
            >
              <Autocomplete
                id="document-items-form-edit-item"
                getOptionLabel={(option) => {
                  const optionValue = option.code !== 'Criar Novo' && option.code !== '' ? `${option.code} - ${option.name}` : option.code;
                  return optionValue;
                }}
                options={items || []}
                disableClearable
                disabled
                defaultValue={({
                  id: creditNoteItemValues.item,
                  code: items.find((item) => item.id === creditNoteItemValues.item)?.code,
                  name: items.find((item) => item.id === creditNoteItemValues.item)?.name
                })}
                inputValue={itemInputValue || ''}
                onChange={(event, value) => {
                  setItemInputValue(`${value.code} - ${value.name}`);
                  setFieldValue('item', value?.id || '');

                  setFieldValue('unit', value?.unit?.code || '');
                  setFieldValue('price', (shouldUseCost ? value?.cost : value?.sellingPrice) || '');
                  setFieldValue('tax', value?.tax?.id || '');

                  setUnitInputValue(value?.unit?.code);
                  setPriceInputValue(shouldUseCost ? value?.cost : value?.sellingPrice);
                  setTaxInputValue(value?.tax?.name);

                  if (value.code === 'Criar Novo') {
                    openForm('ITEM');
                    value.code = itemInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && itemInputValue !== '') || itemInputValue.length === 1) {
                    setItemInputValue(newInputValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    code: 'Criar Novo',
                    name: ''
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    error={Boolean(touched.item && errors.item)}
                    fullWidth
                    helperText={touched.item && errors.item}
                    label="Item*"
                    name="item"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>

            <Box sx={{
              mt: 2
            }}
            >
              <NumericTextField
                id="document-items-form-price-input"
                error={Boolean(touched.price && errors.price)}
                fullWidth
                helperText={touched.price && errors.price}
                label="Preço Unitário"
                name="price"
                onBlur={handleBlur}
                onChange={handleChange}
                onInputChange={(event, value) => {
                  setPriceInputValue(value);
                }}
                value={values?.price || ''}
                inputValue={priceInputValue || ''}
                variant="outlined"
              />
            </Box>
            <Box sx={{
              mt: 2,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 1
            }}
            >
              <Autocomplete
                getOptionLabel={(option) => option.code}
                options={units || []}
                disableClearable
                disabled
                defaultValue={({
                  code: units.find((unit) => unit.code === creditNoteItemValues.unit)?.code
                })}
                inputValue={unitInputValue || ''}
                onChange={(event, value) => {
                  setUnitInputValue(value.code);
                  setFieldValue('unit', value.code || '');
                  if (value.code === 'Criar Novo') {
                    openForm('UNIT');
                    value.code = unitInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && unitInputValue !== '') || unitInputValue.length === 1) {
                    setUnitInputValue(newInputValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    code: 'Criar Novo'
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.unit && errors.unit)}
                    helperText={touched.unit && errors.unit}
                    label="Unidade (UND)*"
                    name="unit"
                    variant="outlined"
                    {...params}
                  />
                )}
              />

              <NumericTextField
                id="document-items-form-quantity-input"
                error={Boolean(touched.quantity && errors.quantity)}
                fullWidth
                helperText={touched.quantity && errors.quantity}
                label="Quantidade (QTD)*"
                name="quantity"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.quantity}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2,
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              gap: 1
            }}
            >
              <Autocomplete
                getOptionLabel={(option) => `${option.name} - ${option.value}%`}
                options={taxes || []}
                disableClearable
                disabled
                defaultValue={{
                  id: creditNoteItemValues.tax,
                  name: taxes.find((tax) => tax.id === creditNoteItemValues.tax)?.name,
                  value: taxes.find((tax) => tax.id === creditNoteItemValues.tax)?.value
                }}
                onInputChange={(event, value) => {
                  setTaxInputValue(value);
                }}
                onChange={(e, value) => {
                  setFieldValue('tax', value?.id || '');
                  setTaxInputValue(value);
                }}
                inputValue={taxInputValue || ''}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.tax && errors.tax)}
                    helperText={touched.tax && errors.tax}
                    label={taxOrRetentionLabels.typeRequiredLabel}
                    name="tax"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              <NumericTextField
                id="document-items-form-discount-input"
                disabled
                error={Boolean(touched.discount && errors.discount)}
                fullWidth
                helperText={touched.discount && errors.discount}
                label="Desconto*"
                name="discount"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.discount}
                variant="outlined"
                InputProps={{
                  endAdornment: <InputAdornment position="end">%</InputAdornment>
                }}
              />
            </Box>
            <Box sx={{ mt: 2, p: 1 }}>
              <Typography
                align="center"
                color="textPrimary"
                variant="h5"
              >
                Total:
                {' '}
                {formatCurrency(
                  calculateTotalInvoiceItem(
                    currentCompany.companyType,
                    values.price,
                    values.quantity,
                    taxes?.find((tax) => tax.id === values.tax)?.value || 0,
                    values.discount
                  )
                )}
                $
              </Typography>
            </Box>
          </Box>

          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="document-items-form-edit-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
              onClick={(e) => handleEdit(e)}
            >
              Criar
            </Button>
          </Box>

          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handlePopUpClose}
            open={isPopUpOpen}
          >
            {isPopUpOpen && (
            <Toast
              isOpen={isPopUpOpen}
              toastType={TOAST_TYPE.SUCCESS}
              message="Guardado com sucesso!"
              onClose={handlePopUpClose}
            />
            )}
          </Dialog>
        </form>
      )}
    </Formik>
  );
};

CreditNoteItemForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  shouldUseCost: PropTypes.bool,
  openForm: PropTypes.func.isRequired,
  selectedCreditNoteItem: PropTypes.object,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  units: PropTypes.arrayOf({
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  items: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number
  }).isRequired,
  documentName: PropTypes.string.isRequired,
  selectedDocumentReferenceItems: PropTypes.arrayOf({
    id: PropTypes.string,
    price: PropTypes.number,
    quantity: PropTypes.number
  }),
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  setMessage: PropTypes.func.isRequired,
  handleInvalidTotalPopUpOpen: PropTypes.func.isRequired,
  setIsItemEdited: PropTypes.func.isRequired,
  documents: PropTypes.array.isRequired
};

CreditNoteItemForm.defaultValue = {
  shouldUseCost: false
};
export default CreditNoteItemForm;
