import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';

const initialState = {
  bankWithdrawals: [],
  isLoading: false,
  errorMessage: null,
  onRequest: false,
  onRequestSuccess: false,
  onRequestFailure: null
};

const slice = createSlice({
  name: 'bankWithdrawal',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
      state.errorMessage = null;
    },
    _getSuccess: (state, { payload }) => {
      state.bankWithdrawals = payload;
      state.isLoading = false;
      state.errorMessage = null;
    },
    _getFailure: (state, { payload }) => {
      state.bankWithdrawals = [];
      state.isLoading = false;
      state.errorMessage = payload;
    },
    _request: (state) => {
      state.onRequest = true;
      state.onRequestFailure = null;
    },
    _requestSuccess: (state) => {
      state.onRequest = false;
      state.onRequestSuccess = true;
    },
    _requestFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = payload;
    },
    _clearOnRequestState: (state) => {
      state.onRequest = false;
      state.onRequestSuccess = false;
      state.onRequestFailure = null;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _request,
  _requestSuccess,
  _requestFailure,
  _clearOnRequestState
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'bankWithdrawal/fetch',
  async (property, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/bank_accounts/${property.bankAccountId}/withdrawals`);

      const bankWithdrawals = data.map((bankWithdrawal) => ({
        id: bankWithdrawal.id,
        bankAccount: {
          id: bankWithdrawal.bank_account.id,
          nib: bankWithdrawal.bank_account.nib
        },
        user: {
          id: bankWithdrawal.user.id,
          name: bankWithdrawal.user.name
        },
        amount: bankWithdrawal.amount,
        status: bankWithdrawal.status,
        transactionId: bankWithdrawal.transaction_id,
        date: bankWithdrawal.date
      }));
      dispatch(_getSuccess(bankWithdrawals));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const requestBankWithdrawal = createAsyncThunk(
  'bankWithdrawal/request_bank_withdrawal',
  async (data, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;
    dispatch(_request());
    try {
      const body = {
        amount: Number(data.values.amount),
        idempotent_id: nanoid(),
        payment_method: data.paymentMethod
      };

      await axios.post(`/companies/${companyId}/bank_accounts/${data.values.bankAccountId}/withdrawals`, body);
      dispatch(_requestSuccess());
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_requestFailure(errorMessage));
    }
  }
);

export const clearOnRequestState = createAsyncThunk(
  'bank_withdrawal/clear_success_state',
  async (_, { dispatch }) => {
    dispatch(_clearOnRequestState());
  }
);
