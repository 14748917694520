import PropTypes from 'prop-types';
import { Box, Button, Card, Dialog, Typography, Checkbox, Link } from '@mui/material';
import Chip from '@mui/material/Chip';
import React, { useState } from 'react';

import WarningPopup from '../WarningPopup';
import LoadingPopUp from '../LoadingPopUp';
import StatusMessagePopup from '../StatusMessagePopup';

const PingIntegration = (props) => {
  const {
    error,
    isLoading,
    companyIntegrations,
    errorMessage,
    handleIntegrationWithPing,
    handleGetCompanyIntegrations,
    isCompanyIntegratedWithPing,
    popupStatus
  } = props;

  const [isWarningPopUpOpen, setIsWarningPopUpOpen] = useState(null);
  const [isIntegrateButtonClicked, setIsIntegrateButtonClicked] = useState(null);
  const [isTermsAndConditionsAccepted, setIsTermsAndConditionsAccepted] = useState(null);

  const handleWarningPopUpOpen = () => {
    setIsWarningPopUpOpen(true);
  };

  const handleWarningPopUpClose = () => {
    setIsWarningPopUpOpen(false);
    setIsTermsAndConditionsAccepted(false);
  };

  const hanleClickIntegrationButton = () => {
    handleWarningPopUpClose();
    handleIntegrationWithPing();
    setIsIntegrateButtonClicked(true);
  };

  const handleOkButtonClicked = () => {
    setIsIntegrateButtonClicked(false);
    handleGetCompanyIntegrations();
  };

  const renderStatusButton = () => {
    if (isCompanyIntegratedWithPing(companyIntegrations)) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
          <Chip
            sx={{ mt: 2 }}
            id="ping-integration-button"
            label="Integrado"
            variant="outlined"
            color="primary"
            style={{
              width: 100
            }}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
        <Button
          id="ping-integration-button"
          sx={{ mt: 2 }}
          color="primary"
          variant="contained"
          onClick={() => handleWarningPopUpOpen()}
          style={{
            width: 100
          }}
        >
          Integrar
        </Button>
      </Box>
    );
  };

  const handleOpenWindow = (e) => {
    e.stopPropagation();
    window.open('/termos-e-condicoes', '_blank', 'noopener,noreferrer');
  };

  const handleAcceptTermsAndConditions = () => (
    <Box
      sx={{ ml: 1, display: 'inline-flex', cursor: 'pointer' }}
      onClick={() => setIsTermsAndConditionsAccepted(!isTermsAndConditionsAccepted)}
    >
      <Checkbox
        id="ping-terms-and-conditions-checkbox"
        color="primary"
        checked={isTermsAndConditionsAccepted}
      />
      <Typography sx={{ alignSelf: 'center' }}>
        Li e aceito os
        {' '}
        <Link
          onClick={handleOpenWindow}
          color="primary"
          underline="none"
        >
          Termos e Condições
        </Link>
      </Typography>
    </Box>
  );

  const renderWarningPopUp = () => {
    if (isWarningPopUpOpen) {
      return (
        <WarningPopup
          primaryButtonId="continue-with-ping-integration-button"
          secondaryButtonId="secondary-id-ping-integration-button"
          message="
            Antes de prosseguir com a integração, confirma que o teu email e número de telefone
            estão corretos e lê os termos e condições de utilização do serviço SAMBA POS.
          "
          buttonText="Continuar"
          secondaryButtonText="Cancelar"
          onSecondaryButtonPress={handleWarningPopUpClose}
          onButtonPress={hanleClickIntegrationButton}
          isAccepted={!isTermsAndConditionsAccepted}
        >
          {handleAcceptTermsAndConditions()}
        </WarningPopup>
      );
    }
    if (isLoading) {
      return <LoadingPopUp />;
    }
    return null;
  };

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Card sx={{ p: 2 }}>
          <img
            src="/static/Samba/sambaPOS.svg"
            alt="Ping Logo"
            width={40}
          />
          <Typography color="textSecondary">
            Consiste num serviço de intermediação de transferências monetárias móvel desenvolvido pela empresa
            CHUVA que permite a realização de transferências monetárias a partir do telemóvel do usuário.
            O SAMBA POS é da exclusiva responsabilidade da empresa CHUVA.
          </Typography>
          {renderStatusButton()}
        </Card>
      </Box>
      <Dialog
        id="integration-pop-up"
        maxWidth="sm"
        onClose={handleWarningPopUpClose}
        open={isWarningPopUpOpen || isLoading}
        sx={{ p: 0 }}
      >
        {renderWarningPopUp()}
      </Dialog>
      <StatusMessagePopup
        isOpen={!isLoading && isIntegrateButtonClicked}
        status={error ? popupStatus.error : popupStatus.success}
        title={error ? 'Erro' : 'Sucesso'}
        text={(error && errorMessage) || 'Integração com o SAMBA POS concluída com sucesso!'}
        handleClosePopUp={handleOkButtonClicked}
      />
    </>
  );
};

PingIntegration.propTypes = {
  error: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  companyIntegrations: PropTypes.arrayOf({
    type: PropTypes.string.isRequired
  }).isRequired,
  handleIntegrationWithPing: PropTypes.func.isRequired,
  handleGetCompanyIntegrations: PropTypes.func.isRequired,
  isCompanyIntegratedWithPing: PropTypes.func.isRequired,
  popupStatus: PropTypes.object.isRequired
};

export default PingIntegration;
