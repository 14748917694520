import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  DialogTitle,
  Box,
  useTheme
} from '@mui/material';

import CheckCircleOutlined from '../../icons/CheckCircleOutlined';
import ErrorCircleOutlined from '../../icons/ErrorCircleOutlined';
import { POPUP_STATUS } from '../../constants';

const StatusMessagePopup = (props) => {
  const theme = useTheme();
  const { isOpen, handleClosePopUp, color, status, title, text, subText } = props;

  const iconChosen = () => {
    let icon;
    if (status === POPUP_STATUS.success) {
      icon = (
        <CheckCircleOutlined style={{ width: '4.5rem' }} />
      );
    } else if (status === POPUP_STATUS.error) {
      icon = (
        <ErrorCircleOutlined style={{ width: '4.5rem' }} />
      );
    }
    return icon;
  };

  const backgroundColor = () => {
    if (status === POPUP_STATUS.success) {
      return theme.palette.primary.main;
    }
    return theme.palette.error.main;
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={isOpen}
      id="status-message-popup"
    >
      <DialogTitle
        sx={{ bgcolor: color || backgroundColor() }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          {iconChosen()}
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 2
        }}
      >
        <Typography
          variant="h6"
          align="center"
          sx={{ mb: 1 }}
          id="status-message-popup-title"
        >
          {title}
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          variant="body2"
        >
          {text}
        </Typography>
        <Typography
          color="textSecondary"
          align="center"
          variant="body1"
          sx={{
            mb: 2,
            fontWeight: 'bold'
          }}
        >
          {subText}
        </Typography>
        <Button
          id="payment-alert-ok-button"
          sx={{ width: '100%' }}
          color={status === POPUP_STATUS.error ? 'error' : 'primary'}
          size="large"
          variant="outlined"
          onClick={handleClosePopUp}
        >
          OK
        </Button>
      </DialogContent>
    </Dialog>
  );
};

StatusMessagePopup.defaultProps = {
  subText: null,
  color: null,
  text: null
};

StatusMessagePopup.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClosePopUp: PropTypes.func.isRequired,
  status: PropTypes.string.isRequired,
  text: PropTypes.string,
  subText: PropTypes.string,
  title: PropTypes.string.isRequired,
  color: PropTypes.string
};

export default StatusMessagePopup;
