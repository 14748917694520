import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { Formik } from 'formik';
import { Button, TextField, Typography, Grid, Box, Card } from '@mui/material';
import Lottie from 'react-lottie';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import animationData from '../../../lotties/loading-fail-state.json';

const LoginJWT = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { login } = useAuth();
  const { handleForgotPasswordButtonClick, navigateToLoginPage, handleRegisterButtonClick } = props;

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Digite um endereço de e-mail válido')
            .max(255)
            .required('Obrigatório'),
          password: Yup
            .string()
            .max(255)
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          await login(values.email, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }

          enqueueSnackbar('Erro', {
            anchorOrigin: {
              horizontal: 'right',
              vertical: 'top'
            },
            variant: 'error',
            autoHideDuration: 3000,
            content: (key, message) => (
              <Card
                id={key}
                width="100%"
                height="58"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', height: 58, width: 58, backgroundColor: '#fffff' }}>
                  <Lottie
                    options={defaultOptions}
                    height={102}
                    width={102}
                  />
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', paddingLeft: 2, background: ' rgba(254, 0, 0, 0.1)', height: 68, width: 152 }}>
                  <Typography
                    color="#FE0000"
                    fontWeight="600"
                  >
                    {message}
                  </Typography>
                </Box>
              </Card>
            )
          });
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email"
            placeholder="Introduzir Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Senha"
            placeholder="Introduzir Senha"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Button
            id="login-form-forgot-password-button"
            variant="text"
            onClick={handleForgotPasswordButtonClick}
            sx={{
              mt: 1
            }}
          >
            <Typography
              color="textSecondary"
              variant="body2"
            >
              Esqueci-me da senha
            </Typography>
          </Button>

          <Grid
            container
            sx={{ mt: 20 }}
          >
            <Grid
              item
              xs
            >
              <Button
                alt="Cancelar"
                variant="outlined"
                type="button"
                color="primary"
                onClick={navigateToLoginPage}
                sx={{
                  px: 6,
                  py: 1,
                  borderRadius: 4
                }}
              >
                <Typography>
                  Cancelar
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  alt="Entrar"
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{
                    px: 6,
                    py: 1,
                    borderRadius: 4
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    Entrar
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleRegisterButtonClick}
                  sx={{
                    mt: 1
                  }}
                >
                  Não tenho conta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

LoginJWT.propTypes = {
  navigateToLoginPage: PropTypes.func.isRequired,
  handleRegisterButtonClick: PropTypes.func.isRequired,
  handleForgotPasswordButtonClick: PropTypes.func.isRequired
};

export default LoginJWT;
