import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent
} from '@mui/material';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import CheckoutForm from './checkoutForm';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const StripeIframePopUpForm = (props) => {
  const {
    clientStripeToken,
    isFormOpen,
    closeForm,
    handleTriggerSuccessPaymentMessage,
    documentName
  } = props;

  const appearance = {
    theme: 'stripe'
  };
  const options = {
    clientSecret: clientStripeToken,
    appearance
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isFormOpen}
      onClose={closeForm}
    >
      <DialogTitle sx={{ mb: 0, pb: 0, textAlign: 'center' }}>
        {`Pagar ${documentName}`}
      </DialogTitle>
      <DialogContent>
        <Elements
          options={options}
          stripe={stripePromise}
        >
          <CheckoutForm
            clientToken={clientStripeToken}
            handleTriggerSuccessPaymentMessage={handleTriggerSuccessPaymentMessage}
            closeForm={closeForm}
          />
        </Elements>
      </DialogContent>
    </Dialog>
  );
};

StripeIframePopUpForm.defaultProps = {
  documentName: ''
};

StripeIframePopUpForm.propTypes = {
  isFormOpen: PropTypes.bool.isRequired,
  clientStripeToken: PropTypes.string.isRequired,
  closeForm: PropTypes.func.isRequired,
  handleTriggerSuccessPaymentMessage: PropTypes.func.isRequired,
  documentName: PropTypes.string
};

export default StripeIframePopUpForm;
