/**
 * Function to retrieve label and styling information based on order status.
 * @param {string} status - The status of the order.
 * @returns {Object|null} An object containing label, color, and backgroundColor properties corresponding to the status, or null if status is not recognized.
 */
import { ORDER_STATUS, UTILS_TEXT } from '../constants';

export const getOrderStatus = (status) => {
  let statusLabel;
  switch (status) {
    case ORDER_STATUS.DELIVERED:
      statusLabel = {
        label: UTILS_TEXT.GET_ORDER_STATUS.DELIVERED,
        color: '#21C8C3',
        backgroundColor: 'rgba(33, 200, 195, 0.1)'
      };
      break;
    case ORDER_STATUS.PENDING:
      statusLabel = {
        label: UTILS_TEXT.GET_ORDER_STATUS.PENDING,
        color: '#F1B37A',
        backgroundColor: 'rgba(241, 179, 122, 0.1)'
      };
      break;
    case ORDER_STATUS.CANCELLED:
      statusLabel = {
        label: UTILS_TEXT.GET_ORDER_STATUS.CANCELLED,
        color: '#FF5E52',
        backgroundColor: 'rgba(255, 94, 82, 0.1)'
      };
      break;
    case ORDER_STATUS.PROCESSING:
      statusLabel = {
        label: UTILS_TEXT.GET_ORDER_STATUS.PROCESSING,
        color: '#34B7CE',
        backgroundColor: 'rgba(28, 197, 220, 0.1)'
      };
      break;
    case ORDER_STATUS.RETURNED:
      statusLabel = {
        label: UTILS_TEXT.GET_ORDER_STATUS.RETURNED,
        color: 'rgba(0, 0, 0, 0.26)',
        backgroundColor: 'rgba(83, 83, 83, 0.1)'
      };
      break;
    default:
      statusLabel = null;
  }
  return statusLabel;
};
