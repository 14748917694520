import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  createFilterOptions,
  Dialog,
  TextField,
  Typography
} from '@mui/material';

import { useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { useForm } from '../../hooks/useForm';
import StatusPopUpContainer from '../StatusPopUpContainer';
import ReceiptItemList from '../receiptItem/List';
import ReceiptItemForm from '../receiptItem/Form';
import ReceiptItemSum from '../receiptItem/Sum';
import { CREATE_NEW, DOCUMENT_TYPE_CODE, FORM } from '../../constants';
import DeletePopUp from '../DeletePopUp';
import { formatCurrency } from '../../util/formatCurrency';
import CustomDatePicker from '../CustomDatePicker';

const filter = createFilterOptions();

const initialValues = {
  serie: null,
  client: null,
  date: new Date(),
  numberOfDocument: 1,
  paymentMode: undefined
};

const ReceiptForm = (props) => {
  const {
    onCancel,
    onSubmitForm,
    clients,
    series,
    invoices,
    getLastReceiptNumber,
    paymentModeDefaults,
    isOnRequest,
  } = props;

  const [serieInitialLength, setSerieInitialLength] = useState(null);
  const [serieInputValue, setSerieInputValue] = useState(null);
  const [clientInitialLength, setClientInitialLength] = useState(null);
  const [clientInputValue, setClientInputValue] = useState(null);
  const [paymentModeInputValue, setPaymentModeInputValue] = useState(null);

  const formikRef = useRef();

  useEffect(() => {
    if (!serieInitialLength && series) {
      setSerieInitialLength(series.length);
    } else if (series?.length > serieInitialLength) {
      setSerieInputValue(series[(series.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.serie = series[(series.length) - 1].id;
      }
    }
  }, [series]);

  useEffect(() => {
    if (!clientInitialLength && clients) {
      setClientInitialLength(clients.length);
    } else if (clients?.length > clientInitialLength) {
      setClientInputValue(clients[(clients.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.client = clients[(clients.length) - 1].id;
      }
    }
  }, [clients]);

  const [currentClient, setCurrentClient] = useState(null);
  const [receiptList, setReceiptList] = useState(null);
  const [availableInvoices, setAvailableInvoices] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [addAnotherInvoice, setAddAnotherInvoice] = useState(false);
  const [isInvoicePopupOpen, setIsInvoicePopupOpen] = useState(false);
  const [totalGeneral, setTotalGeneral] = useState('0');
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [receiptItemError, setReceiptItemError] = useState(false);

  const {
    openForm,
    closeForm,
    isFormOpen,
    renderForm,
    closePopup,
    isPopupOpen,
    onRequest,
    onRequestFailure,
    successMessage,
    errorMessage
  } = useForm();

  const handleDeletePopUpOpen = useCallback(() => {
    setIsDeletePopUpOpen(true);
  }, [isDeletePopUpOpen]);

  const handleDeletePopUpClose = useCallback(() => {
    setIsDeletePopUpOpen(false);
  }, [isDeletePopUpOpen]);

  const handleAddReceipt = useCallback((newInvoice) => {
    const invoiceInfo = invoices.find((invoice) => invoice.id === newInvoice.invoiceId);

    const addedInvoice = { ...newInvoice, info: invoiceInfo };

    const newAvailableInvoices = availableInvoices.filter((invoice) => invoice.id !== newInvoice.invoiceId);

    if (receiptList) {
      setReceiptList([...receiptList, addedInvoice]);
    } else {
      setReceiptList([addedInvoice]);
    }
    setAvailableInvoices(newAvailableInvoices);
    setReceiptItemError(false);
  }, [receiptList, availableInvoices]);

  const handleEditReceipt = useCallback((updatedInvoice) => {
    const invoiceInfo = invoices.find((invoice) => invoice.id === updatedInvoice.invoiceId);

    const editedReceiptList = receiptList.map(((invoice) => {
      if (updatedInvoice.invoiceId === invoice.invoiceId) {
        return { ...updatedInvoice, info: invoiceInfo };
      }
      return invoice;
    }));

    setReceiptList(editedReceiptList);
  }, [receiptList, availableInvoices]);

  const handleSelectInvoice = useCallback((invoice) => {
    setSelectedInvoice(invoice);
  }, []);

  const handleToggleAddAnotherReceipt = () => {
    setAddAnotherInvoice(!addAnotherInvoice);
  };

  const handleDeleteReceipt = useCallback(() => {
    const filtredInvoices = receiptList.filter((invoiceItem) => invoiceItem.info.id !== selectedInvoice.info.id);
    setAvailableInvoices([...availableInvoices, selectedInvoice.info]);
    setReceiptList(filtredInvoices);
    setSelectedInvoice(null);
    handleDeletePopUpClose();

    if (receiptList.length === 0) {
      setReceiptItemError(true);
    }
  }, [receiptList, selectedInvoice]);

  const handleOpenInvoiceForm = useCallback((setTouched, values) => {
    if (!values.client) {
      setTouched({
        serie: true,
        client: true,
        date: true
      });
      return;
    }
    setIsInvoicePopupOpen(true);
  }, [isInvoicePopupOpen]);

  const handleCloseInvoiceForm = useCallback(() => {
    setIsInvoicePopupOpen(false);
    handleSelectInvoice(null);
    setAddAnotherInvoice(false);
  }, [isInvoicePopupOpen]);

  const getPaymentMethods = (paymentModesList) => paymentModesList?.filter((paymentMethod) => paymentMethod.code !== 'SAMBA');

  const handleSetCurrentClient = (client) => {
    setCurrentClient(client);
  };

  useEffect(() => {
    if (currentClient) {
      const newAvailableInvoices = invoices?.filter((invoice) => invoice.clientId === currentClient.id
                                                                 && invoice?.totalGeneral?.toFixed(2) !== invoice?.receiptsValue?.toFixed(2)
                                                                 && invoice.documentType.code !== DOCUMENT_TYPE_CODE.invoice_receipt
                                                                 && !invoice.isVoided);

      setAvailableInvoices(newAvailableInvoices);
      setReceiptList(null);
    }
  }, [currentClient]);

  useMemo(() => {
    const newTotalGeneral = receiptList?.reduce(
      (acc, invoice) => acc + Number(invoice.paymentValue), 0
    );

    const newTotalGeneralFormatted = formatCurrency(newTotalGeneral || 0);
    setTotalGeneral(newTotalGeneralFormatted);
  }, [receiptList]);

  const handleDateChange = (newValue, setFieldValue) => {
    setFieldValue('date', newValue.toDate());
  };

  const [date, setDate] = useState();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={Yup
        .object()
        .shape({
          serie: Yup
            .string()
            .typeError('Obrigatório')
            .trim()
            .required('Obrigatório'),
          client: Yup
            .string()
            .trim()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          date: Yup
            .date()
            .required('Obrigatório'),
          numberOfDocument: Yup
            .number(),
          paymentMode: Yup
            .string()
            .typeError('Obrigatório')
            .trim()
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.invoices = receiptList;
          values.numberOfDocument = getLastReceiptNumber(values.serie) + 1;

          if (!values.invoices) {
            setReceiptItemError(true);
            return;
          }

          onSubmitForm(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        handleBlur,
        handleChange,
        touched,
        values,
        setFieldValue,
        setTouched
      }) => (
        <form
          id="receipt-form"
          onSubmit={handleSubmit}
        >
          <Container>
            <Box
              sx={{
                my: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
            >
              <Autocomplete
                id="receipt-form-serie-input-field"
                getOptionLabel={(option) => option.name}
                options={series || []}
                disableClearable
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    name: CREATE_NEW
                  });

                  return filtered;
                }}
                inputValue={serieInputValue || ''}
                onChange={(event, value) => {
                  setSerieInputValue(value.name);
                  setFieldValue('serie', value.id || '');

                  if (value.name === CREATE_NEW) {
                    openForm(FORM.SERIE);
                    value.name = serieInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && serieInputValue !== '') || serieInputValue.length === 1) {
                    setSerieInputValue(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.serie && errors.serie)}
                    helperText={touched.serie && errors.serie}
                    label="Série*"
                    name="serie"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
              <TextField
                id="receipt-form-number-of-document-input-field"
                error={Boolean(touched.numberOfDocument && errors.numberOfDocument)}
                fullWidth
                helperText={touched.numberOfDocument && errors.numberOfDocument}
                disabled
                label="Número*"
                name="numberOfDocument"
                onBlur={handleBlur}
                onChange={handleChange}
                value={getLastReceiptNumber(values.serie) + 1 || 1}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mb: 2 }}>
              <Autocomplete
                id="receipt-form-client-input-field"
                getOptionLabel={(option) => option.name}
                options={clients || []}
                disableClearable
                inputValue={clientInputValue || ''}
                onChange={(event, value) => {
                  setClientInputValue(value.name);
                  setFieldValue('client', value.id || '');
                  handleSetCurrentClient(value);

                  if (value.name === CREATE_NEW) {
                    openForm(FORM.CLIENT);
                    value.name = clientInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && clientInputValue !== '') || clientInputValue.length === 1) {
                    setClientInputValue(newInputValue);
                  }
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    name: CREATE_NEW
                  });

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.client && errors.client)}
                    helperText={touched.client && errors.client}
                    label="Cliente*"
                    name="client"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box
              sx={{
                my: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
            >
              <CustomDatePicker
                id="receipt-form-date-picker"
                label="Data"
                onChange={(newValue) => {
                  setDate(newValue);
                  handleDateChange(newValue, setFieldValue)}
                }
                values={date}
              />

              <Autocomplete
                id="receipt-form-payment-mode-input-field"
                getOptionLabel={(option) => option.code}
                options={getPaymentMethods(paymentModeDefaults) || []}
                disableClearable
                inputValue={paymentModeInputValue || ''}
                onChange={(event, value) => {
                  setPaymentModeInputValue(value.code);
                  setFieldValue('paymentMode', value.id || '');
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && paymentModeInputValue !== '') || paymentModeInputValue.length === 1) {
                    setPaymentModeInputValue(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.paymentMode && errors.paymentMode)}
                    helperText={touched.paymentMode && errors.paymentMode}
                    label="Modo de Pagamento*"
                    name="paymentMode"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{ py: 4 }}>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista de Recibos
              </Typography>
              <ReceiptItemList
                invoiceList={receiptList}
                onOpenForm={() => handleOpenInvoiceForm(setTouched, values)}
                onSelectInvoice={handleSelectInvoice}
                onDelete={handleDeletePopUpOpen}
                sx={{ my: 1 }}
              />
              <Typography
                id="receipt-list-error"
                color="error"
                variant="overline"
                hidden={!receiptItemError}
              >
                Obrigatório
              </Typography>
              <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleCloseInvoiceForm}
                open={isInvoicePopupOpen}
              >
                {isInvoicePopupOpen && (
                <ReceiptItemForm
                  onComplete={handleCloseInvoiceForm}
                  onCancel={handleCloseInvoiceForm}
                  onAdd={handleAddReceipt}
                  onEdit={handleEditReceipt}
                  onAddAnother={handleAddReceipt}
                  toggleAddAnother={handleToggleAddAnotherReceipt}
                  isAddAnother={addAnotherInvoice}
                  invoice={selectedInvoice}
                  selectInvoice={handleSelectInvoice}
                  openForm={openForm}
                  invoices={availableInvoices}
                />
                )}
              </Dialog>
            </Box>

            <ReceiptItemSum
              totalGeneral={totalGeneral}
            />

            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2,
                mt: 4
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Voltar
              </Button>
              <Button
                id="receipt-form-save-button"
                color="primary"
                disabled={isOnRequest}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
            <Dialog
              fullWidth
              maxWidth="sm"
              onClose={closeForm}
              open={isFormOpen}
            >
              {isFormOpen && (
                renderForm()
              )}
            </Dialog>
            <Dialog
              maxWidth="xs"
              onClose={handleDeletePopUpClose}
              open={isDeletePopUpOpen}
            >
              {isDeletePopUpOpen && (
              <DeletePopUp
                onCancel={handleDeletePopUpClose}
                onDelete={handleDeleteReceipt}
              />
              )}
            </Dialog>
            <StatusPopUpContainer
              isOpen={isPopupOpen}
              isLoading={onRequest}
              hasError={onRequestFailure}
              onClose={closePopup}
              onErrorMessage={errorMessage}
              onSuccessMessage={successMessage}
              onLoadingMessage="Carregando..."
            />
          </Container>
        </form>
      )}
    </Formik>
  );
};

ReceiptForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  getLastReceiptNumber: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  invoices: PropTypes.arrayOf({
    id: PropTypes.string,
    clientName: PropTypes.string,
    totalGeneral: PropTypes.number
  }).isRequired,
  paymentModeDefaults: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  isOnRequest: PropTypes.bool.isRequired,
};

export default ReceiptForm;
