import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import { SLICE_TEXTS } from '../constants';

const initialState = {
  orders: null,
  isLoading: false,
  errorMessage: null,
  successMessage: null,
  isLoadingUpdate: false
};

const slice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getOrdersSuccess: (state, { payload }) => {
      state.orders = payload;
      state.isLoading = false;
    },
    _getOrdersFailure: (state) => {
      state.orders = [];
      state.isLoading = false;
    },
    _update: (state) => {
      state.isLoadingUpdate = true;
    },
    _updateOrdersSuccess: (state, { payload }) => {
      state.orders = payload;
      state.isLoadingUpdate = false;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
      state.errorMessage = null;
    },
    _updateOrdersFailure: (state, { payload }) => {
      state.errorMessage = payload;
      state.successMessage = null;
      state.isLoadingUpdate = false;
    },
    _clearMessages: (state) => {
      state.successMessage = null;
      state.errorMessage = null;
    }
  }
});

const {
  _get,
  _getOrdersSuccess,
  _getOrdersFailure,
  _update,
  _updateOrdersSuccess,
  _clearMessages,
  _updateOrdersFailure,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'orders/fetch',
  async (_, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;

    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/orders`);

      const orders = data.map((order) => ({
        id: order.id,
        client: {
          firstName: order.customer.first_name,
          lastName: order.customer.last_name,
          email: order.customer.email,
          note: order.customer.note,
          phoneNumber: order.customer.phone_number,
          countryCode: order.customer.country_code,
          deliveryAddress: order.customer.delivery_address
        },
        paymentMode: order.payment_mode.code,
        date: order.date,
        orderStatus: order.status,
        orderItems: order.items,
        valueOfTax: order.value_of_tax,
        valueWithoutTax: order.value_without_tax,
        valueWithTax: order.value_with_tax,
        total: order.total_general
      }));

      dispatch(_getOrdersSuccess(orders));
    } catch (error) {
      dispatch(_getOrdersFailure());
    }
  }
);

export const update = createAsyncThunk(
  'orders/update',
  async (data, { dispatch, getState }) => {
    const { id } = getState().companies.currentCompany;
    const { orders } = getState().orders;
    const { status, id: orderId } = data;

    dispatch(_update());
    try {
      await axios.patch(`/companies/${id}/orders/${orderId}`, { status });

      const updatedOrder = orders.map((order) => {
        if (order.id === data.id) {
          return {
            ...order,
            orderStatus: status
          };
        }
        return order;
      });

      dispatch(_updateOrdersSuccess(updatedOrder));
      dispatch(_setSuccessMessage(SLICE_TEXTS.COMMON.SUCCESS_MESSAGE));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_updateOrdersFailure(errorMessage));
    }
  }
);

export const clearMessages = createAsyncThunk(
  'orders/clear_messages',
  async (_, { dispatch }) => {
    dispatch(_clearMessages());
  }
);
