import PropTypes from 'prop-types';
import { Box, Card, Typography } from '@mui/material';

const BankAccountViewSummaryDetails = (props) => {
  const { bankAccount } = props;

  return (
    <Card sx={{ p: 2, mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          color="textSecondary"
        >
          Banco
        </Typography>
        <Typography
          color="textSecondary"
        >
          {bankAccount[0].bankName}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          color="textSecondary"
        >
          NIB
        </Typography>
        <Typography
          color="textSecondary"
        >
          {bankAccount[0].nib}
        </Typography>
      </Box>
    </Card>
  );
};

BankAccountViewSummaryDetails.propTypes = {
  bankAccount: PropTypes.shape({
    id: PropTypes.string,
    bankName: PropTypes.string,
    accountNumber: PropTypes.string
  }).isRequired
};

export default BankAccountViewSummaryDetails;
