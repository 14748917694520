import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import axiosLess from '../lib/axiosLess';
import analytics, { events } from '../services/analytics';
import errorMessageHandler from '../util/errorMessageHandler';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  onRequest: null,
  onRequestSuccess: null,
  onRequestFailure: null,
  onGetRequest: null,
  onGetRequestSuccess: null,
  onGetRequestFailure: null,
  successMessage: null,
  errorMessage: null,
  roles: [],
  companyUsers: [],
  onRequestCompanyUsers: null,
  onRequestCompanyUsersSuccess: null,
  onRequestCompanyUsersFailure: null
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosLess.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
    delete axiosLess.defaults.headers.common.Authorization;
  }
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    _initializeSuccess: (state, { payload }) => {
      state.isInitialized = true;
      state.isAuthenticated = true;
      state.user = payload;
    },
    _initializeFailure: (state) => {
      state.isInitialized = true;
      state.isAuthenticated = false;
      state.user = null;
    },
    _loginSuccess: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
    },
    _loginFailure: (state) => {
      state.isAuthenticated = false;
    },
    _registerSuccess: (state, { payload }) => {
      state.isAuthenticated = true;
      state.user = payload;
    },
    _registerFailure: (state) => {
      state.isAuthenticated = false;
    },
    _logoutSuccess: (state) => {
      state.isAuthenticated = false;
    },
    _forgotPassword: (state) => {
      state.onRequest = true;
    },
    _forgotPasswordSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _forgotPasswordFailure: (state) => {
      state.onRequest = false;
      state.onRequestFailure = true;
    },
    _resetPassword: (state) => {
      state.onRequest = true;
    },
    _resetPasswordSuccess: (state, { payload }) => {
      state.isAuthenticated = true;
      state.onRequest = false;
      state.onRequestFailure = false;
      state.user = payload;
    },
    _resetPasswordFailure: (state) => {
      state.isAuthenticated = false;
      state.onRequest = false;
      state.onRequestFailure = true;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setErrorMessage: (state, { payload }) => {
      state.errorMessage = payload;
    },
    _get: (state) => {
      state.onGetRequest = true;
      state.onGetRequestFailure = false;
      state.onGetRequestSuccess = false;
    },
    _getSuccess: (state, { payload }) => {
      state.onGetRequest = false;
      state.roles = payload;
      state.onGetRequestFailure = false;
      state.onGetRequestSuccess = true;
    },
    _getFailure: (state) => {
      state.onGetRequest = false;
      state.onGetRequestFailure = true;
      state.onGetRequestSuccess = false;
    },
    _addUser: (state) => {
      state.onRequest = true;
      state.onRequestFailure = false;
      state.onRequestSuccess = false;
    },
    _addUserSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
      state.onRequestSuccess = true;
      state.errorMessage = null;
    },
    _addUserFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.onRequestSuccess = false;
      state.errorMessage = payload;
    },
    _clearState: (state) => {
      state.onRequest = null;
      state.onRequestFailure = null;
      state.onRequestSuccess = null;
      state.errorMessage = null;
      state.onRequestCompanyUsers = null;
      state.onRequestCompanyUsersSuccess = null;
      state.onRequestCompanyUsersFailure = null;
    },
    _getAllUsers: (state) => {
      state.onRequestCompanyUsers = true;
      state.onRequestCompanyUsersSuccess = false;
      state.onRequestCompanyUsersFailure = false;
    },
    _getAllUsersSuccess: (state, { payload }) => {
      state.onRequestCompanyUsers = false;
      state.companyUsers = payload;
      state.onRequestCompanyUsersSuccess = true;
      state.onRequestCompanyUsersFailure = false;
    },
    _getAllUsersFailed: (state, { payload }) => {
      state.onRequestCompanyUsers = false;
      state.onRequestCompanyUsersSuccess = false;
      state.onRequestCompanyUsersFailure = true;
      state.errorMessage = payload;
    }
  }
});

const {
  _initializeSuccess,
  _initializeFailure,
  _loginSuccess,
  _loginFailure,
  _registerSuccess,
  _registerFailure,
  _logoutSuccess,
  _forgotPassword,
  _forgotPasswordSuccess,
  _forgotPasswordFailure,
  _resetPassword,
  _resetPasswordSuccess,
  _resetPasswordFailure,
  _setSuccessMessage,
  _setErrorMessage,
  _get,
  _getSuccess,
  _getFailure,
  _addUser,
  _addUserSuccess,
  _addUserFailure,
  _clearState,
  _getAllUsersSuccess,
  _getAllUsersFailed,
  _getAllUsers
} = slice.actions;

export default slice.reducer;

export const initialize = createAsyncThunk(
  'user/initialize',
  async (_, { dispatch }) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken) {
        setSession(accessToken);
        const response = await axios.get('/users');

        const user = response.data;

        if (user) {
          analytics.setUserId(user.id);
        } else {
          analytics.setUserId(null);
        }

        dispatch(_initializeSuccess(user));
      } else {
        dispatch(_initializeFailure());
      }
    } catch (error) {
      dispatch(_initializeFailure());
    }
  }
);

export const login = createAsyncThunk(
  'user/login',
  async ({ email, password }, { dispatch }) => {
    try {
      const { data } = await axios.post('/sessions', {
        email,
        password
      });

      const { token } = data;

      setSession(token);

      const response = await axios.get('/users');

      const user = response.data;

      if (user) {
        analytics.setUserId(user.id);
      } else {
        analytics.setUserId(null);
      }

      dispatch(_loginSuccess(user));
      analytics.dispatchEvent(events.logIn('email'));
    } catch (error) {
      dispatch(_loginFailure());
    }
  }
);

export const logout = createAsyncThunk(
  'user/logout',
  async (_, { dispatch }) => {
    setSession(null);
    dispatch(_logoutSuccess());
    dispatch({ type: 'USER_LOGOUT' });
  }
);

export const register = createAsyncThunk(
  'user/register',
  async (data, { dispatch }) => {
    try {
      const user = {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        password: data.password
      };

      const response = await axios.post('/users', user);

      const { id, token } = response.data;

      analytics.setUserId(id);
      analytics.dispatchEvent(events.signUp('email'));

      setSession(token);

      dispatch(_registerSuccess(
        {
          id,
          email: data.email,
          first_name: data.firstName,
          last_name: data.lastName
        }
      ));
    } catch (error) {
      dispatch(_registerFailure());
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'user/forgot_password',
  async (data, { dispatch }) => {
    dispatch(_forgotPassword());

    try {
      const email = data;
      await axios.post('/forgot_password', { email });
      dispatch(_forgotPasswordSuccess());
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (e) {
      dispatch(_forgotPasswordFailure());
      dispatch(_setErrorMessage('Erro ao enviar o Email!'));
    }
  }
);

export const resetPassword = createAsyncThunk(
  'user/reset_password',
  async ({ password, token }, { dispatch }) => {
    dispatch(_resetPassword());
    try {
      const response = await axios.post(`/reset_password/${token}`, { password });

      const authToken = response.data.token;

      setSession(authToken);

      const getUser = await axios.get('/users');

      const user = getUser.data;

      if (user) {
        analytics.setUserId(user.id);
      } else {
        analytics.setUserId(null);
      }

      dispatch(_resetPasswordSuccess(user));
      dispatch(_setSuccessMessage('Faça login ou consulte o seu email!'));
    } catch (e) {
      dispatch(_resetPasswordFailure());
      dispatch(_setErrorMessage('Ocorreu um erro, tente mais tarde'));
    }
  }
);

export const fetchUserTypes = createAsyncThunk(
  'user/user_roles',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get('/user_roles');
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const assignUserRole = createAsyncThunk(
  'user/assign_user_role',
  async (data, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;
    dispatch(_addUser());
    try {
      const body = {
        email: data.email,
        role_id: data.typeOfUser
      };
      await axios.post(`/companies/${companyId}/users`, body);
      dispatch(_addUserSuccess());
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_addUserFailure(errorMessage));
    }
  }
);

export const clearState = createAsyncThunk(
  'user/clear',
  async (_, { dispatch }) => {
    dispatch(_clearState());
  }
);

export const fetchCompanyUsers = createAsyncThunk(
  'user/fetch_company_users',
  async (data, { dispatch, getState }) => {
    dispatch(_getAllUsers());
    const { userType } = data;
    const companyId = getState().companies.currentCompany.id;
    try {
      const response = await axios.get(`/companies/${companyId}/users?role=${userType}`);

      const companyUsers = response.data.map((companyUser) => ({
        id: companyUser.id,
        type: companyUser.role_id,
        firstName: companyUser.first_name,
        lastName: companyUser.last_name,
        email: companyUser.email
      }));

      dispatch(_getAllUsersSuccess(companyUsers));
    } catch (error) {
      dispatch(_getAllUsersFailed(errorMessageHandler(error)));
    }
  }
);
