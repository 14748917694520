import { Helmet } from 'react-helmet-async';
import { useLayoutEffect } from 'react';
import {
  Box,
  Container,
  Grid,
  Typography
} from '@mui/material';

import { format, parseISO } from 'date-fns';

import useSettings from '../../hooks/useSettings';
import EmptyList from '../../components/empty_list';
import WarningAlertOnPage from '../../components/AlertOnPage';
import SambaPaymentsTable from '../../components/SambaPayments/Table';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchPayments } from '../../slices/sambaPayments';

const SambaPayment = () => {
  const dispatch = useDispatch();

  const { settings } = useSettings();

  const { documents } = useSelector((state) => state).sambaPayments;
  const { currentCompany } = useSelector((state) => state).companies;

  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchPayments());
    }
  }, [dispatch, currentCompany]);

  const returnResult = () => {
    if (documents && documents.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <SambaPaymentsTable
            documents={documents}
            formatDate={formatDate}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Pagamento feito via Samba"
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>Pagamentos Via Samba</title>
      </Helmet>
      {
        currentCompany.integrations?.eFatura?.status === false && (
          <WarningAlertOnPage
            message="Esta empresa não está integrada na plataforma e-Fatura."
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: 'background.paper'
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Pagamentos via Samba
              </Typography>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista de Recibos
              </Typography>
            </Grid>
          </Grid>
          {returnResult()}
        </Container>
      </Box>
    </>
  );
};

export default SambaPayment;
