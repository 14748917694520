import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const ClientViewDetails = (props) => {
  const { client, onEdit, onDelete, onCancel, ...other } = props;
  return (
    <Card {...other}>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Cliente
        </Typography>
      </Box>
      <Scrollbar>
        <Box sx={{ p: 3 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Nome
                </TableCell>
                <TableCell sx={{ fontWeight: 400 }}>
                  {client.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Nome Fiscal
                </TableCell>
                <TableCell>
                  {client.fiscalName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  NIF do Cliente
                </TableCell>
                <TableCell>
                  {client.nif}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  País
                </TableCell>
                <TableCell>
                  {client.countryName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Endereço
                </TableCell>
                <TableCell>
                  {client.address}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Telefone
                </TableCell>
                <TableCell>
                  {`${client.phoneNumber?.country_code || ''} ${client.phoneNumber?.phone_number || ''}`}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Tipo
                </TableCell>
                <TableCell>
                  {client.internal ? 'Interno' : 'Coletivo'}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Entidade
                </TableCell>
                <TableCell>
                  {client.isIndividual ? 'Singular' : 'Coletivo'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={onDelete}
        >
          Apagar
        </Button>
        <Button
          id="view-client-details-edit-button"
          color="primary"
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={onEdit}
        >
          Editar
        </Button>
      </Box>
    </Card>
  );
};

ClientViewDetails.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    nif: PropTypes.number,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    internal: PropTypes.bool,
    isIndividual: PropTypes.bool,
    address: PropTypes.string,
    phoneNumber: PropTypes.shape({
      phone_number: PropTypes.string,
      country_code: PropTypes.string
    })
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ClientViewDetails;
