import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip,
  alpha,
  useTheme
} from '@mui/material';

import Scrollbar from '../Scrollbar';
import { USER_TYPES } from '../../constants';

const UserManagementTable = (props) => {
  const { companyUsers, roles, ...other } = props;

  const theme = useTheme();

  const userType = (type) => {
    let result;
    if (type === USER_TYPES.attendee) {
      result = {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        label: roles.find((role) => role?.id === type)?.pt
      };
    }
    return result;
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box id="user-management-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Tipo de utilizador
                </TableCell>
                <TableCell>
                  Email
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                companyUsers.map((user, index) => (
                  <TableRow>
                    <TableCell>
                      {`${user.firstName} ${user.lastName}`}
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="medium"
                        style={{
                          color: userType(user.type).color,
                          backgroundColor: userType(user.type).backgroundColor
                        }}
                        label={userType(user.type).label}
                      />
                    </TableCell>
                    <TableCell id={`user-${index}-email`}>
                      {user.email}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

UserManagementTable.propTypes = {
  companyUsers: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired
  }).isRequired,
  roles: PropTypes.isRequired
};

export default UserManagementTable;
