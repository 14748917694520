function calculateValueWithoutTax(invoiceItems = []) {
  let total = 0;

  invoiceItems.map((invoiceItem) => {
    const sum = invoiceItem.price * invoiceItem.quantity;

    total += sum;
    return total;
  });
  return total;
}

export default calculateValueWithoutTax;
