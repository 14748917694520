import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { addDays, format, parseISO, isAfter, isBefore } from 'date-fns';
import { clone } from 'lodash';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import useSettings from '../../../hooks/useSettings';
import { useDispatch, useSelector } from '../../../store';
import { fetch as fetchReturnNotes, create as createReturnNote, sendReturnNotesEmail, print as printReturnNote } from '../../../slices/returnNotes';
import { fetch as fetchInvoices } from '../../../slices/invoices';
import { fetch as fetchInvoiceReceipts } from '../../../slices/invoiceReceipts';
import { fetch as fetchSeries } from '../../../slices/series';

import { fetch as fetchFiscalYears } from '../../../slices/fiscalYear';
import { fetch as fetchClients } from '../../../slices/clients';
import { fetch as fetchIssueReasons } from '../../../slices/issueReasons';
import { fetch as fetchDocumentTypes } from '../../../slices/documentTypes';
import { fetch as fetchPaymentPeriod } from '../../../slices/paymentPeriod';
import { fetch as fetchPaymentMode } from '../../../slices/paymentMode';
import { fetch as fetchItems } from '../../../slices/itens';
import { fetch as fetchUnit } from '../../../slices/units';
import { fetch as fetchTaxes } from '../../../slices/taxes';
import { retrySendDocument as retrySendReturnNote } from '../../../slices/eFatura';

import Table from '../../../components/returnNote/Table';
import ReturnNoteForm from '../../../components/returnNote/Form';
import ViewDetails from '../../../components/returnNote/ViewDetails';
import Toast from '../../../components/Toast';
import EmptyList from '../../../components/empty_list';
import LoadingPopUp from '../../../components/LoadingPopUp';
import WarningAlertOnPage from '../../../components/AlertOnPage';

import Scrollbar from '../../../components/Scrollbar';

import { DOCUMENTS, TOAST_TYPE, ISSUE_REASONS, ISSUE_REASONS_DOCS, EFATURA_BANNER_MESSAGE } from '../../../constants';
import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';

const ReturnNote = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedReturnNote, setSelectedReturnNote] = useState(null);
  const [returnNoteIssueReasons, setReturnNoteIssueReasons] = useState([]);

  const { settings } = useSettings();

  const dispatch = useDispatch();
  const { returnNotes, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).returnNotes;
  const { invoices } = useSelector((state) => state).invoices;
  const { invoiceReceipts } = useSelector((state) => state).invoiceReceipts;
  const { series } = useSelector((state) => state).series;
  const { documentTypes } = useSelector((state) => state).documentTypes;
  const { paymentPeriods } = useSelector((state) => state).paymentPeriod;
  const { paymentModes } = useSelector((state) => state).paymentMode;
  const { clients } = useSelector((state) => state).clients;
  const { issueReasons, issueReasonsFormatted } = useSelector((state) => state).issueReasons;
  const { units } = useSelector((state) => state).units;
  const { taxes } = useSelector((state) => state).taxes;
  const { itens } = useSelector((state) => state).itens;
  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state).companies;
  const { retrySendDocumentSuccess: retrySendReturnNoteSuccess } = useSelector((state) => state).eFatura;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [returnNoteStorage, setReturnNoteStorage] = useLocalStorage('returnNoteStorage', null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftKey, setDraftKey] = useState(null);
  const [documents, setDocuments] = useState(null);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchReturnNotes()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchInvoices()),
        dispatch(fetchInvoiceReceipts()),
        dispatch(fetchIssueReasons()),
        dispatch(fetchSeries()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchClients()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchPaymentPeriod()),
        dispatch(fetchPaymentMode()),
        dispatch(fetchItems()),
        dispatch(fetchUnit()),
        dispatch(fetchTaxes())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setSelectedDraft(null);
    setDraftKey(Math.random());
    setIsAddMode(true);
  };

  const handleEditDraft = (draft, keyDraft) => {
    setIsAddMode(true);
    dispatch(fetchSeries());
    setSelectedDraft(draft);
    setDraftKey(keyDraft);
  };

  const handleDeleteDraft = (draftId) => {
    const draftsReturnNoteStorage = clone(returnNoteStorage);
    const indexCompany = draftsReturnNoteStorage.returnNotes.findIndex((company) => currentCompany.id in company);
    const draftIndex = draftsReturnNoteStorage.returnNotes[indexCompany][currentCompany.id].findIndex((draft) => draftId in draft);

    if (draftsReturnNoteStorage.returnNotes[indexCompany][currentCompany.id].length <= 1) {
      draftsReturnNoteStorage.returnNotes.splice(indexCompany, 1);
    } else {
      draftsReturnNoteStorage.returnNotes[indexCompany][currentCompany.id].splice(draftIndex, 1);
    }
    setReturnNoteStorage(draftsReturnNoteStorage);
  };

  const handleUpdateSelectedReturnNote = () => {
    if (selectedReturnNote) {
      const returnNoteUpdate = returnNotes.find((returnNote) => returnNote.id === selectedReturnNote?.id);
      setSelectedReturnNote(returnNoteUpdate);
    }
  };

  const handleDetailOpen = (returnNote) => {
    setSelectedReturnNote(returnNote);
    setIsDetailsModalOpen(true);
  };

  const handleDuplicate = (returnNote) => {
    setSelectedReturnNote(returnNote);
    handleNewClick();
  };

  const handleDetailClose = () => {
    setIsDetailsModalOpen(false);
    setSelectedReturnNote(null);
  };

  const handleModalClose = () => {
    setIsAddMode(false);
    setSelectedReturnNote(null);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedReturnNote();
    setIsPopUpOpen(false);
  };

  const handleCreate = (returnNote) => {
    handlePopUpOpen();
    dispatch(createReturnNote({
      ...returnNote,
      documentType: documentTypes.find((documentType) => documentType.code === DOCUMENTS.return_note.code).id
    }));
  };

  /**
   * Send Email
   * @param {string} email - Email to send the returnNote email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendReturnNotesEmail({ id: selectedReturnNote.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const getPaymentPeriodRange = (values) => {
    if (paymentPeriods) {
      return format(addDays(values?.date, paymentPeriods.find((paymentPeriod) => paymentPeriod?.id === values?.paymentPeriod)?.numberOfDay || 0), 'dd/MM/yyyy');
    }
    return format(values?.date, 'dd/MM/yyyy');
  };

  const getNextReturnNoteNumber = (serieId) => {
    const getReturnNotes = returnNotes ? returnNotes.filter((returnNote) => returnNote.serie === serieId) : 0;

    if ((!getReturnNotes || getReturnNotes.length === 0) && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);

      if (getSerie?.serialNumberConfig?.returnNoteLastDocumentNumber) {
        return Number(getSerie.serialNumberConfig.returnNoteLastDocumentNumber) + 1;
      }

      return Number(getSerie?.lastDocumentNumber) + 1;
    }

    return Number(getReturnNotes[getReturnNotes.length - 1]?.number) + 1;
  };

  /**
   * isBefore is a function that returns true if the date is before the given date.
   * isAfter is a function that returns true if the date is after the given date.
   * @param {Date} date
   * @param {Object} serie
   */
  const isInSerieDateRange = (date, serie) => (isBefore(date, new Date(serie?.endDateRaw)) && isAfter(date, new Date(serie?.startDateRaw)));

  useEffect(() => {
    const docs = [];
    if (invoices?.length > 0) {
      docs.push(...invoices.map((invoice) => ({
        id: invoice.id,
        code: DOCUMENTS.invoice.code,
        name: DOCUMENTS.invoice.name,
        client: invoice.clientId,
        number: invoice.number,
        date: invoice.date,
        items: invoice.invoiceItems,
        totalGeneral: invoice.totalGeneral,
        isVoided: invoice.isVoided
      })));
    }

    if (invoiceReceipts?.length > 0) {
      docs.push(...invoiceReceipts.map((invoiceReceipt) => ({
        id: invoiceReceipt.id,
        code: DOCUMENTS.invoice_receipt.code,
        name: DOCUMENTS.invoice_receipt.name,
        client: invoiceReceipt.clientId,
        date: invoiceReceipt.date,
        number: invoiceReceipt.number,
        items: invoiceReceipt.invoiceItems,
        totalGeneral: invoiceReceipt.totalGeneral,
        isVoided: invoiceReceipt.isVoided
      })));
    }
    setDocuments(docs);
  }, [invoices, invoiceReceipts]);

  const handleRetrySendReturnNote = (returnNoteId) => {
    const returnNoteInfo = { id: returnNoteId, documentType: DOCUMENTS.return_note.name };
    dispatch(retrySendReturnNote(returnNoteInfo));
  };

  function returnResult() {
    const validIndex = 0;
    const companyIndex = returnNoteStorage?.returnNotes?.findIndex((company) => currentCompany.id in company);
    let companyDrafts = null;
    if (companyIndex >= validIndex) companyDrafts = returnNoteStorage?.returnNotes[companyIndex][currentCompany.id];
    const hasCompanyDrafts = companyIndex >= validIndex && companyDrafts?.length > 0;

    if ((returnNotes && returnNotes.length > 0) || (companyIndex >= validIndex && hasCompanyDrafts)) {
      return (
        <Box sx={{ mt: 3 }}>
          <Table
            draftsStorage={companyIndex >= validIndex ? companyDrafts : null}
            deleteDraft={handleDeleteDraft}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
            returnNotes={returnNotes}
            onOpenDetails={handleDetailOpen}
            onClick={handleEditDraft}
            onView={handleDetailOpen}
            onDuplicate={handleDuplicate}
            handleRetrySendDocument={handleRetrySendReturnNote}
            retrySendReturnNoteSuccess={retrySendReturnNoteSuccess}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Nota de Devolução"
        buttonText="Criar Nota de Devolução"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  const handlePrint = () => {
    dispatch(printReturnNote(selectedReturnNote));
  };

  useEffect(() => {
    setReturnNoteIssueReasons(issueReasons?.filter((issueReason) => ISSUE_REASONS[issueReason.code]?.includes(ISSUE_REASONS_DOCS.returnNote)));
  }, [issueReasons]);

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>Nota de Devolução | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {!isAddMode ? 'Nota de Devolução' : 'Nova Nota de Devolução'}
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Nota de Devolução
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                id="return-note-new-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }

          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <ReturnNoteForm
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreate}
              series={series}
              clients={clients}
              paymentPeriods={paymentPeriods}
              documentTypes={documentTypes}
              paymentModes={paymentModes}
              units={units}
              taxes={taxes}
              items={itens}
              returnNote={selectedReturnNote}
              isInSerieDateRange={isInSerieDateRange}
              getNextReturnNoteNumber={getNextReturnNoteNumber}
              draftId={draftKey}
              returnNoteDraft={selectedDraft}
              returnNoteStorage={returnNoteStorage}
              setReturnNoteStorage={setReturnNoteStorage}
              handleDeleteDraft={handleDeleteDraft}
              currentCompany={currentCompany}
              issueReasons={returnNoteIssueReasons}
              documents={documents}
              getPaymentPeriodRange={getPaymentPeriodRange}
              documentName={DOCUMENTS.return_note.name}
              issueReasonTypes={issueReasonsFormatted}
              isOnRequest={onRequest}
            />
            )}
          </Box>

        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleDetailClose}
          open={Boolean(isDetailsModalOpen && selectedReturnNote)}
        >
          <Scrollbar>
            {Boolean(isDetailsModalOpen && selectedReturnNote) && (
            <ViewDetails
              currentCompany={currentCompany}
              title="Nota de Devolução"
              returnNote={{ ...selectedReturnNote, dateFormatted: format(parseISO(selectedReturnNote.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedReturnNote.date), selectedReturnNote.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
              onCancel={handleDetailClose}
              handleSendEmail={handleSendEmail}
              handlePrintButtonClick={handlePrint}
              documents={documents}
              issueReasonTypes={issueReasonsFormatted}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default ReturnNote;
