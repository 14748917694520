import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  debitNotes: null,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  debitNotePdf: null
};

const slice = createSlice({
  name: 'debitNotes',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.debitNotes = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.debitNotes = null;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.debitNotes === null) {
        state.debitNotes = [];
      }
      state.debitNotes.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendDebitNoteEmail: (state) => {
      state.onRequest = true;
    },
    _sendDebitNoteEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendDebitNoteEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setDebitNotePdf: (state, { payload }) => {
      state.debitNotePdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage,
  _sendDebitNoteEmail,
  _sendDebitNoteEmailFailure,
  _sendDebitNoteEmailSuccess,
  _setDebitNotePdf
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'debitNote/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/debit_notes`);
      const debitNotes = data.map((debitNote) => ({
        id: debitNote.id,
        documentType: debitNote.document_type ? {
          id: debitNote.document_type.id,
          code: debitNote.document_type.code,
          name: debitNote.document_type.name
        } : {},
        clientName: debitNote.customer.name,
        clientId: debitNote.customer.id,
        issueReason: {
          id: debitNote.issue_reason?.id,
          description: debitNote.issue_reason?.description,
          code: debitNote.issue_reason?.code
        },
        serieCode: debitNote.serial_number.code,
        paymentPeriod: {
          id: debitNote.payment_period.id,
          code: debitNote.payment_period.code,
          name: debitNote.payment_period.name,
          numberOfDay: debitNote.payment_period.number_of_day
        },
        paymentMode: {
          id: debitNote.payment_mode.id,
          code: debitNote.payment_mode.code,
          name: debitNote.payment_mode.name
        },
        efaturaData: {
          id: debitNote.efatura_data.id,
          status: debitNote.efatura_data.status,
          errorCode: debitNote.efatura_data.error_code,
          errorMessage: debitNote.efatura_data.error_message
        },
        date: debitNote.date,
        serie: debitNote.serial_number.id,
        serieName: debitNote.serial_number.name,
        commentary: debitNote.commentary,
        valueWithoutTax: debitNote.value_without_tax,
        totalTax: debitNote.value_of_tax,
        isVoided: debitNote.is_voided,
        valueWithTax: debitNote.value_with_tax,
        discount: debitNote.discount,
        totalGeneral: debitNote.total_general,
        number: debitNote.invoice_number || debitNote.number,
        receiptsValue: debitNote.receipts_values,
        paymentStatus: debitNote.payment_status,
        items: debitNote.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      }));

      dispatch(_getSuccess(debitNotes));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'debitNote/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const debitNoteItems = data.items.map((debitNoteItem) => ({
        item_id: debitNoteItem.item,
        quantity: Number(debitNoteItem.quantity),
        unit_code: debitNoteItem.unit,
        price: Number(debitNoteItem.price),
        tax_id: debitNoteItem.tax,
        discount: Number(debitNoteItem.discount),
        item_description: debitNoteItem.description
      }));

      const debitNote = {
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client,
        issue_reason_id: data.issueReason,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        items: debitNoteItems
      };

      const response = await axios.post(`/companies/${companyId}/debit_notes`, debitNote);
      const newDebitNote = {
        id: response.data.id,
        clientName: response.data.customer.name,
        issueReason: {
          id: response.data.issue_reason?.id,
          description: response.data.issue_reason?.description,
          code: response.data.issue_reason?.code
        },
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieCode: response.data.serial_number.code,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalIva: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        isVoided: response.data.is_voided,
        number: response.data.number,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        paymentStatus: response.data.payment_status,
        items: response.data.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      };

      analytics.dispatchEvent(events.debitNoteCreated());

      dispatch(_createOrUpdatingSuccess(newDebitNote));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const sendDebitNoteEmail = createAsyncThunk(
  'debitNote/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendDebitNoteEmail());

    try {
      await axios.post(`/companies/${companyId}/debit_notes/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.debitNoteEmailed());

      dispatch(_sendDebitNoteEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendDebitNoteEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'debitNote/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/debit_notes/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.debitNoteDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'debitNote/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/debit_notes/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setDebitNotePdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'debitNote/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { debitNotes } = getState().debitNotes;

      const updatedDebitNotes = debitNotes.map((debitNote) => {
        const debitNoteUpdated = data.find((i) => i.id === debitNote.id);

        if (debitNoteUpdated) {
          return { ...debitNote, receiptsValue: debitNote.receiptsValue + debitNoteUpdated.value };
        }

        return debitNote;
      });

      dispatch(_getSuccess(updatedDebitNotes));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
