import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider } from '@mui/material/styles';
import './i18n';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'dayjs/locale/pt';
import GlobalStyles from './components/GlobalStyles';
import SplashScreen from './components/SplashScreen';
import useAuth from './hooks/useAuth';
import useScrollReset from './hooks/useScrollReset';
import useSettings from './hooks/useSettings';
import routes from './routes';
import { createTheme } from './theme';
import { FormProvider } from './hooks/useForm';

import './theme/fonts.css';

const App = () => {
  const content = useRoutes(routes);
  const { settings } = useSettings();
  const auth = useAuth();
  useScrollReset();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });

  const handleHttps = () => (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      locale="pt"
    >
      <ThemeProvider theme={theme}>
        <FormProvider>
          <SnackbarProvider
            dense
            maxSnack={3}
          >
            <GlobalStyles />
            {auth.isInitialized ? content : <SplashScreen />}
          </SnackbarProvider>
        </FormProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );

  return (handleHttps());
};

export default App;
