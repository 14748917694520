import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CAPE_VERD_CURRENCY_ID } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';

let companyId;

const initialState = {
  bankAccounts: undefined,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'bankAccount',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.bankAccounts = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.bankAccounts = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.bankAccounts === undefined) {
        state.bankAccounts = [];
      }
      state.bankAccounts.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'bankAccount/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/bank_accounts`);

      const bankAccounts = data.map((bankAccount) => ({
        id: bankAccount.id,
        bankName: bankAccount.bank_name,
        abbreviation: bankAccount.abbreviation,
        accountNumber: bankAccount.account_number,
        nib: bankAccount.nib,
        swift: bankAccount.swift,
        currencyId: bankAccount.currency_id,
        iban: bankAccount.iban
      }));

      dispatch(_getSuccess(bankAccounts));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'bankAccount/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const bankAccount = {
        bank_name: data.bankName,
        abbreviation: data.abbreviation,
        account_number: data.accountNumber,
        nib: data.nib,
        swift: data.swift,
        currency_id: CAPE_VERD_CURRENCY_ID,
        iban: data.iban
      };

      await axios.post(`/companies/${companyId}/bank_accounts`, bankAccount);

      dispatch(_createOrUpdatingSuccess(data));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const update = createAsyncThunk(
  'bankAccount/update',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const bankAccount = {
        bank_name: data.bankName,
        abbreviation: data.abbreviation,
        account_number: data.accountNumber,
        nib: data.nib,
        swift: data.swift,
        currency_id: CAPE_VERD_CURRENCY_ID,
        iban: data.iban
      };
      await axios.put(`/companies/${companyId}/bank_accounts/${data.id}`, bankAccount);
      dispatch(_createOrUpdatingSuccess());

      dispatch(_getSuccess([data]));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
