import { useEffect, useRef, useCallback, useState } from 'react';
import { useParams, useNavigate, Navigate, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { Box, Button, Card, Container, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useDispatch, useSelector } from '../../store';
import { fetchInvoicePdfById, pay as payInvoice, fetchIsPayable } from '../../slices/invoices';

import RenderPdf from '../../components/RenderPdf';
import Header from '../../components/PublicInvoice/Header';
import { Footer } from '../../components/homePage/Footer';
import LoadingPopUp from '../../components/LoadingPopUp';
import PaymentStatusMessagePopup from '../../components/StatusMessagePopup';
import PaymentOptions from '../../components/PaymentOptions';

import { PAYMENT_SERVICES, POPUP_STATUS } from '../../constants';

import '../../theme/fonts.css';
import './styles.css';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    margin: '7rem auto',
    flexDirection: 'column',
    maxWidth: '42rem'
  },
  cardContent: {
    alignItems: 'center',
    minHeight: '100vh',
    margin: '1rem',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)',
    overflow: 'auto',
    padding: '0',
    zIndex: 3
  },
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '48px',
    margin: '0 1rem'
  }
}));

const index = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();

  const {
    invoicePdf,
    loading,
    loadingError,
    onRequest,
    onRequestFailure,
    errorMessage,
    paymentResponse,
    isPayable
  } = useSelector((state) => state).invoices;

  const [isPaid, setIsPaid] = useState(false);
  const [emailForReceipt, setEmailForReceipt] = useState(null);
  const [isPaymentStatusPopupOpen, setIsPaymentStatusPopupOpen] = useState(false);
  const [paymentStatusInfo, setPaymentStatusInfo] = useState({});
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [queryParams] = useSearchParams();

  const theme = useTheme();
  const projectColors = theme.palette;

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchIsPayable(id));
    dispatch(fetchInvoicePdfById(id));
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );

  const handleDispatchPayInvoice = (paymentService, email) => {
    dispatch(payInvoice({ invoiceId: id, email, paymentService }));
    setEmailForReceipt(email);
  };

  const popupUpdate = () => {
    if (!onRequest && onRequest !== undefined && onRequestFailure) {
      setPaymentStatusInfo({
        status: POPUP_STATUS.error,
        color: projectColors.error.main,
        title: 'Ocorreu um erro ao solicitar o pagamento!',
        text: errorMessage,
        subText: null
      });
      setIsPaymentStatusPopupOpen(true);
    }
  };

  useEffect(() => {
    popupUpdate();
  }, [onRequest, onRequestFailure, errorMessage]);

  useEffect(() => {
    const errorResponse = queryParams.get('errorMessage');
    if (errorResponse) {
      setPaymentStatusInfo({
        status: POPUP_STATUS.error,
        color: projectColors.error.main,
        title: 'Ocorreu um erro ao efetuar o pagamento!',
        text: errorResponse,
        subText: null
      });
      setIsPaymentStatusPopupOpen(true);
    }
  }, [queryParams]);

  const onError = (message) => {
    setPaymentStatusInfo({
      status: POPUP_STATUS.error,
      color: projectColors.error.main,
      title: 'Ocorreu um erro ao efetuar o pagamento!',
      text: message,
      subText: null
    });
    setIsPaymentStatusPopupOpen(true);
  };

  const onSuccess = () => {
    setIsPaid(true);
    setPaymentStatusInfo({
      status: POPUP_STATUS.success,
      color: projectColors.primary.main,
      title: 'Pagamento efetuado com sucesso!',
      text: 'O recibo será enviado para o email',
      subText: emailForReceipt
    });

    setIsPaymentStatusPopupOpen(true);
  };

  const handlePaymentStatusPopupClose = () => {
    setIsDialogOpen(false);
    setIsPaymentStatusPopupOpen(false);
  };

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  if (loadingError) {
    return <Navigate to="/404" />;
  }

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Fatura | Samba</title>
      </Helmet>
      <Header handleLogoClick={handleLogoClick} />
      {/* BODY */}
      <Container className={classes.articles}>
        <div className={classes.buttonsGroup}>
          <a
            href={invoicePdf}
            download
            style={{ textDecoration: 'none' }}
          >
            <Button
              variant="outlined"
              endIcon={<CloudDownloadIcon />}
              sx={{
                mr: 1
              }}
            >
              BAIXAR PDF
            </Button>
          </a>
          {
            isPayable && !isPaid && !loading && (
              <Button
                id="invoice-pdf-payment-button"
                variant="contained"
                color="primary"
                onClick={handleOpenDialog}
                endIcon={<MonetizationOnIcon />}
              >
                PAGAR VALOR EM DÍVIDA
              </Button>
            )
          }
        </div>
        <Card
          className={classes.cardContent}
        >
          <RenderPdf file={invoicePdf} />
          <PaymentOptions
            isDialog
            onError={onError}
            isLoading={onRequest}
            onSuccess={onSuccess}
            isDialogOpen={isDialogOpen}
            paymentOptionList={[PAYMENT_SERVICES.sisp, PAYMENT_SERVICES.stripe]}
            onCloseDialog={() => setIsDialogOpen(false)}
            onClickPaymentOption={handleDispatchPayInvoice}
            paymentResponse={paymentResponse}
          />
        </Card>
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-1-left.svg"
          sx={{
            position: 'absolute',
            height: 460,
            left: -34,
            top: 0,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-2-left.svg"
          sx={{
            position: 'absolute',
            height: 360,
            left: -82,
            top: 680,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-3-left.svg"
          sx={{
            position: 'absolute',
            width: 200,
            height: 300,
            left: -84,
            top: 940,
            opacity: 1,
            zIndex: -13
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-1-right.svg"
          sx={{
            position: 'absolute',
            width: 60,
            height: 460,
            right: -2,
            top: 40,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-2-right.svg"
          sx={{
            position: 'absolute',
            width: 60,
            height: 460,
            right: -2,
            top: 620,
            opacity: 1,
            zIndex: -13
          }}
        />

        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <Box
            alt="Visa Logo"
            component="img"
            src="/static/paymentPage/visa-logo.svg"
            sx={{
              width: 50,
              height: 'auto',
              mr: 2
            }}
          />
          <Box
            alt="Mastercard Logo"
            component="img"
            src="/static/paymentPage/mastercard-logo.svg"
            sx={{
              width: 50,
              height: 'auto',
              mr: 2
            }}
          />
          <Box
            alt="Vinti4 Logo"
            component="img"
            src="/static/paymentPage/vinti4-logo.png"
            sx={{
              width: 50,
              height: 'auto'
            }}
          />
        </Box>

      </Container>
      <Box
        alt="Shape"
        sx={{ zIndex: 3 }}
      >
        <Footer
          sx={{
            zIndex: 4,
            backgroundColor: 'background.grey'
          }}
        />
      </Box>
      <PaymentStatusMessagePopup
        isOpen={isPaymentStatusPopupOpen}
        handleClosePopUp={handlePaymentStatusPopupClose}
        status={paymentStatusInfo?.status}
        color={paymentStatusInfo?.color}
        title={paymentStatusInfo?.title}
        text={paymentStatusInfo?.text}
        subText={paymentStatusInfo?.subText}
      />
    </>
  );
};

export default index;
