import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { Button } from '@mui/material';
import { formatCurrency } from '../../../util/formatCurrency';
import LoadingPopUp from '../../LoadingPopUp';

const CheckoutForm = (props) => {
  const {
    clientToken: clientSecret,
    handleTriggerSuccessPaymentMessage,
    closeForm
  } = props;
  const stripe = useStripe();
  const elements = useElements();

  const [amount, setAmount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isStripeIframeLoading, setIsStripeIframeLoading] = useState(true);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      setAmount(paymentIntent.amount / 100);
      setIsStripeIframeLoading(false);
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}`
      },
      redirect: 'if_required'
    });

    setIsLoading(false);
    if (error?.type === 'card_error') {
      let message;
      switch (error?.decline_code || error.code) {
        case 'insufficient_funds':
          message = 'O saldo do seu cartão é insuficiente.';
          break;
        case 'expired_card':
          message = 'O seu cartão expirou.';
          break;
        case 'incorrect_cvc':
          message = 'O código de segurança do seu cartão está incorreto.';
          break;
        case 'processing_error':
          message = 'Ocorreu um erro ao processar o seu cartão. Tente mais tarde.';
          break;
        default:
          message = 'O seu cartão foi recusado.';
      }

      handleTriggerSuccessPaymentMessage(false, message);
      closeForm();
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      handleTriggerSuccessPaymentMessage(true);
      closeForm();
    }
  };

  const paymentElementOptions = {
    layout: 'tabs'
  };

  return (
    isStripeIframeLoading
      ? <LoadingPopUp />
      : (
        <form
          id="payment-form"
          onSubmit={handleSubmit}
        >
          <PaymentElement
            id="payment-element"
            options={paymentElementOptions}
          />
          <Button
            sx={{ my: 2, width: '100%' }}
            disabled={isLoading}
            size="large"
            type="submit"
            variant="contained"
          >
            <span id="button-text">
              {isLoading ? 'Processando...' : `Pagar ${formatCurrency(amount)}`}
            </span>
          </Button>
        </form>
      )
  );
};

CheckoutForm.propTypes = {
  clientToken: PropTypes.string.isRequired,
  handleTriggerSuccessPaymentMessage: PropTypes.func.isRequired,
  closeForm: PropTypes.func.isRequired
};

export default CheckoutForm;
