import { Helmet } from 'react-helmet-async';
import { Box, Dialog } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { fetch as fetchOrders, update as updateOrder, clearMessages } from '../../../slices/orders';
import { useSelector, useDispatch } from '../../../store';

import {
  EFATURA_BANNER_MESSAGE,
  LOADING_MESSAGE,
  HELMET_TITLES,
  PAGES_TEXT,
  TOAST_TYPE,
  ORDER_STATUS_LIST
} from '../../../constants';

import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';
import WarningAlertOnPage from '../../../components/AlertOnPage';
import ViewDetails from '../../../components/Orders/ViewDetails';
import LoadingPopUp from '../../../components/LoadingPopUp';
import OrderTable from '../../../components/Orders/Table';
import H1Section from '../../../components/Header/H1Section';
import EmptyList from '../../../components/empty_list';
import Scrollbar from '../../../components/Scrollbar';
import Wrapper from '../../../components/Wrapper';
import Toast from '../../../components/Toast';

const Orders = () => {
  const dispatch = useDispatch();

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [toastType, setToastType] = useState(false);
  const [message, setMessage] = useState(false);

  const { currentCompany } = useSelector((state) => state).companies;
  const { orders, isLoading, isLoadingUpdate, errorMessage, successMessage } = useSelector((state) => state).orders;

  const renderBanner = () => isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
    <WarningAlertOnPage message={EFATURA_BANNER_MESSAGE} />
  );

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchOrders());
    }
  }, [dispatch, currentCompany]);

  const handleOpenDetails = (order) => {
    setSelectedOrder(order);
    setIsDetailsOpen(true);
  };

  const handleDetailClose = () => {
    setIsDetailsOpen(false);
    setSelectedOrder(null);
  };

  const renderContent = () => {
    if (isLoading) {
      return (<LoadingPopUp message={LOADING_MESSAGE} />);
    }

    if (orders?.length > 0) {
      return (
        <Box sx={{ mt: 2 }}>
          <OrderTable
            id="order-list"
            orders={orders}
            onOpenDetails={handleOpenDetails}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        id="empty-list-e-commerce-page"
        title="Não tens nenhum Pedido"
      />
    );
  };

  const updateOrderStatus = (data) => {
    handlePopUpOpen();
    dispatch(updateOrder(data));
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const toastUpdate = () => {
    if (isLoadingUpdate) {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
    if (successMessage) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      setIsDetailsOpen(false);
    }
    if (errorMessage) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [isLoadingUpdate, errorMessage, successMessage]);

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    dispatch(clearMessages());
  };

  return (
    <>
      <Helmet>
        <title>{HELMET_TITLES.E_COMMERCE.ORDERS}</title>
      </Helmet>
      {renderBanner()}
      <Wrapper id="title-of-my-orders-page">
        <H1Section
          title={PAGES_TEXT.ECOMMERCE.ORDERS.TITLE}
          description={PAGES_TEXT.ECOMMERCE.ORDERS.DESCRIPTION}
        />
        <Box>{renderContent()}</Box>
      </Wrapper>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleDetailClose}
        open={Boolean(isDetailsOpen && selectedOrder)}
      >
        <Scrollbar>
          {Boolean(isDetailsOpen && selectedOrder) && (
          <ViewDetails
            title="Pedido"
            onCancel={handleDetailClose}
            order={selectedOrder}
            onClose={handleDetailClose}
            currentCompany={currentCompany}
            updateOrderStatus={updateOrderStatus}
            orderStatusList={ORDER_STATUS_LIST}
          />
          )}
        </Scrollbar>
      </Dialog>
      <Toast
        isOpen={isPopUpOpen}
        toastType={toastType}
        message={message}
        onClose={handleClosePopUp}
      />
    </>
  );
};

export default Orders;
