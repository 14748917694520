import PropTypes from 'prop-types';
import {
  Box,
  AppBar,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListItem,
  Toolbar,
  Typography,
  Button
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Link } from 'react-scroll';
import MenuIcon from '../../icons/Menu';

const Header = ({
  handleBusinessManagement,
  handleWhySambaClick,
  handleIntegrationsClick,
  handleLoginButtonClick
}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [headerBorder, setHeaderBorder] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setHeaderBorder(true);
    } else {
      setHeaderBorder(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [window.scrollY]);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        boxShadow: headerBorder ? '0px 2px 10px 10px rgba(80, 80, 80, 0.1)' : ''
      }}
    >
      <Toolbar sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        >
          <Typography
            color="primary"
            sx={{
              left: 0,
              fontSize: 36
            }}
          >
            SAMBA
          </Typography>
        </Box>
        <Box>
          <Hidden lgDown>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              <Typography
                color="textSecondary"
                variant="textPrimary"
                sx={{
                  mx: 4,
                  textTransform: 'uppercase',
                  fontSize: 20,
                  lineHeight: '4rem',
                  cursor: 'pointer'
                }}
              >
                <Link
                  to="business-management"
                  spy
                  smooth
                  offset={-70}
                  onClick={handleBusinessManagement}
                >
                  Gerenciamento de Empresas
                </Link>
              </Typography>
              <Typography
                variant="textPrimary"
                color="textSecondary"
                sx={{
                  mx: 4,
                  textTransform: 'uppercase',
                  fontSize: 20,
                  lineHeight: '4rem',
                  cursor: 'pointer'
                }}
              >
                <Link
                  to="why-samba"
                  spy
                  smooth
                  offset={-70}
                  onClick={handleWhySambaClick}
                >
                  Porquê Samba?
                </Link>
              </Typography>
              <Typography
                color="textSecondary"
                variant="textPrimary"
                sx={{
                  mx: 4,
                  textTransform: 'uppercase',
                  fontSize: 20,
                  lineHeight: '4rem',
                  cursor: 'pointer'
                }}
              >
                <Link
                  to="integration"
                  spy
                  smooth
                  offset={-70}
                  onClick={handleIntegrationsClick}
                >
                  Integrações
                </Link>
              </Typography>
            </Box>
          </Hidden>
        </Box>
        <Box>
          <Hidden lgDown>
            <Button
              alt="Login"
              color="primary"
              type="button"
              variant="contained"
              size="small"
              onClick={handleLoginButtonClick}
              sx={{
                px: 4,
                borderRadius: 4,
                mb: 1
              }}
            >
              <Typography
                variant="textSecondary"
                color="#FAFCFF"
                sx={{
                  fontFamily: 'Gill Sans',
                  pt: 0.5,
                  fontWeight: 600,
                  fontSize: 16
                }}
              >
                Login
              </Typography>
            </Button>
          </Hidden>
          <Hidden lgUp>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              color="inherit"
              onClick={handleDrawerOpen}
            >
              <MenuIcon
                alt="Menu"
                fontSize="small"
              />
            </IconButton>
          </Hidden>
        </Box>
      </Toolbar>
      <Drawer
        anchor="right"
        onClose={handleDrawerClose}
        open={isDrawerOpen}
        variant="temporary"
        PaperProps={{
          sx: {
            backgroundColor: 'background.default',
            width: 256
          }
        }}
      >
        <List>
          <Link
            to="business-management"
            spy
            smooth
            offset={-50}
            onClick={() => {
              handleBusinessManagement();
              handleDrawerClose();
            }}
          >
            <ListItem
              button
              sx={{
                m: 1,
                fontSize: 18,
                fontFamily: 'Gill Sans',
                textTransform: 'uppercase'
              }}
            >
              Gerenciamento de Empresas
            </ListItem>
          </Link>
          <Link
            to="why-samba"
            spy
            smooth
            offset={-50}
            onClick={() => {
              handleWhySambaClick();
              handleDrawerClose();
            }}
          >
            <ListItem
              button
              sx={{
                m: 1,
                fontSize: 18,
                fontFamily: 'Gill Sans',
                textTransform: 'uppercase'

              }}
            >
              Porquê samba?
            </ListItem>
          </Link>
          <Link
            to="integration"
            spy
            smooth
            offset={-50}
            onClick={() => {
              handleIntegrationsClick();
              handleDrawerClose();
            }}
          >
            <ListItem
              button
              sx={{
                m: 1,
                fontSize: 18,
                fontFamily: 'Gill Sans',
                textTransform: 'uppercase'
              }}
            >
              Integrações
            </ListItem>
          </Link>
          <Button
            alt="Login"
            color="primary"
            type="button"
            variant="contained"
            size="small"
            onClick={handleLoginButtonClick}
            sx={{
              mx: 2,
              px: 4,
              borderRadius: 4
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Gill Sans',
                fontWeight: 600,
                pt: 0.5
              }}
            >
              Login
            </Typography>
          </Button>
        </List>
      </Drawer>
    </AppBar>
  );
};

Header.propTypes = {
  handleLoginButtonClick: PropTypes.func.isRequired,
  handleBusinessManagement: PropTypes.func.isRequired,
  handleWhySambaClick: PropTypes.func.isRequired,
  handleIntegrationsClick: PropTypes.func.isRequired
};

export default Header;
