import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Dialog } from '@mui/material';

import PaymentMethodForm from './PaymentMethodForm';
import StripeIframePopUpForm from './StripeIframePopUpForm';
import LoadingPopUp from '../LoadingPopUp';
import Vinti4IconPath from './Assets/vinti4-logo.png';
import VisaIconPath from './Assets/visa-logo.svg';

const PaymentOptions = (props) => {
  const {
    onClickPaymentOption,
    hasTaxInformation,
    amountInformation,
    paymentOptionList,
    paymentResponse,
    onCloseDialog,
    isDialogOpen,
    isLoading,
    onSuccess,
    isDialog,
    onError
  } = props;

  const paymentServices = {
    STRIPE: 'STRIPE',
    SISP: 'SISP'
  };

  const [isStripeIframePopUpFormOpen, setIsStripeIframePopUpFormOpen] = useState(false);
  const [isPaymentMethodFormOpen, setIsPaymentMethodFormOpen] = useState(false);
  const [clickedSispOption, setClickedSispOption] = useState(false);
  const [stripeToken, setStripeToken] = useState(null);

  const paymentOptions = (id, title, icon, onClick) => (
    <Box
      sx={{
        display: 'flex',
        cursor: 'pointer',
        alignItems: 'center',
        p: 2,
        gap: 1,
        borderRadius: 0.5,
        boxShadow: '0px 2px 8px rgba(80, 80, 80, 0.10)'
      }}
      id={id}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', userSelect: 'none', alignItems: 'center' }}>
        <img
          width={40}
          height={40}
          src={icon}
          alt={title}
        />
      </Box>
      <Box sx={{ display: 'flex', width: '100%' }}>
        <Typography
          sx={{ userSelect: 'none' }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );

  const handleOpenPaymentMethodForm = () => {
    setIsPaymentMethodFormOpen(true);
  };

  const handleClosePaymentMethodForm = () => {
    setIsPaymentMethodFormOpen(false);
  };

  const onClickOption = (isSisp) => {
    handleOpenPaymentMethodForm();
    setClickedSispOption(isSisp);
  };

  const handleStripeIframePopUpFormClose = () => {
    setIsStripeIframePopUpFormOpen(false);
  };

  const handleStripeIframePopUpFormOpen = () => {
    setIsStripeIframePopUpFormOpen(true);
  };

  const handlePayment = (email) => {
    const paymentService = clickedSispOption ? paymentServices.SISP : paymentServices.STRIPE;
    onClickPaymentOption(paymentService, email);
  };

  const handleStripeTriggerPaymentMessage = (isSuccess, stripeErrorMessage) => {
    if (isSuccess) {
      onSuccess();
    } else {
      onError(stripeErrorMessage);
    }
  };

  useEffect(() => {
    if (paymentResponse) {
      if (paymentResponse.token) {
        setStripeToken(paymentResponse.token);
        handleStripeIframePopUpFormOpen();
      } else {
        window.location.href = paymentResponse.url;
      }
    }
  }, [paymentResponse]);

  const renderContent = () => {
    if (isLoading) {
      return <LoadingPopUp />;
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Typography
            align="center"
          >
            Escolha sua opção de pagamento
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          {paymentOptionList.includes(paymentServices.STRIPE) && paymentOptions(
            'payment-option-stripe',
            'Visa/MasterCard',
            VisaIconPath,
            () => onClickOption(false)
          )}
          {paymentOptionList.includes(paymentServices.SISP) && paymentOptions(
            'payment-option-sisp',
            'Vinti4',
            Vinti4IconPath,
            () => onClickOption(true)
          )}
        </Box>
        <PaymentMethodForm
          handlePayment={handlePayment}
          isPaymentMethodFormOpen={isPaymentMethodFormOpen}
          onClose={handleClosePaymentMethodForm}
          description="Insira o seu email:"
          hasTaxInformation={hasTaxInformation}
          amountInformation={amountInformation}
        />
        <StripeIframePopUpForm
          isFormOpen={isStripeIframePopUpFormOpen}
          closeForm={handleStripeIframePopUpFormClose}
          clientStripeToken={stripeToken}
          handleTriggerSuccessPaymentMessage={handleStripeTriggerPaymentMessage}
        />
      </Box>
    );
  };

  return (
    isDialog
      ? (
        <Dialog
          open={isDialogOpen}
          onClose={onCloseDialog}
          id="payment-option-dialog"
        >
          <Box sx={{ p: 3 }}>{renderContent()}</Box>
        </Dialog>
      )
      : renderContent()
  );
};

PaymentOptions.defaultProps = {
  hasTaxInformation: false,
  isDialogOpen: false,
  isDialog: false,
  amountInformation: null,
  onCloseDialog: () => {}
};

PaymentOptions.propTypes = {
  paymentOptionList: PropTypes.arrayOf(['SISP', 'STRIPE']).isRequired,
  onClickPaymentOption: PropTypes.func.isRequired,
  paymentResponse: PropTypes.shape({
    url: PropTypes.string,
    token: PropTypes.string
  }).isRequired,
  onSuccess: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  amountInformation: PropTypes.shape({
    amount: PropTypes.number,
    amountAfterFee: PropTypes.number,
    finalAmount: PropTypes.number,
    deductedFee: PropTypes.number
  }),
  onError: PropTypes.func.isRequired,
  isDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
  isDialogOpen: PropTypes.bool,
  hasTaxInformation: PropTypes.bool
};

export default PaymentOptions;
