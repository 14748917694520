import PropTypes from 'prop-types';
import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Scrollbar from '../Scrollbar';
import ReceiptItemSum from '../receiptItem/Sum';
import { formatCurrency } from '../../util/formatCurrency';
import { emailSchema } from '../../util/schema/emailValidation';
import renderEfaturaDocumentErrorMessage from '../../util/renderEfaturaDocumentErrorMessage';

const ViewDetails = (props) => {
  const { receipt, onCancel, handleSendEmail, handlePrintButtonClick, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOpen = () => {
    setOpenEmailDialog(true);
  };

  const handleSendClick = () => {
    handleClickOpen();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const validateEmail = async (data) => {
    /* validate email schema */
    try {
      await emailSchema.validate(data ? data.trim() : data, {
        abortEarly: false
      });
      setEmailError(null);
      return true;
    } catch (error) {
      setEmailError(error.errors[0]);
    }
    return false;
  };

  const handlePopupClose = () => {
    setOpenEmailDialog(false);
    setEmail(null);
  };

  const handleSendEmailButtonClick = async () => {
    const validEmail = await validateEmail(email);

    if (validEmail) {
      handleSendEmail(email);
      handleClickOpen();
      handlePopupClose();
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    validateEmail(email);
  };

  return (
    <Card
      id="receipt-view-details"
      {...other}
    >
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Recibo
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {renderEfaturaDocumentErrorMessage(receipt)}
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Serie/Numero
                </TableCell>
                <TableCell
                  id="receipt-view-details-serial-and-number"
                >
                  {String(receipt.number).padStart(3, '0')}
                  {' / '}
                  {receipt.serie.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Cliente
                </TableCell>
                <TableCell
                  id="receipt-view-details-client"
                >
                  {receipt.client.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data de Pagamento
                </TableCell>
                <TableCell
                  id="receipt-view-details-payment-date"
                >
                  {receipt.dateFormatted}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Modo de Pagamento
                </TableCell>
                <TableCell
                  id="receipt-view-details-payment-mode"
                >
                  {receipt.paymentMode?.code}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Lista de Recibos
        </Typography>
        <Box sx={{ pt: 3 }}>
          <Card {...other}>
            <Scrollbar>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        Fatura
                      </TableCell>
                      <TableCell>
                        Valor em Dívida
                      </TableCell>
                      <TableCell>
                        Valor Pago
                      </TableCell>
                      <TableCell>
                        Pendente
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {receipt.invoices.map((invoice, index) => (
                      <TableRow>
                        <TableCell
                          id={`receipt-view-details-invoice-${index}-number`}
                        >
                          {String(invoice.number).padStart(3, '0')}
                        </TableCell>
                        <TableCell
                          id={`receipt-view-details-invoice-${index}-debt-value`}
                        >
                          {formatCurrency(invoice.generalTotal)}
                        </TableCell>
                        <TableCell
                          id={`receipt-view-details-invoice-${index}-amount-paid`}
                        >
                          {formatCurrency(invoice.paymentValue)}
                        </TableCell>
                        <TableCell
                          id={`receipt-view-details-invoice-${index}-pendent-value`}
                        >
                          {formatCurrency(invoice.generalTotal - invoice.paymentValue)}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Scrollbar>
          </Card>
        </Box>

        <Box sx={{
          my: 2
        }}
        >
          <ReceiptItemSum
            totalGeneral={formatCurrency(receipt.total)}
          />
        </Box>

      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Cancelar
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </Box>
      {/* Popup to insert email to be sent */}
      <Dialog
        open={openEmailDialog}
        onClose={() => setOpenEmailDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar para:</DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email*"
            placeholder="Introduzir Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange}
            helperText={emailError}
            error={emailError}
            onFocus={() => setEmailError(null)}
            onBlur={handleChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', margin: '0 8px 24px 8px' }}>
          <Button
            onClick={handlePopupClose}
            color="primary"
            variant="outlined"
            style={{ borderRadius: '28px' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSendEmailButtonClick}
            color="primary"
            variant="contained"
            style={{ borderRadius: '28px' }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSendClick}>
          <Typography
            color="textSecondary"
          >
            Enviar por email
          </Typography>
        </MenuItem>
        <MenuItem onClick={handlePrintButtonClick}>
          <Typography
            color="textSecondary"
          >
            Baixar PDF
          </Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
};

ViewDetails.propTypes = {
  receipt: PropTypes.shape({
    id: PropTypes.string,
    dateFormatted: PropTypes.string,
    invoices: PropTypes.arrayOf({
      generalTotal: PropTypes.string,
      number: PropTypes.string,
      paymentValue: PropTypes.string
    }),
    client: PropTypes.shape({
      name: PropTypes.string
    }).isRequired,
    serie: PropTypes.shape({
      code: PropTypes.string
    }).isRequired,
    number: PropTypes.string,
    total: PropTypes.string,
    paymentMode: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    efaturaData: PropTypes.shape({
      status: PropTypes.string,
      errorCode: PropTypes.string,
      errorMessage: PropTypes.string
    }),
    isRetried: PropTypes.bool
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  onVoid: PropTypes.func.isRequired,
  handlePrintButtonClick: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired
};

export default ViewDetails;
