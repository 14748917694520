import { COMPANY_TYPES } from '../constants';

function calculateTotalGeneral(valueWithoutTax, totalTax, discount, companyType = COMPANY_TYPES[1].id) {
  let total = 0;

  if (companyType === COMPANY_TYPES[0].name) {
    total = valueWithoutTax - totalTax - discount;
  } else {
    total = valueWithoutTax + totalTax - discount;
  }

  return total;
}
export default calculateTotalGeneral;
