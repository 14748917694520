import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PAYMENT_SERVICES } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';

const initialState = {
  onRequest: undefined,
  onRequestFailure: undefined,
  errorMessage: '',
  paymentResponse: null
};

const slice = createSlice({
  name: 'onlinePayments',
  initialState,
  reducers: {
    _processPayment: (state) => {
      state.onRequest = true;
    },
    _processPaymentSuccess: (state, { payload }) => {
      state.onRequest = false;
      state.paymentResponse = payload;
    },
    _processPaymentFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    }
  }
});

const {
  _processPayment,
  _processPaymentSuccess,
  _processPaymentFailure
} = slice.actions;

export default slice.reducer;

export const pay = createAsyncThunk(
  'onlinePayments',
  async (paymentInfo, { dispatch }) => {
    dispatch(_processPayment());
    try {
      const { paymentIntentId, email, paymentService } = paymentInfo;

      const { data } = await axios.get(`/payment_intents/${paymentIntentId}?payment_service_id=${paymentService}&email=${email}`);

      let response;
      switch (paymentService) {
        case PAYMENT_SERVICES.sisp:
          response = {
            url: data
          };
          break;
        default:
          response = {
            token: data?.token
          };
      }

      dispatch(_processPaymentSuccess(response));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_processPaymentFailure(errorMessage));
    }
  }
);
