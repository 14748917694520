import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { addDays, format, parseISO, isAfter, isBefore } from 'date-fns';
import { clone } from 'lodash';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import useSettings from '../../../hooks/useSettings';
import Table from '../../../components/debitNote/Table';
import DebitNoteForm from '../../../components/debitNote/Form';
import Toast from '../../../components/Toast';
import { useDispatch, useSelector } from '../../../store';
import { fetch as fetchDebitNotes, create as createDebitNote, sendDebitNoteEmail, print as printDebitNote } from '../../../slices/debitNotes';
import { fetch as fetchSeries } from '../../../slices/series';

import { fetch as fetchFiscalYears } from '../../../slices/fiscalYear';
import { fetch as fetchClients } from '../../../slices/clients';
import { fetch as fetchIssueReasons } from '../../../slices/issueReasons';
import { fetch as fetchDocumentTypes } from '../../../slices/documentTypes';
import { fetch as fetchPaymentPeriod } from '../../../slices/paymentPeriod';
import { fetch as fetchPaymentMode } from '../../../slices/paymentMode';
import { fetch as fetchItems } from '../../../slices/itens';
import { fetch as fetchUnit } from '../../../slices/units';
import { fetch as fetchTaxes } from '../../../slices/taxes';
import { retrySendDocument as retrySendDebitNote } from '../../../slices/eFatura';

import EmptyList from '../../../components/empty_list';
import LoadingPopUp from '../../../components/LoadingPopUp';
import ViewDetails from '../../../components/debitNote/ViewDetails';
import Scrollbar from '../../../components/Scrollbar';
import WarningAlertOnPage from '../../../components/AlertOnPage';

import modifyOldLocalStorage from '../../../util/modifyOldLocalStorage';
import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';
import { DOCUMENTS, TOAST_TYPE, ISSUE_REASONS, ISSUE_REASONS_DOCS, EFATURA_BANNER_MESSAGE } from '../../../constants';

const DebitNote = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedDebitNote, setSelectedDebitNote] = useState(null);
  const [debitNoteIssueReasons, setDebitNoteIssueReasons] = useState([]);

  const { settings } = useSettings();

  const dispatch = useDispatch();
  const { debitNotes, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.debitNotes);
  const { series } = useSelector((state) => state.series);
  const { documentTypes } = useSelector((state) => state.documentTypes);
  const { paymentPeriods } = useSelector((state) => state.paymentPeriod);
  const { paymentModes } = useSelector((state) => state.paymentMode);
  const { clients } = useSelector((state) => state.clients);
  const { issueReasons } = useSelector((state) => state.issueReasons);
  const { units } = useSelector((state) => state.units);
  const { taxes } = useSelector((state) => state.taxes);
  const { itens } = useSelector((state) => state.itens);
  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state.companies);
  const { retrySendDocumentSuccess: retrySendDebitNoteSuccess } = useSelector((state) => state.eFatura);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [debitNoteStorage, setDebitNoteStorage] = useLocalStorage('debitNoteStorage', null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftKey, setDraftKey] = useState(null);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchDebitNotes()),
        dispatch(fetchSeries()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchClients()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchPaymentPeriod()),
        dispatch(fetchPaymentMode()),
        dispatch(fetchItems()),
        dispatch(fetchUnit()),
        dispatch(fetchTaxes()),
        dispatch(fetchIssueReasons())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setSelectedDraft(null);
    setDraftKey(Math.random());
    setIsAddMode(true);
  };

  const handleEditDraft = (draft, keyDraft) => {
    setIsAddMode(true);
    dispatch(fetchSeries());
    setSelectedDraft(draft);
    setDraftKey(keyDraft);
  };

  const handleDeleteDraft = (draftId) => {
    const draftsDebitNotesStorage = clone(debitNoteStorage);
    const indexCompany = draftsDebitNotesStorage.debitNotes.findIndex((company) => currentCompany.id in company);
    const draftIndex = draftsDebitNotesStorage.debitNotes[indexCompany][currentCompany.id].findIndex((draft) => draftId in draft);

    if (draftsDebitNotesStorage.debitNotes[indexCompany][currentCompany.id].length <= 1) {
      draftsDebitNotesStorage.debitNotes.splice(indexCompany, 1);
    } else {
      draftsDebitNotesStorage.debitNotes[indexCompany][currentCompany.id].splice(draftIndex, 1);
    }
    setDebitNoteStorage(draftsDebitNotesStorage);
  };

  const handleUpdateSelectedDebitNote = () => {
    if (selectedDebitNote) {
      const debitNoteUpdate = debitNotes.find((debitNote) => debitNote.id === selectedDebitNote?.id);
      setSelectedDebitNote(debitNoteUpdate);
    }
  };

  const handleDetailOpen = (debitNote) => {
    setSelectedDebitNote(debitNote);
    setIsDetailsModalOpen(true);
  };

  const handleDuplicate = (debitNote) => {
    setSelectedDebitNote(debitNote);
    handleNewClick();
  };

  const handleDetailClose = () => {
    setIsDetailsModalOpen(false);
    setSelectedDebitNote(null);
  };

  const handleModalClose = () => {
    setIsAddMode(false);
    setSelectedDebitNote(null);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedDebitNote();
    setIsPopUpOpen(false);
  };

  const handleCreate = (debitNote) => {
    handlePopUpOpen();
    dispatch(createDebitNote({
      ...debitNote,
      documentType: documentTypes.find((documentType) => documentType.code === DOCUMENTS.debit_note.code).id
    }));
  };

  /**
   * Send Email
   * @param {string} email - Email to send the debitNote email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendDebitNoteEmail({ id: selectedDebitNote.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const getNextDebitNoteNumber = (serieId) => {
    const getDebitNotes = debitNotes ? debitNotes.filter((debitNote) => debitNote.serie === serieId) : 0;

    if ((!getDebitNotes || getDebitNotes.length === 0) && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);

      if (getSerie?.serialNumberConfig?.debitNoteLastDocumentNumber) {
        return Number(getSerie.serialNumberConfig.debitNoteLastDocumentNumber) + 1;
      }

      return Number(getSerie?.lastDocumentNumber) + 1;
    }

    return Number(getDebitNotes[getDebitNotes.length - 1]?.number) + 1;
  };

  /**
   * isBefore is a function that returns true if the date is before the given date.
   * isAfter is a function that returns true if the date is after the given date.
   * @param {Date} date
   * @param {Object} serie
   */
  const isInSerieDateRange = (date, serie) => (isBefore(date, new Date(serie?.endDateRaw)) && isAfter(date, new Date(serie?.startDateRaw)));

  const handleRetrySendDebitNote = (debitNoteId) => {
    const debitNoteInfo = { id: debitNoteId, documentType: DOCUMENTS.debit_note.name };
    dispatch(retrySendDebitNote(debitNoteInfo));
  };

  function returnResult() {
    const validIndex = 0;
    if (debitNoteStorage?.invoices?.length && debitNoteStorage?.invoices?.length !== 0) {
      modifyOldLocalStorage(debitNoteStorage, 'debitNotes', setDebitNoteStorage);
    } else if (debitNoteStorage?.invoices?.length === 0) {
      setDebitNoteStorage({ debitNotes: [] });
    }

    const companyIndex = debitNoteStorage?.debitNotes?.findIndex((company) => currentCompany.id in company);
    let companyDrafts = null;
    if (companyIndex >= validIndex) {
      companyDrafts = debitNoteStorage?.debitNotes[companyIndex][currentCompany.id];
    }
    const hasCompanyDrafts = companyIndex >= validIndex && companyDrafts?.length > 0;

    if ((debitNotes && debitNotes.length > 0) || (companyIndex >= validIndex && hasCompanyDrafts)) {
      return (
        <Box sx={{ mt: 3 }}>
          <Table
            draftsStorage={companyIndex >= validIndex ? companyDrafts : null}
            deleteDraft={handleDeleteDraft}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
            debitNotes={debitNotes}
            onOpenDetails={handleDetailOpen}
            onClick={handleEditDraft}
            onView={handleDetailOpen}
            onDuplicate={handleDuplicate}
            handleRetrySendDocument={handleRetrySendDebitNote}
            retrySendDebitNoteSuccess={retrySendDebitNoteSuccess}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Nota de Débito"
        buttonText="Criar Nota de Débito"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  const handlePrint = () => {
    dispatch(printDebitNote(selectedDebitNote));
  };

  const getPaymentPeriodRange = ({ date, paymentPeriod }) => {
    if (paymentPeriods) {
      return format(addDays(date, paymentPeriods.find((item) => item?.id === paymentPeriod)?.numberOfDay || 0), 'dd/MM/yyyy');
    }
    return format(date, 'dd/MM/yyyy');
  };

  useEffect(() => {
    setDebitNoteIssueReasons(issueReasons?.filter((issueReason) => ISSUE_REASONS[issueReason.code]?.includes(ISSUE_REASONS_DOCS.debitNote)));
  }, [issueReasons]);

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>Nota de Débito | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {!isAddMode ? 'Nota de Débito' : 'Nova Nota de Débito'}
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Nota de Débito
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                id="debit-note-new-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }

          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <DebitNoteForm
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreate}
              series={series}
              clients={clients}
              paymentPeriods={paymentPeriods}
              documentTypes={documentTypes}
              paymentModes={paymentModes}
              units={units}
              taxes={taxes}
              items={itens}
              debitNote={selectedDebitNote}
              isInSerieDateRange={isInSerieDateRange}
              getNextDocumentNumber={getNextDebitNoteNumber}
              draftId={draftKey}
              debitNoteDraft={selectedDraft}
              debitNoteStorage={debitNoteStorage}
              setDebitNoteStorage={setDebitNoteStorage}
              handleDeleteDraft={handleDeleteDraft}
              currentCompany={currentCompany}
              issueReasons={debitNoteIssueReasons}
              getPaymentPeriodRange={getPaymentPeriodRange}
              isOnRequest={onRequest}
            />
            )}
          </Box>

        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleDetailClose}
          open={Boolean(isDetailsModalOpen && selectedDebitNote)}
        >
          <Scrollbar>
            {Boolean(isDetailsModalOpen && selectedDebitNote) && (
            <ViewDetails
              currentCompany={currentCompany}
              title="Nota de Débito"
              debitNote={{ ...selectedDebitNote, dateFormatted: format(parseISO(selectedDebitNote.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedDebitNote.date), selectedDebitNote.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
              onCancel={handleDetailClose}
              handleSendEmail={handleSendEmail}
              handlePrintButtonClick={handlePrint}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default DebitNote;
