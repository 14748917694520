import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Chip,
  alpha,
  useTheme,
  TableCell
} from '@mui/material';

import Scrollbar from '../Scrollbar';
import { BANK_WITHDRAWAL_STATUS } from '../../constants';
import { formattedDate } from '../../util/formattedDate';

const BankWithdrawalTable = (props) => {
  const { bankWithdrawals, ...other } = props;

  const theme = useTheme();
  const renderBankWithdrawalStatus = (status) => {
    let result;

    if (status === BANK_WITHDRAWAL_STATUS.completed) {
      result = {
        color: theme.palette.primary.main,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        label: 'Completo'
      };
    }
    if (status === BANK_WITHDRAWAL_STATUS.rejected) {
      result = {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.1),
        label: 'Rejeitado'
      };
    }
    if (status === BANK_WITHDRAWAL_STATUS.pending) {
      result = {
        color: '#F1B47B',
        backgroundColor: alpha('#F1B47B', 0.1),
        label: 'Pendente'
      };
    }

    return result;
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box id="bank-withdrawal-table">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                bankWithdrawals.map((bankWithdrawal) => (
                  <TableRow>
                    <TableCell>
                      {`${bankWithdrawal.user.name}`}
                    </TableCell>
                    <TableCell>
                      {`${formattedDate(bankWithdrawal.date)}`}
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="medium"
                        style={{
                          color: renderBankWithdrawalStatus(bankWithdrawal.status).color,
                          backgroundColor: renderBankWithdrawalStatus(bankWithdrawal.status).backgroundColor
                        }}
                        label={renderBankWithdrawalStatus(bankWithdrawal.status).label}
                      />
                    </TableCell>
                    <TableCell>
                      {bankWithdrawal.amount}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

BankWithdrawalTable.propTypes = {
  bankWithdrawals: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    bankAccount: PropTypes.shape({
      id: PropTypes.string.isRequired,
      nib: PropTypes.string.isRequired
    }),
    user: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }),
    amount: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    transactionId: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired
  }).isRequired
};

export default BankWithdrawalTable;
