import { format, parseISO } from 'date-fns';
import { formatCurrency } from './formatCurrency';

export const documentReferenceLabel = (name, number, date, total) => {
  const follow = name && number && date && total;
  let label;

  if (follow) {
    const dateFormatted = format(parseISO(date), 'dd/MM/yyyy');
    label = `${name} No. ${Number(number).toString().padStart(5, '0')} - ${formatCurrency(total)}$ - ${dateFormatted}`;
  }

  return label;
};
