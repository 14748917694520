import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import { updateState as updateInvoiceState } from './invoices';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  receipts: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: false,
  onRequestFailure: false,
  successMessage: '',
  errorMessage: '',
  receiptPdf: null
};

const slice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.receipts = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.receipts = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.receipts === undefined) {
        state.receipts = [];
      }
      state.receipts.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setReceiptPdf: (state, { payload }) => {
      state.receiptPdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    },
    _sendReceiptEmail: (state) => {
      state.onRequest = true;
    },
    _sendReceiptEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendReceiptEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getPdfSuccess,
  _getFailure,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage,
  _sendReceiptEmail,
  _sendReceiptEmailFailure,
  _sendReceiptEmailSuccess,
  _setReceiptPdf

} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'receipts/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/receipts`);

      const receipts = data.map((receipt) => ({
        id: receipt.id,
        client: receipt.customer,
        paymentDate: receipt.payment_date,
        number: receipt.receipt_number,
        serie: receipt.serial_number,
        paymentMode: {
          code: receipt.payment_mode_default?.code,
          name: receipt.payment_mode_default?.name
        },
        isVoided: receipt.is_voided,
        invoices: receipt.invoices.map((invoice) => ({
          generalTotal: invoice.total_general,
          number: invoice.invoice_number,
          paymentValue: invoice.value
        })),
        efaturaData: {
          id: receipt.efatura_data.id,
          status: receipt.efatura_data.status,
          errorCode: receipt.efatura_data.error_code,
          errorMessage: receipt.efatura_data.error_message
        },
        total: receipt.total
      }));

      dispatch(_getSuccess(receipts));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'receipts/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const invoices = data.invoices.map((invoice) => ({
        id: invoice.info.id,
        value: Number(invoice.paymentValue)
      }));

      const receipt = {
        customer_id: data.client,
        payment_date: data.date,
        payment_mode_default_id: data.paymentMode,
        invoices
      };

      const response = await axios.post(`/companies/${companyId}/serial_numbers/${data.serie}/receipts`, receipt);

      const newReceipt = {
        id: response.data.id,
        client: response.data.customer,
        paymentDate: response.data.payment_date,
        number: response.data.receipt_number,
        serie: response.data.serial_number,
        invoices: response.data.invoices.map((invoice) => ({
          generalTotal: invoice.total_general,
          number: invoice.invoice_number,
          paymentValue: invoice.value
        })),
        total: response.data.total,
        paymentMode: {
          id: response.data.payment_mode_default.id,
          code: response.data.payment_mode_default.code,
          name: response.data.payment_mode_default.name
        }
      };

      dispatch(_createOrUpdatingSuccess(newReceipt));
      dispatch(updateInvoiceState(invoices));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const fetchReceiptPdfById = createAsyncThunk(
  'receipt/receiptPDF',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/receipts/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setReceiptPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const sendReceiptEmail = createAsyncThunk(
  'receipt/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendReceiptEmail());

    try {
      await axios.post(`/companies/${companyId}/receipts/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.receiptEmailed());

      dispatch(_sendReceiptEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendReceiptEmailFailure(errorMessage));
    }
  }
);

export const sendPublicReceiptEmail = createAsyncThunk(
  'publicReceipt/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendReceiptEmail());
    try {
      await axios.post(`/receipts/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.receiptEmailed());

      dispatch(_sendReceiptEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendReceiptEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'receipt/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/receipts/${data.id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.client.name, '.');
      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');

      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.receiptDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);
