import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { startOfMonth, endOfMonth, subMonths, startOfDay, endOfDay } from 'date-fns';
import axios from '../lib/axios';
import { convertKeys } from '../util/convertSnakeToCamelCase';
import { DATA_FORMAT } from '../constants';
import { formatDateRange } from '../util/formatDateRange';

let companyId;

const initialState = {
  dailySummary: null,
  monthlySummary: null,
  lastThreeMonthsSummary: null,
  isLoadingDailySummary: false,
  isLoadingMonthlySummary: false,
  isLoadingLastThreeMonthsSummary: false,
  errorDailySummary: null,
  errorMonthlySummary: null,
  errorLastThreeMonthsSummary: null,
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    _getDailySummary: (state) => {
      state.isLoadingDailySummary = true;
      state.errorDailySummary = false;
    },
    _getDailySummarySuccess: (state, { payload }) => {
      state.dailySummary = payload;
      state.isLoadingDailySummary = false;
      state.errorDailySummary = false;
    },
    _getDailySummaryFailure: (state) => {
      state.dailySummary = null;
      state.isLoadingDailySummary = false;
      state.errorDailySummary = true;
    },
    _getMonthlySummary: (state) => {
      state.isLoadingMonthlySummary = true;
      state.errorMonthlySummary = false;
    },
    _getMonthlySummarySuccess: (state, { payload }) => {
      state.monthlySummary = payload;
      state.isLoadingMonthlySummary = false;
      state.errorMonthlySummary = false;
    },
    _getMonthlySummaryFailure: (state) => {
      state.monthlySummary = null;
      state.isLoadingMonthlySummary = false;
      state.errorMonthlySummary = true;
    },
    _getLastThreeMonthsSummary: (state) => {
      state.isLoadingLastThreeMonthsSummary = true;
      state.errorLastThreeMonthsSummary = false;
    },
    _getLastThreeMonthsSummarySuccess: (state, { payload }) => {
      state.lastThreeMonthsSummary = payload;
      state.isLoadingLastThreeMonthsSummary = false;
      state.errorLastThreeMonthsSummary = false;
    },
    _getLastThreeMonthsSummaryFailure: (state) => {
      state.lastThreeMonthsSummary = null;
      state.isLoadingLastThreeMonthsSummary = false;
      state.errorLastThreeMonthsSummary = true;
    }
  }
});

const {
  _getDailySummary,
  _getDailySummarySuccess,
  _getDailySummaryFailure,
  _getMonthlySummary,
  _getMonthlySummarySuccess,
  _getMonthlySummaryFailure,
  _getLastThreeMonthsSummary,
  _getLastThreeMonthsSummarySuccess,
  _getLastThreeMonthsSummaryFailure

} = slice.actions;

export default slice.reducer;

const fetchSummary = async (url, dispatch, successAction, failureAction) => {
  try {
    const { data } = await axios.get(url);
    const convertedData = convertKeys(data);
    dispatch(successAction(convertedData));
  } catch (error) {
    dispatch(failureAction(error.message));
  }
};

export const fetchDaily = createAsyncThunk(
  'dasboard/fetchDaily',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_getDailySummary());

    const now = new Date();

    const { startDate, endDate } = formatDateRange(startOfDay(now), endOfDay(now), DATA_FORMAT);

    await fetchSummary(`/companies/${companyId}/reports?start_date=${startDate}&end_date=${endDate}`, dispatch, _getDailySummarySuccess, _getDailySummaryFailure);
  }
);

export const fetchMonthly = createAsyncThunk(
  'dasboard/fetchMonthly',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_getMonthlySummary());

    const { startDate, endDate } = formatDateRange(startOfMonth(new Date()), endOfMonth(new Date()), DATA_FORMAT);

    await fetchSummary(`/companies/${companyId}/reports?start_date=${startDate}&end_date=${endDate}`, dispatch, _getMonthlySummarySuccess, _getMonthlySummaryFailure);
  }
);

export const fetchLastThreeMonths = createAsyncThunk(
  'dasboard/fetchLastThreeMonths',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_getLastThreeMonthsSummary());

    const threeMonthsAgo = subMonths(new Date(), 2);

    const { startDate, endDate } = formatDateRange(startOfMonth(threeMonthsAgo), endOfMonth(new Date()), DATA_FORMAT);

    await fetchSummary(`/companies/${companyId}/reports?start_date=${startDate}&end_date=${endDate}`, dispatch, _getLastThreeMonthsSummarySuccess, _getLastThreeMonthsSummaryFailure);
  }
);
