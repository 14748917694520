import { createContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useDispatch } from '../store';
import { initialize, login as userLogin, register as userRegister, logout as userLogout, resetPassword as userResetPassword } from '../slices/users';

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null
};

const AuthContext = createContext({
  ...initialState,
  platform: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  resetPassword: () => Promise.resolve()
});

export const AuthProvider = (props) => {
  const { children } = props;
  const dispatch = useDispatch();

  const { user, isAuthenticated, isInitialized } = useSelector((state) => state.users);

  const login = async (email, password) => {
    dispatch(userLogin({ email, password }));
    dispatch(initialize());
  };

  const logout = async () => {
    dispatch(userLogout());
    dispatch(initialize());
  };

  const register = async (email, firstName, lastName, password) => {
    dispatch(userRegister({ email, firstName, lastName, password }));
  };

  const resetPassword = (password, token) => {
    dispatch(userResetPassword({ password, token }));
  };

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated,
        isInitialized,
        platform: 'JWT',
        login,
        logout,
        register,
        resetPassword
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
