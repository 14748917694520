import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';

const ViewGeneralInfo = (props) => {
  const { company } = props;

  return (
    <>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Informação Geral da Empresa
        </Typography>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Nome
            </TableCell>
            <TableCell>
              {company.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              NIF da Empresa
            </TableCell>
            <TableCell>
              {company.taxId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              País de Origem
            </TableCell>
            <TableCell>
              {company.countryName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Moeda
            </TableCell>
            <TableCell>
              {company.currencyId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Email
            </TableCell>
            <TableCell>
              {company.email}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Telefone
            </TableCell>
            <TableCell>
              {company.phoneNumber}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

ViewGeneralInfo.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    email: PropTypes.string,
    phoneNumber: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

export default ViewGeneralInfo;
