import { Typography, Box, Card } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';

const StatisticsCard = ({ id, title, description, isLoading }) => {
  const renderSkeleton = () => {
    return (
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Skeleton variant="rounded" width={210} height={60} />
        <Skeleton variant="rounded" width={210} height={60} />
      </Box>
    );
  };

  const renderContent = () => {
    if (!isLoading) {
      return (
        <Card
          id={id}
          sx={{
            display: 'flex',
            gap: 1,
            width: 200,
            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            p: 2,
            flexDirection: 'column',
            borderRadius: '1rem'
          }}
        >
          <Box>
            <Typography
              color="textSecondary"
              variant="caption"
            >
              {title}
            </Typography>
            <Typography variant="h6">{description}</Typography>
          </Box>
        </Card>
      )
    }

    return renderSkeleton();
  };

  return (
    renderContent()
  )
};

StatisticsCard.defaultProp = {
  id: null
};

StatisticsCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default StatisticsCard;
