import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { forwardRef } from 'react';
import {
  Box,
  Typography,
  Card
} from '@mui/material';

const key = 'snackbar';

const Snack = forwardRef((props, ref) => {
  const { message, background, color, animationData } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <Box
      ref={ref}
      key={key}
    >
      <Card
        width="100%"
        height="58"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: 58, width: 58, backgroundColor: '#fffff' }}>
          <Lottie
            options={defaultOptions}
            height={102}
            width={102}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', px: 2, backgroundColor: background, height: 68, width: '100%' }}>
          <Typography
            color={color}
            fontWeight="600"
          >
            {message}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
});

Snack.propTypes = {
  message: PropTypes.string.isRequired,
  background: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  animationData: PropTypes.string.isRequired
};
export default Snack;
