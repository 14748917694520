import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const UnitViewDetails = (props) => {
  const { unit, onEdit, onDelete, onCancel, ...other } = props;

  return (
    <Card {...other}>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Unidade
        </Typography>
      </Box>
      <Scrollbar>
        <Box sx={{ p: 3 }}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Código
                </TableCell>
                <TableCell sx={{ fontWeight: 400 }}>
                  {unit.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Descrição
                </TableCell>
                <TableCell>
                  {unit.description}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="text"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="text"
          onClick={onDelete}
        >
          Apagar
        </Button>
        <Button
          color="primary"
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={onEdit}
        >
          Editar
        </Button>
      </Box>
    </Card>
  );
};

UnitViewDetails.propTypes = {
  unit: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default UnitViewDetails;
