import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  TextField,
  Typography,
  createFilterOptions,
  FormHelperText
} from '@mui/material';

import { useEffect, useState, useRef, useCallback } from 'react';

import { clone, debounce } from 'lodash';

import ReturnNoteItemTable from '../creditNoteItem/CreditNoteItemTable';
import ReturnNoteItemForm from '../creditNoteItem/CreditNoteItemForm';
import ReturnNoteItemSummation from '../creditNoteItem/CreditNoteItemSummation';
import InvalidTotalPopUp from '../InvalidTotalPopUp';
import { useForm } from '../../hooks/useForm';

import { documentReferenceLabel } from '../../util/documentReferenceLabel';
import CustomDatePicker from '../CustomDatePicker';
import dayjs from 'dayjs';

const filter = createFilterOptions();

const initialValues = {
  serie: undefined,
  numberOfDocument: 1,
  date: new Date(),
  paymentPeriod: undefined,
  client: undefined,
  issueReason: undefined,
  issueReasonDescription: undefined,
  otherIssueReason: undefined,
  paymentMode: undefined,
  commentary: undefined,
  items: [],
  documentReference: []
};

const Form = (props) => {
  const {
    onCancel,
    onSubmitForm,
    series,
    getNextReturnNoteNumber,
    clients,
    paymentPeriods,
    paymentModes,
    returnNote,
    isInSerieDateRange,
    currentCompany,
    draftId,
    returnNoteDraft,
    returnNoteStorage,
    setReturnNoteStorage,
    issueReasons,
    handleDeleteDraft,
    documents,
    getPaymentPeriodRange,
    units,
    taxes,
    items,
    documentName,
    issueReasonTypes,
    isOnRequest,
  } = props;

  const [currentSerie, setCurrentSerie] = useState(null);
  const [serieInitialLength, setSerieInitialLength] = useState(null);
  const [serieInputValue, setSerieInputValue] = useState(null);
  const [paymentPeriodInitialLength, setPaymentPeriodInitialLength] = useState(null);
  const [paymentPeriodInputValue, setPaymentPeriodInputValue] = useState(null);
  const [paymentModeInitialLength, setPaymentModeInitialLength] = useState(null);
  const [paymentModeInputValue, setPaymentModeInputValue] = useState(null);
  const [clientInitialLength, setClientInitialLength] = useState(null);
  const [clientInputValue, setClientInputValue] = useState(null);
  const [issueReasonInitialLength, setIssueReasonInitialLength] = useState(null);
  const [issueReasonInputValue, setIssueReasonInputValue] = useState(null);
  const [documentReferenceInitialLength, setDocumentReferenceInitialLength] = useState(null);
  const [documentReferenceInputValue, setDocumentReferenceInputValue] = useState(null);
  const [isInvalidTotalPopUpOpen, setIsInvalidTotalPopUpOpen] = useState(false);
  const [returnNoteItems, setReturnNoteItems] = useState([]);
  const [returnNoteItemError, setReturnNoteItemError] = useState(false);
  const [documentsOfClient, setDocumentsOfClient] = useState([]);
  const [selectedDocumentReference, setSelectedDocumentReference] = useState(null);
  const [selectedDocumentReferenceItems, setSelectedDocumentReferenceItems] = useState(null);
  const [returnNoteItemsTotal, setReturnNoteItemsTotal] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(null);
  const [message, setMessage] = useState(null);
  const [isTotalChanged, setIstotalChanged] = useState(false);
  const [documentReference, setDocumentReference] = useState(null);
  const [isReturnNoteItemPopUpOpen, setIsReturnNoteItemPopUpOpen] = useState(false);
  const [selectedReturnNoteItem, setSelectedReturnNoteItem] = useState();
  const [taxesMerged, setTaxesMerged] = useState(null);
  const [isItemEdited, setIsItemEdited] = useState(false);

  const {
    openForm
  } = useForm();

  const formikRef = useRef();

  const currentReturnNote = returnNote ?? returnNoteDraft ?? null;

  useEffect(() => {
    if (returnNoteDraft?.documentReference?.length) {
      const { id, name, number, date, totalGeneral } = returnNoteDraft.documentReference[0].document;
      const { items: documentItems } = documents.find((document) => document.id === id);
      setDocumentReferenceInputValue(documentReferenceLabel(name, number, date, totalGeneral));
      const newDocumentItems = documentItems?.map((documentItem, index) => ({
        id: returnNoteDraft.items[index].id,
        discount: Number(documentItem.discount),
        item: documentItem.item.id,
        itemCode: documentItem.item.code,
        tax: documentItem.tax.id,
        taxValue: Number(documentItem.tax.value),
        taxName: documentItem.tax.name,
        price: Number(documentItem.price),
        quantity: Number(documentItem.quantity),
        unit: documentItem.unit,
        total: Number(documentItem.itemTotal),
        deleted: false,
        edited: false
      }));
      setSelectedDocumentReferenceItems(newDocumentItems);
    }
  }, [returnNoteDraft]);

  const returnNoteValues = currentReturnNote ? ({
    serie: currentReturnNote.serie,
    numberOfDocument: 1,
    date: new Date(),
    paymentPeriod: currentReturnNote.paymentPeriod?.id || '',
    client: currentReturnNote.clientId || '',
    issueReason: currentReturnNote.issueReasonId || '',
    issueReasonDescription: currentReturnNote.issueReasonDescription || '',
    otherIssueReason: currentReturnNote.otherIssueReason || '',
    paymentMode: currentReturnNote.paymentMode?.id || '',
    commentary: currentReturnNote.commentary ?? '',
    documentReference: currentReturnNote.documentReference ?? [],
    items: currentReturnNote.items ?? []
  }) : null;

  useEffect(() => {
    const [lastDocument = {}] = documentsOfClient.slice(-1);
    if (!documentReferenceInitialLength && documentsOfClient) {
      setDocumentReferenceInitialLength(documentsOfClient.length);
    } else if (documentsOfClient?.length > documentReferenceInitialLength) {
      setDocumentReferenceInputValue(documentReferenceLabel(lastDocument.name, lastDocument.number, lastDocument.date, lastDocument.totalGeneral));
      if (formikRef.current) {
        formikRef.current.values.documentReference.document = { id: lastDocument.id, code: lastDocument.id };
      }
    }
  }, [documents]);

  useEffect(() => {
    if (!serieInitialLength && series) {
      setSerieInitialLength(series.length);
    } else if (series?.length > serieInitialLength) {
      setSerieInputValue(series[(series.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.serie = series[(series.length) - 1].id;
      }
    }
  }, [series]);

  useEffect(() => {
    setPaymentAmount(returnNoteItemsTotal);
  }, [returnNoteItemsTotal]);

  useEffect(() => {
    if (!paymentPeriodInitialLength && paymentPeriods) {
      setPaymentPeriodInitialLength(paymentPeriods.length);
    } else if (paymentPeriods?.length > paymentPeriodInitialLength) {
      setPaymentPeriodInputValue(paymentPeriods[(paymentPeriods.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentPeriod = paymentPeriods[(paymentPeriods.length) - 1].id;
      }
    }
  }, [paymentPeriods]);

  useEffect(() => {
    if (!paymentModeInitialLength && paymentModes) {
      setPaymentModeInitialLength(paymentModes.length);
    } else if (paymentModes?.length > paymentModeInitialLength) {
      setPaymentModeInputValue(paymentModes[(paymentModes.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentMode = paymentModes[(paymentModes.length) - 1].id;
      }
    }
  }, [paymentModes]);

  useEffect(() => {
    if (!clientInitialLength && clients) {
      setClientInitialLength(clients.length);
    } else if (clients?.length > clientInitialLength) {
      setClientInputValue({ name: clients[(clients.length) - 1].name, id: clients[(clients.length) - 1].id });

      if (formikRef.current) {
        formikRef.current.values.client = clients[(clients.length) - 1].id;
      }
    }
  }, [clients]);

  useEffect(() => {
    if (!issueReasonInitialLength && issueReasons) {
      setIssueReasonInitialLength(issueReasons.length);
    } else if (issueReasons?.length > issueReasonInitialLength) {
      setIssueReasonInputValue(issueReasons[(issueReasons.length) - 1].description);

      if (formikRef.current) {
        formikRef.current.values.issueReason = issueReasons[(issueReasons.length) - 1].id;
      }
    }
  }, [issueReasons]);

  const handleInvalidTotalPopUpOpen = () => {
    setIsInvalidTotalPopUpOpen(true);
  };

  const handleInvalidTotalPopUpClose = () => {
    setIsInvalidTotalPopUpOpen(false);
  };

  const updateReturnNoteStorage = (data) => {
    const idCompany = currentCompany.id;
    const initialDraftsStorage = {
      returnNotes: []
    };

    const draftsStorage = clone(returnNoteStorage) || clone(initialDraftsStorage);
    let hasCompanyStorage;
    const companyIndex = draftsStorage.returnNotes.findIndex((company) => hasCompanyStorage = idCompany in company);
    let updatedReturnNoteStorage = clone(initialValues);
    const drafts = [];

    if (!hasCompanyStorage) {
      data.keys.forEach((key, index) => updatedReturnNoteStorage[key] = data.values[index]);
      drafts.push({ [draftId]: updatedReturnNoteStorage });
      draftsStorage.returnNotes.push(
        {
          [idCompany]: drafts
        }
      );
    } else {
      const invalidIndex = -1;
      const draftIndex = draftsStorage.returnNotes[companyIndex][idCompany].findIndex((draft) => draftId in draft);
      if (draftIndex > invalidIndex) {
        updatedReturnNoteStorage = draftsStorage.returnNotes[companyIndex][idCompany][draftIndex][draftId];
        data.keys.forEach((key, index) => updatedReturnNoteStorage[key] = data.values[index]);
        draftsStorage.returnNotes[companyIndex][idCompany][draftIndex][draftId] = updatedReturnNoteStorage;
      } else {
        data.keys.forEach((key, index) => updatedReturnNoteStorage[key] = data.values[index]);
        draftsStorage.returnNotes[companyIndex][idCompany].push({
          [draftId]: updatedReturnNoteStorage
        });
      }
    }
    setReturnNoteStorage(draftsStorage);
  };

  const debouncedUpdateReturnNoteStorage = debounce(updateReturnNoteStorage, 1000);

  const handleFetchDocumentItems = (documentItems) => {
    const newReturnNoteItems = documentItems?.map((documentItem) => ({
      // this is a temporary id, since we won't send this id it to api
      id: Math.random(),
      discount: Number(documentItem.discount),
      item: documentItem.item.id,
      itemCode: documentItem.item.code,
      tax: documentItem.tax.id,
      taxValue: Number(documentItem.tax.value),
      taxName: documentItem.tax.name,
      price: Number(documentItem.price),
      quantity: Number(documentItem.quantity),
      unit: documentItem.unit,
      total: Number(documentItem.itemTotal),
      deleted: false,
      edited: false
    }));
    setSelectedDocumentReferenceItems(newReturnNoteItems);
    setReturnNoteItems(newReturnNoteItems);
    debouncedUpdateReturnNoteStorage({ keys: ['items'], values: [newReturnNoteItems || []] });
    setReturnNoteItemError(false);
  };

  const resetAllItems = () => {
    setReturnNoteItems(selectedDocumentReferenceItems);
    debouncedUpdateReturnNoteStorage({ keys: ['items'], values: [selectedDocumentReferenceItems] });
  };

  const handleUpdateReturnNoteItem = (updatedReturnNoteItem) => {
    const editedReturnNoteItemList = returnNoteItems.map(((returnNoteItem) => {
      const { deleted, edited } = returnNoteItem;
      if (updatedReturnNoteItem.id === returnNoteItem.id) {
        return { deleted, edited, ...updatedReturnNoteItem };
      }
      return returnNoteItem;
    }));

    setReturnNoteItems(editedReturnNoteItemList);
    debouncedUpdateReturnNoteStorage({ keys: ['items'], values: [editedReturnNoteItemList] });
  };

  useEffect(() => {
    const referencedDocumentItems = selectedDocumentReference?.items;
    if (referencedDocumentItems) {
      handleFetchDocumentItems(referencedDocumentItems);
    }
  }, [selectedDocumentReference]);

  const handleSelectReturnNoteItem = (returnNoteItem) => {
    setSelectedReturnNoteItem(returnNoteItem);
  };

  /* An item in a return note has two types of states - deleted and edited.
  *  If the action is to delete, this function will change the item to deleted(its value as true) or not(its value as false),
  *  depending on the state of its property. If the action is not to delete (i.e., to edit),
  *  this function will change the item's state to edited (value as true - if the user changes one of its fields)
  *  or not (value as false - if the user wants to reset the item to its previous values if they have already been changed).
  */
  const handleChangeReturnNoteItemStatus = (_returnNoteItem, deleteAction) => {
    const returnNoteItemsChanged = returnNoteItems.map((returnNoteItem) => {
      if (returnNoteItem.id === _returnNoteItem?.id) {
        const { id, deleted: newDeleted, edited: newEdited, ...rest } = returnNoteItem;
        const item = selectedDocumentReferenceItems.find((selectedItem) => selectedItem.id === id);
        if (deleteAction) {
          return { id, deleted: !newDeleted, edited: newEdited, ...rest };
        }
        if (isItemEdited && id === _returnNoteItem.id) {
          return { id, deleted: newDeleted, edited: isItemEdited, ...rest };
        }
        return { id, deleted: newDeleted, edited: isItemEdited, ...item };
      }
      return returnNoteItem;
    });
    setReturnNoteItems(returnNoteItemsChanged);

    if (returnNoteItemsChanged.length === 0) {
      setReturnNoteItemError(true);
    }
    debouncedUpdateReturnNoteStorage({ keys: ['items'], values: [returnNoteItemsChanged] });
  };

  const handleSetCurrentSerie = (value) => {
    setCurrentSerie(value);
  };

  const handleDisableOrEnableAllItems = (disable) => {
    const newReturnNoteItems = returnNoteItems.map((returnNoteItem) => {
      const { deleted: newDeleted, ...rest } = returnNoteItem;
      return { deleted: disable, ...rest };
    });
    setReturnNoteItems(newReturnNoteItems);
  };

  const isSerieDraftExist = () => series?.find((serie) => currentReturnNote?.serie === serie.id);
  const isPaymentPeriodExist = () => paymentPeriods?.find((paymentPeriod) => currentReturnNote?.paymentPeriod?.id === paymentPeriod.id);
  const isPaymentModeExist = () => paymentModes?.find((paymentMode) => currentReturnNote?.paymentMode?.id === paymentMode.id);
  const isClientExist = () => clients?.find((client) => currentReturnNote?.clientId === client.id);
  const isIssueReasonExist = () => issueReasons?.find((issueReason) => currentReturnNote?.issueReasonId === issueReason.id);
  const isDocumentReferenceExist = () => documentsOfClient?.find((document) => currentReturnNote?.documentReference?.[0]?.document.id === document.id);
  const isClientAndSerieSet = useCallback((values) => !(values.client && values.serie));

  useEffect(() => {
    if (currentReturnNote) {
      setReturnNoteItems(currentReturnNote.items?.map((item) => ({
        id: item.id,
        discount: Number(item.discount),
        item: item.item.id || item.item,
        itemCode: item.item.code || item.itemCode,
        tax: item.tax.id || item.tax,
        taxValue: Number(item.tax.value) || Number(item.taxValue),
        taxName: item.tax.name || item.taxName,
        price: Number(item.price),
        quantity: Number(item.quantity),
        unit: item.unit,
        total: Number(item.itemTotal) || Number(item.total),
        deleted: item.deleted,
        edited: item.edited
      })));
    }
  }, []);

  useEffect(() => {
    if ((series && returnNote) || (series && currentReturnNote)) {
      handleSetCurrentSerie(series.find((serie) => serie.id === currentReturnNote?.serie));
    }
  }, [series]);

  const handleDocumentReferenceClick = (setTouched, values) => {
    if (!values.client || !values.serie) {
      setTouched({
        serie: true,
        client: true,
        date: true
      });
    }
  };

  const handleFormCreate = (event, values) => {
    const { serie, date, paymentPeriod, paymentMode, client, issueReason, documentReference: documentReferenced } = values;
    const isAllValuesSet = (
      serie && date
      && paymentMode && paymentPeriod
      && client && issueReason
      && documentReferenced?.length
    );
    if (!isTotalChanged && isAllValuesSet) {
      event.preventDefault();
      setMessage(`Não houve alterações relativas à ${selectedDocumentReference?.name}`);
      handleInvalidTotalPopUpOpen();
    }
  };

  useEffect(() => {
    if (documentReference) {
      const { paymentAmount: deletedPaymentAmount, ...rest } = documentReference;
      const newDocumentReference = [{ ...rest, paymentAmount }];
      debouncedUpdateReturnNoteStorage({ keys: ['documentReference'], values: [newDocumentReference] });
    }
  }, [paymentAmount]);

  const handleOpenReturnNoteItemForm = () => {
    setIsReturnNoteItemPopUpOpen(true);
  };

  const handleCloseReturnNoteItemForm = () => {
    setIsReturnNoteItemPopUpOpen(false);
    setSelectedReturnNoteItem();
  };

  const handleFormatTaxes = () => {
    const reducer = (acc, currentValue) => {
      acc[currentValue.id] = currentValue;
      return acc;
    };

    const hashItens = items ? items
      .map((item) => item.tax)
      .reduce(reducer, {}) : {};

    const hashTaxes = taxes ? taxes.reduce(reducer, hashItens) : {};

    setTaxesMerged(Object.values(hashTaxes));
  };

  useEffect(() => {
    handleFormatTaxes();
  }, [taxes, items]);

  useEffect(() => {
    if (isItemEdited) {
      handleChangeReturnNoteItemStatus(selectedReturnNoteItem, false);
      setIsItemEdited(false);
    }
  }, [isItemEdited]);

  const handleSetOtherIssueReason = (touched, errors, handleBlur, handleChange, values) => (issueReasonInputValue !== issueReasonTypes?.OTR.description ? '' : (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        p: 1
      }}
    >
      <Typography
        color="textSecondary"
        variant="overline"
      >
        Digite o seu Motivo de Retificação
      </Typography>
      <TextField
        id="return-note-form-other-issue-reason-input"
        error={Boolean(touched.otherIssueReason && errors.otherIssueReason)}
        fullWidth
        helperText={touched.otherIssueReason && errors.otherIssueReason}
        name="otherIssueReason"
        onBlur={handleBlur}
        onChange={(event) => {
          handleChange(event);
          debouncedUpdateReturnNoteStorage({ keys: ['otherIssueReason'], values: [event.target.value] });
        }}
        value={values.otherIssueReason}
        variant="standard"
        multiline
      />
    </Box>
  ));

  const handleDateChange = (newValue, setFieldValue) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue('date', newValue.toDate());
    }
  };

  const [date, setDate] = useState();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={(returnNote || currentReturnNote) ? returnNoteValues : initialValues}
      validationSchema={Yup
        .object()
        .shape({
          serie: Yup
            .string()
            .trim()
            .required('Obrigatório'),
          date: Yup
            .date()
            .min(new Date(currentSerie?.startDateRaw || new Date()),
              'Esta data não pertence à série selecionada')
            .max(new Date(currentSerie?.endDateRaw || new Date()),
              'Esta data não pertence à série selecionada')
            .required('Obrigatório'),
          paymentPeriod: Yup
            .string()
            .required('Obrigatório'),
          paymentMode: Yup
            .string()
            .required('Obrigatório'),
          client: Yup
            .string()
            .trim()
            .required('Obrigatório'),
          issueReason: Yup
            .string()
            .trim()
            .required('Obrigatório'),
          otherIssueReason: Yup
            .string()
            .when('issueReason', {
              is: (val) => val === issueReasons?.find((issueReason) => issueReason?.code === issueReasonTypes?.OTR.code)?.id,
              then: Yup
                .string()
                .trim()
                .required('Obrigatório')
            }),
          documentReference: Yup
            .array()
            .min(1, 'Obrigatório')
            .required('Obrigatório'),
          commentary: Yup
            .string()
            .max(200)
            .trim(),
          numberOfDocument: Yup
            .number()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.items = returnNoteItems.filter((returnNoteItem) => !returnNoteItem.deleted);
          values.documentReference[0].paymentAmount = paymentAmount;

          if (!values.items.length) {
            setReturnNoteItemError(true);
            return;
          }
          onSubmitForm(values);
          handleDeleteDraft(draftId);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        touched,
        values,
        setFieldValue,
        setTouched
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Container>
            <Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                mt: 2
              }}
              >

                <Autocomplete
                  id="return-note-form-serie-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={series || []}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);
                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });
                    return filtered;
                  }}
                  disableClearable
                  inputValue={serieInputValue || ''}
                  defaultValue={currentReturnNote && isSerieDraftExist() ? ({
                    id: isSerieDraftExist()?.id,
                    name: isSerieDraftExist()?.name
                  }) : null}
                  onChange={(event, value) => {
                    handleSetCurrentSerie(value);
                    setSerieInputValue(value.name);
                    setFieldValue('serie', value.id || '');
                    debouncedUpdateReturnNoteStorage({ keys: ['serie', 'serieName', 'serieCode'], values: [value.id || '', value.name || '', value.code || ''] });
                    if (value.name === 'Criar Novo') {
                      openForm('SERIE');
                      value.name = serieInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && serieInputValue !== '') || serieInputValue.length === 1) {
                      setSerieInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.serie && errors.serie)}
                      helperText={touched.serie && errors.serie}
                      label="Série*"
                      name="serie"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />

                <TextField
                  id="return-note-form-number-of-document"
                  error={Boolean(touched.numberOfDocument && errors.numberOfDocument)}
                  fullWidth
                  helperText={touched.numberOfDocument && errors.numberOfDocument}
                  disabled
                  label="Número*"
                  name="numberOfDocument"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={getNextReturnNoteNumber(values.serie) || 1}
                  variant="outlined"
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <Box>
                  <CustomDatePicker
                    id="return-note-form-date-picker"
                    label="Data"
                    onChange={(newValue) => {
                      setDate(newValue);
                      handleDateChange(newValue, setFieldValue)}
                    }
                    values={date}
                  />
                  {(currentSerie && !isInSerieDateRange(values.date, currentSerie)) && (
                    <Box>
                      <FormHelperText
                        id="return-note-form-date-picker-helper-text"
                        error
                      >
                        Esta data não pertence à série selecionada
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
                <Autocomplete
                  id="return-note-form-payment-period-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={paymentPeriods || []}
                  defaultValue={currentReturnNote && isPaymentPeriodExist() ? ({
                    id: isPaymentPeriodExist().id,
                    name: isPaymentPeriodExist().name
                  }) : null}
                  disableClearable
                  inputValue={paymentPeriodInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentPeriodInputValue(value.name);
                    setFieldValue('paymentPeriod', value.id || '');
                    debouncedUpdateReturnNoteStorage({ keys: ['paymentPeriod'], values: [value || ''] });

                    if (value.name === 'Criar Novo') {
                      openForm('PAYMENT_PERIOD');
                      value.name = paymentPeriodInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentPeriodInputValue !== '') || paymentPeriodInputValue.length === 1) {
                      setPaymentPeriodInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentPeriod && errors.paymentPeriod)}
                      helperText={touched.paymentPeriod && errors.paymentPeriod}
                      label="Período de Pagamento*"
                      name="paymentPeriod"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Limite de Pagamento"
                  name="range"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    debouncedUpdateReturnNoteStorage({ keys: ['paymentPeriod'], values: [event.target.value || ''] });
                  }}
                  value={getPaymentPeriodRange(values)}
                  variant="outlined"
                />
                <Autocomplete
                  id="return-note-form-payment-mode-autocomplete"
                  getOptionLabel={(option) => option.code}
                  options={paymentModes || []}
                  disableClearable
                  defaultValue={currentReturnNote && isPaymentModeExist() ? ({
                    id: isPaymentModeExist()?.id,
                    code: isPaymentModeExist()?.code
                  }) : null}
                  inputValue={paymentModeInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentModeInputValue(value.code);
                    setFieldValue('paymentMode', value.id || '');
                    debouncedUpdateReturnNoteStorage({ keys: ['paymentMode'], values: [value || ''] });

                    if (value.code === 'Criar Novo') {
                      openForm('PAYMENT_MODE');
                      value.code = paymentModeInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentModeInputValue !== '') || paymentModeInputValue.length === 1) {
                      setPaymentModeInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      code: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentMode && errors.paymentMode)}
                      helperText={touched.paymentMode && errors.paymentMode}
                      label="Modo de Pagamento*"
                      name="paymentMode"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >

                <Autocomplete
                  id="return-note-form-client-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={clients || []}
                  disableClearable
                  inputValue={clientInputValue || ''}
                  defaultValue={currentReturnNote && isClientExist() ? ({
                    id: isClientExist().id,
                    name: isClientExist().name
                  }) : null}
                  onChange={(event, value) => {
                    setClientInputValue(value.name);
                    setFieldValue('client', value.id || '');
                    setDocumentsOfClient(documents.filter((doc) => doc.client === value.id && !doc.isVoided));
                    setSelectedDocumentReference([]);
                    debouncedUpdateReturnNoteStorage({ keys: ['clientId', 'clientName', 'items', 'documentReference'], values: [value.id || '', value.name || '', [], []] });
                    setFieldValue('documentReference', []);
                    setReturnNoteItems([]);
                    setDocumentReferenceInputValue();
                    if (value.name === 'Criar Novo') {
                      openForm('CLIENT');
                      value.name = clientInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && clientInputValue !== '') || clientInputValue.length === 1) {
                      setClientInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.client && errors.client)}
                      helperText={touched.client && errors.client}
                      label="Cliente*"
                      name="client"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <Autocomplete
                  id="return-note-form-issueReason-autocomplete"
                  getOptionLabel={(option) => option.description}
                  options={issueReasons || []}
                  disableClearable
                  inputValue={issueReasonInputValue || ''}
                  defaultValue={currentReturnNote && isIssueReasonExist() ? ({
                    id: isIssueReasonExist().id,
                    code: isIssueReasonExist().code,
                    description: isIssueReasonExist().description
                  }) : null}
                  onChange={(event, value) => {
                    setIssueReasonInputValue(value.description);
                    setFieldValue('issueReason', value.id || '');
                    setFieldValue('issueReasonDescription', value.description);
                    debouncedUpdateReturnNoteStorage({ keys: ['issueReasonId', 'issueReasonDescription'], values: [value.id || '', value.description || ''] });
                    if (value.description !== issueReasonTypes?.OTR.description) {
                      setFieldValue('otherIssueReason', '');
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && issueReasonInputValue !== '') || issueReasonInputValue.length === 1) {
                      setIssueReasonInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.issueReason && errors.issueReason)}
                      helperText={touched.issueReason && errors.issueReason}
                      label="Motivo de Retificação*"
                      name="issueReason"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              {handleSetOtherIssueReason(touched, errors, handleBlur, handleChange, values)}
              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <Autocomplete
                  id="return-note-form-document-reference-autocomplete"
                  disabled={isClientAndSerieSet(values)}
                  getOptionLabel={(option) => documentReferenceLabel(option.name, option.number, option.date, option.totalGeneral)}
                  key={clientInputValue}
                  options={documentsOfClient || []}
                  inputValue={documentReferenceInputValue || ''}
                  disableClearable
                  defaultValue={currentReturnNote && isDocumentReferenceExist() ? ({
                    id: isDocumentReferenceExist().id,
                    code: isDocumentReferenceExist().code,
                    date: isDocumentReferenceExist().date,
                    name: isDocumentReferenceExist().document?.name,
                    number: isDocumentReferenceExist().number,
                    totalGeneral: isDocumentReferenceExist().totalGeneral
                  }) : null}
                  onChange={(event, value) => {
                    setDocumentReferenceInputValue(documentReferenceLabel(value.name, value.number, value.date, value.totalGeneral));
                    setFieldValue('documentReference', [{ document: { id: value.id, code: value.code }, paymentAmount }] || []);
                    debouncedUpdateReturnNoteStorage({ keys: ['documentReference'], values: [[{ document: { id: value.id || '', code: value.code || '', number: value.number || '', name: value.name || '', date: value.date || '', totalGeneral: value.totalGeneral || '' }, paymentAmount }]] });
                    const documentOfClient = documentsOfClient.find((document) => document.id === value.id);
                    setSelectedDocumentReference(documentOfClient);
                    setDocumentReference({ document: { id: value.id || '', code: value.code || '', number: value.number || '', name: value.name || '', date: value.date || '', totalGeneral: value.totalGeneral || '' }, paymentAmount });
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && documentReferenceInputValue !== '')) {
                      setDocumentReferenceInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.documentReference && errors.documentReference)}
                      helperText={touched.documentReference && errors.documentReference}
                      label="Documento a Referenciar*"
                      name="documentReference"
                      onClick={() => { handleDocumentReferenceClick(setTouched, values); }}
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={{ py: 4 }}>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista de itens na nota de devolução
              </Typography>
              <ReturnNoteItemTable
                currentCompany={currentCompany}
                creditNoteItems={returnNoteItems}
                onChangeItemStatus={handleChangeReturnNoteItemStatus}
                onOpenForm={handleOpenReturnNoteItemForm}
                selectCreditNoteItem={handleSelectReturnNoteItem}
                disableOrEnableAllItems={handleDisableOrEnableAllItems}
                resetAllItems={resetAllItems}
              />
              <Typography
                color="error"
                variant="overline"
                hidden={!returnNoteItemError}
              >
                Obrigatório
              </Typography>
              <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleCloseReturnNoteItemForm}
                open={isReturnNoteItemPopUpOpen}
              >
                {isReturnNoteItemPopUpOpen && (
                  <ReturnNoteItemForm
                    onComplete={handleCloseReturnNoteItemForm}
                    onCancel={handleCloseReturnNoteItemForm}
                    onEdit={handleUpdateReturnNoteItem}
                    selectedCreditNoteItem={selectedReturnNoteItem}
                    selectedDocumentReferenceItems={selectedDocumentReferenceItems}
                    openForm={openForm}
                    units={units}
                    taxes={taxesMerged}
                    items={items}
                    documentName={documentName}
                    setMessage={setMessage}
                    handleInvalidTotalPopUpOpen={handleInvalidTotalPopUpOpen}
                    setIsItemEdited={setIsItemEdited}
                    currentCompany={currentCompany}
                  />
                )}
              </Dialog>
            </Box>
            <Box sx={{
              py: 4,
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: 4
            }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="overline"
                >
                  Comentário(s)
                </Typography>
                <TextField
                  id="return-note-form-commentary-input"
                  error={Boolean(touched.commentary && errors.commentary)}
                  fullWidth
                  helperText={touched.commentary && errors.commentary}
                  name="commentary"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                    debouncedUpdateReturnNoteStorage({ keys: ['commentary'], values: [event.target.value] });
                  }}
                  value={values.commentary}
                  variant="standard"
                  multiline
                />
              </Box>
              <ReturnNoteItemSummation
                currentCompany={currentCompany}
                creditNoteItems={returnNoteItems}
                setIsTotalChanged={setIstotalChanged}
                setCreditNoteTotal={setReturnNoteItemsTotal}
              />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Voltar
              </Button>
              <Button
                id="return-note-form-add-button"
                color="primary"
                disabled={isOnRequest}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
                onClick={(e) => handleFormCreate(e, values)}
              >
                Criar
              </Button>
            </Box>
            <Dialog
              maxWidth="xs"
              onClose={handleInvalidTotalPopUpClose}
              open={isInvalidTotalPopUpOpen}
            >
              {isInvalidTotalPopUpOpen && (
                <InvalidTotalPopUp
                  onClick={handleInvalidTotalPopUpClose}
                  message={message}
                  action="Voltar"
                />
              )}
            </Dialog>
          </Container>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  isInSerieDateRange: PropTypes.func.isRequired,
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lastDocumentNumber: PropTypes.string,
    range: PropTypes.string,
    fiscalYearName: PropTypes.string,
    fiscalYearDate: PropTypes.string
  }).isRequired,
  returnNote: PropTypes.shape({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientId: PropTypes.string,
    clientName: PropTypes.string,
    serieCode: PropTypes.string,
    paymentPeriod: PropTypes.string,
    isVoided: PropTypes.bool,
    paymentMode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    serieName: PropTypes.string,
    valueWithoutTax: PropTypes.number,
    totalTax: PropTypes.number,
    valueWithTax: PropTypes.number,
    discount: PropTypes.number,
    totalGeneral: PropTypes.number,
    number: PropTypes.number,
    items: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    }),
    documentReference: PropTypes.arrayOf({
      id: PropTypes.string,
      code: PropTypes.string
    })
  }).isRequired,
  getNextReturnNoteNumber: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  clients: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  paymentPeriods: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.string
  }).isRequired,
  paymentModes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  units: PropTypes.arrayOf({
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    tax: PropTypes.number
  }).isRequired,
  items: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.string.isRequired,
  draftId: PropTypes.number.isRequired,
  returnNoteDraft: PropTypes.object.isRequired,
  returnNoteStorage: PropTypes.object.isRequired,
  setReturnNoteStorage: PropTypes.func.isRequired,
  handleDeleteDraft: PropTypes.func.isRequired,
  issueReasons: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  documents: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  getPaymentPeriodRange: PropTypes.func.isRequired,
  documentName: PropTypes.string.isRequired,
  issueReasonTypes: PropTypes.object.isRequired,
  isOnRequest: PropTypes.bool.isRequired,
};

export default Form;
