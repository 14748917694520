export const THEMES = {
  LIGHT: 'LIGHT',
  DARK: 'DARK',
  NATURE: 'NATURE'
};

export const FORM = {
  SERIE: 'SERIE',
  DOCUMENT_TYPE: 'DOCUMENT_TYPE',
  CLIENT: 'CLIENT',
  PAYMENT_PERIOD: 'PAYMENT_PERIOD',
  PAYMENT_MODE: 'PAYMENT_MODE',
  UNIT: 'UNIT',
  TAX: 'TAX',
  ITEM: 'ITEM',
  FISCAL_YEAR: 'FISCAL_YEAR',
  PROVIDER: 'PROVIDER'
};

export const CREATE_NEW = 'Criar Novo';

export const POPUP_CONTENT_MESSAGE = {
  DELETE: {
    MESSAGE: 'Tens certeza que queres apagar?',
    ACTION: 'Apagar'
  },
  VOID: {
    MESSAGE: 'Tens certeza que queres anular?',
    ACTION: 'Anular'
  }
};

export const LOGO_VARIANT = {
  WHITE: 'white',
  GREEN: 'green'
};

export const TOAST_TYPE = {
  LOADING: 'LOADING',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
};

export const CAPE_VERDE_TIME_ZONE = '00:00:00-01';

export const MAX_DOC_NUMBER = 2000000000;
export const MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR = '2,000,000,000';

export const DOCUMENT_TYPE_NAME = {
  invoice: 'Fatura',
  receipt: 'Recibo',
  invoice_receipt: 'Fatura/Recibo',
  debit_note: 'Nota de Débito',
  credit_note: 'Nota de Crédito'
};

export const DOCUMENT_TYPE_CODE = {
  invoice_receipt: 'FR'
};

export const CAPE_VERD_CURRENCY_ID = 'CVE';

export const CAPE_VERDE_COUNTRY_CODE = '+238';

export const DOCUMENTS = {
  invoice: {
    name: 'Fatura',
    code: 'FA'
  },
  invoice_receipt: {
    name: 'Fatura/Recibo',
    code: 'FR'
  },
  credit_note: {
    name: 'Nota de Crédito',
    code: 'NC'
  },
  debit_note: {
    name: 'Nota de Débito',
    code: 'ND'
  },
  pro_forma: {
    name: 'Pro Forma',
    code: 'PF'
  },
  return_note: {
    name: 'Nota de Devolução',
    code: 'NDV'
  },
  receipt: {
    name: 'Recibo',
    code: 'RE'
  },
  sales_receipt: {
    name: 'Talão de Venda',
    code: 'SR'
  }
};

export const PAYMENT_SERVICES = {
  stripe: 'STRIPE',
  sisp: 'SISP',
  ping: 'PING'
};

export const ISSUE_REASONS_DOCS = {
  creditNote: 'NCE',
  debitNote: 'NDE',
  returnNote: 'DVE'
};

export const ISSUE_REASONS = {
  A65N2: [
    'NCE',
    'NDE',
    'DVE'
  ],
  A65N3: [
    'NCE',
    'NDE',
    'DVE'
  ],
  A65N4: [
    'NDE'
  ],
  A65N6: [
    'NCE',
    'NDE',
    'DVE'
  ],
  A65N7: [
    'NCE',
    'DVE'
  ],
  A65N8: [
    'NCE',
    'NDE',
    'DVE'
  ],
  A65N9: [
    'NCE',
    'NDE',
    'DVE'
  ],
  DD: [
    'NDE'
  ],
  OTR: [
    'DVE'
  ],
  IN: [
    'NCE',
    'NDE',
    'DVE'
  ]
};

export const DOCUMENTS_TO_VOID_INVOICE = [
  {
    name: 'Nota de Devolução',
    code: 'NDV'
  },
  {
    name: 'Nota de Crédito',
    code: 'NC'
  }
];

export const DOCUMENTS_TO_VOID_INVOICE_FORMATTED = {
  creditNote: {
    name: 'creditNote',
    code: 'NC'
  },
  returnNote: {
    name: 'returnNote',
    code: 'NDV'
  }
};

export const EFATURA_DOCUMENT_STATUS = {
  completed: 'completed',
  failed: 'failed',
  pending: 'pending'
};

export const POPUP_STATUS = {
  success: 'success',
  error: 'error'
};

export const SAMBA_PAYMENT_DEBT_STATUS = {
  liquidated: 'liquidated',
  pending: 'pending'
};

export const EFATURA_BANNER_MESSAGE = 'Esta empresa não está integrada na plataforma e-Fatura.';

export const USER_TYPES = {
  attendee: 'Attendee'
};

export const BANK_WITHDRAWAL_STATUS = {
  completed: 'completed',
  rejected: 'rejected',
  pending: 'pending'
};

export const BALANCE_TYPES = {
  pingPOS: 'PING_POS',
  CARD: 'CARD'
};

export const BANK_WITHDRAWAL_TYPE = {
  PING: 'PING',
  CARD: 'CARD'
};

export const INTEGRATION_TYPES = {
  pingPOS: 'PING_POS'
};

export const WARNING_PING_INTEGRATION_TEXT = 'Você não pode acessar esta página no momento, pois a empresa não está integrada com o SAMBA POS. Por favor, clique no botão abaixo para ir para a página de integração.';

export const HELMET_TITLES = {
  SALES: {
    DOCUMENTS: {
      INVOICE: 'Fatura | Samba',
      RECEIPT: 'Recibo | Samba',
      INVOICE_RECEIPT: 'Fatura/Recibo | Samba',
      CREDIT_NOTE: 'Nota de Crédito | Samba',
      DEBIT_NOTE: 'Nota de Débito | Samba',
      PRO_FORMA: 'Pro Forma | Samba',
      RETURN_NOTE: 'Nota de Devolução | Samba',
      SALES_RECEIPT: 'Talão de Venda | Samba'
    }
  },
  E_COMMERCE: {
    PRODUCTS: 'Produtos | Samba',
    ORDERS: 'Pedidos | Samba'
  },
  DASHBOARD: {
    SUMMARY: 'Resumo | Samba'
  },
  SETTINGS: {
    E_COMMERCE: {
      MY_STORE: 'Minha Loja | Samba'
    }
  }
};

export const COMPANY_TYPES = [
  {
    id: 'REMPE',
    name: 'REMPE'
  },
  {
    id: 'CO',
    name: 'Contabilidade Organizada'
  }
];

export const PRODUCT_AVAILABILITY_OPTIONS = {
  YES: 'Sim',
  NO: 'Não'
};

export const LOADING_MESSAGE = 'Carregando...';

export const PAGES_TEXT = {
  ECOMMERCE: {
    PRODUCTS: {
      TITLE: 'Produtos',
      DESCRIPTION: 'Meus Produtos'
    },
    ORDERS: {
      TITLE: 'Pedidos',
      DESCRIPTION: 'Meus Pedidos'
    }
  },
  DASHBOARD: {
    SUMMARY: {
      TITLE: 'Dashboard',
      DESCRIPTION: 'Visão Geral'
    },
    STATISTICS: {
      DAILY: {
        TITLE: 'Resumo Diário',
        DESCRIPTION: 'Informações essenciais do dia em destaque.'
      },
      MONTHLY: {
        TITLE: 'Resumo Mensal',
        DESCRIPTION: 'Informações essenciais do mês em destaque.'
      },
      LAST_THREE_MONTHS: {
        TITLE: 'Resumo dos Últimos 3 Meses',
        DESCRIPTION: 'Informações essenciais dos últimos 3 mês em destaque.'
      }
    },
    CARD: {
      SALES: 'Total de Vendas',
      RECEIVED: 'Total de Recebidos'
    }
  },
  SETTINGS: {
    E_COMMERCE: {
      MY_STORE: {
        TITLE: 'E-Commerce',
        DESCRIPTION: 'Minha Loja'
      }
    }
  }
};

export const COMPONENTS_TEXT = {
  UPLOAD_FILE: {
    PLACEHOLDER: 'Arraste e solte uma imagem aqui, ou clique para selecionar',
    LOGO: {
      TITLE: 'Upload do Logo',
      SUBTITLE: 'Dimensões do logo: Altura - 53px, Largura - 120px'
    },
    COVER: {
      TITLE: 'Upload do Cover',
      SUBTITLE: 'Dimensões do cover: Altura - 200px, Largura - Adapta-se ao tamanho do ecrã (largura mínima: 1080px)'
    }
  }
};

export const UTILS_TEXT = {
  GET_ORDER_STATUS: {
    PENDING: 'Pendente',
    PROCESSING: 'Processando',
    DELIVERED: 'Entregue',
    CANCELLED: 'Cancelado',
    RETURNED: 'Devolvido'
  },
  GET_STORE_REQUEST_STATUS: {
    PROCESSING: 'Processando',
    APPROVED: 'Aprovado',
    DENIED: 'Recusado',
    PENDING: 'Pendente'
  }
};

export const ORDER_STATUS = {
  PENDING: 'pending',
  PROCESSING: 'processing',
  DELIVERED: 'delivered',
  CANCELLED: 'cancelled',
  RETURNED: 'returned'
};

export const STORE_REQUEST_STATUS = {
  PROCESSING: 'processing',
  APPROVED: 'approved',
  DENIED: 'denied',
  PENDING: 'pending'
};

export const ORDER_STATUS_LIST = Object.keys(ORDER_STATUS).map((status) => ({
  id: ORDER_STATUS[status],
  name: UTILS_TEXT.GET_ORDER_STATUS[status]
}));

export const SLICE_TEXTS = {
  COMMON: {
    SUCCESS_MESSAGE: 'Guardado com sucesso!'
  },
  E_COMMERCE: {
    SUCCESS_MESSAGE: 'Solicitação submetida com sucesso!'
  }
};

export const DATA_FORMAT = 'yyyy-MM-dd HH:mm:ssX';

export const TIME_ZONE = 'UTC';
