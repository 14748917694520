import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Chip
} from '@mui/material';

import { AddCircleOutline as AddCircleOutlineIcon } from '@mui/icons-material';

import { useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Scrollbar from '../Scrollbar';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

import { formatCurrency } from '../../util/formatCurrency';

const CreditNoteItemListTable = (props) => {
  const {
    creditNoteItems,
    onChangeItemStatus,
    disableOrEnableAllItems,
    selectCreditNoteItem,
    onOpenForm,
    resetAllItems,
    currentCompany,
    ...other
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElItem, setAnchorElItem] = useState(null);
  const [isAllItemsDisabled, setIsAllItemsDisabled] = useState(false);
  const [isAllItemsEnabled, setIsAllItemsEnabled] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isAnItemEdited, setIsAnItemEdited] = useState(false);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickItem = (event, item) => {
    setAnchorElItem(event.currentTarget);
    setSelectedItem(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemMenuClose = () => {
    setAnchorElItem(null);
  };

  const handleEdit = () => {
    selectCreditNoteItem(selectedItem);
    handleItemMenuClose();
    onOpenForm();
  };

  const handleRemove = () => {
    onChangeItemStatus(selectedItem, true);
    handleItemMenuClose();
  };

  const handleReset = () => {
    onChangeItemStatus(selectedItem, false);
    handleItemMenuClose();
  };

  const handleEnableAllItems = () => {
    disableOrEnableAllItems(false);
    handleClose();
  };

  const handleDisableAllItems = () => {
    disableOrEnableAllItems(true);
    handleClose();
  };

  const handleResetAllItems = () => {
    resetAllItems();
    handleClose();
  };

  useEffect(() => {
    const findItemEnabled = creditNoteItems?.find((creditNoteItem) => !creditNoteItem.deleted);
    const findItemDisabled = creditNoteItems?.find((creditNoteItem) => creditNoteItem.deleted);
    const findItemEdited = creditNoteItems?.find((creditNoteItem) => creditNoteItem?.edited);
    setIsAllItemsEnabled(!findItemDisabled);
    setIsAllItemsDisabled(!findItemEnabled);
    setIsAnItemEdited(Boolean(findItemEdited));
  }, [creditNoteItems]);

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  {
                    creditNoteItems?.length >= 2
                      ? (
                        <IconButton
                          size="small"
                          id="document-table-drop-down"
                        >
                          <ArrowDropDownIcon
                            onClick={handleClick}
                          />
                        </IconButton>
                      ) : ''
                  }
                </TableCell>
                <TableCell sx={{ paddingLeft: '0px' }}>
                  Item
                </TableCell>
                <TableCell>
                  Unidade
                </TableCell>
                <TableCell>
                  Quantidade
                </TableCell>
                <TableCell>
                  Preço
                </TableCell>
                <TableCell>
                  {taxOrRetentionLabels.taxTypeLabel}
                </TableCell>
                <TableCell>
                  Desconto
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {creditNoteItems && creditNoteItems.map((creditNoteItem, index) => (
                <TableRow
                  hover
                  key={creditNoteItem.id}
                  variant={creditNoteItem.deleted ? 'disabled' : ''}
                >
                  <TableCell
                    sx={{ paddingY: '0', paddingRight: '0' }}
                  >
                    {!creditNoteItem.deleted
                      ? (
                        <IconButton
                          id={`${index}-button`}
                          size="small"
                          sx={{ padding: '0px' }}
                        >
                          <ArrowDropDownIcon
                            onClick={(e) => handleClickItem(e, creditNoteItem)}
                          />
                        </IconButton>
                      ) : (
                        <IconButton
                          id={`${index}-button-add`}
                          sx={{ padding: '0px' }}
                          onClick={() => onChangeItemStatus(creditNoteItem, true)}
                          size="small"
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      )}
                    {
                      !creditNoteItem?.edited
                        ? '' : (
                          <Chip
                            variant={creditNoteItem.deleted ? 'disabled' : ''}
                            size="small"
                            style={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', fontSize: '10px', marginLeft: '5px' }}
                            label="Editado"
                          />
                        )
                      }
                  </TableCell>
                  <TableCell
                    variant="disabled"
                    sx={{ paddingLeft: '0px' }}
                  >
                    {creditNoteItem.itemCode}
                  </TableCell>
                  <TableCell variant="disabled">
                    {creditNoteItem.unit}
                  </TableCell>
                  <TableCell variant="disabled">
                    {creditNoteItem.quantity}
                  </TableCell>
                  <TableCell variant="disabled">
                    {formatCurrency(creditNoteItem.price)}
                    $
                  </TableCell>
                  <TableCell variant="disabled">
                    {creditNoteItem.taxName}
                    {' - '}
                    {creditNoteItem.taxValue}
                    %
                  </TableCell>
                  <TableCell variant="disabled">
                    {creditNoteItem.discount || 0}
                    %
                  </TableCell>
                  <TableCell variant="disabled">
                    {formatCurrency(creditNoteItem.total)}
                    $
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        id="document-table-drop-down-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {
          isAllItemsEnabled
            ? '' : (
              <MenuItem
                id="document-table-drop-down-menu-add"
                onClick={handleEnableAllItems}
              >
                Adicionar todos
              </MenuItem>
            )
        }
        {
          isAllItemsDisabled
            ? '' : (
              <MenuItem
                id="document-table-drop-down-menu-remove"
                onClick={handleDisableAllItems}
              >
                Remover todos
              </MenuItem>
            )
        }
        {
          isAnItemEdited
            ? (
              <MenuItem
                id="document-table-drop-down-menu-reset"
                onClick={handleResetAllItems}
              >
                Redifinir todos os itens editados
              </MenuItem>
            ) : ''
        }
      </Menu>
      <Menu
        id="button-menu"
        anchorEl={anchorElItem}
        keepMounted
        open={Boolean(anchorElItem)}
        onClose={handleItemMenuClose}
      >
        <MenuItem
          id="button-menu-editar"
          onClick={handleEdit}
        >
          Editar
        </MenuItem>
        <MenuItem
          id="button-menu-remove"
          onClick={handleRemove}
        >
          Remover
        </MenuItem>
        {
          !selectedItem?.edited
            ? '' : (
              <MenuItem
                id="button-menu-reset"
                onClick={handleReset}
              >
                Redefinir
              </MenuItem>
            )
        }
      </Menu>
    </Card>
  );
};

CreditNoteItemListTable.propTypes = {
  onChangeItemStatus: PropTypes.func.isRequired,
  creditNoteItems: PropTypes.arrayOf({
    discount: PropTypes.number,
    item: PropTypes.string,
    tax: PropTypes.string,
    taxValue: PropTypes.number,
    taxName: PropTypes.number,
    price: PropTypes.string,
    quantity: PropTypes.number,
    unit: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  disableOrEnableAllItems: PropTypes.func.isRequired,
  onOpenForm: PropTypes.func.isRequired,
  selectCreditNoteItem: PropTypes.func.isRequired,
  resetAllItems: PropTypes.func.isRequired
};

export default CreditNoteItemListTable;
