import PropTypes from 'prop-types';
import { addYears, addDays } from 'date-fns';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { CREATE_NEW, FORM, MAX_DOC_NUMBER, MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR } from '../../constants';
import EditableTable from './EditableTable';

import { COMMON_MESSAGE } from '../../util/yupValidationMessages';
import CustomDatePicker from '../CustomDatePicker';
import dayjs from 'dayjs';

const filter = createFilterOptions();

const initialValues = {
  fiscalYear: undefined,
  code: undefined,
  name: undefined,
  startDate: new Date(),
  endDate: addDays(addYears(new Date(), 1), -1),
  invoiceLastDocumentNumber: null,
  invoiceRange: null,
  receiptLastDocumentNumber: null,
  receiptRange: null,
  invoiceReceiptLastDocumentNumber: null,
  invoiceReceiptRange: null,
  debitNoteLastDocumentNumber: null,
  debitNoteRange: null,
  creditNoteLastDocumentNumber: null,
  creditNoteRange: null,
  proFormaLastDocumentNumber: null,
  proFormaRange: null,
  returnNoteLastDocumentNumber: null,
  returnNoteRange: null,
  salesReceiptLastDocumentNumber: null,
  salesReceiptRange: null
};

const SeriesForm = (props) => {
  const { onCancel, onSubmitForm, serie, fiscalYears, useForm } = props;
  const [fiscalYearInitialLength, setFiscalYearInitialLength] = useState(null);
  const [fiscalYearInputValue, setFiscalYearInputValue] = useState(null);

  const formikRef = useRef();

  const validationSchema = Yup
    .object()
    .shape({
      fiscalYear: Yup
        .string()
        .required(COMMON_MESSAGE.REQUIRED),
      code: Yup
        .string()
        .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
        .matches(/^[0-9a-zA-Z_-]+$/, 'Deve conter apenas letras, números, underscore (_) e hífen (-)')
        .required(COMMON_MESSAGE.REQUIRED),
      name: Yup
        .string()
        .max(255)
        .matches(/^[0-9a-zA-Z_-]+$/, 'Deve conter apenas letras, números, underscore (_) e hífen (-)')
        .required(COMMON_MESSAGE.REQUIRED),
      startDate: Yup.date().required(COMMON_MESSAGE.REQUIRED),
      endDate: Yup
        .date()
        .when('startDate',
          (start, schema) => (start && schema.min(start,
            'A Data Final deve ser posterior à Data de Início')))
        .required(COMMON_MESSAGE.REQUIRED),
      invoiceLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      invoiceRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      receiptLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      receiptRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .required(COMMON_MESSAGE.REQUIRED),
      invoiceReceiptLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      invoiceReceiptRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      debitNoteLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      debitNoteRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      creditNoteLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      creditNoteRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      proFormaLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      proFormaRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      returnNoteLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      returnNoteRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      salesReceiptLastDocumentNumber: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED),
      salesReceiptRange: Yup
        .number()
        .max(MAX_DOC_NUMBER, `O número de documento não pode ser maior que ${MAX_DOC_NUMBER_WITH_THOUSAND_SEPARATOR}`)
        .typeError(COMMON_MESSAGE.NUMBER)
        .nullable()
        .required(COMMON_MESSAGE.REQUIRED)
    });

  useEffect(() => {
    if (!fiscalYearInitialLength && fiscalYears) {
      setFiscalYearInitialLength(fiscalYears.length);
    } else if (fiscalYears?.length > fiscalYearInitialLength) {
      setFiscalYearInputValue(fiscalYears[(fiscalYears.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.fiscalYear = fiscalYears[(fiscalYears.length) - 1].id;
      }
    }
  }, [fiscalYears]);

  const seriesValues = {
    fiscalYear: serie?.fiscalYear?.id,
    code: serie?.code,
    name: serie?.name,
    lastDocumentNumber: serie?.lastDocumentNumber,
    endDate: new Date(serie?.endDateRaw),
    startDate: new Date(serie?.startDateRaw),
    range: serie?.range,
    invoiceLastDocumentNumber: serie?.serialNumberConfig?.invoiceLastDocumentNumber,
    invoiceRange: serie?.serialNumberConfig?.invoiceRange,
    receiptLastDocumentNumber: serie?.serialNumberConfig?.receiptLastDocumentNumber,
    receiptRange: serie?.serialNumberConfig?.receiptRange,
    invoiceReceiptLastDocumentNumber: serie?.serialNumberConfig?.invoiceReceiptLastDocumentNumber,
    invoiceReceiptRange: serie?.serialNumberConfig?.invoiceReceiptRange,
    debitNoteLastDocumentNumber: serie?.serialNumberConfig?.debitNoteLastDocumentNumber,
    debitNoteRange: serie?.serialNumberConfig?.debitNoteRange,
    creditNoteLastDocumentNumber: serie?.serialNumberConfig?.creditNoteLastDocumentNumber,
    creditNoteRange: serie?.serialNumberConfig?.creditNoteRange,
    proFormaLastDocumentNumber: serie?.serialNumberConfig?.proFormaLastDocumentNumber,
    proFormaRange: serie?.serialNumberConfig.proFormaRange,
    returnNoteLastDocumentNumber: serie?.serialNumberConfig.returnNoteLastDocumentNumber,
    returnNoteRange: serie?.serialNumberConfig.returnNoteRange,
    salesReceiptLastDocumentNumber: serie?.serialNumberConfig.salesReceiptLastDocumentNumber,
    salesReceiptRange: serie?.serialNumberConfig.salesReceiptRange
  };

  const {
    openForm
  } = useForm();

  const handleDateChange = (newValue, setFieldValue, setFieldKey) => {
    if (dayjs(newValue).isValid()) {
      setFieldValue(setFieldKey, newValue.toDate());
    }
  };

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  return (
    <Formik
      innerRef={formikRef}
      initialValues={serie ? seriesValues : initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitForm(values);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {serie ? 'Editar Série' : 'Nova Série'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 2 }}>
              <Autocomplete
                id="serie-form-fiscal-year-autocomplete"
                getOptionLabel={(option) => option.name}
                getOptionSelected={(option, value) => option.id === value.id}
                options={fiscalYears || []}
                defaultValue={serie ? ({
                  id: serie.fiscalYear?.id,
                  name: fiscalYears.find((fiscalYear) => fiscalYear.id === serie.fiscalYear.id)?.name
                }) : null}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    name: CREATE_NEW
                  });

                  return filtered;
                }}
                disableClearable
                inputValue={fiscalYearInputValue || ''}
                onChange={(event, value) => {
                  setFiscalYearInputValue(value.name);
                  setFieldValue('fiscalYear', value.id || '');

                  if (value.name === CREATE_NEW) {
                    openForm(FORM.FISCAL_YEAR);
                    value.name = fiscalYearInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && fiscalYearInputValue !== '') || fiscalYearInputValue.length === 1) {
                    setFiscalYearInputValue(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    autoFocus
                    fullWidth
                    error={Boolean(touched.fiscalYear && errors.fiscalYear)}
                    helperText={touched.fiscalYear && errors.fiscalYear}
                    label="Ano Fiscal"
                    name="fiscalYear"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 2fr'
            }}
            >
              <TextField
                id="serie-form-code-input"
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                label="Codigo*"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="outlined"
              />
              <Box sx={{ ml: 1 }}>
                <TextField
                  id="serie-form-name-input"
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Nome*"
                  name="name"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.name}
                  variant="outlined"
                />
              </Box>

            </Box>
            <Box sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              mt: 2
            }}
            >
              <Box sx={{
                mr: 2
              }}
              >
                <CustomDatePicker
                  id="serie-start-Date-form-date-picker"
                  label="Data Inícial"
                  onChange={(newValue) => {
                    setStartDate(newValue);
                    handleDateChange(newValue, setFieldValue, 'startDate')}
                  }
                  values={startDate}
                />
              </Box>
              <Box>
                <CustomDatePicker
                  id="serie-end-Date-form-date-picker"
                  label="Data Final"
                  onChange={(newValue) => {
                    setEndDate(newValue);
                    handleDateChange(newValue, setFieldValue, 'endDate')}
                  }
                  values={endDate}
                />
              </Box>
              {Boolean(touched.endDate && errors.endDate) && (
                <Box sx={{ mt: 2 }}>
                  <FormHelperText error>
                    {errors.endDate}
                  </FormHelperText>
                </Box>
              )}
            </Box>
          </Box>
          <EditableTable
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            errors={errors}
            values={values}
          />
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="serie-form-save-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Guardar
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

SeriesForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  useForm: PropTypes.func.isRequired,
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lastDocumentNumber: PropTypes.string,
    range: PropTypes.string,
    fiscalYear: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired,
  fiscalYears: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  serie: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    startDateRaw: PropTypes.string,
    endDateRaw: PropTypes.string,
    lastDocumentNumber: PropTypes.string,
    range: PropTypes.string,
    serialNumberConfig: PropTypes.shape({
      invoiceLastDocumentNumber: PropTypes.string,
      invoiceRange: PropTypes.string,
      receiptLastDocumentNumber: PropTypes.string,
      receiptRange: PropTypes.string,
      invoiceReceiptLastDocumentNumber: PropTypes.string,
      invoiceReceiptRange: PropTypes.string,
      debitNoteLastDocumentNumber: PropTypes.string,
      debitNoteRange: PropTypes.string,
      creditNoteLastDocumentNumber: PropTypes.string,
      creditNoteRange: PropTypes.string,
      proFormaLastDocumentNumber: PropTypes.string,
      proFormaRange: PropTypes.string,
      returnNoteLastDocumentNumber: PropTypes.string,
      returnNoteRange: PropTypes.string,
      salesReceiptLastDocumentNumber: PropTypes.string,
      salesReceiptRange: PropTypes.string
    }).isRequired,
    fiscalYear: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired
};

export default SeriesForm;
