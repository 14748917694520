/**
 * Calculates the total balance from an array of balances.
 * @param {Array} balances - An array of balance objects.
 * @returns {number} The total balance.
 */
const calculateTotalBalance = (balances) => (
  // Converts the value to an integer
  balances.reduce((accumulator, balance) => accumulator + parseInt(balance.value, 10), 0)
);

export default calculateTotalBalance;
