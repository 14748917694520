import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';

import { format, parseISO } from 'date-fns';
import useSettings from '../../../hooks/useSettings';
import ReceiptTable from '../../../components/receipt/Table';
import ReceiptForm from '../../../components/receipt/Form';
import { useDispatch, useSelector } from '../../../store';
import { fetch as fetchReceipt, create as createReceipt, sendReceiptEmail, print as printReceipt } from '../../../slices/receipts';
import { fetch as fetchClients } from '../../../slices/clients';
import { fetch as fetchInvoices } from '../../../slices/invoices';
import { fetch as fetchSeries } from '../../../slices/series';
import { fetch as fetchFiscalYears } from '../../../slices/fiscalYear';
import { fetch as fetchPaymentModeDefaults } from '../../../slices/paymentModeDefaults';
import { retrySendDocument as retrySendReceipt } from '../../../slices/eFatura';

import EmptyList from '../../../components/empty_list';
import { CAPE_VERDE_TIME_ZONE, TOAST_TYPE, DOCUMENTS, EFATURA_BANNER_MESSAGE } from '../../../constants';
import LoadingPopUp from '../../../components/LoadingPopUp';
import Toast from '../../../components/Toast';
import ReceiptViewDetails from '../../../components/receipt/ViewDetails';
import Scrollbar from '../../../components/Scrollbar';
import WarningAlertOnPage from '../../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';

const Receipt = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [selectedReceipt, setSelectedReceipt] = useState(null);

  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const { settings } = useSettings();

  const dispatch = useDispatch();

  const { receipts, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.receipts);
  const { clients } = useSelector((state) => state.clients);
  const { series } = useSelector((state) => state.series);
  const { invoices } = useSelector((state) => state.invoices);

  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state.companies);
  const { paymentModeDefaults } = useSelector((state) => state.paymentModeDefaults);
  const { retrySendDocumentSuccess: retrySendReceiptSuccess } = useSelector((state) => state.eFatura);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchReceipt()),
        dispatch(fetchClients()),
        dispatch(fetchInvoices()),
        dispatch(fetchSeries()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchPaymentModeDefaults())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setIsAddMode(true);
  };

  const handleModalClose = () => {
    setIsAddMode(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  const handleReceiptDetailOpen = (receipt) => {
    setSelectedReceipt(receipt);
  };

  const handleReceiptDetailClose = () => {
    setSelectedReceipt(null);
  };

  const formatDate = (date) => format(parseISO(date), 'dd/MM/yyyy');
  const formatDateToUTC = (date) => format(date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);

  const handleCreateOrUpdateItem = useCallback((receipt) => {
    handlePopUpOpen();
    dispatch(createReceipt({ ...receipt, date: formatDateToUTC(receipt.date) }));
  }, []);

  const getLastReceiptNumber = useCallback((serieId) => {
    const getReceipts = receipts ? receipts.filter((receipt) => receipt.serie.id === serieId) : 0;

    if (!getReceipts && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);
      if (getSerie?.serialNumberConfig) {
        return Number(getSerie.serialNumberConfig.receiptLastDocumentNumber);
      }
      return Number(getSerie?.lastDocumentNumber);
    }

    return Number(getReceipts[getReceipts.length - 1]?.number) || 0;
  }, [receipts, series]);

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const handleSendEmail = (email) => {
    dispatch(sendReceiptEmail({ id: selectedReceipt.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const handlePrintReceipt = () => {
    dispatch(printReceipt(selectedReceipt));
  };

  const handleRetrySendReceipt = (receiptId) => {
    const receiptInfo = { id: receiptId, documentType: DOCUMENTS.receipt.name };
    dispatch(retrySendReceipt(receiptInfo));
  };

  const returnResult = () => {
    if (receipts && receipts.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <ReceiptTable
            receipts={receipts}
            formatDate={formatDate}
            onOpenReceiptDetails={handleReceiptDetailOpen}
            handleRetrySendDocument={handleRetrySendReceipt}
            retrySendReceiptSuccess={retrySendReceiptSuccess}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Recibo"
        buttonText="Criar Recibo"
        handleClick={handleNewClick}
      />
    );
  };

  const toastUpdate = () => {
    if (!onRequest && !onRequestFailure) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>Recibos | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {!isAddMode ? 'Recibo' : 'Novo Recibo'}
              </Typography>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Recibos
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                id="receipt-page-create-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }
          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <ReceiptForm
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreateOrUpdateItem}
              formatDate={formatDateToUTC}
              clients={clients}
              series={series}
              invoices={invoices}
              getLastReceiptNumber={getLastReceiptNumber}
              paymentModeDefaults={paymentModeDefaults}
              isOnRequest={onRequest}
            />
            )}
          </Box>
        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleReceiptDetailClose}
          open={selectedReceipt}
        >
          <Scrollbar>
            {selectedReceipt && (
            <ReceiptViewDetails
              receipt={{ ...selectedReceipt, dateFormatted: format(parseISO(selectedReceipt.paymentDate), 'dd/MM/yyyy') }}
              onCancel={handleReceiptDetailClose}
              handleSendEmail={handleSendEmail}
              handlePrintButtonClick={handlePrintReceipt}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default Receipt;
