import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Checkbox,
  Button,
  FormControlLabel,
  Box,
  Typography
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const getAlertPaymentLocalStorage = (company) => {
  const paymentAlertLocalStorage = JSON.parse(window.localStorage.getItem('alertPayment'));

  if (!paymentAlertLocalStorage) {
    const newPaymentAlertLocalStorage = { companies: {} };
    newPaymentAlertLocalStorage.companies[company] = false;
    window.localStorage.setItem('alertPayment', JSON.stringify(newPaymentAlertLocalStorage));
    return false;
  }
  if (paymentAlertLocalStorage?.companies?.[company] === undefined) {
    paymentAlertLocalStorage.companies[company] = false;
    window.localStorage.setItem('alertPayment', JSON.stringify(paymentAlertLocalStorage));
    return false;
  }

  return paymentAlertLocalStorage?.companies?.[company];
};

const setCompanyAlertPaymentLocalStorage = (company) => {
  const paymentAlertLocalStorage = JSON.parse(window.localStorage.getItem('alertPayment'));
  paymentAlertLocalStorage.companies[company] = true;
  window.localStorage.setItem('alertPayment', JSON.stringify(paymentAlertLocalStorage));
};

const AlertPayment = (props) => {
  const { isButtonNewClicked, message, handleConfirm, companyId } = props;

  const isPopUpDisabled = getAlertPaymentLocalStorage(companyId);

  const [isPopUpDisabledCheck, setIsPopUpDisabledCheck] = useState(false);
  const [isCompanyAlertDisabled, setIsCompanyAlertDisabled] = useState(isPopUpDisabled);

  useEffect(() => {
    if (isButtonNewClicked && isPopUpDisabled) {
      handleConfirm();
    }
  }, [isButtonNewClicked]);

  const handleClosePopUp = () => {
    if (isPopUpDisabledCheck) {
      setCompanyAlertPaymentLocalStorage(companyId);
      setIsCompanyAlertDisabled(true);
    }
    handleConfirm();
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isButtonNewClicked && !isCompanyAlertDisabled}
    >
      <Box
        id="payment-alert"
        sx={{ p: 2 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
          <InfoIcon
            color="primary"
            sx={{
              fontSize: 80,
              mt: 1
            }}
          />
        </Box>
        <DialogContent>
          <Typography sx={{ textAlign: 'justify', mb: 2 }}>
            {message}
          </Typography>
          <FormControlLabel
            id="payment-alert-checkbox"
            sx={{ alignSelf: 'flex-start', mb: 2 }}
            label="Não mostrar novamente."
            control={(
              <Checkbox
                color="primary"
                onChange={(event) => setIsPopUpDisabledCheck(event.target.checked)}
              />
            )}
          />
          <Button
            id="payment-alert-ok-button"
            sx={{ width: '100%' }}
            size="large"
            variant="contained"
            onClick={handleClosePopUp}
          >
            OK
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

AlertPayment.propTypes = {
  isButtonNewClicked: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default AlertPayment;
