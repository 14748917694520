import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import {
  Box,
  Typography
} from '@mui/material';
import animationData from '../../lotties/loading.json';

export default function LoadingPopUp(props) {
  const { message } = props;
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  };

  const renderMessage = () => {
    if (message) {
      return (
        <Typography
          color="textSecondary"
          variant="h5"
          align="center"
          sx={{
            mb: 4
          }}
        >
          {message}
        </Typography>
      );
    }

    return null;
  };

  return (
    <Box
      id="loading-popup"
    >
      <Lottie
        options={defaultOptions}
        height={350}
        width={350}
      />
      {renderMessage()}
    </Box>
  );
}

LoadingPopUp.defaultProps = {
  message: null
};

LoadingPopUp.propTypes = {
  message: PropTypes.string
};
