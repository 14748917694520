import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Tab,
  Tabs,
  useMediaQuery
} from '@mui/material';

import { useState } from 'react';
import FiscalForm from './FiscalForm';
import GeneralForm from './GeneralForm';
import BankAccountForm from './BankAccountForm';
import TabPanel from '../TabPanel';

import { COMPANY_TYPES } from '../../constants';

const initialValues = {
  name: undefined,
  nif: undefined,
  country: undefined,
  currency: undefined,
  email: undefined,
  fiscalName: undefined,
  accountantTaxId: undefined,
  countyTaxId: undefined,
  county: undefined,
  bankName: null,
  abbreviation: null,
  accountNumber: null,
  nib: null,
  swift: null,
  isBankAccountRequired: true,
  address: null,
  phoneNumber: null,
  companyType: null
};

const schemaValidation = Yup
  .object()
  .shape({
    name: Yup
      .string()
      .max(255)
      .trim()
      .required('Obrigatório'),
    nif: Yup
      .string()
      .matches(/^[0-9]+$/, 'Pode conter apenas 9 dígitos ')
      .length(9, 'Pode conter apenas 9 dígitos ')
      .required('Obrigatório'),
    country: Yup
      .string()
      .required('Obrigatório'),
    currency: Yup
      .string()
      .required('Obrigatório'),
    email: Yup
      .string()
      .email('Email precisa ser válido')
      .max(255)
      .required('Email é obrigatório'),
    fiscalName: Yup
      .string(),
    accountantTaxId: Yup
      .string()
      .matches(/^[0-9]+$/, 'Pode conter apenas 9 dígitos ')
      .length(9, 'Pode conter apenas 9 dígitos '),
    countyTaxId: Yup
      .string()
      .matches(/^[0-9]+$/, 'Pode conter apenas 9 dígitos ')
      .length(9, 'Pode conter apenas 9 dígitos '),
    county: Yup
      .string()
      .required('Obrigatório'),
    address: Yup
      .string()
      .min(10, 'O endereço deve ter no mínimo 10 caracteres')
      .max(200, 'O endereço deve ter no máximo 200 caracteres')
      .required('Obrigatório')
      .nullable(),
    phoneNumber: Yup
      .string()
      .nullable()
      .matches(/^[0-9]{7}$/, 'Pode conter apenas 7 dígitos ')
      .length(7, 'Pode conter apenas 7 dígitos ')
      .required('Obrigatório')
      .nullable(),
    bankName: Yup
      .string()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.string().max(255).required('Obrigatório').nullable(),
        otherwise: Yup.string().max(255).nullable()
      }),
    abbreviation: Yup
      .string()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.string().max(255).required('Obrigatório').nullable(),
        otherwise: Yup.string().max(255).nullable()
      }),
    accountNumber: Yup
      .number()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.number().typeError('Deve conter apenas números').required('Obrigatório').nullable(),
        otherwise: Yup.number().typeError('Deve conter apenas números').nullable()
      }),
    nib: Yup
      .number()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.number().typeError('Deve conter apenas números').required('Obrigatório').nullable(),
        otherwise: Yup.number().typeError('Deve conter apenas números').nullable()
      }),
    iban: Yup
      .string()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.string().max(255).required('Obrigatório').nullable(),
        otherwise: Yup.string().max(255).nullable()
      }),
    swift: Yup
      .string()
      .when('isBankAccountRequired', {
        is: true,
        then: Yup.string().typeError('Deve conter apenas números').required('Obrigatório').nullable(),
        otherwise: Yup.string().typeError('Deve conter apenas números').nullable()
      }),
    companyType: Yup
      .string()
      .required('Obrigatório')
      .nullable()
  });

const formOptions = {
  GENERAL_FORM: 0,
  FISCAL_FORM: 1,
  BANK_ACCOUNT_FORM: 2
};

const CompanyForm = (props) => {
  const {
    countries,
    currencies,
    counties,
    onCancel,
    onSubmitForm
  } = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const [tabValue, setTabValue] = useState(0);

  const handleNextView = (values, setTouched, errors) => {
    if (!values.name || !values.nif || !values.country || !values.currency || !values.email || !values.address || !values.phoneNumber || errors.phoneNumber) {
      setTouched({
        name: true,
        nif: true,
        country: true,
        currency: true,
        email: true,
        fiscalName: true,
        accountantTaxId: true,
        countyTaxId: true,
        county: true,
        address: true,
        phoneNumber: true,
        companyType: true
      });
      return;
    }

    if (tabValue === formOptions.FISCAL_FORM) {
      if (errors.accountantTaxId || errors.countyTaxId) {
        return;
      }
    }

    if (tabValue >= formOptions.BANK_ACCOUNT_FORM) return;

    setTabValue((prev) => prev + 1);
  };

  const handleSkip = (values) => {
    if (tabValue === formOptions.FISCAL_FORM) {
      if (values.accountantTaxId || values.countyTaxId || values.fiscalName) {
        values.fiscalName = undefined;
        values.accountantTaxId = undefined;
        values.countyTaxId = undefined;
      }
      setTabValue((prev) => prev + 1);
    }

    if (tabValue === formOptions.BANK_ACCOUNT_FORM) {
      if (values.bankName || values.abbreviation || values.accountNumber || values.nib || values.swift) {
        values.bankName = null;
        values.abbreviation = null;
        values.accountNumber = null;
        values.nib = null;
        values.swift = null;
      }
      values.isBankAccountRequired = false;
    }
  };

  const handlePreviousView = () => {
    if (tabValue === formOptions.GENERAL_FORM) return;
    setTabValue((prev) => prev - 1);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schemaValidation}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.countyId = counties.find((county) => county.code === values.county).id;

          onSubmitForm(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setTouched
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Tabs
              value={tabValue}
              onChange={() => null}
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
              }}
              centered
            >
              <Tab
                disabled={tabValue > 0}
                label="GERAL"
              />
              <Tab
                disabled={tabValue < 1 || tabValue >= 2}
                label="FISCAL"
              />
              <Tab
                disabled={tabValue < 2}
                label="BANCÁRIA"
              />
            </Tabs>

            <TabPanel
              value={tabValue}
              index={0}
            >
              <GeneralForm
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                setFieldValue={setFieldValue}
                currencies={currencies}
                countries={countries}
                counties={counties}
                companyTypes={COMPANY_TYPES}
              />
            </TabPanel>

            <TabPanel
              value={tabValue}
              index={1}
            >
              <FiscalForm
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                counties={counties}
              />
            </TabPanel>

            <TabPanel
              value={tabValue}
              index={2}
            >
              <BankAccountForm
                values={values}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                counties={counties}
              />
            </TabPanel>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                mt: 2
              }}
            >
              {tabValue === formOptions.GENERAL_FORM && (
                <Box sx={{ display: 'flex', gap: 1, flexDirection: isMobile ? 'column' : 'row', width: '100%', justifyContent: 'flex-end' }}>
                  <Button
                    color="primary"
                    onClick={onCancel}
                    variant="outlined"
                    sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => handleNextView(values, setTouched, errors)}
                    sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                  >
                    Seguinte
                  </Button>
                </Box>
              )}

              {tabValue === formOptions.FISCAL_FORM && (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 1
                }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Button
                      color="primary"
                      onClick={() => handleSkip(values)}
                      variant="outlined"
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                    >
                      Pular
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', gap: 1 }}>
                    <Button
                      color="primary"
                      onClick={handlePreviousView}
                      variant="outlined"
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                    >
                      Voltar
                    </Button>
                    <Button
                      color="primary"
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                      variant="contained"
                      onClick={() => handleNextView(values, setTouched, errors)}
                    >
                      Seguinte
                    </Button>
                  </Box>
                </Box>
              )}

              {tabValue === formOptions.BANK_ACCOUNT_FORM && (
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: 1
                }}
                >
                  <Box sx={{ width: '100%' }}>
                    <Button
                      id="company-form-skip-or-create-button"
                      color="primary"
                      disabled={isSubmitting}
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                      type="submit"
                      variant="outlined"
                      onClick={() => handleSkip(values)}
                    >
                      Pular
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', width: '100%', gap: 1 }}>
                    <Button
                      color="primary"
                      onClick={handlePreviousView}
                      disabled={isSubmitting}
                      variant="outlined"
                      type="submit"
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                    >
                      Voltar
                    </Button>
                    <Button
                      color="primary"
                      disabled={isSubmitting}
                      sx={{ width: isMobile ? '100%' : '150px', borderRadius: '8px' }}
                      type="submit"
                      variant="contained"
                    >
                      Criar
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  );
};

CompanyForm.propTypes = {
  countries: PropTypes.arrayOf({
    id: PropTypes.string,
    // pt stands for name of the company in portuguese
    pt: PropTypes.string
  }).isRequired,
  counties: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    country_id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  currencies: PropTypes.arrayOf({
    id: PropTypes.string
  }).isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CompanyForm;
