import PropTypes from 'prop-types';
import { Card as CardMaterialUI } from '@mui/material';

const Card = (props) => {
  const { children } = props;

  return (
    <CardMaterialUI sx={{ p: 2, mt: 2 }}>
      {children}
    </CardMaterialUI>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired
};

export default Card;
