import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Section } from './Section';

export const BusinessManagement = (props) => {
  const { handleScheduleDemo, ...rest } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'relative'
      }}
      component="section"
    >
      <Section
        {...rest}
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          maxWidth: 700,
          height: 'auto'
        }}
        >
          <Box
            alt="business-management"
            component="img"
            src="/static/homepage/business-management.svg"
            sx={{ maxWidth: mobileDevice ? '80%' : '60%', height: 'auto' }}
          />
          <Typography
            color={theme.typography.homePageTextSecondary}
            sx={{
              textAlign: 'center',
              mt: 3,
              mb: 3
            }}
          >
            Samba é um sistema de gestão empresarial novo e fácil de usar.
            O nosso objetivo é facilitar a vida das empresas, capacitando-as com
            as ferramentas que necessitam para gerir e recolher informações das
            operações diárias do seu negócio.
          </Typography>
        </Box>
        <a
          href="https://calendly.com/chuva-nilson/samba-demo"
          target="_blank"
          rel="noreferrer"
          onClick={handleScheduleDemo}
        >
          <Button
            alt="Marcar Demo Grátis"
            color="primary"
            type="button"
            variant="contained"
            size="small"
            sx={{
              px: 4,
              borderRadius: 4
            }}
          >
            <Typography
              variant="textSecondary"
              color="#FAFCFF"
              sx={{
                fontFamily: 'Gill Sans',
                fontWeight: 600,
                fontSize: 16,
                pt: 0.5
              }}
            >
              Marcar Demo Grátis
            </Typography>
          </Button>
        </a>
      </Section>
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-12.svg"
        sx={{
          position: 'absolute',
          left: -160,
          top: -150,
          opacity: 1
        }}
      />
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-13.svg"
        sx={{
          position: 'absolute',
          top: mobileDevice ? 100 : 0,
          right: 0,
          bottom: 100,
          opacity: 1
        }}
      />
    </Box>
  );
};
BusinessManagement.propTypes = {
  handleScheduleDemo: PropTypes.func
};
