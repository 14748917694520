import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../Scrollbar';
import EditableTable from './EditableTable';

const SeriesViewDetails = (props) => {
  const { serie, onEdit, onDelete, onCancel, ...other } = props;

  return (
    <Card {...other}>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Série
        </Typography>
      </Box>
      <Scrollbar>
        <Box sx={{ p: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Ano Fiscal
                </TableCell>
                <TableCell sx={{ fontWeight: 400 }}>
                  {serie.fiscalYearName}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Código
                </TableCell>
                <TableCell>
                  {serie.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Nome
                </TableCell>
                <TableCell>
                  {serie.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data Início
                </TableCell>
                <TableCell>
                  {serie.startDate}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data Fim
                </TableCell>
                <TableCell>
                  {serie.endDate}
                </TableCell>
              </TableRow>
              {!serie.serialNumberConfig && (
              <>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Número do Último Documento
                  </TableCell>
                  <TableCell>
                    {serie.lastDocumentNumber}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 700 }}>
                    Limite de Número de Série
                  </TableCell>
                  <TableCell>
                    {serie.range}
                  </TableCell>
                </TableRow>
              </>
              )}
            </TableBody>
          </Table>
        </Box>
        {serie.serialNumberConfig && (
        <EditableTable
          serialNumberConfig={serie.serialNumberConfig}
        />
        )}
      </Scrollbar>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="outlined"
        >
          Voltar
        </Button>
        <Button
          color="primary"
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={onDelete}
        >
          Apagar
        </Button>
        {!serie?.invoices?.length && (
        <Button
          color="primary"
          sx={{ ml: 1 }}
          type="submit"
          variant="contained"
          onClick={onEdit}
        >
          Editar
        </Button>
        )}
      </Box>
    </Card>
  );
};

SeriesViewDetails.propTypes = {
  serie: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    range: PropTypes.number,
    lastDocumentNumber: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    fiscalYearName: PropTypes.string,
    serialNumberConfig: PropTypes.shape({
      invoiceLastDocumentNumber: PropTypes.string,
      invoiceRange: PropTypes.string,
      receiptLastDocumentNumber: PropTypes.string,
      receiptRange: PropTypes.string,
      invoiceReceiptLastDocumentNumber: PropTypes.string,
      invoiceReceiptRange: PropTypes.string,
      debitNoteLastDocumentNumber: PropTypes.string,
      debitNoteRange: PropTypes.string,
      creditNoteLastDocumentNumber: PropTypes.string,
      creditNoteRange: PropTypes.string,
      proFormaLastDocumentNumber: PropTypes.string,
      proFormaRange: PropTypes.string,
      returnNoteLastDocumentNumber: PropTypes.string,
      returnNoteRange: PropTypes.string,
      salesReceiptLastDocumentNumber: PropTypes.string,
      salesReceiptRange: PropTypes.string
    }).isRequired,
    invoices: PropTypes.array.isRequired
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default SeriesViewDetails;
