import PropTypes from 'prop-types';
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  InputAdornment
} from '@mui/material';
import { CAPE_VERDE_COUNTRY_CODE } from '../../constants';

const GeneralForm = (props) => {
  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    setFieldValue,
    counties,
    countries,
    currencies,
    companyTypes
  } = props;

  return (
    <>
      <Box
        id="general-form"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
      >
        {/* Company Information */}
        <Box>
          <Box sx={{ mb: 2 }}>
            <Typography color="primary">Informações da Empresa</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              id="general-form-name-input"
              autoFocus
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Nome*"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.name}
              variant="outlined"
            />
            <Autocomplete
              id="general-form-company-type-autocomplete"
              getOptionLabel={(option) => option.name}
              options={companyTypes}
              disableClearable
              defaultValue={values.companyType ? ({
                name: companyTypes.find((companyType) => companyType.id === values.companyType)?.name
              }) : null}
              noOptionsText="Tipo não encontrado"
              onChange={(e, value) => setFieldValue('companyType', value?.id || '')}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  error={Boolean(touched.companyType && errors.companyType)}
                  helperText={touched.companyType && errors.companyType}
                  label="Tipo de Empresa*"
                  name="companyType"
                  variant="outlined"
                  {...params}
                />
              )}
            />
          </Box>
        </Box>

        {/* Fiscal Information */}
        <Box>
          <Box sx={{ mb: 2 }}>
            <Typography color="primary">Informações Fiscais</Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Autocomplete
              id="general-form-currency-autocomplete"
              getOptionLabel={(option) => option.id}
              options={currencies}
              disableClearable
              noOptionsText="Moeda não encontrada"
              defaultValue={values.currency ? ({
                id: values.currency
              }) : null}
              onChange={(e, value) => setFieldValue('currency', value?.id || '')}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  error={Boolean(touched.currency && errors.currency)}
                  helperText={touched.currency && errors.currency}
                  label="Moeda*"
                  name="currency"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <TextField
              id="general-form-nif-input"
              error={Boolean(touched.nif && errors.nif)}
              fullWidth
              helperText={touched.nif && errors.nif}
              label="NIF da Empresa*"
              name="nif"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.nif}
              variant="outlined"
            />
          </Box>
        </Box>

        {/* Contact Information */}
        <Box>
          <Typography color="primary">Informações de Contato</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box>
              <TextField
                id="general-form-email-input"
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email*"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            </Box>
            <TextField
              id="general-form-phone-number-input"
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              fullWidth
              helperText={touched.phoneNumber && errors.phoneNumber}
              label="Telefone*"
              name="phoneNumber"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.phoneNumber}
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">{CAPE_VERDE_COUNTRY_CODE}</InputAdornment>
              }}
            />
            <Autocomplete
              id="general-form-country-autocomplete"
              noOptionsText="País não encontrado"
              getOptionLabel={(option) => option.pt}
              options={countries.filter((country) => country.id === 'CVP')}
              defaultValue={values.country ? ({
                id: values.country,
                pt: countries.find((country) => country.id === values.country)?.pt
              }) : null}
              disableClearable
              onChange={(e, value) => setFieldValue('country', value?.id || '')}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  error={Boolean(touched.country && errors.country)}
                  helperText={touched.country && errors.country}
                  label="País de Origem*"
                  name="country"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <Autocomplete
              id="general-form-county-autocomplete"
              getOptionLabel={(option) => `${option.code} - ${option.name}`}
              options={counties?.filter((county) => county.country_id === values.country)}
              disableClearable
              defaultValue={values.county ? ({
                code: values.county,
                name: counties.find((county) => county.code === values.county)?.name
              }) : null}
              noOptionsText="Por favor selecione um País de origem"
              onChange={(e, value) => setFieldValue('county', value?.code || '')}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  error={Boolean(touched.county && errors.county)}
                  helperText={touched.county && errors.county}
                  label="Concelho*"
                  name="county"
                  variant="outlined"
                  {...params}
                />
              )}
            />
            <TextField
              id="general-form-address-input"
              error={Boolean(touched.address && errors.address)}
              fullWidth
              helperText={touched.address && errors.address}
              label="Endereço*"
              name="address"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.address}
              variant="outlined"
            />

          </Box>
        </Box>
      </Box>
    </>
  );
};

GeneralForm.propTypes = {
  countries: PropTypes.object.isRequired,
  currencies: PropTypes.object.isRequired,
  counties: PropTypes.object.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    companyType: PropTypes.string
  }).isRequired,
  touched: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    companyType: PropTypes.string
  }).isRequired,
  errors: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    address: PropTypes.string,
    phoneNumber: PropTypes.string,
    companyType: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  companyTypes: PropTypes.object.isRequired
};

export default GeneralForm;
