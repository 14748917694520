import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  taxes: undefined,
  isLoading: false,
  loadingError: false
};

const slice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.taxes = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.taxes = undefined;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'tax/fetch',
  async (_, { dispatch, getState }) => {
    dispatch(_get());
    try {
      const { countryId, companyType } = getState().companies.currentCompany;
      const response = await axios.get(`/countries/${countryId}/taxes?regime_type=${companyType}`);
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
