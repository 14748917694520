import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { useState } from 'react';
import NumericTextField from '../NumericTextField';

import { documentReferenceLabel } from '../../util/documentReferenceLabel';
import { DOCUMENT_TYPE_NAME } from '../../constants';

const initialValues = {
  invoiceId: null,
  valueInDebt: null,
  paymentValue: null,
  pendent: null
};

const ReceiptItemForm = (props) => {
  const {
    onComplete,
    onCancel,
    invoices,
    onAdd,
    invoice,
    onEdit,
    toggleAddAnother,
    isAddAnother
  } = props;

  const [invoiceInput, setInvoiceInput] = useState(null);

  const invoiceValues = {
    invoiceId: invoice?.info.id,
    valueInDebt: invoice?.valueInDebt,
    paymentValue: invoice?.paymentValue
  };

  return (
    <Formik
      enableReinitialize
      initialValues={invoice ? invoiceValues : initialValues}
      validationSchema={Yup
        .object()
        .shape({
          invoiceId: Yup
            .string()
            .typeError('Obrigatório')
            .max(255)
            .trim()
            .required('Obrigatório'),
          paymentValue: Yup
            .number()
            .max(Yup.ref('valueInDebt'), 'Valor a ser pago deve ser menor ou igual ao valor em dívida')
            .typeError('O Valor a ser pago deve ser um número')
            .required('Obrigatório')
        })}
      onSubmit={async (values, { resetForm, setValues, setErrors, setStatus, setSubmitting }) => {
        try {
          values.pendent = values?.valueInDebt - values?.paymentValue;

          if (invoice) {
            onEdit(values);
          } else {
            onAdd(values);
          }

          setStatus({ success: true });
          setSubmitting(false);

          if (isAddAnother) {
            resetForm();
            setValues(() => ({
              invoiceId: '',
              valueInDebt: 0,
              paymentValue: 0,
              pendent: 0
            }));
            setInvoiceInput(null);
          } else {
            onComplete();
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue,
        setValues
      }) => (
        <form
          id="receipt-item-form-popup"
          onSubmit={handleSubmit}
        >
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              Fatura
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box sx={{
              mt: 2
            }}
            >
              <Autocomplete
                id="receipt-item-form-popup-invoice"
                getOptionLabel={(option) => documentReferenceLabel(DOCUMENT_TYPE_NAME.invoice, option.number, option.date, option.totalGeneral)}
                options={invoices || []}
                defaultValue={invoice ? ({
                  id: invoice.info.id,
                  clientName: invoice.info.clientName
                }) : null}
                noOptionsText="Este cliente não dispõe de faturas a serem pagas"
                disableClearable
                onChange={(e, value) => {
                  setFieldValue('invoiceId', value?.id || '');
                  setInvoiceInput(value?.clientName || '');
                  const invoiceValue = value.totalGeneral - value.receiptsValue;

                  setValues((state) => ({
                    ...state,
                    invoiceId: value.id,
                    valueInDebt: invoiceValue.toFixed(2),
                    paymentValue: invoiceValue.toFixed(2)
                  }));
                }}
                onInputChange={(event, value) => {
                  setInvoiceInput(value);
                }}
                inputValue={invoiceInput || ''}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.invoiceId && errors.invoiceId)}
                    helperText={touched.invoiceId && errors.invoiceId}
                    label="Fatura*"
                    name="invoiceId"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{
              mt: 2
            }}
            >
              <TextField
                error={Boolean(touched.valueInDebt && errors.valueInDebt)}
                fullWidth
                disabled
                helperText={touched.valueInDebt && errors.valueInDebt}
                label="Valor em Dívida*"
                name="valueInDebt"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.valueInDebt || ''}
                variant="outlined"
              />
            </Box>
            <Box sx={{
              mt: 2
            }}
            >
              <NumericTextField
                id="receipt-item-form-popup-payment-value"
                error={Boolean(touched.paymentValue && errors.paymentValue)}
                fullWidth
                helperText={touched.paymentValue && errors.paymentValue}
                label="Valor a ser pago*"
                name="paymentValue"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.paymentValue || ''}
                variant="outlined"
              />
            </Box>
            <Box sx={{
              mt: 2
            }}
            >
              <TextField
                id="receipt-item-form-popup-pendent-value"
                error={Boolean(touched.pendent && errors.pendent)}
                fullWidth
                disabled
                helperText={touched.pendent && errors.pendent}
                label="Pendente*"
                name="pendent"
                onBlur={handleBlur}
                onChange={handleChange}
                value={(values?.valueInDebt - values?.paymentValue).toFixed(2) || ''}
                variant="outlined"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex'
              }}
            >
              <Checkbox
                color="primary"
                checked={isAddAnother}
                onClick={toggleAddAnother}
              />
              <Typography
                color="textSecondary"
                variant="body2"
              >
                criar outro
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="receipt-item-form-popup-save-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Guardar
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

ReceiptItemForm.propTypes = {
  onComplete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  openForm: PropTypes.func.isRequired,
  toggleAddAnother: PropTypes.func.isRequired,
  isAddAnother: PropTypes.bool.isRequired,
  selectedInvoice: PropTypes.shape({
    id: PropTypes.string,
    totalGeneral: PropTypes.number
  }).isRequired,
  invoices: PropTypes.arrayOf({
    id: PropTypes.string,
    clientName: PropTypes.string,
    totalGeneral: PropTypes.number
  }).isRequired,
  invoice: PropTypes.shape({
    info: PropTypes.shape({
      id: PropTypes.string,
      clientName: PropTypes.string
    }),
    valueInDebt: PropTypes.number,
    paymentValue: PropTypes.number
  }).isRequired
};

export default ReceiptItemForm;
