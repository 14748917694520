import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import {
  Grid,
  Button,
  TextField,
  Typography
} from '@mui/material';
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const RegisterJWT = (props) => {
  const isMountedRef = useIsMountedRef();
  const { register } = useAuth();
  const { onBackButtonClick, handleLoginButtonClick, handlePopUpOpen, showErrorMessage } = props;

  return (
    <Formik
      initialValues={{
        email: '',
        firstName: '',
        lastName: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          email: Yup
            .string()
            .email('Digite um email válido')
            .max(255)
            .required('Obrigatório'),
          firstName: Yup
            .string()
            .max(255)
            .required('Obrigatório'),
          lastName: Yup
            .string()
            .max(255)
            .required('Obrigatório'),
          password: Yup
            .string()
            .min(7)
            .max(255)
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          handlePopUpOpen();
          await register(values.email, values.firstName, values.lastName, values.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
            showErrorMessage(false);
          }
        } catch (err) {
          showErrorMessage(true);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          {...props}
        >
          <TextField
            autoFocus
            error={Boolean(touched.firstName && errors.firstName)}
            fullWidth
            helperText={touched.firstName && errors.firstName}
            label="Nome*"
            placeholder="Introduzir Nome"
            margin="normal"
            name="firstName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.firstName}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.lastName && errors.lastName)}
            fullWidth
            helperText={touched.lastName && errors.lastName}
            label="Apelido*"
            placeholder="Introduzir Apelido"
            margin="normal"
            name="lastName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.lastName}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            label="Email*"
            placeholder="Introduzir Email"
            margin="normal"
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={values.email}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Senha*"
            placeholder="Criar Senha"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <Grid
            container
            sx={{ mt: 20 }}
          >
            <Grid
              item
              xs
            >
              <Button
                color="primary"
                disabled={isSubmitting}
                variant="outlined"
                onClick={onBackButtonClick}
                sx={{
                  px: 6,
                  py: 1,
                  borderRadius: 4
                }}
              >
                <Typography>
                  Cancelar
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  type="submit"
                  variant="contained"
                  sx={{
                    px: 6,
                    py: 1,
                    borderRadius: 4
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    Criar Conta
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleLoginButtonClick}
                  sx={{
                    mt: 1
                  }}
                >
                  Já tenho conta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

RegisterJWT.propTypes = {
  handlePopUpOpen: PropTypes.func.isRequired,
  onBackButtonClick: PropTypes.func.isRequired,
  showErrorMessage: PropTypes.func.isRequired,
  handleLoginButtonClick: PropTypes.func.isRequired
};

export default RegisterJWT;
