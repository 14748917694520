import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  clients: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'clients',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.clients = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.clients = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.clients === undefined) {
        state.clients = [];
      }
      state.clients.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.clients = state.clients.filter((client) => client.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'clients/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/customers`);
      const clients = data.map((client) => ({
        id: client.id,
        name: client.name,
        fiscalName: client.fiscal_name,
        nif: client.tax_id,
        countryId: client.country.id,
        countryName: client.country.pt,
        internal: client.internal,
        isIndividual: client.is_individual,
        address: client.address,
        invoices: client.invoices?.map((invoice) => ({
          id: invoice.id,
          isVoided: invoice.is_voided,
          documentType: {
            id: invoice.document_type.id,
            code: invoice.document_type.code,
            name: invoice.document_type.name
          },
          paymentMode: {
            id: invoice.payment_mode.id,
            code: invoice.payment_mode.code,
            name: invoice.payment_mode.name
          },
          invoiceItems: invoice.items.map((invoiceItem) => ({
            id: invoiceItem.id,
            item: invoiceItem.item,
            quantity: invoiceItem.quantity,
            unit: invoiceItem.unit_code,
            price: invoiceItem.price,
            tax: invoiceItem.tax,
            discount: invoiceItem.discount,
            itemTotal: invoiceItem.item_total
          })),
          paymentPeriod: {
            id: invoice.payment_period.id,
            code: invoice.payment_period.code,
            name: invoice.payment_period.name,
            numberOfDay: invoice.payment_period.number_of_day
          },
          clientName: invoice.customer.name,
          commentary: invoice.commentary,
          number: invoice.invoice_number,
          totalGeneral: invoice.total_general,
          date: invoice.date,
          serieCode: invoice.serial_number.code
        })),
        phoneNumber: client.phone_numbers && client.phone_numbers[client.phone_numbers.length - 1],
        receipts: client.receipt?.map((receipt) => ({
          id: receipt.receipts.id,
          client: receipt.receipts.name,
          paymentDate: receipt.receipts.payment_date,
          number: receipt.receipts.receipt_number,
          serie: receipt.serial_number,
          isVoided: receipt.is_voided,
          totalGeneral: receipt.total_general
        }))
      }));

      dispatch(_getSuccess(clients));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'clients/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const customer = {
        name: data.name,
        internal: data.type === 'internal',
        fiscal_name: data.fiscalName,
        tax_id: String(data.nif),
        country_id: data.country,
        is_individual: data.isIndividual === 'singular',
        address: data.address,
        phone_numbers: [
          {
            phone_number: data.phoneNumber,
            country_code: data.country_code
          }
        ]
      };

      const response = await axios.post(`/companies/${companyId}/customers`, customer);
      const client = {
        id: response.data.id,
        name: response.data.name,
        fiscalName: response.data.fiscal_name,
        nif: response.data.tax_id,
        countryId: response.data.country.id,
        countryName: response.data.country.pt,
        address: response.data.address,
        phoneNumber: {
          phone_number: data.phoneNumber,
          country_code: data.country_code
        }
      };

      analytics.dispatchEvent(events.clientCreated());

      dispatch(_createOrUpdatingSuccess(client));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const update = createAsyncThunk(
  'clients/update',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const updatedcustomer = {
        name: data.name,
        internal: data.type === 'internal',
        fiscal_name: data.fiscalName,
        tax_id: String(data.nif),
        country_id: data.country,
        is_individual: data.isIndividual === 'singular',
        address: data.address,
        phone_numbers: [
          {
            id: data.phoneNumberId,
            phone_number: data.phoneNumber,
            country_code: data.country_code
          }
        ]
      };

      await axios.put(`/companies/${companyId}/customers/${data.clientId}`, updatedcustomer);

      dispatch(_createOrUpdatingSuccess());
      // We are making another request to updated the clientList with the updated value
      const response = await axios.get(`/companies/${companyId}/customers`);
      const clients = response.data.map((client) => ({
        id: client.id,
        name: client.name,
        fiscalName: client.fiscal_name,
        nif: client.tax_id,
        countryId: client.country.id,
        countryName: client.country.pt,
        internal: client.internal,
        isIndividual: client.is_individual,
        address: client.address,
        phoneNumber: client.phone_numbers && client.phone_numbers[client.phone_numbers.length - 1]
      }));

      dispatch(_getSuccess(clients));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const remove = createAsyncThunk(
  'clients/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/customers/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
