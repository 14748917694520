import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format, parseISO } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

import { formatRFC2822 } from '../util/formatRFC2822';

let companyId;

const initialState = {
  fiscalYears: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'fiscalYear',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.fiscalYears = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.fiscalYears = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createSuccess: (state, { payload }) => {
      if (state.fiscalYears === undefined) {
        state.fiscalYears = [];
      }
      state.fiscalYears.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _updateSuccess: (state, { payload }) => {
      const indexToUpdate = state.fiscalYears.findIndex(fiscalYear => fiscalYear.id === payload.fiscalYearId);

      if (indexToUpdate !== -1) {
        const startDate = formatRFC2822(payload.startDate, "dd/MM/yyyy");
        const endDate = formatRFC2822(payload.endDate, "dd/MM/yyyy");

        state.fiscalYears[indexToUpdate].name = payload.name;
        state.fiscalYears[indexToUpdate].startDate = startDate;
        state.fiscalYears[indexToUpdate].endDate = endDate;
      }
      
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.fiscalYears = state.fiscalYears.filter((fiscalYear) => fiscalYear.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createSuccess,
  _updateSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'fiscalYears/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/fiscal_years`);

      const fiscalYears = data.map((fiscalYear) => ({
        id: fiscalYear.id,
        name: fiscalYear.name,
        startDate: format(parseISO(fiscalYear.date_range[0].value), 'dd/MM/yyyy'),
        startDateRaw: fiscalYear.date_range[0].value,
        endDate: format(parseISO(fiscalYear.date_range[1].value), 'dd/MM/yyyy'),
        endDateRaw: fiscalYear.date_range[1].value
      }));

      dispatch(_getSuccess(fiscalYears));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'fiscalYears/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const startDateFormated = format(data.startDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);
      const endDateFormated = format(data.endDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);

      const fiscalYear = {
        name: data.name.trim(),
        date_range: `${startDateFormated},${endDateFormated}`
      };

      const response = await axios.post(`/companies/${companyId}/fiscal_years`, fiscalYear);

      const newFiscalYear = {
        id: response.data.id,
        name: response.data.name,
        startDate: format(parseISO(response.data.date_range[0].value), 'dd/MM/yyyy'),
        startDateRaw: response.data.date_range[0].value,
        endDate: format(parseISO(response.data.date_range[1].value), 'dd/MM/yyyy'),
        endDateRaw: response.data.date_range[1].value
      };

      analytics.dispatchEvent(events.fiscalYearCreated());

      dispatch(_createSuccess(newFiscalYear));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const update = createAsyncThunk(
  'fiscalYears/update',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const startDateFormated = format(data.startDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);
      const endDateFormated = format(data.endDate, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`);

      const updatedFiscalYear = {
        name: data.name.trim(),
        date_range: `${startDateFormated},${endDateFormated}`
      };

      await axios.put(`/companies/${companyId}/fiscal_years/${data.fiscalYearId}`, updatedFiscalYear);

      dispatch(_updateSuccess(data));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const remove = createAsyncThunk(
  'clients/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/fiscal_years/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
