import { Box, TextField, useTheme, Chip } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Formik } from 'formik';

import { fetch as fetchStore, create as createStore, clearMessages } from '../../../../slices/onlineStores';
import { useSelector, useDispatch } from '../../../../store';

import {
  EFATURA_BANNER_MESSAGE,
  COMPONENTS_TEXT,
  HELMET_TITLES,
  PAGES_TEXT,
  TOAST_TYPE
} from '../../../../constants';

import {
  eCommerceNameValidationSchema,
  instagramURLValidationSchema,
  facebookURLValidationSchema,
  getCombinedValidationSchema,
  subdomainValidationSchema
} from '../../../../yupValidation';

import WarningAlertOnPage from '../../../../components/AlertOnPage';
import BorderButton from '../../../../components/BorderButton';
import UploadFile from '../../../../components/UploadFile';
import H1Section from '../../../../components/Header/H1Section';
import Wrapper from '../../../../components/Wrapper';
import Toast from '../../../../components/Toast';

import { isCompanyNotIntegratedWithEFatura } from '../../../../util/eFaturasStatus';
import { getStoreRequestStatus } from '../../../../util/getStoreRequestStatus';
import { colors as requestStatusColors } from '../../../../colors';
import LoadingPopUp from '../../../../components/LoadingPopUp/index';

const MyStore = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  let formikRef;

  const { onlineStore, isLoadingCreate, isLoading, errorMessage, successMessage } = useSelector((state) => state.onlineStores);
  const { isLoading: isLoadingCompany, currentCompany } = useSelector((state) => state.companies);

  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [toastType, setToastType] = useState(false);
  const [coverFile, setCoverFile] = useState(null);
  const [logoFile, setLogoFile] = useState(null);
  const [message, setMessage] = useState(false);

  const initialValues = {
    eCommerceName: null,
    subdomain: null,
    facebookURL: null,
    instagramURL: null
  };

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchStore());
    }
  }, [currentCompany]);

  useEffect(() => {
    if (successMessage) {
      dispatch(fetchStore());
    }
  }, [successMessage]);

  const combinedValidationSchema = getCombinedValidationSchema([
    eCommerceNameValidationSchema(),
    subdomainValidationSchema(),
    facebookURLValidationSchema(),
    instagramURLValidationSchema()
  ]);

  const dropzoneStyles = {
    border: `0.125rem dashed ${theme.palette.grey[400]}`,
    marginBottom: '1.25rem',
    borderRadius: '0.25rem',
    textAlign: 'center',
    padding: '1.25rem',
    cursor: 'pointer'
  };

  const colors = {
    textColor: theme.palette.text.secondary,
    borderColor: requestStatusColors.blue.main,
    deleteIconColor: theme.palette.primary.contrastText,
    deleteIconBackgroundColor: theme.palette.error.main
  };

  const renderBanner = () => isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
    <WarningAlertOnPage message={EFATURA_BANNER_MESSAGE} />
  );

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const toastUpdate = () => {
    if (isLoadingCreate) {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
    if (successMessage) {
      setIsPopUpOpen(true);
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
    }
    if (errorMessage) {
      setIsPopUpOpen(true);
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [isLoadingCreate, errorMessage, successMessage]);

  const handleClosePopUp = () => {
    setIsPopUpOpen(false);
    dispatch(clearMessages());
  };

  const handleExternalSubmit = async () => {
    if (formikRef) {
      formikRef.handleSubmit();
    }
  };

  const handleSubmit = (values) => {
    handlePopUpOpen();
    const data = {
      ...values,
      images: [
        { type: 'cover', file: coverFile },
        { type: 'logo', file: logoFile }
      ]
    };

    dispatch(createStore(data));
  };

  const handleDeleteLogoFile = () => {
    setLogoFile(null);
  };

  const handleDeleteCoverFile = () => {
    setCoverFile(null);
  };

  const renderForm = () => (
    <Formik
      innerRef={(ref) => (formikRef = ref)}
      initialValues={initialValues}
      validationSchema={combinedValidationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ handleChange, values, errors, touched, handleBlur }) => (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], gap: 2 }}>
              <TextField
                error={Boolean(touched.eCommerceName && errors.eCommerceName)}
                fullWidth
                helperText={touched.eCommerceName && errors.eCommerceName}
                label="Nome da Loja"
                name="eCommerceName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.eCommerceName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.subdomain && errors.subdomain)}
                fullWidth
                helperText={touched.subdomain && errors.subdomain}
                label="Subdomínio"
                name="subdomain"
                placeholder="loja"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.subdomain}
                variant="outlined"
              />
            </Box>
            <Box sx={{ display: 'flex', flexDirection: ['column', 'row'], gap: 2 }}>
              <TextField
                error={Boolean(touched.facebookURL && errors.facebookURL)}
                fullWidth
                helperText={touched.facebookURL && errors.facebookURL}
                label="Facebook URL"
                name="facebookURL"
                placeholder="https://facebook.com/nome"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.facebookURL}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.instagramURL && errors.instagramURL)}
                fullWidth
                helperText={touched.instagramURL && errors.instagramURL}
                label="Instagram URL"
                name="instagramURL"
                placeholder="https://instagram.com/nome"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.instagramURL}
                variant="outlined"
              />
            </Box>
          </Box>
          <Box>
            <Box>
              <UploadFile
                title={COMPONENTS_TEXT.UPLOAD_FILE.LOGO.TITLE}
                subtitle={COMPONENTS_TEXT.UPLOAD_FILE.LOGO.SUBTITLE}
                acceptedFormats={['image/jpeg', 'image/png']}
                placeholder={COMPONENTS_TEXT.UPLOAD_FILE.PLACEHOLDER}
                colors={colors}
                dropzoneStyles={dropzoneStyles}
                currentFile={logoFile}
                handleDelete={handleDeleteLogoFile}
                setFile={setLogoFile}
              />
            </Box>
            <Box>
              <UploadFile
                title={COMPONENTS_TEXT.UPLOAD_FILE.COVER.TITLE}
                subtitle={COMPONENTS_TEXT.UPLOAD_FILE.COVER.SUBTITLE}
                acceptedFormats={['image/jpeg', 'image/png']}
                placeholder={COMPONENTS_TEXT.UPLOAD_FILE.PLACEHOLDER}
                colors={colors}
                dropzoneStyles={dropzoneStyles}
                currentFile={coverFile}
                handleDelete={handleDeleteCoverFile}
                setFile={setCoverFile}
              />
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'end' }}>
              <BorderButton
                id="request-store-button"
                title="Solicitar"
                variant="contained"
                type="submit"
                onClick={handleExternalSubmit}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Formik>
  );

  const renderChip = () => (
    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
      <Chip
        size="medium"
        label={getStoreRequestStatus(
          onlineStore.status, requestStatusColors.status
        )?.label}
        style={{
          padding: '0.875rem',
          color: `${
            getStoreRequestStatus(
              onlineStore.status, requestStatusColors.status
            )?.color
          }`,
          backgroundColor: `${
            getStoreRequestStatus(
              onlineStore.status, requestStatusColors.status
            )?.backgroundColor
          }`
        }}
      />
    </Box>
  );

  const renderContent = () => {
    if (isLoading || isLoadingCompany) {
      return <LoadingPopUp />;
    }

    if (onlineStore && onlineStore.status) {
      return renderChip();
    }

    return renderForm();
  };

  return (
    <>
      <Helmet>
        <title>{HELMET_TITLES.SETTINGS.E_COMMERCE.MY_STORE}</title>
      </Helmet>
      {renderBanner()}
      <Wrapper>
        <H1Section
          title={PAGES_TEXT.SETTINGS.E_COMMERCE.MY_STORE.TITLE}
          description={PAGES_TEXT.SETTINGS.E_COMMERCE.MY_STORE.DESCRIPTION}
        />
        {renderContent()}
      </Wrapper>
      <Toast
        isOpen={isPopUpOpen}
        toastType={toastType}
        message={message}
        onClose={handleClosePopUp}
      />
    </>
  );
};

export default MyStore;
