import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  documents: null,
  isLoading: false,
  error: false
};

const slice = createSlice({
  name: 'sambaPayments',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.documents = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.documents = null;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'sambaPayments/fetch',
  async (_, { dispatch, getState }) => {
    dispatch(_get());
    try {
      const { id } = getState().companies.currentCompany;
      const { data } = await axios.get(`/companies/${id}/receipts?payment_mode=Samba`);

      const payments = data.map((payment) => ({
        receiptId: payment.receipt_id,
        receiptNumber: payment.receipt_number,
        totalPaid: payment.total_paid,
        feeValue: payment.fee_amount,
        totalReceivable: payment.total_receivable,
        paymentDate: payment.payment_date,
        paymentService: payment.payment_service_id,
        debtStatus: payment.debt_status
      }));

      dispatch(_getSuccess(payments));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
