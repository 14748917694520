import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const UnitListTable = (props) => {
  const { units, onOpenUnitDetail, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Código
                </TableCell>
                <TableCell>
                  Descrição
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {units.map((unit) => (
                <TableRow
                  hover
                  key={unit.code}
                  onClick={() => onOpenUnitDetail(unit)}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {unit.code}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {unit.description}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

UnitListTable.propTypes = {
  units: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  onOpenUnitDetail: PropTypes.func.isRequired
};

export default UnitListTable;
