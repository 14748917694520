import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import Lottie from 'react-lottie';
import useMediaQuery from '@mui/material/useMediaQuery';
import animationData from '../../lotties/search-file.json';

const EmptyList = (props) => {
  const { id, title, buttonText, handleClick } = props;

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  };

  return (
    <Box
      id={id}
      sx={{
        mt: 5,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column'
      }}
    >
      <Lottie
        options={defaultOptions}
        height={isMobile ? '100%' : 500}
        width={isMobile ? '100%' : 500}
      />
      <Typography
        color="#9E9E9E"
        variant="h5"
        align="center"
      >
        {title}
      </Typography>
      { buttonText && (
        <Button
          sx={{ mt: 2 }}
          color="primary"
          variant="contained"
          onClick={handleClick}
          style={{
            width: isMobile ? '100%' : 350
          }}
        >
          {buttonText}
        </Button>
      )}
    </Box>
  );
};

EmptyList.defaultProps = {
  id: null,
  buttonText: null,
  handleClick: null
};

EmptyList.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  handleClick: PropTypes.func
};

export default EmptyList;
