import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Dialog } from '@mui/material';
import { addDays, format, parseISO, isAfter, isBefore } from 'date-fns';
import { clone } from 'lodash';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

import {
  create as createSalesReceipt,
  fetch as fetchSalesReceipt,
  print as printSalesReceipt,
  sendSalesReceiptEmail,
  voidSalesReceipt
} from '../../../slices/salesReceipts';
import { retrySendDocument as retrySendSalesReceipt } from '../../../slices/eFatura';
import { fetch as fetchPaymentPeriod } from '../../../slices/paymentPeriod';
import { fetch as fetchDocumentTypes } from '../../../slices/documentTypes';
import { fetch as fetchPaymentMode } from '../../../slices/paymentMode';
import { fetch as fetchFiscalYears } from '../../../slices/fiscalYear';
import { fetch as fetchClients } from '../../../slices/clients';
import { fetch as fetchSeries } from '../../../slices/series';
import { fetch as fetchItems } from '../../../slices/itens';
import { fetch as fetchTaxes } from '../../../slices/taxes';
import { fetch as fetchUnit } from '../../../slices/units';
import { useDispatch, useSelector } from '../../../store';

import {
  EFATURA_BANNER_MESSAGE,
  POPUP_CONTENT_MESSAGE,
  HELMET_TITLES,
  TOAST_TYPE,
  DOCUMENTS
} from '../../../constants';
import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';

import ViewDetails from '../../../components/SalesReceipt/ViewDetails';
import WarningAlertOnPage from '../../../components/AlertOnPage';
import LoadingPopUp from '../../../components/LoadingPopUp';
import Table from '../../../components/SalesReceipt/Table';
import DeletePopUp from '../../../components/DeletePopUp';
import Form from '../../../components/SalesReceipt/Form';
import H1Section from '../../../components/Header/H1Section';
import EmptyList from '../../../components/empty_list';
import Scrollbar from '../../../components/Scrollbar';
import Wrapper from '../../../components/Wrapper';
import Toast from '../../../components/Toast';

const SalesReceipt = () => {
  const [salesReceiptStorage, setSalesReceiptStorage] = useLocalStorage('salesReceiptStorage', null);
  const [selectedSalesReceipt, setSelectedSalesReceipt] = useState(null);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(undefined);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [toastType, setToastType] = useState(undefined);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [message, setMessage] = useState(undefined);
  const [isAddMode, setIsAddMode] = useState(false);
  const [draftKey, setDraftKey] = useState(null);

  const dispatch = useDispatch();
  const { salesReceipts, onRequest, onRequestFailure, successMessage, errorMessage, isLoading } = useSelector((state) => state).salesReceipts;
  const { retrySendDocumentSuccess: retrySendSalesReceiptSuccess } = useSelector((state) => state).eFatura;
  const { paymentPeriods } = useSelector((state) => state).paymentPeriod;
  const { documentTypes } = useSelector((state) => state).documentTypes;
  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state).companies;
  const { paymentModes } = useSelector((state) => state).paymentMode;
  const { clients } = useSelector((state) => state).clients;
  const { series } = useSelector((state) => state).series;
  const { units } = useSelector((state) => state).units;
  const { taxes } = useSelector((state) => state).taxes;
  const { itens } = useSelector((state) => state).itens;

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchDocumentTypes()),
        dispatch(fetchPaymentPeriod()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchPaymentMode()),
        dispatch(fetchClients()),
        dispatch(fetchSeries()),
        dispatch(fetchItems()),
        dispatch(fetchTaxes()),
        dispatch(fetchUnit()),
        dispatch(fetchSalesReceipt()),
        dispatch(fetchDocumentTypes())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setSelectedDraft(null);
    setDraftKey(Math.random());
    setIsAddMode(true);
  };

  const handleEditDraft = (draft, keyDraft) => {
    setIsAddMode(true);
    dispatch(fetchSeries());
    setSelectedDraft(draft);
    setDraftKey(keyDraft);
  };

  const handleDeleteDraft = (draftId) => {
    const draftsSalesReceiptStorage = clone(salesReceiptStorage);
    const indexCompany = draftsSalesReceiptStorage.salesReceipts.findIndex((company) => currentCompany.id in company);
    const draftIndex = draftsSalesReceiptStorage.salesReceipts[indexCompany][currentCompany.id].findIndex((draft) => draftId in draft);

    if (draftsSalesReceiptStorage.salesReceipts[indexCompany][currentCompany.id].length <= 1) {
      draftsSalesReceiptStorage.salesReceipts.splice(indexCompany, 1);
    } else {
      draftsSalesReceiptStorage.salesReceipts[indexCompany][currentCompany.id].splice(draftIndex, 1);
    }
    setSalesReceiptStorage(draftsSalesReceiptStorage);
  };

  const handleUpdateSelectedSalesReceipt = () => {
    if (selectedSalesReceipt) {
      const salesReceiptUpdate = salesReceipts.find((salesReceipt) => salesReceipt.id === selectedSalesReceipt?.id);
      setSelectedSalesReceipt(salesReceiptUpdate);
    }
  };

  const handleDetailOpen = (salesReceipt) => {
    setSelectedSalesReceipt(salesReceipt);
    setIsDetailsModalOpen(true);
  };

  const handleDuplicate = (salesReceipt) => {
    setSelectedSalesReceipt(salesReceipt);
    handleNewClick();
  };

  const handleDetailClose = () => {
    setIsDetailsModalOpen(false);
    setSelectedSalesReceipt(null);
  };

  const handleModalClose = () => {
    setIsAddMode(false);
    setSelectedSalesReceipt(null);
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedSalesReceipt();
    setIsPopUpOpen(false);
  };

  const handleCreate = (salesReceipt) => {
    handlePopUpOpen();
    dispatch(createSalesReceipt({
      ...salesReceipt,
      documentType: documentTypes.find((documentType) => documentType.code === DOCUMENTS.sales_receipt.code)
    }));
  };

  const handleVoid = () => {
    handleDetailClose();
    handleDeletePopUpClose();
    handlePopUpOpen();
    dispatch(voidSalesReceipt(selectedSalesReceipt));
  };

  /**
   * Send Email
   * @param {string} email - Email to send the InvoiceReceipt email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendSalesReceiptEmail({ id: selectedSalesReceipt.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const getNextSalesReceiptNumber = (serieId) => {
    const getSalesReceipts = salesReceipts ? salesReceipts.filter((salesReceipt) => salesReceipt.serie === serieId) : 0;
    let number;
    if ((!getSalesReceipts || getSalesReceipts.length === 0) && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);
      if (getSerie?.serialNumberConfig?.salesReceiptLastDocumentNumber) {
        number = Number(getSerie.serialNumberConfig.salesReceiptLastDocumentNumber) + 1;
        return number;
      }

      number = Number(getSerie?.lastDocumentNumber) + 1;
      return number;
    }

    number = Number(getSalesReceipts[getSalesReceipts.length - 1]?.number) + 1;
    return number;
  };

  /**
   * isBefore is a function that returns true if the date is before the given date.
   * isAfter is a function that returns true if the date is after the given date.
   * @param {Date} date
   * @param {Object} serie
   */
  const isInSerieDateRange = (date, serie) => (isBefore(date, new Date(serie?.endDateRaw)) && isAfter(date, new Date(serie?.startDateRaw)));

  const handleRetrySendSalesReceipt = (salesReceiptId) => {
    const salesReceiptInfo = { id: salesReceiptId, documentType: DOCUMENTS.sales_receipt.name };
    dispatch(retrySendSalesReceipt(salesReceiptInfo));
  };

  const returnResult = () => {
    const validIndex = 0;
    const companyIndex = salesReceiptStorage?.salesReceipts.findIndex((company) => currentCompany.id in company);
    let companyDrafts = null;
    if (companyIndex >= validIndex) companyDrafts = salesReceiptStorage?.salesReceipts[companyIndex][currentCompany.id];
    const hasCompanyDrafts = companyIndex >= validIndex && companyDrafts?.length > 0;

    if ((salesReceipts && salesReceipts.length > 0) || (companyIndex >= validIndex && hasCompanyDrafts)) {
      return (
        <Box sx={{ mt: 3 }}>
          <Table
            draftsStorage={companyIndex >= validIndex ? companyDrafts : null}
            setSelectedDraft={setSelectedDraft}
            onOpenDetails={handleDetailOpen}
            deleteDraft={handleDeleteDraft}
            selectedDraft={selectedDraft}
            documents={salesReceipts}
            onView={handleDetailOpen}
            onClick={handleEditDraft}
            onVoid={(saleReceipt) => {
              setSelectedSalesReceipt(saleReceipt);
              handleDeletePopUpOpen();
            }}
            onDuplicate={handleDuplicate}
            handleRetrySendDocument={handleRetrySendSalesReceipt}
            retrySendDocumentSuccess={retrySendSalesReceiptSuccess}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title={`Não tens nenhum ${DOCUMENTS.sales_receipt.name}`}
        buttonText={`Criar ${DOCUMENTS.sales_receipt.name}`}
        handleClick={handleNewClick}
      />
    );
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  const handlePrint = () => {
    dispatch(printSalesReceipt(selectedSalesReceipt));
  };

  const renderBanner = () => isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
    <WarningAlertOnPage
      message={EFATURA_BANNER_MESSAGE}
    />
  );

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>{HELMET_TITLES.SALES.DOCUMENTS.SALES_RECEIPT}</title>
      </Helmet>
      {renderBanner()}
      <Wrapper>
        <H1Section
          title={!isAddMode ? DOCUMENTS.sales_receipt.name : `Novo ${DOCUMENTS.sales_receipt.name}`}
          description={DOCUMENTS.sales_receipt.name}
          buttonTitle={isAddMode ? null : 'Novo'}
          buttonId="sales-receipt-new-button"
          onClick={handleNewClick}
        />
        {!isAddMode && (
        <Box sx={{ mt: 3 }}>
          {isLoading
            ? (
              <LoadingPopUp />
            ) : returnResult()}
        </Box>
        )}
        <Box sx={{ mt: 3 }}>
          {isAddMode && (
          <Form
            onComplete={handleModalClose}
            onCancel={handleModalClose}
            onSubmitForm={handleCreate}
            series={series}
            clients={clients}
            paymentPeriods={paymentPeriods}
            documentTypes={documentTypes}
            paymentModes={paymentModes}
            units={units}
            taxes={taxes}
            items={itens}
            document={selectedSalesReceipt}
            isInSerieDateRange={isInSerieDateRange}
            getNextDocumentNumber={getNextSalesReceiptNumber}
            draftId={draftKey}
            documentDraft={selectedDraft}
            documentStorage={salesReceiptStorage}
            setDocumentStorage={setSalesReceiptStorage}
            handleDeleteDraft={handleDeleteDraft}
            currentCompany={currentCompany}
            isOnRequest={onRequest}
          />
          )}
        </Box>

      </Wrapper>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleDetailClose}
        open={Boolean(isDetailsModalOpen && selectedSalesReceipt)}
      >
        <Scrollbar>
          {Boolean(isDetailsModalOpen && selectedSalesReceipt) && (
          <ViewDetails
            title={DOCUMENTS.sales_receipt.name}
            document={{
              ...selectedSalesReceipt,
              dateFormatted: format(parseISO(selectedSalesReceipt.date), 'dd/MM/yyyy'),
              paymentLimit: format(addDays(parseISO(selectedSalesReceipt.date), selectedSalesReceipt.paymentPeriod.numberOfDay), 'dd/MM/yyyy')
            }}
            handlePrintButtonClick={handlePrint}
            handleSendEmail={handleSendEmail}
            onVoid={handleDeletePopUpOpen}
            onCancel={handleDetailClose}
            currentCompany={currentCompany}
          />
          )}
        </Scrollbar>
      </Dialog>
      <Toast
        onClose={handlePopUpClose}
        toastType={toastType}
        isOpen={isPopUpOpen}
        message={message}
      />
      <Dialog
        maxWidth="xs"
        onClose={handleDeletePopUpClose}
        open={isDeletePopUpOpen}
      >
        {isDeletePopUpOpen && (
        <DeletePopUp
          message={POPUP_CONTENT_MESSAGE.VOID.MESSAGE}
          action={POPUP_CONTENT_MESSAGE.VOID.ACTION}
          onCancel={handleDeletePopUpClose}
          onDelete={handleVoid}
        />
        )}
      </Dialog>
    </>
  );
};

export default SalesReceipt;
