import PropTypes from 'prop-types';
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Divider,
  Typography
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { useState } from 'react';

import Scrollbar from '../Scrollbar';
import ViewFiscalInfo from './ViewFiscalInfo';
import ViewGeneralInfo from './ViewGeneralInfo';

const viewOptions = {
  FISCAL_INFO: 'FISCAL_INFO',
  GENERAL_INFO: 'GENERAL_INFO'
};

const CompanyViewDetails = (props) => {
  const { company, onCancel, ...other } = props;

  const [currentView, setCurrentView] = useState(viewOptions.GENERAL_INFO);

  const handleNextView = () => {
    setCurrentView(viewOptions.FISCAL_INFO);
  };

  const handlePreviousView = () => {
    setCurrentView(viewOptions.GENERAL_INFO);
  };

  const renderInfo = () => {
    switch (currentView) {
      case viewOptions.FISCAL_INFO:
        return (
          <ViewFiscalInfo company={company} />
        );
      case viewOptions.GENERAL_INFO:
        return (
          <ViewGeneralInfo company={company} />
        );
      default:
        throw new Error();
    }
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box sx={{ p: 3 }}>
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            sx={{
              m: 2,
              display: 'flex',
              alignContent: 'center',
              justifyContent: 'center'
            }}
          >
            <Typography color={currentView === viewOptions.GENERAL_INFO ? 'textPrimary' : 'textSecondary'}>Informação Geral da Empresa</Typography>
            <Typography color={currentView === viewOptions.FISCAL_INFO ? 'textPrimary' : 'textSecondary'}>Informação Fiscal</Typography>
          </Breadcrumbs>
          {renderInfo()}
        </Box>
      </Scrollbar>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        {currentView === viewOptions.FISCAL_INFO && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handlePreviousView}
          >
            Voltar
          </Button>
        )}
        {currentView === viewOptions.GENERAL_INFO && (
          <Button
            color="primary"
            variant="outlined"
            onClick={handleNextView}
            sx={{
              ml: 1
            }}
          >
            Seguinte
          </Button>
        )}
      </Box>
    </Card>
  );
};

CompanyViewDetails.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    email: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export default CompanyViewDetails;
