import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import ItemListTable from '../../components/item/ListTable';
import ItemForm from '../../components/item/Form';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchItems, create as createItem, update as updateItem, remove as deleteItem } from '../../slices/itens';
import { fetch as fetchTaxes } from '../../slices/taxes';
import { fetch as fetchUnits } from '../../slices/units';

import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';
import ItemViewDetails from '../../components/item/ViewDetails';

import DeletePopUp from '../../components/DeletePopUp';
import { useForm } from '../../hooks/useForm';
import Scrollbar from '../../components/Scrollbar';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const Item = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [taxesMerged, setTaxesMerged] = useState(null);

  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { itens, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).itens;
  const { currentCompany } = useSelector((state) => state).companies;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const { units } = useSelector((state) => state).units;
  const { taxes } = useSelector((state) => state).taxes;

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchItems());
      dispatch(fetchTaxes());
      dispatch(fetchUnits());
    }
  }, [dispatch, currentCompany]);

  const handleUpdateSelectedItem = () => {
    if (selectedItem) {
      const updatedItem = itens.find((item) => item.id === selectedItem?.id);
      setSelectedItem(updatedItem);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleUpdateSelectedItem();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleSelectItem = (item) => {
    setSelectedItem(item);
  };

  const handleUnSelectItem = () => {
    setSelectedItem(null);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedItem();
    setIsPopUpOpen(false);
  };
  const handleCreateOrUpdateItem = (item) => {
    if (selectedItem) {
      item.id = selectedItem.id;
      dispatch(updateItem(item));
    } else {
      dispatch(createItem(item));
    }
    handlePopUpOpen();
  };

  const handleItemDelete = () => {
    handleUnSelectItem();
    handleDeletePopUpClose();
    dispatch(deleteItem(selectedItem));
    handlePopUpOpen();
  };

  function returnResult() {
    if (itens && itens.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <ItemListTable
            currentCompany={currentCompany}
            itens={itens}
            onSelectItem={handleSelectItem}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Item"
        buttonText="Criar Item"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  const handleFormatTaxes = () => {
    const reducer = (acc, currentValue) => {
      acc[currentValue.id] = currentValue;
      return acc;
    };

    const hashItens = itens ? itens
      .map((item) => item.tax)
      .reduce(reducer, {}) : {};

    const hashTaxes = taxes ? taxes.reduce(reducer, hashItens) : {};

    setTaxesMerged(Object.values(hashTaxes));
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleFormatTaxes();
  }, [taxes, itens]);

  return (
    <>
      <Helmet>
        <title>Itens | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Item
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Meus Itens
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
              <ItemForm
                currentCompany={currentCompany}
                onCancel={handleModalClose}
                onSubmitForm={handleCreateOrUpdateItem}
                item={selectedItem}
                taxes={taxesMerged}
                units={units}
                useForm={useForm}
              />
            )}
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleUnSelectItem}
            open={selectedItem}
          >
            <Scrollbar>
              {selectedItem && (
              <ItemViewDetails
                currentCompany={currentCompany}
                item={selectedItem}
                onEdit={handleNewClick}
                onDelete={handleDeletePopUpOpen}
                onCancel={handleUnSelectItem}
              />
              )}
            </Scrollbar>
          </Dialog>
        </Container>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
            <DeletePopUp
              onCancel={handleDeletePopUpClose}
              onDelete={handleItemDelete}
            />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default Item;
