import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import React, { useEffect } from 'react';

import { fetch as fetchProducts } from '../../../slices/products';

import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';
import WarningAlertOnPage from '../../../components/AlertOnPage';
import LoadingPopUp from '../../../components/LoadingPopUp';
import H1Section from '../../../components/Header/H1Section';
import EmptyList from '../../../components/empty_list';
import Wrapper from '../../../components/Wrapper';

import { useSelector, useDispatch } from '../../../store';

import { HELMET_TITLES, EFATURA_BANNER_MESSAGE, LOADING_MESSAGE, PAGES_TEXT } from '../../../constants';
import ProductCard from '../../../components/ProductCard';
import { formatCurrency } from '../../../util/formatCurrency';

const Products = () => {
  const dispatch = useDispatch();

  const { currentCompany } = useSelector((state) => state).companies;
  const { products: allProducts, isLoading } = useSelector((state) => state).products;

  const renderBanner = () => isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
    <WarningAlertOnPage
      message={EFATURA_BANNER_MESSAGE}
    />
  );

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchProducts());
    }
  }, [dispatch, currentCompany]);

  const renderContent = () => {
    if (isLoading) {
      return (<LoadingPopUp message={LOADING_MESSAGE} />);
    }

    if (allProducts?.length > 0) {
      return (
        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row', flexWrap: 'wrap', gap: 2 }}>
          {allProducts?.map((product) => (
            <ProductCard
              id="product-card-on-products"
              title={product.name}
              description={formatCurrency(product.sellingPrice)}
              alt={product.name}
              images={product?.images[0]?.url}
            />
          ))}
        </Box>
      );
    }

    return (
      <EmptyList
        id="empty-list-e-commerce-page"
        title="Não tens nenhum produto disponibilizado no e-commerce"
      />
    );
  };

  return (
    <>
      <Helmet>
        <title>{HELMET_TITLES.E_COMMERCE.PRODUCTS}</title>
      </Helmet>
      {renderBanner()}
      <Wrapper id="title-of-my-products-page">
        <H1Section
          title={PAGES_TEXT.ECOMMERCE.PRODUCTS.TITLE}
          description={PAGES_TEXT.ECOMMERCE.PRODUCTS.DESCRIPTION}
        />
        <Box>
          {renderContent()}
        </Box>
      </Wrapper>
    </>
  );
};

export default Products;
