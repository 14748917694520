import rawAxios from 'axios';
import axiosLess from '../lib/axiosLess';

const uploadFile = async (companyId, item, file) => {
  const presignedURLResponse = await axiosLess.get(
    `companies/${companyId}/items/${item}/images/${file.path}/presigned_url`
  );
  const { presigned_url: url } = presignedURLResponse.data;

  await rawAxios.put(url, file);

  await axiosLess.post(`/companies/${companyId}/items/${item}/images`, {
    file_name: file.path,
    image_rank: 0
  });
};

export { uploadFile };
