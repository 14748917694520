import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import AlertIcon from '../../icons/Alert';

import { POPUP_CONTENT_MESSAGE } from '../../constants';

export default function DeletePopUp(props) {
  const { onCancel, message, action, onDelete } = props;

  return (
    <Box sx={{
      m: 4
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <AlertIcon
          sx={{
            fontSize: 60,
            mt: 1
          }}
        />
        <Typography
          color="textSecondary"
          variant="h6"
          align="center"
          sx={{
            m: 2
          }}
        >
          {message}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          p: 2,
          mt: 1
        }}
      >
        <Button
          fullWidth
          color="primary"
          onClick={onCancel}
          variant="outlined"
          sx={{
            mr: 2
          }}
        >
          Voltar
        </Button>
        <Button
          id="delete-pop-up-action-button"
          fullWidth
          color="error"
          variant="contained"
          onClick={onDelete}
        >
          {action}
        </Button>
      </Box>
    </Box>
  );
}
DeletePopUp.propTypes = {
  message: PropTypes.string,
  action: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

DeletePopUp.defaultProps = {
  message: POPUP_CONTENT_MESSAGE.DELETE.MESSAGE,
  action: POPUP_CONTENT_MESSAGE.DELETE.ACTION
};
