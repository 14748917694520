import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  companyConstants: null,
  isLoading: false,
  error: null
};

const slice = createSlice({
  name: 'companyConstants',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    _getSuccess: (state, { payload }) => {
      state.isLoading = false;
      state.companyConstants = payload;
      state.error = null;
    },
    _getFailure: (state) => {
      state.isLoading = false;
      state.companyConstants = null;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetchCompanyConstants = createAsyncThunk(
  'companyConstants/fetch_company_constants',
  async (_, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/constants`);
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
