import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import PaymentModeListTable from '../../components/payment_mode/PaymentModeListTable';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchPaymentModes } from '../../slices/paymentMode';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';
import PaymentModeViewDetails from '../../components/payment_mode/PaymentModeViewDetails';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const PaymentMode = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isViewPaymentModeModalOpen, setIsViewPaymentModeModalOpen] = useState(false);
  const [selectedPaymentMode, setSelectedPaymentMode] = useState(null);
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { paymentModes, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.paymentMode);
  const { currentCompany } = useSelector((state) => state.companies);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchPaymentModes());
    }
  }, [dispatch, currentCompany]);

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  const handlePaymentModeDetailOpen = (paymentMode) => {
    setSelectedPaymentMode(paymentMode);
    setIsViewPaymentModeModalOpen(true);
  };

  const handlePaymentModeDetailClose = () => {
    setSelectedPaymentMode([]);
    setIsViewPaymentModeModalOpen(false);
  };

  function returnResult() {
    if (paymentModes && paymentModes.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <PaymentModeListTable
            paymentModes={paymentModes}
            onOpenPaymentModeDetail={handlePaymentModeDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        id="empty-payment-mode"
        title="Não tens nenhum Modo de Pagamento"
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Modo de Pagamento | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Modo de Pagamento
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Modos de Pagamento
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
        </Container>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handlePaymentModeDetailClose}
          open={isViewPaymentModeModalOpen}
        >
          {isViewPaymentModeModalOpen && (
          <PaymentModeViewDetails
            paymentMode={selectedPaymentMode}
            onCancel={handlePaymentModeDetailClose}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default PaymentMode;
