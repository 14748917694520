import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Button
} from '@mui/material';
import AlertIcon from '../../icons/Alert';

export default function WarningPopup(props) {
  const { primaryButtonId, secondaryButtonId, message, onButtonPress, onSecondaryButtonPress, buttonText, secondaryButtonText, children, isAccepted } = props;

  return (
    <Box sx={{
      m: 3
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <AlertIcon sx={{ fontSize: 60, mt: 1 }} />
        <Typography
          color="textSecondary"
          variant="h6"
          sx={{
            textAlign: 'justify',
            m: 2
          }}
        >
          {message}
        </Typography>
      </Box>
      {children}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2
        }}
      >
        {secondaryButtonText && (
          <Button
            id={secondaryButtonId}
            fullWidth
            variant="outlined"
            onClick={onSecondaryButtonPress}
            sx={{
              mr: 2
            }}
          >
            {secondaryButtonText}
          </Button>
        )}
        <Button
          id={primaryButtonId}
          fullWidth
          color="primary"
          variant="contained"
          onClick={onButtonPress}
          disabled={isAccepted}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
}
WarningPopup.propTypes = {
  primaryButtonId: PropTypes.string,
  secondaryButtonId: PropTypes.string,
  message: PropTypes.string.isRequired,
  onButtonPress: PropTypes.func.isRequired,
  onSecondaryButtonPress: PropTypes.func,
  buttonText: PropTypes.string.isRequired,
  secondaryButtonText: PropTypes.string,
  children: PropTypes.node,
  isAccepted: PropTypes.bool
};
