const COMMON_MESSAGE = {
  required: 'Obrigatório'
};

export const EMAIL_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
  email: 'Digite um endereço de e-mail válido',
  max: 'O e-mail deve ter no máximo 255 caracteres'
};

export const USER_ROLES_MESSAGES = {
  required: COMMON_MESSAGE.required
};

export const AMOUNT_ERROR_MESSAGES = {
  required: COMMON_MESSAGE.required,
  number: 'O valor precisa ser maior que 0'
};
