/**
 * Function to retrieve label and styling information based on order status.
 * @param {string} status - The status of the order.
 * @returns {Object|null} An object containing label, color, and backgroundColor properties corresponding to the status, or null if status is not recognized.
 */

import { STORE_REQUEST_STATUS, UTILS_TEXT } from '../constants';

export const getStoreRequestStatus = (status, colors) => {
  let statusLabel;
  switch (status) {
    case STORE_REQUEST_STATUS.PROCESSING:
      statusLabel = {
        label: UTILS_TEXT.GET_STORE_REQUEST_STATUS.PROCESSING,
        color: colors.processing.main,
        backgroundColor: colors.processing.background
      };
      break;
    case STORE_REQUEST_STATUS.PENDING:
      statusLabel = {
        label: UTILS_TEXT.GET_STORE_REQUEST_STATUS.PENDING,
        color: colors.pending.main,
        backgroundColor: colors.pending.background
      };
      break;
    case STORE_REQUEST_STATUS.DENIED:
      statusLabel = {
        label: UTILS_TEXT.GET_STORE_REQUEST_STATUS.DENIED,
        color: colors.denied.main,
        backgroundColor: colors.denied.background
      };
      break;
    case STORE_REQUEST_STATUS.APPROVED:
      statusLabel = {
        label: UTILS_TEXT.GET_STORE_REQUEST_STATUS.APPROVED,
        color: colors.approved.main,
        backgroundColor: colors.approved.background
      };
      break;
    default:
      statusLabel = null;
  }
  return statusLabel;
};
