import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextField, Grid, Button, Typography } from '@mui/material';

const ForgotPasswordForm = ({ isDisabled, navigateToLoginPage, handleRegisterButtonClick, onSubmitForm }) => (
  <Formik
    initialValues={{
      email: null
    }}
    validationSchema={Yup
      .object()
      .shape({
        email: Yup
          .string()
          .email('Digite um email válido')
          .max(255)
          .nullable()
          .required('Obrigatório')
      })}
    onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
      try {
        onSubmitForm(values.email);
      } catch (err) {
        setStatus({ success: false });
        setErrors({ submit: err.message });
        setSubmitting(false);
      }
    }}
  >
    {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
      <form
        onSubmit={handleSubmit}
      >
        <TextField
          id="forgot-password-form-email-textfield"
          autoFocus
          error={Boolean(touched.email && errors.email)}
          fullWidth
          helperText={touched.email && errors.email}
          label="Email"
          placeholder="Introduzir Email"
          margin="normal"
          name="email"
          onBlur={handleBlur}
          onChange={handleChange}
          type="email"
          value={values.email}
          variant="outlined"
          disabled={isDisabled}
        />
        {isDisabled && (
          <>
            <Typography
              align="center"
              id="forgot-password-form-info-typography"
              marginTop="2rem"
              color="textPrimary"
            >
              Consulte o seu email.
            </Typography>
            <Typography
              align="center"
              color="textSecondary"
            >
              Se este endereço de email existir na nossa base de dados, enviaremos um email para que possa recuperar a sua senha.
            </Typography>

          </>
        )}
        <Grid
          container
          sx={{ mt: 20 }}
        >
          <Grid
            item
            xs
          >
            <Button
              alt="Cancelar"
              variant="outlined"
              type="button"
              color="primary"
              onClick={navigateToLoginPage}
              sx={{
                px: 6,
                py: 1,
                borderRadius: 4
              }}
            >
              <Typography>
                Cancelar
              </Typography>
            </Button>
          </Grid>
          <Grid
            item
          >
            <Grid
              container
              direction="column"
              justifyContent="space-between"
              alignItems="center"
            >
              <Button
                id="forgot-password-form-send-button"
                alt="Entrar"
                color="primary"
                disabled={isDisabled}
                type="submit"
                variant="contained"
                sx={{
                  px: 6,
                  py: 1,
                  borderRadius: 4
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600
                  }}
                >
                  Enviar
                </Typography>
              </Button>
              <Button
                color="primary"
                variant="text"
                onClick={handleRegisterButtonClick}
                sx={{
                  mt: 1
                }}
              >
                Não tenho conta
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    )}
  </Formik>

);

ForgotPasswordForm.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  navigateToLoginPage: PropTypes.func.isRequired,
  handleRegisterButtonClick: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired
};

export default ForgotPasswordForm;
