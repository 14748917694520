import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  purchaseInvoices: null,
  isLoading: false,
  loadingError: false,
  onRequest: null,
  onRequestFailure: null,
  successMessage: null,
  errorMessage: null,
  invoicePdf: null
};

const slice = createSlice({
  name: 'purchaseInvoices',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.purchaseInvoices = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.purchaseInvoices = null;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.purchaseInvoices === null) {
        state.purchaseInvoices = [];
      }
      state.purchaseInvoices.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.purchaseInvoices = state.purchaseInvoices.filter((purchaseInvoice) => purchaseInvoice.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'purchaseInvoices/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/purchase_invoices`);

      const purchaseInvoices = data.map((purchaseInvoice) => ({
        id: purchaseInvoice.id,
        documentType: {
          id: purchaseInvoice.document_type.id,
          code: purchaseInvoice.document_type.code,
          name: purchaseInvoice.document_type.name
        },
        provider: {
          id: purchaseInvoice.supplier.id,
          name: purchaseInvoice.supplier.name
        },
        serieNumber: purchaseInvoice.serial_number,
        paymentPeriod: {
          id: purchaseInvoice.payment_period.id,
          code: purchaseInvoice.payment_period.code,
          name: purchaseInvoice.payment_period.name,
          numberOfDay: purchaseInvoice.payment_period.number_of_day
        },
        paymentMode: {
          id: purchaseInvoice.payment_mode.id,
          code: purchaseInvoice.payment_mode.code,
          name: purchaseInvoice.payment_mode.name
        },
        date: purchaseInvoice.date,
        commentary: purchaseInvoice.commentary,
        valueWithoutTax: purchaseInvoice.value_without_tax,
        totalTax: purchaseInvoice.value_of_tax,
        valueWithTax: purchaseInvoice.value_with_tax,
        discount: purchaseInvoice.discount,
        totalGeneral: purchaseInvoice.total_general,
        number: purchaseInvoice.purchase_invoice_number,
        status: purchaseInvoice.status,
        purchaseInvoiceItems: purchaseInvoice.items.map((purchaseInvoiceItem) => ({
          id: purchaseInvoiceItem.id,
          item: purchaseInvoiceItem.item,
          quantity: purchaseInvoiceItem.quantity,
          unit: purchaseInvoiceItem.unit_code,
          price: purchaseInvoiceItem.price,
          tax: purchaseInvoiceItem.tax,
          discount: purchaseInvoiceItem.discount,
          itemTotal: purchaseInvoiceItem.item_total
        }))
      }));
      dispatch(_getSuccess(purchaseInvoices));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'purchaseInvoices/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const purchaseInvoiceItems = data.purchaseInvoiceItems.map((purchaseInvoiceItem) => ({
        item_id: purchaseInvoiceItem.item,
        tax_id: purchaseInvoiceItem.tax,
        unit_code: purchaseInvoiceItem.unit,
        price: Number(purchaseInvoiceItem.price),
        quantity: Number(purchaseInvoiceItem.quantity),
        discount: Number(purchaseInvoiceItem.discount)
      }));

      const purchaseInvoice = {
        document_type_id: data.documentType,
        serial_number: data.serie,
        purchase_invoice_number: data.number ? Number(data.number) : '',
        supplier_id: data.provider,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        status: data.status,
        commentary: data.commentary,
        purchase_invoice_items: purchaseInvoiceItems
      };

      const response = await axios.post(`/companies/${companyId}/purchase_invoices`, purchaseInvoice);

      const newPurchaseInvoice = {
        id: response.data.id,
        documentType: {
          id: response.data.document_type.id,
          code: response.data.document_type.code,
          name: response.data.document_type.name
        },
        provider: {
          id: response.data.supplier.id,
          name: response.data.supplier.name
        },
        serieNumber: response.data.serial_number,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalTax: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        number: response.data.purchase_invoice_number,
        status: response.data.status,
        purchaseInvoiceItems: response.data.items.map((purchaseInvoiceItem) => ({
          id: purchaseInvoiceItem.id,
          item: purchaseInvoiceItem.item,
          quantity: purchaseInvoiceItem.quantity,
          unit: purchaseInvoiceItem.unit_code,
          price: purchaseInvoiceItem.price,
          tax: purchaseInvoiceItem.tax,
          discount: purchaseInvoiceItem.discount,
          itemTotal: purchaseInvoiceItem.item_total
        }))
      };

      analytics.dispatchEvent(events.purchaseInvoiceCreated());

      dispatch(_createOrUpdatingSuccess(newPurchaseInvoice));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const update = createAsyncThunk(
  'purchaseInvoices/update',
  async (data, { dispatch, getState }) => {
    dispatch(_createOrUpdate());
    try {
      const purchaseInvoiceItems = data.purchaseInvoiceItems.map((purchaseInvoiceItem) => ({
        // we are checking if the id is a number,
        // since we dont want to send the temporary id to the api
        id: typeof purchaseInvoiceItem.id === 'number' ? undefined : purchaseInvoiceItem.id,
        item_id: purchaseInvoiceItem.item,
        tax_id: purchaseInvoiceItem.tax,
        unit_code: purchaseInvoiceItem.unit,
        price: Number(purchaseInvoiceItem.price),
        quantity: Number(purchaseInvoiceItem.quantity),
        discount: Number(purchaseInvoiceItem.discount)
      }));

      const editedPurchaseInvoice = {
        document_type_id: data.documentType,
        serial_number: data.serie,
        purchase_invoice_number: data.number ? Number(data.number) : '',
        supplier_id: data.provider,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        status: data.status,
        commentary: data.commentary,
        purchase_invoice_items: purchaseInvoiceItems
      };

      const response = await axios.put(`/companies/${companyId}/purchase_invoices/${data.id}`, editedPurchaseInvoice);

      const newPurchaseInvoice = {
        id: response.data.id,
        documentType: {
          id: response.data.document_type.id,
          code: response.data.document_type.code,
          name: response.data.document_type.name
        },
        provider: {
          id: response.data.supplier.id,
          name: response.data.supplier.name
        },
        serieNumber: response.data.serial_number,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalTax: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        number: response.data.purchase_invoice_number,
        status: response.data.status,
        purchaseInvoiceItems: response.data.items.map((purchaseInvoiceItem) => ({
          id: purchaseInvoiceItem.id,
          item: purchaseInvoiceItem.item,
          quantity: purchaseInvoiceItem.quantity,
          unit: purchaseInvoiceItem.unit_code,
          price: purchaseInvoiceItem.price,
          tax: purchaseInvoiceItem.tax,
          discount: purchaseInvoiceItem.discount,
          itemTotal: purchaseInvoiceItem.item_total
        }))
      };

      const { purchaseInvoices } = getState().purchaseInvoice;

      const updatedPurchaseInvoices = purchaseInvoices.map(
        (purchaseInvoice) => ((purchaseInvoice.id === data.id)
          ? newPurchaseInvoice
          : purchaseInvoice)
      );

      dispatch(_createOrUpdatingSuccess());
      dispatch(_getSuccess(updatedPurchaseInvoices));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const remove = createAsyncThunk(
  'purchaseInvoice/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/purchase_invoices/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
