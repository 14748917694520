import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  createFilterOptions,
  FormHelperText
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useEffect, useState, useRef } from 'react';

import { useForm } from '../../hooks/useForm';
import CustomDatePicker from '../CustomDatePicker';

const filter = createFilterOptions();

const FormToVoidInvoice = (props) => {
  const {
    onCancel,
    onSubmitForm,
    paymentPeriods,
    paymentModes,
    isInSerieDateRange,
    issueReasons,
    serie,
    getNextReturnOrCreditNoteNumber,
    documentsToVoidInvoice,
    issueReasonsDocs,
    issueReasonsAllowed,
    issueReasonsTypes,
    documentsToVoidInvoiceFormatted,
    getPaymentPeriodRange
  } = props;

  const [paymentPeriodInputValue, setPaymentPeriodInputValue] = useState(null);
  const [paymentModeInputValue, setPaymentModeInputValue] = useState(null);
  const [issueReasonInputValue, setIssueReasonInputValue] = useState(null);
  const [documentNameInputValue, setDocumentNameInputValue] = useState(null);
  const [documentCode, setDocumentCode] = useState(null);
  const [documentIssueReasons, setDocumentIssueReasons] = useState(null);

  const otherIssueReasonId = issueReasons?.find((issueReason) => issueReason.code === issueReasonsTypes?.OTR.code)?.id;

  const {
    openForm
  } = useForm();

  const formikRef = useRef();

  const documentValues = {
    documentCode: '',
    numberOfDocument: 1,
    date: new Date(),
    paymentPeriod: '',
    issueReason: '',
    issueReasonCode: '',
    otherIssueReason: '',
    paymentMode: '',
    commentary: ''
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  useEffect(() => {
    if (documentCode) {
      let docName;
      switch (documentCode) {
        case documentsToVoidInvoiceFormatted.returnNote.code:
          docName = documentsToVoidInvoiceFormatted.returnNote.name;
          break;
        default:
          docName = documentsToVoidInvoiceFormatted.creditNote.name;
      }

      const issueReasonsDoc = issueReasons.filter((issueReason) => issueReasonsAllowed[issueReason.code].includes(issueReasonsDocs[docName]));
      setDocumentIssueReasons(issueReasonsDoc);
      if (documentCode === documentsToVoidInvoiceFormatted.creditNote.code && issueReasonInputValue === issueReasonsTypes?.OTR.description) {
        setIssueReasonInputValue();
      }
    }
  }, [documentCode]);

  const handleSetOtherIssueReason = (touched, errors, handleBlur, handleChange, values) => (issueReasonInputValue !== issueReasonsTypes?.OTR.description ? '' : (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        p: 1
      }}
    >
      <Typography
        color="textSecondary"
        variant="overline"
      >
        Digite o seu Motivo de Retificação
      </Typography>
      <TextField
        id="document-to-void-form-other-issue-reason-input"
        error={Boolean(touched.otherIssueReason && errors.otherIssueReason)}
        fullWidth
        helperText={touched.otherIssueReason && errors.otherIssueReason}
        name="otherIssueReason"
        onBlur={handleBlur}
        onChange={(event) => {
          handleChange(event);
        }}
        value={values.otherIssueReason}
        variant="standard"
        multiline
      />
    </Box>
  ));

  const handleDateChange = (newValue, setFieldValue) => {
    setFieldValue('date', newValue.toDate());
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={documentValues}
      validationSchema={Yup
        .object()
        .shape({
          documentCode: Yup
            .string()
            .required('Obrigatório'),
          date: Yup
            .date()
            .min(new Date(serie?.startDateRaw || new Date()),
              'Esta data não pertence à série selecionada')
            .max(new Date(serie?.endDateRaw || new Date()),
              'Esta data não pertence à série selecionada')
            .required('Obrigatório'),
          paymentPeriod: Yup
            .string()
            .required('Obrigatório'),
          paymentMode: Yup
            .string()
            .required('Obrigatório'),
          issueReason: Yup
            .string()
            .required('Obrigatório'),
          otherIssueReason: Yup
            .string()
            .when('issueReason', {
              is: (val) => val === otherIssueReasonId,
              then: Yup
                .string()
                .trim('Não deve conter espaços nem no início nem no fim')
                .max(255, 'Este campo deve conter no máximo 255 caracteres')
                .required('Obrigatório'),
              otherwise: Yup
                .string()
            }),
          commentary: Yup
            .string()
            .max(200, 'Este campo deve conter no máximo 200 caracteres')
            .trim('Não deve conter espaços nem no início nem no fim'),
          numberOfDocument: Yup
            .number()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitForm(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setTouched,
        setFieldValue
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              Criar documento para anular a Fatura
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                gap: 1,
                mt: 2
              }}
              >
                <Autocomplete
                  id="document-to-void-form-document-code-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={documentsToVoidInvoice || []}
                  disableClearable
                  inputValue={documentNameInputValue || ''}
                  onChange={(event, value) => {
                    setDocumentNameInputValue(value.name);
                    setDocumentCode(value.code);
                    setFieldValue('documentCode', value.code || '');
                    if (values.issueReason === otherIssueReasonId) {
                      setFieldValue('issueReason', '');
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && documentNameInputValue !== '') || documentNameInputValue.length === 1) {
                      setDocumentNameInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.documentCode && errors.documentCode)}
                      helperText={touched.documentCode && errors.documentCode}
                      label="Documento*"
                      name="documentCode"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
                <TextField
                  id="document-to-void-form-number-of-document"
                  error={Boolean(touched.numberOfDocument && errors.numberOfDocument)}
                  fullWidth
                  helperText={touched.numberOfDocument && errors.numberOfDocument}
                  disabled
                  label="Número*"
                  name="numberOfDocument"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={documentCode ? getNextReturnOrCreditNoteNumber(serie?.id, documentCode) : ''}
                  variant="outlined"
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                gap: 1
              }}
              >
                <Box>
                  <CustomDatePicker
                    label="Data"
                    onChange={(newValue) => handleDateChange(newValue, setFieldValue)}
                  />
                  {(serie && !isInSerieDateRange(values.date, serie)) && (
                    <Box>
                      <FormHelperText
                        id="document-to-void-form-date-picker-helper-text"
                        error
                      >
                        Esta data não pertence à série selecionada
                      </FormHelperText>
                    </Box>
                  )}
                </Box>
                <Autocomplete
                  id="document-to-void-form-payment-period-autocomplete"
                  getOptionLabel={(option) => option.name}
                  options={paymentPeriods || []}
                  disableClearable
                  inputValue={paymentPeriodInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentPeriodInputValue(value.name);
                    setFieldValue('paymentPeriod', value.id || '');

                    if (value.name === 'Criar Novo') {
                      openForm('PAYMENT_PERIOD');
                      value.name = paymentPeriodInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentPeriodInputValue !== '') || paymentPeriodInputValue.length === 1) {
                      setPaymentPeriodInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentPeriod && errors.paymentPeriod)}
                      helperText={touched.paymentPeriod && errors.paymentPeriod}
                      label="Período de Pagamento*"
                      name="paymentPeriod"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                gap: 1
              }}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Limite de Pagamento"
                  name="range"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  value={getPaymentPeriodRange(values)}
                  variant="outlined"
                />
                <Autocomplete
                  id="document-to-void-form-payment-mode-autocomplete"
                  getOptionLabel={(option) => option.code}
                  options={paymentModes || []}
                  disableClearable
                  inputValue={paymentModeInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentModeInputValue(value.code);
                    setFieldValue('paymentMode', value.id || '');
                    if (value.code === 'Criar Novo') {
                      openForm('PAYMENT_MODE');
                      value.code = paymentModeInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentModeInputValue !== '') || paymentModeInputValue.length === 1) {
                      setPaymentModeInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      code: 'Criar Novo'
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.paymentMode && errors.paymentMode)}
                      helperText={touched.paymentMode && errors.paymentMode}
                      label="Modo de Pagamento*"
                      name="paymentMode"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <Autocomplete
                  id="document-to-void-form-issueReason-autocomplete"
                  disabled={!documentCode}
                  getOptionLabel={(option) => (documentCode === documentsToVoidInvoiceFormatted.creditNote.code && option.description === issueReasonsTypes?.OTR.description ? '' : option.description)}
                  options={documentIssueReasons || []}
                  disableClearable
                  inputValue={issueReasonInputValue || ''}
                  onChange={(event, value) => {
                    setIssueReasonInputValue(value.description);
                    setFieldValue('issueReason', value.id || '');
                    setFieldValue('issueReasonCode', value.code);
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && issueReasonInputValue !== '') || issueReasonInputValue.length === 1) {
                      setIssueReasonInputValue(newInputValue);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      error={Boolean(touched.issueReason && errors.issueReason)}
                      helperText={touched.issueReason && errors.issueReason}
                      label="Motivo de Retificação*"
                      name="issueReason"
                      variant="outlined"
                      onClick={() => {
                        if (!values.documentCode) {
                          setTouched({
                            documentCode: true
                          });
                        }
                      }}
                      {...params}
                    />
                  )}
                />
              </Box>
              {handleSetOtherIssueReason(touched, errors, handleBlur, handleChange, values)}
            </Box>

            <Box sx={{
              py: 4,
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: 4
            }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="overline"
                >
                  Comentário(s)
                </Typography>
                <TextField
                  id="document-to-void-form-commentary-input"
                  error={Boolean(touched.commentary && errors.commentary)}
                  fullWidth
                  helperText={touched.commentary && errors.commentary}
                  name="commentary"
                  onBlur={handleBlur}
                  onChange={(event) => {
                    handleChange(event);
                  }}
                  value={values.commentary}
                  variant="standard"
                  multiline
                />
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="document-to-void-form-add-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Criar
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

FormToVoidInvoice.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  isInSerieDateRange: PropTypes.func.isRequired,
  serie: PropTypes.object.isRequired,
  getNextReturnOrCreditNoteNumber: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  paymentPeriods: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.string
  }).isRequired,
  paymentModes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  issueReasons: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  getPaymentPeriodRange: PropTypes.func.isRequired,
  documentsToVoidInvoice: PropTypes.arrayOf({
    name: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  issueReasonsAllowed: PropTypes.object.isRequired,
  issueReasonsDocs: PropTypes.object.isRequired,
  issueReasonsTypes: PropTypes.object.isRequired,
  documentsToVoidInvoiceFormatted: PropTypes.object.isRequired
};

export default FormToVoidInvoice;
