import { BALANCE_TYPES } from '../constants';

/**
 * Calculates the balance for Ping transactions for the current company.
 * @param {Object} currentCompany - The current company object.
 * @returns {Object|undefined} The balance object for Ping transactions, or undefined if not found.
 */
const calculatePingBalance = (currentCompany) => currentCompany?.balances?.find((balance) => balance.balanceId === BALANCE_TYPES.pingPOS);

export default calculatePingBalance;
