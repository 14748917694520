import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';

let companyId;

const initialState = {
  isLoading: null,
  error: null,
  errorMessage: null
};

const slice = createSlice({
  name: 'ping',
  initialState,
  reducers: {
    _integrate: (state) => {
      state.isLoading = true;
    },
    _integratedSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _integratedFailure: (state, { payload }) => {
      state.isLoading = false;
      state.error = true;
      state.errorMessage = payload.errorMessage;
    }
  }
});

const {
  _integrate,
  _integratedSuccess,
  _integratedFailure
} = slice.actions;

export default slice.reducer;

export const integrate = createAsyncThunk(
  'ping/integrate',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_integrate());
    try {
      const { data } = await axios.post(`/integrations/ping_pos/companies/${companyId}`);

      dispatch(_integratedSuccess(data));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_integratedFailure({ errorMessage }));
    }
  }
);
