import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import Scrollbar from '../Scrollbar';

import { formatCurrency } from '../../util/formatCurrency';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const ViewDetails = (props) => {
  const { purchaseInvoice, onEdit, onDelete, onCancel, currentCompany, ...other } = props;

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  return (
    <Card {...other}>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Despesas
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Tipo de Documento
                </TableCell>
                <TableCell sx={{ fontWeight: 400 }}>
                  {purchaseInvoice.documentType.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Número
                </TableCell>
                <TableCell>
                  {
                    purchaseInvoice.number
                      ? String(purchaseInvoice.number).padStart(4, '0')
                      : ''
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data
                </TableCell>
                <TableCell>
                  {purchaseInvoice.dateFormatted}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Período de Pagamento
                </TableCell>
                <TableCell>
                  {purchaseInvoice.paymentPeriod.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Limite de Pagamento
                </TableCell>
                <TableCell>
                  {purchaseInvoice.paymentLimit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Modo de Pagamento
                </TableCell>
                <TableCell>
                  {purchaseInvoice.paymentMode.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Fornecedor
                </TableCell>
                <TableCell>
                  {purchaseInvoice.provider.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Estado
                </TableCell>
                <TableCell>
                  {purchaseInvoice.status === 'settled' ? 'Liquidado' : 'Não Liquidado'}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Lista de itens na Fatura
        </Typography>
        <Box sx={{ pt: 3 }}>
          <Card>
            <Scrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Artigo
                    </TableCell>
                    <TableCell>
                      Descrição
                    </TableCell>
                    <TableCell>
                      Unidade
                    </TableCell>
                    <TableCell>
                      Quantidade
                    </TableCell>
                    <TableCell>
                      Preço
                    </TableCell>
                    <TableCell>
                      {taxOrRetentionLabels.taxTypeLabel}
                    </TableCell>
                    <TableCell>
                      Desconto
                    </TableCell>
                    <TableCell>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {purchaseInvoice.purchaseInvoiceItems.map((invoiceItem) => (
                    <TableRow
                      hover
                      key={invoiceItem.id}
                    >
                      <TableCell>
                        {invoiceItem.item.code}
                      </TableCell>
                      <TableCell>
                        {invoiceItem.item.name}
                      </TableCell>
                      <TableCell>
                        {invoiceItem.unit}
                      </TableCell>
                      <TableCell>
                        {invoiceItem.quantity}
                      </TableCell>
                      <TableCell>
                        {invoiceItem.price}
                        $
                      </TableCell>
                      <TableCell>
                        {invoiceItem.tax.value}
                        %
                      </TableCell>
                      <TableCell>
                        {invoiceItem.discount}
                        %
                      </TableCell>
                      <TableCell>
                        {formatCurrency(invoiceItem.itemTotal)}
                        $
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </Card>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="outlined"
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          sx={{ ml: 1 }}
          type="submit"
          variant="outlined"
          onClick={onEdit}
        >
          Editar
        </Button>
        <Button
          color="error"
          variant="contained"
          onClick={onDelete}
          sx={{ ml: 1 }}
        >
          Apagar
        </Button>
      </Box>
    </Card>
  );
};

ViewDetails.propTypes = {
  purchaseInvoice: PropTypes.shape({
    id: PropTypes.string,
    providerName: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    paymentLimit: PropTypes.string,
    serieCode: PropTypes.string,
    discount: PropTypes.number,
    number: PropTypes.number,
    status: PropTypes.string,
    provider: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string
    }),
    purchaseInvoiceItems: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    }),
    documentType: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    paymentPeriod: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      numberOfDay: PropTypes.number
    }),
    paymentMode: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ViewDetails;
