import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  units: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'unit',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.units = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.units = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createSuccess: (state, { payload }) => {
      if (state.units === undefined) {
        state.units = [];
      }
      state.units.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _updateSuccess: (state, { payload }) => {
      const indexToUpdate = state.units.findIndex(unit => unit.id === payload.unitId);

      if (indexToUpdate !== -1) {
        state.units[indexToUpdate].code = payload.code;
        state.units[indexToUpdate].description = payload.description;
      }
      
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.units = state.units.filter((unit) => unit.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createSuccess,
  _updateSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'unit/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/units`);
      dispatch(_getSuccess(response.data.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'unit/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const unit = {
        code: data.code.trim(),
        description: data.description
      };

      const response = await axios.post(`/companies/${companyId}/units`, unit);

      const newUnit = {
        id: response.data.id,
        code: response.data.code,
        description: response.data.description
      };

      analytics.dispatchEvent(events.unitCreated());

      dispatch(_createSuccess(newUnit));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const update = createAsyncThunk(
  'unit/update',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const unit = {
        code: data.code.trim(),
        description: data.description
      };
      await axios.put(`/companies/${companyId}/units/${data.unitId}`, unit);

      dispatch(_updateSuccess(data));

      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const remove = createAsyncThunk(
  'units/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/units/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
