import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import LoadingPopUp from '../LoadingPopUp';
import UnsuccessPopUp from '../UnsuccessPopUp';

import './index.css';

const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true
};

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
export default function RenderPdf({ file }) {
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages: nextNumPages }) {
    setNumPages(nextNumPages);
  }

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      options={options}
      loading={<LoadingPopUp message="Carregando..." />}
      noData={<LoadingPopUp message="Carregando..." />}
      error={<UnsuccessPopUp message="Erro ao carregar a Fatura" />}
    >
      {
        Array.from(
          new Array(numPages),
          (el, index) => (
            <Page
              key={`page_${index + 1}`}
              pageNumber={index + 1}
            />
          )
        )
      }
    </Document>
  );
}

RenderPdf.propTypes = {
  file: PropTypes.string.isRequired
};
