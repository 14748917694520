import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  Divider,
  TextField,
  Typography,
  IconButton
} from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { CREATE_NEW, FORM, PRODUCT_AVAILABILITY_OPTIONS } from '../../constants';
import NumericTextField from '../NumericTextField';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const filter = createFilterOptions();

const ItemForm = (props) => {
  const { onCancel, onSubmitForm, item, taxes, units, useForm, currentCompany } = props;
  const [unitInitialLength, setUnitInitialLength] = useState(null);
  const [unitInputValue, setUnitInputValue] = useState(null);

  const initialValues = {
    images: item && item.images ? item.images : null,
    code: '',
    name: '',
    cost: '',
    sellingPrice: '',
    unitId: '',
    taxId: '',
    isProduct: false
  };

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const formikRef = useRef();

  const [file, setFile] = useState(item && item.images.length > 0 ? item.images[0].url : null);

  const dropzoneStyles = {
    border: '0.25rem dashed #cccccc',
    borderRadius: '0.25rem',
    padding: '1.25rem',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '1.25rem'
  };

  const onDrop = (acceptedFiles) => {
    setFile(Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0])
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
    maxFiles: 1
  });

  const handleDelete = () => {
    setFile(null);
  };

  useEffect(() => {
    if (!unitInitialLength && units) {
      setUnitInitialLength(units.length);
    } else if (units && units.length > unitInitialLength) {
      setUnitInputValue(units[(units.length) - 1].code);

      if (formikRef.current) {
        formikRef.current.values.unitId = units[(units.length) - 1].id;
      }
    }
  }, [units]);

  const itemValues = {
    id: item?.id,
    code: item?.code,
    name: item?.name,
    cost: item?.cost,
    sellingPrice: item?.sellingPrice,
    unitId: item?.unit.id,
    taxId: item?.tax.id,
    isProduct: item?.isProduct
  };

  const {
    openForm
  } = useForm();

  const productAvailabilityOptions = [
    {
      label: PRODUCT_AVAILABILITY_OPTIONS.YES,
      value: true
    },
    {
      label: PRODUCT_AVAILABILITY_OPTIONS.NO,
      value: false
    }
  ];
  const renderDeleteIcon = () => {
    if (!item) {
      return (
        <Box
          sx={{
            position: 'absolute',
            top: '-0.75rem',
            right: '-0.75rem',
            backgroundColor: '#f44336',
            borderRadius: '50%',
            width: '1.5rem',
            height: '1.5rem',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            p: 2
          }}
        >
          <IconButton
            onClick={handleDelete}
            color="secondary"
            size="small"
          >
            <DeleteIcon style={{ color: '#FFFFFF' }} />
          </IconButton>
        </Box>
      );
    }
    return null;
  };

  const renderPreviewPhoto = () => {
    if (item && item.images.length > 0) {
      return file;
    }
    return file.preview;
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={item ? itemValues : initialValues}
      validationSchema={Yup
        .object()
        .shape({
          images: Yup
            .mixed()
            .test('fileFormat', 'Formato de imagem inválido', (value) => {
              if (!value) return true;
              return ['image/jpeg', 'image/png'].includes(value.type);
            }),
          code: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          name: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          cost: Yup
            .number()
            .typeError('Pode conter apenas números'),
          sellingPrice: Yup
            .number()
            .typeError('Pode conter apenas números'),
          unitId: Yup
            .string()
            .max(255)
            .required('Obrigatório'),
          taxId: Yup
            .string()
            .max(255)
            .required('Obrigatório'),
          isProduct: Yup
            .bool()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.tax = taxes.find((tax) => tax.id === values.taxId);
          const dataToSend = {
            ...values,
            file
          };
          onSubmitForm(dataToSend);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3, display: 'flex', gap: 2, flexDirection: 'column' }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {item ? 'Editar Item' : 'Novo Item'}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Box>
                {!file ? (
                  <Box
                    {...getRootProps()}
                    style={dropzoneStyles}
                  >
                    <input {...getInputProps()} />
                    <Typography variant="body1">
                      Arraste e solte uma imagem aqui, ou clique para selecionar
                    </Typography>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ position: 'relative', display: 'inline-block' }}>
                      <img
                        src={renderPreviewPhoto()}
                        alt="Imagem do item"
                        style={{
                          width: '11.875rem',
                          height: '11.875rem',
                          border: '0.125rem solid #1CC5DC',
                          borderRadius: '5%'
                        }}
                      />
                      {renderDeleteIcon()}
                    </Box>
                  </>
                )}
              </Box>
            </Box>
            <Box>
              <TextField
                autoFocus
                error={Boolean(touched.code && errors.code)}
                fullWidth
                helperText={touched.code && errors.code}
                label="Código*"
                name="code"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.code}
                variant="outlined"
              />
            </Box>
            <Box>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Nome*"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Box>
            <Box>
              <NumericTextField
                error={Boolean(touched.cost && errors.cost)}
                fullWidth
                helperText={touched.cost && errors.cost}
                label="Custo"
                name="cost"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.cost}
                variant="outlined"
              />
            </Box>
            <Box>
              <NumericTextField
                error={Boolean(touched.sellingPrice && errors.sellingPrice)}
                fullWidth
                helperText={touched.sellingPrice && errors.sellingPrice}
                label="Preço de venda"
                name="sellingPrice"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.sellingPrice}
                variant="outlined"
              />
            </Box>
            <Box>
              <Autocomplete
                getOptionLabel={(option) => option.code}
                options={units || []}
                defaultValue={item ? ({
                  id: itemValues?.unitId,
                  code: units.find((unit) => unit.id === itemValues.unitId)?.code
                }) : null}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  filtered.push({
                    inputValue: params.inputValue,
                    code: CREATE_NEW
                  });

                  return filtered;
                }}
                disableClearable
                inputValue={unitInputValue || ''}
                onChange={(event, value) => {
                  setUnitInputValue(value.code);
                  setFieldValue('unitId', value.id || '');

                  if (value.code === CREATE_NEW) {
                    openForm(FORM.UNIT);
                    value.code = unitInputValue || '';
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  if ((newInputValue !== '' && unitInputValue !== '') || unitInputValue.length === 1) {
                    setUnitInputValue(newInputValue);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.unitId && errors.unitId)}
                    helperText={touched.unitId && errors.unitId}
                    label="Unidade*"
                    name="unitId"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box>
              <Autocomplete
                getOptionLabel={(option) => `${option.name} - ${option.value}%`}
                options={taxes || []}
                disableClearable
                defaultValue={item ? ({
                  id: itemValues?.taxId,
                  name: taxes.find((tax) => tax.id === itemValues.taxId)?.name,
                  value: taxes.find((tax) => tax.id === itemValues.taxId)?.value
                }) : null}
                onChange={(e, value) => {
                  setFieldValue('taxId', value?.id || '');
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.taxId && errors.taxId)}
                    helperText={touched.taxId && errors.taxId}
                    label={taxOrRetentionLabels.typeRequiredLabel}
                    name="taxId"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box>
              <Autocomplete
                getOptionLabel={(option) => `${option.label}`}
                options={productAvailabilityOptions}
                disableClearable
                defaultValue={item ? ({
                  label: itemValues?.isProduct ? PRODUCT_AVAILABILITY_OPTIONS.YES : PRODUCT_AVAILABILITY_OPTIONS.NO,
                  value: itemValues.isProduct
                }) : null}
                onChange={(e, value) => {
                  setFieldValue('isProduct', value?.value);
                }}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.isProduct && errors.isProduct)}
                    helperText={touched.isProduct && errors.isProduct}
                    label="Disponível no E-Commerce"
                    name="isProduct"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              Guardar
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};
ItemForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  useForm: PropTypes.func.isRequired,
  // item can be null
  item: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number,
    isProduct: PropTypes.bool,
    images: PropTypes.arrayOf({
      rank: PropTypes.number,
      url: PropTypes.string
    }),
    unit: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string
    }),
    tax: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      value: PropTypes.string
    })
  }),
  units: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    value: PropTypes.string
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

ItemForm.defaultProps = {
  item: null
};

export default ItemForm;
