import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography
} from '@mui/material';
import { create as createPaymentPeriod, update as updatePaymentPeriod } from '../../slices/paymentPeriod';
import { useDispatch } from '../../store';
import NumericTextField from '../NumericTextField';

const initialValues = {
  code: undefined,
  name: undefined,
  numberOfDay: undefined
};

const PaymentPeriodForm = (props) => {
  const { onCancel, isAdd, selectedPaymentPeriod, onSubmitForm } = props;
  const dispatch = useDispatch();

  const selectedPaymentPeriodValues = {
    code: selectedPaymentPeriod?.code,
    name: selectedPaymentPeriod?.name,
    numberOfDay: selectedPaymentPeriod?.numberOfDay
  };

  return (
    <Formik
      initialValues={isAdd ? initialValues : selectedPaymentPeriodValues}
      validationSchema={Yup
        .object()
        .shape({
          code: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          name: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          numberOfDay: Yup
            .number()
            .typeError('Deve conter apenas números')
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const data = {
            code: values.code,
            name: values.name,
            number_of_day: Number(values.numberOfDay)
          };
          onSubmitForm();
          if (!isAdd) {
            data.paymentPeriod = selectedPaymentPeriod.id;
            dispatch(updatePaymentPeriod(data));
          } else {
            dispatch(createPaymentPeriod(data));
          }

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {isAdd ? 'Novo Período de Pagamento' : 'Editar Modo de Pagamento'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              autoFocus
              error={Boolean(touched.code && errors.code)}
              fullWidth
              helperText={touched.code && errors.code}
              label="Código*"
              name="code"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.code}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Nome*"
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.name}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <NumericTextField
                error={Boolean(touched.numberOfDay && errors.numberOfDay)}
                fullWidth
                helperText={touched.numberOfDay && errors.numberOfDay}
                label="Número de Dias*"
                name="numberOfDay"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.numberOfDay}
                variant="outlined"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {isAdd ? 'Criar' : 'Guardar'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

PaymentPeriodForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  selectedPaymentPeriod: PropTypes.shape({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.number
  })
};
PaymentPeriodForm.defaultProps = {
  selectedPaymentPeriod: {}
};

export default PaymentPeriodForm;
