import {
  Box,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

const Header = () => {
  const [headerBorder, setHeaderBorder] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setHeaderBorder(true);
    } else {
      setHeaderBorder(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [window.scrollY]);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        boxShadow: headerBorder ? '0px 2px 10px 10px rgba(80, 80, 80, 0.1)' : ''
      }}
    >
      <Toolbar sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center'
        }}
        >
          <Typography
            variant="title"
            color="primary"
            sx={{
              left: 0,
              px: 2,
              fontSize: 36
            }}
          >
            SAMBA
          </Typography>
          <Typography
            variant="textPrimary"
            gutterBottom
            sx={{
              left: 0,
              fontSize: 16
            }}
          >
            BLOG
          </Typography>
        </Box>
        <Box>
          <a
            href="https://www.facebook.com/SAMBA-Cloud-108642664819245"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              alt="Facebook"
              component="img"
              src="/static/blogPage/facebook-icon.svg"
              width="32px"
              height="32px"
              mx={1}
              sx={{
                w: 16
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/samba_cloud/"
            target="_blank"
            rel="noreferrer"
            style={{ padding: 0, margin: 0 }}
          >
            <Box
              alt="Instagram"
              component="img"
              src="/static/blogPage/instagram-icon.svg"
              width="32px"
              height="32px"
              mx={2}
              sx={{
                w: 16
              }}
            />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
