import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  TextField,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  InputAdornment
} from '@mui/material';
import NumericTextField from '../NumericTextField';
import { CAPE_VERDE_COUNTRY_CODE } from '../../constants';

const initialValues = {
  name: undefined,
  type: 'internal',
  fiscalName: undefined,
  nif: undefined,
  country: undefined,
  isIndividual: 'singular',
  address: null,
  phoneNumber: null,
  country_code: CAPE_VERDE_COUNTRY_CODE
};

const ClientForm = (props) => {
  // isAdd is a boolean that indicate if the form
  // is to add or to edit
  const { onCancel, onSubmitForm, isAdd, selectedClient, countries } = props;

  const getCountryCode = (countryId) => {
    if (!countryId) {
      return initialValues.country_code;
    }

    const getCountry = countries.filter((country) => countryId === country.id);

    return getCountry[0]?.country_code;
  };

  const clientValues = {
    name: selectedClient?.name,
    type: selectedClient?.internal ? 'internal' : 'external',
    fiscalName: selectedClient?.fiscalName,
    nif: selectedClient?.nif,
    isIndividual: selectedClient?.isIndividual ? 'singular' : 'colective',
    country: selectedClient?.countryId,
    address: selectedClient?.address,
    phoneNumber: selectedClient?.phoneNumber?.phone_number || null,
    country_code: selectedClient?.phoneNumber?.country_code || getCountryCode(selectedClient?.countryId)
  };

  return (
    <Formik
      initialValues={isAdd ? initialValues : clientValues}
      validationSchema={Yup
        .object()
        .shape({
          name: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          fiscalName: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório'),
          nif: Yup
            .string()
            .matches(/^[0-9]+$/, 'Deve conter exatamente 9 dígitos')
            .length(9, 'Deve conter exatamente 9 dígitos')
            .trim()
            .required('Obrigatório'),
          country: Yup
            .string()
            .required('Obrigatório'),
          address: Yup.string()
            .nullable()
            .test('no-spaces', 'O campo não pode começar ou terminar com espaços', (value) => !(value && (value.startsWith(' ') || value.endsWith(' '))))
            .min(10, 'O endereço deve ter no mínimo 10 caracteres')
            .max(200, 'O endereço deve ter no máximo 200 caracteres')
            .required('Obrigatório'),
          isIndividual: Yup
            .string()
            .nullable()
            .required('Obrigatório'),
          type: Yup
            .string()
            .nullable()
            .required('Obrigatório'),
          phoneNumber: Yup
            .string()
            .nullable()
            .matches(/^[0-9]+$/, 'Pode conter apenas dígitos')
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          if (!isAdd) {
            values.clientId = selectedClient.id;
            values.phoneNumberId = selectedClient.phoneNumber?.id;
          }

          onSubmitForm(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              id="title-new-client-dialog"
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {isAdd ? 'Novo Cliente' : 'Editar Cliente'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              id="new-client-name-form-input"
              autoFocus
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Nome"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.name}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <TextField
                id="new-client-fiscal-name-form-input"
                error={Boolean(touched.fiscalName && errors.fiscalName)}
                fullWidth
                helperText={touched.fiscalName && errors.fiscalName}
                label="Nome Fiscal"
                name="fiscalName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.fiscalName}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <NumericTextField
                id="new-client-nif-form-input"
                error={Boolean(touched.nif && errors.nif)}
                fullWidth
                helperText={touched.nif && errors.nif}
                label="NIF do Cliente"
                name="nif"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.nif}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <Autocomplete
                id="new-client-country-form-autocomplete"
                getOptionLabel={(option) => option.pt}
                options={countries || []}
                disableClearable
                defaultValue={isAdd ? null : ({
                  id: clientValues.countryId,
                  pt: countries.find((country) => country.id === clientValues.country)?.pt
                })}
                onChange={
                  (e, value) => {
                    setFieldValue('country', value?.id || '');
                    setFieldValue('country_code', getCountryCode(value?.id));
                  }
                }
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(touched.country && errors.country)}
                    helperText={touched.country && errors.country}
                    label="País"
                    name="country"
                    variant="outlined"
                    {...params}
                  />
                )}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="new-client-address-form-input"
                error={Boolean(touched.address && errors.address)}
                fullWidth
                helperText={touched.address && errors.address}
                label="Endereço"
                name="address"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.address}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="new-client-phone-number-form-input"
                error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                fullWidth
                helperText={touched.phoneNumber && errors.phoneNumber}
                label="Telefone"
                name="phoneNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.phoneNumber}
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">{values?.country_code}</InputAdornment>
                }}
              />

            </Box>
            <Box sx={{ mt: 2 }}>
              <FormLabel>Tipo</FormLabel>
              <RadioGroup
                row
                name="type"
                value={values?.type}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="internal"
                  control={<Radio />}
                  label="Interno"
                />
                <FormControlLabel
                  value="external"
                  control={<Radio />}
                  label="Externo"
                />
              </RadioGroup>
            </Box>
            <Box sx={{ mt: 2 }}>
              <FormLabel>Entidade</FormLabel>
              <RadioGroup
                row
                name="isIndividual"
                value={values?.isIndividual}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="singular"
                  control={<Radio />}
                  label="Singular"
                />
                <FormControlLabel
                  value="colective"
                  control={<Radio />}
                  label="Coletivo"
                />
              </RadioGroup>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="new-client-submit-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {isAdd ? 'Criar' : 'Guardar'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

ClientForm.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isAdd: PropTypes.func.isRequired,
  // selectedClient can be null if the isAdd is true
  selectedClient: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    nif: PropTypes.number,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    internal: PropTypes.bool,
    isIndividual: PropTypes.bool,
    address: PropTypes.string,
    phoneNumber: PropTypes.shape({
      phone_number: PropTypes.string,
      id: PropTypes.string,
      country_code: PropTypes.string
    })
  }),
  countries: PropTypes.arrayOf(PropTypes.shape({
    country_code: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    pt: PropTypes.string.isRequired
  })).isRequired
};
ClientForm.defaultProps = {
  selectedClient: {
    phoneNumber: {
      phone_number: null,
      id: null,
      country_code: null
    }
  }
};
export default ClientForm;
