import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  invoiceReceipts: null,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  invoiceReceiptPdf: null
};

const slice = createSlice({
  name: 'invoiceReceipts',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.invoiceReceipts = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.invoiceReceipts = null;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.invoiceReceipts === null) {
        state.invoiceReceipts = [];
      }
      state.invoiceReceipts.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _void: (state) => {
      state.onRequest = true;
    },
    _voidingSuccess: (state, { payload }) => {
      state.invoiceReceipts = state.invoiceReceipts.map((invoiceReceipt) => {
        if (invoiceReceipt.id === payload.id) {
          invoiceReceipt.isVoided = true;
        }

        return invoiceReceipt;
      });
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _voidFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendInvoiceReceiptEmail: (state) => {
      state.onRequest = true;
    },
    _sendInvoiceReceiptEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendInvoiceReceiptEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setInvoiceReceiptsPdf: (state, { payload }) => {
      state.invoiceReceiptPdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _void,
  _voidFailure,
  _voidingSuccess,
  _setSuccessMessage,
  _sendInvoiceReceiptEmail,
  _sendInvoiceReceiptEmailFailure,
  _sendInvoiceReceiptEmailSuccess,
  _setInvoiceReceiptsPdf
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'invoiceReceipt/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/invoice_receipts`);

      const invoiceReceipts = data.map((invoiceReceipt) => ({
        id: invoiceReceipt.id,
        clientName: invoiceReceipt.customer.name,
        clientId: invoiceReceipt.customer.id,
        serieCode: invoiceReceipt.serial_number.code,
        paymentPeriod: {
          id: invoiceReceipt.payment_period.id,
          code: invoiceReceipt.payment_period.code,
          name: invoiceReceipt.payment_period.name,
          numberOfDay: invoiceReceipt.payment_period.number_of_day
        },
        paymentMode: {
          id: invoiceReceipt.payment_mode.id,
          code: invoiceReceipt.payment_mode.code,
          name: invoiceReceipt.payment_mode.name
        },
        efaturaData: {
          id: invoiceReceipt.efatura_data.id,
          status: invoiceReceipt.efatura_data.status,
          errorCode: invoiceReceipt.efatura_data.error_code,
          errorMessage: invoiceReceipt.efatura_data.error_message
        },
        date: invoiceReceipt.date,
        serie: invoiceReceipt.serial_number.id,
        serieName: invoiceReceipt.serial_number.name,
        commentary: invoiceReceipt.commentary,
        valueWithoutTax: invoiceReceipt.value_without_tax,
        totalTax: invoiceReceipt.value_of_tax,
        valueWithTax: invoiceReceipt.value_with_tax,
        discount: invoiceReceipt.discount,
        totalGeneral: invoiceReceipt.total_general,
        number: invoiceReceipt.number || invoiceReceipt.invoice_number,
        isVoided: invoiceReceipt.is_voided,
        receiptsValue: invoiceReceipt.receipts_values,
        paymentStatus: invoiceReceipt.payment_status,
        invoiceItems: invoiceReceipt.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      }));
      dispatch(_getSuccess(invoiceReceipts));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'invoiceReceipt/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const invoiceItems = data.invoiceItems.map((item) => ({
        item_id: item.item,
        quantity: Number(item.quantity),
        unit_code: item.unit,
        price: Number(item.price),
        tax_id: item.tax,
        discount: Number(item.discount),
        item_description: item.description
      }));

      const invoiceReceipt = {
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        items: invoiceItems
      };

      const response = await axios.post(`/companies/${companyId}/invoice_receipts`, invoiceReceipt);

      const newInvoiceReceipt = {
        id: response.data.id,
        clientName: response.data.customer.name,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieCode: response.data.serial_number.code,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalIva: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        number: response.data.number || response.data.invoice_number,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        isVoided: response.data.is_voided,
        paymentStatus: response.data.payment_status,
        invoiceItems: response.data.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      };

      analytics.dispatchEvent(events.invoiceReceiptCreated());

      dispatch(_createOrUpdatingSuccess(newInvoiceReceipt));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const voidInvoiceReceipt = createAsyncThunk(
  'invoiceReceipt/void',
  async (data, { dispatch }) => {
    dispatch(_void());
    try {
      await axios.post(`/companies/${companyId}/invoice_receipts/${data.id}/void`);

      dispatch(_voidingSuccess(data));
      dispatch(_setSuccessMessage('Anulado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_voidFailure(errorMessage));
    }
  }
);

export const sendInvoiceReceiptEmail = createAsyncThunk(
  'invoiceReceipt/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendInvoiceReceiptEmail());
    try {
      await axios.post(`/companies/${companyId}/invoice_receipts/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.invoiceReceiptEmailed());

      dispatch(_sendInvoiceReceiptEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendInvoiceReceiptEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'invoiceReceipt/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/invoice_receipts/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.invoiceReceiptDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'invoiceReceipt/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/invoice_receipts/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setInvoiceReceiptsPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'invoiceReceipt/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { invoiceReceipts } = getState().invoiceReceipts;

      const updatedInvoiceReceipt = invoiceReceipts.map((invoiceReceipt) => {
        const invoiceReceiptUpdated = data.find((i) => i.id === invoiceReceipt.id);

        if (invoiceReceiptUpdated) {
          return { ...invoiceReceipt, receiptsValue: invoiceReceipt.receiptsValue + invoiceReceiptUpdated.value };
        }

        return invoiceReceipt;
      });

      dispatch(_getSuccess(updatedInvoiceReceipt));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
