import { useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';

import { Box, Button, Card, Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from '../../store';
import { fetchPdfById } from '../../slices/returnNotes';

import { homeTheme } from '../../theme/homeTheme';

import RenderPdf from '../../components/RenderPdf';
import Header from '../../components/PublicInvoice/Header';
import { Footer } from '../../components/homePage/Footer';
import LoadingPopUp from '../../components/LoadingPopUp';

import '../../theme/fonts.css';
import './styles.css';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    margin: '7rem auto'
  },
  cardContent: {
    alignItems: 'center',
    minHeight: '100vh',
    margin: '1rem',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)',
    overflow: 'auto',
    padding: '0',
    maxWidth: '320rem',
    zIndex: 3
  },
  downloadButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '56vw',
    height: '48px',
    margin: '0 2rem'
  }
}));

const index = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();

  const { returnNotePdf, loading, loadingError } = useSelector((state) => state).returnNotes;

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchPdfById(id));
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  if (loadingError) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>Nota de Devolução | Samba</title>
      </Helmet>
      <ThemeProvider theme={homeTheme}>
        <Header handleLogoClick={handleLogoClick} />
        {/* BODY */}
        <Container className={classes.articles}>
          <div className={classes.downloadButton}>
            <a
              href={returnNotePdf}
              download
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<CloudDownloadIcon />}
              >
                BAIXAR PDF
              </Button>
            </a>
          </div>
          <Card
            className={classes.cardContent}
          >
            <RenderPdf file={returnNotePdf} />
          </Card>
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-left.svg"
            sx={{
              position: 'absolute',
              height: 460,
              left: -34,
              top: 0,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-left.svg"
            sx={{
              position: 'absolute',
              height: 360,
              left: -82,
              top: 680,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-3-left.svg"
            sx={{
              position: 'absolute',
              width: 200,
              height: 300,
              left: -84,
              top: 940,
              opacity: 1,
              zIndex: -13
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 40,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 620,
              opacity: 1,
              zIndex: -13
            }}
          />
        </Container>
        <Box
          alt="Shape"
          sx={{ zIndex: 3 }}
        >
          <Footer
            sx={{
              zIndex: 4,
              backgroundColor: 'background.grey'
            }}
          />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default index;
