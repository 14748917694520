import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const SeriesListTable = (props) => {
  const { series, onSelectSeries, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Código
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Data Início
                </TableCell>
                <TableCell>
                  Data Fim
                </TableCell>
                <TableCell>
                  Ano Fiscal
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              id="serie-list-table-body"
            >
              {series.map((serie) => (
                <TableRow
                  hover
                  key={serie.id}
                  onClick={() => onSelectSeries(serie)}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {serie.code}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {serie.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {serie.startDate}
                  </TableCell>
                  <TableCell>
                    {serie.endDate}
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {serie.fiscalYearName}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

SeriesListTable.propTypes = {
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    fiscalYearName: PropTypes.string,
    fiscalYearDate: PropTypes.string
  }).isRequired,
  onSelectSeries: PropTypes.func.isRequired
};

export default SeriesListTable;
