import PropTypes from 'prop-types';
import Snackbar from '@mui/material/Snackbar';
import { Portal } from '@mui/material';
import Snack from '../Snack';
import loadingAnimationData from '../../lotties/loading.json';
import successAnimationData from '../../lotties/loading-passed-state.json';
import unsuccessAnimationData from '../../lotties/loading-fail-state.json';
import { TOAST_TYPE } from '../../constants';

export default function Toast(props) {
  const {
    isOpen,
    onClose,
    message,
    toastType
  } = props;

  const toastsOptions = {
    LOADING: {
      message: 'Carregando...',
      background: 'rgba(83, 83, 83, 0.1)',
      color: '#535353',
      animationData: loadingAnimationData
    },
    SUCCESS: {
      message,
      background: 'rgba(33, 200, 195, 0.1)',
      color: '#21C8C3',
      animationData: successAnimationData
    },
    FAILURE: {
      message,
      background: 'rgba(254, 0, 0, 0.1)',
      color: '#FE0000',
      animationData: unsuccessAnimationData
    }
  };

  return (
    <Portal>
      <Snackbar
        id="snackbar"
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        open={isOpen}
        onClose={onClose}
        autoHideDuration={3000}
        key="snackbar"
      >
        <Snack
          background={toastsOptions[toastType]?.background}
          color={toastsOptions[toastType]?.color}
          animationData={toastsOptions[toastType]?.animationData}
          message={toastsOptions[toastType]?.message}
        />
      </Snackbar>

    </Portal>
  );
}

Toast.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  toastType: PropTypes.oneOf(TOAST_TYPE),
  message: PropTypes.string.isRequired
};
