import { combineReducers } from '@reduxjs/toolkit';
import fiscalYear from '../slices/fiscalYear';
import clients from '../slices/clients';
import paymentPeriod from '../slices/paymentPeriod';
import taxes from '../slices/taxes';
import providers from '../slices/providers';
import countries from '../slices/countries';
import paymentMode from '../slices/paymentMode';
import series from '../slices/series';
import documentTypes from '../slices/documentTypes';
import units from '../slices/units';
import itens from '../slices/itens';
import companies from '../slices/companies';
import invoices from '../slices/invoices';
import receipts from '../slices/receipts';
import creditNotes from '../slices/creditNotes';
import debitNotes from '../slices/debitNotes';
import invoiceReceipts from '../slices/invoiceReceipts';
import proFormas from '../slices/proFormas';
import blogPosts from '../slices/blogPosts';
import currencies from '../slices/currencies';
import counties from '../slices/counties';
import purchaseInvoice from '../slices/purchaseInvoice';
import users from '../slices/users';
import bankAccounts from '../slices/bankAccounts';
import companySettings from '../slices/companySettings';
import paymentModeDefaults from '../slices/paymentModeDefaults';
import issueReasons from '../slices/issueReasons';
import returnNotes from '../slices/returnNotes';
import eFatura from '../slices/eFatura';
import sambaPayments from '../slices/sambaPayments';
import bankWithdrawals from '../slices/bankWithdrawals';
import transactions from '../slices/transactions';
import sambaConstants from '../slices/sambaConstants';
import companyConstants from '../slices/companyConstants';
import companyIntegrations from '../slices/companyIntegrations';
import ping from '../slices/ping';
import onlinePayments from '../slices/onlinePayments';
import products from '../slices/products';
import salesReceipts from '../slices/salesReceipts';
import orders from '../slices/orders';
import dashboard from '../slices/dashboard';
import onlineStores from '../slices/onlineStores';

const appReducer = combineReducers({
  fiscalYear,
  clients,
  paymentPeriod,
  taxes,
  providers,
  countries,
  paymentMode,
  series,
  documentTypes,
  units,
  itens,
  companies,
  invoices,
  receipts,
  blogPosts,
  currencies,
  counties,
  purchaseInvoice,
  users,
  bankAccounts,
  creditNotes,
  debitNotes,
  invoiceReceipts,
  proFormas,
  companySettings,
  paymentModeDefaults,
  issueReasons,
  returnNotes,
  eFatura,
  sambaPayments,
  bankWithdrawals,
  transactions,
  sambaConstants,
  companyConstants,
  companyIntegrations,
  ping,
  onlinePayments,
  salesReceipts,
  products,
  orders,
  dashboard,
  onlineStores
});

const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
