import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  providers: undefined,
  isLoading: false,
  loadingError: false,
  creating: undefined,
  creatingError: undefined,
  creatingErrorMessage: '',
  creatingSuccessMessage: ''
};

const slice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.providers = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.providers = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _create: (state) => {
      state.creating = true;
    },
    _createSuccess: (state, { payload }) => {
      if (state.providers === undefined) {
        state.providers = [];
      }
      state.providers.push(payload);
      state.creating = false;
      state.creatingError = false;
    },
    _createFailure: (state, { payload }) => {
      state.creating = false;
      state.creatingError = true;
      state.creatingErrorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.creatingSuccessMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _create,
  _createSuccess,
  _createFailure,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'providers/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/suppliers`);
      const suppliers = data.map((supplier) => ({
        address: supplier.address,
        country_id: supplier.country_id,
        fiscal_name: supplier.fiscal_name,
        id: supplier.id,
        internal: supplier.internal,
        is_individual: supplier.is_individual,
        name: supplier.name,
        tax_id: supplier.tax_id,
        phoneNumber: {
          phone_number: supplier.SupplierPhoneNumbers.length > 0 && supplier.SupplierPhoneNumbers[0].phone_number,
          country_code: supplier.SupplierPhoneNumbers.length > 0 && supplier.SupplierPhoneNumbers[0].Country.country_code
        }
      }));
      dispatch(_getSuccess(suppliers));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'providers/create',
  async (data, { dispatch }) => {
    dispatch(_create());
    try {
      const response = await axios.post(`/companies/${companyId}/suppliers`, data);
      analytics.dispatchEvent(events.supplierCreated());
      const supplierCreated = {
        address: response.data.address,
        country_id: response.data.country_id,
        fiscal_name: response.data.fiscal_name,
        id: response.data.id,
        internal: response.data.internal,
        is_individual: response.data.is_individual,
        name: response.data.name,
        tax_id: response.data.tax_id,
        phoneNumber: {
          phone_number: data.phone_numbers[0].phone_number,
          country_code: data.phone_numbers[0].country_code
        }

      };
      dispatch(_createSuccess(supplierCreated));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createFailure(errorMessage));
    }
  }
);
