import PropTypes from 'prop-types';

import {
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { Dialog } from '@mui/material';
import ClientForm from '../components/clients/ClientForm';

import SeriesForm from '../components/series/Form';
import PaymentPeriodForm from '../components/payment_period/PaymentPeriodForm';
import UnitForm from '../components/unit/UnitForm';
import ItemForm from '../components/item/Form';
import ProviderForm from '../components/provider/ProviderForm';
import { create as createItem } from '../slices/itens';
import { create as createSeries } from '../slices/series';
import { fetch as fetchCountries } from '../slices/countries';
import { create as createClient } from '../slices/clients';

import { useDispatch, useSelector } from '../store';
import FiscalYearForm from '../components/fiscal year/FiscalYearForm';
import Toast from '../components/Toast';
import { FORM, TOAST_TYPE } from '../constants';

export const FormContext = createContext();

export function useForm() {
  const context = useContext(FormContext);

  return context;
}

export const FormProvider = (props) => {
  const { children } = props;
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [form, setForm] = useState(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentFormOpen, setCurrentFormOpen] = useState(null);

  const [onRequest, setOnRequest] = useState(undefined);
  const [onRequestFailure, setOnRequestFailure] = useState(undefined);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmiting, setIsSubmiting] = useState(false);

  const dispatch = useDispatch();

  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const {
    paymentPeriod,
    series,
    paymentMode,
    clients,
    taxes,
    itens,
    fiscalYear,
    providers
  } = useSelector((state) => state);

  const { units } = useSelector((state) => state.units);
  const { countries } = useSelector((state) => state.countries);
  const { currentCompany } = useSelector((state) => state.companies);

  const closeForm = () => {
    setIsFormOpen(false);
    setOnRequest(undefined);
    setOnRequestFailure(undefined);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const handleSubmitForm = () => {
    setIsSubmiting(true);
    setIsPopupOpen(true);
  };

  const handleCreateClient = (client) => {
    dispatch(createClient(client));
    handleSubmitForm();
  };

  const handleItemFormSubmit = (item) => {
    dispatch(createItem(item));
    handleSubmitForm();
  };

  const handleSerieFormSubmit = (serie) => {
    dispatch(createSeries(serie));
    handleSubmitForm();
  };

  useEffect(() => {
    dispatch(fetchCountries());
  }, []);

  const openForm = (name) => {
    setCurrentFormOpen(name);
    setIsFormOpen(true);
    switch (name) {
      case FORM.SERIE:
        setForm(
          <SeriesForm
            onCancel={closeForm}
            onSubmitForm={handleSerieFormSubmit}
            fiscalYears={fiscalYear.fiscalYears}
            useForm={useForm}
          />
        );
        break;
      case FORM.CLIENT:
        setForm(
          <ClientForm
            countries={countries}
            onCancel={closeForm}
            onSubmitForm={handleCreateClient}
            isAdd
          />
        );
        break;
      case FORM.PAYMENT_PERIOD:
        setForm(
          <PaymentPeriodForm
            onCancel={closeForm}
            onSubmitForm={handleSubmitForm}
            isAdd
          />
        );
        break;
      case FORM.UNIT:
        setForm(
          <UnitForm
            onCancel={closeForm}
            onSubmitForm={handleSubmitForm}
            isAdd
          />
        );
        break;
      case FORM.ITEM:
        setForm(
          <ItemForm
            currentCompany={currentCompany}
            onCancel={closeForm}
            onSubmitForm={handleItemFormSubmit}
            taxes={taxes.taxes}
            units={units.units}
            isAdd
            useForm={useForm}
          />
        );
        break;
      case FORM.FISCAL_YEAR:
        setForm(
          <FiscalYearForm
            onCancel={closeForm}
            onSubmitForm={handleSubmitForm}
            isAdd
          />
        );
        break;
      case FORM.PROVIDER:
        setForm(
          <ProviderForm
            onCancel={closeForm}
            onSubmitForm={handleSubmitForm}
          />
        );
        break;
      default:
        break;
    }
  };

  const renderForm = () => (
    form
  );

  // handleUpdateStatus
  useEffect(() => {
    switch (currentFormOpen) {
      case FORM.SERIE:
        setOnRequest(series.onRequest);
        setOnRequestFailure(series.onRequestFailure);
        setSuccessMessage(series.successMessage);
        setErrorMessage(series.errorMessage);
        break;
      case FORM.CLIENT:
        setOnRequest(clients.onRequest);
        setOnRequestFailure(clients.onRequestFailure);
        setSuccessMessage(clients.successMessage);
        setErrorMessage(clients.errorMessage);
        break;
      case FORM.PAYMENT_PERIOD:
        setOnRequest(paymentPeriod.onRequest);
        setOnRequestFailure(paymentPeriod.onRequestFailure);
        setSuccessMessage(paymentPeriod.successMessage);
        setErrorMessage(paymentPeriod.errorMessage);
        break;
      case FORM.PAYMENT_MODE:
        setOnRequest(paymentMode.onRequest);
        setOnRequestFailure(paymentMode.onRequestFailure);
        setSuccessMessage(paymentMode.successMessage);
        setErrorMessage(paymentMode.errorMessage);
        break;
      case FORM.UNIT:
        setOnRequest(units.onRequest);
        setOnRequestFailure(units.onRequestFailure);
        setSuccessMessage(units.successMessage);
        setErrorMessage(units.errorMessage);
        break;
      case FORM.ITEM:
        setOnRequest(itens.onRequest);
        setOnRequestFailure(itens.onRequestFailure);
        setSuccessMessage(itens.successMessage);
        setErrorMessage(itens.errorMessage);
        break;
      case FORM.FISCAL_YEAR:
        setOnRequest(fiscalYear.onRequest);
        setOnRequestFailure(fiscalYear.onRequestFailure);
        setSuccessMessage(fiscalYear.successMessage);
        setErrorMessage(fiscalYear.errorMessage);
        break;
      case FORM.PROVIDER:
        setOnRequest(providers.creating);
        setOnRequestFailure(providers.creatingError);
        setSuccessMessage(providers.creatingSuccessMessage);
        setErrorMessage(providers.creatingErrorMessage);
        break;
      default:
        break;
    }
  }, [
    itens,
    units,
    clients,
    paymentMode,
    paymentPeriod,
    series,
    fiscalYear,
    providers,
    isSubmiting,
    currentFormOpen,
    countries
  ]);

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if (!onRequest && !onRequestFailure) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      closeForm();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <FormContext.Provider value={{
      openForm
    }}
    >
      {children}
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={closeForm}
        open={isFormOpen}
      >
        {isFormOpen && (
          renderForm()
        )}
      </Dialog>
      <Toast
        isOpen={isPopupOpen}
        toastType={toastType}
        message={message}
        onClose={closePopup}
      />
    </FormContext.Provider>
  );
};

FormProvider.propTypes = {
  children: PropTypes.node.isRequired
};
