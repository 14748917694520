import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import { Box } from '@mui/material';

import Wrapper from '../../components/Wrapper';
import H1Section from '../../components/Header/H1Section';
import EmptyList from '../../components/empty_list';
import DialogPopUp from '../../components/DialogPopUp';
import UserManagementForm from '../../components/UserManagement/UserManagementForm';
import UserManagementTable from '../../components/UserManagement/Table';
import LoadingPopUp from '../../components/LoadingPopUp';
import { assignUserRole, clearState, fetchUserTypes, fetchCompanyUsers } from '../../slices/users';
import { useDispatch, useSelector } from '../../store';
import StatusMessagePopup from '../../components/StatusMessagePopup';
import { USER_TYPES } from '../../constants';

const UserManagement = () => {
  const dispatch = useDispatch();
  const { roles, onRequestSuccess, onRequestFailure, errorMessage, companyUsers, onRequestCompanyUsers } = useSelector((state) => state).users;
  const { currentCompany } = useSelector((state) => state).companies;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleAddNewUser = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClearState = () => {
    dispatch(clearState());
    handleModalClose();
  };

  const handleStatusMessagePopUpClose = () => {
    dispatch(clearState());
  };

  const handleSubmit = (values) => {
    dispatch(assignUserRole(values));
  };

  const renderEmptyList = () => (
    <EmptyList
      title="Nāo tens nenhum utilizador"
      buttonText="Adicionar Utilizador"
      handleClick={handleAddNewUser}
    />
  );

  const renderList = () => {
    if (onRequestCompanyUsers) {
      return (<LoadingPopUp />);
    }
    if (companyUsers.length) {
      return (
        <Box sx={{ mt: 3 }}>
          <UserManagementTable
            roles={roles}
            companyUsers={companyUsers}
          />
        </Box>
      );
    }
    return renderEmptyList();
  };

  useEffect(() => {
    dispatch(fetchUserTypes());
  }, [dispatch]);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchCompanyUsers({ userType: USER_TYPES.attendee }));
    }
  }, [dispatch, currentCompany.id]);

  // Fetch all the users when assigned a new user role
  useEffect(() => {
    if (onRequestSuccess) {
      dispatch(fetchCompanyUsers({ userType: USER_TYPES.attendee }));
    }
  }, [onRequestSuccess]);

  return (
    <>
      <Helmet>
        <title>Utilizadores | Samba</title>
      </Helmet>
      <Wrapper>
        <H1Section
          title="Utilizadores"
          description="Meus Utilizadores"
          buttonTitle="Novo"
          buttonId="add-new-user-management-button"
          onClick={handleAddNewUser}
        />
        {renderList()}
      </Wrapper>

      <DialogPopUp
        onClose={handleModalClose}
        open={isModalOpen}
      >
        <UserManagementForm
          roles={roles}
          onSubmit={handleSubmit}
          onCancel={handleModalClose}
        />
      </DialogPopUp>

      <StatusMessagePopup
        isOpen={onRequestSuccess}
        handleClosePopUp={handleClearState}
        status="success"
        text="Utilizador adicionado com sucesso."
        title="Sucesso!"
      />

      <StatusMessagePopup
        isOpen={onRequestFailure}
        handleClosePopUp={handleStatusMessagePopUpClose}
        status="error"
        text={errorMessage}
        title="Erro!"
      />
    </>
  );
};

export default UserManagement;
