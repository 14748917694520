import { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Typography
} from '@mui/material';
import { RegisterJWT } from '../../components/authentication/register';
import StatusPopUpContainer from '../../components/StatusPopUpContainer';

import useAuth from '../../hooks/useAuth';

const Register = () => {
  const { platform } = useAuth();
  const [onRequest, setOnRequest] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [onRequestFailure, setOnRequestFailure] = useState(false);

  const navigate = useNavigate();

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
    setOnRequest(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
    setOnRequest(true);
  };

  const showErrorMessage = (hasError) => {
    setOnRequestFailure(hasError);
    setOnRequest(false);
  };

  const handleLoginButtonClick = useCallback(() => {
    navigate('/authentication/login');
  }, []);

  const handleBackButtonClick = useCallback(() => {
    navigate('/');
  }, []);

  return (
    <>
      <Helmet>
        <title>Registrar | Samba</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Bem-vindo!
                    <br />
                    Não perca tempo e junte-se ao Samba!
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Crie a sua conta gratuitamente para iniciar a sua jornada de faturação.
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT' && (
                  <RegisterJWT
                    handlePopUpOpen={handlePopUpOpen}
                    onBackButtonClick={handleBackButtonClick}
                    showErrorMessage={showErrorMessage}
                    handleLoginButtonClick={handleLoginButtonClick}
                  />
                )}
                <StatusPopUpContainer
                  isOpen={isPopUpOpen}
                  isLoading={onRequest}
                  hasError={onRequestFailure}
                  onClose={handlePopUpClose}
                  onErrorMessage="Erro"
                  onSuccessMessage="Guardado com sucesso!"
                  onLoadingMessage="Carregando..."
                />
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Register;
