/**
 * Calculates the deducted fee based on the given fee percentage.
 * @param {number} fee - The fee percentage (expressed as a decimal).
 * @returns {number} The deducted fee.
 */
const calculateDeductedFee = (fee) => {
  const deductedFee = fee / (1 - fee);
  return deductedFee;
};

export default calculateDeductedFee;
