import React from 'react';
import PropsTypes from 'prop-types';
import { Box, Button } from '@mui/material';

export default function BorderButton(props) {
  const { id, title, variant, type, onClick } = props;

  return (
    <Box
      sx={{
        shadowColor: 'rgb(21, 70, 160)',
        shadowOffset: { width: 2, height: 8 },
        shadowOpacity: 0.04,
        shadowRadius: 16,
        borderRadius: 12
      }}
    >
      <Button
        id={id}
        type={type}
        variant={variant}
        onClick={onClick}
      >
        {title}
      </Button>
    </Box>
  );
}

BorderButton.defaultProps = {
  id: null
};

BorderButton.propTypes = {
  id: PropsTypes.string,
  title: PropsTypes.string.isRequired,
  variant: PropsTypes.oneOf(['contained', 'outlined']).isRequired,
  type: PropsTypes.oneOf(['submit', 'reset', 'button']).isRequired,
  onClick: PropsTypes.func.isRequired
};
