import { COMPANY_TYPES } from '../constants';

const renderTaxOrRetentionLabels = (companyType) => {
  const isREMPE = companyType === COMPANY_TYPES[0].name;

  return {
    taxTypeLabel: isREMPE ? 'Retenção' : 'Imposto',
    typeLabel: isREMPE ? 'Tipo de Retenção' : 'Tipo de Imposto',
    typeRequiredLabel: isREMPE ? 'Tipo de Retenção*' : 'Tipo de Imposto*',
    valueOfTaxLabel: isREMPE ? 'Valor da Retenção' : 'Valor do Imposto',
    valueWithTaxLabel: isREMPE ? 'Valor com Retenção' : 'Valor com Imposto',
    valueWithoutTaxLabel: isREMPE ? 'Valor sem Retenção' : 'Valor sem Imposto'
  };
};

export { renderTaxOrRetentionLabels };
