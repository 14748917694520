import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';

let companyId;

const initialState = {
  url: null,
  isLoading: false,
  error: null,
  retrySendDocumentSuccess: null,
  errorMessage: null
};

const slice = createSlice({
  name: 'eFatura',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.url = payload.url;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state, { payload }) => {
      state.url = null;
      state.isLoading = false;
      state.error = true;
      state.errorMessage = payload.errorMessage;
    },
    _retrySendDocument: (state) => {
      state.isLoading = true;
      state.retrySendDocumentSuccess = null;
    },
    _retrySendDocumentSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
      state.retrySendDocumentSuccess = true;
    },
    _retrySendDocumentFailed: (state) => {
      state.isLoading = false;
      state.error = true;
      state.retrySendDocumentSuccess = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _retrySendDocumentSuccess,
  _retrySendDocumentFailed,
  _retrySendDocument
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'eFatura/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/integrations/efatura/companies/${companyId}/auth`);

      dispatch(_getSuccess(data));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_getFailure({ errorMessage }));
    }
  }
);

export const retrySendDocument = createAsyncThunk(
  'eFatura/retrySendDocument',
  async (documentInfo, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_retrySendDocument());
    try {
      const { id, documentType } = documentInfo;
      const data = { document_id: id, document_type: documentType };

      await axios.post(`/companies/${companyId}/efatura/documents/process`, data);

      dispatch(_retrySendDocumentSuccess());
    } catch (error) {
      dispatch(_retrySendDocumentFailed());
    }
  }
);
