import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';
import { CAPE_VERDE_COUNTRY_CODE } from '../constants';
import calculateTotalBalance from '../util/calculateTotalBalances';

const initialState = {
  companies: undefined,
  currentCompany: {},
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'companies',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.companies = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.companies === undefined) {
        state.companies = [];
      }
      state.companies.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setCompany: (state, { payload }) => {
      state.isLoading = false;
      state.error = false;
      state.currentCompany = payload;
      state.companies = state.companies.filter((company) => company.id !== payload.id);
      state.companies.unshift(payload);
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage,
  _setCompany
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'companies/fetch',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const { data } = await axios.get('companies');
      const companies = data.map((company) => ({
        id: company.id,
        name: company.name,
        fiscalName: company.fiscal_name,
        countryId: company.country.id,
        countryName: company.country.pt,
        email: company.email,
        companyType: company.company_type,
        accountantTaxId: company.accountant_tax_id,
        currencyId: company.currency_id,
        financeDepartmentCode: company.finance_department_code,
        financeDepartmentCountyId: company.finance_department_county_id,
        financeDepartmentTaxId: company.finance_department_tax_id,
        taxId: company.tax_id,
        financeDepartmentName: company.finance_department_name,
        address: company.address,
        phoneNumber: company.phone_number,
        integrations: {
          eFatura: {
            status: company.integrations.e_fatura.status
          }
        }
      }));

      dispatch(_getSuccess(companies));
      const storedCompany = localStorage.getItem('currentCompany');
      let currentCompany = companies[0];

      if (storedCompany) {
        currentCompany = companies.find((company) => company.id === storedCompany) || companies[0];
      } else {
        localStorage.setItem('currentCompany', currentCompany.id);
      }

      dispatch(_setCompany(currentCompany));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const fetchById = createAsyncThunk(
  'companies/fetch_by_id',
  async (_, { dispatch, getState }) => {
    const companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`companies/${companyId}`);

      const currentCompany = {
        id: data.id,
        name: data.name,
        fiscalName: data.fiscal_name,
        countryId: data.countryId,
        countryName: data.country.pt,
        email: data.email,
        companyType: data.company_type,
        accountantTaxId: data.accountant_tax_id,
        currencyId: data.currency_id,
        financeDepartmentCode: data.finance_department_code,
        financeDepartmentCountyId: data.finance_department_county_id,
        financeDepartmentTaxId: data.finance_department_tax_id,
        taxId: data.tax_id,
        financeDepartmentName: data.finance_department_name,
        address: data.address,
        phoneNumber: data.phone_number,
        integrations: {
          e_fatura: {
            status: data.integrations.e_fatura.status
          }
        },
        balances: data.balances.map((balance) => ({
          balanceId: balance.balance_id,
          value: balance.value
        })),
        totalBalance: calculateTotalBalance(data.balances)
      };

      dispatch(_setCompany(currentCompany));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'companies/create',
  async (data, { getState, dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const company = {
        name: data.name,
        fiscal_name: data.fiscalName,
        tax_id: data.nif,
        email: data.email,
        currency_id: data.currency,
        company_type: data.companyType,
        accountant_tax_id: data.accountantTaxId === '' ? undefined : data.accountantTaxId,
        finance_department_code: data.county === '' ? undefined : data.county,
        finance_department_county_id: data.countyId === '' ? undefined : data.countyId,
        finance_department_tax_id: data.countyTaxId === '' ? undefined : data.countyTaxId,
        bank_account: (
          (data.bankName && data.abbreviation && data.accountNumber
            && data.nib && data.swift) ? ({
              bank_name: data.bankName,
              abbreviation: data.abbreviation,
              account_number: data.accountNumber,
              nib: data.nib,
              iban: data.iban,
              swift: data.swift,
              currency_id: data.currency
            }) : undefined),
        address: data.address,
        phone_numbers: [
          {
            phone_number: data.phoneNumber,
            country_code: CAPE_VERDE_COUNTRY_CODE
          }
        ]
      };

      const response = await axios.post('companies', company);

      const { countries } = getState().countries;
      const { counties } = getState().counties;

      const getCounty = counties.find((county) => county.id === data.countyId);
      const getCountry = countries.find((country) => country.id === getCounty.country_id);

      const newCompany = {
        id: response.data.id,
        name: data.name,
        fiscalName: data.fiscalName,
        countryId: getCountry.id,
        countryName: getCountry.pt,
        email: data.email,
        companyType: data.companyType,
        accountantTaxId: data.accountantTaxId,
        currencyId: data.currency,
        financeDepartmentCode: getCounty.code,
        financeDepartmentCountyId: getCounty.id,
        financeDepartmentTaxId: data.countyTaxId,
        financeDepartmentName: getCounty.name,
        taxId: data.nif,
        address: data.address,
        phoneNumber: data.phoneNumber,
        integrations: {
          eFatura: {
            status: response?.integrations?.e_fatura.status
          }
        }
      };

      localStorage.setItem('currentCompany', newCompany.id);

      analytics.dispatchEvent(events.companyCreated());

      dispatch(_createOrUpdatingSuccess(newCompany));
      dispatch(_setCompany(newCompany));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const set = createAsyncThunk(
  'company/set',
  async (data, { dispatch }) => {
    try {
      dispatch(_setCompany(data));
      localStorage.setItem('currentCompany', data.id);
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
