import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import FiscalYearListTable from '../../components/fiscal year/FiscalYearListTable';
import FiscalYearForm from '../../components/fiscal year/FiscalYearForm';

import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';

import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';

import { useDispatch, useSelector } from '../../store';
import { fetch as fetchFiscalYears, remove as deleteFiscalYear } from '../../slices/fiscalYear';
import FiscalYearViewDetails from '../../components/fiscal year/FiscalYearViewDetails';
import DeletePopUp from '../../components/DeletePopUp';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const FiscalYear = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isViewFiscalYearModalOpen, setIsViewFiscalYearModalOpen] = useState(false);
  const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);

  const { settings } = useSettings();
  const dispatch = useDispatch();

  const { fiscalYears, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).fiscalYear;
  const { currentCompany } = useSelector((state) => state).companies;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchFiscalYears());
    }
  }, [dispatch, currentCompany]);

  const handleUpdateSelectedFiscalYear = () => {
    if (selectedFiscalYear) {
      const updatedFiscalYear = fiscalYears.find((fiscalYear) => fiscalYear.id === selectedFiscalYear?.id);
      setSelectedFiscalYear(updatedFiscalYear);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
    handleUpdateSelectedFiscalYear();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleFiscalYearDetailOpen = (fiscalYear) => {
    setSelectedFiscalYear(fiscalYear);
    setIsViewFiscalYearModalOpen(true);
  };

  const handleFiscalYearDetailClose = () => {
    setSelectedFiscalYear([]);
    setIsViewFiscalYearModalOpen(false);
  };
  const handleFiscalYearDelete = () => {
    handleFiscalYearDetailClose();
    handleDeletePopUpClose();
    handlePopUpOpen();
    dispatch(deleteFiscalYear(selectedFiscalYear));
  };

  function returnResult() {
    if (fiscalYears && fiscalYears.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <FiscalYearListTable
            fiscalYears={fiscalYears}
            onOpenFiscalYearDetail={handleFiscalYearDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Ano Fiscal"
        buttonText="Criar Ano Fiscal"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Ano Fiscal | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Ano Fiscal
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Lista Ano Fiscal
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
              <FiscalYearForm
                onCancel={handleModalClose}
                onSubmitForm={handlePopUpOpen}
                selectedFiscalYear={selectedFiscalYear}
                isAdd={!isViewFiscalYearModalOpen}
              />
            )}
          </Dialog>
        </Container>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleFiscalYearDetailClose}
          open={isViewFiscalYearModalOpen}
        >
          {isViewFiscalYearModalOpen && (
          <FiscalYearViewDetails
            fiscalYear={selectedFiscalYear}
            onEdit={handleNewClick}
            onDelete={handleDeletePopUpOpen}
            onCancel={handleFiscalYearDetailClose}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            onCancel={handleDeletePopUpClose}
            onDelete={handleFiscalYearDelete}
          />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default FiscalYear;
