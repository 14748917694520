import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { addDays, format, parseISO } from 'date-fns';
import useSettings from '../../hooks/useSettings';
import PurchaseInvoiceListTable from '../../components/purchaseInvoices/Table';
import PurchaseInvoiceForm from '../../components/purchaseInvoices/Form';
import Toast from '../../components/Toast';

import { fetch as fetchPurchaseInvoices, create as createPurchaseInvoice, update as updatePurchaseInvoice, remove as deletePurchaseInvoice } from '../../slices/purchaseInvoice';
import { fetch as fetchProviders } from '../../slices/providers';
import { fetch as fetchDocumentTypes } from '../../slices/documentTypes';
import { fetch as fetchPaymentPeriod } from '../../slices/paymentPeriod';
import { fetch as fetchPaymentMode } from '../../slices/paymentMode';
import { fetch as fetchItems } from '../../slices/itens';
import { fetch as fetchUnit } from '../../slices/units';
import { fetch as fetchTaxes } from '../../slices/taxes';
import { fetch as fetchFiscalYears } from '../../slices/fiscalYear';

import { useDispatch, useSelector } from '../../store';
import { useForm } from '../../hooks/useForm';

import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';

import PurchaseViewDetails from '../../components/purchaseInvoices/ViewDetails';
import Scrollbar from '../../components/Scrollbar';
import DeletePopUp from '../../components/DeletePopUp';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const PurchaseInvoice = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isViewPurchaseInvoiceModalOpen, setIsViewPurchaseInvoiceModalOpen] = useState(false);
  const [selectedPurchaseInvoice, setSelectedPurchaseInvoice] = useState(null);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);

  const { settings } = useSettings();

  const dispatch = useDispatch();
  const { purchaseInvoices, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).purchaseInvoice;
  const { currentCompany } = useSelector((state) => state).companies;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const { documentTypes } = useSelector((state) => state).documentTypes;
  const { paymentPeriods } = useSelector((state) => state).paymentPeriod;
  const { paymentModes } = useSelector((state) => state).paymentMode;
  const { providers } = useSelector((state) => state).providers;
  const { units } = useSelector((state) => state).units;
  const { taxes } = useSelector((state) => state).taxes;
  const { itens } = useSelector((state) => state).itens;

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchPurchaseInvoices());
    }
  }, [dispatch, currentCompany]);

  const handleModalClose = () => {
    setIsAddMode(false);
  };

  const handleUpdateSelectedPurchaseInvoice = () => {
    if (selectedPurchaseInvoice) {
      const updatedPurchaseInvoice = purchaseInvoices.find((item) => item.id === selectedPurchaseInvoice?.id);
      setSelectedPurchaseInvoice(updatedPurchaseInvoice);
    }
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedPurchaseInvoice();
    setIsPopUpOpen(false);
  };

  const handleCreateOrEditPurchaseInvoice = (purchaseInvoice) => {
    handlePopUpOpen();
    if (selectedPurchaseInvoice) {
      dispatch(updatePurchaseInvoice({ ...purchaseInvoice, id: selectedPurchaseInvoice.id }));
      setIsViewPurchaseInvoiceModalOpen(true);
    } else {
      dispatch(createPurchaseInvoice(purchaseInvoice));
    }
  };

  const getPaymentPeriodRange = (values) => {
    if (paymentPeriods) {
      return format(addDays(values?.date, paymentPeriods.find((paymentPeriod) => paymentPeriod?.id === values?.paymentPeriod)?.numberOfDay || 0), 'dd/MM/yyyy');
    }
    return format(values?.date, 'dd/MM/yyyy');
  };

  const handlePurchaseInvoiceDetailOpen = (purchaseInvoice) => {
    setSelectedPurchaseInvoice(purchaseInvoice);
    setIsViewPurchaseInvoiceModalOpen(true);
  };

  const handlePurchaseInvoiceDetailClose = () => {
    setIsViewPurchaseInvoiceModalOpen(false);
    setSelectedPurchaseInvoice(null);
  };

  const handleNewClick = () => {
    setIsAddMode(true);
    setIsViewPurchaseInvoiceModalOpen(false);
    dispatch(fetchProviders());
    dispatch(fetchDocumentTypes());
    dispatch(fetchPaymentPeriod());
    dispatch(fetchPaymentMode());
    dispatch(fetchItems());
    dispatch(fetchUnit());
    dispatch(fetchTaxes());
    dispatch(fetchFiscalYears());
  };

  const returnResult = () => {
    if (purchaseInvoices && purchaseInvoices.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <PurchaseInvoiceListTable
            purchaseInvoices={purchaseInvoices}
            onOpenPurchaseInvoiceDetails={handlePurchaseInvoiceDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma despesa"
        buttonText="Criar Despesa"
        handleClick={handleNewClick}
      />
    );
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleDeletePurchaseInvoice = () => {
    handlePurchaseInvoiceDetailClose();
    handleDeletePopUpClose();
    handlePopUpOpen();
    dispatch(deletePurchaseInvoice(selectedPurchaseInvoice));
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  return (
    <>
      <Helmet>
        <title>Faturas | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Despesas
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                {!isAddMode ? 'Minhas Despesas' : 'Nova Despesas'}
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }

          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <PurchaseInvoiceForm
              currentCompany={currentCompany}
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreateOrEditPurchaseInvoice}
              useForm={useForm}
              getPaymentPeriodRange={getPaymentPeriodRange}
              purchaseInvoice={selectedPurchaseInvoice}
              providers={providers}
              paymentPeriods={paymentPeriods}
              documentTypes={documentTypes}
              paymentModes={paymentModes}
              units={units}
              taxes={taxes}
              itens={itens}
            />
            )}
          </Box>
        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handlePurchaseInvoiceDetailClose}
          open={isViewPurchaseInvoiceModalOpen}
        >
          <Scrollbar>
            {isViewPurchaseInvoiceModalOpen && (
            <PurchaseViewDetails
              currentCompany={currentCompany}
              purchaseInvoice={{ ...selectedPurchaseInvoice, dateFormatted: format(parseISO(selectedPurchaseInvoice.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedPurchaseInvoice.date), selectedPurchaseInvoice.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
              onEdit={handleNewClick}
              onDelete={handleDeletePopUpOpen}
              onCancel={handlePurchaseInvoiceDetailClose}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            onCancel={handleDeletePopUpClose}
            onDelete={handleDeletePurchaseInvoice}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default PurchaseInvoice;
