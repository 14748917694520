import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Drawer, Hidden } from '@mui/material';

import {
  Store as StoreIcon,
  MonetizationOn as MonetizationOnIcon,
  Settings as SettingsIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon
} from '@mui/icons-material';

import Logo from '../Logo';
import Scrollbar from '../Scrollbar';
import NavSection from '../NavSection';
import Database from '../../icons/Database';

const sections = [
  {
    title: 'Dashboard',
    icon: <TrendingUpIcon />,
    items: [
      {
        title: 'Resumo',
        path: '/dashboard/resumo'
      }
    ]
  },
  {
    title: 'Vendas',
    icon: <TrendingUpIcon />,
    items: [
      {
        title: 'Clientes',
        path: '/geral/clientes'
      },
      {
        aditionalSection: [
          {
            title: 'Faturas',
            path: '/faturacao/faturas'
          },
          {
            title: 'Recibos',
            path: '/faturacao/recibos'
          },
          {
            title: 'Fatura/Recibo',
            path: '/faturacao/fatura_recibo'
          },
          {
            title: 'Nota de Débito',
            path: '/faturacao/nota_de_debito'
          },
          {
            title: 'Nota de Crédito',
            path: '/faturacao/nota_de_credito'
          },
          {
            title: 'Pró-Forma',
            path: '/faturacao/pro_forma'
          },
          {
            title: 'Nota de Devolução',
            path: '/faturacao/nota_de_devolucao'
          },
          {
            title: 'Talão de Venda',
            path: '/faturacao/talao_de_venda'
          }
        ],
        aditionalSectionTitle: 'Documentos'
      }
    ]
  },
  {
    title: 'Compras',
    icon: <AssignmentIcon />,
    items: [
      {
        title: 'Fornecedores',
        path: '/geral/fornecedores'
      },
      {
        title: 'Despesas',
        path: '/compras/despesas'
      }
    ]
  },
  {
    title: 'Itens',
    icon: <Database />,
    items: [
      {
        title: 'Meus Itens',
        path: '/faturacao/itens'
      }
    ]
  },
  {
    icon: <StoreIcon />,
    title: 'E-commerce',
    items: [
      {
        title: 'Pedidos',
        path: '/e-commerce/pedidos'
      },
      {
        title: 'Produtos',
        path: '/e-commerce/produtos'
      }
    ]
  },
  {
    title: 'Pagamentos',
    icon: <MonetizationOnIcon />,
    items: [
      {
        title: 'Pagamentos via Samba',
        path: '/faturacao/pagamentos_via_samba'
      },
      {
        aditionalSection: [
          {
            title: 'Transações',
            path: '/ping/transacoes'
          },
          {
            title: 'Levantamento Bancário',
            path: '/ping/levantamento_bancario'
          }
        ],
        aditionalSectionTitle: 'Samba POS'
      }
    ]
  },
  {
    title: 'Configuração',
    icon: <SettingsIcon />,
    items: [
      {
        aditionalSection: [
          {
            title: 'Ano Fiscal',
            path: '/geral/ano_fiscal'
          },
          {
            title: 'Séries',
            path: '/faturacao/series'
          },
          {
            title: 'Unidade',
            path: '/configuracao/unidade'
          },
          {
            title: 'Modo de Pagamento',
            path: '/configuracao/modo_de_pagamento'
          },
          {
            title: 'Período de Pagamento',
            path: '/configuracao/periodo_de_pagamento'
          },
          {
            title: 'Conta Bancária',
            path: '/configuracao/conta_bancaria'
          }
        ],
        aditionalSectionTitle: 'Geral'
      },
      {
        aditionalSection: [
          {
            title: 'Minha Loja',
            path: '/configuracao/e-commerce/minha_loja'
          }
        ],
        aditionalSectionTitle: 'E-Commerce'
      },
      {
        aditionalSection: [
          {
            title: 'Utilizadores',
            path: '/configuracao/utilizadores'
          }
        ],
        aditionalSectionTitle: 'Samba POS'
      },
      {
        aditionalSection: [
          {
            title: 'Minhas Integrações',
            path: '/integracoes/minhas_integracoes'
          }
        ],
        aditionalSectionTitle: 'Integrações'
      }
    ]
  }
];

const DashboardSidebar = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              p: 1
            }}
          >
            <RouterLink to="/">
              <Logo
                sx={{
                  height: 100,
                  width: 100
                }}
              />
            </RouterLink>
          </Box>
        </Hidden>
        <Box sx={{ p: 2 }}>
          {sections.map((section, index) => (
            <NavSection
              key={index}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              width: 280
            }
          }}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          PaperProps={{
            sx: {
              backgroundColor: 'background.paper',
              height: 'calc(100% - 64px) !important',
              top: '64px !Important',
              width: 280
            }
          }}
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
