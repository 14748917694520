import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography
} from '@mui/material';

const BankAccountForm = (props) => {
  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors
  } = props;

  return (
    <>
      <Box>
        <Box sx={{ mb: 2 }}>
          <Typography color="primary">Informações Bancárias</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            autoFocus
            error={Boolean(touched.bankName && errors.bankName)}
            fullWidth
            helperText={touched.bankName && errors.bankName}
            label="Banco"
            name="bankName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.bankName}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.abbreviation && errors.abbreviation)}
            fullWidth
            helperText={touched.abbreviation && errors.abbreviation}
            label="Abreviação"
            name="abbreviation"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.abbreviation}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.accountNumber && errors.accountNumber)}
            fullWidth
            helperText={touched.accountNumber && errors.accountNumber}
            label="Número de Conta"
            name="accountNumber"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.accountNumber}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.nib && errors.nib)}
            fullWidth
            helperText={touched.nib && errors.nib}
            label="NIB"
            name="nib"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.nib}
            variant="outlined"
          />
          <TextField
            id="iban-text-field"
            error={Boolean(touched.iban && errors.iban)}
            fullWidth
            helperText={touched.iban && errors.iban}
            label="IBAN*"
            name="iban"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.iban}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.swift && errors.swift)}
            fullWidth
            helperText={touched.swift && errors.swift}
            label="SWIFT"
            name="swift"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.swift}
            variant="outlined"
          />
        </Box>
      </Box>
    </>
  );
};

BankAccountForm.propTypes = {
  values: PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    bankName: PropTypes.string,
    abbreviation: PropTypes.string,
    accountNumber: PropTypes.string,
    nib: PropTypes.string,
    iban: PropTypes.string,
    swift: PropTypes.string
  }).isRequired,
  touched: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    bankName: PropTypes.string,
    abbreviation: PropTypes.string,
    accountNumber: PropTypes.string,
    nib: PropTypes.string,
    iban: PropTypes.string,
    swift: PropTypes.string
  }).isRequired,
  errors: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string,
    bankName: PropTypes.string,
    abbreviation: PropTypes.string,
    accountNumber: PropTypes.string,
    nib: PropTypes.string,
    iban: PropTypes.string,
    swift: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default BankAccountForm;
