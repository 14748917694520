import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';

import Scrollbar from '../../Scrollbar';
import { formatCurrency } from '../../../util/formatCurrency';
import { formattedDate } from '../../../util/formattedDate';
import { getOrderStatus } from '../../../util/getOrderStatus';

const OrderTable = (props) => {
  const { id, orders, onOpenDetails, ...other } = props;

  return (
    <Card
      id={id}
      {...other}
    >
      <Scrollbar>
        <Box>
          <Table
            id="orders-list"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Código
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Data
                </TableCell>
                <TableCell>
                  Estado de Pedido
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {orders.map((order, index) => (
                <TableRow
                  id={`order-list-element-${index}`}
                  onClick={() => onOpenDetails(order)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>
                    {order.id}
                  </TableCell>
                  <TableCell>
                    {`${order.client.firstName} ${order.client.lastName}` }
                  </TableCell>
                  <TableCell>
                    {formattedDate(order.date)}
                  </TableCell>
                  <TableCell id={`order-${index + 1}-order-status`}>
                    <Chip
                      size="medium"
                      label={getOrderStatus(
                        order.orderStatus
                      )?.label}
                      style={{
                        padding: '14px',
                        color: `${
                          getOrderStatus(
                            order.orderStatus
                          )?.color
                        }`,
                        backgroundColor: `${
                          getOrderStatus(
                            order.orderStatus
                          )?.backgroundColor
                        }`
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    {formatCurrency(Number(order.total))}
                    $
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

OrderTable.propTypes = {
  id: PropTypes.string.isRequired,
  orders: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    receiptNumber: PropTypes.string.isRequired,
    totalPaid: PropTypes.number.isRequired,
    feeValue: PropTypes.number.isRequired,
    totalReceivable: PropTypes.number.isRequired,
    paymentDate: PropTypes.string.isRequired,
    paymentService: PropTypes.string.isRequired,
    debtStatus: PropTypes.string.isRequired
  }).isRequired,
  onOpenDetails: PropTypes.func.isRequired
};

export default OrderTable;
