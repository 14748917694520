import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Chip
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, InfoOutlined as InfoIcon } from '@mui/icons-material';

import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';
import {
  eFaturasStatus,
  isDocumentEfaturaStatusFailed as isReturnNoteEfaturaStatusFailed,
  getDocumentEfaturaStatusButton as getReturnNoteEfaturaStatusButton
} from '../../util/eFaturasStatus';

const ReturnNoteListTable = (props) => {
  const {
    draftsStorage,
    deleteDraft,
    setSelectedDraft,
    selectedDraft,
    onClick,
    returnNotes,
    onOpenDetails,
    onView,
    onDuplicate,
    handleRetrySendDocument,
    retrySendReturnNoteSuccess,
    ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedReturnNote, setSelectedReturnNote] = useState(null);
  const [anchorDraft, setAnchorDraft] = useState(null);
  const [returnNoteRetried, setReturnNoteRetried] = useState(null);
  const [returnNotesRetried, setReturnNotesRetried] = useState([]);

  const handleClick = (event, returnNote) => {
    setAnchorEl(event.currentTarget);
    setSelectedReturnNote(returnNote);
  };

  const handleDraftClick = (event, draft) => {
    setAnchorDraft(event.currentTarget);
    setSelectedDraft(draft);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDraftClose = () => {
    setAnchorDraft(null);
    setSelectedDraft(null);
  };

  const returnNoteStatus = (status) => {
    switch (status) {
      case 'liquidated':
        return { label: 'Liquidado', color: '#21C8C3', backgroundColor: 'rgba(33, 200, 195, 0.1)' };
      case 'overdue':
        return { label: 'Em Atraso', color: '#FF5E52', backgroundColor: 'rgba(255, 94, 82, 0.1)' };
      default:
        return { label: 'Pendente', color: '#F1B37A', backgroundColor: 'rgba(241, 179, 122, 0.1)' };
    }
  };

  const keys = [];

  const getDraftsKeys = () => {
    draftsStorage.forEach((draft) => {
      keys.push(Object.keys(draft));
    });
  };

  const calculateTotal = (totals) => {
    let sum = 0;
    totals.forEach((item) => sum += item.total);
    return sum;
  };

  useEffect(() => {
    if (retrySendReturnNoteSuccess) {
      setReturnNotesRetried([...returnNotesRetried, returnNoteRetried]);
    }
  }, [retrySendReturnNoteSuccess, returnNoteRetried]);

  const handleOpenReturnNoteDetails = (returnNote) => {
    if (!returnNote.isVoided) {
      const isRetried = returnNotesRetried.includes(returnNote.id);
      onOpenDetails({
        ...returnNote,
        isRetried
      });
    }
  };

  return (

    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Estado E-Fatura
                </TableCell>
                <TableCell>
                  Número
                </TableCell>
                <TableCell>
                  Série
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              id="return-note-list-table-body"
            >
              {draftsStorage && getDraftsKeys()}
              {draftsStorage?.map((draft, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={(e) => handleDraftClick(e, draft)}
                  />
                  <TableRow
                    key={[keys[index]]}
                    hover
                    onClick={() => onClick(draft?.[keys[index]], keys[index])}
                  >
                    <TableCell
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {draft?.[keys[index]].clientName}
                    </TableCell>
                    <TableCell>
                      Rascunho
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].numberOfDocument}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].serieCode}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].items ? formatCurrency(calculateTotal(draft[keys[index]].items)) : null}
                      $
                    </TableCell>
                  </TableRow>

                </>
              ))}
              {returnNotes?.map((returnNote, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={!returnNote.isVoided ? ((e) => handleClick(e, returnNote)) : (() => null)}
                  />
                  <TableRow
                    hover={!returnNote.isVoided}
                    key={returnNote.id}
                    variant={!returnNote.isVoided ? '' : 'disabled'}
                    onClick={() => handleOpenReturnNoteDetails(returnNote)}
                  >
                    <TableCell
                      id={`return-note-client-name-${index}`}
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {returnNote.clientName}
                    </TableCell>
                    <TableCell variant="disabled">
                      {returnNote.paymentStatus && (
                      <Chip
                        size="medium"
                        style={{ color: `${returnNoteStatus(returnNote.paymentStatus).color}`, padding: '14px', backgroundColor: `${returnNoteStatus(returnNote.paymentStatus).backgroundColor}` }}
                        label={returnNoteStatus(returnNote.paymentStatus).label}
                      />
                      )}
                    </TableCell>
                    <TableCell
                      variant="disabled"
                      id="table-cell-e-fatura-column"
                    >
                      {returnNote.efaturaData && (
                        <Chip
                          id={`status-e-fatura-button-${index}`}
                          size="medium"
                          style={{
                            padding: isReturnNoteEfaturaStatusFailed(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status)) ? '10.2px' : '14px',
                            color: `${eFaturasStatus(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status)).color}`,
                            backgroundColor: `${eFaturasStatus(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status)).backgroundColor}`
                          }}
                          icon={
                            isReturnNoteEfaturaStatusFailed(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status)) && (
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <InfoIcon
                                  size="small"
                                  sx={{ color: '#FF5E52' }}
                                />
                              </Box>
                            )
                          }
                          label={eFaturasStatus(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status)).label}
                          clickable={isReturnNoteEfaturaStatusFailed(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status))}
                          onClick={(e) => {
                            if (isReturnNoteEfaturaStatusFailed(getReturnNoteEfaturaStatusButton(returnNotesRetried, returnNote.id, returnNote.efaturaData.status))) {
                              e.stopPropagation();
                              handleRetrySendDocument(returnNote.id);
                              setReturnNoteRetried(returnNote.id);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell variant="disabled">
                      {returnNote.number}
                    </TableCell>
                    <TableCell variant="disabled">
                      {returnNote.serieCode}
                    </TableCell>
                    <TableCell variant="disabled">
                      {formatCurrency(returnNote.totalGeneral)}
                      $
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorDraft}
        keepMounted
        open={Boolean(anchorDraft)}
        onClose={handleDraftClose}
      >
        <MenuItem
          onClick={() => {
            deleteDraft(Object.keys(selectedDraft));
            handleDraftClose();
          }}
        >
          Eliminar
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onView(selectedReturnNote);
            handleClose();
          }}
        >
          Ver
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDuplicate(selectedReturnNote);
            handleClose();
          }}
        >
          Duplicar
        </MenuItem>
      </Menu>
    </Card>
  );
};

ReturnNoteListTable.propTypes = {
  returnNotes: PropTypes.arrayOf({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientName: PropTypes.string,
    serieCode: PropTypes.string,
    paymentPeriod: PropTypes.string,
    isVoided: PropTypes.bool,
    paymentMode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    valueWithoutTax: PropTypes.number,
    totalTax: PropTypes.number,
    valueWithTax: PropTypes.number,
    discount: PropTypes.number,
    totalGeneral: PropTypes.number,
    number: PropTypes.number,
    efaturaData: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string
    })
  }).isRequired,
  draftsStorage: PropTypes.object.isRequired,
  deleteDraft: PropTypes.func.isRequired,
  selectedDraft: PropTypes.object.isRequired,
  setSelectedDraft: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onOpenDetails: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  handleRetrySendDocument: PropTypes.func.isRequired,
  retrySendReturnNoteSuccess: PropTypes.bool.isRequired
};

export default ReturnNoteListTable;
