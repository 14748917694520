import { clone } from 'lodash';

const modifyOldLocalStorage = (storage, document, setDocumentStorage) => {
  const documentStorage = {};
  const invoicesDrafts = clone(storage.invoices);
  const documentDrafts = invoicesDrafts?.map((company) => {
    const companyKeys = Object.keys(company);
    if (company[companyKeys[0]].length !== 0) {
      const newCompany = {};
      newCompany[companyKeys[0]] = company[companyKeys[0]].map((invoice) => {
        const documentKeys = Object.keys(invoice);
        if (invoice[documentKeys[0]].invoiceItems?.length) {
          const { invoiceItems: items, ...rest } = invoice[documentKeys[0]];
          const newDocument = {};
          newDocument[documentKeys[0]] = { items, ...rest };
          return newDocument;
        }
        return invoice;
      });
      return newCompany;
    }
    return company[companyKeys[0]];
  });
  documentStorage[document] = documentDrafts;
  setDocumentStorage(documentStorage);
};

export default modifyOldLocalStorage;
