import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  returnNotes: null,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  returnNotePdf: null
};

const slice = createSlice({
  name: 'returnNotes',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.returnNotes = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.returnNotes = null;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.returnNotes === null) {
        state.returnNotes = [];
      }
      state.returnNotes.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendReturnNotesEmail: (state) => {
      state.onRequest = true;
    },
    _sendReturnNotesEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendReturnNotesEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setReturnNotesPdf: (state, { payload }) => {
      state.returnNotePdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _setSuccessMessage,
  _sendReturnNotesEmail,
  _sendReturnNotesEmailFailure,
  _sendReturnNotesEmailSuccess,
  _setReturnNotesPdf
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'returnNote/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/return_notes`);

      const returnNotes = data.map((returnNote) => ({
        id: returnNote.id,
        documentType: returnNote.document_type ? {
          id: returnNote.document_type.id,
          code: returnNote.document_type.code,
          name: returnNote.document_type.name
        } : {},
        clientName: returnNote.customer.name,
        clientId: returnNote.customer.id,
        issueReason: returnNote.issue_reason ? {
          id: returnNote.issue_reason.id,
          code: returnNote.issue_reason.code,
          description: returnNote.issue_reason.description
        } : {},
        otherIssueReason: returnNote.issue_reason_motive,
        serieCode: returnNote.serial_number.code,
        paymentPeriod: {
          id: returnNote.payment_period.id,
          code: returnNote.payment_period.code,
          name: returnNote.payment_period.name,
          numberOfDay: returnNote.payment_period.number_of_day
        },
        paymentMode: {
          id: returnNote.payment_mode.id,
          code: returnNote.payment_mode.code,
          name: returnNote.payment_mode.name
        },
        efaturaData: {
          id: returnNote.efatura_data.id,
          status: returnNote.efatura_data.status,
          errorCode: returnNote.efatura_data.error_code,
          errorMessage: returnNote.efatura_data.error_message
        },
        date: returnNote.date,
        serie: returnNote.serial_number.id,
        serieName: returnNote.serial_number.name,
        commentary: returnNote.commentary,
        valueWithoutTax: returnNote.value_without_tax,
        totalTax: returnNote.value_of_tax,
        valueWithTax: returnNote.value_with_tax,
        isVoided: returnNote.is_voided,
        discount: returnNote.discount,
        totalGeneral: returnNote.total_general,
        number: returnNote.number || returnNote.invoice_number,
        receiptsValue: returnNote.receipts_values,
        paymentStatus: returnNote.payment_status,
        items: returnNote.items.map((returnNoteItem) => ({
          id: returnNoteItem.id,
          item: returnNoteItem.item,
          quantity: returnNoteItem.quantity,
          unit: returnNoteItem.unit_code,
          price: returnNoteItem.price,
          tax: returnNoteItem.tax,
          discount: returnNoteItem.discount,
          itemTotal: returnNoteItem.item_total
        })),
        documentsReferenced: returnNote.return_note_references?.length ? returnNote.return_note_references?.map((returnNoteReference) => ({
          id: returnNoteReference.id,
          returnNoteId: returnNoteReference.return_note_id,
          document: {
            id: returnNoteReference.document_reference_id,
            code: returnNoteReference.document_reference_type_code
          },
          paymentAmount: returnNoteReference.payment_amount
        })) : []
      }));
      dispatch(_getSuccess(returnNotes));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'returnNote/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const returnNoteItems = data.items.map((returnNoteItem) => ({
        item_id: returnNoteItem.item,
        quantity: Number(returnNoteItem.quantity),
        unit_code: returnNoteItem.unit,
        price: Number(returnNoteItem.price),
        tax_id: returnNoteItem.tax,
        discount: Number(returnNoteItem.discount)
      }));

      const returnNoteReferences = data.documentReference?.map((documentReference) => ({
        document_reference_id: documentReference.document.id,
        document_reference_type_code: documentReference.document.code,
        payment_amount: documentReference.paymentAmount
      }));

      const returnNote = data.otherIssueReason
        ? {
          date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
          customer_id: data.client,
          issue_reason_id: data.issueReason,
          issue_reason_motive: data.otherIssueReason,
          payment_period_id: data.paymentPeriod,
          payment_mode_id: data.paymentMode,
          serial_number_id: data.serie,
          commentary: data.commentary,
          items: returnNoteItems,
          references: returnNoteReferences
        }
        : {
          date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
          customer_id: data.client,
          issue_reason_id: data.issueReason,
          payment_period_id: data.paymentPeriod,
          payment_mode_id: data.paymentMode,
          serial_number_id: data.serie,
          commentary: data.commentary,
          items: returnNoteItems,
          references: returnNoteReferences
        };

      const response = await axios.post(`/companies/${companyId}/return_notes`, returnNote);

      const newReturnNote = {
        id: response.data.id,
        clientName: response.data.customer.name,
        clientId: response.data.customer.id,
        issueReason: {
          id: response.data.issue_reason.id,
          code: response.data.issue_reason.code,
          description: response.data.issue_reason.description
        },
        otherIssueReason: response.data.issue_reason_motive,
        serieCode: response.data.serial_number.code,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalTax: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        isVoided: response.data.is_voided,
        number: response.data.number,
        receiptsValue: response.data.receipts_values,
        paymentStatus: response.data.payment_status,
        items: response.data.items.map((responseItem) => ({
          id: responseItem.id,
          item: responseItem.item,
          quantity: responseItem.quantity,
          unit: responseItem.unit_code,
          price: responseItem.price,
          tax: responseItem.tax,
          discount: responseItem.discount,
          itemTotal: responseItem.item_total
        })),
        documentsReferenced: response.data.return_note_references?.map((documentReference) => ({
          id: documentReference.id,
          returnNoteId: documentReference.return_note_id,
          document: {
            id: documentReference.document_reference_id,
            code: documentReference.document_reference_type_code
          },
          paymentAmount: documentReference.payment_amount
        }))
      };

      analytics.dispatchEvent(events.returnNoteCreated());

      dispatch(_createOrUpdatingSuccess(newReturnNote));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const sendReturnNotesEmail = createAsyncThunk(
  'returnNote/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendReturnNotesEmail());

    try {
      await axios.post(`/companies/${companyId}/return_notes/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.returnNoteEmailed());

      dispatch(_sendReturnNotesEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendReturnNotesEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'returnNote/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/return_notes/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.returnNoteDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'returnNote/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/return_notes/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setReturnNotesPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'returnNote/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { returnNotes } = getState().returnNotes;

      const updatedReturnNotes = returnNotes.map((returnNote) => {
        const returnNoteUpdated = data.find((i) => i.id === returnNote.id);

        if (returnNoteUpdated) {
          return { ...returnNote, receiptsValue: returnNote.receiptsValue + returnNoteUpdated.value };
        }

        return returnNote;
      });

      dispatch(_getSuccess(updatedReturnNotes));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
