import { Box } from '@mui/material';
import PropTypes from 'prop-types';

const Wrapper = ({ children, id }) => (
  <Box
    id={id}
    sx={{
      backgroundColor: 'background.default',
      minHeight: '100%',
      p: 4
    }}
  >
    {children}
  </Box>
);

Wrapper.defaultProps = {
  id: null
};

Wrapper.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Wrapper;
