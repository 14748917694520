import PropTypes from 'prop-types';
import { matchPath } from 'react-router-dom';
import { Box, List, ListSubheader, Collapse, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import NavItem from './NavItem';

const renderNavItems = ({ depth = 1, items, pathname }) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        item,
        pathname,
        depth
      }), []
    )}
  </List>
);

const reduceChildRoutes = ({ acc, pathname, item, depth }) => {
  const checkExactMatch = (pathToCheck) => (pathToCheck ? !!matchPath({
    path: pathToCheck,
    end: true
  }, pathname) : false);

  let paddingLeft = 16;

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  if (item.sectionTitle) {
    acc.push(
      <Typography
        key={`${item.sectionTitle}-${pathname}`}
        sx={{
          pl: `${paddingLeft}px`,
          pb: '0.25rem',
          fontWeight: 600,
          fontSize: 14,
          color: '#535353'
        }}
      >
        {item.sectionTitle}
      </Typography>
    );
  } else if (item.aditionalSection) {
    acc.push(
      <NavItem
        active={checkExactMatch(item.path)}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={`${item.aditionalSectionTitle}-${item.path}`}
        path={item.path}
        title={item.aditionalSectionTitle}
      >
        {item.aditionalSection.map((aditionalItem) => (
          <NavItem
            active={checkExactMatch(aditionalItem.path)}
            depth={depth + 1}
            icon={aditionalItem.icon}
            info={aditionalItem.info}
            key={`${aditionalItem.title}-${aditionalItem.path}`}
            path={aditionalItem.path}
            title={aditionalItem.title}
          />
        ))}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        active={checkExactMatch(item.path)}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={`${item.title}-${item.path}`}
        path={item.path}
        title={item.title}
      />
    );
  }

  return acc;
};

const NavSection = (props) => {
  const { items, pathname, title, icon, ...other } = props;

  const [open, setOpen] = useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List
      subheader={(
        <ListSubheader
          onClick={handleClick}
          disableGutters
          disableSticky
          sx={{
            color: 'primary.main',
            fontSize: '0.75rem',
            lineHeight: 2.5,
            fontWeight: 700,
            textTransform: 'uppercase',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            pb: 1,
            cursor: 'pointer'
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center'
          }}
          >
            {icon}
            <Box sx={{
              ml: 1
            }}
            >
              {title}
            </Box>
          </Box>
          {open ? <ExpandMore /> : <ExpandLess />}
        </ListSubheader>
      )}
      {...other}
    >
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
      >
        {renderNavItems({
          items,
          pathname
        })}
      </Collapse>
    </List>
  );
};

NavSection.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.node
};

export default NavSection;
