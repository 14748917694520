import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import React, { useLayoutEffect } from 'react';

import { useDispatch, useSelector } from '../../store';
import { fetch as fetchEFaturaLinkIntegrations } from '../../slices/eFatura';
import { fetch as fetchCompanyIntegrations } from '../../slices/companyIntegrations';
import { integrate as integrateWithPing } from '../../slices/ping';

import EFaturaIntegration from '../../components/EfaturaIntegration';
import PingIntegration from '../../components/PingIntegration';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import H1Section from '../../components/Header/H1Section';
import Wrapper from '../../components/Wrapper';

import { isCompanyIntegratedWithPing } from '../../util/isCompanyIntegratedWithPing';
import { isCompanyIntegratedWithEFatura } from '../../util/eFaturasStatus';
import { POPUP_STATUS } from '../../constants';

const Integrations = () => {
  const dispatch = useDispatch();

  const {
    url: eFaturaUrl,
    error: eFaturaError,
    errorMessage: eFaturaErrorMessage,
    isLoading: isEFaturaUrlLoading
  } = useSelector((state) => state).eFatura;

  const {
    error: pingError,
    errorMessage: pingErrorMessage,
    isLoading: isPingIntegrationLoading
  } = useSelector((state) => state).ping;

  const {
    error: errorFetchingIntegrations,
    isLoading: isFetchIntegrationsLoading,
    companyIntegrations
  } = useSelector((state) => state).companyIntegrations;

  const { currentCompany } = useSelector((state) => state).companies;

  const handleGetCompanyIntegrations = () => {
    dispatch(fetchCompanyIntegrations());
  };

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchEFaturaLinkIntegrations());
      handleGetCompanyIntegrations();
    }
  }, [dispatch, currentCompany]);

  const handleIntegrationWithPing = () => {
    dispatch(integrateWithPing());
  };

  const renderIntegrations = () => {
    if (isFetchIntegrationsLoading) {
      return <LoadingPopUp />;
    }
    if (currentCompany.id && !errorFetchingIntegrations) {
      return (
        <>
          <PingIntegration
            error={pingError}
            popupStatus={POPUP_STATUS}
            errorMessage={pingErrorMessage}
            isLoading={isPingIntegrationLoading}
            companyIntegrations={companyIntegrations}
            handleIntegrationWithPing={handleIntegrationWithPing}
            handleGetCompanyIntegrations={handleGetCompanyIntegrations}
            isCompanyIntegratedWithPing={isCompanyIntegratedWithPing}
          />
          <EFaturaIntegration
            url={eFaturaUrl}
            error={eFaturaError}
            errorMessage={eFaturaErrorMessage}
            isCompanyIntegratedWithEFatura={isCompanyIntegratedWithEFatura}
            isLoading={isEFaturaUrlLoading || (!eFaturaError && !eFaturaUrl)}
            companyEfaturaStatus={currentCompany.integrations?.eFatura?.status}
          />
        </>
      );
    }
    return <EmptyList />;
  };

  return (
    <>
      <Helmet>
        <title>Integrações | Samba</title>
      </Helmet>
      <Wrapper id="title-of-integration-page">
        <H1Section
          title="Integraçāo"
          description="Minhas Integrações"
        />
        <Box>
          {renderIntegrations()}
        </Box>
      </Wrapper>
    </>
  );
};

export default Integrations;
