import React, { useEffect, useLayoutEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';

import { useDispatch, useSelector } from '../../store';
import { fetchAllTransactions } from '../../slices/transactions';
import { fetchById as fetchCompanyById } from '../../slices/companies';
import { fetch as fetchCompanyIntegrations } from '../../slices/companyIntegrations';

import Wrapper from '../../components/Wrapper';
import EmptyList from '../../components/empty_list';
import H1Section from '../../components/Header/H1Section';
import LoadingPopUp from '../../components/LoadingPopUp';
import TransactionsTable from '../../components/Transactions/Table';
import DisplayBalance from '../../components/DisplayBalance';

import { isCompanyIntegratedWithPing } from '../../util/isCompanyIntegratedWithPing';
import { WARNING_PING_INTEGRATION_TEXT } from '../../constants';
import IntegrationWarning from '../../components/WarningOnPage';

const Transactions = () => {
  const dispatch = useDispatch();
  const { onRequest, transactions } = useSelector((state) => state).transactions;

  const { currentCompany } = useSelector((state) => state).companies;
  const { companyIntegrations, isLoading: isGetCompanyIntegrationsLoading, error } = useSelector((state) => state).companyIntegrations;

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchCompanyIntegrations());
    }
  }, [currentCompany]);

  const renderEmptyList = () => (
    <EmptyList
      id="empty-list-transactions-page"
      title="Ainda nāo foi realizado nenhuma transação"
    />
  );

  const renderTransactionsList = () => {
    if (onRequest) {
      return (<LoadingPopUp message="Carregando..." />);
    }
    if (transactions.length) {
      return (
        <Box sx={{ mt: 3 }}>
          <TransactionsTable
            id="sales-transactions-table"
            transactions={transactions}
          />
        </Box>
      );
    }

    return renderEmptyList();
  };

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchAllTransactions());
    }
  }, [dispatch, currentCompany.id]);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchCompanyById());
    }
  }, [dispatch, currentCompany.id]);

  const renderTransactions = () => {
    if (currentCompany.id && error !== null) {
      if (isGetCompanyIntegrationsLoading) {
        return <LoadingPopUp />;
      }

      if (isCompanyIntegratedWithPing(companyIntegrations)) {
        return (
          <Box>
            <DisplayBalance
              label="Saldo Atual:"
              balance={currentCompany.totalBalance}
            />
            {renderTransactionsList()}
          </Box>
        );
      }

      return (
        <IntegrationWarning
          message={WARNING_PING_INTEGRATION_TEXT}
          buttonLabel="Integrar com o SAMBA POS"
          url="/integracoes/minhas_integracoes"
        />
      );
    }

    return <EmptyList />;
  };

  return (
    <>
      <Helmet>
        <title>Transações | Samba</title>
      </Helmet>
      <Wrapper>
        <H1Section
          title="Transações"
          description="Minhas Transações"
        />
        {renderTransactions()}
      </Wrapper>
    </>
  );
};

export default Transactions;
