import React from 'react';
import { Container as MuiContainer } from '@mui/material';
import PropTypes from 'prop-types';

const Container = ({ children }) => (
  <MuiContainer maxWidth="md">
    {children}
  </MuiContainer>
);

export default Container;

Container.propTypes = {
  children: PropTypes.node.isRequired
};
