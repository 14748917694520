export default {

  // Authentication

  signUp: (method) => [
    'sign_up',
    { method }
  ],

  logIn: (method) => [
    'login',
    { method }
  ],

  // Home page

  businessManagementClicked: () => ['business_management_clicked'],

  whySambaClicked: () => ['why_samba_clicked'],

  integrationsClicked: () => ['integrations_clicked'],

  facebookClicked: () => ['facebook_clicked'],

  instagramClicked: () => ['instagram_clicked'],

  blogClicked: () => ['blog_clicked'],

  scheduleDemoClicked: () => ['schedule_demo_clicked'],

  // Resource creation

  clientCreated: () => ['client_created'],

  companyCreated: () => ['company_created'],

  documentTypeCreated: () => ['document_type_created'],

  fiscalYearCreated: () => ['fiscal_year_created'],

  invoiceCreated: () => ['invoice_created'],

  invoiceDownloaded: () => ['invoice_downloaded'],

  receiptDownloaded: () => ['receipt_downloaded'],

  invoiceReceiptCreated: () => ['invoice_receipt_created'],

  invoiceReceiptDownloaded: () => ['invoice_receipt_downloaded'],

  creditNoteCreated: () => ['credit_note_created'],

  salesReceiptCreated: () => ['sales_receipt_created'],

  creditNoteDownloaded: () => ['credit_note_downloaded'],

  returnNoteCreated: () => ['return_note_created'],

  returnNoteDownloaded: () => ['return_note_downloaded'],

  debitNoteCreated: () => ['debit_note_created'],

  debitNoteDownloaded: () => ['debit_note_downloaded'],

  proFormaCreated: () => ['pro_forma_created'],

  proFormaDownloaded: () => ['pro_forma_downloaded'],

  purchaseInvoiceCreated: () => ['purchase_invoice_created'],

  itemCreated: () => ['item_created'],

  ivaTypeCreated: () => ['iva_type_created'],

  paymentModeCreated: () => ['payment_mode_created'],

  paymentPeriodCreated: () => ['payment_period_created'],

  supplierCreated: () => ['supplier_created'],

  serialNumberCreated: () => ['serial_number_created'],

  unitCreated: () => ['unit_created'],

  invoiceEmailed: () => ['invoice_emailed'],

  receiptEmailed: () => ['receipt_emailed'],

  invoiceReceiptEmailed: () => ['invoice_receipt_emailed'],

  creditNoteEmailed: () => ['credit_note_emailed'],

  debitNoteEmailed: () => ['debit_note_emailed'],

  proFormaEmailed: () => ['pro_forma_emailed'],

  returnNoteEmailed: () => ['return_note_emailed'],

  salesReceiptEmailed: () => ['sales_receipt_emailed'],

  salesReceiptDownloaded: () => ['sales_receipt_downloaded']
};
