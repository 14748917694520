import { useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { format, parseISO } from 'date-fns';

import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

import CssBaseline from '@mui/material/CssBaseline';
import { fetch as fetchPosts } from '../../slices/blogPosts';
import { useDispatch, useSelector } from '../../store';

import { blogTheme } from '../../theme/blogTheme';

import NavBar from '../../components/blogPage/NavBar';
import Footer from '../../components/blogPage/Footer';
import Post from '../../components/blogPage/Post';
import LoadingPopUp from '../../components/LoadingPopUp';

import '../../theme/fonts.css';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    marginTop: '7rem'
  },
  container: {
    height: 'auto',
    minHeight: '100vh'
  }
}));

const BlogPage = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();

  const { posts, loading } = useSelector((state) => state.blogPosts);

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchPosts());
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  const seePostDetails = useCallback((post) => {
    navigate(`/blog/${post.id}`);
  },
  []);

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  return (
    <>
      <Helmet>
        <title>Blog | Samba</title>
      </Helmet>
      <ThemeProvider theme={blogTheme}>
        <CssBaseline />
        <NavBar />
        <Box
          className={classes.articles}
        >
          { posts.map((item) => (
            <button
              key={item.id}
              style={{ border: 'none', background: 'transparent' }}
              type="button"
              onClick={() => seePostDetails(item)}
            >
              <Post
                key={item.id}
                title={item.title}
                description={item.description}
                tags={item.tags}
                createdAt={format(parseISO(item.created_at), 'PP')}
              />
            </button>
          ))}
        </Box>
        <Box
          alt="Shape"
          component="img"
          width="748.87px"
          height="791.98px"
          src="/static/blogPage/blog-shape-1-left.svg"
          sx={{
            zIndex: -2,
            position: 'absolute',
            width: 286,
            left: -40,
            top: -80
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/blogPage/blog-shape-1-right.svg"
          sx={{
            zIndex: -2,
            position: 'absolute',
            right: 0,
            top: 34
          }}
        />
        <Footer
          sx={{
            backgroundColor: 'background.grey'
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default BlogPage;
