import { Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from '../hooks/useAuth';

const GuestGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();

  const location = useLocation();

  if (isAuthenticated) {
    if (location.pathname === '/authentication/register') {
      return <Navigate to="/bem_vindo" />;
    }

    return <Navigate to="/minhas_empresas" />;
  }

  return <>{children}</>;
};

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default GuestGuard;
