import PropTypes from 'prop-types';
import { addYears, addDays } from 'date-fns';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  TextField,
  Typography
} from '@mui/material';
import { create as createFiscalYear, update as updateFiscalYear } from '../../slices/fiscalYear';
import { useDispatch } from '../../store';
import CustomDatePicker from '../CustomDatePicker';
import { useState } from 'react';

const initialValues = {
  endDate: addDays(addYears(new Date(), 1), -1),
  startDate: new Date(),
  name: undefined
};

const FiscalYearForm = (props) => {
  const { onCancel, onSubmitForm, isAdd, selectedFiscalYear } = props;
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const fiscalYearValues = {
    endDate: new Date(selectedFiscalYear?.endDateRaw),
    startDate: new Date(selectedFiscalYear?.startDateRaw),
    name: selectedFiscalYear?.name
  };

  const handleDateChange = (newValue, setFieldValue, setFieldKey) => {
    setFieldValue(setFieldKey, newValue.toDate());
  };

  return (
    <Formik
      initialValues={isAdd ? initialValues : fiscalYearValues}
      validationSchema={Yup
        .object()
        .shape({
          endDate: Yup
            .date()
            .when('startDate',
              (start, schema) => (start && schema.min(start,
                'A Data Final deve ser posterior à Data de Início')))
            .required('Obrigatório'),
          startDate: Yup.date().required('Obrigatório'),
          name: Yup
            .string()
            .max(255)
            .trim()
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitForm();
          if (!isAdd) {
            values.fiscalYearId = selectedFiscalYear.id;
            dispatch(updateFiscalYear(values));
          } else {
            dispatch(createFiscalYear(values));
          }

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {isAdd ? 'Novo Ano Fiscal' : 'Editar Ano Fiscal'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <TextField
              autoFocus
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label="Nome"
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values?.name}
              variant="outlined"
            />
            <Box sx={{ mt: 2 }}>
              <CustomDatePicker
                label="Data Inícial"
                onChange={(newValue) => {
                  setStartDate(newValue);
                  handleDateChange(newValue, setFieldValue, 'startDate')
                }}
                values={startDate}
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <CustomDatePicker
                label="Data Final"
                onChange={(newValue) => {
                  setEndDate(newValue)
                  handleDateChange(newValue, setFieldValue, 'endDate')}
                }
                values={endDate}
              />
            </Box>
            {Boolean(touched.endDate && errors.endDate) && (
              <Box sx={{ mt: 2 }}>
                <FormHelperText error>
                  {errors.endDate}
                </FormHelperText>
              </Box>
            )}
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {isAdd ? 'Criar' : 'Guardar'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

FiscalYearForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmitForm: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  // selectedFiscalYear can be null if the isAdd is true
  selectedFiscalYear: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    startDateRaw: PropTypes.string,
    endDate: PropTypes.string,
    endDateRaw: PropTypes.string
  })
};
FiscalYearForm.defaultProps = {
  selectedFiscalYear: {}
};
export default FiscalYearForm;
