import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export default function CustomCard(props) {
  const { title, message, color } = props;
  const theme = useTheme();

  return (
    <Box sx={{
      m: 1,
      p: 2,
      borderRadius: 0.7,
      border: `2px solid ${color}`,
      flexWrap: 'wrap',
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      minHeight: 100,
      background: `${theme.palette.primary.contrastText}`
    }}
    >
      {title && (
        <Typography
          color={theme.typography.homePageCardTitle}
          align="center"
          sx={{ }}
        >
          {title}
        </Typography>
      )}

      <Typography
        color={theme.typography.homePageCardDescription}
        align="center"
      >
        {message}
      </Typography>
    </Box>
  );
}
CustomCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};
