import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

const ReceiptItemSum = (props) => {
  const {
    totalGeneral,
    ...other
  } = props;

  return (
    <Card {...other}>
      <Box>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 600, border: 0 }}>
                Total Geral
              </TableCell>
              <TableCell
                id="receipt-sum-total-general"
                align="right"
                sx={{ border: 0 }}
              >
                {totalGeneral || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

ReceiptItemSum.propTypes = {
  totalGeneral: PropTypes.string
};

ReceiptItemSum.defaultProps = {
  totalGeneral: '0'
};

export default ReceiptItemSum;
