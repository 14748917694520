import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  paymentPeriods: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'paymentPeriod',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.paymentPeriods = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.paymentPeriods = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrSuccess: (state, { payload }) => {
      if (state.paymentPeriods === undefined) {
        state.paymentPeriods = [];
      }
      state.paymentPeriods.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _updateSuccess: (state, { payload }) => {
      const indexToUpdate = state.paymentPeriods.findIndex(paymentPeriod => paymentPeriod.id === payload.paymentPeriod);

      if (indexToUpdate !== -1) {
        state.paymentPeriods[indexToUpdate].code = payload.code;
        state.paymentPeriods[indexToUpdate].name = payload.name;
        state.paymentPeriods[indexToUpdate].numberOfDay = payload.number_of_day;
      }
      
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.paymentPeriods = state.paymentPeriods.filter((paymentPeriod) => paymentPeriod.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createOrSuccess,
  _updateSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'paymentPeriod/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/payment_periods`);

      const paymentPeriods = data.map((paymentPeriod) => ({
        id: paymentPeriod.id,
        code: paymentPeriod.code,
        name: paymentPeriod.name,
        numberOfDay: paymentPeriod.number_of_day,
        createdAt: paymentPeriod.createdAt
      }));

      dispatch(_getSuccess(paymentPeriods));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'paymentPeriod/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const paymentPeriod = {
        code: data.code,
        name: data.name,
        number_of_day: data.number_of_day,
        createdAt: data.createdAt
      };

      const response = await axios.post(`/companies/${companyId}/payment_periods`, paymentPeriod);

      analytics.dispatchEvent(events.paymentPeriodCreated());

      const newPaymentPeriod = {
        id: response.data.id,
        code: data.code,
        name: data.name,
        numberOfDay: data.number_of_day
      };

      dispatch(_createOrSuccess(newPaymentPeriod));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const update = createAsyncThunk(
  'paymentPeriod/update',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const paymentPeriod = {
        code: data.code,
        name: data.name,
        number_of_day: data.number_of_day
      };

      await axios.put(`/companies/${companyId}/payment_periods/${data.paymentPeriod}`, paymentPeriod);

      dispatch(_updateSuccess(data));

      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);
export const remove = createAsyncThunk(
  'paymentPeriods/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/payment_periods/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
