import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Typography
} from '@mui/material';
import AlertIcon from '../../icons/Alert';

export default function invalidTotalPopUp(props) {
  const { onClick, message, action } = props;

  return (
    <Box sx={{
      m: 4
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
      >
        <AlertIcon
          sx={{
            fontSize: 60,
            mt: 1
          }}
        />
        <Typography
          color="textSecondary"
          variant="h6"
          align="center"
          sx={{
            m: 2
          }}
        >
          {message}
        </Typography>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          p: 2,
          mt: 1
        }}
      >
        <Button
          fullWidth
          color="primary"
          onClick={onClick}
          variant="outlined"
          sx={{
            mr: 2
          }}
        >
          {action}
        </Button>
      </Box>
    </Box>
  );
}
invalidTotalPopUp.propTypes = {
  message: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired
};
