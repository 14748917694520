import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Dialog, DialogContent, Button, Box, TextField, FormLabel, useMediaQuery } from '@mui/material';
import InfoPair from '../../InfoPair';
import { formatCurrency } from '../../../util/formatCurrency';
import Card from '../../Card';

const PaymentMethodForm = (props) => {
  const {
    handlePayment,
    isPaymentMethodFormOpen,
    onClose,
    description,
    hasTaxInformation,
    amountInformation
  } = props;

  const initialValues = {
    email: ''
  };

  const handleSubmitToPay = (values) => {
    handlePayment(values.email, values.phoneNumber);
    onClose();
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const renderTaxInformation = () => {
    if (hasTaxInformation) {
      return (
        <Card>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
            <InfoPair
              label="Valor"
              value={formatCurrency(amountInformation.amount)}
              id="amount-samba-pos-payment-pop-up"
            />
            <InfoPair
              label={`Taxa (${(amountInformation.deductedFee * 100).toFixed(1)}%)`}
              value={formatCurrency(amountInformation.amountAfterFee)}
              id="fee-samba-pos-payment-pop-up"
            />
            <InfoPair
              label="Valor total"
              value={formatCurrency(amountInformation.finalAmount)}
              id="final-amount-samba-pos-payment-pop-up"
            />
          </Box>
        </Card>
      );
    }

    return null;
  };

  return (
    <Dialog
      onClose={() => onClose()}
      open={isPaymentMethodFormOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: '33rem'
        }
      }}
    >
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup
            .object()
            .shape(
              {
                email: Yup
                  .string()
                  .email('Digite um endereço de e-mail válido')
                  .max(255)
                  .required('Obrigatório')
              }
            )}
          onSubmit={async (values) => {
            handleSubmitToPay(values);
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              id="send-form-to-pay-document"
              onSubmit={handleSubmit}
            >
              <Box sx={{ display: 'flex', flexDirection: 'column', py: 2, gap: 2 }}>
                <FormLabel>{description}</FormLabel>
                <Box>
                  <TextField
                    id="send-form-to-pay-document-email-field"
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label="Email*"
                    name="email"
                    placeholder="Introduzir Email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values?.email}
                    variant="outlined"
                  />
                </Box>
                {renderTaxInformation()}
                <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column-reverse' : 'row', justifyContent: 'space-between' }}>
                  <Button
                    id="send-form-to-pay-document-cancel-button"
                    size="large"
                    variant="outlined"
                    onClick={() => onClose()}
                    sx={{ width: isMobile ? '100%' : '13rem', mt: isMobile ? 1 : 0 }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    id="send-form-to-pay-document-submit-button"
                    size="large"
                    variant="contained"
                    disabled={isSubmitting}
                    type="submit"
                    sx={{ width: isMobile ? '100%' : '13rem' }}
                  >
                    Pagar
                  </Button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

PaymentMethodForm.defaultProps = {
  hasTaxInformation: false,
  amountInformation: null
};

PaymentMethodForm.propTypes = {
  handlePayment: PropTypes.func.isRequired,
  isPaymentMethodFormOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  hasTaxInformation: PropTypes.bool,
  amountInformation: PropTypes.shape({
    amount: PropTypes.number,
    amountAfterFee: PropTypes.number,
    finalAmount: PropTypes.number,
    deductedFee: PropTypes.number
  })
};

export default PaymentMethodForm;
