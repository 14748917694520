import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { TextField, Grid, Button, Typography } from '@mui/material';

import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

const ResetPasswordForm = ({ token, navigateToLoginPage, handleRegisterButtonClick, handlePopUpOpen }) => {
  const isMountedRef = useIsMountedRef();
  const { resetPassword } = useAuth();

  return (
    <Formik
      initialValues={{
        password: null,
        confirmPassword: null
      }}
      validationSchema={Yup
        .object()
        .shape({
          password: Yup
            .string()
            .min(7, 'Deve conter no mínimo 7 caracteres')
            .max(255)
            .required('Obrigatório')
            .nullable(),
          confirmPassword: Yup
            .string()
            .min(7, 'Deve conter no mínimo 7 caracteres')
            .max(255)
            .required('Obrigatório')
            .nullable()
            .when('password', {
              is: (val) => (!!(val && val.length > 0)),
              then: Yup
                .string()
                .min(7, 'Deve conter no mínimo 7 caracteres')
                .max(255)
                .required('Obrigatório')
                .nullable()
                .oneOf([Yup.ref('password')],
                  'As senhas devem ser iguais')
            })
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          handlePopUpOpen();
          resetPassword(values.password, token);
          if (isMountedRef.current) {
            setStatus({ success: true });
            setSubmitting(false);
          }
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({ errors, handleBlur, handleChange, handleSubmit, touched, values }) => (
        <form
          onSubmit={handleSubmit}
        >
          <TextField
            id="reset-password-form-new-password-textfield"
            autoFocus
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            label="Nova Senha*"
            placeholder="Inserir Senha*"
            margin="normal"
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            variant="outlined"
          />
          <TextField
            id="reset-password-form-repeat-new-password-textfield"
            error={Boolean(touched.confirmPassword && errors.confirmPassword)}
            fullWidth
            helperText={touched.confirmPassword && errors.confirmPassword}
            label="Repetir Nova Senha*"
            placeholder="Repetir Senha*"
            margin="normal"
            name="confirmPassword"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.confirmPassword}
            variant="outlined"
          />
          <Grid
            container
            sx={{ mt: 20 }}
          >
            <Grid
              item
              xs
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={navigateToLoginPage}
                sx={{
                  px: 6,
                  py: 1,
                  borderRadius: 4
                }}
              >
                <Typography>
                  Cancelar
                </Typography>
              </Button>
            </Grid>
            <Grid
              item
            >
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="center"
              >
                <Button
                  id="reset-password-form-send-button"
                  color="primary"
                  type="submit"
                  variant="contained"
                  sx={{
                    px: 6,
                    py: 1,
                    borderRadius: 4
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600
                    }}
                  >
                    Entrar
                  </Typography>
                </Button>
                <Button
                  color="primary"
                  variant="text"
                  onClick={handleRegisterButtonClick}
                  sx={{
                    mt: 1
                  }}
                >
                  Não tenho conta
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
  navigateToLoginPage: PropTypes.func.isRequired,
  handleRegisterButtonClick: PropTypes.func.isRequired,
  handlePopUpOpen: PropTypes.func.isRequired
};

export default ResetPasswordForm;
