import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import BankAccountForm from '../../components/bank_account/Form';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchCompanySettings } from '../../slices/companySettings';
import { fetch as fetchBankAccounts, create as createBankAccount, update as updateBankAccount } from '../../slices/bankAccounts';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';
import ViewDetails from '../../components/bank_account/ViewDetails';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const BankAccount = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { bankAccounts, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.bankAccounts);
  const { currentCompany } = useSelector((state) => state.companies);
  const { companySettings } = useSelector((state) => state.companySettings);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchBankAccounts());
    }
  }, [dispatch, currentCompany]);

  useEffect(() => {
    function fetch() {
      dispatch(fetchCompanySettings());
    }
    fetch();
  }, []);

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  const handleSubmitForm = (bankAccount) => {
    if (bankAccounts?.length) {
      dispatch(updateBankAccount({ ...bankAccount, id: bankAccounts[0].id }));
    } else {
      dispatch(createBankAccount(bankAccount));
    }
    handlePopUpOpen();
  };

  function returnResult() {
    if (bankAccounts && bankAccounts.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <ViewDetails
            bankAccount={bankAccounts[0]}
            companySettings={companySettings}
          />
          <Box sx={{
            mt: 3,
            display: 'flex',
            justifyContent: 'flex-end'
          }}
          >
            <Button
              id="bank-account-edit-button"
              color="primary"
              variant="contained"
              onClick={handleNewClick}
            >
              Editar
            </Button>
          </Box>
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Conta Bancaria"
        buttonText="Criar Conta Bancaria"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Contas Bancárias | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Contas Bancárias
              </Typography>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                {bankAccounts ? 'Conta Bancária' : 'Minhas Contas Bancárias'}
              </Typography>
            </Grid>
            <Grid item>
              {(!bankAccounts?.length) && (
              <Button
                id="new-bank-account-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
            <BankAccountForm
              onCancel={handleModalClose}
              onSubmitForm={handleSubmitForm}
              bankAccount={bankAccounts?.[0]}
            />
            )}
          </Dialog>
        </Container>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
      </Box>
    </>
  );
};

export default BankAccount;
