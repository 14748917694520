import PropTypes from 'prop-types';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from '@mui/material';

const ViewFiscalInfo = (props) => {
  const { company } = props;

  return (
    <>
      <Box
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Informação Fiscal
        </Typography>
      </Box>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Nome Fiscal
            </TableCell>
            <TableCell>
              {company.fiscalName}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              NIF do T.O.C.
            </TableCell>
            <TableCell>
              {company.accountantTaxId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{
              fontWeight: 700,
              width: '50%'
            }}
            >
              NIF Repartição de
              Finanças
            </TableCell>
            <TableCell>
              {company.financeDepartmentTaxId}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 700 }}>
              Concelho R.F.
            </TableCell>
            <TableCell>
              {company.financeDepartmentCode}
              {' - '}
              {company.financeDepartmentName}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

ViewFiscalInfo.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    fiscalName: PropTypes.string,
    email: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

export default ViewFiscalInfo;
