import { Typography, Box, Card } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

const ProductCard = ({ id, title, description, images, alt }) => (
  <Card
    id={id}
    sx={{
      display: 'flex',
      gap: 1,
      width: 200,
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      p: 2,
      flexDirection: 'column',
      borderRadius: '16px'
    }}
  >
    <Box sx={{ width: '100%', height: '140px' }}>
      <img
        alt={alt}
        src={images || '/static/DefaultImage/default.jpg'}
        style={{
          height: '100%',
          width: '100%',
          borderRadius: '8px',
          objectFit: 'cover'
        }}
      />
    </Box>
    <Box>
      <Typography>{title}</Typography>
      <Typography>{description}</Typography>
    </Box>
  </Card>
);

ProductCard.defaultProp = {
  id: null
};

ProductCard.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  images: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default ProductCard;
