import { useState, useEffect } from 'react';

function getStorageValue(key, defaultValue) {
  let value;
  try {
    value = JSON.parse(localStorage.getItem(key) || JSON.stringify(defaultValue));
  } catch (error) {
    value = defaultValue;
  }
  return value;
}

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => getStorageValue(key, defaultValue));

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export const deleteLocalStorage = (key) => {
  localStorage.removeItem(key);
};
