import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const PaymentPeriodListTable = (props) => {
  const { paymentPeriods, onOpenPaymentPeriodDetail, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Código
                </TableCell>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Período Pagamento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paymentPeriods.map((paymentPeriod) => (
                <TableRow
                  hover
                  key={paymentPeriod.code}
                  onClick={() => onOpenPaymentPeriodDetail(paymentPeriod)}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {paymentPeriod.code}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {paymentPeriod.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {paymentPeriod.numberOfDay}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

PaymentPeriodListTable.propTypes = {
  paymentPeriods: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.number
  }).isRequired,
  onOpenPaymentPeriodDetail: PropTypes.func.isRequired
};

export default PaymentPeriodListTable;
