import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';

export function Section(props) {
  const { children, ...rest } = props;
  return (
    <Box
      {...rest}
      component="section"
    >
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          py: 6,
          position: 'relative',
          zIndex: 7
        }}
        maxWidth={false}
      >
        {children}
      </Container>
    </Box>
  );
}
Section.propTypes = {
  children: PropTypes.node
};
