import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { addDays, format, parseISO, isAfter, isBefore } from 'date-fns';
import { clone } from 'lodash';
import { useLocalStorage } from '../../../hooks/useLocalStorage';
import useSettings from '../../../hooks/useSettings';
import Table from '../../../components/documents/Table';
import Form from '../../../components/documents/Form';
import Toast from '../../../components/Toast';
import { useDispatch, useSelector } from '../../../store';
import { fetch as fetchProForma, create as createProForma, voidProForma, sendProFormaEmail, print as printProforma } from '../../../slices/proFormas';
import { fetch as fetchSeries } from '../../../slices/series';

import { fetch as fetchFiscalYears } from '../../../slices/fiscalYear';
import { fetch as fetchClients } from '../../../slices/clients';
import { fetch as fetchDocumentTypes } from '../../../slices/documentTypes';
import { fetch as fetchPaymentPeriod } from '../../../slices/paymentPeriod';
import { fetch as fetchPaymentMode } from '../../../slices/paymentMode';
import { fetch as fetchItems } from '../../../slices/itens';
import { fetch as fetchUnit } from '../../../slices/units';
import { fetch as fetchTaxes } from '../../../slices/taxes';

import EmptyList from '../../../components/empty_list';
import LoadingPopUp from '../../../components/LoadingPopUp';
import ViewDetails from '../../../components/documents/ViewDetails';

import { DOCUMENTS, POPUP_CONTENT_MESSAGE, TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../../constants';

import DeletePopUp from '../../../components/DeletePopUp';
import Scrollbar from '../../../components/Scrollbar';
import WarningAlertOnPage from '../../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';

const ProForma = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [isDeletePopUpOpen, setisDeletePopUpOpen] = useState(false);
  const [selectedProForma, setSelectedProForma] = useState(null);

  const { settings } = useSettings();

  const dispatch = useDispatch();
  const { proFormas, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.proFormas);
  const { series } = useSelector((state) => state.series);
  const { documentTypes } = useSelector((state) => state.documentTypes);
  const { paymentPeriods } = useSelector((state) => state.paymentPeriod);
  const { paymentModes } = useSelector((state) => state.paymentMode);
  const { clients } = useSelector((state) => state.clients);
  const { units } = useSelector((state) => state.units);
  const { taxes } = useSelector((state) => state.taxes);
  const { itens } = useSelector((state) => state.itens);
  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state.companies);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [proFormaStorage, setProFormaStorage] = useLocalStorage('proFormaStorage', null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftKey, setDraftKey] = useState(null);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchSeries()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchClients()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchPaymentPeriod()),
        dispatch(fetchPaymentMode()),
        dispatch(fetchItems()),
        dispatch(fetchUnit()),
        dispatch(fetchTaxes()),
        dispatch(fetchProForma()),
        dispatch(fetchDocumentTypes())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleNewClick = () => {
    setSelectedDraft(null);
    setDraftKey(Math.random());
    setIsAddMode(true);
  };

  const handleEditDraft = (draft, keyDraft) => {
    setIsAddMode(true);
    dispatch(fetchSeries());
    setSelectedDraft(draft);
    setDraftKey(keyDraft);
  };

  const handleDeleteDraft = (draftId) => {
    const draftsInvoiceStorage = clone(proFormaStorage);
    const indexCompany = draftsInvoiceStorage.invoices.findIndex((company) => currentCompany.id in company);
    const draftIndex = draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].findIndex((draft) => draftId in draft);

    if (draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].length <= 1) {
      draftsInvoiceStorage.invoices.splice(indexCompany, 1);
    } else {
      draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].splice(draftIndex, 1);
    }
    setProFormaStorage(draftsInvoiceStorage);
  };

  const handleUpdateSelectedProForma = () => {
    if (selectedProForma) {
      const invoiceUpdate = proFormas.find((proForma) => proForma.id === selectedProForma?.id);
      setSelectedProForma(invoiceUpdate);
    }
  };

  const handleDetailOpen = (proForma) => {
    setSelectedProForma(proForma);
    setIsDetailsModalOpen(true);
  };

  const handleDuplicate = (proForma) => {
    setSelectedProForma(proForma);
    handleNewClick();
  };

  const handleDetailClose = () => {
    setIsDetailsModalOpen(false);
    setSelectedProForma(null);
  };

  const handleModalClose = () => {
    setIsAddMode(false);
    setSelectedProForma(null);
  };

  const handleDeletePopUpOpen = () => {
    setisDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setisDeletePopUpOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedProForma();
    setIsPopUpOpen(false);
  };

  const handleCreate = (proForma) => {
    handlePopUpOpen();
    dispatch(createProForma({
      ...proForma,
      documentType: documentTypes.find((documentType) => documentType.code === DOCUMENTS.pro_forma.code).id
    }));
  };

  const handleVoid = () => {
    handleDetailClose();
    handleDeletePopUpClose();
    handlePopUpOpen();
    dispatch(voidProForma(selectedProForma));
  };

  /**
   * Send Email
   * @param {string} email - Email to send the proForma email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendProFormaEmail({ id: selectedProForma.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const getNextProFormaNumber = (serieId) => {
    const getProFormas = proFormas ? proFormas.filter((proForma) => proForma.serie === serieId) : 0;

    if (!getProFormas && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);

      if (getSerie?.serialNumberConfig?.proFormaLastDocumentNumber) {
        return Number(getSerie.serialNumberConfig.proFormaLastDocumentNumber) + 1;
      }

      return Number(getSerie?.lastDocumentNumber) + 1;
    }

    return Number(getProFormas[getProFormas.length - 1]?.number) + 1;
  };

  /**
   * isBefore is a function that returns true if the date is before the given date.
   * isAfter is a function that returns true if the date is after the given date.
   * @param {Date} date
   * @param {Object} serie
   */
  const isInSerieDateRange = (date, serie) => (isBefore(date, new Date(serie?.endDateRaw)) && isAfter(date, new Date(serie?.startDateRaw)));

  function returnResult() {
    const validIndex = 0;
    const companyIndex = proFormaStorage?.invoices.findIndex((company) => currentCompany.id in company);
    let companyDrafts = null;
    if (companyIndex >= validIndex) companyDrafts = proFormaStorage?.invoices[companyIndex][currentCompany.id];
    const hasCompanyDrafts = companyIndex >= validIndex && companyDrafts?.length > 0;

    if ((proFormas && proFormas.length > 0) || (companyIndex >= validIndex && hasCompanyDrafts)) {
      return (
        <Box sx={{ mt: 3 }}>
          <Table
            draftsStorage={companyIndex >= validIndex ? companyDrafts : null}
            deleteDraft={handleDeleteDraft}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
            documents={proFormas}
            onOpenDetails={handleDetailOpen}
            onClick={handleEditDraft}
            onVoid={(proForma) => {
              setSelectedProForma(proForma);
              handleDeletePopUpOpen();
            }}
            onView={handleDetailOpen}
            onDuplicate={handleDuplicate}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Pró-Forma"
        buttonText="Criar Pró-Forma"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  const handlePrint = () => {
    dispatch(printProforma(selectedProForma));
  };

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>Pró-Forma | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {!isAddMode ? 'Pró-Forma' : 'Nova Pró-Forma'}
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Pró-Forma
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                id="pro-forma-new-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }

          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <Form
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreate}
              series={series}
              clients={clients}
              paymentPeriods={paymentPeriods}
              documentTypes={documentTypes}
              paymentModes={paymentModes}
              units={units}
              taxes={taxes}
              itens={itens}
              document={selectedProForma}
              isInSerieDateRange={isInSerieDateRange}
              getNextDocumentNumber={getNextProFormaNumber}
              draftId={draftKey}
              documentDraft={selectedDraft}
              documentStorage={proFormaStorage}
              setDocumentStorage={setProFormaStorage}
              handleDeleteDraft={handleDeleteDraft}
              currentCompany={currentCompany}
              isOnRequest={onRequest}
            />
            )}
          </Box>

        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleDetailClose}
          open={Boolean(isDetailsModalOpen && selectedProForma)}
        >
          <Scrollbar>
            {Boolean(isDetailsModalOpen && selectedProForma) && (
            <ViewDetails
              title="Pró-Forma"
              document={{ ...selectedProForma, dateFormatted: format(parseISO(selectedProForma.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedProForma.date), selectedProForma.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
              onCancel={handleDetailClose}
              onVoid={handleDeletePopUpOpen}
              handleSendEmail={handleSendEmail}
              handlePrintButtonClick={handlePrint}
              currentCompany={currentCompany}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            message={POPUP_CONTENT_MESSAGE.VOID.MESSAGE}
            action={POPUP_CONTENT_MESSAGE.VOID.ACTION}
            onCancel={handleDeletePopUpClose}
            onDelete={handleVoid}
          />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default ProForma;
