import PropTypes from 'prop-types';
import Lottie from 'react-lottie';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import animationData from '../../lotties/warning.json';

const IntegrationWarning = (props) => {
  const { url, message, buttonLabel } = props;
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleNavigateToPage = () => {
    navigate(url);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData
  };

  return (
    <Box
      id="warning-on-page"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 4
      }}
    >
      <Lottie
        options={defaultOptions}
        height={isMobile ? '100%' : 400}
        width={isMobile ? '100%' : 400}
      />
      <Typography
        align="center"
        color="gray"
        variant="h6"
      >
        {message}
      </Typography>
      {buttonLabel && (
        <Button
          sx={{ mt: 2, width: 250 }}
          variant="contained"
          onClick={handleNavigateToPage}
        >
          {buttonLabel}
        </Button>
      )}
    </Box>
  );
};

IntegrationWarning.propTypes = {
  url: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired
};

export default IntegrationWarning;
