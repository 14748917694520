import React from 'react';
import { Helmet } from 'react-helmet-async';

import { Box, Container, Grid, Typography, List, ListItem } from '@mui/material';
import Wrapper from '../../components/Wrapper';

const TermsAndConditions = () => (
  <>
    <Helmet>
      <title>Termos e Condições | Samba POS</title>
    </Helmet>
    <Wrapper>
      <Container maxWidth="lg">
        <Grid
          container
          justifyContent="space-between"
          spacing={3}
        >
          <Grid item>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              TERMOS E CONDIÇÕES DE UTILIZAÇÃO DO SERVIÇO SAMBA POS
            </Typography>

            <Typography
              color="textSecondary"
              variant="overline"
            >
              Esta página contém os Termos e Condições de Uso e demais
              Políticas, que visam informar ao usuário sobre a utilização e os
              serviços que podem ser prestados pelo aplicativo.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Container maxWidth="lg">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Typography>
            Ao aceitar os termos e condições de uso, o usuário declara estar ciente das disposições nele contidas, e em caso
            de utilização do serviço em nome de sua empresa, esta também estará concordando com os termos e políticas
            mencionados.
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              1. DEFINIÇÕES
            </Typography>
            <Typography align="justify">
              “Termos e Condições” é o documento que reúne as regras para utilização dos serviços e produtos de uma
              determinada empresa.
            </Typography>
            <Typography align="justify">
              SAMBA POS: consiste num serviço de intermediação de pagamento de produtos e serviços desenvolvido pela empresa
              Chuva que permite a realização de pagamentos a partir do telemóvel do usuário. O aplicativo SAMBA POS é da
              exclusiva responsabilidade da empresa Chuva.
            </Typography>
            <Typography align="justify">
              Usuários: são todos os agentes externos ao sistema, com idade mínima de treze (13) anos, que usufruem dos
              benefícios oferecidos pela SAMBA POS.
            </Typography>
            <Typography align="justify">
              Número de telemóvel: é uma sequência de números ****decimais que identificam um ponto de terminação de rede.
              Esse número deve ser registado em nome pessoal do usuário, conter o indicativo do país onde reside e no mínimo
              sete (7) dígitos numéricos.
            </Typography>
            <Typography align="justify">
              PIN: consiste num código de quatro (4) dígitos numéricos criados pelo próprio usuário. O referido código é
              definido no momento de criação da conta PING e solicitado no momento de transferências monetárias. O código
              PIN é pessoal e intransmissível.
            </Typography>
            <Typography align="justify">
              Aplicativo ou App Mobile: é um software desenvolvido para ser instalado em smartphone. O download do mesmo é
              realizado a partir de uma loja on-line, como Google Play ou AppStore, diretamente para o seu telemóvel.
            </Typography>
            <Typography align="justify">
              OTP: One Time Password, é um código enviado para o número de telemóvel do usuário, com o intuito de verificar
              a veracidade do número, no momento de registar-se e/ou fazer login no PING.
            </Typography>
            <Typography align="justify">
              Fundos: é a quantia em dinheiro que o usuário possui na sua conta PING.
            </Typography>
            <Typography align="justify">
              Dias úteis: são todos aqueles onde o trabalho não está suspenso, com o funcionamento normal dos
              estabelecimentos de bens e serviços. No caso do PING, este período abrange de segunda a sexta-feira.
            </Typography>
            <Typography align="justify">
              SISP: Sociedade Interbancária e Sistemas de Pagamentos, é uma instituição financeira que está encarregada de
              gerir atividades relacionadas com o desenvolvimento e utilização de meios de pagamentos em Cabo Verde,
              ocupando-se, em particular, com a gestão de sistemas bancários de pagamentos nacionais e internacionais,
              emissão e gestão de cartões de débito, prestação de serviços ligados a sistemas eletrônicos de pagamentos,
              transmissão e gestão de informação de dados.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              2. OBJETO
            </Typography>
            <Typography align="justify">
              SAMBA POS é um serviço que permite e facilita o pagamento de produtos e serviços via o aplicativo PING.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              3. UTILIZAÇÃO E FUNCIONAMENTO DO SERVIÇO
            </Typography>
            <Typography align="justify">
              Elegibilidade e Registo de Conta
            </Typography>
            <Typography align="justify">
              A utilização do aplicativo SAMBA POS requer a abertura de uma conta no sistema de gestão empresarial SAMBA, com os dados da empresa, que é processada através de elementos de identificação estritamente referentes à empresa e/ou pessoa e intransmissíveis.
            </Typography>
            <Typography align="justify">
              No momento de registo, utilização ou atualização de uma conta SAMBA, podem haver exigências no que concerne a informações do usuário e este deve garantir que as informações são reais.
            </Typography>
            <Typography align="justify">
              É necessário fornecer essas informações pessoais, pois serão utilizados para verificar a identidade de quem as solicitar.
            </Typography>
            <Typography align="justify">
              Quando o usuário valida e autentica sua identidade, automaticamente autoriza a empresa Chuva a fazer quaisquer consultas que sejam consideradas necessárias para validar sua identidade ou autenticar a identidade e informações de conta.
            </Typography>
            <Typography align="justify">
              Além disso, qualquer informação que o usuário forneça ou disponibilize ao público por meio dos serviços do SAMBA POS, não deve conter nada que possa ser considerado censurável. A título de exemplo, informações de caracter ilegal, obsceno, odioso ou prejudicial tanto para o usuário, como a outros usuários ou mesmo ao PING - Empresa Chuva. Caso isso se verifique, esses conteúdos podem ser removidos imediatamente.
            </Typography>
            <Typography align="justify">
              No momento de utilização do SAMBA POS, o usuário deverá ter especial atenção aos procedimentos, prevenindo e evitando eventuais lapsos de digitação ou comunicação, de modo a salvaguardar o sucesso da operação.
            </Typography>
            <Typography align="justify">
              O SAMBA POS e SAMBA não são é responsáveis por contas bancárias e número de telemóvel introduzidos incorretamente.
            </Typography>
            <Typography align="justify">
              O serviço SAMBA POS é muito simples, prático e funcional pois permite ao usuário:
            </Typography>
            <List>
              <ListItem>
                <Typography align="justify">
                  1. Pagar produtos e serviços com o seu aplicativo PING via um QR Code.
                </Typography>
              </ListItem>
            </List>
            <Typography align="justify">
              O usuário deve confirmar o e-mail e número de telemóvel com o qual irá fazer o registo, assim como todas as
              informações inseridas ou coletadas durante a criação de sua conta e, em caso de necessidade, garantir a atualização das mesmas. Todas as informações da conta estão sujeitas à política de direitos autorais do SAMBA POS.
            </Typography>
            <Typography align="justify">
              Qualquer ordem regularmente transmitida é irrevogável.
            </Typography>
            <Typography align="justify">
              Conteúdo – o usuário detém todos os direitos sobre os seus conteúdos cedidos nos Termos do SAMBA POS. Também concede ao SAMBA POS e às suas subsidiárias um direito mundial, não exclusivo, isento de royalties, totalmente pago, transferível, irrevogável, perpétuo de usar, reproduzir, modificar, adaptar, publicar, preparar trabalhos derivados, distribuir, executar e exibir publicamente seu conteúdo a nível global, em qualquer mídia, por qualquer motivo, inclusive para fornecer, promover e/ou incorporar aos Serviços.
            </Typography>
            <Typography align="justify">
              Alertando sempre que todo e qualquer tipo de conteúdo falso, obsceno e/ou ofensivo são expressamente proibidos.
            </Typography>
            <Typography align="justify">
              Segurança - Meios técnicos foram criados e estabelecidos para proteger as informações pessoais do usuário contra perda acidental e acesso, uso, alteração ou divulgação não autorizados. No entanto, a empresa Chuva, não pode garantir que terceiros não autorizados nunca conseguirão ultrapassar essas medidas ou usar suas informações pessoais para fins impróprios.
            </Typography>
            <Typography align="justify">
              Licença – o usuário deve garantir que está usando a versão mais recente do SAMBA POS, o que pode exigir que o usuário baixe e instale as atualizações manualmente. Quaisquer atualizações podem estar sujeitas a termos adicionais que serão automaticamente informados na ocasião.
            </Typography>
            <Typography align="justify">
              É sempre importante esclarecer que a empresa Chuva leva a segurança, tanto dela como dos usuários do SAMBA POS, muito a sério, mas não poderá garantir que pessoas mal-intencionadas não tenham acesso às suas informações pessoais. É obrigatório que o usuário faça a sua parte mantendo o seu password seguro, sendo precavido sobre quem tem acesso à sua conta e seu telemóvel para aceder aos serviços do SAMBA POS, informando sempre se existe desconfiança na utilização da conta por parte de usuários não autorizados.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              4. INTRANSMISSIBILIDADE DOS ELEMENTOS DE IDENTIFICAÇÃO
            </Typography>
            <Typography align="justify">
              O Usuário obriga-se a garantir a segurança dos elementos de identificação referidos no ponto anterior ****bem como a sua utilização estritamente pessoal, designadamente:
            </Typography>
            <Typography align="justify">
              a) Não permitindo a sua utilização por terceiros, ainda que seu procurador ou mandatário; caso assim deseje, o usuário será o único responsável por qualquer contratempo;
            </Typography>
            <Typography align="justify">
              b) Não os revelando, nem por qualquer forma os tornando acessíveis ao conhecimento de terceiros;
            </Typography>
            <Typography align="justify">
              c) Memorizando-os e abstendo-se de os registar, quer diretamente, quer por qualquer forma ou meio que sejam inteligíveis por terceiros.
            </Typography>
            <Typography align="justify">
              A empresa garante que o SAMBA POS jamais vai solicitar qualquer dado além dos enumerados nos presentes termos e condições.
            </Typography>
            <Typography align="justify">
              A aplicação é criptograficamente verificada.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              5. RESPONSABILIDADES DO CLIENTE
            </Typography>
            <Typography align="justify">
              O usuário obriga-se a comunicar imediatamente à Empresa Chuva quaisquer ocorrências anómalas, nomeadamente no lançamento em conta de uma operação não ordenada;
            </Typography>
            <Typography align="justify">
              Sempre que uma operação seja realizada mediante os procedimentos referidos nas cláusulas anteriores, presumir-se-á que a mesma foi feita pelo Cliente.
            </Typography>
            <Typography align="justify">
              Se, no entanto, se provar que a operação foi realizada por terceiros, presumir-se-á que tal foi consentida ou culposamente facilitada pelo Cliente.
            </Typography>
            <Typography align="justify">
              É vedado ao usuário, modificar, copiar, distribuir, transmitir, exibir, realizar, reproduzir, publicar, disponibilizar, licenciar ou desenvolver produtos derivados a partir das informações recolhidas no aplicativo PING, bem como transferir ou vender tais informações, software, produtos ou serviços, sob pena de violação do presente Termo e de responsabilização nos termos da lei.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              6. SALDO SAMBA POS
            </Typography>
            <Typography align="justify">
              O usuário é o único responsável pelos fundos para concluir qualquer transferência/pagamento.
            </Typography>
            <Typography align="justify">
              Os fundos do SAMBA POS, podem ser consultados via a plataforma SAMBA
            </Typography>
            <Typography align="justify">
              Limitações – o SAMBA POS estipula os limites ao valor mantido no Saldo SAMBA POS e o direito de alterar esses limites a qualquer momento.
            </Typography>
            <Typography align="justify">
              A Empresa Chuva reserva o direito de estipular todas as limitações de levantamentos diários, sobre o valor mantido no Saldo SAMBA POS e o direito de alterar esses limites a qualquer momento.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              7. PREÇÁRIO E COBRANÇA
            </Typography>

            <Typography align="justify">
              A instalação do aplicativo SAMBA POS é totalmente gratuita.
            </Typography>
            <Typography align="justify">
              Serão cobradas as seguintes taxas no seguintes momentos:
            </Typography>
            <List>
              <ListItem>
                <Typography>
                  • Recebimento de pagamento pelo SAMBA POS - 0,5% no momento da transação.
                </Typography>
              </ListItem>
              <ListItem>
                <Typography>
                  • Levantamento Bancário do saldo SAMBA POS:
                  <List>
                    <ListItem>
                      <Typography>
                        - Integrado com E-fatura: 0,2% do valor a ser levantado.
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        - Não integrado com E-fatura: 2% do valor a ser levantado.
                      </Typography>
                    </ListItem>
                  </List>
                </Typography>
              </ListItem>
              <Typography align="justify">
                As taxas aplicadas podem ser alteradas com o devido aviso prévio.
              </Typography>
            </List>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              8. PAGAMENTOS DE PESSOA PARA BUSINESS
            </Typography>
            <Typography align="justify">
              Transferência - O aplicativo SAMBA POS permite o pagamento de produtos/serviços via uma conta Ping.
            </Typography>
            <Typography align="justify">
              Para efetuar o pagamento, é apresentado um QR code, que ao ser lido pelo cliente com o seu telemóvel, gera uma solicitação de pagamento no aplicativo Ping. Para confirmar este pagamento, o cliente deverá inserir o PIN da sua conta PING, configurado na altura da criação da sua conta PING.
            </Typography>
            <Typography align="justify">
              A empresa Chuva, reserva para si as limitações na quantidade de dinheiro enviada de pessoa para pessoa e o direito de alterar esses limites a qualquer momento.
            </Typography>
            <Typography align="justify">
              A empresa Chuva não tem nenhuma responsabilidade se o usuário não conseguir concluir a transferência de pessoa para pessoa devido a instruções de pagamento imprecisas ou insuficientes.
            </Typography>
            <Typography align="justify">
              Receber fundos - Qualquer pagamento de pessoa para empresa recebida pelo usuário será atualizada em seu Saldo Ping.
            </Typography>
            <Typography align="justify">
              O usuário pode armazenar fundos na sua conta PING e pode fazer levantamento bancário dos seus fundos via a plataforma SAMBA.
            </Typography>
            <Typography align="justify">
              Risco de Transação Fraudulenta - O Serviço pessoa para empresa é um serviço de transmissão de dinheiro. Sendo assim, as transações fraudulentas podem resultar na perda de fundos sem recurso.
            </Typography>
            <Typography align="justify">
              Com o aplicativo SAMBA POS funcionando normalmente, e o usurário efetuando pagamentos não se pode garantir que o destinatário não tenha intenções de fraude, assim sendo, o usuário, por si mesmo, deve garantir que sabe o destinatário das suas transferências.
            </Typography>
            <Typography align="justify">
              É necessário que o usuário fique atento a sua conta PING e verifique regularmente se há operações suspeitas ou não autorizadas.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              9. TRANSFERÊNCIA DE FUNDOS PARA CONTA BANCÁRIA PESSOAL DO USUÁRIO
            </Typography>
            <Typography align="justify">
              O usuário pode transferir fundos de seu Saldo SAMBA POS para uma Conta Bancária, pessoal ou de um terceiro e a transferência será iniciado em até dois (2) dias úteis. Esta operação não pode ser cancelada.
            </Typography>
            <Typography align="justify">
              Contas de Cabo Verde: o SAMBA POS permite fazer levantamentos bancários, apenas para contas bancárias nacionais.
            </Typography>
            <Typography align="justify">
              Limitações – o SAMBA POS pode limitar o número de levantamentos que o usuário pode realizar diariamente e a quantidade de fundos que o mesmo pode transferir em uma única transação, limitação essa que será informada no momento da transferência.
            </Typography>
            <Typography align="justify">
              Tratamento de fundos - Os fundos do usuário serão mantidos separados dos fundos corporativos do SAMBA POS e não serão usados para fins corporativos. A Empresa combinará os fundos do usuário e os colocará em uma ou mais contas bancárias do SAMBA POS.
            </Typography>
            <Typography align="justify">
              Os valores dos usuários de SAMBA POS estarão em contas bancarias de SAMBA POS em diferentes bancos.
            </Typography>
            <Typography align="justify">
              Para essa opção, de transferir dinheiro da conta SAMBA POS para uma conta bancaria, o usuário terá a oportunidade de confirmar seus dados por duas ocasiões, procedimento que irá minimizar as probabilidades de erro durante a transação. O usuário é o único responsável por qualquer transação efetuada a partir da sua conta para uma outra conta bancaria.
            </Typography>
            <Typography align="justify">
              A empresa não tem nenhuma responsabilidade caso o usuário não consiga concluir a transação, quando os dados ou instruções de pagamento são fornecidos de forma errada ou imprecisa.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              10. RECIBOS
            </Typography>
            <Typography align="justify">
              O aplicativo SAMBA POS tem reservado um histórico, onde o usuário poderá ter acesso a todas as transações realizadas e aos extratos de conta correspondentes à sua conta SAMBA POS.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              11. COMUNICAÇÕES
            </Typography>
            <Typography align="justify">
              Para qualquer dúvida ou informação adicional, a empresa Chuva estará sempre disponível para ajudar o usuário em todos os aspetos possíveis. Para tal, basta que o usuário envie um e-mail para ping.support@chuva.io solicitando esclarecimentos adicionais.
            </Typography>
            <Typography align="justify">
              Agradecemos que entre em contato connosco se encontrar algum erro ou problema em sua conta.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              12. DIREITOS AUTORAIS
            </Typography>
            <Typography align="justify">
              O usuário não tem nenhum direito de propriedade sobre os serviços criados e prestados pela empresa Chuva, nomeadamente do SAMBA POS.
            </Typography>
            <Typography align="justify">
              Compartilhamento de suas informações – o SAMBA POS compartilhará as informações de conta e transações, incluindo o nome do usuário, o valor e uma descrição, com a outra parte em sua transação, o destinatário. Ou seja, ao usar o SAMBA POS e solicitar um pagamento, o SAMBA POS enviará as informações do usuário para a pessoa que este deseje receber pagamento.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              13. RESOLUÇÃO/ RESCISÃO
            </Typography>
            <Typography align="justify">
              O usuário só pode excluir a conta quando o saldo for zero e não houver transferências pendentes.
            </Typography>
            <Typography align="justify">
              O incumprimento por qualquer das partes no que concerne às obrigações assumidas, nos termos destas Condições Gerais ou da lei, constitui motivo de resolução imediata do contrato, mediante comunicação à outra parte.
            </Typography>
            <Typography align="justify">
              O presente contrato poderá ser, a qualquer momento, livremente rescindido por qualquer das partes, sem aviso prévio, tornando-se a rescisão efetiva por mera comunicação à contraparte.
            </Typography>
            <Typography align="justify">
              A rescisão não prejudica, em qualquer caso, a execução das ordens regularmente transmitidas.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              14. ALTERAÇÃO/ ATUALIZAÇÕES DESTES TERMOS E CONDIÇÕES
            </Typography>
            <Typography align="justify">
              A empresa Chuva poderá, unilateralmente, rever, aprimorar, modificar e/ou atualizar, a qualquer momento, qualquer cláusula ou disposição contidas nestes Termos e Condições de Uso ou demais Políticas. A versão atualizada valerá para o uso do SAMBA POS.
            </Typography>
            <Typography>
              A continuidade de acesso ou utilização deste aplicativo, depois da divulgação de quaisquer modificações, demonstra, por parte do usuário, a aceitação dos novos Termos e Condições de Uso ou das novas Políticas pelo mesmo.
            </Typography>
            <Typography align="justify">
              Caso um usuário não esteja de acordo com uma determinada alteração das Políticas ou dos Termos e Condições de Uso, poderá rescindir seu vínculo com o SAMBA POS, por meio de pedido de exclusão da conta no aplicativo.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              15. DAS DISPOSIÇÕES FINAIS
            </Typography>
            <Typography align="justify">
              O conteúdo deste instrumento poderá ser modificado livremente pela empresa Chuva por meio de comunicação eletrónica aos usuários, passando os novos termos a valer, a partir da data que forem inseridas no aplicativo - SAMBA POS, reservando-se ao usuário o direito de permanecer registado ou simplesmente solicitar sua exclusão.
            </Typography>
            <Typography align="justify">
              Estes Termos e Condições de Uso e as Políticas têm duração indefinida e permanecerão em vigor enquanto o aplicativo estiver ativo. O acesso e a utilização do aplicativo e dos recursos por ele oferecidos têm, em princípio, duração indeterminada e de modo exclusivo e a critério da empresa Chuva.
            </Typography>
            <Typography align="justify">
              Todo o conteúdo, do aplicativo – SAMBA POS é protegido pela legislação de propriedade intelectual aplicável e não infringem qualquer lei ou norma a que estejam subordinadas, contratos, documentos, acordos dos quais faz parte, bem como não infringe direitos de terceiros. A violação de tais direitos por terceiros implicará a respetiva reparação integral dos danos, nos termos da lei.
            </Typography>
            <Typography align="justify">
              Todos os termos, condições e obrigações constantes no presente Termo e Condições Gerais do aplicativo SAMBA POS são regidos pelas leis vigentes na República de Cabo Verde.
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography
              color="textPrimary"
              variant="h5"
            >
              Caixa para expressar o consentimento:
            </Typography>
            <Typography align="justify">
              Declaro ter tomado conhecimento e aceitar as Condições Gerais de Utilização do Serviço SAMBA POS, das quais recebi cópia.
            </Typography>
            <Typography align="justify">
              Estes Termos e Condições de Uso e as Políticas têm duração indefinida e permanecerão em vigor enquanto o aplicativo estiver ativo. O acesso e a utilização do aplicativo e dos recursos por ele oferecidos têm, em princípio, duração indeterminada e de modo exclusivo e a critério da empresa Chuva.
            </Typography>
            <Typography>
              Janeiro 2024
            </Typography>
            <Typography>
              Chuva
            </Typography>
          </Box>
        </Box>
      </Container>
    </Wrapper>
  </>
);

export default TermsAndConditions;
