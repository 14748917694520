import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Collapse
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Scrollbar from '../Scrollbar';

import InvoiceItemSumatory from '../invoiceItem/InvoiceItemSumatory';
import { formatCurrency } from '../../util/formatCurrency';
import { emailSchema } from '../../util/schema/emailValidation';
import renderEfaturaDocumentErrorMessage from '../../util/renderEfaturaDocumentErrorMessage';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const ViewDetails = (props) => {
  const { title, debitNote, onCancel, handleSendEmail, handlePrintButtonClick, currentCompany, ...other } = props;

  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  const documentTableHeader = [
    'Artigo',
    'Nome',
    'Unidade',
    'Quantidade',
    'Preço',
    taxOrRetentionLabels.taxTypeLabel,
    'Desconto',
    'Total'
  ];

  const rowItems = (invoiceItem, open, setOpen) => (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
            disabled={!invoiceItem.item.item_description}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {invoiceItem.item.code}
        </TableCell>
        <TableCell>
          {invoiceItem.item.name}
        </TableCell>
        <TableCell>
          {invoiceItem.unit}
        </TableCell>
        <TableCell>
          {invoiceItem.quantity}
        </TableCell>
        <TableCell>
          {invoiceItem.price}
          $
        </TableCell>
        <TableCell>
          {invoiceItem.tax.value}
          %
        </TableCell>
        <TableCell>
          {invoiceItem.discount}
          %
        </TableCell>
        <TableCell>
          {formatCurrency(invoiceItem.itemTotal)}
          $
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={9}
        >
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
          >
            <Box
              margin={1}
              sx={{ display: 'flex', flexDirection: 'column' }}
            >
              <Typography
                gutterBottom
                component="div"
              >
                Descrição do Item
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
              >
                {invoiceItem.item.item_description}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );

  const handleOpenClick = () => {
    setOpenEmailDialog(true);
  };

  const handleSendClick = () => {
    handleOpenClick();
  };

  const validateEmail = async (data) => {
    /* validate email schema */
    try {
      await emailSchema.validate(data ? data.trim() : data, {
        abortEarly: false
      });
      setEmailError(undefined);
      return true;
    } catch (error) {
      setEmailError(error.errors[0]);
    }
    return false;
  };

  const handlePopupClose = () => {
    setOpenEmailDialog(false);
    setEmail(undefined);
  };

  const handleSendEmailButtonClick = async () => {
    const validEmail = await validateEmail(email);

    if (validEmail) {
      handleSendEmail(email);
      handleOpenClick();
      handlePopupClose();
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    validateEmail(email);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card {...other}>
      <Box
        id="debit-note-view-details"
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {renderEfaturaDocumentErrorMessage(debitNote)}
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Série
                </TableCell>
                <TableCell>
                  {debitNote.serieCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Número
                </TableCell>
                <TableCell>
                  {String(debitNote.number).padStart(4, '0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data
                </TableCell>
                <TableCell>
                  {debitNote.dateFormatted}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Período de Pagamento
                </TableCell>
                <TableCell>
                  {debitNote.paymentPeriod.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Limite de Pagamento
                </TableCell>
                <TableCell>
                  {debitNote.paymentLimit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Modo de Pagamento
                </TableCell>
                <TableCell>
                  {debitNote.paymentMode.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Cliente
                </TableCell>
                <TableCell>
                  {debitNote.clientName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Motivo de Retificação
                </TableCell>
                <TableCell>
                  {debitNote.issueReason?.description}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Lista de itens na Nota de Débito
        </Typography>
        <Box sx={{ pt: 3 }}>
          <Card>
            <Scrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell />
                    {documentTableHeader.map((cellContent) => (
                      <TableCell>
                        {cellContent}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {debitNote.items.map((invoiceItem) => {
                    const [open, setOpen] = useState(false);
                    return (
                      rowItems(invoiceItem, open, setOpen)
                    );
                  })}
                </TableBody>
              </Table>
            </Scrollbar>
          </Card>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Comentário(s)
        </Typography>
        { debitNote.commentary && (
          <Box
            sx={{
              p: 2,
              border: 0,
              borderRadius: 0
            }}
            boxShadow={1}
          >
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {debitNote.commentary}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ p: 3 }}>
        <InvoiceItemSumatory
          currentCompany={currentCompany}
          invoiceItems={debitNote.items.map((debitNoteItem) => ({
            discount: debitNoteItem.discount,
            item: debitNoteItem.item.name,
            tax: debitNoteItem.tax.name,
            taxValue: debitNoteItem.tax.value,
            price: debitNoteItem.price,
            quantity: debitNoteItem.quantity,
            unit: debitNoteItem.unit
          }))}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Voltar
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </Box>
      {/* Popup to insert email to be sent */}
      <Dialog
        open={openEmailDialog}
        onClose={() => setOpenEmailDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar para:</DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email*"
            placeholder="Introduzir Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange}
            helperText={emailError}
            error={emailError}
            onFocus={() => setEmailError(undefined)}
            onBlur={handleChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', margin: '0 8px 24px 8px' }}>
          <Button
            onClick={handlePopupClose}
            color="primary"
            variant="outlined"
            style={{ borderRadius: '28px' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSendEmailButtonClick}
            color="primary"
            variant="contained"
            style={{ borderRadius: '28px' }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSendClick}>
          <Typography
            color="textSecondary"
          >
            Enviar por email
          </Typography>
        </MenuItem>
        <MenuItem onClick={handlePrintButtonClick}>
          <Typography
            color="textSecondary"
          >
            Baixar PDF
          </Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
};

ViewDetails.propTypes = {
  debitNote: PropTypes.shape({
    id: PropTypes.string,
    clientName: PropTypes.string,
    issueReason: PropTypes.shape({
      description: PropTypes.string
    }),
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    paymentLimit: PropTypes.string,
    serieCode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    discount: PropTypes.number,
    number: PropTypes.number,
    items: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    }),
    documentType: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    paymentPeriod: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      numberOfDay: PropTypes.number
    }),
    paymentMode: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    efaturaData: PropTypes.shape({
      status: PropTypes.string,
      errorCode: PropTypes.string,
      errorMessage: PropTypes.string
    }),
    isRetried: PropTypes.bool
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  handlePrintButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default ViewDetails;
