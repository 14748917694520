import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { LoginJWT } from '../../components/authentication/login';
import useAuth from '../../hooks/useAuth';

const Login = () => {
  const { platform } = useAuth();

  const navigate = useNavigate();

  const navigateToLoginPage = useCallback(() => {
    navigate('/');
  }, []);

  const handleRegisterButtonClick = useCallback(() => {
    navigate('/authentication/register');
  }, []);

  const handleForgotPasswordPage = useCallback(() => {
    navigate('/forgot_password');
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Samba</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    Bem-vindo!
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    Introduza os seus dados para entrar na plataforma Samba.
                  </Typography>
                </div>
                <Box
                  sx={{
                    height: 32,
                    '& > img': {
                      maxHeight: '100%',
                      width: 'auto'
                    }
                  }}
                />
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'JWT'
                && (
                <LoginJWT
                  navigateToLoginPage={navigateToLoginPage}
                  handleRegisterButtonClick={handleRegisterButtonClick}
                  handleForgotPasswordButtonClick={handleForgotPasswordPage}
                />
                )}
              </Box>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default Login;
