import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Fade from 'react-reveal/Fade';
import CustomCard from '../CustomCard';
import Container from '../Container';

export const Integration = (props) => {
  const { handleScheduleDemo } = props;
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        position: 'relative'
      }}
      component="section"
    >
      <Container>
        <Box sx={{
          display: 'flex',
          flexWrap: mobileDevice ? 'wrap' : 'unset',
          justifyContent: 'center',
          mt: 6
        }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Fade left>
              <CustomCard
                message="Outras opções digitais são caras e difíceis de começar a usar."
                color={theme.palette.purple.light}
              />
            </Fade>
            <Fade left>
              <CustomCard
                color={theme.palette.success.light}
                message="Estamos sempre disponíveis para ajudar. Entre em contato com a nossa equipe e vamos te preparar."
              />
            </Fade>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Fade right>
              <CustomCard
                message="Com o Samba, enviar seus documentos para a e-Fatura é simples e gratuito."
                color={theme.palette.orange.main}
              />
            </Fade>
            <Fade right>
              <CustomCard
                color={theme.palette.purple.main}
                message="Configure Samba e e-Fatura em minutos."
              />
            </Fade>
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          mb: 6
        }}
        >
          <Box
            alt="integration-table"
            component="img"
            src="/static/homepage/integration.svg"
            sx={{
              maxWidth: '100%',
              height: 'auto',
              mt: 6,
              mb: 6,
              zIndex: 9999
            }}
          />
          <a
            href="https://calendly.com/chuva-nilson/samba-demo"
            target="_blank"
            rel="noreferrer"
            onClick={handleScheduleDemo}
          >
            <Button
              alt="Marcar Demo Grátis"
              color="primary"
              type="button"
              variant="contained"
              size="small"
              sx={{
                px: 4,
                borderRadius: 4
              }}
            >
              <Typography
                variant="textSecondary"
                color="#FAFCFF"
                sx={{
                  fontFamily: 'Gill Sans',
                  fontWeight: 600,
                  fontSize: 16,
                  pt: 0.5
                }}
              >
                Marcar Demo Grátis
              </Typography>
            </Button>
          </a>
        </Box>
      </Container>
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-12.svg"
        sx={{
          position: 'absolute',
          left: -160,
          top: -150,
          opacity: 1
        }}
      />
      <Box
        alt="Shape"
        component="img"
        src="/static/homepage/decoration-13.svg"
        sx={{
          position: 'absolute',
          top: mobileDevice ? 100 : 0,
          right: 0,
          bottom: 100,
          opacity: 1
        }}
      />
    </Box>
  );
};
Integration.propTypes = {
  handleScheduleDemo: PropTypes.func.isRequired
};
