import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  Typography
} from '@mui/material';

const FiscalForm = (props) => {
  const {
    values,
    touched,
    handleBlur,
    handleChange,
    errors,
    counties
  } = props;

  const getCountyValue = (countyCode) => {
    const countyValue = counties.find((county) => county?.code === countyCode);

    if (countyValue) {
      return `${countyValue.code} - ${countyValue.name}`;
    }
    return '';
  };

  return (
    <>
      <Box>
        <Box sx={{ mb: 2 }}>
          <Typography color="primary">Informações Fiscais</Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            autoFocus
            error={Boolean(touched.fiscalName && errors.fiscalName)}
            fullWidth
            helperText={touched.fiscalName && errors.fiscalName}
            label="Nome Fiscal"
            name="fiscalName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.fiscalName}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.accountantTaxId && errors.accountantTaxId)}
            fullWidth
            helperText={touched.accountantTaxId && errors.accountantTaxId}
            label="NIF T.O.C (Técnico Oficial de Contas)"
            name="accountantTaxId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.accountantTaxId}
            variant="outlined"
          />
          <TextField
            error={Boolean(touched.countyTaxId && errors.countyTaxId)}
            fullWidth
            helperText={touched.countyTaxId && errors.countyTaxId}
            label="NIF R.F (Repartição de Finanças)"
            name="countyTaxId"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values?.countyTaxId}
            variant="outlined"
          />
          <TextField
            fullWidth
            label="Concelho Repartição de Finanças"
            variant="outlined"
            value={getCountyValue(values.county)}
            disabled
          />
        </Box>
      </Box>
    </>
  );
};

FiscalForm.propTypes = {
  counties: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    country_id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string
  }).isRequired,
  touched: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string
  }).isRequired,
  errors: PropTypes.PropTypes.shape({
    name: PropTypes.string,
    nif: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    email: PropTypes.string,
    fiscalName: PropTypes.string,
    accountantTaxId: PropTypes.string,
    countyTaxId: PropTypes.string,
    county: PropTypes.string
  }).isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired
};

export default FiscalForm;
