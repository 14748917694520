import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import PaymentPeriodListTable from '../../components/payment_period/PaymentPeriodListTable';
import PaymentPeriodForm from '../../components/payment_period/PaymentPeriodForm';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchPaymentPeriods, remove as deletePaymentPeriod } from '../../slices/paymentPeriod';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';
import PaymentPeriodViewDetails from '../../components/payment_period/PaymentPeriodViewDetails';
import DeletePopUp from '../../components/DeletePopUp';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const PaymentPeriod = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isViewPaymentPeriodModalOpen, setIsViewPaymentPeriodModalOpen] = useState(false);
  const [selectedPaymentPeriod, setSelectedPaymentPeriod] = useState(null);
  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { paymentPeriods, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.paymentPeriod);
  const { currentCompany } = useSelector((state) => state.companies);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchPaymentPeriods());
    }
  }, [dispatch, currentCompany]);

  const handleUpdateSelectedPaymentPeriod = () => {
    if (selectedPaymentPeriod) {
      const updatedPaymentPeriod = paymentPeriods.find((paymentperiod) => paymentperiod.id === selectedPaymentPeriod?.id);
      setSelectedPaymentPeriod(updatedPaymentPeriod);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
    handleUpdateSelectedPaymentPeriod();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handlePaymentPeriodDetailOpen = (paymentPeriod) => {
    setSelectedPaymentPeriod(paymentPeriod);
    setIsViewPaymentPeriodModalOpen(true);
  };

  const handlePaymentPeriodDetailClose = () => {
    setSelectedPaymentPeriod([]);
    setIsViewPaymentPeriodModalOpen(false);
  };

  const handlePaymentPeriodDelete = () => {
    handlePaymentPeriodDetailClose();
    handleDeletePopUpClose();
    dispatch(deletePaymentPeriod(selectedPaymentPeriod));
    handlePopUpOpen();
  };

  function returnResult() {
    if (paymentPeriods && paymentPeriods.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <PaymentPeriodListTable
            paymentPeriods={paymentPeriods}
            onOpenPaymentPeriodDetail={handlePaymentPeriodDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhum Período de Pagamento"
        buttonText="Criar Período de Pagamento"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Período de Pagamento | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Período de Pagamento
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Períodos de Pagamento
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
              <PaymentPeriodForm
                onCancel={handleModalClose}
                onSubmitForm={handlePopUpOpen}
                selectedPaymentPeriod={selectedPaymentPeriod}
                isAdd={!isViewPaymentPeriodModalOpen}
              />
            ) }
          </Dialog>
        </Container>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handlePaymentPeriodDetailClose}
          open={isViewPaymentPeriodModalOpen}
        >
          {isViewPaymentPeriodModalOpen && (
          <PaymentPeriodViewDetails
            paymentPeriod={selectedPaymentPeriod}
            onEdit={handleNewClick}
            onDelete={handleDeletePopUpOpen}
            onCancel={handlePaymentPeriodDetailClose}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            onCancel={handleDeletePopUpClose}
            onDelete={handlePaymentPeriodDelete}
          />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default PaymentPeriod;
