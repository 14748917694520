import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Chip
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, InfoOutlined as InfoIcon } from '@mui/icons-material';

import { useEffect, useState } from 'react';
import Scrollbar from '../../Scrollbar';
import { formatCurrency } from '../../../util/formatCurrency';
import {
  eFaturasStatus,
  isDocumentEfaturaStatusFailed as isInvoiceEfaturaStatusFailed,
  getDocumentEfaturaStatusButton as getInvoiceEfaturaStatusButton
} from '../../../util/eFaturasStatus';
import { getEfaturaStatusColors } from '../../../util/getEfaturaStatusColors';

const SalesReceiptListTable = (props) => {
  const {
    draftsStorage,
    deleteDraft,
    setSelectedDraft,
    selectedDraft,
    onClick,
    documents,
    onOpenDetails,
    onView,
    onVoid,
    onDuplicate,
    handleRetrySendDocument,
    retrySendDocumentSuccess,
    ...other
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedSalesReceipt, setSelectedSalesReceipt] = useState(null);
  const [anchorDraft, setAnchorDraft] = useState(null);
  const [documentRetried, setDocumentRetried] = useState(null);
  const [documentsRetried, setDocumentsRetried] = useState([]);

  const handleClick = (event, saleReceipt) => {
    setAnchorEl(event.currentTarget);
    setSelectedSalesReceipt(saleReceipt);
  };

  const handleDraftClick = (event, draft) => {
    setAnchorDraft(event.currentTarget);
    setSelectedDraft(draft);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDraftClose = () => {
    setAnchorDraft(null);
    setSelectedDraft(null);
  };

  const keys = [];

  const getDraftsKeys = () => {
    draftsStorage.forEach((draft) => {
      keys.push(Object.keys(draft));
    });
  };

  const calculateTotal = (totals) => {
    let sum = 0;
    totals.forEach((item) => sum += item.total);
    return sum;
  };

  useEffect(() => {
    if (retrySendDocumentSuccess) {
      setDocumentsRetried([...documentsRetried, documentRetried]);
    }
  }, [retrySendDocumentSuccess, documentRetried]);

  const handleOpenDocumentDetails = (document) => {
    if (!document.isVoided) {
      const isRetried = documentsRetried.includes(document.id);
      onOpenDetails({
        ...document,
        isRetried
      });
    }
  };

  return (

    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Estado E-Fatura
                </TableCell>
                <TableCell>
                  Número
                </TableCell>
                <TableCell>
                  Série
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              id="sales-receipt-list-table-body"
            >
              {draftsStorage && getDraftsKeys()}
              {draftsStorage?.map((draft, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={(e) => handleDraftClick(e, draft)}
                  />
                  <TableRow
                    key={[keys[index]]}
                    hover
                    onClick={() => onClick(draft?.[keys[index]], keys[index])}
                  >
                    <TableCell
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {draft?.[keys[index]].clientName ? draft?.[keys[index]].clientName : 'Consumidor final'}
                    </TableCell>
                    <TableCell>
                      Rascunho
                    </TableCell>
                    <TableCell>
                      <Chip
                        size="medium"
                        style={{ color: getEfaturaStatusColors('none').color, padding: '14px', backgroundColor: getEfaturaStatusColors('none').backgroundColor }}
                        label={getEfaturaStatusColors('none').label}
                      />
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].numberOfDocument}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].serieCode}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].salesReceiptItems ? formatCurrency(calculateTotal(draft[keys[index]].salesReceiptItems)) : null}
                      $
                    </TableCell>
                  </TableRow>

                </>
              ))}
              {documents?.map((salesReceipt, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={!salesReceipt.isVoided ? ((e) => handleClick(e, salesReceipt)) : (() => null)}
                  />
                  <TableRow
                    hover={!salesReceipt.isVoided}
                    key={salesReceipt.id}
                    variant={!salesReceipt.isVoided ? '' : 'disabled'}
                    onClick={() => handleOpenDocumentDetails(salesReceipt)}
                  >
                    <TableCell
                      id={`document-client-name-${index}`}
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {salesReceipt.clientName ? salesReceipt.clientName : 'Consumidor final'}
                    </TableCell>
                    <TableCell variant="disabled">
                      {salesReceipt.paymentStatus && (
                      <Chip
                        size="medium"
                        style={{
                          backgroundColor: `${getEfaturaStatusColors(salesReceipt.paymentStatus).backgroundColor}`,
                          color: `${getEfaturaStatusColors(salesReceipt.paymentStatus).color}`,
                          padding: '14px'
                        }}
                        label={getEfaturaStatusColors(salesReceipt.paymentStatus).label}
                      />
                      )}
                    </TableCell>
                    <TableCell
                      variant="disabled"
                      id="table-cell-e-fatura-column"
                    >
                      {salesReceipt.efaturaData && (
                        <Chip
                          id={`status-e-fatura-button-${index}`}
                          size="medium"
                          style={{
                            padding: isInvoiceEfaturaStatusFailed(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status)) ? '10.2px' : '14px',
                            color: `${eFaturasStatus(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status)).color}`,
                            backgroundColor: `${eFaturasStatus(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status)).backgroundColor}`
                          }}
                          icon={
                            isInvoiceEfaturaStatusFailed(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status)) && (
                              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                <InfoIcon
                                  size="small"
                                  sx={{ color: '#FF5E52' }}
                                />
                              </Box>
                            )
                          }
                          label={eFaturasStatus(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status)).label}
                          clickable={isInvoiceEfaturaStatusFailed(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status))}
                          onClick={(e) => {
                            if (isInvoiceEfaturaStatusFailed(getInvoiceEfaturaStatusButton(documentsRetried, salesReceipt.id, salesReceipt.efaturaData.status))) {
                              e.stopPropagation();
                              handleRetrySendDocument(salesReceipt.id);
                              setDocumentRetried(salesReceipt.id);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell variant="disabled">
                      {salesReceipt.number}
                    </TableCell>
                    <TableCell variant="disabled">
                      {salesReceipt.serieCode}
                    </TableCell>
                    <TableCell variant="disabled">
                      {formatCurrency(salesReceipt.totalGeneral)}
                      $
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorDraft}
        keepMounted
        open={Boolean(anchorDraft)}
        onClose={handleDraftClose}
      >
        <MenuItem
          onClick={() => {
            deleteDraft(Object.keys(selectedDraft));
            handleDraftClose();
          }}
        >
          Eliminar
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onView(selectedSalesReceipt);
            handleClose();
          }}
        >
          Ver
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDuplicate(selectedSalesReceipt);
            handleClose();
          }}
        >
          Duplicar
        </MenuItem>
      </Menu>
    </Card>
  );
};

SalesReceiptListTable.propTypes = {
  documents: PropTypes.arrayOf({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientName: PropTypes.string,
    serieCode: PropTypes.string,
    paymentPeriod: PropTypes.string,
    isVoided: PropTypes.bool,
    paymentMode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    valueWithoutTax: PropTypes.number,
    totalTax: PropTypes.number,
    valueWithTax: PropTypes.number,
    discount: PropTypes.number,
    totalGeneral: PropTypes.number,
    number: PropTypes.number,
    efaturaData: PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string
    })
  }).isRequired,
  draftsStorage: PropTypes.object.isRequired,
  deleteDraft: PropTypes.func.isRequired,
  selectedDraft: PropTypes.object.isRequired,
  setSelectedDraft: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onOpenDetails: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onVoid: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  handleRetrySendDocument: PropTypes.func.isRequired,
  retrySendDocumentSuccess: PropTypes.bool.isRequired
};

export default SalesReceiptListTable;
