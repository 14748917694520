import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import { Box, Card, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactMarkdown from 'react-markdown';
import { useDispatch, useSelector } from '../../store';
import { fetchById as fetchPost } from '../../slices/blogPosts';

import { blogTheme } from '../../theme/blogTheme';

import LoadingPopUp from '../../components/LoadingPopUp';
import NavBar from '../../components/blogPage/NavBar';
import Footer from '../../components/blogPage/Footer';
import '../../theme/fonts.css';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    minHeight: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6rem 2rem 2rem 2rem'
  },
  cardContent: {
    padding: theme.spacing(4),
    width: '88vw',
    minheight: '100vh',
    margin: '2rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)'
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '88vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tags: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  time: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const PostDetails = () => {
  const { id } = useParams();
  const classes = useStyles();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();

  const { post, loading } = useSelector((state) => state.blogPosts);

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchPost(id));
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  return (
    <>
      <Helmet>
        <title>Blog | Samba</title>
      </Helmet>
      <ThemeProvider theme={blogTheme}>
        <CssBaseline />
        <NavBar />
        <Box
          className={classes.cardContainer}
        >
          <Box className={classes.titles}>
            <Typography
              variant="title"
              align="left"
              maxHeight="120px"
              sx={{
                textTransform: 'uppercase',
                fontSize: 30,
                py: 2
              }}
            >
              {post.title}
            </Typography>
          </Box>
          <Card
            className={classes.cardContent}
          >
            {post.markdown && <ReactMarkdown>{post.markdown}</ReactMarkdown>}
          </Card>
        </Box>
        <Box
          alt="Shape"
          component="img"
          width="748.87px"
          height="791.98px"
          src="/static/blogPage/blog-shape-1-left.svg"
          sx={{
            zIndex: -2,
            position: 'absolute',
            width: 286,
            left: -40,
            top: -80
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/blogPage/blog-shape-1-right.svg"
          sx={{
            zIndex: -2,
            position: 'absolute',
            right: 0,
            top: 34
          }}
        />
        <Footer
          sx={{
            backgroundColor: 'background.grey'
          }}
        />
      </ThemeProvider>
    </>
  );
};

export default PostDetails;
