import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  currencies: [],
  loading: false,
  loadingError: false
};

const slice = createSlice({
  name: 'currencies',
  initialState,
  reducers: {
    _get: (state) => {
      state.loading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.currencies = payload;
      state.loading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.loading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'currencies/fetch',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get('currencies');
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
