import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';
import LoadingPopUp from '../LoadingPopUp';
import SuccessPopUp from '../SuccessPopUp';
import UnsuccessPopUp from '../UnsuccessPopUp';

export default function StatusPopUpContainer(props) {
  const {
    isOpen,
    isLoading,
    hasError,
    onClose,
    onSuccessMessage,
    onErrorMessage,
    onLoadingMessage
  } = props;

  function returnResult() {
    return (
      hasError
        ? (
          <UnsuccessPopUp message={onErrorMessage} />
        )
        : (
          <SuccessPopUp message={onSuccessMessage} />
        )
    );
  }
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={isOpen}
      sx={{
        zIndex: 99999
      }}
    >
      {isLoading
        ? (<LoadingPopUp message={onLoadingMessage} />)
        : returnResult()}
    </Dialog>
  );
}

StatusPopUpContainer.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSuccessMessage: PropTypes.string.isRequired,
  onErrorMessage: PropTypes.string.isRequired,
  onLoadingMessage: PropTypes.string.isRequired
};
