import PropTypes from 'prop-types';
import {
  Box,
  AppBar,
  Toolbar,
  Typography
} from '@mui/material';
import { useEffect, useState } from 'react';

const Header = ({ handleLogoClick }) => {
  const [headerBorder, setHeaderBorder] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setHeaderBorder(true);
    } else {
      setHeaderBorder(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
  }, [window.scrollY]);

  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary',
        boxShadow: headerBorder ? '0px 2px 10px 10px rgba(80, 80, 80, 0.1)' : ''
      }}
    >
      <Toolbar sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        py: 1
      }}
      >
        <Box>
          <button
            style={{ border: 'none', background: 'transparent', cursor: 'pointer' }}
            type="button"
            onClick={handleLogoClick}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
            >
              <Typography
                color="primary"
                sx={{
                  left: 0,
                  fontSize: 36,
                  fontFamily: 'Blogger Sans'
                }}
              >
                SAMBA
              </Typography>
            </Box>
          </button>
        </Box>
        <Box />
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  handleLogoClick: PropTypes.func.isRequired
};

export default Header;
