import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import SeriesListTable from '../../components/series/ListTable';
import SeriesForm from '../../components/series/Form';

import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';

import { useDispatch, useSelector } from '../../store';
import { fetch as fetchSeries, create as createSeries, update as updateSeries, remove as deleteSeries } from '../../slices/series';
import { fetch as fetchFiscalYears } from '../../slices/fiscalYear';
import SeriesViewDetails from '../../components/series/ViewDetails';
import DeletePopUp from '../../components/DeletePopUp';
import { useForm } from '../../hooks/useForm';
import Scrollbar from '../../components/Scrollbar';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const Series = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [selectedSeries, setSelectedSeries] = useState(null);

  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { series, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).series;
  const { currentCompany } = useSelector((state) => state).companies;

  const { fiscalYears } = useSelector((state) => state).fiscalYear;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchSeries());
      dispatch(fetchFiscalYears());
    }
  }, [dispatch, currentCompany]);

  const handleUpdateSelectedSeries = () => {
    if (selectedSeries) {
      const updatedSeries = series.find((serie) => serie.id === selectedSeries?.id);
      setSelectedSeries(updatedSeries);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleUpdateSelectedSeries();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleSelectSeries = (serie) => {
    setSelectedSeries(serie);
  };

  const handleUnSelectSeries = () => {
    setSelectedSeries(null);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedSeries();
    setIsPopUpOpen(false);
  };

  const handleCreateOrUpdateSeries = (serie) => {
    if (selectedSeries) {
      serie.id = selectedSeries.id;
      dispatch(updateSeries(serie));
    } else {
      dispatch(createSeries(serie));
    }
    handlePopUpOpen();
  };

  const handleSeriesDelete = () => {
    handleUnSelectSeries();
    handleDeletePopUpClose();
    dispatch(deleteSeries(selectedSeries));
    handlePopUpOpen();
  };

  function returnResult() {
    if (series && series.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <SeriesListTable
            series={series}
            onSelectSeries={handleSelectSeries}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Série"
        buttonText="Criar Série"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Séries | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Séries
              </Typography>
              <Typography
                color="textSecondary"
                variant="overline"
              >
                Minhas Séries
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
                id="serie-new-button"
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
              <SeriesForm
                onCancel={handleModalClose}
                onSubmitForm={handleCreateOrUpdateSeries}
                serie={selectedSeries}
                fiscalYears={fiscalYears}
                useForm={useForm}
              />
            )}
          </Dialog>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleUnSelectSeries}
            open={selectedSeries}
          >
            <Scrollbar>
              {selectedSeries && (
              <SeriesViewDetails
                serie={selectedSeries}
                onEdit={handleNewClick}
                onDelete={handleDeletePopUpOpen}
                onCancel={handleUnSelectSeries}
              />
              )}
            </Scrollbar>
          </Dialog>
        </Container>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
            <DeletePopUp
              onCancel={handleDeletePopUpClose}
              onDelete={handleSeriesDelete}
            />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default Series;
