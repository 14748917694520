/**
 * Calculate the total sum of `totalGeneral` from receipt, invoiceReceipt, and salesReceipt.
 *
 * @param {Object} data - The data object containing receipt, invoiceReceipt, and salesReceipt.
 * @param {Object} data.receipt - The receipt object.
 * @param {Object} data.receipt.sum - The sum object within receipt.
 * @param {number} data.receipt.sum.totalGeneral - The total general amount in receipt.
 * @param {Object} data.invoiceReceipt - The invoiceReceipt object.
 * @param {Object} data.invoiceReceipt.sum - The sum object within invoiceReceipt.
 * @param {number} data.invoiceReceipt.sum.totalGeneral - The total general amount in invoiceReceipt.
 * @param {Object} data.salesReceipt - The salesReceipt object.
 * @param {Object} data.salesReceipt.sum - The sum object within salesReceipt.
 * @param {number} data.salesReceipt.sum.totalGeneral - The total general amount in salesReceipt.
 *
 * @returns {number} The total sum of `totalGeneral` from receipt, invoiceReceipt, and salesReceipt.
 */

export const totalReceived = (data) => {
  const receiptTotal = data.receipt?.sum?.totalGeneral || 0;
  const invoiceReceiptTotal = data.invoiceReceipt?.sum?.totalGeneral || 0;
  const salesReceiptTotal = data.salesReceipt?.sum?.totalGeneral || 0;

  return receiptTotal + invoiceReceiptTotal + salesReceiptTotal;
};
