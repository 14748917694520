import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';

export default function BannerSection(props) {
  const { message, odd, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box
        alt="Stars"
        component="img"
        src="/static/homepage/banner-section.svg"
        sx={{
          opacity: 0.7,
          objectFit: 'cover',
          height: 350,
          width: '100%'
        }}
      />
      <Typography
        variant="title"
        align="center"
        sx={{
          position: 'absolute',
          textTransform: 'uppercase',
          fontSize: 60,
          lineHeight: 1,
          whiteSpace: 'break-spaces'
        }}
      >
        {message}
      </Typography>
    </Box>
  );
}
BannerSection.propTypes = {
  message: PropTypes.string.isRequired,
  odd: PropTypes.bool
};
