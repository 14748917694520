import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const ProvidersListTable = (props) => {
  const { providers, ...other } = props;
  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Nome Fiscal
                </TableCell>
                <TableCell>
                  NIF
                </TableCell>
                <TableCell>
                  Endereço
                </TableCell>
                <TableCell>
                  Telefone
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {providers.map((provider) => (
                <TableRow
                  hover
                  key={provider.id}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {provider.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {provider.fiscal_name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {provider.tax_id}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {provider.address}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {`${provider.phoneNumber.country_code || ''} ${provider.phoneNumber.phone_number || ''}`}
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

ProvidersListTable.propTypes = {
  providers: PropTypes.array.isRequired
};

export default ProvidersListTable;
