import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

import calculateDiscount from '../../util/calculateDiscount';
import calculateTotalGeneral from '../../util/calculateTotalGeneral';
import calculateValueOfTax from '../../util/calculateValueOfTax';
import calculateValueWithTax from '../../util/calculateValueWithTax';
import calculateValueWithoutTax from '../../util/calculateValueWithoutTax';
import { formatCurrency } from '../../util/formatCurrency';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const InvoiceItemSumatory = (props) => {
  const { invoiceItems, currentCompany, ...other } = props;

  const valueWithoutTax = calculateValueWithoutTax(invoiceItems);
  const totalTax = calculateValueOfTax(invoiceItems);
  const valueWithTax = calculateValueWithTax(invoiceItems, currentCompany.companyType);
  const discount = calculateDiscount(invoiceItems, currentCompany.companyType);
  const totalGeneral = calculateTotalGeneral(valueWithoutTax, totalTax, discount, currentCompany.companyType);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  return (
    <Card {...other}>
      <Box sx={{ p: 2 }}>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueWithoutTaxLabel}
              </TableCell>
              <TableCell>
                {formatCurrency(valueWithoutTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueOfTaxLabel}
              </TableCell>
              <TableCell>
                {formatCurrency(totalTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                {taxOrRetentionLabels.valueWithTaxLabel}
              </TableCell>
              <TableCell>
                {formatCurrency(valueWithTax || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 500 }}>
                Desconto
              </TableCell>
              <TableCell>
                {formatCurrency(discount || 0)}
                $
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 600 }}>
                Total Geral
              </TableCell>
              <TableCell>
                {formatCurrency(totalGeneral || 0)}
                $
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

InvoiceItemSumatory.propTypes = {
  invoiceItems: PropTypes.arrayOf({
    discount: PropTypes.number,
    item: PropTypes.string,
    tax: PropTypes.string,
    taxValue: PropTypes.number,
    price: PropTypes.number,
    quantity: PropTypes.number,
    unit: PropTypes.string,
    total: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired
};

export default InvoiceItemSumatory;
