function calculateValueOfTax(invoiceItems = []) {
  let total = 0;

  invoiceItems.map((invoiceItem) => {
    const sum = (invoiceItem.price * invoiceItem.quantity) * (invoiceItem.taxValue / 100);
    total += sum;
    return total;
  });
  return total;
}

export default calculateValueOfTax;
