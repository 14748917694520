import { Helmet } from 'react-helmet-async';
import { Box } from '@mui/material';
import React, { useCallback, useEffect } from 'react';

import { fetchDaily, fetchLastThreeMonths, fetchMonthly } from '../../../slices/dashboard';

import { useSelector, useDispatch } from '../../../store';

import {
  EFATURA_BANNER_MESSAGE,
  HELMET_TITLES,
  PAGES_TEXT,
} from '../../../constants';

import { isCompanyNotIntegratedWithEFatura } from '../../../util/eFaturasStatus';
import WarningAlertOnPage from '../../../components/AlertOnPage';
import H1Section from '../../../components/Header/H1Section';
import Wrapper from '../../../components/Wrapper';
import DashboardStatsChart from '../../../components/DashboardStatsChart';
import LoadingPopUp from '../../../components/LoadingPopUp';
import { totalSales } from '../../../util/totalSales';
import { totalReceived } from '../../../util/totalReceived';

const Summary = () => {
  const dispatch = useDispatch();

  const { currentCompany, isLoading } = useSelector((state) => state.companies);
  const {
    isLoadingDailySummary,
    isLoadingMonthlySummary,
    isLoadingLastThreeMonthsSummary,
    dailySummary,
    monthlySummary,
    lastThreeMonthsSummary
  } = useSelector((state) => state.dashboard);

  const renderBanner = () => isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
    <WarningAlertOnPage message={EFATURA_BANNER_MESSAGE} />
  );

  const formatChartData = useCallback((input) => {
    if (input) {
      const labelsMap = {
        invoice: 'Faturas',
        receipt: 'Recibos',
        invoiceReceipt: 'Fatura Recibo',
        salesReceipt: 'Talão Venda'
      };
      const orderedKeys = ['invoice', 'receipt', 'invoiceReceipt', 'salesReceipt'];

      return orderedKeys.map((key, index) => {
        return {
          id: index,
          value: input[key].sum.totalGeneral,
          label: labelsMap[key]
        };
      });
    }
  }, []);

  const renderContent = () => {
    if (!isLoading) {
      return (
        <>
          <Box>
          <H1Section
            title={PAGES_TEXT.DASHBOARD.SUMMARY.TITLE}
            description={`Olá, ${currentCompany.name}! Aqui está sua visão diária, mensal e dos últimos 3 meses para uma análise rápida e fácil.`}
          />
        </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          <Box>
            <DashboardStatsChart
              title={PAGES_TEXT.DASHBOARD.STATISTICS.DAILY.TITLE}
              description={PAGES_TEXT.DASHBOARD.STATISTICS.DAILY.DESCRIPTION}
              salesTitle={PAGES_TEXT.DASHBOARD.CARD.SALES}
              salesValue={dailySummary ? totalSales(dailySummary) : 0}
              receivedTitle={PAGES_TEXT.DASHBOARD.CARD.RECEIVED}
              receivedValue={dailySummary ? totalReceived(dailySummary) : 0}
              dataSetValues={formatChartData(dailySummary)}
              isLoading={isLoadingDailySummary}
              showGraph={false}
            />
          </Box>

          <Box>
            <DashboardStatsChart
              title={PAGES_TEXT.DASHBOARD.STATISTICS.MONTHLY.TITLE}
              description={PAGES_TEXT.DASHBOARD.STATISTICS.MONTHLY.DESCRIPTION}
              salesTitle={PAGES_TEXT.DASHBOARD.CARD.SALES}
              salesValue={monthlySummary ? totalSales(monthlySummary) : 0}
              receivedTitle={PAGES_TEXT.DASHBOARD.CARD.RECEIVED}
              receivedValue={monthlySummary ? totalReceived(monthlySummary) : 0}
              dataSetValues={formatChartData(monthlySummary)}
              isLoading={isLoadingMonthlySummary}
            />
          </Box>

          <Box>
            <DashboardStatsChart
              title={PAGES_TEXT.DASHBOARD.STATISTICS.LAST_THREE_MONTHS.TITLE}
              description={PAGES_TEXT.DASHBOARD.STATISTICS.LAST_THREE_MONTHS.DESCRIPTION}
              salesTitle={PAGES_TEXT.DASHBOARD.CARD.SALES}
              salesValue={lastThreeMonthsSummary ? totalSales(lastThreeMonthsSummary) : 0}
              receivedTitle={PAGES_TEXT.DASHBOARD.CARD.RECEIVED}
              receivedValue={lastThreeMonthsSummary ? totalReceived(lastThreeMonthsSummary) : 0}
              dataSetValues={formatChartData(lastThreeMonthsSummary)}
              isLoading={isLoadingLastThreeMonthsSummary}
            />
          </Box>
        </Box>
        </>
      )
    }
    return (
      <LoadingPopUp />
    );
  }

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchDaily());
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchMonthly());
    }
  }, [currentCompany]);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchLastThreeMonths())
    }
  }, [currentCompany]);

  return (
    <>
      <Helmet>
        <title>{HELMET_TITLES.DASHBOARD.SUMMARY}</title>
      </Helmet>
      {renderBanner()}
      <Wrapper id="summary-page">
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 4 }}>
          {renderContent()}
        </Box>
      </Wrapper>
    </>
  );
};

export default Summary;
