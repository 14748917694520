import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import H2Section from '../Header/H2Section';
import { formatCurrency } from '../../util/formatCurrency';
import StatisticsCard from '../Cards/StatisticsCard';
import { PieChart } from '@mui/x-charts/PieChart';
import Card from '../Card';
import Skeleton from '@mui/material/Skeleton';

const DashboardStatsChart = (props) => {
  const {
    title,
    description,
    salesTitle,
    salesValue,
    receivedTitle,
    receivedValue,
    dataSetValues,
    isLoading,
    showGraph
  } = props;

  const renderGraph = useMemo(() => {
    if (isLoading) {
      return (
        <Box sx={{ mt: 2 }}>
          <Skeleton variant="rounded" width={400} height={200} />
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex' }}>
        <Card>
          <PieChart
            colors={['rgba(33, 200, 195, 0.6)', 'rgba(198, 122, 206, 0.6)', 'rgba(3, 149, 169, 0.6)', 'rgba(241, 179, 122, 0.6)']}
            series={[
              {
                data: dataSetValues || [],
                innerRadius: 30,
                highlightScope: { faded: 'global', highlighted: 'item' },
                faded: { innerRadius: 30, additionalRadius: -30, color: 'gray' }
              },
            ]}
            width={400}
            height={200}
          />
        </Card>
      </Box>
    );
  }, [isLoading, dataSetValues]);

  return (
    <Box>
      <H2Section
        title={title}
        description={description}
      />
      <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
        <StatisticsCard
          isLoading={isLoading}
          title={salesTitle}
          description={formatCurrency(salesValue)}
        />
        <StatisticsCard
          isLoading={isLoading}
          title={receivedTitle}
          description={formatCurrency(receivedValue)}
        />
      </Box>
      {showGraph && renderGraph}
    </Box>
  );
};

DashboardStatsChart.defaultProps = {
  showGraph: true
};

DashboardStatsChart.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  salesTitle: PropTypes.string.isRequired,
  salesValue: PropTypes.number.isRequired,
  dataSetValues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired
  })).isRequired,
  receivedTitle: PropTypes.string.isRequired,
  receivedValue: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showGraph: PropTypes.bool
};

export default DashboardStatsChart;
