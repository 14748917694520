import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import useSettings from '../../hooks/useSettings';
import UnitListTable from '../../components/unit/UnitListTable';
import UnitForm from '../../components/unit/UnitForm';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchUnits, remove as deleteUnit } from '../../slices/units';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import Toast from '../../components/Toast';
import { TOAST_TYPE, EFATURA_BANNER_MESSAGE } from '../../constants';
import UnitViewDetails from '../../components/unit/UnitViewDetails';
import DeletePopUp from '../../components/DeletePopUp';
import WarningAlertOnPage from '../../components/AlertOnPage';

import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const Unit = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [isViewUnitModalOpen, setIsViewUnitModalOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(null);

  const { settings } = useSettings();
  const dispatch = useDispatch();
  const { units, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.units);
  const { currentCompany } = useSelector((state) => state.companies);
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchUnits());
    }
  }, [dispatch, currentCompany]);

  const handleUpdateSelectedUnit = () => {
    if (selectedUnit) {
      const updatedUnit = units.find((unit) => unit.id === selectedUnit?.id);
      setSelectedUnit(updatedUnit);
    }
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    handleUpdateSelectedUnit();
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
    handleUpdateSelectedUnit();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleUnitDetailOpen = (unit) => {
    setSelectedUnit(unit);
    setIsViewUnitModalOpen(true);
  };

  const handleUnitDetailClose = () => {
    setSelectedUnit([]);
    setIsViewUnitModalOpen(false);
  };

  const handleUnitDelete = () => {
    handleUnitDetailClose();
    handleDeletePopUpClose();
    dispatch(deleteUnit(selectedUnit));
    handlePopUpOpen();
  };

  function returnResult() {
    if (units && units.length > 0) {
      return (
        <Box sx={{ mt: 3 }}>
          <UnitListTable
            units={units}
            onOpenUnitDetail={handleUnitDetailOpen}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma unidade"
        buttonText="Criar Unidade"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  return (
    <>
      <Helmet>
        <title>Unidade | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container maxWidth={settings.compact ? 'xl' : false}>
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                Unidade
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Unidades
              </Typography>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
          </Grid>
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          <Dialog
            fullWidth
            maxWidth="sm"
            onClose={handleModalClose}
            open={isModalOpen}
          >
            {isModalOpen && (
            <UnitForm
              onCancel={handleModalClose}
              onSubmitForm={handlePopUpOpen}
              selectedUnit={selectedUnit}
              isAdd={!isViewUnitModalOpen}
            />
            )}
          </Dialog>
        </Container>
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleUnitDetailClose}
          open={isViewUnitModalOpen}
        >
          {isViewUnitModalOpen && (
          <UnitViewDetails
            unit={selectedUnit}
            onEdit={handleNewClick}
            onDelete={handleDeletePopUpOpen}
            onCancel={handleUnitDetailClose}
          />
          )}
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            onCancel={handleDeletePopUpClose}
            onDelete={handleUnitDelete}
          />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default Unit;
