import React from 'react';
import PropTypes from 'prop-types';
import { Dialog } from '@mui/material';

const DialogPopUp = ({ open, onClose, children }) => (
  <Dialog
    fullWidth
    maxWidth="sm"
    onClose={onClose}
    open={open}
  >
    {children}
  </Dialog>
);

DialogPopUp.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired
};

export default DialogPopUp;
