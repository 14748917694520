import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  paymentModeDefaults: undefined,
  isLoading: false,
  error: false
};

const slice = createSlice({
  name: 'paymentModeDefaults',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.paymentModeDefaults = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.paymentModeDefaults = undefined;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'paymentModeDefaults/fetch',
  async (_, { dispatch, getState }) => {
    const { countryId } = getState().companies.currentCompany;
    dispatch(_get());
    try {
      const response = await axios.get(`/countries/${countryId}/payment_mode_defaults`);
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
