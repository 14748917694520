import { useEffect, useRef, useCallback, useState } from 'react';
import { useParams, useNavigate, Navigate, useSearchParams } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';

import { Box, Card, Button, TextField, Container, useMediaQuery, Dialog, DialogActions, DialogContent, DialogTitle, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CloudDownload as CloudDownloadIcon, Drafts as DraftsIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from '../../store';
import { fetchReceiptPdfById, sendPublicReceiptEmail } from '../../slices/receipts';

import { homeTheme } from '../../theme/homeTheme';

import RenderPdf from '../../components/RenderPdf';
import Header from '../../components/PublicInvoice/Header';
import { Footer } from '../../components/homePage/Footer';
import LoadingPopUp from '../../components/LoadingPopUp';

import '../../theme/fonts.css';
import './styles.css';
import Toast from '../../components/Toast';
import { POPUP_STATUS, TOAST_TYPE } from '../../constants';
import { emailSchema } from '../../util/schema/emailValidation';
import StatusMessagePopup from '../../components/StatusMessagePopup';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '7rem auto',
    maxWidth: '42rem'
  },
  cardContent: {
    alignItems: 'center',
    minHeight: '100vh',
    margin: '1rem',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)',
    overflow: 'auto',
    padding: '0',
    maxWidth: '320rem',
    zIndex: 3
  },
  buttonsGroup: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: '48px',
    margin: '0 1rem'
  }
}));

const index = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const classes = useStyles(isMobile);
  const { id } = useParams();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();
  const theme = useTheme();

  const [queryParams] = useSearchParams();

  const [toastType, setToastType] = useState(null);
  const [message, setMessage] = useState(null);
  const [showToast, setShowToast] = useState(null);

  const { receiptPdf, loading, loadingError, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).receipts;

  const [email, setEmail] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [paymentStatusInfo, setPaymentStatusInfo] = useState({});
  const [isStatusPopUpOpen, setIsStatusPopUpOpen] = useState(false);

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchReceiptPdfById(id));
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );

  const validateEmail = async (data) => {
    try {
      await emailSchema.validate(data ? data.trim() : data, {
        abortEarly: false
      });
      setEmailError(null);
      return true;
    } catch (error) {
      setEmailError(error.errors[0]);
    }
    return false;
  };

  const handleSendEmail = () => {
    dispatch(sendPublicReceiptEmail({ id, emailList: [{ email }] }));
  };

  const handleSendEmailButtonClick = async () => {
    const validEmail = await validateEmail(email);
    if (validEmail) {
      handleSendEmail(email);
      setShowToast(true);
      setOpenEmailDialog(false);
      setEmail();
    }
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    const successMessageParams = queryParams.get('successMessage');
    if (successMessageParams) {
      handleSetMessage(successMessageParams);
      setToastType(TOAST_TYPE.SUCCESS);
      setShowToast(true);
    }
  }, [queryParams]);

  useEffect(() => {
    const successResponse = queryParams.get('success');
    if (successResponse) {
      setPaymentStatusInfo({
        status: POPUP_STATUS.success,
        color: theme.palette.primary.main,
        title: 'Pagamento efetuado com sucesso!',
        text: 'O recibo será enviado para o email.',
        subText: null
      });
      setIsStatusPopUpOpen(true);
    }
  }, [queryParams]);

  const handleStatusMessagePopUpClose = () => {
    setIsStatusPopUpOpen(false);
  };

  const handlePopupClose = () => {
    setOpenEmailDialog(false);
    setEmail(undefined);
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    validateEmail(email);
  };

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  if (loadingError) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>Recibo | Samba</title>
      </Helmet>
      <ThemeProvider theme={homeTheme}>
        <Header handleLogoClick={handleLogoClick} />
        {/* BODY */}
        <Container
          maxWidth="md"
          className={classes.articles}
        >
          <div className={classes.buttonsGroup}>
            <Button
              component="a"
              href={receiptPdf}
              download
              sx={{ textDecoration: 'none', mr: 1 }}
              variant="outlined"
              endIcon={<CloudDownloadIcon />}
            >
              BAIXAR PDF
            </Button>
            <Button
              id="public-receipt-email-button"
              onClick={() => setOpenEmailDialog(true)}
              variant="contained"
              endIcon={<DraftsIcon />}
            >
              ENVIAR PARA EMAIL
            </Button>
          </div>
          <Card
            className={classes.cardContent}
          >
            <RenderPdf file={receiptPdf} />
          </Card>
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-left.svg"
            sx={{
              position: 'absolute',
              height: 460,
              left: -34,
              top: 0,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-left.svg"
            sx={{
              position: 'absolute',
              height: 360,
              left: -82,
              top: 680,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-3-left.svg"
            sx={{
              position: 'absolute',
              width: 200,
              height: 300,
              left: -84,
              top: 940,
              opacity: 1,
              zIndex: -13
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 40,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 620,
              opacity: 1,
              zIndex: -13
            }}
          />
        </Container>
        <Box
          alt="Shape"
          sx={{ zIndex: 3 }}
        >
          <Footer
            sx={{
              zIndex: 4,
              backgroundColor: 'background.grey'
            }}
          />
        </Box>
        <Toast
          isOpen={showToast}
          toastType={toastType}
          message={message}
          onClose={() => setShowToast(false)}
        />
      </ThemeProvider>
      <Dialog
        fullWidth
        maxWidth="xs"
        open={openEmailDialog}
        onClose={() => setOpenEmailDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle
          id="form-dialog-title"
          sx={{ padding: '2rem 2rem 0rem 2rem' }}
        >
          Enviar para:
        </DialogTitle>
        <DialogContent sx={{ padding: '2rem 2rem 0rem 2rem' }}>
          <TextField
            autoFocus
            id="public-receipt-email-input"
            label="Email*"
            placeholder="Introduzir Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange}
            helperText={emailError}
            error={emailError}
            onFocus={() => setEmailError(undefined)}
            onBlur={handleChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', py: '2.5rem', px: '2rem' }}>
          <Button
            id="public-receipt-email-cancel-button"
            onClick={handlePopupClose}
            color="primary"
            variant="outlined"
            sx={{ borderRadius: '28px', width: '10rem' }}
          >
            Cancelar
          </Button>
          <Button
            id="public-receipt-email-send-button"
            onClick={handleSendEmailButtonClick}
            color="primary"
            variant="contained"
            style={{ borderRadius: '28px', width: '10rem' }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <StatusMessagePopup
        isOpen={isStatusPopUpOpen}
        handleClosePopUp={handleStatusMessagePopUpClose}
        status={paymentStatusInfo?.status}
        color={paymentStatusInfo?.color}
        title={paymentStatusInfo?.title}
        text={paymentStatusInfo?.text}
        subText={paymentStatusInfo?.subText}
      />
    </>
  );
};

export default index;
