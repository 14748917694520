import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import analytics, { events } from '../services/analytics';
import { uploadFile } from '../util/uploadFile';

let companyId;

const initialState = {
  itens: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  uploadSuccess: false,
  uploadFailure: false,
  successMessage: '',
  errorMessage: ''
};

const slice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.itens = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.itens = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createSuccess: (state, { payload }) => {
      if (state.itens === undefined) {
        state.itens = [];
      }
      state.itens.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _updateSuccess: (state, { payload }) => {
      const indexToUpdate = state.itens.findIndex(item => item.id === payload.id);

      if (indexToUpdate !== -1) {
        state.itens[indexToUpdate] = payload;
      }
      
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _delete: (state) => {
      state.onRequest = true;
    },
    _deletingSuccess: (state, { payload }) => {
      state.itens = state.itens.filter((item) => item.id !== payload.id);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _deletingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _uploadSuccess: (state) => {
      state.uploadSuccess = true;
      state.uploadFailure = false;
    },
    _uploadFailure: (state) => {
      state.uploadFailure = true;
      state.uploadSuccess = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _createOrUpdate,
  _createSuccess,
  _updateSuccess,
  _createOrUpdatingFailure,
  _delete,
  _deletingFailure,
  _deletingSuccess,
  _setSuccessMessage,
  _uploadSuccess,
  _uploadFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'item/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/items`);

      const itens = data.map((item) => ({
        id: item.id,
        code: item.code,
        name: item.name,
        cost: item.cost === '0' ? '' : item.cost,
        sellingPrice: item.selling_price === '0' ? '' : item.selling_price,
        unit: item.unit,
        tax: item.tax,
        createdAt: item.created_at,
        isProduct: item.is_product,
        images: item.images
      }));

      dispatch(_getSuccess(itens));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'item/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    let item;

    try {
      const items = {
        code: data.code.trim(),
        name: data.name.trim(),
        cost: data.cost === '0' ? null : Number(data.cost),
        selling_price: data.sellingPrice === '0' ? null : Number(data.sellingPrice),
        unit_id: data.unitId,
        tax_id: data.taxId,
        is_product: data.isProduct
      };

      const response = await axios.post(`/companies/${companyId}/items`, items);
      item = {
        id: response.data.id,
        code: response.data.code,
        name: response.data.name,
        cost: response.data.cost === '0' ? '' : response.data.cost,
        sellingPrice: response.data.selling_price === '0' ? '' : response.data.selling_price,
        unit: response.data.unit,
        tax: response.data.tax,
        createdAt: response.data.created_at,
        isProduct: response.data.is_product,
        images: []
      };

      analytics.dispatchEvent(events.itemCreated());
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }

    try {
      if (data.file && item?.id) {
        const updatedItem = {
          id: item.id,
          code: item.code,
          name: item.name,
          cost: item.cost === '0' ? '' : item.cost,
          sellingPrice: item.sellingPrice === '0' ? '' : item.sellingPrice,
          unit: item.unit,
          tax: item.tax,
          createdAt: item.createdAt,
          isProduct: item.isProduct,
          images: [
            {
              rank: 0,
              url: data.file.preview
            }
          ]
        };
        await uploadFile(companyId, item.id, data.file);
        dispatch(_createSuccess(updatedItem));
      }

      if (!data.file) {
        dispatch(_createSuccess(item));
      }

      dispatch(_setSuccessMessage('Guardado com sucesso!'));
      dispatch(_uploadSuccess());
    } catch (e) {
      dispatch(_uploadFailure());
    }
  }
);

export const update = createAsyncThunk(
  'item/update',
  async (data, { dispatch, getState }) => {
    dispatch(_createOrUpdate());
    try {
      const editedItem = {
        code: data.code.trim(),
        name: data.name.trim(),
        cost: data.cost === '0' ? null : Number(data.cost),
        selling_price: data.sellingPrice === '0' ? null : Number(data.sellingPrice),
        unit_id: data.unitId,
        tax_id: data.taxId,
        is_product: data.isProduct
      };

      await axios.put(`/companies/${companyId}/items/${data.id}`, editedItem);

      const { units } = getState().units;
      const { taxes } = getState().taxes;

      const updatedItem = {
        id: data.id,
        code: data.code,
        name: data.name,
        isProduct: data.isProduct,
        cost: data.cost,
        sellingPrice: data.sellingPrice,
        unit: units.find((unit) => unit.id === data.unitId),
        tax: taxes.find((tax) => tax.id === data.taxId),
        images: [],
      }

      dispatch(_updateSuccess(updatedItem));

      if (!data.file) {
        dispatch(_setSuccessMessage('Guardado com sucesso!'));
      }
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }

    try {
      if (data.file && data?.id) {
        await uploadFile(companyId, data.id, data.file);
      }
      dispatch(_uploadSuccess());
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (e) {
      dispatch(_uploadFailure());
      dispatch(_createOrUpdatingFailure('Não foi possível carregar a imagem'));
    }
  }
);

export const remove = createAsyncThunk(
  'items/remove',
  async (data, { dispatch }) => {
    dispatch(_delete());
    try {
      await axios.delete(`/companies/${companyId}/items/${data.id}`);

      dispatch(_deletingSuccess(data));
      dispatch(_setSuccessMessage('Apagado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_deletingFailure(errorMessage));
    }
  }
);
