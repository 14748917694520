import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';

import { DOCUMENT_TYPE_NAME } from '../../constants';

const ExtractTable = (props) => {
  const { invoices, formatDate, onOpenInvoiceDetails, receipts, ...other } = props;
  let currentBalance = 0;

  const getBalance = (documentTypeName, totalGeneral, isVoided) => {
    let color = null;

    if (
      DOCUMENT_TYPE_NAME.invoice === documentTypeName
      || DOCUMENT_TYPE_NAME.debit_note === documentTypeName
    ) {
      currentBalance += totalGeneral;
      color = isVoided ? null : 'primary.main';
    }

    if (
      DOCUMENT_TYPE_NAME.receipt === documentTypeName
      || DOCUMENT_TYPE_NAME.credit_note === documentTypeName
    ) {
      currentBalance -= totalGeneral;
      color = isVoided ? null : 'error.main';
    }

    return (
      <TableCell
        variant={isVoided ? 'disabled' : ''}
        sx={{
          color
        }}
      >
        {formatCurrency(currentBalance)}
      </TableCell>
    );
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    width: '35rem'
                  }}
                >
                  Documento
                </TableCell>
                <TableCell
                  sx={{
                    width: '20rem'
                  }}
                >
                  Número de Documento
                </TableCell>
                <TableCell
                  sx={{
                    width: '20rem'
                  }}
                >
                  Valor da Fatura
                </TableCell>
                <TableCell
                  sx={{
                    width: '20rem'
                  }}
                >
                  Saldo
                </TableCell>
                <TableCell
                  sx={{
                    width: '20rem'
                  }}
                >
                  Data de Criação
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoices?.map((invoice) => (
                <TableRow
                  hover={!invoice.isVoided}
                  variant={!invoice.isVoided ? '' : 'disabled'}
                  key={invoice.id}
                  onClick={!invoice.isVoided ? (() => onOpenInvoiceDetails(invoice)) : (() => null)}
                >
                  <TableCell variant="disabled">
                    {invoice.documentType.name}
                  </TableCell>
                  <TableCell variant="disabled">
                    {invoice.number}
                  </TableCell>
                  <TableCell variant="disabled">
                    {formatCurrency(invoice.totalGeneral)}
                  </TableCell>
                  {getBalance(invoice.documentType.name, invoice.totalGeneral, invoice.isVoided)}
                  <TableCell>
                    {formatDate(invoice.date)}
                  </TableCell>
                </TableRow>
              ))}
              {receipts?.map((receipt) => (
                <TableRow
                  key={receipt.id}
                >
                  <TableCell>
                    Recibo
                  </TableCell>
                  <TableCell>
                    {receipt.number}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(receipt.totalGeneral)}
                  </TableCell>
                  {getBalance(DOCUMENT_TYPE_NAME.receipt, receipt.totalGeneral)}
                  <TableCell>
                    {formatDate(receipt.paymentDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

ExtractTable.propTypes = {
  invoices: PropTypes.arrayOf({
    id: PropTypes.string,
    number: PropTypes.string,
    totalGeneral: PropTypes.number,
    documentType: PropTypes.shape({
      name: PropTypes.string
    })
  }).isRequired,
  formatDate: PropTypes.func.isRequired,
  onOpenInvoiceDetails: PropTypes.func.isRequired,
  receipts: PropTypes.arrayOf({
    id: PropTypes.string,
    number: PropTypes.string,
    totalGeneral: PropTypes.number,
    paymentDate: PropTypes.string
  })
};

export default ExtractTable;
