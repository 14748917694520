import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow
} from '@mui/material';

const ViewDetails = (props) => {
  const { bankAccount, companySettings, ...other } = props;

  return (
    <Card {...other}>
      <Box sx={{ p: 3 }}>
        <Table>
          <TableBody>
            <TableRow id="bank-account-view-details-bank">
              <TableCell sx={{ fontWeight: 700 }}>
                Banco
              </TableCell>
              <TableCell sx={{ fontWeight: 400 }}>
                {bankAccount.bankName}
              </TableCell>
            </TableRow>
            <TableRow id="bank-account-view-abbreviation-bank">
              <TableCell sx={{ fontWeight: 700 }}>
                Abreviação
              </TableCell>
              <TableCell>
                {bankAccount.abbreviation}
              </TableCell>
            </TableRow>
            <TableRow id="bank-account-view-account-number-bank">
              <TableCell sx={{ fontWeight: 700 }}>
                Número da Conta
              </TableCell>
              <TableCell>
                {bankAccount.accountNumber}
              </TableCell>
            </TableRow>
            <TableRow id="bank-account-view-nib-bank">
              <TableCell sx={{ fontWeight: 700 }}>
                NIB
              </TableCell>
              <TableCell>
                {bankAccount.nib}
              </TableCell>
            </TableRow>
            <TableRow id="bank-account-view-swift-bank">
              <TableCell sx={{ fontWeight: 700 }}>
                IBAN
              </TableCell>
              <TableCell>
                {bankAccount.iban}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 700 }}>
                SWIFT
              </TableCell>
              <TableCell>
                {bankAccount.swift}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Card>
  );
};

ViewDetails.propTypes = {
  bankAccount: PropTypes.shape({
    bankName: PropTypes.string,
    abbreviation: PropTypes.string,
    accountNumber: PropTypes.string,
    nib: PropTypes.string,
    swift: PropTypes.string,
    iban: PropTypes.string
  }).isRequired,
  companySettings: PropTypes.objectOf({
    isPaymentEnabled: PropTypes.bool
  })
};

ViewDetails.defaultProps = {
  companySettings: PropTypes.objectOf({
    isPaymentEnabled: false
  })
};

export default ViewDetails;
