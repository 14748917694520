import { BALANCE_TYPES } from '../constants';

/**
 * Calculates the balance for card transactions for the current company.
 * @param {Object} currentCompany - The current company object.
 * @returns {Object|undefined} The balance object for card transactions, or undefined if not found.
 */
const calculateCardBalance = (currentCompany) => currentCompany?.balances?.find((balance) => balance.balanceId === BALANCE_TYPES.CARD);

export default calculateCardBalance;
