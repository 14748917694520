import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Divider,
  TextField,
  Typography
} from '@mui/material';

const initialValues = {
  bankName: '',
  abbreviation: '',
  accountNumber: '',
  nib: '',
  swift: '',
  iban: ''
};

const Form = (props) => {
  const {
    onCancel,
    onSubmitForm,
    bankAccount
  } = props;

  const bankAccountValues = bankAccount ? ({
    bankName: bankAccount.bankName,
    abbreviation: bankAccount.abbreviation,
    accountNumber: bankAccount.accountNumber,
    nib: bankAccount.nib,
    swift: bankAccount.swift,
    iban: bankAccount.iban
  }) : null;

  return (
    <Formik
      initialValues={bankAccount ? bankAccountValues : initialValues}
      validationSchema={Yup
        .object()
        .shape({
          bankName: Yup
            .string()
            .max(255, 'O Banco deve ter no máximo 255 caracteres')
            .required('Obrigatório'),
          abbreviation: Yup
            .string()
            .max(255, 'A abreviaçāo deve ter no máximo 255 caracteres')
            .required('Obrigatório'),
          accountNumber: Yup
            .number()
            .typeError('Deve conter apenas números')
            .required('Obrigatório'),
          nib: Yup
            .number()
            .typeError('Deve conter apenas números')
            .required('Obrigatório'),
          swift: Yup
            .string()
            .max(255)
            .required('Obrigatório'),
          iban: Yup
            .string()
            .nullable()
            .max(255)
            .required('Obrigatório')
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          onSubmitForm(values);
          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values
      }) => (
        <form onSubmit={handleSubmit}>
          <Box sx={{ p: 3 }}>
            <Typography
              id="new-bank-account-title"
              align="center"
              color="textPrimary"
              gutterBottom
              variant="h5"
            >
              {bankAccount ? 'Editar Conta Bancária' : 'Nova Conta Bancária'}
            </Typography>
          </Box>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="bank-account-form-bank-name-input"
                autoFocus
                error={Boolean(touched.bankName && errors.bankName)}
                fullWidth
                helperText={touched.bankName && errors.bankName}
                label="Banco*"
                name="bankName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.bankName}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="bank-account-form-abbreviation-input"
                error={Boolean(touched.abbreviation && errors.abbreviation)}
                fullWidth
                helperText={touched.abbreviation && errors.abbreviation}
                label="Abreviação*"
                name="abbreviation"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.abbreviation}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="bank-account-form-account-number-input"
                error={Boolean(touched.accountNumber && errors.accountNumber)}
                fullWidth
                helperText={touched.accountNumber && errors.accountNumber}
                label="Número de Conta*"
                name="accountNumber"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.accountNumber}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="bank-account-form-nib-input"
                error={Boolean(touched.nib && errors.nib)}
                fullWidth
                helperText={touched.nib && errors.nib}
                label="NIB*"
                name="nib"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.nib}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="iban-text-field"
                error={Boolean(touched.iban && errors.iban)}
                fullWidth
                helperText={touched.iban && errors.iban}
                label="IBAN*"
                name="iban"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.iban}
                variant="outlined"
              />
            </Box>
            <Box sx={{ mt: 2 }}>
              <TextField
                id="bank-account-form-swift-input"
                error={Boolean(touched.swift && errors.swift)}
                fullWidth
                helperText={touched.swift && errors.swift}
                label="SWIFT*"
                name="swift"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values?.swift}
                variant="outlined"
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              p: 2
            }}
          >
            <Box sx={{ flexGrow: 1 }} />
            <Button
              color="primary"
              onClick={onCancel}
              variant="text"
            >
              Voltar
            </Button>
            <Button
              id="bank-account-submit-button"
              color="primary"
              disabled={isSubmitting}
              sx={{ ml: 1 }}
              type="submit"
              variant="contained"
            >
              {bankAccount ? 'Guardar' : 'Criar'}
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  bankAccount: PropTypes.shape({
    bankName: PropTypes.string,
    abbreviation: PropTypes.string,
    accountNumber: PropTypes.string,
    nib: PropTypes.string,
    swift: PropTypes.string,
    iban: PropTypes.string
  }).isRequired
};

export default Form;
