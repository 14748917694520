import { useRef, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Avatar,
  Box,
  ButtonBase,
  Popover,
  Typography,
  Divider
} from '@mui/material';
import PropTypes from 'prop-types';

import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import BusinessIcon from '@mui/icons-material/Business';
import useAuth from '../../hooks/useAuth';
import { set as setCompany } from '../../slices/companies';
import { useDispatch } from '../../store';

const AccountPopover = (props) => {
  const { companies, handleNavigate } = props;
  const anchorRef = useRef(null);
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeCompany = async (company) => {
    await dispatch(setCompany(company));
    handleClose();
  };

  const handleLogout = async () => {
    try {
      handleClose();
      await logout();
      handleNavigate('/');
    } catch (err) {
      enqueueSnackbar('Unable to logout', {
        anchorOrigin: {
          horizontal: 'right',
          vertical: 'top'
        },
        variant: 'error'
      });
    }
  };

  const handleNavigateToMyCompanyPage = () => {
    handleClose();
    handleNavigate('/minhas_empresas', { openPopup: false });
  };

  const handleNavigateToMyCompanyPageAndOpenAddPopup = () => {
    handleClose();
    handleNavigate('/minhas_empresas', { openPopup: true });
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <Avatar
          src={user && user.avatar}
          sx={{
            height: 32,
            width: 32
          }}
        />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        slotProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="h6"
          >
            {user.first_name ? `${user.first_name} ${user.last_name}` : ''}
          </Typography>
        </Box>
        <Divider />
        <Box>
          <MenuList>
            <Box
              sx={{
                display: 'flex',
                py: 2,
                ml: 3
              }}
            >
              <BusinessIcon
                fontSize="medium"
                sx={{ mr: 1 }}
              />
              <Typography>
                Empresas Recentes
              </Typography>
            </Box>
            {companies?.slice(0, 3).map((company) => (
              <MenuItem
                key={company.id}
                onClick={() => handleChangeCompany(company)}
              >
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  sx={{ p: 1 }}
                >
                  {company.name}
                </Typography>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
        <Divider />
        <Box>
          <MenuList>
            <MenuItem>
              <Typography
                color="textSecondary"
                variant="subtitle2"
                sx={{ p: 1 }}
                onClick={handleNavigateToMyCompanyPageAndOpenAddPopup}
              >
                Criar Empresa
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography
                color="textSecondary"
                variant="subtitle2"
                sx={{ p: 1 }}
                onClick={handleNavigateToMyCompanyPage}
              >
                Minhas Empresas
              </Typography>
            </MenuItem>
          </MenuList>
        </Box>
        <Divider />
        <Box>
          <MenuList>
            <MenuItem
              onClick={handleLogout}
              sx={{ py: 2 }}
            >
              <PowerSettingsNewIcon
                fontSize="medium"
                sx={{ mr: 1 }}
              />
              Sair
            </MenuItem>
          </MenuList>
        </Box>
      </Popover>
    </>
  );
};

AccountPopover.propTypes = {
  companies: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    accountantTaxId: PropTypes.string,
    currencyId: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  handleNavigate: PropTypes.func.isRequired
};
export default AccountPopover;
