/**
 * Converts a snake_case string to camelCase.
 *
 * @param {string} snakeStr - The snake_case string to convert.
 * @returns {string} - The camelCase version of the input string.
 */
const snakeToCamel = (snakeStr) => snakeStr.replace(/(_\w)/g, (matches) => matches[1].toUpperCase());

/**
 * Recursively converts the keys of an object or an array of objects from snake_case to camelCase.
 *
 * @param {Object|Array} obj - The object or array of objects to convert.
 * @returns {Object|Array} - The object or array with keys converted to camelCase.
 */
export const convertKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeys(item));
  }
  if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const newKey = snakeToCamel(key);
      acc[newKey] = convertKeys(obj[key]);
      return acc;
    }, {});
  }
  return obj;
};
