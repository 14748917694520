import { useEffect, useRef, useCallback, useState } from 'react';
import { useParams, useNavigate, Navigate } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

import { Box, Button, Card, Container, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useDispatch, useSelector } from '../../store';
import { fetchPdfById, fetchPaymentInfo, createPaymentIntent } from '../../slices/proFormas';

import { homeTheme } from '../../theme/homeTheme';

import RenderPdf from '../../components/RenderPdf';
import Header from '../../components/PublicInvoice/Header';
import { Footer } from '../../components/homePage/Footer';
import LoadingPopUp from '../../components/LoadingPopUp';
import PaymentForm from '../../components/PaymentForm';
import StripeIframePopUpForm from '../../components/PaymentOptions/StripeIframePopUpForm';
import PaymentStatusMessagePopup from '../../components/StatusMessagePopup';
import { POPUP_STATUS, DOCUMENTS } from '../../constants';

import '../../theme/fonts.css';
import './styles.css';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    margin: '7rem auto',
    flexDirection: 'column',
    maxWidth: '42rem'
  },
  cardContent: {
    alignItems: 'center',
    minHeight: 'auto',
    margin: '1rem',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)',
    overflow: 'auto',
    padding: '0',
    maxWidth: '320rem',
    zIndex: 3
  },
  downloadButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '56vw',
    height: '48px',
    margin: '0 2rem'
  }
}));

const index = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const mountedRef = useRef(false);
  const dispatch = useDispatch();

  const { proFormaPdf, loading, loadingError, debtAmount, token, onRequestFailure, errorMessage } = useSelector((state) => state).proFormas;
  const [isPaymentFormOpen, setIsPaymentFormOpen] = useState(false);
  const [isStripeIframePopUpFormOpen, setIsStripeIframePopUpFormOpen] = useState(false);
  const [stripeToken, setStripeToken] = useState(null);
  const [emailForReceipt, setEmailForReceipt] = useState(null);
  const [isPaymentStatusPopupOpen, setIsPaymentStatusPopupOpen] = useState(false);
  const [paymentStatusInfo, setPaymentStatusInfo] = useState({});
  const [paymentSucceded, setPaymentSucceded] = useState(false);
  const [proformaDebtAmount, setProformaDebtAmount] = useState(0);
  const [amountPaid, setAmountPaid] = useState(0);

  const theme = useTheme();
  const projectColors = theme.palette;

  useEffect(() => {
    mountedRef.current = true;
    dispatch(fetchPdfById(id));
    dispatch(fetchPaymentInfo(id));
    return () => {
      mountedRef.current = false;
    };
  }, [dispatch]);

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );

  const handlePaymentForm = () => {
    setIsPaymentFormOpen(true);
  };

  const hanldePayProforma = (data) => {
    dispatch(createPaymentIntent({
      id,
      ...data
    }));
    setEmailForReceipt(data.email);
  };

  const handleStripeIframePopUpFormOpen = () => {
    setIsStripeIframePopUpFormOpen(true);
  };

  const handleStripeIframePopUpFormClose = () => {
    setIsStripeIframePopUpFormOpen(false);
  };

  useEffect(() => {
    if (debtAmount) {
      setProformaDebtAmount(debtAmount);
    }
  }, [debtAmount]);

  useEffect(() => {
    if (token) {
      handleStripeIframePopUpFormOpen();
      setStripeToken(token);
    }
  }, [token]);

  useEffect(() => {
    if (paymentSucceded) {
      setProformaDebtAmount(proformaDebtAmount - amountPaid);
      setPaymentSucceded(false);
    }
  }, [paymentSucceded]);

  const handleTriggerSuccessPaymentMessage = (isSuccess, stripeErrorMessage) => {
    if (isSuccess) {
      setPaymentSucceded(true);
      setPaymentStatusInfo({
        status: POPUP_STATUS.success,
        color: projectColors.primary.main,
        title: 'Pagamento efetuado com sucesso!',
        text: `A ${DOCUMENTS.invoice_receipt.name} será enviado para o email`,
        subText: emailForReceipt
      });
    } else {
      setPaymentSucceded(false);
      setPaymentStatusInfo({
        status: POPUP_STATUS.error,
        color: projectColors.error.main,
        title: 'Ocorreu um erro ao efetuar o pagamento!',
        text: stripeErrorMessage,
        subText: null
      });
    }
    setIsPaymentStatusPopupOpen(true);
  };

  useEffect(() => {
    if (onRequestFailure) {
      handleTriggerSuccessPaymentMessage(false, errorMessage);
    }
  }, [onRequestFailure]);

  const handlePaymentStatusPopupClose = () => {
    setIsPaymentStatusPopupOpen(false);
  };

  if (loading) {
    return <LoadingPopUp message="Carregando..." />;
  }

  if (loadingError) {
    return <Navigate to="/404" />;
  }

  return (
    <>
      <Helmet>
        <title>ProForma | Samba</title>
      </Helmet>
      <ThemeProvider theme={homeTheme}>
        <Header handleLogoClick={handleLogoClick} />
        {/* BODY */}
        <Container className={classes.articles}>
          <Box sx={{ display: 'flex', gap: 1, justifyContent: 'flex-end', marginRight: 2 }}>
            <a
              href={proFormaPdf}
              download
              style={{ textDecoration: 'none' }}
            >
              <Button
                variant="contained"
                color="primary"
                endIcon={<CloudDownloadIcon />}
              >
                BAIXAR PDF
              </Button>
            </a>
            {
              proformaDebtAmount > 0 && !loading && (
                <Button
                  id="proforma-payment-button"
                  variant="contained"
                  color="primary"
                  onClick={handlePaymentForm}
                  endIcon={<MonetizationOnIcon />}
                >
                  PAGAR VALOR EM DÍVIDA
                </Button>
              )
            }
          </Box>
          <Card
            className={classes.cardContent}
          >
            <RenderPdf file={proFormaPdf} />
          </Card>
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-left.svg"
            sx={{
              position: 'absolute',
              height: 460,
              left: -34,
              top: 0,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-left.svg"
            sx={{
              position: 'absolute',
              height: 360,
              left: -82,
              top: 680,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-3-left.svg"
            sx={{
              position: 'absolute',
              width: 200,
              height: 300,
              left: -84,
              top: 940,
              opacity: 1,
              zIndex: -13
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-1-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 40,
              opacity: 1
            }}
          />
          <Box
            alt="Shape"
            component="img"
            src="/static/invoice/invoice-shape-2-right.svg"
            sx={{
              position: 'absolute',
              width: 60,
              height: 460,
              right: -2,
              top: 620,
              opacity: 1,
              zIndex: -13
            }}
          />
        </Container>
        <Box
          alt="Shape"
          sx={{ zIndex: 3 }}
        >
          <Footer
            sx={{
              zIndex: 4,
              backgroundColor: 'background.grey'
            }}
          />
        </Box>
      </ThemeProvider>
      <PaymentForm
        debtAmount={proformaDebtAmount}
        setAmountPaid={setAmountPaid}
        handleDispatchPayDocument={hanldePayProforma}
        isPaymentFormOpen={isPaymentFormOpen}
        setIsPaymentFormOpen={setIsPaymentFormOpen}
      />
      <StripeIframePopUpForm
        documentName={DOCUMENTS.invoice_receipt.name}
        isFormOpen={isStripeIframePopUpFormOpen}
        closeForm={handleStripeIframePopUpFormClose}
        clientStripeToken={stripeToken}
        handleTriggerSuccessPaymentMessage={handleTriggerSuccessPaymentMessage}
      />
      <PaymentStatusMessagePopup
        isOpen={isPaymentStatusPopupOpen}
        handleClosePopUp={handlePaymentStatusPopupClose}
        status={paymentStatusInfo?.status}
        color={paymentStatusInfo?.color}
        title={paymentStatusInfo?.title}
        text={paymentStatusInfo?.text}
        subText={paymentStatusInfo?.subText}
      />
    </>
  );
};

export default index;
