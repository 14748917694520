import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

let companyId;

const initialState = {
  proFormas: null,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  proFormaPdf: null,
  token: null
};

const slice = createSlice({
  name: 'proFormas',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
      state.onRequestFailure = false;
      state.token = null;
    },
    _getSuccess: (state, { payload }) => {
      state.proFormas = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.proFormas = null;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.proFormas === null) {
        state.proFormas = [];
      }
      state.proFormas.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _void: (state) => {
      state.onRequest = true;
    },
    _voidingSuccess: (state, { payload }) => {
      state.proFormas = state.proFormas.map((proForma) => {
        if (proForma.id === payload.id) {
          proForma.isVoided = true;
        }

        return proForma;
      });
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _voidFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendProFormaEmail: (state) => {
      state.onRequest = true;
    },
    _sendProFormaEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendProFormaEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setProFormaPdf: (state, { payload }) => {
      state.proFormaPdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    },
    _setProFormaPaymentInfo: (state, { payload }) => {
      state.debtAmount = payload.debt_amount;
      state.isLoading = false;
    },
    _setProFormaPaymentPaymentIntent: (state, { payload }) => {
      state.token = payload.token;
      state.onRequestFailure = false;
      state.isLoading = false;
    },
    _errorAfterCreatePaymentIntent: (state, { payload }) => {
      state.errorMessage = payload;
      state.onRequestFailure = true;
      state.isLoading = false;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _void,
  _voidFailure,
  _voidingSuccess,
  _setSuccessMessage,
  _sendProFormaEmail,
  _sendProFormaEmailFailure,
  _sendProFormaEmailSuccess,
  _setProFormaPdf,
  _setProFormaPaymentInfo,
  _setProFormaPaymentPaymentIntent,
  _errorAfterCreatePaymentIntent
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'proForma/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/pro_formas`);

      const proFormas = data.map((proForma) => ({
        id: proForma.id,
        documentType: {
          id: proForma.document_type.id,
          code: proForma.document_type.code,
          name: proForma.document_type.name
        },
        clientName: proForma.customer.name,
        clientId: proForma.customer.id,
        serieCode: proForma.serial_number.code,
        paymentPeriod: {
          id: proForma.payment_period.id,
          code: proForma.payment_period.code,
          name: proForma.payment_period.name,
          numberOfDay: proForma.payment_period.number_of_day
        },
        paymentMode: {
          id: proForma.payment_mode.id,
          code: proForma.payment_mode.code,
          name: proForma.payment_mode.name
        },
        date: proForma.date,
        serie: proForma.serial_number.id,
        serieName: proForma.serial_number.name,
        commentary: proForma.commentary,
        valueWithoutTax: proForma.value_without_tax,
        totalTax: proForma.value_of_tax,
        valueWithTax: proForma.value_with_tax,
        discount: proForma.discount,
        totalGeneral: proForma.total_general,
        number: proForma.invoice_number,
        isVoided: proForma.is_voided,
        receiptsValue: proForma.receipts_values,
        paymentStatus: proForma.payment_status,
        referenceCode: proForma.reference_code,
        invoiceItems: proForma.items.map((item) => ({
          id: item.id,
          item: item.item,
          quantity: item.quantity,
          unit: item.unit_code,
          price: item.price,
          tax: item.tax,
          discount: item.discount,
          itemTotal: item.item_total
        }))
      }));
      dispatch(_getSuccess(proFormas));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'proForma/create',
  async (data, { dispatch }) => {
    dispatch(_createOrUpdate());
    try {
      const invoiceItems = data.invoiceItems.map((invoiceItem) => ({
        item_id: invoiceItem.item,
        quantity: Number(invoiceItem.quantity),
        unit_code: invoiceItem.unit,
        price: Number(invoiceItem.price),
        tax_id: invoiceItem.tax,
        discount: Number(invoiceItem.discount),
        item_description: invoiceItem.description
      }));

      const invoice = {
        document_type_id: data.documentType,
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        reference_code: data.referenceCode,
        invoice_items: invoiceItems
      };

      const response = await axios.post(`/companies/${companyId}/pro_formas`, invoice);

      const newInvoice = {
        id: response.data.id,
        documentType: {
          id: response.data.document_type.id,
          code: response.data.document_type.code,
          name: response.data.document_type.name
        },
        clientName: response.data.customer.name,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieCode: response.data.serial_number.code,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalIva: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        number: response.data.invoice_number,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        isVoided: invoice.is_voided,
        paymentStatus: response.data.payment_status,
        invoiceItems: response.data.items.map((invoiceItem) => ({
          id: invoiceItem.id,
          item: invoiceItem.item,
          quantity: invoiceItem.quantity,
          unit: invoiceItem.unit_code,
          price: invoiceItem.price,
          tax: invoiceItem.tax,
          discount: invoiceItem.discount,
          itemTotal: invoiceItem.item_total
        }))
      };

      analytics.dispatchEvent(events.proFormaCreated());

      dispatch(_createOrUpdatingSuccess(newInvoice));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const voidProForma = createAsyncThunk(
  'proForma/void',
  async (data, { dispatch }) => {
    dispatch(_void());

    try {
      await axios.post(`/companies/${companyId}/pro_formas/${data.id}/void`);

      dispatch(_voidingSuccess(data));
      dispatch(_setSuccessMessage('Anulado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_voidFailure(errorMessage));
    }
  }
);

export const sendProFormaEmail = createAsyncThunk(
  'proForma/send_email',
  async (data, { dispatch }) => {
    dispatch(_sendProFormaEmail());

    try {
      await axios.post(`/companies/${companyId}/pro_formas/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.proFormaEmailed());

      dispatch(_sendProFormaEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendProFormaEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'proForma/print',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/companies/${companyId}/pro_formas/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.proFormaDownloaded());

      dispatch(_getPdfSuccess());
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchPdfById = createAsyncThunk(
  'proForma/fetchPdf',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/pro_formas/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setProFormaPdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'proForma/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { invoices } = getState().invoices;

      const updatedInvoices = invoices.map((invoice) => {
        const invoiceUpdated = data.find((i) => i.id === invoice.id);

        if (invoiceUpdated) {
          return { ...invoice, receiptsValue: invoice.receiptsValue + invoiceUpdated.value };
        }

        return invoice;
      });

      dispatch(_getSuccess(updatedInvoices));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const fetchPaymentInfo = createAsyncThunk(
  'proForma/fetchPaymentInfo',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/pro_formas/${id}/payment_info`);

      dispatch(_setProFormaPaymentInfo(response.data));
    } catch (error) {
      dispatch(_getPdfFailure('Erro ao buscar informações de pagamento'));
    }
  }
);

export const createPaymentIntent = createAsyncThunk(
  'proForma/createPaymentIntent',
  async (data, { dispatch }) => {
    dispatch(_get());
    try {
      const bodyRequest = {
        amount: data.amount,
        payment_service_id: data.paymentService,
        email: data.email
      };

      const response = await axios.post(`/payments/pro_formas/${data.id}`, bodyRequest);

      dispatch(_setProFormaPaymentPaymentIntent(response.data));
    } catch (error) {
      dispatch(_errorAfterCreatePaymentIntent('Erro ao criar a intenção de pagamento'));
    }
  }
);
