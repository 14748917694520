import PropTypes from 'prop-types';
import { Box, Button, Card, Dialog, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Chip from '@mui/material/Chip';
import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';
import WarningPopup from '../WarningPopup';

import ErrorText from '../ErrorText';

const EFaturaIntegration = (props) => {
  const {
    url,
    error,
    isLoading,
    errorMessage,
    companyEfaturaStatus,
    isCompanyIntegratedWithEFatura
  } = props;

  const [searchParams] = useSearchParams();

  const FAIL = 'fail';
  const SUCCESS = 'success';
  const START = 'start';

  const [isWarningPopUpOpen, setIsWarningPopUpOpen] = useState(false);
  const [efaturaStatusError, setEFaturaStatusError] = useState(START);

  const handleWarningPopUpOpen = () => {
    setIsWarningPopUpOpen(true);
  };

  const handleWarningPopUpClose = () => {
    setIsWarningPopUpOpen(false);
  };

  const handleOpenWindow = () => {
    window.open(url, '_blank');
  };

  const renderStatusButton = () => {
    if (isLoading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
          <LoadingButton
            loading
            variant="contained"
            sx={{ mt: 2 }}
            style={{
              width: 100
            }}
          >
            Loading
          </LoadingButton>
        </Box>
      );
    }
    if (isCompanyIntegratedWithEFatura(companyEfaturaStatus)) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
          <Chip
            id="e-fatura-confirm-integration-button"
            label="Integrado"
            variant="outlined"
            color="primary"
            sx={{ mt: 2 }}
            style={{
              width: 100
            }}
          />
        </Box>
      );
    }
    return (
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'flex-end' }}>
        <Button
          id="e-fatura-integration-button"
          sx={{ mt: 2 }}
          color="primary"
          variant="contained"
          onClick={() => handleWarningPopUpOpen()}
          style={{
            width: 100
          }}
          disabled={efaturaStatusError === FAIL || efaturaStatusError === START}
        >
          Integrar
        </Button>
      </Box>
    );
  };

  const integrationPopUp = !!searchParams.get('sucesso');

  const renderErrorMessage = () => (
    <ErrorText id="error-message-integration-page">{errorMessage}</ErrorText>
  );

  useEffect(() => {
    if (!url && error) {
      setEFaturaStatusError(FAIL);
    }
    if (url) {
      setEFaturaStatusError(SUCCESS);
    }
  }, [url, error]);

  return (
    <>
      <Box sx={{ mt: 3 }}>
        <Card sx={{ p: 2 }}>
          <img
            src="/static/eFatura/eFaturaLogo.svg"
            alt="eFatura Logo"
          />
          <Typography
            color="textSecondary"
          >
            Caso uses o serviço e-Fatura na tua facturação, podes associar a
            tua conta ao Samba. Para isso, basta clicar em Integrar e, na
            página seguinte, inserir os dados da tua conta e-Fatura onde tiveres
            o NIF associado.
          </Typography>
          {renderStatusButton()}
        </Card>
      </Box>
      <Dialog
        id="integration-pop-up"
        maxWidth="xs"
        onClose={handleWarningPopUpClose}
        open={isWarningPopUpOpen}
      >
        {isWarningPopUpOpen && (
          <WarningPopup
            primaryButtonId="continue-with-e-fatura-integration-button"
            secondaryButtonId="secondary-id-e-fatura-integration-button"
            message="Para integrar com e-Fatura, será redirecionado para a página de e-Fatura para autenticar"
            buttonText="Continuar"
            secondaryButtonText="Cancelar"
            onSecondaryButtonPress={handleWarningPopUpClose}
            onButtonPress={() => handleOpenWindow()}
          />
        )}
      </Dialog>

      <Dialog
        maxWidth="xs"
        onClose={handleWarningPopUpClose}
        open={integrationPopUp}
      >
        {integrationPopUp && (
          <WarningPopup
            message={integrationPopUp ? 'e-Fatura integrado com sucesso' : 'Ocorreu um erro ao tentar integrar com e-Fatura'}
            buttonText="Ok"
            onButtonPress={handleWarningPopUpClose}
          />
        )}
      </Dialog>
      {!isLoading && efaturaStatusError === FAIL && (
        <>
          <Box sx={{ my: 2 }}>
            <Typography
              color="textPrimary"
              variant="subtitle2"
            >
              Botão de integração desabilitado pelo seguinte motivo:
            </Typography>
          </Box>
          {renderErrorMessage()}
        </>
      )}
    </>
  );
};

EFaturaIntegration.propTypes = {
  url: PropTypes.string.isRequired,
  error: PropTypes.bool.isRequired,
  errorMessage: PropTypes.string.isRequired,
  companyEfaturaStatus: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isCompanyIntegratedWithEFatura: PropTypes.func.isRequired
};

export default EFaturaIntegration;
