import { createTheme } from '@mui/material/styles';

export const homeTheme = createTheme({
  typography: {
    button: {
      fontWeight: 600,
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.15), 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
      fontFamily: 'Gill Sans'
    },
    fontFamily: 'Blogger Sans',
    title: {
      fontWeight: 700,
      color: '#535353',
      fontFamily: 'Blogger Sans',
      fontSize: '3rem',
      lineHeight: 2,
      '@media (max-width:600px)': {
        fontSize: '2rem',
        lineHeight: 1
      }
    },
    titleSecondary: {
      fontWeight: 600,
      fontSize: '2rem',
      color: '#535353',
      fontFamily: 'Blogger Sans'
    },
    textPrimary: {
      fontWeight: 400,
      fontSize: '1.5rem',
      color: '#535353',
      fontFamily: 'Gill Sans',
      '@media (max-width:600px)': {
        fontSize: '1.4rem'
      }
    },
    textSecondary: {
      fontWeight: 300,
      fontSize: '1.5rem',
      color: '#535353',
      fontFamily: 'Gill Sans',
      '@media (max-width:600px)': {
        fontSize: '1.2rem'
      }
    },
    light: {
      fontFamily: 'Blogger Sans Regular',
      fontSize: '1.5rem',
      color: '#535353',
      '@media (max-width:600px)': {
        fontSize: '1.2rem'
      }
    },
    homePageCardTitle: {
      fontFamily: 'Blogger Sans Regular',
      fontWeight: 600,
      fontSize: '1.4rem',
      color: '#535353',
      '@media (max-width:600px)': {
        fontSize: '1.2rem'
      }
    },
    homePageCardDescription: {
      fontFamily: 'Blogger Sans Regular',
      fontSize: '1.3rem',
      color: '#535353',
      '@media (max-width:600px)': {
        fontSize: '1.2rem'
      }
    },
    homePageTextSecondary: {
      fontFamily: 'Blogger Sans Regular',
      fontSize: '1.3rem',
      color: '#535353',
      '@media (max-width:600px)': {
        fontSize: '1.2rem'
      }
    },
    overline: {
      fontWeight: 600
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontFamily: 'Gill Sans',
          backgroundColor: '#FAFCFF',
          position: 'relative',
          fontSize: 16,
          width: '100%',
          padding: '0.5rem 1rem',
          transition: '2s border-color, box-shadow',
          '&:focus': {
            boxShadow: '#1CC5DC 0 0 0 0.1rem',
            borderColor: '#1CC5DC'
          },
          '&::placeholder': {
            opacity: 0.86,
            color: '#42526e',
            margin: 0,
            padding: 0
          }
        }
      }
    }
  },
  palette: {
    action: {
      active: '#6b778c'
    },
    background: {
      default: '#FAFCFF',
      paper: '#FAFCFF',
      grey: '#535353'
    },
    error: {
      contrastText: '#535353',
      main: '#f44336'
    },
    mode: 'light',
    primary: {
      contrastText: '#ffffff',
      main: '#21C8C3'
    },
    blue: {
      main: '#1CC5DC',
      contrastText: '#ffffff'
    },
    purple: {
      main: '#C67ACE',
      contrastText: '#ffffff',
      light: '#EFD5F2'
    },
    orange: {
      main: '#F1B47B',
      contrastText: '#ffffff'
    },
    gradientBlue: {
      main: 'linear-gradient(249.6deg, rgba(28, 197, 220, 0.2) 0%, rgba(117, 207, 184, 0.2) 100%)',
      contrastText: '#ffffff'
    },
    success: {
      contrastText: '#ffffff',
      main: '#4caf50',
      light: '#D0F3F2'
    },
    text: {
      primary: '#535353',
      secondary: '#6b778c'
    },
    warning: {
      contrastText: '#ffffff',
      main: '#ff9800'
    }
  },
  shape: {
    borderRadius: 20
  }
});
