/**
 * Calculate the total sum of `totalGeneral` from invoice, invoiceReceipt, and salesReceipt.
 *
 * @param {Object} data - The data object containing invoice, invoiceReceipt, and salesReceipt.
 * @param {Object} data.invoice - The invoice object.
 * @param {Object} data.invoice.sum - The sum object within invoice.
 * @param {number} data.invoice.sum.totalGeneral - The total general amount in invoice.
 * @param {Object} data.invoiceReceipt - The invoiceReceipt object.
 * @param {Object} data.invoiceReceipt.sum - The sum object within invoiceReceipt.
 * @param {number} data.invoiceReceipt.sum.totalGeneral - The total general amount in invoiceReceipt.
 * @param {Object} data.salesReceipt - The salesReceipt object.
 * @param {Object} data.salesReceipt.sum - The sum object within salesReceipt.
 * @param {number} data.salesReceipt.sum.totalGeneral - The total general amount in salesReceipt.
 *
 * @returns {number} The total sum of `totalGeneral` from invoice, invoiceReceipt, and salesReceipt.
 */

export const totalSales = (data) => {
  const invoiceTotal = data.invoice?.sum?.totalGeneral || 0;
  const invoiceReceiptTotal = data.invoiceReceipt?.sum?.totalGeneral || 0;
  const salesReceiptTotal = data.salesReceipt?.sum?.totalGeneral || 0;

  return invoiceTotal + invoiceReceiptTotal + salesReceiptTotal;
};
