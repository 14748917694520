import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import AlertIcon from '../../icons/Alert';

const ErrorText = (props) => {
  const { children, ...rest } = props;

  return (
    <Box
      {...rest}
      sx={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <AlertIcon
        sx={{
          fontSize: 17,
          ml: 1
        }}
      />
      <Typography
        color="error"
        variant="subtitle2"
        sx={{
          m: 1
        }}
      >
        {children}
      </Typography>
    </Box>
  );
};

ErrorText.propTypes = {
  children: PropTypes.node.isRequired
};

export default ErrorText;
