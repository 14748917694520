import { useState, useCallback, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

import { Helmet } from 'react-helmet-async';

import { Box, Card, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSelector, useDispatch } from '../../store';
import { pay as processPayment } from '../../slices/onlinePayments';

import Header from '../../components/PublicInvoice/Header';
import { Footer } from '../../components/homePage/Footer';
import { formatCurrency } from '../../util/formatCurrency';
import PaymentStatusMessagePopup from '../../components/StatusMessagePopup';

import { homeTheme } from '../../theme/homeTheme';

import { PAYMENT_SERVICES, POPUP_STATUS } from '../../constants';

import '../../theme/fonts.css';
import './styles.css';
import PaymentOptions from '../../components/PaymentOptions';
import calculateDeductedFee from '../../util/calculateDeductedFee';

const useStyles = makeStyles(() => ({
  articles: {
    display: 'flex',
    flexWrap: 'wrap',
    height: 'auto',
    minHeight: '100vh',
    justifyContent: 'center',
    margin: '7rem auto'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)',
    overflow: 'auto',
    padding: '16px',
    maxWidth: '320rem',
    gap: '36px',
    zIndex: 3,
    backgroundColor: 'white'
  }
}));

const PublicPayment = () => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { id } = useParams();
  const location = useLocation();

  const theme = useTheme();
  const projectColors = theme.palette;

  const dispatch = useDispatch();

  const isMobile = useMediaQuery((currentTheme) => currentTheme.breakpoints.down('sm'));

  const {
    onRequest,
    onRequestFailure,
    errorMessage,
    paymentResponse
  } = useSelector((state) => state.onlinePayments);

  const queryParams = new URLSearchParams(location.search);
  const companyName = queryParams.get('empresa');
  const amount = queryParams.get('valor');

  const [isPaymentStatusPopupOpen, setIsPaymentStatusPopupOpen] = useState(false);
  const [paymentStatusInfo, setPaymentStatusInfo] = useState({});

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );
  const handlePaymentStatusPopupClose = () => {
    setIsPaymentStatusPopupOpen(false);
  };

  useEffect(() => {
    const errorResponse = queryParams.get('errorMessage');
    if (errorResponse) {
      setPaymentStatusInfo({
        status: POPUP_STATUS.error,
        color: projectColors.error.main,
        title: 'Ocorreu um erro ao efetuar o pagamento!',
        text: errorResponse,
        subText: null
      });
      setIsPaymentStatusPopupOpen(true);
    }
  }, [queryParams]);

  const popupUpdate = () => {
    if (!onRequest && onRequest !== undefined && onRequestFailure) {
      setPaymentStatusInfo({
        status: POPUP_STATUS.error,
        color: theme.palette.error.main,
        title: 'Ocorreu um erro ao solicitar o pagamento!',
        text: errorMessage,
        subText: null
      });
      setIsPaymentStatusPopupOpen(true);
    }
  };

  useEffect(() => {
    popupUpdate();
  }, [onRequest, onRequestFailure, errorMessage]);

  const handleOptionPayment = (email) => {
    dispatch(processPayment({ paymentIntentId: id, email, paymentService: PAYMENT_SERVICES.stripe }));
  };

  const onError = (message) => {
    setPaymentStatusInfo({
      status: POPUP_STATUS.error,
      color: projectColors.error.main,
      title: 'Ocorreu um erro ao efetuar o pagamento!',
      text: message,
      subText: null
    });
    setIsPaymentStatusPopupOpen(true);
  };

  const onSuccess = () => {
    setPaymentStatusInfo({
      status: POPUP_STATUS.success,
      color: theme.palette.primary.main,
      title: 'Pagamento efetuado com sucesso!'
    });
    setIsPaymentStatusPopupOpen(true);
  };

  const fee = process.env.REACT_APP_STRIPE_SAMBA_POS_FEE;
  const deductedFee = calculateDeductedFee(fee);
  const amountAfterFee = Math.round((Number(amount) * deductedFee));

  const amountInformation = {
    amount: Number(amount),
    amountAfterFee,
    deductedFee,
    finalAmount: Number(amount) + amountAfterFee
  };

  return (
    <>
      <Helmet>
        <title>Pagamento Online | Samba</title>
      </Helmet>
      <Header handleLogoClick={handleLogoClick} />
      {/* BODY */}
      <Container className={classes.articles}>
        <ThemeProvider theme={homeTheme}>
          <Card
            className={classes.cardContent}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  align="center"
                  variant="titleSecondary"
                  fontWeight={200}
                >
                  {companyName.toUpperCase()}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography
                  align="center"
                  variant="light"
                >
                  {formatCurrency(amount)}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', minWidth: isMobile ? '90%' : '60%', flexDirection: 'column', gap: 2 }}>
              <PaymentOptions
                amountInformation={amountInformation}
                isLoading={onRequest}
                paymentOptionList={[PAYMENT_SERVICES.stripe]}
                paymentResponse={paymentResponse}
                onClickPaymentOption={handleOptionPayment}
                setPaymentStatusInfo={setPaymentStatusInfo}
                hasTaxInformation
                onSuccess={onSuccess}
                onError={onError}
              />
            </Box>
          </Card>
        </ThemeProvider>
        <PaymentStatusMessagePopup
          isOpen={isPaymentStatusPopupOpen}
          handleClosePopUp={handlePaymentStatusPopupClose}
          status={paymentStatusInfo?.status}
          color={paymentStatusInfo?.color}
          title={paymentStatusInfo?.title}
          text={paymentStatusInfo?.text}
          subText={paymentStatusInfo?.subText}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-1-left.svg"
          sx={{
            position: 'absolute',
            height: 460,
            left: -34,
            top: 0,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-2-left.svg"
          sx={{
            position: 'absolute',
            height: 360,
            left: -82,
            top: 680,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-3-left.svg"
          sx={{
            position: 'absolute',
            width: 200,
            height: 300,
            left: -84,
            top: 940,
            opacity: 1,
            zIndex: -13
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-1-right.svg"
          sx={{
            position: 'absolute',
            width: 60,
            height: 460,
            right: -2,
            top: 40,
            opacity: 1
          }}
        />
        <Box
          alt="Shape"
          component="img"
          src="/static/invoice/invoice-shape-2-right.svg"
          sx={{
            position: 'absolute',
            width: 60,
            height: 460,
            right: -2,
            top: 620,
            opacity: 1,
            zIndex: -13
          }}
        />
      </Container>
      <Box
        alt="Shape"
        sx={{ zIndex: 3 }}
      >
        <Footer
          sx={{
            zIndex: 4,
            backgroundColor: 'background.grey'
          }}
        />
      </Box>
    </>
  );
};

export default PublicPayment;
