import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

let companyId;

const initialState = {
  companyIntegrations: null,
  isLoading: null,
  error: null
};

const slice = createSlice({
  name: 'companyIntegrations',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.companyIntegrations = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.companyIntegrations = null;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'companyIntegrations/fetch',
  async (_, { dispatch, getState }) => {
    companyId = getState().companies.currentCompany.id;
    dispatch(_get());
    try {
      const { data } = await axios.get(`/companies/${companyId}/integrations`);

      const mappedIntegrations = data.map((integration) => ({
        type: integration.integration_type
      }));

      dispatch(_getSuccess(mappedIntegrations));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
