import { COMPANY_TYPES } from '../constants';

function calculateTotalInvoiceItem(unitPrice = 0, quantity = 0, ivaTypeTax = 0, discount = 0, companyType = COMPANY_TYPES[1].id) {
  let totalGeneral = 0;
  if (companyType === COMPANY_TYPES[0].name) {
    totalGeneral = (unitPrice * quantity * (1 - (ivaTypeTax / 100)) * (1 - (discount / 100)));
  } else {
    totalGeneral = (unitPrice * quantity * (1 + (ivaTypeTax / 100)) * (1 - (discount / 100)));
  }

  return totalGeneral.toFixed(2);
}

export default calculateTotalInvoiceItem;
