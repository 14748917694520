import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Box } from '@mui/material';

import HorizontalDataTable from '../HorizontalDataTable';

const DetailsSection = ({ title, rows }) => (
  <Box sx={{ display: 'flex', gap: 1, flexDirection: 'column' }}>
    <Typography
      color="textSecondary"
      variant="overline"
    >
      {title}
    </Typography>
    <Box>
      <HorizontalDataTable rows={rows} />
    </Box>
  </Box>
);

DetailsSection.propTypes = {
  title: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ).isRequired
};

export default DetailsSection;
