import * as Yup from 'yup';
import { AMOUNT_ERROR_MESSAGES, EMAIL_ERROR_MESSAGES, USER_ROLES_MESSAGES } from './errorMessagesConstants';
import { COMMON_MESSAGE } from './util/yupValidationMessages';

export const emailValidationSchema = () => Yup.object().shape({
  email: Yup
    .string()
    .email(EMAIL_ERROR_MESSAGES.email)
    .nullable()
    .max(255, EMAIL_ERROR_MESSAGES.max)
    .required(EMAIL_ERROR_MESSAGES.required)
});

export const typesOfUsersValidationSchema = () => Yup.object().shape({
  typeOfUser: Yup
    .string()
    .nullable()
    .required(USER_ROLES_MESSAGES.required)
});

export const amountValidationSchema = () => Yup.object().shape({
  amount: Yup
    .string()
    .nullable()
    .matches(/^[1-9][0-9]*$/, AMOUNT_ERROR_MESSAGES.number)
    .required(AMOUNT_ERROR_MESSAGES.required)
});

export const eCommerceNameValidationSchema = () => Yup.object().shape({
  eCommerceName: Yup
    .string()
    .nullable()
    .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
    .required(COMMON_MESSAGE.REQUIRED)
});

export const subdomainValidationSchema = () => Yup.object().shape({
  subdomain: Yup
    .string()
    .nullable()
    .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
    .required(COMMON_MESSAGE.REQUIRED)
});

export const facebookURLValidationSchema = () => Yup.object().shape({
  facebookURL: Yup
    .string()
    .nullable()
    .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
});

export const instagramURLValidationSchema = () => Yup.object().shape({
  instagramURL: Yup
    .string()
    .nullable()
    .max(255, COMMON_MESSAGE.MAX_CHARACTERS)
});

export const getCombinedValidationSchema = (schemas) => {
  const validSchemas = schemas.filter((schema) => schema instanceof Yup.object);

  if (validSchemas.length === 0) {
    throw new Error('No valid schemas found to combine.');
  }

  return validSchemas.reduce((combinedSchema, schema) => combinedSchema.concat(schema), Yup.object());
};
