import { Box, useTheme } from '@mui/material';
import MessageBox from '../components/MessageBox';
import { isDocumentEfaturaStatusFailedWithError } from './eFaturasStatus';

/**
 * Display a box with a message of an error related to sending a document to e-Fatura
 *
 * @param {object} document - the document to display its error message in case that it failed to be sent to e-Fatura
 * @param {boolean} document.isRetried -  the boolean to check if the document is been retried
 * @param {object} document.efaturaData - the object that contain important data related to the error message on e-Fatura
 * @param {string} document.efaturaData.status - the status of the document at e-Fatura
 * @param {string} document.efaturaData.error_code - the code related to the error message
 * @param {string} document.efaturaData.errorMessage - the error message to be displayed
 * @returns - the component "messageBox" with the error message of the document
 */
const renderEfaturaDocumentErrorMessage = (document) => {
  const {
    isRetried,
    efaturaData
  } = document;

  const theme = useTheme();

  return isDocumentEfaturaStatusFailedWithError(isRetried, efaturaData) && (
    <Box sx={{ mb: 3 }}>
      <MessageBox
        message={efaturaData.errorMessage}
        color={theme.palette.error.main}
      />
    </Box>
  );
};

export default renderEfaturaDocumentErrorMessage;
