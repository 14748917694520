import createSvgIcon from '@mui/material/utils/createSvgIcon';

const Alert = createSvgIcon(
  <svg
    viewBox="0 0 60 60"
    fill="error"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      d="M27.082 38.7502H32.9154V44.5835H27.082V38.7502ZM27.082 15.4168H32.9154V32.9168H27.082V15.4168ZM29.9695 0.833496C13.8695 0.833496 0.832031 13.9002 0.832031 30.0002C0.832031 46.1002 13.8695 59.1668 29.9695 59.1668C46.0987 59.1668 59.1654 46.1002 59.1654 30.0002C59.1654 13.9002 46.0987 0.833496 29.9695 0.833496ZM29.9987 53.3335C17.107 53.3335 6.66537 42.8918 6.66537 30.0002C6.66537 17.1085 17.107 6.66683 29.9987 6.66683C42.8904 6.66683 53.332 17.1085 53.332 30.0002C53.332 42.8918 42.8904 53.3335 29.9987 53.3335Z"
      fill="#F44336"
      clipRule="evenodd"
    />
  </svg>,
  'Alert'
);

export default Alert;
