import PropTypes from 'prop-types';
import { Box, Button, Container, Hidden, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

export const Hero = (props) => {
  const { handleScheduleDemo } = props;
  const [index, setIndex] = useState(0);
  const [subIndex, setSubIndex] = useState(0);
  const [blink, setBlink] = useState(true);
  const [reverse, setReverse] = useState(false);

  const words = [
    'Fatura',
    'Fatura/Recibo',
    'Recibo',
    'Fatura Pró-forma',
    'Nota de Débito',
    'Nota de Crédito'
  ];

  useEffect(() => {
    if (index === words.length) {
      return () => {};
    }

    if (subIndex === words[index].length + 1
      && index !== words.length - 1 && !reverse) {
      setReverse(true);
      return () => {};
    }

    if (subIndex === 0 && reverse
      && words.length - 1 !== index) {
      setReverse(false);
      setIndex((prev) => prev + 1);
      return () => {};
    }

    if (subIndex === words[index].length + 1
      && words.length - 1 === index && !reverse) {
      setReverse(true);
      return () => {};
    }

    if (subIndex === 0
      && words.length - 1 === index && reverse) {
      setIndex(0);
      setReverse(false);
      return () => {};
    }

    let delay;
    if (reverse) {
      delay = 10;
    } else if (subIndex === words[index].length) { // If the word is fully typed
      delay = 1000;
    } else {
      delay = 50;
    }

    const timeout = setTimeout(() => {
      setSubIndex((prev) => prev + (reverse ? -1 : 1));
    }, delay);

    return () => clearTimeout(timeout);
  }, [subIndex, index, reverse]);

  useEffect(() => {
    const timeout2 = setTimeout(() => {
      setBlink((prev) => !prev);
    }, 500);
    return () => clearTimeout(timeout2);
  }, [blink]);

  return (
    <Box
      sx={{
        backgroundColor: 'background.paper'
      }}
      component="main"
    >
      <Container>
        <Box
          alt="Shape"
          component="img"
          src="/static/homepage/hero-shape-left.svg"
          sx={{
            position: 'absolute',
            width: 500,
            left: -200,
            bottom: 90
          }}
        />
        <Box
          sx={{
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              zIndex: 2,
              minWidth: '250px'
            }}
          >
            <Typography
              variant="title"
              align="center"
              sx={{
                textTransform: 'uppercase',
                fontSize: 60
              }}
            >
              Software de Gestão cloud
            </Typography>
            <Box
              sx={{
                height: 64,
                my: 6,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Typography
                component="span"
                variant="textSecondary"
                align="center"
                sx={{
                  textTransform: 'uppercase',
                  textAlign: 'center',
                  letterSpacing: 16,
                  fontSize: 40
                }}
              >
                {words[index].substring(0, subIndex)}
                <Typography
                  component="span"
                  variant="textSecondary"
                  sx={{
                    opacity: blink ? 100 : 0,
                    fontSize: 40
                  }}
                >
                  |
                </Typography>
              </Typography>

            </Box>
            <a
              href="https://calendly.com/chuva-nilson/samba-demo"
              target="_blank"
              rel="noreferrer"
              onClick={handleScheduleDemo}
            >
              <Button
                alt="Marcar Demo Grátis"
                color="primary"
                type="button"
                variant="contained"
                size="small"
                sx={{
                  px: 4,
                  borderRadius: 4,
                  mb: 1
                }}
              >
                <Typography
                  variant="textSecondary"
                  color="#FAFCFF"
                  sx={{
                    fontFamily: 'Gill Sans',
                    fontWeight: 600,
                    fontSize: 16,
                    pt: 0.5
                  }}
                >
                  Marcar Demo Grátis
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
        <Hidden mdDown>
          <Box
            alt="Shape"
            component="img"
            src="/static/homepage/hero-shape-right.svg"
            sx={{
              position: 'absolute',
              width: 250,
              right: 0,
              bottom: 0
            }}
          />
        </Hidden>
      </Container>
    </Box>
  );
};

Hero.propTypes = {
  handleScheduleDemo: PropTypes.func.isRequired
};
