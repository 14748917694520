import PropTypes from 'prop-types';
import { Box, Card, Typography, Chip } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '400px',
    justifyContent: 'flex-start',
    margin: '2rem',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      width: '360px'
    },
    [theme.breakpoints.down('sm')]: {
      margin: '2rem auto',
      width: '280px'
    }
  },
  cardContent: {
    padding: theme.spacing(4),
    height: '588px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxShadow: '0px 4px 8px rgba(80, 80, 80, 0.3)'
  },
  titles: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  tags: {
    display: 'flex',
    justifyContent: 'flex-start',
    height: '100px',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  },
  time: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const Post = ({
  title,
  description,
  tags,
  createdAt
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.cardContainer}
    >
      <Card
        className={classes.cardContent}
      >
        <Box className={classes.titles}>
          <Typography
            variant="titleSecondary"
            align="left"
            maxHeight="32px"
            textOverflow="ellipsis"
            width="122px"
            sx={{
              fontSize: 30,
              height: 64,
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
          >
            {title}
          </Typography>
          <br />
          <Typography
            variant="textSecondary"
            align="justify"
            overflow="hidden"
            maxHeight="180px"
            sx={{
              fontSize: 24,
              py: 1,
              maxHeight: '148px'
            }}
          >
            {description}
          </Typography>
        </Box>
        <Box>
          <Box
            className={classes.tags}
          >
            {
            tags.map((tag) => (
              <Chip
                size="medium"
                variant="outlined"
                color="secondary"
                label={tag}
                style={{
                  backgroundColor: 'rgba(198, 122, 206, 0.1)',
                  border: '1px solid rgba(198, 122, 206, 0.2)',
                  color: '#C67ACE'
                }}
              />
            ))
        }
          </Box>
          <Box
            className={classes.time}
          >
            <Typography
              variant="overline"
              align="right"
              gutterBottom
              sx={{
                fontSize: 18,
                fontStyle: 'normal'
              }}
            >
              {createdAt}
            </Typography>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(
    PropTypes.string
  ).isRequired,
  createdAt: PropTypes.string.isRequired
};

export default Post;
