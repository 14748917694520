import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Menu,
  MenuItem
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react';
import Scrollbar from '../Scrollbar';

import AlertIcon from '../../icons/Alert';

const CompanyTable = (props) => {
  const { companies, onSelectCompany, onViewCompany, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleClick = (event, company) => {
    setAnchorEl(event.currentTarget);
    setSelectedCompany(company);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleView = () => {
    onViewCompany(selectedCompany);
    handleClose();
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableBody>
              {companies.map((company) => (
                <TableRow
                  hover
                  key={company.id}
                >
                  <TableCell
                    sx={{
                      border: 0,
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <ArrowDropDownIcon
                      sx={{
                        mr: 1,
                        border: 0,
                        fontSize: 16
                      }}
                      onClick={(e) => handleClick(e, company)}
                    />
                    <Box
                      sx={{
                        flex: 1,
                        display: 'flex',
                        alignItems: 'center'
                      }}
                      onClick={() => onSelectCompany(company)}
                    >
                      {company.name}
                      {(
                        !company.accountantTaxId
                      || !company.fiscalName
                      || !company.financeDepartmentTaxId
                      ) && (
                      <AlertIcon sx={{
                        fontSize: 16,
                        ml: 1
                      }}
                      />
                      ) }
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleView}>
          Ver Info
        </MenuItem>
      </Menu>
    </Card>
  );
};

CompanyTable.propTypes = {
  companies: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    accountantTaxId: PropTypes.string,
    currencyId: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onSelectCompany: PropTypes.func.isRequired,
  onViewCompany: PropTypes.func.isRequired
};

export default CompanyTable;
