import PropTypes from 'prop-types';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { makeStyles, styled, useTheme } from '@mui/styles';

import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  width: '100%'
}));

const DashboardLayoutContainer = styled('div')({
  display: 'flex',
  flex: '1 1 auto',
  overflow: 'hidden'
});

const DashboardLayoutContent = styled('div')({
  flex: '1 1 auto',
  height: '100%',
  overflow: 'auto',
  position: 'relative',
  WebkitOverflowScrolling: 'touch'
});

const useStyles = makeStyles((theme) => ({
  dashboardLayoutWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: '64px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: (props) => props.hasCompany ? '280px' : '0',
    }
  }
}));

const DashboardLayout = ({ hasCompany }) => {
  const theme = useTheme();
  const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState(false);

  const classes = useStyles({theme, hasCompany});

  return (
    <DashboardLayoutRoot>
      <DashboardNavbar
        hasCompany={hasCompany}
        onSidebarMobileOpen={() => setIsSidebarMobileOpen(true)}
      />
      {hasCompany && (
      <DashboardSidebar
        onMobileClose={() => setIsSidebarMobileOpen(false)}
        openMobile={isSidebarMobileOpen}
      />
      )}
      <div className={classes.dashboardLayoutWrapper}>
        <DashboardLayoutContainer>
          <DashboardLayoutContent>
            <Outlet />
          </DashboardLayoutContent>
        </DashboardLayoutContainer>
      </div>
    </DashboardLayoutRoot>
  );
};

DashboardLayout.propTypes = {
  hasCompany: PropTypes.bool
};

DashboardLayout.defaultProps = {
  hasCompany: false
};

export default DashboardLayout;
