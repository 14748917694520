import PropTypes from 'prop-types';
import { useNavigate } from 'react-router';

import { Box, Typography, Button, alpha } from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from '@mui/material/styles';

export default function WarningAlertOnPage(props) {
  const { message } = props;
  const theme = useTheme();

  const navigate = useNavigate();

  return (
    <Box
      id="e-Fatura-integration-banner"
      sx={{
        bgcolor: alpha(theme.palette.primary.main, 0.2),
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        p: 2
      }}
    >
      <InfoIcon
        sx={{ mr: 2 }}
      />
      <Typography>
        {message}
      </Typography>
      <Button
        id="integration-page-move-button"
        sx={{ fontWeight: 'bold', ml: '1rem' }}
        variant="outlined"
        onClick={() => navigate('/integracoes/minhas_integracoes')}
      >
        Clique aqui para integrar
      </Button>
    </Box>
  );
}

WarningAlertOnPage.propTypes = {
  message: PropTypes.string.isRequired
};
