/**
 * Recursively converts the keys of an object from camelCase with an initial lowercase letter
 * to camelCase with an initial uppercase letter.
 *
 * @param {object|array} obj - The input object or array to be transformed.
 * @returns {object|array} - The transformed object or array.
 */

export const camelCaseToCamelCaseWithLowerCaseFirstLetter = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map((item) => camelCaseToCamelCaseWithLowerCaseFirstLetter(item));
  }

  return Object.keys(obj).reduce((newObj, key) => {
    const newKey = key.replace(/_([a-z])/g, (matched, matchedLetter) => matchedLetter.toUpperCase());
    newObj[newKey] = camelCaseToCamelCaseWithLowerCaseFirstLetter(obj[key]);
    return newObj;
  }, {});
};
