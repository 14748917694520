import { useState, useLayoutEffect, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Button,
  Container,
  Dialog,
  Grid,
  Typography
} from '@mui/material';
import { addDays, format, parseISO, isAfter, isBefore } from 'date-fns';
import { clone } from 'lodash';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import useSettings from '../../hooks/useSettings';
import Table from '../../components/documents/Table';
import Form from '../../components/documents/Form';
import Toast from '../../components/Toast';
import { useDispatch, useSelector } from '../../store';
import { fetch as fetchInvoices, create as createInvoice, voidInvoice, sendInvoiceEmail, print as printInvoice } from '../../slices/invoices';
import { fetch as fetchCreditNotes } from '../../slices/creditNotes';
import { fetch as fetchReturnNotes } from '../../slices/returnNotes';
import { fetch as fetchSeries } from '../../slices/series';

import { fetch as fetchFiscalYears } from '../../slices/fiscalYear';
import { fetch as fetchClients } from '../../slices/clients';
import { fetch as fetchDocumentTypes } from '../../slices/documentTypes';
import { fetch as fetchPaymentPeriod } from '../../slices/paymentPeriod';
import { fetch as fetchPaymentMode } from '../../slices/paymentMode';
import { fetch as fetchItems } from '../../slices/itens';
import { fetch as fetchUnit } from '../../slices/units';
import { fetch as fetchTaxes } from '../../slices/taxes';
import { fetch as fetchIssuReasons } from '../../slices/issueReasons';
import { retrySendDocument as retrySendInvoice } from '../../slices/eFatura';

import FormToVoidInvoice from '../../components/FormToVoidInvoice';
import EmptyList from '../../components/empty_list';
import LoadingPopUp from '../../components/LoadingPopUp';
import ViewDetails from '../../components/documents/ViewDetails';

import { DOCUMENTS, POPUP_CONTENT_MESSAGE, TOAST_TYPE, DOCUMENTS_TO_VOID_INVOICE, DOCUMENTS_TO_VOID_INVOICE_FORMATTED, ISSUE_REASONS, ISSUE_REASONS_DOCS, EFATURA_BANNER_MESSAGE } from '../../constants';

import DeletePopUp from '../../components/DeletePopUp';
import Scrollbar from '../../components/Scrollbar';
import WarningAlertOnPage from '../../components/AlertOnPage';
import AlertPayment from '../../components/AlertPayment';
import { isCompanyNotIntegratedWithEFatura } from '../../util/eFaturasStatus';

const paymentFee = process.env.REACT_APP_PAYMENT_FEE;

const Invoice = () => {
  const [isAddMode, setIsAddMode] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isViewInvoiceModalOpen, setIsViewInvoiceModalOpen] = useState(false);
  const [isDeletePopUpOpen, setisDeletePopUpOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [isDocumentToVoidModalOpen, setIsDocumentToVoidModalOpen] = useState(false);

  const { settings } = useSettings();

  const dispatch = useDispatch();
  const { invoices, isLoading, onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state).invoices;
  const { series } = useSelector((state) => state).series;
  const { documentTypes } = useSelector((state) => state).documentTypes;
  const { paymentPeriods } = useSelector((state) => state).paymentPeriod;
  const { paymentModes } = useSelector((state) => state).paymentMode;
  const { clients } = useSelector((state) => state).clients;
  const { units } = useSelector((state) => state).units;
  const { taxes } = useSelector((state) => state).taxes;
  const { itens } = useSelector((state) => state).itens;
  const { issueReasons, issueReasonsFormatted } = useSelector((state) => state).issueReasons;
  const { currentCompany, isLoading: isLoadingCompanies } = useSelector((state) => state).companies;
  const { creditNotes } = useSelector((state) => state).creditNotes;
  const { returnNotes } = useSelector((state) => state).returnNotes;
  const { retrySendDocumentSuccess: retrySendInvoiceSuccess } = useSelector((state) => state).eFatura;
  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const [invoiceStorage, setInvoiceStorage] = useLocalStorage('invoiceStorage', null);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [draftKey, setDraftKey] = useState(null);
  const [isButtonNewClicked, setIsButtonNewClicked] = useState(false);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      Promise.all([
        dispatch(fetchInvoices()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchSeries()),
        dispatch(fetchFiscalYears()),
        dispatch(fetchClients()),
        dispatch(fetchDocumentTypes()),
        dispatch(fetchPaymentPeriod()),
        dispatch(fetchPaymentMode()),
        dispatch(fetchItems()),
        dispatch(fetchUnit()),
        dispatch(fetchTaxes())
      ]);
    }
  }, [dispatch, currentCompany]);

  const handleConfirmToCreate = () => {
    setSelectedDraft(null);
    setDraftKey(Math.random());
    setIsAddMode(true);
    setIsButtonNewClicked(false);
  };

  const handleNewClick = () => {
    setIsButtonNewClicked(true);
  };

  const handleEditDraft = (draft, keyDraft) => {
    setIsAddMode(true);
    dispatch(fetchSeries());
    setSelectedDraft(draft);
    setDraftKey(keyDraft);
  };

  const handleDeleteInvoiceDraft = (draftId) => {
    const draftsInvoiceStorage = clone(invoiceStorage);
    const indexCompany = draftsInvoiceStorage.invoices.findIndex((company) => currentCompany.id in company);
    const draftIndex = draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].findIndex((draft) => draftId in draft);

    if (draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].length <= 1) {
      draftsInvoiceStorage.invoices.splice(indexCompany, 1);
    } else {
      draftsInvoiceStorage.invoices[indexCompany][currentCompany.id].splice(draftIndex, 1);
    }
    setInvoiceStorage(draftsInvoiceStorage);
  };

  const handleUpdateSelectedInvoice = () => {
    if (selectedInvoice) {
      const invoiceUpdate = invoices.find((invoice) => invoice.id === selectedInvoice?.id);
      setSelectedInvoice(invoiceUpdate);
    }
  };

  const handleInvoiceDetailOpen = (invoice) => {
    setSelectedInvoice(invoice);
    setIsViewInvoiceModalOpen(true);
  };

  const handleInvoiceDuplicate = (invoice) => {
    setSelectedInvoice(invoice);
    handleNewClick();
  };

  const handleInvoiceDetailClose = () => {
    setIsViewInvoiceModalOpen(false);
    if (!isDocumentToVoidModalOpen) {
      setSelectedInvoice(null);
    }
  };

  const handleModalClose = () => {
    setIsAddMode(false);
    setSelectedInvoice(null);
  };

  const handleDeletePopUpOpen = () => {
    setisDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setisDeletePopUpOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handlePopUpClose = () => {
    handleUpdateSelectedInvoice();
    setIsPopUpOpen(false);
  };

  const handleCreateInvoice = (invoice) => {
    handlePopUpOpen();
    dispatch(createInvoice({
      ...invoice,
      documentType: documentTypes.find((documentType) => documentType.code === DOCUMENTS.invoice.code).id
    }));
  };

  const handleDocumentToVoidPopUpOpen = () => {
    setIsDocumentToVoidModalOpen(true);
    handleDeletePopUpClose();
    dispatch(fetchSeries());
    dispatch(fetchIssuReasons());
    dispatch(fetchCreditNotes());
    dispatch(fetchReturnNotes());
    dispatch(fetchPaymentMode());
    dispatch(fetchPaymentPeriod());
  };

  const handleDocumentToVoidPopUpClose = () => {
    setIsDocumentToVoidModalOpen(false);
  };

  const handleInvoiceVoid = (documentToVoid) => {
    dispatch(voidInvoice({ invoice: selectedInvoice, documentToVoid }));
    handlePopUpOpen();
    handleInvoiceDetailClose();
    handleDocumentToVoidPopUpClose();
  };
  /**
   * Send Email
   * @param {string} email - Email to send the Invoice email.
   * @return {void} - Return Void
   */
  const handleSendEmail = (email) => {
    dispatch(sendInvoiceEmail({ id: selectedInvoice.id, emailList: [{ email }] }));
    handlePopUpOpen();
  };

  const getNextInvoiceNumber = (serieId) => {
    const getInvoices = invoices ? invoices.filter((invoice) => invoice.serie === serieId) : 0;

    if ((!getInvoices || getInvoices.length === 0) && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);

      if (getSerie?.serialNumberConfig?.invoiceLastDocumentNumber) {
        return Number(getSerie.serialNumberConfig.invoiceLastDocumentNumber) + 1;
      }

      return Number(getSerie?.lastDocumentNumber) + 1;
    }

    return Number(getInvoices[getInvoices.length - 1]?.number) + 1;
  };

  const getNextReturnOrCreditNoteNumber = (serieId, documentCode) => {
    const documents = documentCode === DOCUMENTS.credit_note.code ? creditNotes : returnNotes;
    const lastDocumentNumber = `${documentCode === DOCUMENTS.credit_note.code ? 'creditNote' : 'returnNote'}LastDocumentNumber`;
    const getDocuments = documents ? documents.filter((document) => document.serie === serieId) : 0;

    if ((!getDocuments || getDocuments.length === 0) && series) {
      const getSerie = series.find((serie) => serie?.id === serieId);

      if (getSerie?.serialNumberConfig?.[lastDocumentNumber]) {
        return Number(getSerie.serialNumberConfig[lastDocumentNumber]) + 1;
      }

      return Number(getSerie?.lastDocumentNumber) + 1;
    }

    return Number(getDocuments[getDocuments.length - 1]?.number) + 1;
  };

  /**
   * isBefore is a function that returns true if the date is before the given date.
   * isAfter is a function that returns true if the date is after the given date.
   * @param {Date} date
   * @param {Object} serie
   */
  const isInSerieDateRange = (date, serie) => (isBefore(date, new Date(serie?.endDateRaw)) && isAfter(date, new Date(serie?.startDateRaw)));

  const handleRetrySendInvoice = (invoiceId) => {
    const invoiceInfo = { id: invoiceId, documentType: DOCUMENTS.invoice.name };
    dispatch(retrySendInvoice(invoiceInfo));
  };

  function returnResult() {
    const validIndex = 0;
    const companyIndex = invoiceStorage?.invoices.findIndex((company) => currentCompany.id in company);
    let companyDrafts = null;
    if (companyIndex >= validIndex) companyDrafts = invoiceStorage?.invoices[companyIndex][currentCompany.id];
    const hasCompanyDrafts = companyIndex >= validIndex && companyDrafts?.length > 0;

    if ((invoices && invoices.length > 0) || (companyIndex >= validIndex && hasCompanyDrafts)) {
      return (
        <Box sx={{ mt: 3 }}>
          <Table
            draftsStorage={companyIndex >= validIndex ? companyDrafts : null}
            deleteDraft={handleDeleteInvoiceDraft}
            selectedDraft={selectedDraft}
            setSelectedDraft={setSelectedDraft}
            documents={invoices}
            onOpenDetails={handleInvoiceDetailOpen}
            onClick={handleEditDraft}
            onVoid={(invoice) => {
              setSelectedInvoice(invoice);
              handleDeletePopUpOpen();
            }}
            onView={handleInvoiceDetailOpen}
            onDuplicate={handleInvoiceDuplicate}
            handleRetrySendDocument={handleRetrySendInvoice}
            retrySendDocumentSuccess={retrySendInvoiceSuccess}
          />
        </Box>
      );
    }

    return (
      <EmptyList
        title="Não tens nenhuma Fatura"
        buttonText="Criar Fatura"
        handleClick={handleNewClick}
      />
    );
  }

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if ((!onRequest && onRequest !== undefined) && (!onRequestFailure && onRequestFailure !== undefined)) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
      handleModalClose();
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  useEffect(() => {
    handleModalClose();
  }, [currentCompany]);

  const handlePrintInvoice = () => {
    dispatch(printInvoice(selectedInvoice));
  };

  const getPaymentPeriodRange = (values) => {
    if (paymentPeriods) {
      return format(addDays(values?.date, paymentPeriods.find((paymentPeriod) => paymentPeriod?.id === values?.paymentPeriod)?.numberOfDay || 0), 'dd/MM/yyyy');
    }
    return format(values?.date, 'dd/MM/yyyy');
  };

  if (isLoadingCompanies) {
    return <LoadingPopUp />;
  }
  return (
    <>
      <Helmet>
        <title>Faturas | Samba</title>
      </Helmet>
      {
        isCompanyNotIntegratedWithEFatura(currentCompany.integrations?.eFatura?.status) && (
          <WarningAlertOnPage
            message={EFATURA_BANNER_MESSAGE}
          />
        )
      }
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 8
        }}
      >
        <Container
          maxWidth={settings.compact ? 'xl' : false}
          sx={{
            backgroundColor: isAddMode ? 'background.paper' : '',
            mt: isAddMode ? -8 : ''
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            spacing={3}
          >
            <Grid item>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {!isAddMode ? 'Fatura' : 'Nova Fatura'}
              </Typography>

              <Typography
                color="textSecondary"
                variant="overline"
              >
                Faturas
              </Typography>
            </Grid>
            {!isAddMode && (
            <Grid item>
              <Button
                id="invoice-new-button"
                color="primary"
                variant="contained"
                onClick={handleNewClick}
              >
                Novo
              </Button>
            </Grid>
            ) }

          </Grid>
          {!isAddMode && (
          <Box sx={{ mt: 3 }}>
            {isLoading
              ? (
                <LoadingPopUp />
              ) : returnResult()}
          </Box>
          )}
          <Box sx={{ mt: 3 }}>
            {isAddMode && (
            <Form
              onComplete={handleModalClose}
              onCancel={handleModalClose}
              onSubmitForm={handleCreateInvoice}
              series={series}
              clients={clients}
              paymentPeriods={paymentPeriods}
              documentTypes={documentTypes}
              paymentModes={paymentModes}
              units={units}
              taxes={taxes}
              itens={itens}
              document={selectedInvoice}
              isInSerieDateRange={isInSerieDateRange}
              getNextDocumentNumber={getNextInvoiceNumber}
              draftId={draftKey}
              documentDraft={selectedDraft}
              documentStorage={invoiceStorage}
              setDocumentStorage={setInvoiceStorage}
              handleDeleteDraft={handleDeleteInvoiceDraft}
              currentCompany={currentCompany}
              isOnRequest={onRequest}
            />
            )}
          </Box>

        </Container>
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleInvoiceDetailClose}
          open={Boolean(isViewInvoiceModalOpen && selectedInvoice)}
        >
          <Scrollbar>
            {Boolean(isViewInvoiceModalOpen && selectedInvoice) && (
            <ViewDetails
              currentCompany={currentCompany}
              title="Fatura"
              document={{ ...selectedInvoice, dateFormatted: format(parseISO(selectedInvoice.date), 'dd/MM/yyyy'), paymentLimit: format(addDays(parseISO(selectedInvoice.date), selectedInvoice.paymentPeriod.numberOfDay), 'dd/MM/yyyy') }}
              onCancel={handleInvoiceDetailClose}
              onVoid={handleDeletePopUpOpen}
              handleSendEmail={handleSendEmail}
              handlePrintButtonClick={handlePrintInvoice}
            />
            )}
          </Scrollbar>
        </Dialog>
        <Toast
          isOpen={isPopUpOpen}
          toastType={toastType}
          message={message}
          onClose={handlePopUpClose}
        />
        <Dialog
          maxWidth="xs"
          onClose={handleDeletePopUpClose}
          open={isDeletePopUpOpen}
        >
          {isDeletePopUpOpen && (
          <DeletePopUp
            message={POPUP_CONTENT_MESSAGE.VOID.MESSAGE}
            action={POPUP_CONTENT_MESSAGE.VOID.ACTION}
            onCancel={handleDeletePopUpClose}
            onDelete={handleDocumentToVoidPopUpOpen}
          />
          )}
        </Dialog>
        <AlertPayment
          message={`
          Agora você pode oferecer aos seus clientes a opção de pagar as faturas enviadas
          por email usando o Samba. É rápido, seguro e prático!
          Cada pagamento processado pelo Samba será sujeito a uma taxa de ${paymentFee}%.
          `}
          isButtonNewClicked={isButtonNewClicked}
          handleConfirm={handleConfirmToCreate}
          companyId={currentCompany.id}
        />
        <Dialog
          fullWidth
          maxWidth="sm"
          onClose={handleDocumentToVoidPopUpClose}
          open={Boolean(isDocumentToVoidModalOpen && selectedInvoice)}
        >
          {isDocumentToVoidModalOpen && (
            <FormToVoidInvoice
              onCancel={handleDocumentToVoidPopUpClose}
              onSubmitForm={handleInvoiceVoid}
              paymentPeriods={paymentPeriods}
              paymentModes={paymentModes}
              isInSerieDateRange={isInSerieDateRange}
              getNextReturnOrCreditNoteNumber={getNextReturnOrCreditNoteNumber}
              serie={series?.find((serie) => serie.id === selectedInvoice?.serie)}
              documentsToVoidInvoice={DOCUMENTS_TO_VOID_INVOICE}
              issueReasons={issueReasons}
              issueReasonsAllowed={ISSUE_REASONS}
              issueReasonsTypes={issueReasonsFormatted}
              issueReasonsDocs={ISSUE_REASONS_DOCS}
              documentsToVoidInvoiceFormatted={DOCUMENTS_TO_VOID_INVOICE_FORMATTED}
              getPaymentPeriodRange={getPaymentPeriodRange}
            />
          )}
        </Dialog>
      </Box>
    </>
  );
};

export default Invoice;
