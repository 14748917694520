import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import Scrollbar from '../Scrollbar';

const FiscalYearListTable = (props) => {
  const { fiscalYears, onOpenFiscalYearDetail, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Nome
                </TableCell>
                <TableCell>
                  Data de Início
                </TableCell>
                <TableCell>
                  Data de Fim
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {fiscalYears.map((fiscalYear) => (
                <TableRow
                  hover
                  key={fiscalYear.id}
                  onClick={() => onOpenFiscalYearDetail(fiscalYear)}
                >
                  <TableCell>
                    <Link
                      color="textPrimary"
                      component={RouterLink}
                      to="#"
                      underline="none"
                      variant="subtitle2"
                    >
                      {fiscalYear.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {fiscalYear.startDate}
                  </TableCell>
                  <TableCell>
                    {fiscalYear.endDate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

FiscalYearListTable.propTypes = {
  fiscalYears: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    startDateRaw: PropTypes.string,
    endDate: PropTypes.string,
    endDateRaw: PropTypes.string
  }).isRequired,
  onOpenFiscalYearDetail: PropTypes.func.isRequired
};

export default FiscalYearListTable;
