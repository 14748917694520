import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';
import { InfoOutlined as InfoIcon } from '@mui/icons-material';

import Scrollbar from '../Scrollbar';
import {
  eFaturasStatus,
  isDocumentEfaturaStatusFailed as isReceiptEfaturaStatusFailed,
  getDocumentEfaturaStatusButton as getReceiptEfaturaStatusButton
} from '../../util/eFaturasStatus';

const ReceiptTable = (props) => {
  const { receipts, formatDate, onOpenReceiptDetails, handleRetrySendDocument, retrySendReceiptSuccess, ...other } = props;
  const [receiptRetried, setReceiptRetried] = useState(null);
  const [receiptsRetried, setReceiptsRetried] = useState([]);

  useEffect(() => {
    if (retrySendReceiptSuccess) {
      setReceiptsRetried([...receiptsRetried, receiptRetried]);
    }
  }, [retrySendReceiptSuccess, receiptRetried]);

  const handleOpenReceiptDetails = (receipt) => {
    if (!receipt.isVoided) {
      const isRetried = receiptsRetried.includes(receipt.id);
      onOpenReceiptDetails({
        ...receipt,
        isRetried
      });
    }
  };

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table
            id="receipt-list"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Recibo
                </TableCell>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Estado E-Fatura
                </TableCell>
                <TableCell>
                  Data de pagamento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              id="receipt-list-table-body"
            >
              {receipts.map((receipt, index) => (
                <TableRow
                  id={`receipt-list-element-${index}`}
                  hover={!receipt.isVoided}
                  key={receipt.id}
                  variant={!receipt.isVoided ? '' : 'disabled'}
                  onClick={() => handleOpenReceiptDetails(receipt)}
                >
                  <TableCell variant="disabled">
                    {String(receipt.number).padStart(3, '0')}
                    {' / '}
                    {receipt.serie.code}
                  </TableCell>
                  <TableCell
                    id={`receipt-client-name-${index}`}
                    variant="disabled"
                  >
                    {receipt.client.name}
                  </TableCell>
                  <TableCell
                    variant="disabled"
                    id="receipt-table-cell-e-fatura-column"
                  >
                    {receipt.efaturaData && (
                      <Chip
                        id={`receipt-status-e-fatura-button-${index}`}
                        size="medium"
                        style={{
                          padding: isReceiptEfaturaStatusFailed(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status)) ? '10.2px' : '14px',
                          color: `${eFaturasStatus(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status)).color}`,
                          backgroundColor: `${eFaturasStatus(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status)).backgroundColor}`
                        }}
                        icon={
                          isReceiptEfaturaStatusFailed(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status)) && (
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <InfoIcon
                                size="small"
                                sx={{ color: '#FF5E52' }}
                              />
                            </Box>
                          )
                        }
                        label={eFaturasStatus(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status)).label}
                        clickable={isReceiptEfaturaStatusFailed(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status))}
                        onClick={(e) => {
                          if (isReceiptEfaturaStatusFailed(getReceiptEfaturaStatusButton(receiptsRetried, receipt.id, receipt.efaturaData.status))) {
                            e.stopPropagation();
                            handleRetrySendDocument(receipt.id);
                            setReceiptRetried(receipt.id);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell variant="disabled">
                    {formatDate(receipt.paymentDate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

ReceiptTable.propTypes = {
  receipts: PropTypes.arrayOf({
    id: PropTypes.string,
    paymentDate: PropTypes.string,
    clients: PropTypes.shape({
      name: PropTypes.string
    }).isRequired,
    series: PropTypes.shape({
      code: PropTypes.string
    }).isRequired,
    number: PropTypes.string
  }).isRequired,
  formatDate: PropTypes.func.isRequired,
  onOpenReceiptDetails: PropTypes.func.isRequired,
  efaturaData: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string
  }),
  handleRetrySendDocument: PropTypes.func.isRequired,
  retrySendReceiptSuccess: PropTypes.bool.isRequired
};

export default ReceiptTable;
