import { TextField } from '@mui/material';
import React from 'react';

import './style.css';

function NumericTextField({ ...rest }) {
  const handlePaste = (e) => {
    // [ˆe]|^[0-9]*$ - regex to match only numbers
    if (!e.target.value.match(/[ˆe]|^[0-9]*$/)) {
      e.preventDefault();
    }
  };

  return (
    <TextField
      type="number"
      InputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        type: 'number'
      }}
      onKeyDown={(e) => {
        // to prevent the user to type any character different from a number
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          e.preventDefault();
        }
      }}
      onPasteCapture={handlePaste}
      onPaste={handlePaste}
      {...rest}
    />
  );
}

export default NumericTextField;
