import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Box, Tab, Tabs } from '@mui/material';

import { useDispatch, useSelector } from '../../store';
import { fetchById as fetchCompanyById } from '../../slices/companies';
import {
  fetch as fetchBankWithdrawals,
  requestBankWithdrawal,
  clearOnRequestState
} from '../../slices/bankWithdrawals';
import { fetch as fetchBankAccounts } from '../../slices/bankAccounts';
import { fetchSambaConstants } from '../../slices/sambaConstants';
import { fetchCompanyConstants } from '../../slices/companyConstants';
import { fetch as fetchCompanyIntegrations } from '../../slices/companyIntegrations';

import Wrapper from '../../components/Wrapper';
import EmptyList from '../../components/empty_list';
import H1Section from '../../components/Header/H1Section';
import DialogPopUp from '../../components/DialogPopUp';
import LoadingPopUp from '../../components/LoadingPopUp';
import StatusMessagePopup from '../../components/StatusMessagePopup';
import BankWithdrawalTable from '../../components/BankWithdrawal/Table';
import DisplayBalance from '../../components/DisplayBalance';
import BankWithdrawalForm from '../../components/BankWithdrawal/BankWithdrawalForm';
import IntegrationWarning from '../../components/WarningOnPage';

import { BANK_WITHDRAWAL_TYPE, WARNING_PING_INTEGRATION_TEXT } from '../../constants';
import { isCompanyIntegratedWithPing } from '../../util/isCompanyIntegratedWithPing';
import TabPanel from '../../components/TabPanel';
import calculatePingBalance from '../../util/calculatePingBalance';
import calculateCardBalance from '../../util/calculateCardBalance';

const BankWithdrawal = () => {
  const dispatch = useDispatch();
  const { sambaConstants } = useSelector((state) => state.sambaConstants);
  const { companyConstants } = useSelector((state) => state.companyConstants);
  const { bankWithdrawals, onRequest, onRequestSuccess, onRequestFailure } = useSelector((state) => state.bankWithdrawals);

  const {
    companyIntegrations,
    isLoading: isGetCompanyIntegrationsLoading
  } = useSelector((state) => state.companyIntegrations);

  const { currentCompany } = useSelector((state) => state.companies);
  const { bankAccounts } = useSelector((state) => state.bankAccounts);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIntegratedWithPing, setIsIntegratedWithPing] = useState(false);
  const [bankAccountNotExistMessage, setBankAccountNotExistMessage] = useState(null);
  const [tabValue, setTabValue] = useState(0);

  const handleRequestNewBankWithdrawalUser = () => {
    if (bankAccounts.length) {
      setIsModalOpen(true);
    } else {
      setBankAccountNotExistMessage('Esta empresa não tem uma conta bancaria associada.');
    }
  };
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleClearSuccessState = () => {
    dispatch(clearOnRequestState());
    handleModalClose();
  };

  const handleStatusMessagePopUpClose = () => {
    dispatch(clearOnRequestState());
    setBankAccountNotExistMessage(null);
  };

  const handleSubmit = (values) => {
    // Determine the payment method based on the tab value
    const paymentMethod = tabValue === 0 ? BANK_WITHDRAWAL_TYPE.PING : BANK_WITHDRAWAL_TYPE.CARD;

    const dataToSend = { values, paymentMethod };

    dispatch(requestBankWithdrawal(dataToSend));
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderEmptyList = () => (
    <EmptyList
      id="empty-list-bank-withdrawal-page"
      title="Ainda nāo foi realizado nenhum levantamento bancário"
    />
  );

  const renderBankWithdrawalList = () => {
    if (onRequest) {
      return (<LoadingPopUp message="Carregando..." />);
    }
    if (bankWithdrawals.length) {
      return (
        <Box sx={{ mt: 3 }}>
          <BankWithdrawalTable
            bankWithdrawals={bankWithdrawals}
          />
        </Box>
      );
    }
    return renderEmptyList();
  };

  /**
   * Returns the label for a tab based on the tab value.
   * 0 - Ping || 1 - Card
   * @param {number} tab - The value of the tab.
   * @returns {string} The label for the tab.
   */
  const getTabLabel = (tab) => (tab === 0 ? 'Saldo Ping Atual:' : 'Saldo Cartōes Online Atual:');

  /**
   * Returns the balance value based on the tab and company.
   * 0 - Ping || 1 - Card
   * @param {number} tab - The value of the tab.
   * @param {Object} company - The company object.
   * @returns {number} The balance value based on the tab and company.
   */
  const getBalanceValue = (tab, company) => (tab === 0 ? calculatePingBalance(company)?.value : calculateCardBalance(company)?.value);

  useEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchSambaConstants());
      dispatch(fetchCompanyConstants());
      dispatch(fetchCompanyById());
      dispatch(fetchBankAccounts());
    }
  }, [dispatch, currentCompany.id]);

  useEffect(() => {
    if (onRequestSuccess) {
      handleModalClose();
    }
  }, [onRequestSuccess]);

  useEffect(() => {
    setIsIntegratedWithPing(isCompanyIntegratedWithPing(companyIntegrations));
  }, [companyIntegrations]);

  useLayoutEffect(() => {
    if (currentCompany.id) {
      dispatch(fetchCompanyIntegrations());
    }
  }, [bankAccounts]);

  useEffect(() => {
    if (isIntegratedWithPing && bankAccounts?.length) {
      dispatch(fetchBankWithdrawals({
        bankAccountId: bankAccounts[0].id
      }));
    }
  }, [isIntegratedWithPing, bankAccounts]);

  const renderBankWithdrawalPage = () => {
    if (isGetCompanyIntegrationsLoading !== null) {
      if (isGetCompanyIntegrationsLoading) {
        return <LoadingPopUp />;
      }

      if (isIntegratedWithPing) {
        return (
          <>
            <Box>
              <DisplayBalance
                label={getTabLabel(tabValue)}
                balance={getBalanceValue(tabValue, currentCompany)}
              />
            </Box>
            {renderBankWithdrawalList()}
          </>
        );
      }

      return (
        <IntegrationWarning
          message={WARNING_PING_INTEGRATION_TEXT}
          buttonLabel="Integrar com o SAMBA POS"
          url="/integracoes/minhas_integracoes"
        />
      );
    }

    return null;
  };

  const renderDialogPopUp = () => {
    if (onRequest) {
      return <LoadingPopUp message="Carregando..." />;
    }
    return (
      <DialogPopUp
        onClose={handleModalClose}
        open={isModalOpen}
      >
        <BankWithdrawalForm
          balances={currentCompany.balances}
          sambaConstants={sambaConstants}
          companyConstants={companyConstants}
          bankAccounts={bankAccounts}
          onSubmit={handleSubmit}
          onCancel={handleModalClose}
          withdrawalType={tabValue === 0 ? BANK_WITHDRAWAL_TYPE.PING : BANK_WITHDRAWAL_TYPE.CARD}
        />
      </DialogPopUp>
    );
  };

  return (
    <>
      <Helmet>
        <title>Levantamento Bancário | Samba</title>
      </Helmet>
      <Wrapper>
        <H1Section
          title="Levantamento Bancário"
          description="Levantamento Bancário"
          buttonTitle={isIntegratedWithPing ? 'Solicitar' : null}
          buttonId="request-bank-withdrawal-button"
          onClick={handleRequestNewBankWithdrawalUser}
        />
        <Box>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            sx={{ mt: 3 }}
          >
            <Tab
              id="ping-bank-withdrawal-tab"
              label="PING"
            />
            <Tab
              id="card-bank-withdrawal-tab"
              label="CARTŌES ONLINE"
            />
          </Tabs>

          <TabPanel
            value={tabValue}
            index={0}
          >
            {renderBankWithdrawalPage()}
          </TabPanel>

          <TabPanel
            value={tabValue}
            index={1}
          >
            {renderBankWithdrawalPage()}
          </TabPanel>
        </Box>
      </Wrapper>

      {renderDialogPopUp()}

      <StatusMessagePopup
        isOpen={onRequestSuccess}
        handleClosePopUp={handleClearSuccessState}
        text="Levantamento bancário solicitado com sucesso."
        title="Sucesso!"
        status="success"
      />
      <StatusMessagePopup
        isOpen={Boolean(onRequestFailure) || Boolean(bankAccountNotExistMessage)}
        handleClosePopUp={handleStatusMessagePopUpClose}
        text={onRequestFailure || bankAccountNotExistMessage}
        title="Erro!"
        status="error"
      />
    </>
  );
};

export default BankWithdrawal;
