import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';

import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';
import { PAYMENT_SERVICES, SAMBA_PAYMENT_DEBT_STATUS } from '../../constants';

const paymentStatus = (status) => {
  let statusLabel;
  switch (status) {
    case SAMBA_PAYMENT_DEBT_STATUS.liquidated:
      statusLabel = {
        label: 'Liquidado',
        color: '#21C8C3',
        backgroundColor: 'rgba(33, 200, 195, 0.1)'
      };
      break;
    case SAMBA_PAYMENT_DEBT_STATUS.pending:
      statusLabel = {
        label: 'Pendente',
        color: '#F1B37A',
        backgroundColor: 'rgba(241, 179, 122, 0.1)'
      };
      break;
    default:
      statusLabel = null;
  }
  return statusLabel;
};

const SambaPaymentsTable = (props) => {
  const { documents, formatDate, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table
            id="online-payments-list"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  Número
                </TableCell>
                <TableCell>
                  Data de pagamento
                </TableCell>
                <TableCell>
                  Pagamento online via
                </TableCell>
                <TableCell>
                  Valor de Recibo
                </TableCell>
                <TableCell>
                  Taxa
                </TableCell>
                <TableCell>
                  Valor a receber
                </TableCell>
                <TableCell>
                  Estado do pagamento
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {documents.map((document, index) => (
                <TableRow>
                  <TableCell>
                    {document.receiptNumber.toString().padStart(3, '0')}
                  </TableCell>
                  <TableCell>
                    {formatDate(document.paymentDate)}
                  </TableCell>
                  <TableCell>
                    {document.paymentService === PAYMENT_SERVICES.ping ? 'Ping' : 'Cartão'}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(document.totalPaid)}
                    $
                  </TableCell>
                  <TableCell>
                    {formatCurrency(document.feeValue)}
                    $
                  </TableCell>
                  <TableCell>
                    {formatCurrency(document.totalReceivable)}
                    $
                  </TableCell>
                  <TableCell id={`receipt-${index + 1}-debt-status`}>
                    {document.debtStatus && (
                      <Chip
                        size="medium"
                        label={
                          paymentStatus(
                            document.debtStatus
                          )?.label
                        }
                        style={{
                          padding: '14px',
                          color: `${
                            paymentStatus(
                              document.debtStatus
                            )?.color
                          }`,
                          backgroundColor: `${
                            paymentStatus(
                              document.debtStatus
                            )?.backgroundColor
                          }`
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

SambaPaymentsTable.propTypes = {
  documents: PropTypes.arrayOf({
    receiptId: PropTypes.string.isRequired,
    receiptNumber: PropTypes.string.isRequired,
    totalPaid: PropTypes.number.isRequired,
    feeValue: PropTypes.number.isRequired,
    totalReceivable: PropTypes.number.isRequired,
    paymentDate: PropTypes.string.isRequired,
    paymentService: PropTypes.string.isRequired,
    debtStatus: PropTypes.string.isRequired
  }).isRequired,
  formatDate: PropTypes.func.isRequired
};

export default SambaPaymentsTable;
