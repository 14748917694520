import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from '../lib/axios';

const initialState = {
  posts: [],
  post: {},
  loading: false,
  loadingError: false
};

const slice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    _get: (state) => {
      state.loading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.posts = payload;
      state.loading = false;
      state.error = false;
    },
    _getSuccessById: (state, { payload }) => {
      state.post = payload;
      state.loading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.loading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getSuccessById,
  _getFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'blogPosts/fetch',
  async (_, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get('posts');
      dispatch(_getSuccess(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const fetchById = createAsyncThunk(
  'blogPosts/fetchById',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`posts/${id}`);

      const markdown = await axios.get(response.data.url);
      response.data.markdown = markdown.data;
      dispatch(_getSuccessById(response.data));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);
