import PropTypes from 'prop-types';
import { Box, Divider, Typography, useMediaQuery } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    display: 'grid',
    gridTemplateAreas:
    "'sambaLogo socialMedia chuvaLogo'",
    alignItems: 'center',
    margin: theme.spacing(0, 4),
    padding: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      gridTemplateAreas: `
      'socialMedia socialMedia'
      'sambaLogo chuvaLogo'
      `,
      alignItems: 'center',
      margin: theme.spacing(0, 2),
      padding: theme.spacing(0.5)
    }
  },
  divider: {
    background: '#FFFFFF',
    height: '1rem'
  },

  sambaLogoContainer: {
    gridArea: 'sambaLogo'
  },

  sambaLogo: {
    fontWeight: 600,
    fontSize: '2rem',
    fontFamily: 'Blogger Sans',
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      margin: theme.spacing(0)
    }
  },

  chuvaLogoContainer: {
    gridArea: 'chuvaLogo'
  },

  chuvaLogo: {
    width: '6rem',
    [theme.breakpoints.down('sm')]: {
      width: '5rem'
    }
  },

  socialMediaContainer: {
    gridArea: 'socialMedia',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(0),
      flexDirection: 'row'
    }
  },

  socialMediaText: {
    fontSize: '1rem',
    fontWeight: 600,
    fontFamily: 'Gill Sans',
    padding: theme.spacing(0, 1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      margin: theme.spacing(0)
    }
  },

  socialMediaIcon: {
    margin: theme.spacing(-0.9, 0),
    width: '1.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '3rem'
    }
  }
}));

export function Footer(props) {
  const { handleFacebookClick, handleInstagramClick, ...rest } = props;
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  return (
    <Box
      {...rest}
      component="footer"
    >
      <Box
        className={classes.footer}
      >
        <Box className={classes.sambaLogoContainer}>
          <Typography
            color="#FFFFFF"
            className={classes.sambaLogo}
          >
            SAMBA
          </Typography>
        </Box>
        <Box className={classes.socialMediaContainer}>
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            className={classes.socialMediaText}
          >
            samba@chuva.io
          </Typography>
          {isDesktop && (
            <Divider
              orientation="vertical"
              className={classes.divider}
            />
          )}
          <Typography
            variant="textPrimary"
            color="#FFFFFF"
            className={classes.socialMediaText}
          >
            +238 353 35 17
          </Typography>
          {isDesktop && (
            <Divider
              orientation="vertical"
              className={classes.divider}
            />
          )}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 2
            }}
          >
            <a
              href="https://www.facebook.com/SAMBA-Cloud-108642664819245"
              target="_blank"
              rel="noreferrer"
              onClick={handleFacebookClick}
            >
              <Box
                alt="Facebook"
                component="img"
                src="/static/homepage/facebook-icon.svg"
                className={classes.socialMediaIcon}
              />
            </a>
            <a
              href="https://www.instagram.com/samba.cloud/"
              target="_blank"
              rel="noreferrer"
              onClick={handleInstagramClick}
            >
              <Box
                alt="Instagram"
                component="img"
                src="/static/homepage/instagram-icon.svg"
                className={classes.socialMediaIcon}
              />
            </a>
          </Box>
        </Box>
        <Box className={classes.chuvaLogoContainer}>
          <a
            href="https://chuva.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              alt="Powered by Chuva"
              component="img"
              align="right"
              src="/static/homepage/powered-by-chuva.png"
              className={classes.chuvaLogo}
            />
          </a>
        </Box>
      </Box>
    </Box>
  );
}

Footer.propTypes = {
  handleFacebookClick: PropTypes.func.isRequired,
  handleInstagramClick: PropTypes.func.isRequired
};
