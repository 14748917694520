import AuthGuardMiddleware from './pages/AuthGuardMiddleware';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';

// Dashboard Page
import Summary from './pages/Dashboard/Summary';

// Home Page
import HomePage from './pages/HomePage';
import PublicInvoice from './pages/PublicInvoice';
import PublicReceipt from './pages/PublicReceipt';
import PublicInvoiceReceipt from './pages/PublicInvoiceReceipt';
import PublicCreditNote from './pages/PublicCreditNote';
import PublicDebitNote from './pages/PublicDebitNote';
import PublicReturnNote from './pages/PublicReturnNote';
import PublicProForma from './pages/PublicProForma';
import PublicPayment from './pages/PublicPayment';
import PublicSalesReceipt from './pages/PublicSalesReceipt';

// Welcome Page
import WelcomePage from './pages/WelcomePage';

// Blog Page
import BlogPage from './pages/blog/BlogPage';
import PostDetails from './pages/blog/PostDetails';

// Authentication pages
import Register from './pages/authentication/Register';
import Login from './pages/authentication/Login';
import ForgotPassword from './pages/authentication/ForgotPassword';
import ResetPassword from './pages/authentication/ResetPassword';
// Company Page
import Company from './pages/Company';
// General pages
import Client from './pages/general/Client';
import FiscalYear from './pages/general/FiscalYear';
import Provider from './pages/general/Provider';

// Invoice pages
import Invoice from './pages/invoice/Invoice';
import Receipt from './pages/Documents/Receipt/Receipt';
import CreditNote from './pages/Documents/CreditNote/CreditNote';
import DebitNote from './pages/Documents/DebitNote/DebitNote';
import ProForma from './pages/Documents/ProForma/ProForma';
import InvoiceReceipt from './pages/Documents/InvoiceReceipt/InvoiceReceipt';
import Item from './pages/invoice/Item';
import Series from './pages/invoice/Series';
import ReturnNote from './pages/Documents/ReturnNote/ReturnNote';
import SambaPayment from './pages/invoice/SambaPayment';
import SalesReceipt from './pages/Documents/SalesReceipt/SalesReceipt';

// Purchase pages
import PurchaseInvoice from './pages/purchase/PurchaseInvoice';

// Configuration pages
import BankAccount from './pages/configuration/BankAccount';
import PaymentMode from './pages/configuration/PaymentMode';
import PaymentPeriod from './pages/configuration/PaymentPeriod';
import Unit from './pages/configuration/Unit';
import Integrations from './pages/integrations';
import Users from './pages/userManagement';
import BankWithdrawal from './pages/BankWithdrawal';
import Transactions from './pages/Transactions';
import MyStore from './pages/Settings/Ecommerce/MyStore';

import TermsAndConditions from './pages/TermsAndConditions';

// E-commerce pages
import Products from './pages/Ecommerce/Products';
import Orders from './pages/Ecommerce/Orders';

// Error pages
import AuthorizationRequired from './pages/AuthorizationRequired';
import NotFound from './pages/NotFound';
import ServerError from './pages/ServerError';

const routes = [
  {
    path: '/',
    element: (
      <GuestGuard>
        <HomePage />
      </GuestGuard>
    )
  },
  {
    path: 'termos-e-condicoes',
    element: (
      <TermsAndConditions />
    )
  },
  {
    path: 'blog',
    element: <BlogPage />
  },
  {
    path: 'blog/:id',
    element: <PostDetails />
  },
  {
    path: 'faturas/:id',
    element: <PublicInvoice />
  },
  {
    path: 'recibos/:id',
    element: <PublicReceipt />
  },
  {
    path: 'faturas_recibo/:id',
    element: <PublicInvoiceReceipt />
  },
  {
    path: 'notas_de_credito/:id',
    element: <PublicCreditNote />
  },
  {
    path: 'notas_de_debito/:id',
    element: <PublicDebitNote />
  },
  {
    path: 'notas_de_devolucao/:id',
    element: <PublicReturnNote />
  },
  {
    path: 'pro_formas/:id',
    element: <PublicProForma />
  },
  {
    path: 'intencao_de_pagamento/:id',
    element: <PublicPayment />
  },
  {
    path: 'bem_vindo',
    element: (
      <AuthGuardMiddleware>
        <WelcomePage />
      </AuthGuardMiddleware>
    )
  },
  {
    path: 'talao_de_vendas/:id',
    element: <PublicSalesReceipt />
  },
  {
    path: 'minhas_empresas',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: '',
        element: <Company />
      }
    ]
  },
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'register-unguarded',
        element: <Register />
      }
    ]
  },
  {
    path: 'forgot_password',
    element: (
      <GuestGuard>
        <ForgotPassword />
      </GuestGuard>
    )
  },
  {
    path: 'reset_password/:token',
    element: (
      <GuestGuard>
        <ResetPassword />
      </GuestGuard>
    )
  },
  {
    path: 'dashboard',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'resumo',
        element: <Summary />
      }
    ]
  },
  {
    path: 'geral',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'ano_fiscal',
        element: <FiscalYear />
      },
      {
        path: 'clientes',
        element: <Client />
      },
      {
        path: 'fornecedores',
        element: <Provider />
      }
    ]
  },
  {
    path: 'faturacao',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'faturas',
        element: <Invoice />
      },
      {
        path: 'recibos',
        element: <Receipt />
      },
      {
        path: 'itens',
        element: <Item />
      },
      {
        path: 'series',
        element: <Series />
      },
      {
        path: 'fatura_recibo',
        element: <InvoiceReceipt />
      },
      {
        path: 'nota_de_debito',
        element: <DebitNote />
      },
      {
        path: 'nota_de_credito',
        element: <CreditNote />
      },
      {
        path: 'pro_forma',
        element: <ProForma />
      },
      {
        path: 'nota_de_devolucao',
        element: <ReturnNote />
      },
      {
        path: 'pagamentos_via_samba',
        element: <SambaPayment />
      },
      {
        path: 'talao_de_venda',
        element: <SalesReceipt />
      }
    ]
  },
  {
    path: 'compras',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'despesas',
        element: <PurchaseInvoice />
      }
    ]
  },
  {
    path: 'configuracao',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'modo_de_pagamento',
        element: <PaymentMode />
      },
      {
        path: 'periodo_de_pagamento',
        element: <PaymentPeriod />
      },
      {
        path: 'unidade',
        element: <Unit />
      },
      {
        path: 'conta_bancaria',
        element: <BankAccount />
      },
      {
        path: 'utilizadores',
        element: <Users />
      },
      {
        path: 'e-commerce',
        children: [
          {
            path: 'minha_loja',
            element: <MyStore />
          }
        ]
      }
    ]
  },
  {
    path: 'ping',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'levantamento_bancario',
        element: <BankWithdrawal />
      },
      {
        path: 'transacoes',
        element: <Transactions />
      }
    ]
  },
  {
    path: 'integracoes',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'minhas_integracoes',
        element: <Integrations />
      }
    ]
  },
  {
    path: 'e-commerce',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: 'produtos',
        element: <Products />
      },
      {
        path: 'pedidos',
        element: <Orders />
      }
    ]
  },
  {
    path: '*',
    element: (
      <AuthGuardMiddleware>
        <DashboardLayout />
      </AuthGuardMiddleware>
    ),
    children: [
      {
        path: '401',
        element: <AuthorizationRequired />
      },
      {
        path: '404',
        element: <NotFound />
      },
      {
        path: '500',
        element: <ServerError />
      },
      {
        path: '*',
        element: <NotFound />
      }
    ]
  }
];

export default routes;
