import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { useDropzone } from 'react-dropzone';

const UploadFile = ({
  acceptedFormats,
  dropzoneStyles,
  handleDelete,
  placeholder,
  currentFile,
  maxFiles,
  subtitle,
  setFile,
  colors,
  title
}) => {
  const onDrop = (acceptedFiles) => {
    setFile(Object.assign(acceptedFiles[0], {
      preview: URL.createObjectURL(acceptedFiles[0])
    }));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptedFormats,
    maxFiles
  });

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box>
          <Typography
            variant="overline"
            color={colors.textColor}
          >
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography
            variant="caption"
            color={colors.textColor}
          >
            {subtitle}
          </Typography>
        </Box>
      </Box>
      <Box>
        {!currentFile
          ? (
            <Box
              {...getRootProps()}
              style={dropzoneStyles}
            >
              <input {...getInputProps()} />
              <Typography
                variant="overline"
                color={colors.textColor}
              >
                {placeholder}
              </Typography>
            </Box>
          )
          : (
            <Box sx={{ position: 'relative', display: 'inline-block' }}>
              <img
                src={currentFile.preview}
                alt={currentFile.name}
                style={{
                  width: '100%',
                  aspectRatio: 1,
                  objectFit: 'cover',
                  height: '11.875rem',
                  borderRadius: '1rem',
                  border: `0.125rem solid ${colors.borderColor}`
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  top: '-0.75rem',
                  right: '-0.75rem',
                  backgroundColor: colors.deleteIconBackgroundColor,
                  borderRadius: '1rem',
                  width: '1.5rem',
                  height: '1.5rem',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 2
                }}
              >
                <IconButton
                  onClick={handleDelete}
                  color="secondary"
                  size="small"
                >
                  <DeleteIcon style={{ color: colors.deleteIconColor }} />
                </IconButton>
              </Box>
            </Box>
          )}
      </Box>
    </Box>
  );
};

UploadFile.defaultProps = {
  maxFiles: 1
};

UploadFile.propTypes = {
  title: PropTypes.string.isRequired,
  acceptedFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentFile: PropTypes.shape({
    preview: PropTypes.string,
    name: PropTypes.string
  }),
  onDrop: PropTypes.func.isRequired,
  dropzoneStyles: PropTypes.object,
  theme: PropTypes.object.isRequired,
  subtitle: PropTypes.string.isRequired,
  dropzoneProps: PropTypes.object,
  handleDelete: PropTypes.func.isRequired,
  maxFiles: PropTypes.number,
  placeholder: PropTypes.string.isRequired,
  setFile: PropTypes.func.isRequired,
  colors: PropTypes.shape({
    textColor: PropTypes.string.isRequired,
    borderColor: PropTypes.string.isRequired,
    deleteIconColor: PropTypes.string.isRequired,
    deleteIconBackgroundColor: PropTypes.string.isRequired
  })
};

export default UploadFile;
