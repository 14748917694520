import tracker from '../../config/analytics';

export default {

  setUserId: (userId) => {
    if (process.env.REACT_APP_ANALYTICS_DEBUG_MODE) {
      console.log(`ANALYTICS - setUserId: ${userId}`);
    } else {
      tracker.setUserId(userId);
    }
  },

  dispatchEvent: (event) => {
    const [key, value] = event;
    if (process.env.REACT_APP_ANALYTICS_DEBUG_MODE) {
      console.log(`ANALYTICS - dispatchEvent:\n\tEvent name: ${key}\n\tEvent data: ${JSON.stringify(value, null, 2)}`);
    } else {
      tracker.logEvent(key, value);
    }
  }

};

export { default as events } from './events';
