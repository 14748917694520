import { Box, Button, Container, Dialog, Typography, useMediaQuery } from '@mui/material';

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Footer } from '../components/PublicInvoice/Footer';
import Header from '../components/PublicInvoice/Header';

import { handleFacebookClick, handleInstagramClick } from '../util/socialMediaEvents';

import { create as createCompany } from '../slices/companies';

import { fetch as fetchCountries } from '../slices/countries';
import { fetch as fetchCurrencies } from '../slices/currencies';
import { fetch as fetchCounties } from '../slices/counties';
import CompanyForm from '../components/company/Form';
import Toast from '../components/Toast';
import { TOAST_TYPE } from '../constants';
import WarningPopup from '../components/WarningPopup';

const Welcome = () => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [isWarningPopUpOpen, setIsWarningPopUpOpen] = useState(false);

  const { onRequest, onRequestFailure, successMessage, errorMessage } = useSelector((state) => state.companies);
  const { countries } = useSelector((state) => state.countries);
  const { currencies } = useSelector((state) => state.currencies);
  const { counties } = useSelector((state) => state.counties);

  const [toastType, setToastType] = useState(undefined);
  const [message, setMessage] = useState(undefined);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handlePopUpOpen = () => {
    setIsPopUpOpen(true);
  };

  const handleWarningPopUpOpen = () => {
    setIsWarningPopUpOpen(true);
  };

  const handleNewClick = () => {
    setIsModalOpen(true);
    dispatch(fetchCountries());
    dispatch(fetchCurrencies());
    dispatch(fetchCounties());
  };

  const handleCreateCompany = (company) => {
    if (
      !company.county
      || !company.accountantTaxId
      || !company.countyTaxId
      || !company.fiscalName
    ) {
      handleWarningPopUpOpen();
    } else {
      handlePopUpOpen();
    }
    dispatch(createCompany(company));
    navigate('/minhas_empresas');
  };

  const handleSetMessage = (toastMessage) => {
    setMessage(toastMessage);
  };

  const handleSetToastType = (type) => {
    setToastType(type);
  };

  const toastUpdate = () => {
    if (!onRequest && !onRequestFailure) {
      handleSetMessage(successMessage);
      handleSetToastType(TOAST_TYPE.SUCCESS);
    } else if (onRequestFailure) {
      handleSetMessage(errorMessage);
      handleSetToastType(TOAST_TYPE.FAILURE);
    } else {
      handleSetToastType(TOAST_TYPE.LOADING);
    }
  };

  const handleWarningPopUpClose = () => {
    setIsWarningPopUpOpen(false);
    handlePopUpOpen();
  };

  const handlePopUpClose = () => {
    setIsPopUpOpen(false);
  };

  useEffect(() => {
    toastUpdate();
  }, [onRequest, onRequestFailure, successMessage, errorMessage]);

  const handleLogoClick = useCallback(
    () => {
      navigate('/');
    },
    [navigate]
  );

  return (
    <>
      <Header handleLogoClick={handleLogoClick} />
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignContent: 'center',
        minHeight: '100vh',
        flexDirection: 'column'
      }}
      >
        <Container
          maxWidth="xs"
          sx={{
            display: 'flex'
          }}
        >
          <Box
            sx={{
              mt: 16,
              mb: 5,
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignContent: 'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column'
              }}
            >

              <Typography
                color="primary"
                gutterBottom
                variant="h4"
                sx={{
                  fontSize: 32,
                  fontFamily: 'Blogger Sans'
                }}
              >
                Bem-Vindo ao Samba!
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
                sx={{
                  fontWeight: '600',
                  fontSize: 16
                }}
              >
                Samba é a sua parceira ideal para alavancar os seus negócios.
              </Typography>
            </Box>

            <Box
              sx={{
                mt: 5,
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >

              <Box
                sx={{
                  py: 8
                }}
              >
                <Box
                  alt="Stars"
                  component="img"
                  src="/static/homepage/welcome.svg"
                  style={{
                    width: isMobile ? 270 : 300
                  }}
                />
              </Box>

              <Typography
                color="#9E9E9E"
                variant="h5"
              >
                Comece por criar uma Entidade
              </Typography>
              <Button
                id="create-identity-welcome-page-button"
                sx={{ mt: 2 }}
                color="primary"
                variant="contained"
                onClick={handleNewClick}
                style={{
                  width: '100%'
                }}
              >
                Criar Entidade
              </Button>
            </Box>
          </Box>
        </Container>
        <Footer
          sx={{
            backgroundColor: 'background.grey'
          }}
          handleFacebookClick={handleFacebookClick}
          handleInstagramClick={handleInstagramClick}
        />
      </Box>

      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleModalClose}
        open={isModalOpen}
      >
        {isModalOpen && (
        <CompanyForm
          onComplete={handleModalClose}
          onCancel={handleModalClose}
          onSubmitForm={handleCreateCompany}
          countries={countries}
          currencies={currencies}
          counties={counties}
        />
        )}
      </Dialog>
      <Dialog
        maxWidth="xs"
        onClose={handleWarningPopUpClose}
        open={isWarningPopUpOpen}
      >
        {isWarningPopUpOpen && (
          <WarningPopup
            message="É necessário preencher os campos da informação fiscal para o modelo 106"
            buttonText="OK"
            onButtonPress={handleWarningPopUpClose}
          />
        )}
      </Dialog>
      <Toast
        isOpen={isPopUpOpen}
        toastType={toastType}
        message={message}
        onClose={handlePopUpClose}
      />

    </>
  );
};

export default Welcome;
