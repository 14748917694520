import * as React from 'react';

const ErrorCircleOutlined = (props) => (
  <svg
    width={81}
    height={80}
    viewBox="0 0 81 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2109_118597)">
      <g clipPath="url(#clip1_2109_118597)">
        <mask
          id="path-1-inside-1_2109_118597"
          fill="white"
        >
          <path d="M80.5 40C80.5 62.0914 62.5914 80 40.5 80C18.4086 80 0.5 62.0914 0.5 40C0.5 17.9086 18.4086 0 40.5 0C62.5914 0 80.5 17.9086 80.5 40ZM4.5 40C4.5 59.8823 20.6177 76 40.5 76C60.3823 76 76.5 59.8823 76.5 40C76.5 20.1177 60.3823 4 40.5 4C20.6177 4 4.5 20.1177 4.5 40Z" />
        </mask>
        <path
          d="M80.5 40C80.5 62.0914 62.5914 80 40.5 80C18.4086 80 0.5 62.0914 0.5 40C0.5 17.9086 18.4086 0 40.5 0C62.5914 0 80.5 17.9086 80.5 40ZM4.5 40C4.5 59.8823 20.6177 76 40.5 76C60.3823 76 76.5 59.8823 76.5 40C76.5 20.1177 60.3823 4 40.5 4C20.6177 4 4.5 20.1177 4.5 40Z"
          stroke="#F5F5F5"
          strokeWidth={12}
          mask="url(#path-1-inside-1_2109_118597)"
        />
        <path
          d="M40.5 2C40.5 0.895431 41.3961 -0.0052162 42.4993 0.049982C49.7088 0.410711 56.7002 2.71702 62.7228 6.74122C69.3008 11.1365 74.4277 17.3836 77.4552 24.6927C80.4827 32.0017 81.2748 40.0444 79.7314 47.8036C78.188 55.5629 74.3784 62.6902 68.7843 68.2843C63.1902 73.8784 56.0629 77.688 48.3036 79.2314C40.5444 80.7748 32.5017 79.9827 25.1927 76.9552C17.8836 73.9277 11.6365 68.8008 7.24122 62.2228C3.21702 56.2002 0.910711 49.2088 0.549982 41.9993C0.494784 40.8961 1.39543 40 2.5 40C3.60457 40 4.4942 40.8961 4.55553 41.999C4.91239 48.4169 6.98247 54.6358 10.5671 60.0005C14.5228 65.9207 20.1453 70.5349 26.7234 73.2597C33.3015 75.9844 40.5399 76.6973 47.5233 75.3083C54.5066 73.9192 60.9212 70.4905 65.9558 65.4558C70.9905 60.4212 74.4192 54.0066 75.8083 47.0233C77.1973 40.0399 76.4844 32.8015 73.7597 26.2234C71.0349 19.6453 66.4207 14.0228 60.5005 10.0671C55.1358 6.48247 48.9169 4.41239 42.499 4.05553C41.3961 3.9942 40.5 3.10457 40.5 2Z"
          fill="white"
        />
      </g>
      <path
        d="M42.2138 40.499L48.3661 33.1654C48.4692 33.0436 48.3825 32.8584 48.2231 32.8584H46.3528C46.2427 32.8584 46.1372 32.9076 46.0645 32.992L40.9903 39.0412L35.9161 32.992C35.8458 32.9076 35.7403 32.8584 35.6278 32.8584H33.7575C33.5981 32.8584 33.5114 33.0436 33.6145 33.1654L39.7669 40.499L33.6145 47.8326C33.5914 47.8598 33.5766 47.893 33.5718 47.9283C33.5671 47.9637 33.5725 47.9997 33.5876 48.032C33.6026 48.0643 33.6266 48.0916 33.6568 48.1107C33.6869 48.1298 33.7219 48.1398 33.7575 48.1396H35.6278C35.738 48.1396 35.8435 48.0904 35.9161 48.0061L40.9903 41.9568L46.0645 48.0061C46.1349 48.0904 46.2403 48.1396 46.3528 48.1396H48.2231C48.3825 48.1396 48.4692 47.9545 48.3661 47.8326L42.2138 40.499Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2109_118597">
        <rect
          width={80}
          height={80}
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
      <clipPath id="clip1_2109_118597">
        <rect
          width={80}
          height={80}
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);
export default ErrorCircleOutlined;
