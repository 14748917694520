import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Dialog,
  TextField,
  Typography,
  createFilterOptions
} from '@mui/material';

import { useState, useEffect, useRef } from 'react';

import InvoiceItemTable from '../invoiceItem/InvoiceItemTable';
import InvoiceItemForm from '../invoiceItem/InvoiceItemForm';
import InvoiceItemSumatory from '../invoiceItem/InvoiceItemSumatory';
import DeletePopUp from '../DeletePopUp';
import { CREATE_NEW, FORM } from '../../constants';
import CustomDatePicker from '../CustomDatePicker';

const filter = createFilterOptions();

const initialValues = {
  documentType: null,
  serie: null,
  number: null,
  date: new Date(),
  paymentPeriod: null,
  provider: null,
  paymentMode: null,
  commentary: null,
  status: null
};

const purchaseInvoiceStatus = [
  {
    name: 'Liquidado',
    status: 'settled'
  },
  {
    name: 'Não Liquidado',
    status: 'not settled'
  }
];

const Form = (props) => {
  const {
    onCancel,
    onSubmitForm,
    getPaymentPeriodRange,
    useForm,
    providers,
    documentTypes,
    paymentPeriods,
    paymentModes,
    units,
    taxes,
    purchaseInvoice,
    itens,
    currentCompany
  } = props;

  const [paymentPeriodInitialLength, setPaymentPeriodInitialLength] = useState(null);
  const [paymentPeriodInputValue, setPaymentPeriodInputValue] = useState(null);
  const [paymentModeInitialLength, setPaymentModeInitialLength] = useState(null);
  const [paymentModeInputValue, setPaymentModeInputValue] = useState(null);
  const [providerInitialLength, setProviderInitialLength] = useState(null);
  const [providerInputValue, setProviderInputValue] = useState(null);

  const [isInvoiceItemPopUpOpen, setIsInvoiceItemPopUpOpen] = useState(false);
  const [isDeletePopUpOpen, setIsDeletePopUpOpen] = useState(false);
  const [purchaseInvoiceItems, setPurchaseInvoiceItems] = useState([]);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState();
  const [addAnotherInvoiceItem, setAddAnotherInvoiceItem] = useState(false);
  const [invoiceItemError, setInvoiceItemError] = useState(false);
  const [taxesMerged, setTaxesMerged] = useState(null);

  const formikRef = useRef();

  const purchaseInvoiceValues = {
    documentType: purchaseInvoice?.documentType?.id,
    serie: purchaseInvoice?.serieNumber,
    number: purchaseInvoice?.number,
    date: new Date(purchaseInvoice?.date),
    paymentPeriod: purchaseInvoice?.paymentPeriod?.id,
    provider: purchaseInvoice?.provider?.id,
    paymentMode: purchaseInvoice?.paymentMode?.id,
    commentary: purchaseInvoice?.commentary,
    status: purchaseInvoice?.status
  };

  useEffect(() => {
    if (!paymentPeriodInitialLength && paymentPeriods) {
      setPaymentPeriodInitialLength(paymentPeriods.length);
    } else if (paymentPeriods?.length > paymentPeriodInitialLength) {
      setPaymentPeriodInputValue(paymentPeriods[(paymentPeriods.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentPeriod = paymentPeriods[(paymentPeriods.length) - 1].id;
      }
    }
  }, [paymentPeriods]);

  useEffect(() => {
    if (!paymentModeInitialLength && paymentModes) {
      setPaymentModeInitialLength(paymentModes.length);
    } else if (paymentModes?.length > paymentModeInitialLength) {
      setPaymentModeInputValue(paymentModes[(paymentModes.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.paymentMode = paymentModes[(paymentModes.length) - 1].id;
      }
    }
  }, [paymentModes]);

  useEffect(() => {
    if (!providerInitialLength && providers) {
      setProviderInitialLength(providers.length);
    } else if (providers?.length > providerInitialLength) {
      setProviderInputValue(providers[(providers.length) - 1].name);

      if (formikRef.current) {
        formikRef.current.values.provider = providers[(providers.length) - 1].id;
      }
    }
  }, [providers]);

  useEffect(() => {
    if (purchaseInvoice) {
      setPurchaseInvoiceItems(purchaseInvoice.purchaseInvoiceItems.map((item) => ({
        id: item.id,
        discount: Number(item.discount),
        item: item.item.id,
        itemCode: item.item.code,
        tax: item.tax.id,
        taxValue: Number(item.tax.value),
        taxName: item.tax.name,
        price: Number(item.price),
        quantity: Number(item.quantity),
        unit: item.unit,
        total: Number(item.itemTotal)
      })));
    }
  }, []);

  const {
    openForm
  } = useForm();

  const handleOpenInvoiceItemForm = () => {
    setIsInvoiceItemPopUpOpen(true);
  };

  const handleCloseInvoiceItemForm = () => {
    setIsInvoiceItemPopUpOpen(false);
    setSelectedInvoiceItem();
  };

  const handleDeletePopUpOpen = () => {
    setIsDeletePopUpOpen(true);
  };

  const handleDeletePopUpClose = () => {
    setIsDeletePopUpOpen(false);
  };

  const handleAddInvoiceItem = (invoiceItem) => {
    const newPurchaseInvoiceItem = {
      // this is a temporary id, since we won't send this id it to api
      id: Math.random(),
      discount: Number(invoiceItem.discount),
      item: invoiceItem.item,
      itemCode: invoiceItem.itemCode,
      tax: invoiceItem.tax,
      taxValue: Number(invoiceItem.taxValue),
      taxName: invoiceItem.taxName,
      price: Number(invoiceItem.price),
      quantity: Number(invoiceItem.quantity),
      unit: invoiceItem.unit,
      total: Number(invoiceItem.total)
    };
    setPurchaseInvoiceItems([...purchaseInvoiceItems, newPurchaseInvoiceItem]);
    setInvoiceItemError(false);
  };

  const handleUpdateInvoiceItem = (updatedInvoiceItem) => {
    const editedInvoiceItemList = purchaseInvoiceItems.map(((invoiceItem) => {
      if (updatedInvoiceItem.id === invoiceItem.id) {
        return updatedInvoiceItem;
      }
      return invoiceItem;
    }));

    setPurchaseInvoiceItems(editedInvoiceItemList);
  };

  const handleDeleteInvoiceItem = () => {
    const filtredInvoiceItems = purchaseInvoiceItems.filter((invoiceItem) => invoiceItem.id !== selectedInvoiceItem.id);
    setPurchaseInvoiceItems(filtredInvoiceItems);
    setSelectedInvoiceItem();
    handleDeletePopUpClose();

    if (filtredInvoiceItems.length === 0) {
      setInvoiceItemError(true);
    }
  };

  const handleSelectInvoiceItem = (invoiceItem) => {
    setSelectedInvoiceItem(invoiceItem);
  };

  const handleToggleAddAnotherInvoiceItem = () => {
    setAddAnotherInvoiceItem(!addAnotherInvoiceItem);
  };

  const handleFormatTaxes = () => {
    const reducer = (acc, currentValue) => {
      acc[currentValue.id] = currentValue;
      return acc;
    };

    const hashItens = itens ? itens
      .map((item) => item.tax)
      .reduce(reducer, {}) : {};

    const hashTaxes = taxes ? taxes.reduce(reducer, hashItens) : {};

    setTaxesMerged(Object.values(hashTaxes));
  };

  useEffect(() => {
    handleFormatTaxes();
  }, [taxes, itens]);

  const handleDateChange = (newValue, setFieldValue) => {
    setFieldValue('date', newValue.toDate());
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={purchaseInvoice ? purchaseInvoiceValues : initialValues}
      validationSchema={Yup
        .object()
        .shape({
          documentType: Yup
            .string()
            .typeError('Obrigatório')
            .trim()
            .required('Obrigatório'),
          serie: Yup
            .string()
            .trim()
            .nullable(),
          date: Yup
            .date()
            .required('Obrigatório'),
          paymentPeriod: Yup
            .string()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          paymentMode: Yup
            .string()
            .typeError('Obrigatório'),
          provider: Yup
            .string()
            .typeError('Obrigatório')
            .trim()
            .required('Obrigatório'),
          status: Yup
            .string()
            .typeError('Obrigatório')
            .required('Obrigatório'),
          commentary: Yup
            .string()
            .max(200)
            .nullable()
            .trim(),
          number: Yup
            .number()
            .nullable()
        })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          values.purchaseInvoiceItems = purchaseInvoiceItems;

          if (!values.purchaseInvoiceItems.length) {
            setInvoiceItemError(true);
            return;
          }

          onSubmitForm(values);

          setStatus({ success: true });
          setSubmitting(false);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
        setFieldValue
      }) => (
        <form
          onSubmit={handleSubmit}
        >
          <Container>
            <Box>
              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >
                <Autocomplete
                  getOptionLabel={(option) => `${option.code} - ${option.name}`}
                  options={documentTypes || []}
                  defaultValue={purchaseInvoice ? ({
                    id: purchaseInvoice.documentType?.id,
                    name: purchaseInvoice.documentType?.name,
                    code: purchaseInvoice.documentType?.code
                  }) : null}
                  disableClearable
                  onChange={(e, value) => {
                    setFieldValue('documentType', value?.id || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecionar Tipo de Documento"
                      autoFocus
                      fullWidth
                      error={Boolean(touched.documentType && errors.documentType)}
                      helperText={touched.documentType && errors.documentType}
                      label="Tipo de Documento*"
                      name="documentType"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1,
                mt: 2
              }}
              >
                <TextField
                  placeholder="Introduzir Série"
                  fullWidth
                  error={Boolean(touched.serie && errors.serie)}
                  helperText={touched.serie && errors.serie}
                  label="Série"
                  name="serie"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.serie || ''}
                  variant="outlined"
                />

                <TextField
                  error={Boolean(touched.number && errors.number)}
                  fullWidth
                  helperText={touched.number && errors.number}
                  label="Número"
                  name="number"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.number}
                  variant="outlined"
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <CustomDatePicker
                  label="Data*"
                  onChange={(newValue) => handleDateChange(newValue, setFieldValue)}
                />
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  options={paymentPeriods || []}
                  defaultValue={purchaseInvoice ? ({
                    id: purchaseInvoice.paymentPeriod?.id,
                    name: purchaseInvoice.paymentPeriod?.name
                  }) : null}
                  disableClearable
                  inputValue={paymentPeriodInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentPeriodInputValue(value.code);
                    setFieldValue('paymentPeriod', value?.id || '');

                    if (value.name === CREATE_NEW) {
                      openForm(FORM.PAYMENT_PERIOD);
                      value.name = paymentPeriodInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentPeriodInputValue !== '') || paymentPeriodInputValue.length === 1) {
                      setPaymentPeriodInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: CREATE_NEW
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecionar"
                      fullWidth
                      error={Boolean(touched.paymentPeriod && errors.paymentPeriod)}
                      helperText={touched.paymentPeriod && errors.paymentPeriod}
                      label="Período de Pagamento*"
                      name="paymentPeriod"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: 1
              }}
              >
                <TextField
                  fullWidth
                  disabled
                  label="Limite de Pagamento*"
                  name="range"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={getPaymentPeriodRange(values)}
                  variant="outlined"
                />
                <Autocomplete
                  getOptionLabel={(option) => option.code}
                  options={paymentModes || []}
                  defaultValue={purchaseInvoice ? ({
                    id: purchaseInvoice.paymentMode?.id,
                    code: purchaseInvoice.paymentMode?.code
                  }) : null}
                  disableClearable
                  inputValue={paymentModeInputValue || ''}
                  onChange={(event, value) => {
                    setPaymentModeInputValue(value.code);
                    setFieldValue('paymentMode', value?.id || '');

                    if (value.code === CREATE_NEW) {
                      openForm(FORM.PAYMENT_MODE);
                      value.code = paymentModeInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && paymentModeInputValue !== '') || paymentModeInputValue.length === 1) {
                      setPaymentModeInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      code: CREATE_NEW
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecionar"
                      fullWidth
                      error={Boolean(touched.paymentMode && errors.paymentMode)}
                      helperText={touched.paymentMode && errors.paymentMode}
                      label="Modo de Pagamento*"
                      name="paymentMode"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>

              <Box sx={{
                mt: 2,
                display: 'grid',
                gridTemplateColumns: '1fr',
                gap: 1
              }}
              >

                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  options={providers || []}
                  defaultValue={purchaseInvoice ? ({
                    id: purchaseInvoice.provider.id,
                    name: purchaseInvoice.provider.name
                  }) : null}
                  disableClearable
                  inputValue={providerInputValue || ''}
                  onChange={(event, value) => {
                    setProviderInputValue(value.name);
                    setFieldValue('provider', value?.id || '');

                    if (value.name === CREATE_NEW) {
                      openForm(FORM.PROVIDER);
                      value.name = providerInputValue || '';
                    }
                  }}
                  onInputChange={(event, newInputValue) => {
                    if ((newInputValue !== '' && providerInputValue !== '') || providerInputValue.length === 1) {
                      setProviderInputValue(newInputValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    filtered.push({
                      inputValue: params.inputValue,
                      name: CREATE_NEW
                    });

                    return filtered;
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecionar Fornecedor"
                      fullWidth
                      error={Boolean(touched.provider && errors.provider)}
                      helperText={touched.provider && errors.provider}
                      label="Fornecedor*"
                      name="provider"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
              <Box sx={{
                mt: 2
              }}
              >
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  options={purchaseInvoiceStatus}
                  defaultValue={purchaseInvoice ? ({
                    status: purchaseInvoice.status,
                    name: purchaseInvoice.status === 'settled' ? 'Liquidado' : 'Não Liquidado'
                  }) : null}
                  disableClearable
                  onChange={(e, value) => {
                    setFieldValue('status', value.status || '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      placeholder="Selecione Estado"
                      fullWidth
                      error={Boolean(touched.status && errors.status)}
                      helperText={touched.status && errors.status}
                      label="Estado*"
                      name="status"
                      variant="outlined"
                      {...params}
                    />
                  )}
                />
              </Box>
            </Box>

            <Box sx={{ py: 4 }}>
              <Typography
                color="textSecondary"
                variant="overline"
                sx={{
                  textTransform: 'unset'
                }}
              >
                Lista de Itens na Fatura
              </Typography>
              <InvoiceItemTable
                currentCompany={currentCompany}
                invoiceItems={purchaseInvoiceItems}
                onOpenForm={handleOpenInvoiceItemForm}
                selectInvoiceItem={handleSelectInvoiceItem}
                onDelete={handleDeletePopUpOpen}
              />
              <Typography
                color="error"
                variant="overline"
                hidden={!invoiceItemError}
              >
                Obrigatório
              </Typography>
              <Dialog
                fullWidth
                maxWidth="sm"
                onClose={handleCloseInvoiceItemForm}
                open={isInvoiceItemPopUpOpen}
              >
                {isInvoiceItemPopUpOpen && (
                <InvoiceItemForm
                  currentCompany={currentCompany}
                  onComplete={handleCloseInvoiceItemForm}
                  onCancel={handleCloseInvoiceItemForm}
                  shouldUseCost
                  onAdd={handleAddInvoiceItem}
                  onEdit={handleUpdateInvoiceItem}
                  onAddAnother={handleOpenInvoiceItemForm}
                  toggleAddAnother={handleToggleAddAnotherInvoiceItem}
                  isAddAnother={addAnotherInvoiceItem}
                  isAdd={!selectedInvoiceItem}
                  selectedInvoiceItem={selectedInvoiceItem}
                  openForm={openForm}
                  units={units}
                  taxes={taxesMerged}
                  items={itens}
                />
                )}
              </Dialog>
            </Box>
            <Box sx={{
              py: 4,
              display: 'grid',
              gridTemplateColumns: '1fr',
              gap: 4
            }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  p: 1
                }}
              >
                <Typography
                  color="textSecondary"
                  variant="overline"
                  sx={{
                    textTransform: 'unset'
                  }}
                >
                  Comentário(s)
                </Typography>
                <TextField
                  error={Boolean(touched.commentary && errors.commentary)}
                  fullWidth
                  helperText={touched.commentary && errors.commentary}
                  name="commentary"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.commentary}
                  variant="standard"
                  multiline
                />
              </Box>
              <InvoiceItemSumatory
                currentCompany={currentCompany}
                invoiceItems={purchaseInvoiceItems}
              />
            </Box>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                p: 2
              }}
            >
              <Box sx={{ flexGrow: 1 }} />
              <Button
                color="primary"
                onClick={onCancel}
                variant="text"
              >
                Voltar
              </Button>
              <Button
                color="primary"
                disabled={isSubmitting}
                sx={{ ml: 1 }}
                type="submit"
                variant="contained"
              >
                Guardar
              </Button>
            </Box>
            <Dialog
              maxWidth="xs"
              onClose={handleDeletePopUpClose}
              open={isDeletePopUpOpen}
            >
              {isDeletePopUpOpen && (
              <DeletePopUp
                onCancel={handleDeletePopUpClose}
                onDelete={handleDeleteInvoiceItem}
              />
              )}
            </Dialog>
          </Container>
        </form>
      )}
    </Formik>
  );
};

Form.propTypes = {
  onSubmitForm: PropTypes.func.isRequired,
  getPaymentPeriodRange: PropTypes.func.isRequired,
  useForm: PropTypes.func.isRequired,
  purchaseInvoice: PropTypes.shape({
    id: PropTypes.string,
    supplierName: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    paymentLimit: PropTypes.string,
    serieCode: PropTypes.string,
    discount: PropTypes.number,
    number: PropTypes.number,
    status: PropTypes.string,
    serieNumber: PropTypes.string,
    commentary: PropTypes.string,
    purchaseInvoiceItems: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    }),
    documentType: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string
    }),
    paymentPeriod: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string,
      numberOfDay: PropTypes.number
    }),
    paymentMode: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string,
      name: PropTypes.string
    }),
    provider: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })
  }).isRequired,
  series: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    lastDocumentNumber: PropTypes.string,
    range: PropTypes.string,
    fiscalYearName: PropTypes.string,
    fiscalYearDate: PropTypes.string
  }).isRequired,
  providers: PropTypes.arrayOf({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  documentTypes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  paymentPeriods: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    numberOfDay: PropTypes.string
  }).isRequired,
  paymentModes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string
  }).isRequired,
  units: PropTypes.arrayOf({
    code: PropTypes.string,
    description: PropTypes.string
  }).isRequired,
  taxes: PropTypes.arrayOf({
    id: PropTypes.string,
    code: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.number
  }).isRequired,
  itens: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onCancel: PropTypes.func.isRequired
};

export default Form;
