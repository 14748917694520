import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell
} from '@mui/material';

import Scrollbar from '../Scrollbar';

import { formattedDate } from '../../util/formattedDate';

const TransactionsTable = (props) => {
  const { id, transactions, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box id={id}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Identificaçāo da Transaçāo
                </TableCell>
                <TableCell>
                  Data
                </TableCell>
                <TableCell align="right">
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                transactions.map((transaction) => (
                  <TableRow>
                    <TableCell>
                      {transaction.transaction_id}
                    </TableCell>
                    <TableCell>
                      {formattedDate(transaction.date)}
                    </TableCell>
                    <TableCell align="right">
                      {transaction.amount}
                      {' '}
                      CVE
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

TransactionsTable.propTypes = {
  id: PropTypes.string.isRequired,
  transactions: PropTypes.arrayOf({
    id: PropTypes.string.isRequired,
    amount: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    transaction_id: PropTypes.string.isRequired
  }).isRequired
};

export default TransactionsTable;
