import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import { CAPE_VERDE_TIME_ZONE, DOCUMENTS, PAYMENT_SERVICES } from '../constants';
import axios from '../lib/axios';
import errorMessageHandler from '../util/errorMessageHandler';
import removeChar from '../util/removeChar';
import analytics, { events } from '../services/analytics';

const initialState = {
  invoices: undefined,
  isLoading: false,
  loadingError: false,
  onRequest: undefined,
  onRequestFailure: undefined,
  successMessage: '',
  errorMessage: '',
  invoicePdf: null,
  paymentResponse: null,
  isPayable: null
};

const slice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    _get: (state) => {
      state.isLoading = true;
    },
    _getSuccess: (state, { payload }) => {
      state.invoices = payload;
      state.isLoading = false;
      state.error = false;
    },
    _getFailure: (state) => {
      state.invoices = undefined;
      state.isLoading = false;
      state.error = true;
    },
    _getPdfSuccess: (state) => {
      state.isLoading = false;
      state.error = false;
    },
    _getPdfFailure: (state) => {
      state.isLoading = false;
      state.loadingError = true;
      state.error = true;
    },
    _createOrUpdate: (state) => {
      state.onRequest = true;
    },
    _createOrUpdatingSuccess: (state, { payload }) => {
      if (state.invoices === undefined) {
        state.invoices = [];
      }
      state.invoices.push(payload);
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _createOrUpdatingFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _void: (state) => {
      state.onRequest = true;
    },
    _voidingSuccess: (state, { payload }) => {
      state.invoices = state.invoices.map((invoice) => {
        if (invoice.id === payload.id) {
          invoice.isVoided = true;
        }

        return invoice;
      });
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _voidFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _sendInvoiceEmail: (state) => {
      state.onRequest = true;
    },
    _sendInvoiceEmailSuccess: (state) => {
      state.onRequest = false;
      state.onRequestFailure = false;
    },
    _sendInvoiceEmailFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _setSuccessMessage: (state, { payload }) => {
      state.successMessage = payload;
    },
    _setInvoicePdf: (state, { payload }) => {
      state.invoicePdf = payload;
      state.isLoading = false;
      state.loadingError = false;
    },
    _payInvoice: (state) => {
      state.onRequest = true;
    },
    _payInvoiceSuccess: (state, { payload }) => {
      state.onRequest = false;
      state.paymentResponse = payload;
    },
    _payInvoiceFailure: (state, { payload }) => {
      state.onRequest = false;
      state.onRequestFailure = true;
      state.errorMessage = payload;
    },
    _onIsPayableRequest: (state) => {
      state.onRequest = true;
    },
    _isPayableSuccess: (state, { payload }) => {
      state.onRequest = false;
      state.isPayable = payload?.is_payable;
      state.isLoading = false;
      state.error = false;
    },
    _isPayableFailure: (state) => {
      state.onRequest = false;
      state.isLoading = false;
      state.error = true;
    }
  }
});

const {
  _get,
  _getSuccess,
  _getFailure,
  _getPdfSuccess,
  _getPdfFailure,
  _createOrUpdate,
  _createOrUpdatingSuccess,
  _createOrUpdatingFailure,
  _void,
  _voidFailure,
  _voidingSuccess,
  _setSuccessMessage,
  _sendInvoiceEmail,
  _sendInvoiceEmailFailure,
  _sendInvoiceEmailSuccess,
  _setInvoicePdf,
  _payInvoice,
  _payInvoiceSuccess,
  _payInvoiceFailure,
  _onIsPayableRequest,
  _isPayableSuccess,
  _isPayableFailure
} = slice.actions;

export default slice.reducer;

export const fetch = createAsyncThunk(
  'invoice/fetch',
  async (_, { dispatch, getState }) => {
    dispatch(_get());
    try {
      const companyId = getState().companies.currentCompany.id;

      const { data } = await axios.get(`/companies/${companyId}/invoices`, {
        params: {
          document_type: DOCUMENTS.invoice.code
        }
      });
      const invoices = data.map((invoice) => ({
        id: invoice.id,
        documentType: {
          id: invoice.document_type.id,
          code: invoice.document_type.code,
          name: invoice.document_type.name
        },
        clientName: invoice.customer.name,
        clientId: invoice.customer.id,
        serieCode: invoice.serial_number.code,
        paymentPeriod: {
          id: invoice.payment_period.id,
          code: invoice.payment_period.code,
          name: invoice.payment_period.name,
          numberOfDay: invoice.payment_period.number_of_day
        },
        paymentMode: {
          id: invoice.payment_mode.id,
          code: invoice.payment_mode.code,
          name: invoice.payment_mode.name
        },
        efaturaData: {
          id: invoice.efatura_data.id,
          status: invoice.efatura_data.status,
          errorCode: invoice.efatura_data.error_code,
          errorMessage: invoice.efatura_data.error_message
        },
        date: invoice.date,
        serie: invoice.serial_number.id,
        serieName: invoice.serial_number.name,
        commentary: invoice.commentary,
        valueWithoutTax: invoice.value_without_tax,
        totalTax: invoice.value_of_tax,
        valueWithTax: invoice.value_with_tax,
        discount: invoice.discount,
        totalGeneral: invoice.total_general,
        number: invoice.invoice_number,
        isVoided: invoice.is_voided,
        receiptsValue: invoice.receipts_values,
        paymentStatus: invoice.payment_status,
        invoiceItems: invoice.items.map((invoiceItem) => ({
          id: invoiceItem.id,
          item: invoiceItem.item,
          quantity: invoiceItem.quantity,
          unit: invoiceItem.unit_code,
          price: invoiceItem.price,
          tax: invoiceItem.tax,
          discount: invoiceItem.discount,
          itemTotal: invoiceItem.item_total
        }))
      }));
      dispatch(_getSuccess(invoices));
    } catch (error) {
      dispatch(_getFailure());
    }
  }
);

export const create = createAsyncThunk(
  'invoice/create',
  async (data, { dispatch, getState }) => {
    dispatch(_createOrUpdate());
    try {
      const companyId = getState().companies.currentCompany.id;

      const invoiceItems = data.invoiceItems.map((invoiceItem) => ({
        item_id: invoiceItem.item,
        quantity: Number(invoiceItem.quantity),
        unit_code: invoiceItem.unit,
        price: Number(invoiceItem.price),
        tax_id: invoiceItem.tax,
        discount: Number(invoiceItem.discount),
        item_description: invoiceItem.description
      }));

      const invoice = {
        document_type_id: data.documentType,
        date: format(data.date, `yyyy-MM-dd ${CAPE_VERDE_TIME_ZONE}`),
        customer_id: data.client,
        payment_period_id: data.paymentPeriod,
        payment_mode_id: data.paymentMode,
        serial_number_id: data.serie,
        commentary: data.commentary,
        invoice_items: invoiceItems
      };

      const response = await axios.post(`/companies/${companyId}/invoices`, invoice);

      const newInvoice = {
        id: response.data.id,
        documentType: {
          id: response.data.document_type.id,
          code: response.data.document_type.code,
          name: response.data.document_type.name
        },
        clientName: response.data.customer.name,
        paymentPeriod: {
          id: response.data.payment_period.id,
          code: response.data.payment_period.code,
          name: response.data.payment_period.name,
          numberOfDay: response.data.payment_period.number_of_day
        },
        paymentMode: {
          id: response.data.payment_mode.id,
          code: response.data.payment_mode.code,
          name: response.data.payment_mode.name
        },
        date: response.data.date,
        serie: response.data.serial_number.id,
        serieCode: response.data.serial_number.code,
        serieName: response.data.serial_number.name,
        commentary: response.data.commentary,
        valueWithoutTax: response.data.value_without_tax,
        totalIva: response.data.value_of_tax,
        valueWithTax: response.data.value_with_tax,
        number: response.data.invoice_number,
        discount: response.data.discount,
        totalGeneral: response.data.total_general,
        isVoided: invoice.is_voided,
        paymentStatus: response.data.payment_status,
        invoiceItems: response.data.items.map((invoiceItem) => ({
          id: invoiceItem.id,
          item: invoiceItem.item,
          quantity: invoiceItem.quantity,
          unit: invoiceItem.unit_code,
          price: invoiceItem.price,
          tax: invoiceItem.tax,
          discount: invoiceItem.discount,
          itemTotal: invoiceItem.item_total
        }))
      };

      analytics.dispatchEvent(events.invoiceCreated());

      dispatch(_createOrUpdatingSuccess(newInvoice));
      dispatch(_setSuccessMessage('Guardado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const voidInvoice = createAsyncThunk(
  'invoice/void',
  async (data, { dispatch, getState }) => {
    dispatch(_void());

    try {
      const companyId = getState().companies.currentCompany.id;
      const { invoice, documentToVoid } = data;

      const document = documentToVoid.documentCode === 'NDV' && documentToVoid.issueReasonCode === 'OTR' ? {
        document_code: documentToVoid.documentCode,
        date: documentToVoid.date,
        issue_reason_id: documentToVoid.issueReason,
        issue_reason_motive: documentToVoid.otherIssueReason,
        payment_period_id: documentToVoid.paymentPeriod,
        payment_mode_id: documentToVoid.paymentMode,
        commentary: documentToVoid.commentary
      } : {
        document_code: documentToVoid.documentCode,
        date: documentToVoid.date,
        issue_reason_id: documentToVoid.issueReason,
        payment_period_id: documentToVoid.paymentPeriod,
        payment_mode_id: documentToVoid.paymentMode,
        commentary: documentToVoid.commentary
      };

      await axios.post(`/companies/${companyId}/invoices/${invoice.id}/void`, document);

      dispatch(_voidingSuccess(invoice));
      dispatch(_setSuccessMessage('Anulado com sucesso!'));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_voidFailure(errorMessage));
    }
  }
);

export const sendInvoiceEmail = createAsyncThunk(
  'invoice/send_email',
  async (data, { dispatch, getState }) => {
    dispatch(_sendInvoiceEmail());

    try {
      const companyId = getState().companies.currentCompany.id;

      await axios.post(`/companies/${companyId}/invoices/${data.id}/send_email`, data.emailList);

      analytics.dispatchEvent(events.invoiceEmailed());

      dispatch(_sendInvoiceEmailSuccess(data));
      dispatch(_setSuccessMessage('Email enviado!'));
    } catch (error) {
      const errorMessage = 'Erro ao enviar o Email!';
      dispatch(_sendInvoiceEmailFailure(errorMessage));
    }
  }
);

export const print = createAsyncThunk(
  'invoice/print',
  async (data, { dispatch, getState }) => {
    dispatch(_get());

    try {
      const companyId = getState().companies.currentCompany.id;

      const response = await axios.get(`/companies/${companyId}/invoices/${data.id}`, {
        responseType: 'blob',
        params: {
          pdf: true
        }
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const clientNameWithoutDots = removeChar(data.clientName, '.');

      const fileURL = URL.createObjectURL(file);
      const tempLink = document.createElement('a');
      tempLink.href = fileURL;
      tempLink.setAttribute('download', `Samba - ${clientNameWithoutDots} ${data.number}`);
      tempLink.click();

      analytics.dispatchEvent(events.invoiceDownloaded());

      dispatch(_getPdfSuccess());
      dispatch(_setSuccessMessage('Sucesso!'));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const fetchInvoicePdfById = createAsyncThunk(
  'invoice/invoicePDF',
  async (id, { dispatch }) => {
    dispatch(_get());
    try {
      const response = await axios.get(`/invoices/${id}`, {
        responseType: 'blob'
      });

      const file = new Blob(
        [response.data],
        { type: 'application/pdf' }
      );

      const fileURL = URL.createObjectURL(file);

      dispatch(_setInvoicePdf(fileURL));
    } catch (error) {
      dispatch(_getPdfFailure('Erro na criação do PDF'));
    }
  }
);

export const updateState = createAsyncThunk(
  'invoice/updateState',
  async (data, { dispatch, getState }) => {
    try {
      const { invoices } = getState().invoices;

      const updatedInvoices = invoices.map((invoice) => {
        const invoiceUpdated = data.find((i) => i.id === invoice.id);

        if (invoiceUpdated) {
          return { ...invoice, receiptsValue: invoice.receiptsValue + invoiceUpdated.value };
        }

        return invoice;
      });

      dispatch(_getSuccess(updatedInvoices));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_createOrUpdatingFailure(errorMessage));
    }
  }
);

export const pay = createAsyncThunk(
  'invoice/payInvoice',
  async (paymentInfo, { dispatch }) => {
    dispatch(_payInvoice());
    try {
      const { invoiceId, email, paymentService } = paymentInfo;
      const dataTosend = {
        email,
        payment_service_id: paymentService
      };

      const { data } = await axios.post(`/payments/invoices/${invoiceId}`, dataTosend);

      let response;
      switch (paymentService) {
        case PAYMENT_SERVICES.sisp:
          response = {
            url: data
          };
          break;
        default:
          response = {
            token: data?.token
          };
      }

      dispatch(_payInvoiceSuccess(response));
    } catch (error) {
      const errorMessage = errorMessageHandler(error);
      dispatch(_payInvoiceFailure(errorMessage));
    }
  }
);

export const fetchIsPayable = createAsyncThunk(
  'invoice/payable',
  async (id, { dispatch }) => {
    dispatch(_onIsPayableRequest());
    try {
      const { data } = await axios.get(`/invoices/${id}/payable`);
      dispatch(_isPayableSuccess(data));
    } catch (error) {
      dispatch(_isPayableFailure());
    }
  }
);
