import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Divider,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Scrollbar from '../Scrollbar';

import InvoiceItemSumatory from '../invoiceItem/InvoiceItemSumatory';
import { formatCurrency } from '../../util/formatCurrency';
import { emailSchema } from '../../util/schema/emailValidation';
import { documentReferenceLabel } from '../../util/documentReferenceLabel';
import renderEfaturaDocumentErrorMessage from '../../util/renderEfaturaDocumentErrorMessage';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';

const ViewDetails = (props) => {
  const {
    title,
    returnNote,
    documents,
    onCancel,
    handleSendEmail,
    handlePrintButtonClick,
    issueReasonTypes,
    currentCompany,
    ...other
  } = props;

  const [openEmailDialog, setOpenEmailDialog] = useState(false);
  const [email, setEmail] = useState(undefined);
  const [emailError, setEmailError] = useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);
  const [documentReferenced, setDocumentReferenced] = useState(null);

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  useState(() => {
    if (returnNote?.documentsReferenced.length) {
      const { name, number, date, totalGeneral } = documents?.find((document) => document.id === returnNote?.documentsReferenced[0]?.document?.id);
      const reference = documentReferenceLabel(name, number, date, totalGeneral);
      setDocumentReferenced(reference);
    }
  }, [returnNote]);

  const handleClickOpen = () => {
    setOpenEmailDialog(true);
  };

  const handleSendClick = () => {
    handleClickOpen();
  };

  const validateEmail = async (data) => {
    /* validate email schema */
    try {
      await emailSchema.validate(data ? data.trim() : data, {
        abortEarly: false
      });
      setEmailError(undefined);
      return true;
    } catch (error) {
      setEmailError(error.errors[0]);
    }
    return false;
  };

  const handlePopupClose = () => {
    setOpenEmailDialog(false);
    setEmail(undefined);
  };

  const handleSendEmailButtonClick = async () => {
    const validEmail = await validateEmail(email);

    if (validEmail) {
      handleSendEmail(email);
      handleClickOpen();
      handlePopupClose();
    }
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
    validateEmail(email);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Card {...other}>
      <Box
        id="return-note-view-details"
        sx={{ p: 3 }}
      >
        <Typography
          align="center"
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ p: 3 }}>
        {renderEfaturaDocumentErrorMessage(returnNote)}
        <Card>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Série
                </TableCell>
                <TableCell>
                  {returnNote.serieCode}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Número
                </TableCell>
                <TableCell>
                  {String(returnNote.number).padStart(4, '0')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Data
                </TableCell>
                <TableCell>
                  {returnNote.dateFormatted}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Período de Pagamento
                </TableCell>
                <TableCell>
                  {returnNote.paymentPeriod.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Limite de Pagamento
                </TableCell>
                <TableCell>
                  {returnNote.paymentLimit}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Modo de Pagamento
                </TableCell>
                <TableCell>
                  {returnNote.paymentMode.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Cliente
                </TableCell>
                <TableCell>
                  {returnNote.clientName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Motivo de Retificação
                </TableCell>
                <TableCell>
                  {
                    returnNote.issueReason?.code !== issueReasonTypes.OTR.code
                      ? returnNote.issueReason?.description
                      : returnNote.otherIssueReason
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Documento Referente
                </TableCell>
                <TableCell>
                  {documentReferenced}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Card>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Lista de itens na Nota de Devolução
        </Typography>
        <Box sx={{ pt: 3 }}>
          <Card>
            <Scrollbar>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      Artigo
                    </TableCell>
                    <TableCell>
                      Descrição
                    </TableCell>
                    <TableCell>
                      Unidade
                    </TableCell>
                    <TableCell>
                      Quantidade
                    </TableCell>
                    <TableCell>
                      Preço
                    </TableCell>
                    <TableCell>
                      {taxOrRetentionLabels.taxTypeLabel}
                    </TableCell>
                    <TableCell>
                      Desconto
                    </TableCell>
                    <TableCell>
                      Total
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {returnNote.items.map((returnNoteItem) => (
                    <TableRow
                      hover
                      key={returnNoteItem.id}
                    >
                      <TableCell>
                        {returnNoteItem.item.code}
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.item.name}
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.unit}
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.quantity}
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.price}
                        $
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.tax.value}
                        %
                      </TableCell>
                      <TableCell>
                        {returnNoteItem.discount}
                        %
                      </TableCell>
                      <TableCell>
                        {formatCurrency(returnNoteItem.itemTotal)}
                        $
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Scrollbar>
          </Card>
        </Box>
      </Box>
      <Box sx={{ p: 3 }}>
        <Typography
          color="textSecondary"
          variant="overline"
        >
          Comentário(s)
        </Typography>
        { returnNote.commentary && (
          <Box
            sx={{
              p: 2,
              border: 0,
              borderRadius: 0
            }}
            boxShadow={1}
          >
            <Typography
              color="textSecondary"
              variant="body2"
            >
              {returnNote.commentary}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ p: 3 }}>
        <InvoiceItemSumatory
          currentCompany={currentCompany}
          invoiceItems={returnNote.items.map((returnNoteItem) => ({
            discount: returnNoteItem.discount,
            item: returnNoteItem.item.name,
            tax: returnNoteItem.tax.name,
            taxValue: returnNoteItem.tax.value,
            price: returnNoteItem.price,
            quantity: returnNoteItem.quantity,
            unit: returnNoteItem.unit
          }))}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          p: 2
        }}
      >
        <Box sx={{ flexGrow: 1 }} />
        <Button
          color="primary"
          onClick={onCancel}
          variant="outlined"
          sx={{ mr: 1 }}
        >
          Voltar
        </Button>
        <Button
          variant="outlined"
          sx={{ ml: 1 }}
          onClick={handleClick}
        >
          <ArrowDropDownIcon />
        </Button>
      </Box>
      {/* Popup to insert email to be sent */}
      <Dialog
        open={openEmailDialog}
        onClose={() => setOpenEmailDialog(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Enviar para:</DialogTitle>
        <DialogContent style={{ width: '100%' }}>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email*"
            placeholder="Introduzir Email"
            type="email"
            fullWidth
            value={email}
            onChange={handleChange}
            helperText={emailError}
            error={emailError}
            onFocus={() => setEmailError(undefined)}
            onBlur={handleChange}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions style={{ display: 'flex', justifyContent: 'space-between', margin: '0 8px 24px 8px' }}>
          <Button
            onClick={handlePopupClose}
            color="primary"
            variant="outlined"
            style={{ borderRadius: '28px' }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleSendEmailButtonClick}
            color="primary"
            variant="contained"
            style={{ borderRadius: '28px' }}
          >
            Enviar
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleSendClick}>
          <Typography
            color="textSecondary"
          >
            Enviar por email
          </Typography>
        </MenuItem>
        <MenuItem onClick={handlePrintButtonClick}>
          <Typography
            color="textSecondary"
          >
            Baixar PDF
          </Typography>
        </MenuItem>
      </Menu>
    </Card>
  );
};

ViewDetails.propTypes = {
  returnNote: PropTypes.shape({
    id: PropTypes.string,
    clientName: PropTypes.string,
    date: PropTypes.string,
    dateFormatted: PropTypes.string,
    paymentLimit: PropTypes.string,
    serieCode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    discount: PropTypes.number,
    number: PropTypes.number,
    items: PropTypes.arrayOf({
      id: PropTypes.string,
      discount: PropTypes.number,
      item: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string
      }),
      tax: PropTypes.shape({
        name: PropTypes.string,
        code: PropTypes.string,
        value: PropTypes.number
      }),
      taxValue: PropTypes.number,
      price: PropTypes.number,
      quantity: PropTypes.number,
      unit: PropTypes.string,
      itemTotal: PropTypes.number
    }),
    documentType: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    paymentPeriod: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
      numberOfDay: PropTypes.number
    }),
    paymentMode: PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string
    }),
    issueReason: PropTypes.shape({
      code: PropTypes.string,
      description: PropTypes.string
    }),
    otherIssueReason: PropTypes.string,
    documentsReferenced: PropTypes.arrayOf({
      document: PropTypes.shape({
        code: PropTypes.string,
        id: PropTypes.string
      }),
      paymentAmount: PropTypes.number
    }),
    efaturaData: PropTypes.shape({
      status: PropTypes.string,
      errorCode: PropTypes.string,
      errorMessage: PropTypes.string
    }),
    isRetried: PropTypes.bool
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  documents: PropTypes.array.isRequired,
  onCancel: PropTypes.func.isRequired,
  handleSendEmail: PropTypes.func.isRequired,
  handlePrintButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  issueReasonTypes: PropTypes.object.isRequired
};

export default ViewDetails;
