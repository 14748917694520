import * as React from 'react';

const CheckCircleOutlined = (props) => (
  <svg
    width={81}
    height={81}
    viewBox="0 0 81 81"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_7852_10859)">
      <g clipPath="url(#clip1_7852_10859)">
        <mask
          id="path-1-inside-1_7852_10859"
          fill="white"
        >
          <path d="M80.5 40.5C80.5 62.5914 62.5914 80.5 40.5 80.5C18.4086 80.5 0.5 62.5914 0.5 40.5C0.5 18.4086 18.4086 0.5 40.5 0.5C62.5914 0.5 80.5 18.4086 80.5 40.5ZM4.5 40.5C4.5 60.3823 20.6177 76.5 40.5 76.5C60.3823 76.5 76.5 60.3823 76.5 40.5C76.5 20.6177 60.3823 4.5 40.5 4.5C20.6177 4.5 4.5 20.6177 4.5 40.5Z" />
        </mask>
        <path
          d="M80.5 40.5C80.5 62.5914 62.5914 80.5 40.5 80.5C18.4086 80.5 0.5 62.5914 0.5 40.5C0.5 18.4086 18.4086 0.5 40.5 0.5C62.5914 0.5 80.5 18.4086 80.5 40.5ZM4.5 40.5C4.5 60.3823 20.6177 76.5 40.5 76.5C60.3823 76.5 76.5 60.3823 76.5 40.5C76.5 20.6177 60.3823 4.5 40.5 4.5C20.6177 4.5 4.5 20.6177 4.5 40.5Z"
          stroke="#F5F5F5"
          strokeWidth={12}
          mask="url(#path-1-inside-1_7852_10859)"
        />
        <path
          d="M80.5 40.5C80.5 62.5914 62.5914 80.5 40.5 80.5C18.4086 80.5 0.5 62.5914 0.5 40.5C0.5 18.4086 18.4086 0.5 40.5 0.5C62.5914 0.5 80.5 18.4086 80.5 40.5ZM4.5 40.5C4.5 60.3823 20.6177 76.5 40.5 76.5C60.3823 76.5 76.5 60.3823 76.5 40.5C76.5 20.6177 60.3823 4.5 40.5 4.5C20.6177 4.5 4.5 20.6177 4.5 40.5Z"
          fill="white"
        />
      </g>
      <path
        d="M50.3761 33.4531H48.7378C48.5081 33.4531 48.2901 33.5586 48.1495 33.7391L38.4862 45.9805L33.8526 40.1094C33.7825 40.0204 33.6932 39.9484 33.5913 39.8989C33.4894 39.8494 33.3776 39.8236 33.2644 39.8234H31.6261C31.469 39.8234 31.3823 40.0039 31.4784 40.1258L37.898 48.2586C38.198 48.6383 38.7745 48.6383 39.0769 48.2586L50.5237 33.7531C50.6198 33.6336 50.5331 33.4531 50.3761 33.4531V33.4531Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_7852_10859">
        <rect
          width={80}
          height={80}
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
      <clipPath id="clip1_7852_10859">
        <rect
          width={80}
          height={80}
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default CheckCircleOutlined;
