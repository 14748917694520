import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { AppBar, Box, Hidden, IconButton, Toolbar, Typography } from '@mui/material';
import { styled } from '@mui/system';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import Logo from '../Logo';

import { useDispatch, useSelector } from '../../store';
import { fetch as fetchCompanies } from '../../slices/companies';

const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));

const DashboardNavbar = (props) => {
  const { onSidebarMobileOpen, hasCompany, ...other } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { companies, currentCompany } = useSelector((state) => state.companies);

  useEffect(() => {
    dispatch(fetchCompanies());
  }, [dispatch]);

  const handleNavigate = (route, state) => {
    navigate(route, { state });
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
          >
            { hasCompany && (
            <MenuIcon fontSize="small" />
            )}
          </IconButton>
        </Hidden>
        <Hidden lgDown>
          <RouterLink to="/">
            <Logo
              sx={{
                width: 100,
                height: 22
              }}
              variant="white"
            />
          </RouterLink>
        </Hidden>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{ ml: 2,
          display: 'flex',
          alignItems: 'center'
        }}
        >
          <Typography
            color="paper"
            variant="h6"
            sx={{ mr: 2 }}
          >
            {currentCompany.name}
          </Typography>
          <AccountPopover
            companies={companies}
            handleNavigate={handleNavigate}
          />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func,
  hasCompany: PropTypes.bool
};

DashboardNavbar.defaultProps = {
  hasCompany: false
};

export default DashboardNavbar;
