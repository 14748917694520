export const colors = {
  blue: {
    main: '#1CC5DC',
    contrastText: '#ffffff'
  },
  status: {
    processing: {
      main: '#34B7CE',
      background: 'rgba(28, 197, 220, 0.1)'
    },
    pending: {
      main: '#F1B37A',
      background: 'rgba(241, 179, 122, 0.1)'
    },
    denied: {
      main: '#FF5E52',
      background: 'rgba(255, 94, 82, 0.1)'
    },
    approved: {
      main: '#21C8C3',
      background: 'rgba(33, 200, 195, 0.1)'
    }
  }
};
