import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useTheme
} from '@mui/material';
import { renderTaxOrRetentionLabels } from '../../util/renderTaxOrRetentionLabels';
import { PRODUCT_AVAILABILITY_OPTIONS } from '../../constants';
import defaultImage from '../../assets/defaultImages/defaultProduct.jpg';

const ItemViewDetails = (props) => {
  const theme = useTheme();
  const { item, onEdit, onDelete, onCancel, currentCompany, ...other } = props;

  const taxOrRetentionLabels = renderTaxOrRetentionLabels(currentCompany.companyType);

  return (
    <Card {...other}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
        <Box>
          <Typography
            align="center"
            color="textPrimary"
            variant="h5"
          >
            Item
          </Typography>
        </Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img
              style={{
                width: '11.875rem',
                height: '11.875rem',
                borderRadius: '5%',
                border: `0.125rem solid ${theme.palette.primary.main}`
              }}
              src={item && item?.images[0] ? item?.images[0]?.url : defaultImage}
              alt="Imagem do item"
            />
          </Box>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Código
                </TableCell>
                <TableCell sx={{ fontWeight: 400 }}>
                  {item.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Nome
                </TableCell>
                <TableCell>
                  {item.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Unidade
                </TableCell>
                <TableCell>
                  {item.unit.code}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  {taxOrRetentionLabels.typeLabel}
                </TableCell>
                <TableCell>
                  {item.tax.name}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Custo
                </TableCell>
                <TableCell>
                  {item.cost}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Preço Venda
                </TableCell>
                <TableCell>
                  {item.sellingPrice}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ fontWeight: 700 }}>
                  Disponível no E-Commerce
                </TableCell>
                <TableCell>
                  {item.isProduct ? PRODUCT_AVAILABILITY_OPTIONS.YES : PRODUCT_AVAILABILITY_OPTIONS.NO }
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <Box sx={{ flexGrow: 1 }} />
          <Button
            color="primary"
            onClick={onCancel}
            variant="outlined"
          >
            Voltar
          </Button>
          <Button
            color="primary"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={onDelete}
          >
            Apagar
          </Button>
          <Button
            color="primary"
            sx={{ ml: 1 }}
            type="submit"
            variant="contained"
            onClick={onEdit}
          >
            Editar
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

ItemViewDetails.propTypes = {
  item: PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    cost: PropTypes.number,
    sellingPrice: PropTypes.number,
    isProduct: PropTypes.bool,
    unit: PropTypes.shape({
      id: PropTypes.string,
      code: PropTypes.string
    }),
    images: PropTypes.shape({
      rank: PropTypes.number,
      url: PropTypes.string
    }),
    tax: PropTypes.arrayOf({
      id: PropTypes.string,
      code: PropTypes.string,
      tax: PropTypes.string,
      company_id: PropTypes.string
    })
  }).isRequired,
  currentCompany: PropTypes.shape({
    accountantTaxId: PropTypes.string,
    address: PropTypes.string,
    companyType: PropTypes.string,
    countryId: PropTypes.string,
    countryName: PropTypes.string,
    currencyId: PropTypes.string,
    email: PropTypes.string,
    financeDepartmentCode: PropTypes.string,
    financeDepartmentCountyId: PropTypes.string,
    financeDepartmentName: PropTypes.string,
    financeDepartmentTaxId: PropTypes.string,
    fiscalName: PropTypes.string,
    id: PropTypes.string,
    integrations: PropTypes.shape({
      eFatura: PropTypes.shape({
        status: PropTypes.bool
      })
    }),
    name: PropTypes.string,
    phoneNumber: PropTypes.string,
    taxId: PropTypes.string
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default ItemViewDetails;
