import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Menu,
  MenuItem,
  Chip
} from '@mui/material';
import { ArrowDropDown as ArrowDropDownIcon, InfoOutlined as InfoIcon } from '@mui/icons-material';

import { useState, useEffect } from 'react';
import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';
import {
  eFaturasStatus,
  isDocumentEfaturaStatusFailed as isCreditNoteEfaturaStatusFailed,
  getDocumentEfaturaStatusButton as getCreditNoteEfaturaStatusButton
} from '../../util/eFaturasStatus';

const CreditNoteListTable = (props) => {
  const {
    draftsStorage,
    deleteDraft,
    setSelectedDraft,
    selectedDraft,
    onClick,
    creditNotes,
    onOpenDetails,
    onView,
    onDuplicate,
    handleRetrySendDocument,
    retrySendCreditNoteSuccess,
    ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCreditNote, setSelectedCreditNote] = useState(null);
  const [anchorDraft, setAnchorDraft] = useState(null);
  const [creditNoteRetried, setCreditNoteRetried] = useState(null);
  const [creditNotesRetried, setCreditNotesRetried] = useState([]);

  const handleClick = (event, creditNote) => {
    setAnchorEl(event.currentTarget);
    setSelectedCreditNote(creditNote);
  };

  const handleDraftClick = (event, draft) => {
    setAnchorDraft(event.currentTarget);
    setSelectedDraft(draft);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDraftClose = () => {
    setAnchorDraft(null);
    setSelectedDraft(null);
  };

  const creditNoteStatus = (status) => {
    switch (status) {
      case 'liquidated':
        return { label: 'Liquidado', color: '#21C8C3', backgroundColor: 'rgba(33, 200, 195, 0.1)' };
      case 'overdue':
        return { label: 'Em Atraso', color: '#FF5E52', backgroundColor: 'rgba(255, 94, 82, 0.1)' };
      default:
        return { label: 'Pendente', color: '#F1B37A', backgroundColor: 'rgba(241, 179, 122, 0.1)' };
    }
  };

  const keys = [];

  const getDraftsKeys = () => {
    draftsStorage.forEach((draft) => {
      keys.push(Object.keys(draft));
    });
  };

  const calculateTotal = (totals) => {
    let sum = 0;
    totals.forEach((item) => sum += item.total);
    return sum;
  };

  useEffect(() => {
    if (retrySendCreditNoteSuccess) {
      setCreditNotesRetried([...creditNotesRetried, creditNoteRetried]);
    }
  }, [retrySendCreditNoteSuccess, creditNoteRetried]);

  const handleOpenCreditNoteDetails = (creditNote) => {
    if (!creditNote.isVoided) {
      const isRetried = creditNotesRetried.includes(creditNote.id);
      onOpenDetails({
        ...creditNote,
        isRetried
      });
    }
  };

  return (

    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Cliente
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Estado E-Fatura
                </TableCell>
                <TableCell>
                  Número
                </TableCell>
                <TableCell>
                  Série
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              id="credit-note-list-table-body"
            >
              {draftsStorage && getDraftsKeys()}
              {draftsStorage?.map((draft, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={(e) => handleDraftClick(e, draft)}
                  />
                  <TableRow
                    key={[keys[index]]}
                    hover
                    onClick={() => onClick(draft?.[keys[index]], keys[index])}
                  >
                    <TableCell
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {draft?.[keys[index]].clientName}
                    </TableCell>
                    <TableCell>
                      Rascunho
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].numberOfDocument}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].serieCode}
                    </TableCell>
                    <TableCell>
                      {draft?.[keys[index]].items ? formatCurrency(calculateTotal(draft[keys[index]].items)) : null}
                      $
                    </TableCell>
                  </TableRow>

                </>
              ))}
              {creditNotes?.map((creditNote, index) => (
                <>
                  <ArrowDropDownIcon
                    sx={{
                      ml: 2,
                      mr: 1,
                      mt: 3,
                      border: 0,
                      fontSize: 16,
                      position: 'absolute'
                    }}
                    onClick={!creditNote.isVoided ? ((e) => handleClick(e, creditNote)) : (() => null)}
                  />
                  <TableRow
                    hover={!creditNote.isVoided}
                    key={creditNote.id}
                    variant={!creditNote.isVoided ? '' : 'disabled'}
                    onClick={() => handleOpenCreditNoteDetails(creditNote)}
                  >
                    <TableCell
                      id={`credit-note-client-name-${index}`}
                      sx={{
                        pl: 6
                      }}
                      variant="disabled"
                    >
                      {creditNote.clientName}
                    </TableCell>
                    <TableCell variant="disabled">
                      {creditNote.paymentStatus && (
                      <Chip
                        size="medium"
                        style={{ color: `${creditNoteStatus(creditNote.paymentStatus).color}`, padding: '14px', backgroundColor: `${creditNoteStatus(creditNote.paymentStatus).backgroundColor}` }}
                        label={creditNoteStatus(creditNote.paymentStatus).label}
                      />
                      )}
                    </TableCell>
                    <TableCell
                      variant="disabled"
                      id="credit-note-table-cell-e-fatura-column"
                    >
                      {creditNote.efaturaData && (
                        <Chip
                          id={`credit-note-status-e-fatura-button-${index}`}
                          size="medium"
                          style={{
                            padding: isCreditNoteEfaturaStatusFailed(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status)) ? '10.2px' : '14px',
                            color: `${eFaturasStatus(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status)).color}`,
                            backgroundColor: `${eFaturasStatus(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status)).backgroundColor}`
                          }}
                          icon={
                            isCreditNoteEfaturaStatusFailed(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status)) && (
                              <Box sx={{ p: 0, display: 'flex', justifyContent: 'center' }}>
                                <InfoIcon
                                  color="secondary"
                                />
                              </Box>
                            )
                          }
                          label={eFaturasStatus(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status)).label}
                          clickable={isCreditNoteEfaturaStatusFailed(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status))}
                          onClick={(e) => {
                            if (isCreditNoteEfaturaStatusFailed(getCreditNoteEfaturaStatusButton(creditNotesRetried, creditNote.id, creditNote.efaturaData.status))) {
                              e.stopPropagation();
                              handleRetrySendDocument(creditNote.id);
                              setCreditNoteRetried(creditNote.id);
                            }
                          }}
                        />
                      )}
                    </TableCell>
                    <TableCell variant="disabled">
                      {creditNote.number}
                    </TableCell>
                    <TableCell variant="disabled">
                      {creditNote.serieCode}
                    </TableCell>
                    <TableCell variant="disabled">
                      {formatCurrency(creditNote.totalGeneral)}
                      $
                    </TableCell>
                  </TableRow>
                </>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
      <Menu
        anchorEl={anchorDraft}
        keepMounted
        open={Boolean(anchorDraft)}
        onClose={handleDraftClose}
      >
        <MenuItem
          onClick={() => {
            deleteDraft(Object.keys(selectedDraft));
            handleDraftClose();
          }}
        >
          Eliminar
        </MenuItem>
      </Menu>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => {
            onView(selectedCreditNote);
            handleClose();
          }}
        >
          Ver
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDuplicate(selectedCreditNote);
            handleClose();
          }}
        >
          Duplicar
        </MenuItem>
      </Menu>
    </Card>
  );
};

CreditNoteListTable.propTypes = {
  creditNotes: PropTypes.arrayOf({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientName: PropTypes.string,
    serieCode: PropTypes.string,
    paymentPeriod: PropTypes.string,
    isVoided: PropTypes.bool,
    paymentMode: PropTypes.string,
    serie: PropTypes.string,
    commentary: PropTypes.string,
    valueWithoutTax: PropTypes.number,
    totalTax: PropTypes.number,
    valueWithTax: PropTypes.number,
    discount: PropTypes.number,
    totalGeneral: PropTypes.number,
    number: PropTypes.number
  }).isRequired,
  draftsStorage: PropTypes.object.isRequired,
  deleteDraft: PropTypes.func.isRequired,
  selectedDraft: PropTypes.object.isRequired,
  setSelectedDraft: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onOpenDetails: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  handleRetrySendDocument: PropTypes.func.isRequired,
  retrySendCreditNoteSuccess: PropTypes.bool.isRequired
};

export default CreditNoteListTable;
