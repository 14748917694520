import PropTypes from 'prop-types';
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import Scrollbar from '../Scrollbar';
import { formatCurrency } from '../../util/formatCurrency';

const PurchaseInvoiceListTable = (props) => {
  const { purchaseInvoices, onOpenPurchaseInvoiceDetails, ...other } = props;

  return (
    <Card {...other}>
      <Scrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Fornecedor
                </TableCell>
                <TableCell>
                  Estado
                </TableCell>
                <TableCell>
                  Número
                </TableCell>
                <TableCell>
                  Série
                </TableCell>
                <TableCell>
                  Total
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseInvoices.map((invoice) => (
                <TableRow
                  key={invoice.id}
                  onClick={() => onOpenPurchaseInvoiceDetails(invoice)}
                >
                  <TableCell>
                    {invoice.provider.name}
                  </TableCell>
                  <TableCell>
                    {invoice.status === 'settled'
                      ? (
                        <Chip
                          size="medium"
                          label="Liquidado"
                          sx={{
                            backgroundColor: 'rgba(52, 183, 206, 0.1)',
                            color: 'primary.main'
                          }}
                        />
                      )
                      : (
                        <Chip
                          size="medium"
                          label="Não Liquidado"
                          sx={{
                            backgroundColor: 'rgba(255, 94, 82, 0.1)',
                            color: 'error.main'
                          }}
                        />
                      )}
                  </TableCell>
                  <TableCell>
                    {invoice.number}
                  </TableCell>
                  <TableCell>
                    {invoice.serieNumber}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(invoice.totalGeneral)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Scrollbar>
    </Card>
  );
};

PurchaseInvoiceListTable.propTypes = {
  purchaseInvoices: PropTypes.arrayOf({
    id: PropTypes.string,
    documentType: PropTypes.string,
    clientName: PropTypes.string,
    serieNumber: PropTypes.string,
    provider: PropTypes.shape({
      name: PropTypes.string,
      code: PropTypes.string
    }),
    totalGeneral: PropTypes.number
  }).isRequired,
  onOpenPurchaseInvoiceDetails: PropTypes.func.isRequired
};

export default PurchaseInvoiceListTable;
