import { Box, Container, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
const useStyles = makeStyles((theme) => ({
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    position: 'relative',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    bottom: 0,
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6)
    }
  }
}));

const Footer = (props) => {
  const { ...rest } = props;
  const classes = useStyles();

  return (
    <Box
      {...rest}
      component="footer"
    >
      <Container
        className={classes.footer}
      >
        <Typography
          variant="h6"
          color="#FFFFFF"
          sx={{
            fontWeight: 600,
            fontSize: 30,
            my: 2
          }}
        >
          SAMBA
        </Typography>
        <Typography
          variant="textPrimary"
          color="#FFFFFF"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            my: 2
          }}
        >
          samba@chuva.io
        </Typography>
        <Typography
          variant="textPrimary"
          color="#FFFFFF"
          sx={{
            fontSize: 16,
            fontWeight: 600,
            my: 2
          }}
        >
          +238 353 35 17
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            my: 2
          }}
        >
          <a
            href="https://www.facebook.com/SAMBA-Cloud-108642664819245"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              alt="Facebook"
              component="img"
              src="/static/homepage/facebook-icon.svg"
              sx={{
                w: 16
              }}
            />
          </a>
          <a
            href="https://www.instagram.com/samba.cloud/"
            target="_blank"
            rel="noreferrer"
          >
            <Box
              alt="Instagram"
              component="img"
              src="/static/homepage/instagram-icon.svg"
              sx={{
                fontSize: 12
              }}
            />
          </a>
        </Box>
        <a
          href="https://chuva.io/"
          target="_blank"
          rel="noreferrer"
        >
          <Box
            alt="Powered by Chuva"
            component="img"
            src="/static/homepage/powered-by-chuva.png"
            sx={{
              width: 200
            }}
          />
        </a>
      </Container>
    </Box>
  );
};

export default Footer;
