import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Box } from '@mui/material';

const H1Section = (props) => {
  const {
    title,
    description,
    buttonTitle,
    buttonId,
    disabledButton,
    onClick
  } = props;

  return (
    <Box>
      <Grid
        container
        justifyContent="space-between"
      >
        <Grid item>
          <Typography
            color="textPrimary"
            variant="h5"
          >
            {title}
          </Typography>

          <Typography
            color="textSecondary"
          >
            {description}
          </Typography>
        </Grid>
        {buttonTitle && (
          <Grid item>
            <Button
              id={buttonId}
              color="primary"
              variant="contained"
              onClick={onClick}
              disabled={disabledButton}

            >
              {buttonTitle}
            </Button>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

H1Section.defaultProps = {
  title: null,
  description: null,
  buttonTitle: null,
  buttonId: null,
  disabledButton: false,
  onClick: null
};

H1Section.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonId: PropTypes.string,
  disabledButton: PropTypes.bool,
  onClick: PropTypes.func
};

export default H1Section;
