import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';

import { homeTheme } from '../theme/homeTheme';

import Header from '../components/homePage/Header';
import { Hero } from '../components/homePage/Hero';
import { Section } from '../components/homePage/Section';
import { Footer } from '../components/homePage/Footer';
import { Integration } from '../components/homePage/Integrations';
import BannerSection from '../components/BannerSection';
import analytics, { events } from '../services/analytics';

import '../theme/fonts.css';
import { BusinessManagement } from '../components/homePage/BusinessManagement';
import { WhySamba } from '../components/homePage/WhySamba';

const HomePage = () => {
  const navigate = useNavigate();

  const handleLoginButtonClick = useCallback(() => {
    navigate('authentication/login');
  }, []);

  const handleBusinessManagement = useCallback(
    () => {
      analytics.dispatchEvent(events.businessManagementClicked());
    },
    [analytics]
  );

  const handleWhySambaClick = useCallback(
    () => {
      analytics.dispatchEvent(events.whySambaClicked());
    },
    [analytics]
  );

  const handleIntegrationsClick = useCallback(
    () => {
      analytics.dispatchEvent(events.integrationsClicked());
    },
    [analytics]
  );

  const handleBlogClick = useCallback(
    () => {
      analytics.dispatchEvent(events.blogClicked());
      navigate('blog');
    },
    [analytics, navigate]
  );

  const handleScheduleDemoClick = useCallback(
    () => {
      analytics.dispatchEvent(events.scheduleDemoClicked());
    },
    [analytics]
  );

  const handleFacebookClick = useCallback(
    () => {
      analytics.dispatchEvent(events.facebookClicked());
    },
    [analytics]
  );

  const handleInstagramClick = useCallback(
    () => {
      analytics.dispatchEvent(events.instagramClicked());
    },
    [analytics]
  );

  return (
    <>
      <Helmet>
        <title>Home | Samba</title>
      </Helmet>
      <ThemeProvider theme={homeTheme}>
        <Header
          handleLoginButtonClick={handleLoginButtonClick}
          handleBusinessManagement={handleBusinessManagement}
          handleWhySambaClick={handleWhySambaClick}
          handleIntegrationsClick={handleIntegrationsClick}
          handleBlogClick={handleBlogClick}
        />
        <Hero
          handleScheduleDemo={handleScheduleDemoClick}
        />
        <Section
          id="business-management"
          sx={{
            background: 'linear-gradient(249.6deg, #F3E5D7 0%, #F6DEF9 100%)',
            boxShadow: '0px 2px 10px 10px rgba(80, 80, 80, 0.1)',
            mb: 2,
            py: 8
          }}
        >
          <Typography
            variant="title"
            align="center"
            sx={{
              textTransform: 'uppercase',
              fontSize: 60,
              maxWidth: 500,
              lineHeight: 1
            }}
          >
            Gerenciamento de Empresas
          </Typography>
          <Box
            alt="Stars"
            component="img"
            src="/static/homepage/stars.svg"
            sx={{
              position: 'absolute',
              left: 30,
              top: -40,
              opacity: 0.7,
              objectFit: 'cover'
            }}
          />
          <Box
            alt="Stars"
            component="img"
            src="/static/homepage/stars-complete.svg"
            sx={{
              position: 'absolute',
              right: 0,
              bottom: -40,
              opacity: 0.7,
              objectFit: 'cover'
            }}
          />
        </Section>
        <BusinessManagement />
        <BannerSection
          id="why-samba"
          message="Porquê Samba?"
        />
        <WhySamba
          handleScheduleDemo={handleScheduleDemoClick}
        />
        <BannerSection
          id="integration"
          message={'Integrado com \n e-fatura'}
        />
        <Integration
          handleScheduleDemo={handleScheduleDemoClick}
        />
        <Footer
          sx={{
            backgroundColor: 'background.grey'
          }}
          handleFacebookClick={handleFacebookClick}
          handleInstagramClick={handleInstagramClick}
        />
      </ThemeProvider>
    </>
  );
};
export default HomePage;
